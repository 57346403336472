
import Swal from "sweetalert2";
import axios from "axios";
import { development } from "../../config.json"



export const deleteOndemandVideoClass = (token, video_class_id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      self_study_class_id: video_class_id,

    }
    // dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_REQUEST' })
    axios.post(`${development.baseUrl}deleteOndemandVideoClass`, data, { headers: head })
      .then(res => {
        // console.log("response of pop class ",res)
        // console.log("response of pop class ",res)
        if (res.data.result) {
          Swal.fire('Delete', res.data.message, "success")
          cb()
        }
        else {
          Swal.fire('Delete', res.data.message, "error")
        }
        //    dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_SUCCESS', payload: res.data.class_details })
        // console.log("grade", res.data)
      })
      .catch(err => {
        //  dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
export const deleteLiveClass = (token, class_id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      class_id: class_id,

    }
    // dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_REQUEST' })
    axios.post(`${development.baseUrl}deleteLiveClass`, data, { headers: head })
      .then(res => {
        // console.log("response of pop class ",res)
        if (res.data.result) {
          Swal.fire('Delete', res.data.message, "success")
          cb()

        }
        else {
          Swal.fire('Delete', res.data.message, "error")
        }
        //    dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_SUCCESS', payload: res.data.class_details })
        // console.log("grade", res.data)
      })
      .catch(err => {
        //  dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
export const populateClassForCourse = (token, course_id, teacher_id,cb=null) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }

    let data
    if (teacher_id) {
      data = {
        course_id: course_id,
        teacher_id: teacher_id
      }
    }
    else {
      data = {
        course_id: course_id,
   
      }

    }


    dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_REQUEST' })
    axios.post(`${development.baseUrl}filterClassByCourse`, data, { headers: head })
      .then(res => {
        // console.log("response of pop class ",res)
        dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_SUCCESS', payload: res.data.class_details })
        // console.log("grade", res.data)
      })
      .catch(err => {
        dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_FAILED', payload: err })
        if (err) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
              if (err.response.status === 422) {
                Swal.fire({
                  icon: 'warning',
                  // title: 'err',
                  text: err.response.data ? err.response.data.message:""
                })
              }
            }
          
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
export const filterClassBySubscription = (token, subscription_id, teacher_id) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      subscription_id: subscription_id,
      teacher_id: teacher_id
    }
    dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_REQUEST' })
    axios.post(`${development.baseUrl}filterClassBySubscription`, data, { headers: head })
      .then(res => {
        // console.log("response of pop class ",res)
        dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_SUCCESS', payload: res.data.class_details })
        // console.log("grade", res.data)
      })
      .catch(err => {
        dispatch({ type: 'POPULATE_CLASS_FOR_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
export const populateVendorCourse = (token, id) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    //  const data = {
    //   enterprise_id:null
    //  }
    dispatch({ type: 'POPULATE_COURSES_REQUEST' })
    axios.get(`${development.baseUrl}listAllVendorCourses`, { headers: head })
      .then(res => {
        dispatch({ type: 'POPULATE_COURSES_SUCCESS', payload: res.data.courses })
        // console.log("grade", res.data)
      })
      .catch(err => {
        dispatch({ type: 'POPULATE_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
export const populateMyCourse = (token, id) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    //alert("here")
    dispatch({ type: 'POPULATE_MY_COURSES_REQUEST' })
    axios.get(`${development.baseUrl}getMyCourse`, { headers: head })
      .then(res => {
        dispatch({ type: 'POPULATE_MY_COURSES_SUCCESS', payload: res.data.course_details })
        // console.log("grade", res.data)
      })
      .catch(err => {
        dispatch({ type: 'POPULATE_MY_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
export const deleteCourse = (token, id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      course_id: id
    }
    dispatch({ type: 'DELETE_COURSE_REQUEST' })
    axios.post(`${development.baseUrl}deleteCourse`, data, { headers: head })
      .then(res => {
        dispatch({ type: 'DELETE_COURSE_SUCCESS' })
        cb()
        // console.log("grade", res.data)
        cb()
      })
      .catch(err => {
        dispatch({ type: 'DELETE_COURSE_FAILED', })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }

export const createCourse = (token, formdata, cb, cb2, cb3) => (dispatch) => {

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },

    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
      dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
      // if (percent < 100) {
      //     this.setState({ uploadPercentage: percent })
      // }
    },
  };

  //  console.log(`head ${head.Authorization} data ${data.class_name}`)
  dispatch({ type: "UPLOAD_COURSE_FILES_REQUEST" });
  axios
    .post(`${development.baseUrl}createCourse`, formdata, options)
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "UPLOAD_COURSE_FILES_SUCCESS",
          payload: res.data,
        });
        cb(res.data)
        cb2()
        //to open success popup
      }
      else {
        let percent = ''
        dispatch({ type: "UPLOAD_COURSE_FILES_FAILED" });
        dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });

        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      // dispatch({ type: "EDIT_COURSE_FILES_FAILED", payload: err });
      let percent = ''
      dispatch({ type: "UPLOAD_COURSE_FILES_FAILED", payload: err });
      dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
      if (err) {
        if (err.response) {
          if (err.response.status === 422) {
            var val = Object.values(err.response.data.errors)
            cb3(val)
          }
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Token Expired',
              // text: 'Token Expired'
            })
            window.location.reload()
          }
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Something Went Wrong',
          // text: 'You are logged in to another System'
        })
      }


    });
};

export const getAllCurrencies = (token, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //console.log(`head ${head.Authorization} data ${data.class_name}`)
  dispatch({ type: "POPULATE_CLASS_DETAILS_REQUEST" });
  axios
    .get(`${development.baseUrl}listAllCurrencies`, {
      headers: head,
    })

    .then((res) => {
      //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
      if (res.data.result) {
        // dispatch({ type: "POPULATE_CLASS_DETAILS_SUCCESS", payload: res.data });
        // console.log("called", res.data);
        cb(res.data)
      }
      // console.log("populateRegisteredStudents", res.data)
    })
    .catch((err) => {
      dispatch({ type: "POPULATE_STUDENT_FAILED", payload: err });
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    });
};
export const getCourseDetails = (token, course_id, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const data = {
    course_id: course_id
  }
  //console.log(`head ${head.Authorization} data ${data.class_name}`)
  dispatch({ type: "POPULATE_CLASS_DETAILS_REQUEST" });
  axios
    .post(`${development.baseUrl}getCourseDetailsForClass`, data, {
      headers: head,
    })

    .then((res) => {
      //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
      if (res.data.result) {
        dispatch({ type: "POPULATE_CLASS_DETAILS_SUCCESS", payload: res.data });
        cb(res.data)
      }
      // console.log("populateRegisteredStudents", res.data)
    })
    .catch((err) => {
      dispatch({ type: "POPULATE_STUDENT_FAILED", payload: err });
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    });
};

export const getSubscriptionDetailsForClass = (token, subscription_id, cb) => (dispatch) => {
  const head = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const data = {
    subscription_id: subscription_id
  }
  //console.log(`head ${head.Authorization} data ${data.class_name}`)
  dispatch({ type: "POPULATE_SUBSCRIPTION_CLASS_DETAILS_REQUEST" });
  axios
    .post(`${development.baseUrl}getSubscriptionDetailsForClass`, data, {
      headers: head,
    })

    .then((res) => {
      //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
      if (res.data.result) {
        dispatch({ type: "POPULATE_SUBSCRIPTION_CLASS_DETAILS_SUCCESS", payload: res.data });
        cb(res.data)
      }
      // console.log("populateRegisteredStudents", res.data)
    })
    .catch((err) => {
      dispatch({ type: "POPULATE_SUBSCRIPTION_STUDENT_FAILED", payload: err });
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    });
};


////////////course edit////////////////
export const getEditCourseDetails = (token, course_id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }

    let data = { course_id: course_id }
    dispatch({ type: 'COURSEEDIT_DETAILS_REQUEST' })
    axios.post(`${development.baseUrl}editCourse`, data, { headers: head })
      .then(res => {
        if (res.data.result) {
          // console.log('ggeeettttt',res.data)
          dispatch({ type: 'COURSEEDIT_DETAILS_SUCCESS', payload: res.data })
          cb(res.data)
        }
      })
      .catch(err => {
        dispatch({ type: 'COURSEEDIT_DETAILS_FAILED', payload: err })
        dispatch({ type: 'SET_VENDOR_DATA', payload: { 'batchloader': false } })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }



export const UpdateCourse = (token, data, cb) => (dispatch) => {
  // console.log("data", data);
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
      dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });

    },
  };

  dispatch({ type: "EDIT_COURSE_FILES_REQUEST", });
  axios
    .post(`${development.baseUrl}updateCourse`, data, options)
    .then((res) => {
      // console.log("created Assignment", res.data);
      if (res.data.result) {
        dispatch({
          type: "EDIT_COURSE_FILES_SUCCESS",
          payload: res.data,
        });

        cb(res.data.message); //to open success popup
      } else {
        dispatch({ type: "EDIT_COURSE_FILES_FAILED" });
        let percent = ''
        dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      dispatch({ type: "EDIT_COURSE_FILES_FAILED", payload: err });
      let percent = ''
      dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    });
};

////////////////////////////
export function setCourseData(name, value) {
  return {
    type: 'SET_VENDOR_DATA',
    payload: { [name]: value },
  }
}

export const requestCourseApproval = (token, course_id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      course_id: course_id,

    }
    dispatch({ type: 'REQUEST_APPROVAL' })
    cb(true)
    axios.post(`${development.baseUrl}requestCourseApproval`, data, { headers: head })
      .then(res => {
        cb(false, "update")
        if (res.data.result) {
          Swal.fire({
            icon: "success",
            title: "Done",
            text: res.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: res.data.message,
          });
        }
      })
      .catch(err => {
        cb(false)
        dispatch({ type: 'REQUEST_APPROVAL_FOR_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }

export const uploadVendorFiles = (token, formdata, cb, cb3) => (dispatch) => {

  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },

    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
      dispatch({ type: "UPLOAD_PROGRESS_DOC", payload: percent });
      // if (percent < 100) {
      //     this.setState({ uploadPercentage: percent })
      // }
    },
  };

  //  console.log(`head ${head.Authorization} data ${data.class_name}`)
  // dispatch({ type: "UPLOAD_COURSE_FILES_REQUEST" });
  axios
    .post(`${development.baseUrl}uploadVendorDoc`, formdata, options)
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "UPLOAD_VENDOR_DOC_SUCCESS",
          payload: res.data,
        });
        cb()
        Swal.fire({
          icon: "success",
          title: "Done",
          text: res.data.message,
        });
        let percent = ""
        dispatch({ type: "UPLOAD_PROGRESS_DOC", payload: percent });
        //to open success popup
      }
      else {
        let percent = ''
        dispatch({ type: "UPLOAD_VENDOR_DOC_FAILED" });
        dispatch({ type: "UPLOAD_PROGRESS_DOC", payload: percent });

        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      // console.log("errrrr",err)
      // dispatch({ type: "EDIT_COURSE_FILES_FAILED", payload: err });
      let percent = ''
      cb()
      dispatch({ type: "UPLOAD_VENDOR_DOC_FAILED", payload: err });
      dispatch({ type: "UPLOAD_PROGRESS_DOC", payload: percent });
      if (err) {
        if (err.response) {
          if (err.response.status === 422) {
            var val = Object.values(err.response.data.errors)
            cb3(val)
          }
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Token Expired',
              // text: 'Token Expired'
            })
            window.location.reload()
          }
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Something Went Wrong',
          // text: 'You are logged in to another System'
        })
      }
    });
};



export const deleteGroup = (token, batch_id, course_id, i, cb) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  let data = { group_id: batch_id, course_id: course_id }
  dispatch({ type: 'DELETE_GROUP_REQUEST', })
  axios.post(`${development.baseUrl}deleteGroup`, data, { headers: head })
    .then(res => {
      if (res.data.result) {
        dispatch({ type: 'DELETE_GROUP_SUCCESS', })
        cb(i, res.data.message)
      }
      else {
        dispatch({ type: 'DELETE_GROUP_FAILED', })
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: res.data.message

        })
      }
    })
    .catch(err => {
      dispatch({ type: 'DELETE_GROUP_FAILED', })
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    })
}

export const deleteSubjectModules = (token, subject_id, course_id, i, cb) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  let data = { subject_id: subject_id, course_id: course_id }
  //dispatch({ type: 'DELETE_GROUP_REQUEST', })
  axios.post(`${development.baseUrl}deleteSubject`, data, { headers: head })
    .then(res => {
      if (res.data.result) {
        // dispatch({ type: 'DELETE_GROUP_SUCCESS',})
        cb(i, res.data.message)
      }
      else {
        //  dispatch({ type: 'DELETE_GROUP_FAILED', })
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: res.data.message

        })
      }
    })
    .catch(err => {
      //  dispatch({ type: 'DELETE_GROUP_FAILED', })
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    })
}
export const listAllVendorTransactions = (token, body, cb) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  // let data={ticket_id:ticket_id}
  //dispatch({ type: 'TICKET_ALL_REQUEST' })
  // axios.get(`${development.baseUrl}listAllVendorTransaction`,{ headers: head })
  axios.get(`${development.baseUrl}listAllVendorTransaction?start_date=${body.from_date}&end_date=${body.to_date}`, { headers: head })
    .then(res => {
      if (res.data.result) {
        // dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
        //     ticket_details:res.data.ticket,
        //     Chatdata:res.data.messages
        // }})
        cb(res.data.transactions)
      }
    })
    .catch(err => {
      //   dispatch({ type: 'TICKET_ALL_FAILED'})
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            //text:'You are logged in to another System'
            // text:err
          })
        }
      }
    })
}
export const exportAllVendorTransactions = (token, body, cb) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  // let data={ticket_id:ticket_id}
  //dispatch({ type: 'TICKET_ALL_REQUEST' })
  // axios.get(`${development.baseUrl}listAllVendorTransaction`,{ headers: head })
  axios.get(`${development.baseUrl}exportVendorTransaction?start_date=${body.from_date}&end_date=${body.to_date}`, { headers: head })
    .then(res => {
      if (res.data.result) {
        // dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
        //     ticket_details:res.data.ticket,
        //     Chatdata:res.data.messages
        // }})
        cb(res.data.url)
      }
    })
    .catch(err => {
      //   dispatch({ type: 'TICKET_ALL_FAILED'})
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            //text:'You are logged in to another System'
            // text:err
          })
        }
      }
    })
}

export const exportSalesUnderVendor = (token, from_date, to_date, school_id, student_id, cb) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  let data = { from_date: from_date, to_date: to_date, school_id: school_id, student_id: student_id }
  dispatch({ type: 'SALES_ALL_REQUEST', payload: { saleinvoicesloader: true } })
  axios.post(`${development.baseUrl}exportSalesUnderVendor`, data, { headers: head })
    .then(res => {
      if (res.data.result) {
        dispatch({ type: 'SALES_ALL_SUCCESS', payload: { saleinvoicesloader: false } })
        cb(res.data.url)
      }
    })
    .catch(err => {
      dispatch({ type: 'SALES_ALL_FAILED', payload: { saleinvoicesloader: false } })
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Something Went Wrong',
          // text: 'You are logged in to another System'
        })
      }
    })
}

export const listSalesUnderVendor = (token, from_date, to_date, cb) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  let data = { from_date: from_date, to_date: to_date }
  dispatch({ type: 'SALES_ALL_REQUEST', payload: { salesloader: true } })
  axios.post(`${development.baseUrl}listSalesUnderVendor`, data, { headers: head })
    .then(res => {
      if (res.data.result) {
        dispatch({ type: 'SALES_ALL_SUCCESS', payload: { sales: res.data, salesloader: false } })
        cb(res.data.sales)
      }
    })
    .catch(err => {
      dispatch({ type: 'SALES_ALL_FAILED', payload: { salesloader: false } })
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Something Went Wrong',
          // text: 'You are logged in to another System'
        })
      }
    })
}

////////////////course edit requesst///
export const requestEditForCourse = (token, course_id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      course_id: course_id,

    }
    dispatch({ type: 'REQUEST_APPROVAL' })
    cb(true)
    axios.post(`${development.baseUrl}requestEditForCourse`, data, { headers: head })
      .then(res => {
        cb(false, "update")
        if (res.data.result) {
          Swal.fire({
            icon: "success",
            title: "Done",
            text: res.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: res.data.message,
          });
        }
      })
      .catch(err => {
        cb(false)
        dispatch({ type: 'REQUEST_APPROVAL_FOR_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
//////////////////////////////course edit approvel
export const requestApprovalForEditedCourse = (token, course_id, cb) =>
  (dispatch) => {
    const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    }
    const data = {
      course_id: course_id,

    }
    dispatch({ type: 'REQUEST_APPROVAL' })
    cb(true)
    axios.post(`${development.baseUrl}requestApprovalForEditedCourse`, data, { headers: head })
      .then(res => {
        cb(false, "update")
        if (res.data.result) {
          Swal.fire({
            icon: "success",
            title: "Done",
            text: res.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: res.data.message,
          });
        }
      })
      .catch(err => {
        cb(false)
        dispatch({ type: 'REQUEST_APPROVAL_FOR_COURSES_FAILED', payload: err })
        if (err) {
          if (err.response) {
            if (err.response) {
              if (err.response.status === 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'Token Expired',
                  // text: 'Token Expired'
                })
                window.location.reload()
              }
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
            })
          }
        }
      })
  }
///////////////////////////////////////////////

export const EditDate = (token, course_start_date, course_end_date, course_id, cb, i, e, dt) => (dispatch) => {
  const head = {
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
  }
  let data = { course_start_date: course_start_date, course_end_date: course_end_date, course_id: course_id }
  dispatch({ type: 'DELETE_GROUP_REQUEST', })
  axios.post(`${development.baseUrl}truncateClasses`, data, { headers: head })
    .then(res => {
      if (res.data.result) {
        dispatch({ type: 'DELETE_GROUP_SUCCESS', })
        cb(i, e, dt, res.data.message)
      }
      else {
        dispatch({ type: 'DELETE_GROUP_FAILED', })
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: res.data.message

        })
      }
    })
    .catch(err => {
      dispatch({ type: 'DELETE_GROUP_FAILED', })
      if (err) {
        if (err.response) {
          if (err.response) {
            if (err.response.status === 401) {
              Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
              })
              window.location.reload()
            }
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
          })
        }
      }
    })
}
