import React, { useState, useEffect } from 'react'
import PageTitle from '../../common/HeaderTitle/PageTitle'
import { Card, CardHeader, Row, Col, Input, FormFeedback } from 'reactstrap'
// import Dropzone from 'react-dropzone'
// import { FileCopy, Attachment, Clear } from '@material-ui/icons'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { populateMyCourse,  getStudyMaterialNotes, getStudyMaterialData, updateStudyMaterial } from '../../../redux/Actions/homeTeacher'
// import { populateClassForCourse } from '../../../redux/Actions/vendor_course'
import { useDispatch,  } from 'react-redux'
// import DataTable from '../../common/DataTable'
import { useParams } from 'react-router-dom'
// import { useHistory, } from 'react-router-dom'
import {  requiredValidator } from "../../../utilities/Validator";
// import Swal from 'sweetalert2'
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

function StudyMaterials() {

    const params = useParams()
    // const history = useHistory()

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const homeTeacher = useSelector(({ homeTeacher }) => homeTeacher)
    // const course = useSelector(({ course }) => course)

    const [InputField, setInputField] = useState({});

    const [EditData, setEditData] = useState({})
    const [Errors, setErrors] = useState({})
    const [EState, setEState] = useState(EditorState.createEmpty())
    const [loading,setLoading]=useState(false)
    const cb = (data) => {
        setEditData(data)
        //  setEState( data.Description ) 
        const contentBlock = htmlToDraft(data.Description);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        setEState(editorState)
    }

    useEffect(() => {
        dispatch(populateMyCourse(tokenData.token))
        dispatch(getStudyMaterialNotes(tokenData.token))
        dispatch(getStudyMaterialData(tokenData.token, params.study_material_id, params.class_id, cb))
    }, [])

    // const formData = new FormData();

    const formSubmit = () => {

        let err = {}
        err = {
            title: requiredValidator(EditData.Title),
        };

        if (!err.title) {
            let data = {
                study_material_id: params.study_material_id,
                title: EditData.Title,
                description: draftToHtml(convertToRaw(EState.getCurrentContent()))
            }
            setLoading(true)
            dispatch(updateStudyMaterial(tokenData.token, data, refresh))
        } else {
            setErrors(err)
        }

    }

    const refresh = () => {
        setLoading(false)
    }

    // const reset = () => {
    //     setErrors({})
    //     // setFiles([])
    //     setInputField({
    //         title: '',
    //         course_id: '',
    //         class_id: '',
    //         // description: null
    //     })
    //     dispatch(getStudyMaterialNotes(tokenData.token))
    //     setEState(EditorState.createEmpty())
    //     Swal.fire({
    //         icon: 'success',
    //         text: 'Study Note Created',
    //         // title: 'Token Expired'
    //     })
    // }



    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setEditData({ ...InputField, [name]: value })
    }



    const onEditorStateChange = (e) => {
        setEState(e)
    }


    return (
        <div>
            <PageTitle
                heading="Study Notes"
                subheading="Edit Study Notes"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />
            <Card className='mb-3' >
                <CardHeader>Edit Notes</CardHeader>
                <Row className='p-3' >
                    <Col sm={12} >
                        <label>Title</label>
                        <Input value={EditData.Title} invalid={Errors.title} name='Title' onChange={handleChangeInput} ></Input>
                        <FormFeedback>{Errors.title}</FormFeedback>
                    </Col>



                    <Col   className='mt-3' sm={12} >
                        <Editor
                            stripPastedStyles={true}
                            handlePastedText={() => false}
                            editorStyle={{ border: "1px solid #ced4da", borderRadius: '0.25rem', marginTop: '10px', height: '500px' }}
                            editorState={EState}
                            onEditorStateChange={(e) => { onEditorStateChange(e) }}
                            // toolbar={{
                            //     inline: { inDropdown: true },
                            //     list: { inDropdown: true },
                            //     textAlign: { inDropdown: true },
                            //     link: { inDropdown: true },
                            //     history: { inDropdown: true },
                            //     image: {
                            //         // uploadCallback: uploadImageCallBack,
                            //         alt: { present: false, mandatory: false }, previewImage: true
                            //     },
                            // }}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                fontFamily: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: { inDropdown: false },
                                options: ['inline', 'link', 'history', 'fontSize', 'fontFamily', 'list']
                            }}
                        />
                    </Col>


                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} >
                        <LaddaButton className="mt-2 mr-2 btn btn-danger"
                            loading={loading}
                            onClick={formSubmit}
                            type="submit"
                            data-style={ZOOM_IN}
                            style={{ whiteSpace: 'nowrap', marginRight: '0px' }}

                        >
                            Update
                        </LaddaButton>
                    </div>

                </Row>
            </Card>

            {/* <DataTable
                title={'Study Notes'}
                actions={'study_notes'}
                // role={props.role}
                columns={columns}
                data={homeTeacher.MyStudyMaterialsNotes}
                //  handleDownload={handleDownload}
                handleView={handleView}
                handleEdit={handleEdit}
            //   loader={props.loader}


            /> */}

        </div>
    )
}

export default StudyMaterials
