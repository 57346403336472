import React, { useEffect, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  faHome,
  // faCheckCircle,faTimesCircle ,faUserClock 
} from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { Link } from "react-router-dom";
import Container from "reactstrap/lib/Container";
import {
  Row,
  Col,
  Label, Input, Card, CardHeader,
  Button,
  ListGroup,
  ListGroupItem,

} from "reactstrap";



import PerfectScrollbar from 'react-perfect-scrollbar';


import { useParams } from 'react-router-dom'
import { setReqData, getVendorSingleCoursedetails, addCourseCommission, acceptCourse, blockVendor, approveEditRequest, approveEditedCourseRequest } from '../../../../redux/Actions/Superadmin'
import { requestCourseApproval, requestEditForCourse, requestApprovalForEditedCourse } from '../../../../redux/Actions/vendor_course'
import { getInvoice } from "../../../../redux/Actions/Student"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Model2 from "../../../common/Modal/index"
import EditIcon from '@material-ui/icons/Edit';
import Model from "../Model"
import spinner from '../../../../assets/animated/Spinner.gif'
import ReactHtmlParser from 'react-html-parser';

import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Swal from 'sweetalert2'


import Rejectcourse from './Rejectcourse'
import VideoCard from './Videocard'
import Index from "./Coursefull/index"
import Tooltip from '@material-ui/core/Tooltip';

import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SweetAlert from 'sweetalert-react';
import CircularProgress from "@material-ui/core/CircularProgress";
import vendordummy from '../../../LandingPageNew/images/vendordummy.png'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import coursedummy from "../../../LandingPageNew/images/coursedummy.png"
import previewdummy from "../../../LandingPageNew/images/previewdummy.png"
// import schedulecourse from "../../../LandingPageNew/images/courserescheduled.png"
import { getAssessmentDetails } from "../../../../redux/Actions/genexhome"

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Chip from '@material-ui/core/Chip';
import ReactPlayer from 'react-player'
import { development } from '../../../../../src/config.json';

function CouseFulldetails() {

  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams()
  const [enroll, setEnroll] = useState(false)
  const [enroll_end_date, setEnrollEnd] = useState('')
  const [nowPlaying, setNowPlaying] = useState({ open: false, iframe: "<iframe></iframe>" })
  const [successModal, setSuccessModal] = useState(false);
  const [Load, setLoad] = useState(false)

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const role = tokenData.role
  const editcoursecommision = useSelector((state) => state.superadmin.editcoursecommision ? state.superadmin.editcoursecommision : false);


  const singlecourseloader = useSelector((state) => state.superadmin.singlecourseloader ? state.superadmin.singlecourseloader : false);
  const Course_Edited_status = useSelector((state) => state.superadmin.Course_Edited_status ? state.superadmin.Course_Edited_status : '');
  const Course_Edit_status = useSelector((state) => state.superadmin.Course_Edit_status ? state.superadmin.Course_Edit_status : '');

  const SingleCourseDetail = useSelector((state) => state.superadmin.SingleCourseDetail ? state.superadmin.SingleCourseDetail : []);
  const imageview = useSelector((state) => state.superadmin.imageview ? state.superadmin.imageview : false);
  const video = SingleCourseDetail ? SingleCourseDetail.preview_video ? SingleCourseDetail.preview_video : [] : []
  const frstvideo = video ? video[0] : ''
  const thumb = frstvideo ? frstvideo.thumbnails : false
  const thumblink = SingleCourseDetail.camera_tag_thumbnail_path ? SingleCourseDetail.camera_tag_thumbnail_path : thumb[1] ? thumb[1].link : previewdummy
  const dur = frstvideo ? frstvideo.video_duration : 'no'
  const [value, setValue] = useState()
  const [commissionvalue, setcommissionValue] = useState(0)
  const reasons = SingleCourseDetail ? SingleCourseDetail.reject_reasons ? SingleCourseDetail.reject_reasons : [] : []
  const course_tags = useSelector((state) => state.superadmin.course_tags ? state.superadmin.course_tags : []);


  const reject_reasons_model = useSelector((state) => state.superadmin.reject_reasons_model ? state.superadmin.reject_reasons_model : false);
  const successreject = useSelector((state) => state.superadmin.successreject ? state.superadmin.successreject : false);


  const commission_loader = useSelector((state) => state.superadmin.commission_loader ? state.superadmin.commission_loader : false);
  const accept_loader = useSelector((state) => state.superadmin.accept_loader ? state.superadmin.accept_loader : false);
  const invoiceloader = useSelector((state) => state.student.invoiceloader ? state.student.invoiceloader : false);

  const liveclass_length = SingleCourseDetail ? SingleCourseDetail.live_classes ? SingleCourseDetail.live_classes.length : 0 : 0
  const videoclass_length = SingleCourseDetail ? SingleCourseDetail.ondemand_classes ? SingleCourseDetail.ondemand_classes.length : 0 : 0

  const user_details = SingleCourseDetail ? SingleCourseDetail.user_details ? SingleCourseDetail.user_details : {} : [];
  const CourseAssesmentDetails = useSelector((state) => state.genexhome.CourseAssesmentDetails ? state.genexhome.CourseAssesmentDetails : {});

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  useEffect(() => {
    if (tokenData.role === 'student') {
      dispatch(getVendorSingleCoursedetails(tokenData.token, params.course_id, 'order'))
    }
    else {
      dispatch(getVendorSingleCoursedetails(tokenData.token, params.course_id,))
    }
  }, [])

  /////////Assesment/////////
  useEffect(() => {
    if (tokenData.role === 'student' && user_details.skl_user_id !== undefined) {
      dispatch(getAssessmentDetails(params.course_id,user_details.skl_user_id))
    }
  }, [params.course_id,user_details.skl_user_id])

  const redirecttoQuiz = ()=>{
    let Assessment = Object.keys(CourseAssesmentDetails).length > 0 ? CourseAssesmentDetails:{};   
    let RedirectURL = window.location.href;
    if((((SingleCourseDetail.ondemand_classes.reduce((accu, val) => accu + val.percentage, 0)) / (SingleCourseDetail.ondemand_classes.length * 100)) * 100)===100  && Object.keys(CourseAssesmentDetails).length > 0){
        let obj = {'Quiz_Id':Assessment.Quiz_Id,"CANUID":user_details.skl_user_id,"IsRetake":Assessment.IsRetake,"RedirectURL":RedirectURL}
        obj = btoa(JSON.stringify(obj) )
        let URL =  development.assessmentUrl +'?'+ obj;
        let a = document.createElement('a');
        a.href = URL;
        a.click();
    }
  
  }

  ////////////commission//////////////
  const Commissionedit = (val) => {
    // setValue(val.commision_type ? val.commision_type:'rate')
    setValue('percentage')
    setcommissionValue(val.commision ? val.commision : 0)
    dispatch(setReqData('editcoursecommision', true))
  }
  // const Radiochange=(e)=>{
  //   setcommissionValue(0)
  //   setValue(e.target.value)
  // }
  const Editcommission = (e) => {
    if (value === "percentage" && e.target.value > 100) {
      alert("Percntage can't be more than 100")
    }
    else setcommissionValue(e.target.value)
  }

  const submitCommission = () => {
    dispatch(addCourseCommission(tokenData.token, value, commissionvalue, params.course_id, handleOpenSuccessModal))
  }


  const handleOpenSuccessModal = () => {
    dispatch(setReqData('editcoursecommision', false))
    dispatch(getVendorSingleCoursedetails(tokenData.token, params.course_id))
    setSuccessModal(true)

  }
  // const handleCloseSuccesstModal = () => {
  //   // dispatch(getVendorSingleCoursedetails(tokenData.token, params.course_id))
  //   setSuccessModal(false)
  // }

  const handleAccept = () => {
    dispatch(acceptCourse(tokenData.token, params.course_id, handleOpenSuccessModal))
  }

  const handleAcceptCourseEdit = () => {
    dispatch(approveEditRequest(tokenData.token, params.course_id, handleOpenSuccessModal))
  }

  const handleAcceptCourseEditApproval = () => {
    dispatch(approveEditedCourseRequest(tokenData.token, params.course_id, handleOpenSuccessModal))
  }
  ///////////////////

  const CloseModel = () => {
    dispatch(setReqData('editcoursecommision', false))
  }
  const CloseRejectModel = () => {
    dispatch(setReqData('reject_reasons_model', false))
  }
  const RejectModel = (val) => {
    dispatch(setReqData('reject_reasons', val))
    dispatch(setReqData('reject_reasons_model', true))
  }

  const Viewimage = () => {
    dispatch(setReqData('imageview', true))
  }
  const CloseImage = () => {
    dispatch(setReqData('imageview', false))
  }

  const handleRequest = () => {
    // let subjectmodule=SingleCourseDetail ? SingleCourseDetail.subjects ? SingleCourseDetail.subjects.length > 0 ? true:false:false:false
    let lerningcontent = SingleCourseDetail ? SingleCourseDetail.learning_contents ? SingleCourseDetail.learning_contents.length > 0 ? true : false : false : false
    // let subjectmoduleval=SingleCourseDetail ? SingleCourseDetail.subjects ? SingleCourseDetail.subjects[0] ? SingleCourseDetail.subjects[0].subject_name !='' ? true:false:false:false:false
    let desclength = SingleCourseDetail ? SingleCourseDetail.description ? SingleCourseDetail.description.length > 8 ? true : false : false : false

    if (!lerningcontent || !SingleCourseDetail.description || !desclength) {
      ShowWrning("Complete All The Required Fields")
    }

    else if (!SingleCourseDetail.banner_url) {
      ShowWrning("Please Upload Course Logo")
    }
    else if (thumb.length === 0 && SingleCourseDetail.camera_tag_thumbnail_path ? false :true) {
      ShowWrning("Please Upload Course Preview Video")
    }
    else if (!SingleCourseDetail.live_class_enabled && !SingleCourseDetail.ondemand_video_enabled) {
      ShowWrning("Please Select Course Type(Live or Video)!")
    }

    else if (SingleCourseDetail.schedule_type === 'pre_scheduled' && SingleCourseDetail.live_class_enabled && liveclass_length === 0) {
      ShowWrning("This is a Prescheduled course,Please Add Live Classes")
    }
    else if (SingleCourseDetail.schedule_type === 'pre_scheduled' && SingleCourseDetail.ondemand_video_enabled && videoclass_length === 0) {
      ShowWrning("This is a Prescheduled course,Please Add Video Classes")
    }

    else {
      dispatch(requestCourseApproval(tokenData.token, params.course_id, cb))
      // alert('scuuec')
    }
  }

  const CourseEditRequest = () => {
    dispatch(requestEditForCourse(tokenData.token, params.course_id, cb))
  }

  const CourseEditRequestApproval = () => {
    dispatch(requestApprovalForEditedCourse(tokenData.token, params.course_id, cb))
  }

  const ShowWrning = (msg) => {
    Swal.fire({
      icon: 'warning',
      title: "Can't Send Request",
      text: msg,
    })
  }

  const cb = (bool, update) => {
    setLoad(bool)
    if (update) {
      dispatch(getVendorSingleCoursedetails(tokenData.token, params.course_id))
    }
  }

  const blockHandle = () => {

    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(blockVendor(tokenData.token, 2142, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Cancelled',
          'error'
        )
      }
    })

  }

  const openVideo = (player, thumbnile, processing, camera_tag_url) => {
    if (processing) {
      Swal.fire({
        icon: 'warning',
        title: 'Processing',
        // text: "Video on process"

      })
    } else {
      dispatch(setReqData('prevent_idle_timeout', true))
      setNowPlaying({ open: true, iframe: player, camera_tag_url: camera_tag_url })

    }
  }


  const DownloadInvoice = () => {
    dispatch(getInvoice(tokenData.token, params.order_id, dwnload))
  }
  const dwnload = (val) => {
    if (val) {
      window.open(val, "_blank")
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Download Failed',
        text: "Can't Download this Invoice"
      })
    }
  }
  const ClosePreview = () => {
    dispatch(setReqData('prevent_idle_timeout', false))
    setNowPlaying({ open: false, iframe: '', camera_tag_url: '' })
  }

  const CloseEnrollmentedit = () => {
    setEnroll(false)
  }
  const handleChangeFormDate = (e) => {
    const dt = moment(e).format("YYYY-MM-DD");
    setEnrollEnd(e)
  }
  const leftmargin = { marginTop: '15px' }
  const valstyle = { fontSize: '16px', marginTop: '15px' }
  const requiredvalstyle = { fontSize: '16px', marginTop: '15px', color: 'red' }

  const handleCloseSuccesstModalreason = () => {
    dispatch(setReqData('successreject', false))

  }
  const Reschudule = (val) => {
    if (val.edit_status === 1 && role === 'superAdmin') {
      return <span class="mr-1 course-badges badge badge-warning">Reschduling</span>
    }
    else {
      return null
    }
  }

  const TagLabelCheck = () => {
    if (course_tags.length > 0) {
      return (
        <Col xs={12} className="assign-details-card-div-head">
          <span style={leftmargin}>Course Category Tags</span>
        </Col>
      )
    }
    else {
      return null
    }
  }

  const CameratagOrNot = (val1,val2) => {
    if (val1) {
      return (
        <div className='player-wrapper'>
          <ReactPlayer
            url={val1}
            controls={true}
          />
        </div>
      )
    }
    else {
      return(
        ReactHtmlParser(val2)
      )
    }
  }
  return (
    <Fragment>
      {
        singlecourseloader ?
          <>
            <Card style={{ padding: '30px' }}>
              <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                <Skeleton height={30} count={5} />
              </SkeletonTheme>
            </Card>
            <Card style={{ padding: '30px', marginTop: '25px' }}>
              <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

                <Skeleton height={30} count={4} />
              </SkeletonTheme>
            </Card>
          </>
          :
          <div>
            {
              role === 'student' && SingleCourseDetail.edit_status === 1 ?
                <div style={{ height: 'auto', width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  {/* <img src={schedulecourse} alt='reschuduling' width='600px' height='400px' /> */}
                  Reschuduling.Contact Admin!
                </div>
                :
                <div>

                  <Container>

                    {
                      role === 'student' ?
                        <Row>
                          <Col sm={12} md={12} >
                            <Breadcrumb style={{ width: '100%' }}>
                              <BreadcrumbItem>
                                <Link to="/dashboard">
                                  <FontAwesomeIcon icon={faHome} />
                                </Link>
                              </BreadcrumbItem>
                              <BreadcrumbItem>
                                <Link to="/dashboard/myCourse">My Courses</Link>
                              </BreadcrumbItem>
                              <BreadcrumbItem active>Course Details</BreadcrumbItem>

                            </Breadcrumb>
                          </Col>
                        </Row>
                        : null
                    }


                    {
                      role === 'principal' ?
                        <Row>
                          <Col xs={12}>
                            <Breadcrumb style={{ width: '100%' }}>
                              <BreadcrumbItem>
                                <Link to="/dashboard">
                                  <FontAwesomeIcon icon={faHome} />
                                </Link>
                              </BreadcrumbItem>
                              <BreadcrumbItem>
                                <Link to="/dashboard/showCourse">Course List</Link>
                              </BreadcrumbItem>
                              <BreadcrumbItem active>Course Details</BreadcrumbItem>

                            </Breadcrumb>
                          </Col>
                        </Row>
                        : null
                    }

                    <Row>
                      <Col sm={12} md={12}>

                        <div
                          className="app-page-title bg-plum-plate"
                          style={{
                            margin: "0px 0px 15px 0px",
                            borderRadius: "6px",
                            // backgroundColor: "rgb(0, 69, 139)",
                            height: '160px',
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ paddingRight: "20px" }} >
                            <img style={{ border: "3px white solid", borderRadius: "4px" }} alt={"vendor_logo"} width="164px" height="112px" src={SingleCourseDetail.vendor_logo ? SingleCourseDetail.vendor_logo : vendordummy}></img>
                          </div>

                          <div>
                            <span style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: '18px' }}>
                              {SingleCourseDetail.course_name}
                            </span>{" "}
                            <br />
                            {
                              role !== 'student' ?
                                <div
                                  style={{
                                    color: '#ffffff',
                                    fontWeight: 600,
                                    textTransform: "capitalize",
                                    // marginLeft:'10px' 
                                  }}
                                  className={cx("page-title-subheading")}
                                >
                                  {
                                    SingleCourseDetail.product_code
                                  }
                                </div>
                                : ''
                            }

                            <span style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: '15px', color: "black" }}>
                              {SingleCourseDetail.vendor_name}
                            </span>{" "}
                            <br />
                            {
                              role === 'principal' ?
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}> {SingleCourseDetail.proposed_fee}</span>
                                :
                                <span style={{ fontSize: '15px', fontWeight: 'bold' }}> {SingleCourseDetail.course_fee}</span>

                            }
                            <br />

                            {Reschudule(SingleCourseDetail)}


                          </div>
                          {
                            role === 'student' ?
                              <div className="page-title-actions">
                                <span>Order Id: </span> <span style={{ fontSize: '20px' }}># {params.order_id}</span>
                                <span style={{ cursor: 'pointer' }}>
                                  {
                                    invoiceloader ?
                                      <CircularProgress color="secondary" size={20} />
                                      :
                                      <Tooltip title='Download Invoice'>
                                        <GetAppOutlinedIcon onClick={DownloadInvoice} />
                                      </Tooltip>
                                  }

                                </span>
                              </div> : ''
                          }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12" md="7">
                        <div style={{ backgroundColor: '#ffffff', minHeight: '580px', border: '1px solid #d1d7dc', borderRadius: '4px', padding: '15px 0px 20px 0px', boxSizing: 'border-box', wordWrap: 'break-word', paddingLeft: '10px' }}>
                          <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }} >
                            <span style={{ color: '#1c1d1f', fontWeight: '600', letterSpacing: '-.02rem', fontSize: '1.4rem' }}>Course Details
                            </span>
                            {
                              role !== 'student' ?
                                // <div style={{marginRight:'10px', color:'#1c1d1f'}} > { SingleCourseDetail.request_status === "approved"?(<div style={{color:"green"}} >Approved<FontAwesomeIcon size="2x" color="green" icon={faCheckCircle} /> </div>):(SingleCourseDetail.request_status === "requested" ||SingleCourseDetail.request_status === "unrequested" )?(<div style={{color:"orange"}} >Pending<FontAwesomeIcon size="2x" color="orange" icon={faUserClock} /> </div>):(<div style={{color:"red"}} >Rejected<FontAwesomeIcon color="red" size="2x" icon={faTimesCircle} /> </div>) }</div> 
                                <div style={{ marginRight: '10px', color: '#1c1d1f', fontSize: '15px', marginTop: '6px' }}>{SingleCourseDetail.request_status === "unrequested" ? (<div style={{ color: "#87CEEB" }}>Unrequested</div>) : SingleCourseDetail.request_status === "approved" ? (<div style={{ color: "green" }} >Approved</div>) : (SingleCourseDetail.request_status === "requested") ? (<div style={{ color: "orange" }} >Requested</div>) : (<div style={{ color: "red" }} >Rejected</div>)}</div>

                                :
                                ''
                            }
                          </div>

                          <hr style={{ marginRight: '10px' }} />

                          <Row>


                            <Col md={5} className="assign-details-card-div-head" >
                              <span style={leftmargin}>Course Name</span>
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                              <span style={valstyle}>{SingleCourseDetail.course_name}</span>
                            </Col>
                            <Col md={5} className="assign-details-card-div-head" >
                              <span style={leftmargin}>Product Code</span>
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                              <span style={valstyle}>{SingleCourseDetail.product_code}</span>
                            </Col>


                            <Col md={5} className="assign-details-card-div-head">
                              <span style={leftmargin}>Subject</span>
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                              <span style={valstyle}>{SingleCourseDetail.course_subject_name}</span>
                            </Col>

                            {
                              SingleCourseDetail.live_class_enabled && role === 'principal' ?
                                <>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Subject Modules</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    <span style={valstyle}>
                                      {
                                        SingleCourseDetail ? SingleCourseDetail.subjects ? SingleCourseDetail.subjects.length > 0 &&
                                          SingleCourseDetail.subjects[0].subject_name !== ''
                                          ?
                                          <>
                                            {
                                              SingleCourseDetail.subjects.map((val, ky) => {
                                                return <li key={ky}>{val.subject_name}</li>
                                              })
                                            }
                                          </>
                                          :
                                          ''
                                          : ''
                                          : ''
                                      }

                                    </span>

                                  </Col>
                                </>
                                :
                                ''
                            }


                            <Col md={5} className="assign-details-card-div-head">
                              <span style={leftmargin}>Grade</span>
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                              {
                                SingleCourseDetail.grade_name ?
                                  <span style={valstyle}>{SingleCourseDetail.grade_name}</span>
                                  :
                                  <span style={requiredvalstyle}>*Required</span>

                              }
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                              <span style={leftmargin}>Course Start Date</span>
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                              <span style={valstyle}>{SingleCourseDetail.course_start_date}</span>
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                              <span style={leftmargin}>Course End Date</span>
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                              <span style={valstyle}>{SingleCourseDetail.course_end_date}</span>
                            </Col>

                            {
                              // liveclass_length > 0 ?
                              SingleCourseDetail.live_class_enabled ?
                                <>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Class Type</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    {
                                      SingleCourseDetail.live_class_type ?
                                        <span style={valstyle}>{SingleCourseDetail.live_class_type}</span>
                                        :
                                        <span style={requiredvalstyle}>*Required</span>
                                    }
                                  </Col>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Admission Start Date</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    {
                                      SingleCourseDetail.enrollment_start_date ?
                                        <span style={valstyle}>{SingleCourseDetail.enrollment_start_date}</span>
                                        :
                                        <span style={requiredvalstyle}>*Required</span>
                                    }
                                  </Col>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Admission End Date</span>

                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    {
                                      SingleCourseDetail.enrollment_end_date ?
                                        <span style={valstyle}>{SingleCourseDetail.enrollment_end_date}</span>
                                        :
                                        <span style={requiredvalstyle}>*Required</span>

                                    }

                                  </Col>

                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Live Class</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    {
                                      SingleCourseDetail.total_live_calsses ?
                                        <span style={valstyle}>{SingleCourseDetail.total_live_calsses}</span>
                                        :
                                        <span style={{ fontSize: '16px', marginTop: '15px' }}>-</span>
                                    }
                                  </Col>
                                </>
                                :
                                ''
                            }

                            {
                              // videoclass_length > 0 ? 
                              SingleCourseDetail.ondemand_video_enabled ?
                                <>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>On Demand Class</span>
                                  </Col>
                                  {
                                    videoclass_length === 0 ?
                                      <Col md={7} className="assign-details-card-div">
                                        <span style={{ fontSize: '16px', marginTop: '15px' }}>-</span>
                                      </Col>
                                      :
                                      <Col md={7} className="assign-details-card-div">
                                        <span style={valstyle}>{videoclass_length}</span>
                                      </Col>
                                  }

                                </>
                                :
                                ''
                            }


                            {
                              role === 'superAdmin' ?
                                <>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Proposed fee</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    <div >
                                      <span style={valstyle}>{SingleCourseDetail.proposed_fee}</span>

                                    </div>
                                  </Col>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Commission</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    <div>
                                      <span style={valstyle}>{SingleCourseDetail.commision}</span>
                                      <Tooltip arrow title='Update Commision'>
                                        <span style={{ marginLeft: '10px' }}><EditIcon fontSize='small' onClick={() => { Commissionedit(SingleCourseDetail) }} style={{ cursor: 'pointer' }} /></span>
                                      </Tooltip>
                                    </div>
                                  </Col>
                                  {SingleCourseDetail.course_fee ?
                                    <>
                                      <Col md={5} className="assign-details-card-div-head">
                                        <span style={leftmargin}>Total course fees</span>
                                      </Col>
                                      <Col md={7} className="assign-details-card-div">
                                        <div style={{}}>
                                          <span style={valstyle}>{SingleCourseDetail.course_fee}</span>

                                        </div>
                                      </Col>
                                    </> : ""}
                                </>
                                : null
                            }
                            {
                              SingleCourseDetail.certified === 1 ?
                                <>
                                  <Col md={5} className="assign-details-card-div-head">
                                    <span style={leftmargin}>Cretification</span>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    <div >
                                      <span style={valstyle}>Certified</span>
                                    </div>
                                  </Col>
                                </>
                                :
                                ''
                            }

                            {
                              role === 'superAdmin' || role === 'principal' ?
                                <>

                                  <Col md={5} className="assign-details-card-div-head" >
                                    <span style={{ marginTop: '15px', color: 'olivedrab' }}>Edit Request Status</span>
                                    <Tooltip arrow title='Edit request status of an approved course'>
                                      <InfoOutlinedIcon fontSize='small' />
                                    </Tooltip>
                                  </Col>

                                  <Col md={7} className="assign-details-card-div">
                                    <span style={valstyle}>{Course_Edit_status}</span>
                                  </Col>


                                  <Col md={5} className="assign-details-card-div-head" >
                                    <span style={{ marginTop: '15px', color: 'olivedrab' }}>Edited Request Status</span>
                                    <Tooltip arrow title='Request status of new updations'>
                                      <InfoOutlinedIcon fontSize='small' />
                                    </Tooltip>
                                  </Col>
                                  <Col md={7} className="assign-details-card-div">
                                    <span style={valstyle}>{Course_Edited_status}</span>
                                  </Col>

                                </>
                                : ''
                            }

                            {TagLabelCheck()}

                            <Col xs={12}>
                              {
                                course_tags.map((val, ky) => {
                                  return (
                                    <Chip
                                      key={ky}
                                      size="small"
                                      label={val.tag_name}
                                      color="primary"
                                      variant='outlined'
                                      style={{ margin: '3px 0px 0px 3px' }}
                                      onClick={() => { history.push(`/categories/${val.tag_id}`) }}
                                    />
                                  )
                                })
                              }
                            </Col>
                            {role === 'student' && user_details.skl_user_id !== undefined && Object.keys(CourseAssesmentDetails).length > 0 ? 
                            <div className="assign-details-card-div-head col-11 d-flex justify-content-end px-0 py-0">
                              <button
                               className={(((SingleCourseDetail.ondemand_classes.reduce((accu, val) => accu + val.percentage, 0)) / (SingleCourseDetail.ondemand_classes.length * 100)) * 100)===100 ?"btn btn-primary":"btn btn-primary opac-view"} 
                               disabled ={(((SingleCourseDetail.ondemand_classes.reduce((accu, val) => accu + val.percentage, 0)) / (SingleCourseDetail.ondemand_classes.length * 100)) * 100)===100 ? true : false}
                               onClick={()=>{redirecttoQuiz()}}> 
                               {user_details.skl_user_id !== undefined &&  CourseAssesmentDetails.IsRetake ?  "Retake Final Assessment" : "Final Assessment"}
                              </button>
                            </div>
                            :''
                            }
                            
                          </Row>
                        </div>
                      </Col>

                      <Col sm="12" md="5">
                        <Row>
                          <Col sm={12}>

                            <div style={{ backgroundColor: '#ffffff', height: '580px', width: '100%', border: '1px solid #d1d7dc', borderRadius: '4px', boxSizing: 'border-box', wordWrap: 'break-word', padding: '15px 0px 20px 0px', }}>
                              <span style={{ marginLeft: '10px', color: '#1c1d1f', fontWeight: '600', letterSpacing: '-.02rem', fontSize: '1.4rem' }}>
                                Media Content
                              </span>
                              <hr />

                              <div style={{ height: '90%', width: '100%', boxSizing: 'border-box', padding: '0px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <Tooltip arrow title='View Banner'>
                                  <div style={{ width: '100%', height: '200px', borderRadius: '10px', border: '1px solid #E6E6FA', cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={Viewimage}>
                                    <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center' }}>
                                      <span style={{ marginLeft: '10px', }} className="assign-details-card-div-head">
                                        Course Logo
                                      </span>
                                      <hr />
                                    </div>

                                    <div style={{ width: '95%', height: '160px', display: 'flex', justifyContent: 'center' ,margin:'auto'}}>
                                      <img src={SingleCourseDetail.banner_url ? SingleCourseDetail.banner_url : coursedummy} width='70%' height='auto' />
                                    </div>
                                  </div>
                                </Tooltip>

                                <Tooltip arrow title='View Preview Video'>
                                  <div style={{ backgroundColor: '#f7f9fa', minHeight: '250px', width: '100%', }}>
                                    {
                                      video.length > 0 ?
                                        <VideoCard
                                          type="playlist"
                                          title={'Preview Video'}
                                          thumbnile={thumblink}
                                          duration={dur}
                                          openVideo={openVideo}
                                          embed={frstvideo.embed}
                                          processing={SingleCourseDetail.processing}
                                          camera_tag_url={SingleCourseDetail.camera_tag_url}
                                        /> :
                                        ''
                                    }
                                  </div>
                                </Tooltip>
                              </div>

                            </div>

                          </Col>
                        </Row>

                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <div style={{ backgroundColor: '#ffffff', height: 'auto', width: '100%', border: '1px solid #d1d7dc', borderRadius: '4px', boxSizing: 'border-box', marginTop: '10px', wordWrap: 'break-word', padding: '15px 0px 20px 0px', }}>
                          <span style={{ marginLeft: '10px', color: '#1c1d1f', fontWeight: '600', letterSpacing: '-.02rem', fontSize: '1.4rem' }}>
                            Course Summary
                          </span>
                          <hr />
                          <ul>
                            <Row className="padd-2 wid100-marg-auto">
                              {
                                SingleCourseDetail ? SingleCourseDetail.learning_contents ? SingleCourseDetail.learning_contents.length > 0 ? SingleCourseDetail.learning_contents.map((val, ky) => {
                                  return (
                                    <Col xs={12} sm={6}>
                                      <li key={ky} style={{ color: 'grey', lineHeight: '23px', fontSize: '13px' }}>{val}</li>
                                    </Col>
                                  )
                                })
                                  : <span style={requiredvalstyle}>*Required</span>
                                  : <span style={requiredvalstyle}>*Required</span>
                                  : <span style={requiredvalstyle}>*Required</span>

                              }
                            </Row>
                          </ul>
                        </div>
                      </Col>
                    </Row>

                    <Row className="pt-4">
                      <Col sm={12}>
                        <Card>
                          <CardHeader style={{}} >Course Description</CardHeader>

                          {
                            SingleCourseDetail.description
                              ? SingleCourseDetail.description.length > 8 ?
                                <div
                                  style={{
                                    minHeight: "250px",
                                    maxHeight: "500px",
                                    overflowY: "scroll",
                                    padding: "10px",
                                  }}
                                  dangerouslySetInnerHTML={{ __html: SingleCourseDetail.description }}
                                />
                                :
                                <div style={{
                                  minHeight: "250px",
                                  maxHeight: "500px",
                                  overflowY: "scroll",
                                  padding: "10px",
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'red'
                                }}>
                                  <span style={requiredvalstyle}>*Required</span>
                                </div>

                              : <div style={{
                                minHeight: "250px",
                                maxHeight: "500px",
                                overflowY: "scroll",
                                padding: "10px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'red'
                              }}>
                                <span style={requiredvalstyle}>*Required</span>
                              </div>
                          }

                        </Card>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <Index data={SingleCourseDetail} tabval={liveclass_length === 0 ? "3" : "1"} />
                      </Col>
                    </Row>

                    {
                      role === 'superAdmin' || role === 'principal' ?
                        <>
                          {
                            reasons.length > 0 ?
                              <Row>
                                <Col sm="12">
                                  <Card className="card-hover-shadow-2x mb-3">
                                    <CardHeader className="card-header-tab">
                                      <div
                                        className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-lock icon-gradient bg-malibu-beach"> </i>
                                        Reasons for Rejection
                                      </div>
                                    </CardHeader>
                                    <div className="scroll-area-lg" style={{ height: 'auto' }}>
                                      <PerfectScrollbar>
                                        <div className="p-2">
                                          <ListGroup className="todo-list-wrapper" flush>
                                            {
                                              reasons.map((val, ky) => {
                                                if (val.reason) {
                                                  return (
                                                    <ListGroupItem key={ky}>
                                                      <div className="todo-indicator bg-danger" />
                                                      <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">

                                                          <div className="widget-content-left">
                                                            <div className="widget-heading" style={{ textTransform: 'uppercase' }}>
                                                              {
                                                                val.type === 'banner' ?
                                                                  'Course Logo' :
                                                                  val.type
                                                              }

                                                            </div>
                                                            <div>
                                                              <div>
                                                                {val.reason}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </ListGroupItem>
                                                  )
                                                }
                                                else {
                                                  return (
                                                    null
                                                  )
                                                }

                                              })
                                            }
                                          </ListGroup>
                                        </div>
                                      </PerfectScrollbar>
                                    </div>
                                  </Card>
                                </Col>
                              </Row>
                              : ''
                          }

                          {/* accept or reject course */}
                          {
                            // role==='superAdmin' && (SingleCourseDetail.edit_request_status===null ||SingleCourseDetail.edit_request_status==='rejected' ) && SingleCourseDetail.edited_request_status!=='requested' ? 
                            role === 'superAdmin' && SingleCourseDetail.edit_request_status !== 'requested' && SingleCourseDetail.edited_request_status !== 'requested' ?

                              <Row>
                                <Col sm={12} style={{ marginTop: '10px' }}>

                                  <Row>
                                    <Col sm={12} md={4}>

                                      <LaddaButton
                                        className="mb-2 mr-2 btn btn-primary"
                                        loading={accept_loader}
                                        type="button"
                                        onClick={() => handleAccept()}
                                        data-style={ZOOM_IN}
                                      >
                                        Accept Course
                                      </LaddaButton>

                                      <Button className="mb-2 mr-2 btn-hover-shine" color="danger" onClick={() => { RejectModel(reasons) }}>Reject Course</Button>

                                    </Col>
                                  </Row>

                                </Col>
                              </Row>
                              :
                              ""
                          }

                        </>
                        :
                        null
                    }

                    {/* accept or reject edit request */}
                    {
                      role === 'superAdmin' && SingleCourseDetail.edit_request_status === 'requested' && SingleCourseDetail.edit_status === 0 ?
                        <Row>
                          <Col sm={12} style={{ marginTop: '10px' }}>
                            <LaddaButton
                              className="mb-2 mr-2 btn btn-primary"
                              loading={accept_loader}
                              type="button"
                              onClick={() => handleAcceptCourseEdit()}
                              data-style={ZOOM_IN}
                            >
                              Accept Edit Request
                            </LaddaButton>

                            <Button className="mb-2 mr-2 btn-hover-shine" color="danger" onClick={() => { RejectModel(reasons) }}>Reject Edit Request</Button>
                          </Col>
                        </Row>
                        :
                        ''
                    }

                    {/* accept or reject edit approval request */}
                    {
                      role === 'superAdmin' && SingleCourseDetail.edited_request_status === 'requested' && SingleCourseDetail.edit_status === 1 ?
                        <Row>
                          <Col sm={12} style={{ marginTop: '10px' }}>
                            <LaddaButton
                              className="mb-2 mr-2 btn btn-primary"
                              loading={accept_loader}
                              type="button"
                              onClick={() => handleAcceptCourseEditApproval()}
                              data-style={ZOOM_IN}
                            >
                              Approve Updates
                            </LaddaButton>

                            <Button className="mb-2 mr-2 btn-hover-shine" color="danger" onClick={() => { RejectModel(reasons) }}>Reject Updates</Button>
                          </Col>
                        </Row>
                        :
                        ''
                    }

                    {/* request course */}
                    {
                      role === 'principal' && (SingleCourseDetail.request_status === "unrequested" || SingleCourseDetail.request_status === "rejected") ?

                        <Row>
                          <Col sm={12} style={{ marginTop: '10px' }}>

                            <Row>
                              <Col sm={12} md={6}>
                                <LaddaButton
                                  className="mb-2 mr-2 btn btn-primary"
                                  loading={Load}
                                  type="button"
                                  onClick={handleRequest}
                                  data-style={ZOOM_IN}
                                >
                                  Request For Approval
                                </LaddaButton>

                                <LaddaButton
                                  className="mb-2 btn btn-danger"
                                  type="button"
                                  onClick={() => { history.push('/dashboard/editCourse/' + params.course_id) }}
                                  data-style={ZOOM_IN}
                                >
                                  Edit Course
                                </LaddaButton>

                              </Col>

                            </Row>

                          </Col>
                        </Row>
                        : null
                    }

                    {/* request course edit */}
                    {
                      role === 'principal' && SingleCourseDetail.request_status === "approved" && SingleCourseDetail.edit_status === 0 && SingleCourseDetail.edit_request_status !== "requested" ?
                        <Tooltip arrow title='Make a request for edit this approved course'>
                          <LaddaButton
                            className="mb-2 mr-2 btn btn-primary"
                            loading={Load}
                            type="button"
                            onClick={CourseEditRequest}
                            data-style={ZOOM_IN}
                          >
                            Request For Edit
                          </LaddaButton>
                        </Tooltip>
                        :
                        ''
                    }

                    {/* request for approvel of update  */}
                    {
                      role === 'principal' && SingleCourseDetail.request_status === "approved" && SingleCourseDetail.edit_status === 1 && SingleCourseDetail.edit_request_status !== 'requested' && SingleCourseDetail.edited_request_status !== "requested" ?
                        <Tooltip arrow title='Make a request to get approval for new updates'>
                          <LaddaButton
                            className="mb-2 mr-2 btn btn-primary"
                            loading={Load}
                            type="button"
                            onClick={CourseEditRequestApproval}
                            data-style={ZOOM_IN}
                          >
                            Request For Edited Approval
                          </LaddaButton>
                        </Tooltip>
                        :
                        ''
                    }


                    <Model
                      open={editcoursecommision}
                      handleClose={CloseModel}
                      title='Update Commission'
                    >
                      <Row>
                        {/* <Col xs={6} style={{textAlign:'center'}}>
                      <Label>
                      <Input type='radio' onChange={Radiochange} checked={value==='rate'} value='rate'/> 
                        Rate
                      </Label>
                    </Col> */}
                        {/* <Col xs={6}>
                      <Label>
                      <Input type='radio' onChange={Radiochange} checked={value==="percentage"} value="percentage"/> 
                      Percentage
                      </Label>
                    </Col> */}
                      </Row>
                      <Row>
                        <Col xs={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Commission in {value}
                          </Label>
                        </Col>
                        <Col xs={12}>
                          <Input
                            max={value === "percentage" ? 100 : ""}
                            min={0}
                            type="number"
                            style={{ width: "100%" }}
                            id="commission"
                            name="commission"
                            value={commissionvalue}
                            onChange={Editcommission}
                          //   invalid={errors.deadlineTime}
                          />
                        </Col>
                        <Col xs={12} style={{ marginTop: '5px' }}>
                          <LaddaButton className=" btn btn-primary"
                            loading={commission_loader}
                            type="button"
                            onClick={() => submitCommission()}
                            data-style={ZOOM_IN}
                          >
                            Submit
                          </LaddaButton>
                        </Col>
                      </Row>


                    </Model>

                    <Model2
                      open={reject_reasons_model ? reject_reasons_model : false}
                      handleClose={CloseRejectModel}
                      title='Reason for Reject'
                    >
                      <Rejectcourse />
                    </Model2>



                    <Model
                      open={nowPlaying.open}
                      handleClose={ClosePreview}
                      size='md'
                      title={'Preview Video'}
                    >
                      {
                        nowPlaying.iframe || nowPlaying.camera_tag_url ?
                          <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                            {
                              CameratagOrNot(nowPlaying.camera_tag_url,nowPlaying.iframe)
                            }
                          </div>
                          :
                          <div style={{ width: '100%', height: '100%' }}>
                            <img src={previewdummy} alt='no video' width='100%' height='100%' />
                          </div>
                      }


                    </Model>

                  </Container>
                  <Model
                    open={imageview ? imageview : false}
                    handleClose={CloseImage}
                    title='View Banner'
                  >
                    <div style={{ width: '100%', height: 'auto', borderRadius: '10px', display: 'flex', justifyContent: 'center' }}>
                      <img src={SingleCourseDetail.banner_url ? SingleCourseDetail.banner_url : coursedummy} width='267px' height='auto' style={{ borderRadius: '10px' }} />
                    </div>
                  </Model>


                  <Model
                    open={enroll}
                    handleClose={CloseEnrollmentedit}
                    title='Update Enrollment End Date'
                  >
                    <Row>
                      <Col xs={12} className="assign-form-grid">
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span>
                          Enroll To
                        </Label>
                      </Col>
                      <Col xs={12}>
                        <DatePicker
                          selected={enroll_end_date}
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                          withPortal
                          onChange={(e) => handleChangeFormDate(e)}
                          minDate={new Date(SingleCourseDetail.enrollment_start_date)}
                          maxDate={new Date(SingleCourseDetail.course_end_date)}
                          renderCustomHeader={({
                            date,
                            decreaseMonth,
                            increaseMonth,
                            increaseYear,
                            decreaseYear,

                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center"
                              }}
                            >
                              <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                {"<<"}
                              </Button>
                              <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </Button>

                              <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                              <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                              </Button>
                              <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                {">>"}
                              </Button>
                            </div>
                          )}

                        />
                      </Col>
                      <Col xs={12} style={{ marginTop: '5px' }}>
                        <LaddaButton className=" btn btn-primary"
                          // loading={commission_loader}
                          type="button"
                          //  onClick={() => submitEnroll()}
                          data-style={ZOOM_IN}
                        >
                          Update
                        </LaddaButton>
                      </Col>
                    </Row>


                  </Model>

                  <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={successModal}
                    text="Completed successfully"
                    type="success"
                    onConfirm={() => setSuccessModal(false)} />


                  <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={successreject}
                    text="Submitted successfully"
                    type="success"
                    onConfirm={handleCloseSuccesstModalreason} />

                </div>
            }
          </div>

      }

    </Fragment>

  );
}

export default CouseFulldetails;
