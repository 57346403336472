import React,{useState,useEffect} from 'react';

import {
    TabContent, TabPane,
    Row, Col, CardHeader,
    Card, CardBody,
    Button, ButtonGroup, Container,Badge,Input,FormGroup,Label
  } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import classnames from 'classnames';
import Table from "./table"
import { useHistory } from "react-router-dom";
import Model from "../Vendor/Model"
import LaddaButton, { ZOOM_IN } from 'react-ladda';

import { getAllPendingTickets,getAllInProgressTickets,getAllResolvedTickets,UpdateTicketStatus} from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';


function TicketList(){

    const [tab,setTab]=useState("1")
    const [Open,setOpen]=useState(false)
    const history=useHistory()
    const dispatch=useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [successModal, setSuccessModal] = useState(false);


    const ticket_pending_loader = useSelector((state) =>state.superadmin.ticket_pending_loader ? state.superadmin.ticket_pending_loader : '');
    const ticket_inprogress_loader = useSelector((state) =>state.superadmin.ticket_inprogress_loader ? state.superadmin.ticket_inprogress_loader : '');
    const ticket_resolved_loader = useSelector((state) =>state.superadmin.ticket_resolved_loader ? state.superadmin.ticket_resolved_loader : '');
    const pendingArray = useSelector((state) =>state.superadmin.pendingArray ? state.superadmin.pendingArray : []);
    const progressArray = useSelector((state) =>state.superadmin.progressArray ? state.superadmin.progressArray : []);
    const resolvedArray = useSelector((state) =>state.superadmin.resolvedArray ? state.superadmin.resolvedArray : []);
 
    const pending_count = useSelector((state) =>state.superadmin.pending_count ? state.superadmin.pending_count : 0);
    const progress_count = useSelector((state) =>state.superadmin.progress_count ? state.superadmin.progress_count : 0);
    const resolved_count = useSelector((state) =>state.superadmin.resolved_count ? state.superadmin.resolved_count : 0);
    const status_update_loader = useSelector((state) =>state.superadmin.status_update_loader ? state.superadmin.status_update_loader : '');

    
    useEffect(()=>{
        dispatch(getAllPendingTickets(tokenData.token))
    },[])
    const toggle=(val)=> {
        
        if (tab !== val) {
            if(val==='1'){
                dispatch(getAllPendingTickets(tokenData.token))
            }
            else if(val==='2'){
                dispatch(getAllInProgressTickets(tokenData.token))
            }
            else{
                dispatch(getAllResolvedTickets(tokenData.token))
            }
            setTab(val)
        }
    }

const columns=[
    { title: 'Order Id', field: 'order_id' },
    { title: 'Student', field: 'student_name' },
    { title: 'Issued', field: 'issued_at' },
    { title: 'Course', field: 'course' },
    { title: 'Status', field: 'status',
    render: (rowData) => {
        return rowData.status === 'Pending' ? (
          <Badge color="danger" pill>
            {"Pending"}
          </Badge>
        ) : rowData.status === 'Inprogress' ? (
          <Badge color="warning" pill>
            {"Inprogress"}
          </Badge>
        ) : (
          <Badge color="success" pill>
            {"Resolved"}
          </Badge>
        );
      },
    }
    
]    
    

const action = "Ticket_table"

const Ticketview=(val)=>{
    history.push('/dashboard/ticketdetails/' + val.ticket_id)
}

const [ticket_id,setTicketId]=useState()
const [ticket_status,setTicketStatus]=useState()

const Changestatus=(val)=>{
    setTicketId(val.ticket_id)
    setTicketStatus(val.status)
    setOpen(true)
}

const CloseTicket=()=>{
    setOpen(false)
}

const Submitstatus=(e)=>{
    if(e.target.value !=='select'){
        setTicketStatus(e.target.value)
    }
}

const Submit=()=>{
    dispatch(UpdateTicketStatus(tokenData.token,ticket_id,ticket_status,handleOpenSuccessModal)) 
}

const handleOpenSuccessModal = () => {
    setOpen(false)
    dispatch(getAllPendingTickets(tokenData.token))
    dispatch(getAllInProgressTickets(tokenData.token))
    dispatch(getAllResolvedTickets(tokenData.token))
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
  
    // dispatch(getAllPendingTickets(tokenData.token))
    // dispatch(getAllInProgressTickets(tokenData.token))
    // dispatch(getAllResolvedTickets(tokenData.token))
    setSuccessModal(false)
    // setOpen(false)
}
    return(
        <>
            <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
            >
                <Container>
                    <Row>
                    <Col xs="12" sm="4">
                    <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-danger border-danger">
                                <div className="widget-chat-wrapper-outer">
                                    <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                            Pending
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div>
                                                    {pending_count}
                                                </div>
                                                <div className="ml-auto">
                                                    <div className="icon-wrapper rounded-circle">
                                                        <div className="icon-wrapper-bg opacity-9 bg-danger"/>
                                                            <i className="lnr-enter text-white"/>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="4">
                        <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning border-warning">
                                <div className="widget-chat-wrapper-outer">
                                    <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                           In Progress
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div>
                                                    {progress_count}
                                                </div>
                                                <div className="ml-auto">
                                                    <div className="icon-wrapper rounded-circle">
                                                        <div className="icon-wrapper-bg opacity-10 bg-warning"/>
                                                            <i className="lnr-hourglass text-dark opacity-8"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs="12" sm="4">
                        <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-success border-success">
                                <div className="widget-chat-wrapper-outer">
                                    <div className="widget-chart-content">
                                        <div className="widget-title opacity-5 text-uppercase">
                                            Resolved
                                        </div>
                                        <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                            <div className="widget-chart-flex align-items-center">
                                                <div>
                                                    {resolved_count}
                                                </div>
                                                <div className="ml-auto">
                                                <div className="icon-wrapper rounded-circle">
                                                    <div className="icon-wrapper-bg opacity-9 bg-success"/>
                                                        <i className="lnr-checkmark-circle text-white"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                        <Card className="main-card mb-3">
                            <CardHeader>
                                <i className="header-icon lnr-tag icon-gradient bg-mixed-hopes"> </i>
                                Tickets
                                <div className="btn-actions-pane-right">
                                    <ButtonGroup size="sm">
                                        <Button color="focus"
                                        className={"btn-pill pl-3 " + classnames({active: tab === '1'})}
                                        onClick={() => {
                                            toggle('1');
                                        }}
                                        >
                                        Pending
                                        </Button>
                                        <Button color="focus"
                                        className={classnames({active: tab=== '2'})}
                                        onClick={() => {
                                            toggle('2');
                                        }}
                                            >
                                            Inprogess
                                        </Button>
                                        <Button color="focus"
                                        className={"btn-pill pr-3 " + classnames({active: tab === '3'})}
                                        onClick={() => {
                                            toggle('3');
                                        }}
                                        >
                                            Resolved
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <TabContent activeTab={tab} className="main-card mb-3">
                                    <TabPane tabId="1" className="main-card mb-3">
                                        <Table
                                            actions={action}
                                            data={pendingArray}
                                            title={'Pending'}
                                            columns={columns}
                                            Ticketdetails={Ticketview}
                                            Statuschange={Changestatus}
                                            loader={ticket_pending_loader}

                                        />                                                        
                                    </TabPane>
                                                                            
                                    <TabPane tabId="2">
                                        <Table
                                            actions={action}
                                            data={progressArray}
                                            title={'Inprogress'}
                                            columns={columns}
                                            Ticketdetails={Ticketview}
                                            loader={ticket_inprogress_loader}
                                            Statuschange={Changestatus}

                                        /> 
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Table
                                            actions={action}
                                            data={resolvedArray}
                                            title={'Resolved'}
                                            columns={columns}
                                            Ticketdetails={Ticketview}
                                            loader={ticket_resolved_loader}
                                            Statuschange={Changestatus}

                                        /> 
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                            
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </CSSTransitionGroup>


            <Model
                open={Open}
                handleClose={CloseTicket}
                title={'Ticket Status'}
            >   
                <Container>
                    <Row>
                        <Col sm={12}>
                        <FormGroup>
                            <Label for="exampleSelect">Change Status</Label>
                            <Input type="select" name="select" id="exampleSelect" onChange={Submitstatus}>
                                <option value='select'>Select</option>
                                <option value='Inprogress'>In Progress</option>
                                <option value='Resolved'>Resolved</option>
                            </Input>
                        </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                    <Col xs={12}>
                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                            loading={status_update_loader}
                            type="button"
                            onClick={() => Submit()}
                            data-style={ZOOM_IN}
                        >
                            Submit
                        </LaddaButton>
                    </Col>
                    </Row>
                </Container>
            </Model>

            <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Status Updated successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />
            </>
    )
}
export default TicketList