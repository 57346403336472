import React,{useEffect,useState} from 'react'
import { getSingleCourseDetails,setHomeData } from "../../../../redux/Actions/genexhome"
import { useDispatch } from "react-redux";
import { useHistory,useParams } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay'
import { Loader, } from 'react-loaders';
import { Redirect } from "react-router-dom";
// import { logout } from '../../../../redux/Actions/auth'

export default function DashboardCourseRedirect() {

    const params = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const [check,setCheck]=useState(false)

    const course_id=params.course_id
    const token=params.token

    useEffect(()=>{
        dispatch(getSingleCourseDetails(params.token,params.course_id,cb))
    },[])

    const cb=(val)=>{
        if(val){
            dispatch(setHomeData('check_api_call',false))
            setCheck(true)
        }
        else{
            // dispatch(LogoutSession(params.token))
            localStorage.removeItem("authDetails");
            history.push('/')
        }
    }

    return (
        <LoadingOverlay tag="div" active={true}
        styles={{
            overlay: (base) => ({
                ...base,
                background: '#fff',
                opacity: 0.5
            })
        }}
        spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
        <div style={{width:'100%',height:'100vh'}}>
            {
                check ?
                <Redirect to={`/dashboard/course/${course_id}/details/${token}`}/>
                :
                ''
            }
        </div>
        </LoadingOverlay>
    )
}
