import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import LaddaButton, { ZOOM_IN } from "react-ladda";
import serialize from "form-serialize";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Loader from "react-loaders";
import moment from "moment";
import ChooseFromPlaylist from "../common/chooseFromPlaylist";
import Swal from "sweetalert2";
import VideoClass from "../VideoClass";
import {
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Label,
  CardHeader,
  Input,
  Button
} from "reactstrap";
import {
  populatePlayListInClass,
  getPlayListVideos,
  createVideoClass,
  setPlayListData,
} from "../../../redux/Actions/playlist";
import { setReqData } from "../../../redux/Actions/Superadmin"
import "../Playlist/playlist.scss";
import { useParams } from 'react-router-dom'
import processing_thumnile from "../../../assets/images/processing_thumnile.jpg"



const Index = () => {
  const dispatch = useDispatch();
  const textInputRef = useRef(null);
  const params = useParams()

  const [open, setOpen] = useState({id:'',open_model:false});
  // const [desc, setDesc] = useState('');
  const [chk, setChk] = useState(true);
  // const [percentLimit, setPercentLimit] = useState(0);
  const [check, setCheck] = useState({percentage:false,description:false});
 
  
  useEffect(() => {
    // dispatch(populatePlayListInClass(tokenData.token, 243,params.course_id));
    

    return () => {
      dispatch({ type: "RESET_PLAYLIST_DETAILS" });
      dispatch(setReqData('video_desc', []))
    };
  }, []);

  const video_desc = useSelector((state) => state.superadmin.video_desc ? state.superadmin.video_desc : []);
  // const default_date = useSelector((state) => state.superadmin.default ? state.superadmin.default : false);
  // const first_arr = useSelector((state) => state.superadmin.first_arr ? state.superadmin.first_arr : {});
  // const sec_arr = useSelector((state) => state.superadmin.sec_arr ? state.superadmin.sec_arr : {});

  
  const playlistStore = useSelector(({ playlist }) => playlist);
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const getVideos = (playlist_id) => {
    setSelectedPlaylist(playlist_id);
    setMultiSelect([]);
    dispatch(getPlayListVideos(tokenData.token, playlist_id));
  };
  const handleOrder = (id) => {
    setPlaylistDetails({ ...palylistDetails, [id]: id });
  };
  
  const handlePercentage = (id) => {
    setPlaylistDetails({ ...palylistDetails, [id]: id });
  };

  const handleTime = (value,id) => {
    
    const date = id.split("-");
    let keyName = date[1];
    if (selectedId[keyName]) {
      if (!selectedId[keyName].includes(id)) {
        selectedId[keyName].push(id);
      }
    }
    setPlaylistDetails({ ...palylistDetails, [id]: value });
  };


  const [mutliSelect, setMultiSelect] = useState([]);
  const [playlistsVideoId, setPlaylistsVideoId] = useState([]);
  const [palylistDetails, setPlaylistDetails] = useState({ Details: [] });
  
  const [selectedId, setSelectedId] = useState({});
  const [selectedIdOrigin, setSelectedIdOrigin] = useState({});

  const multiSelectVideo = (video_id, playlists_video_id, thumbnile, index,processing) => {

    if (processing) {
      Swal.fire({
        icon: "warning",
        title: "Processing",
        text: "you can't choose this video,because its on process",
      });
    } else {
      if (playlistsVideoId.includes(playlists_video_id)) {
        const filtered = playlistsVideoId.filter(
          (videoId) => videoId !== playlists_video_id
        );
        setPlaylistsVideoId(filtered);

        delete selectedId[index];

      } else {
        setPlaylistsVideoId([...playlistsVideoId, playlists_video_id]);
        setSelectedId({ ...selectedId, [index]: [] });
        
      }

      if (mutliSelect.includes(video_id)) {
        const filtered = mutliSelect.filter((videoId) => videoId !== video_id);
        setMultiSelect(filtered);
      } else {
        setMultiSelect([...mutliSelect, video_id])
      }
    }


  }
  
  const [syncVideo, setSyncVideo] = useState([]);

  const handleClickSync = (video_id) => {
    if (syncVideo.includes(video_id)) {
      const filtered = syncVideo.filter((videoId) => videoId !== video_id);
      setSyncVideo(filtered);
    } else {
      setSyncVideo([...syncVideo, video_id]);
    }
  }

  const [dateVideo, setDateVideo] = useState({});

  const handleDate = (val,id) => {
    
    const value = moment(val).format("YYYY-MM-DD");
    const date = id.split("-");
    
    let keyName = date[1];

    if (selectedId[keyName]) {
      if (!selectedId[keyName].includes(id)) {
        selectedId[keyName].push(id);
      }
    }
    if (date[0] === "fromDate") {
      

    
      if (`toDate-${date[1]}` in dateVideo) {
        

        if (moment(value).isAfter(dateVideo[`toDate-${date[1]}`], "day")) {
          document.getElementById(id).value = "";
          Swal.fire({
            icon: "error",
            title: "Invalid Date",
            text: "From date should  be less than to date !..",
          });
        } else {
          setDateVideo({ ...dateVideo, [id]: value });
        }
      } else {
        setDateVideo({ ...dateVideo, [id]: value });
      }
    } else if (date[0] === "toDate") {
      if (`fromDate-${date[1]}` in dateVideo) {
        

        if (moment(dateVideo[`fromDate-${date[1]}`]).isAfter(value, "day")) {
          Swal.fire({
            icon: "error",
            title: "Invalid Date",
            text: "To date should  be grater than from date !..",
          });

          document.getElementById(id).value = "";
        } else {
          setDateVideo({ ...dateVideo, [id]: value });
        }
      } else {
        setDateVideo({ ...dateVideo, [id]: value });
      }
    }

  };


  // const canCallApi = (_selectedId) => {
  //   if(defaultval===false && check.datetime){
  //     for (let key in _selectedId) {
  //       if (_selectedId[key].length < 4) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   }
  //   else{
  //     return(true)
  //   }
    
  // };


  const formSubmit = (e) => {
    e.preventDefault();

    // let arr=[]
    // playlistStore.playlistVideos.forEach(element => {
    //   let a=element.video_id
    //   arr.push(a)
    // });
    
    // let video_desc_arr=[...video_desc]
    // for(let i=0 ; i < arr.length ; i++){
    //   var val = video_desc_arr.findIndex(rank => rank.video_id === arr[i]);
    //   if(val!==-1){
    //     original_array.push(video_desc_arr[val].description)

    //   }
    // }
      
    setSelectedIdOrigin(selectedId);
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    
    if(check.description){
      body.description_boolean=1
    }
    else{
      body.description_boolean=0
    }
    if(check.percentage===false){
      body.percentage_boolean=0
    }
    else{
      body.percentage_boolean=1
    }

    let invalid;
    
    if(body.title===''){
      invalid=true
    }
    
   
    if (!invalid) {
      
      if (selectedPlaylist) {
        // if (canCallApi(selectedId)) 
        // {
          dispatch(createVideoClass(tokenData.token, body, resetForm));
        // }
        // else{
        //   Swal.fire({
        //     icon: "warning",
        //     // title: "Select playlist",
        //     text:'fill all fields of selected videos'
        //   });
        // }

      } else {
        Swal.fire({
          icon: "warning",
          title: "Select playlist",
        });
      }
    } else {
    
      
      Swal.fire({
        icon: "warning",
        title: "Fill Title Field",
      });
    }

  };

  const resetForm = () => {
    dispatch(populatePlayListInClass(tokenData.token, 243,params.course_id));
    setSelectedPlaylist("");
    document.getElementById("title").value='';
    document.getElementById("decscription").value='';
    setMultiSelect([]);
    setPlaylistsVideoId([]);
    setOpen({id:'',open_model:false})
    dispatch(setPlayListData('playlistVideos', []))
    setCheck({percentage:false,description:false})
    setChk((prev)=>!prev)
  };

  const Checkbox=(e,type)=>{
    setCheck({...check,[type]:e.target.checked})
  
  }
 
  

return (
    <>
     

      <form onSubmit={formSubmit} id="create_video_class">
        
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                Title
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <Label for="exampleCustomSelectDisabled"><span style={{color:'red'}}>*</span>Video Class Title</Label>
                  </Col>
                  <Col xs={12}>
                    <Input type='text' name='title'  id='title'/>
                  </Col>

                  <Col xs={12}>
                    <Input type='hidden' value='' rows={2} name='description'  id='decscription'/>
                  </Col>
                  
                  <Col xs={12} sm={6} style={{marginTop:'20px'}}>
                    <Input
                      type='checkbox'
                      onChange={(e)=>{Checkbox(e,'description')}}
                      name='description_boolean'
                      checked={check.description ? 1 :0}
                      style={{marginLeft:'30px'}}
                    />
                    <span style={{marginLeft:'50px'}}>Description</span>
                  </Col>
                  {/* <Col xs={12} sm={6} style={{marginTop:'20px'}}>
                    <Input
                        type='checkbox'
                        onChange={(e)=>{Checkbox(e,'percentage')}}
                        name='percentage_boolean'
                        value={check.percentage ? 1 :0}
                        style={{marginLeft:'30px'}}
                      />
                      <span style={{marginLeft:'50px'}}>Percentage Limit</span>

                    </Col> */}
                 
                  
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        

        {playlistStore.playlistSchool.length > 0 ? (
        // {playlistStore.classPlaylist.length > 0 ? (
          <Card style={{ padding: "10px 20px" }}>
            <Row className="jms-create-video-session">
              <Col md={6}> Playlist </Col>
              <Col md={6}> Videos</Col>
              
            </Row>
            <Row>
              <input
                type="hidden"
                value={selectedPlaylist}
                name="playlist_id"
              />

              <Col xs={12} sm={4} className="jms-choose-from-col-title">
                {playlistStore.playlistSchool.map((playlist) => {
                  return (
                    <div
                      className={`jms-choose-from-title ${
                        selectedPlaylist === playlist.playlist_id
                          ? "jms-active-playlist"
                          : null
                        }`}
                      onClick={() => getVideos(playlist.playlist_id)}
                    >
                      {playlist.playlist_name}
                    </div>
                  );
                })}
              </Col>

              <Col xs={12} sm={8} className="jms-choose-from-col-videos">
                {playlistStore.playlistVideos.length > 0 ? (
                  playlistStore.playlistVideos.map((video, index) => {
                    
                    return (
                      <ChooseFromPlaylist
                        // thumbnile={video.thumbnails[2].link}
                        thumbnile={video.processing ? processing_thumnile:video.camera_tag_thumbnail_path ? video.camera_tag_thumbnail_path:video.thumbnails[2].link}
                        type={"create_class"}
                        videoId={video.video_id}
                        playlist_video_id={video.playlist_video_id}
                        title={video.title}
                        url={video.url}
                        duration={video.video_duration}
                        format={"mp4"}
                        playlistsVideoId={playlistsVideoId}
                        handleDate={handleDate}
                        multiSelectVideo={multiSelectVideo}
                        mutliSelect={mutliSelect}
                        handleClickSync={handleClickSync}
                        syncVideo={syncVideo}
                        index={index}
                        handleOrder={handleOrder}
                        handleTime={handleTime}
                        textInputRef={textInputRef}
                        selectedIdOrigin={selectedIdOrigin}
                        check={check}
                        dateVideo={dateVideo}
                        palylistDetails={palylistDetails}
                        handlePercentage={handlePercentage}
                        processing={video.processing}
                      />
                    );
                  })
                ) : playlistStore.playlistVideosLoader ? (
                  <p className="jms-position">
                    {" "}
                    <Loader color="#f7b924" type="ball-rotate" />{" "}
                  </p>
                ) : !selectedPlaylist ? (
                  <p className="jms-position"> Select a playlist</p>
                ) : (
                        <p className="jms-position">
                          {" "}
                          <i
                            style={{ fontSize: 30 }}
                            className="lnr-warning icon-gradient bg-sunny-morning"
                          >
                            {" "}
                          </i>{" "}
                    There is no videos
                        </p>
                      )}
              </Col>
            </Row>
          </Card>
        ) : playlistStore.classPlaylistLoader ? (
          <>
            <Card style={{ padding: 20 }}>
              <Row>
                <Col md={3}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={50} count={4} />
                  </SkeletonTheme>
                </Col>
                <Col md={9}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={100} count={2} />
                  </SkeletonTheme>
                </Col>
              </Row>
            </Card>
          </>
        ) : null}

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label
                for="exampleCustomSelectDisabled"
                style={{ visibility: "hidden" }}
              >
                Subject
              </Label>

              <LaddaButton
                className="mb-2 mr-2 btn btn-danger"
                loading={playlistStore.createVideoClassLoader}
                type="submit"
                data-style={ZOOM_IN}
                style={{
                  whiteSpace: "nowrap",
                  marginRight: "0px",
                  width: "-webkit-fill-available",
                }}
                disabled={mutliSelect.length > 0 ? false:true}
              >
                CREATE CLASS
              </LaddaButton>
            </FormGroup>
          </Col>
        </Row>

        <Input name='course_id'  value={params.course_id} type='hidden'/>
        <Input name='grade_id'  value={6} type='hidden'/>
        <Input name='subject_id'  value={243} type='hidden'/>
        <Input name='category_id'  value={1} type='hidden'/>
       
      </form>
      <Row>
        <Col md={12}>
          
          <VideoClass course_id={params.course_id} control={chk} />
        </Col>
      </Row>


    </>
  );
};

export default Index;



