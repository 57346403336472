import React from 'react'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    return (
        <>
            <DataTable
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns} handleEdit
                data={props.data}
                title={props.title}
                loader={props.loader}
                ViewStudents={(id)=>{props.ViewStudents(id)}}
            />
        </>
    )
}

export default Table
