import React, { Fragment, } from "react";
import Slider from "react-slick";
import { Card,CardFooter } from 'reactstrap'
import CardBody from "reactstrap/lib/CardBody";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    // faUser,
    // faSpinner,
    // faEnvelope,
     faPhone,
    // faCalendarAlt,
    // faHome, 
    faPhoneAlt,
    faLocationArrow, 
    // faRupeeSign
  } from "@fortawesome/free-solid-svg-icons";

function MultipleItems(props) {

    const { sdata, school, handleDetailsClick } = props
 
        const settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3

            
        };
 
    return (
        <Fragment>
            <Slider {...settings}>

                {sdata.map((item) => {
                    return (
                        <div>
                            <div onClick={() => handleDetailsClick(school ? item.school_id : item.student_id)} className="slider-item">
                                <Card style={{ height: '100%' }} className={"bg-success"}>
                                    <CardBody>
                                        {/* <div style={{height:'80%'}} > */}
                                        <h5 style={{ color: 'white', fontWeight: 'bold' }}>{item.school_name || item.student_name || item.course_name || item.enterprise_name || item.name}</h5>
                                        {/* {item.area ? <h6 style={{ color: 'white', fontWeight: '50px' }}> <FontAwesomeIcon icon={faLocationArrow} />  {item.area}</h6> : ""}                 */}
                                        {/* </div> */}
                                        {/* <div style={{height:'20%'}} > */}
                                        <h6 style={{ color: 'white', fontWeight: 'lighter', fontStyle: 'italic' }}>{item.email}</h6>
                                        {item.contact_number ? <h6 style={{ color: 'white', fontWeight: 'normal' }}> <FontAwesomeIcon icon={faPhone} /> {item.contact_number}</h6> : ""}
                                        {/* </div> */}
                                    </CardBody>
                                    
                                </Card>
                            </div>
                        </div>
                    )
                }
                )
                }

                    {/* <div>
                        <div className="slider-item">1</div>
                    </div>
                    <div>
                        <div className="slider-item">2</div>
                    </div>
                    <div>
                        <div className="slider-item">3</div>
                    </div>
                    <div>
                        <div className="slider-item">4</div>
                    </div>
                    <div>
                        <div className="slider-item">5</div>
                    </div>
                    <div>
                        <div className="slider-item">6</div>
                    </div>
                    <div>
                        <div className="slider-item">7</div>
                    </div>
                    <div>
                        <div className="slider-item">8</div>
                    </div>
                    <div>
                        <div className="slider-item">9</div>
                    </div>
                    <div>
                        <div className="slider-item">10</div>
                    </div>
                    <div>
                        <div className="slider-item">11</div>
                    </div>
                    <div>
                        <div className="slider-item">12</div>
                    </div>
                 */}

            </Slider>
        </Fragment>
    );
}
export default MultipleItems;