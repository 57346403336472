import React, { useEffect, useState } from 'react'
import DataTable from '../../common/DataTable'
import { deleteClasses, validateClassTiming, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateAllEnterpriseUserClass, getTeacherAssignedGroups, populateAllUserClassTeacher } from '../../../redux/Actions/homeTeacher'
import { useDispatch, useSelector } from 'react-redux'
import Modals from '../../common/Modal'
import ViewStudent from '../ShowClass/viewStudent'
import TimeShedule from '../ShowClass/viewTime'

function SubscriptionClass() {

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(populateAllUserClassTeacher(tokenData.token, 2, 'subscription'))
    }, [])


    const [openModal, setOpenModal] = useState(false)

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const columns = [

        { title: '#', field: 'tableData.id' },
        { title: 'Subscription', field: 'subscription_name' },
        {
            title: 'Class ', field: 'class_name',

        },
        { title: ' Grade', field: 'grade_name' },
        { title: 'Subject', field: 'subject_name' },
        { title: 'From_Date', field: 'class_from_date' },
        { title: 'To Date', field: 'class_to_date' },
    ]

    let action = 'show_class'


    const [studentListModal, setStudentListModal] = useState();
    const handleOpenStudentListModal = (class_id, grade_id) => {
        if (tokenData.enterpriseUser) {
            dispatch(populateRegisteredStudents(tokenData.token, null, class_id))

        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        }
        // dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        setStudentListModal(true)
    }
    const handleCloseStudentListModal = () => {
        setStudentListModal(false)
    }

    const hadleOpenModal = (id) => {
        //alert(id)
        dispatch(getClassTiming(tokenData.token, id))
        setOpenModal(true)
    }
    
    return (
        <div>
            <DataTable
                 hadleOpenModal={(id) => hadleOpenModal(id)}
                actions={action}
                // role={props.role}
                columns={columns}
                // action={'subscription_class'}
                // handleEdit={(course_id, class_id) => props.handleEdit(course_id, class_id)}
                // handleDelete={(class_id) => props.handleDelete(class_id)}
                data={tStore.classes}
                // loader={props.loader}
                handleOpenStudentListModal={(class_id, grade_id) => handleOpenStudentListModal(class_id, grade_id)}
            // hadleOpenStudentModal={(class_id, grade_id, teacher_id, rowData) => props.hadleOpenStudentModal(class_id, grade_id, teacher_id, rowData)}
            // handleOpenEditModal={(rowData) => props.handleOpenEditModal(rowData)}

            />

            <Modals
                open={studentListModal}
                handleClose={handleCloseStudentListModal}
                title={`View student`}
            >
                <ViewStudent
                    data={tStore.specificStudent}
                    loader={tStore.specificStudentLoader}
                />
            </Modals>
            <Modals
                open={openModal}
                handleClose={handleCloseModal}

            >
                <TimeShedule
                    data={tStore.specificClassTime}
                    loader={tStore.specificClassTimeLoader}

                />
            </Modals>

        </div>
    )
}

export default SubscriptionClass
