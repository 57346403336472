import React,{useEffect} from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Breadcrumb, BreadcrumbItem } from "reactstrap";
// import { faHome } from "@fortawesome/free-solid-svg-icons";
// import cx from "classnames";
// import { Link } from "react-router-dom";

import { useParams } from 'react-router-dom'
import { setReqData } from '../../../../redux/Actions/Superadmin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Container   
} from "reactstrap";
import Table from "../table";


function VendorStudentdetails(props) {

const dispatch = useDispatch();
const params = useParams()
// const tokenData = JSON.parse(localStorage.getItem("authDetails"));
const history=useHistory()
const vendorstudentloader = useSelector((state) =>state.superadmin.vendorstudentloader ? state.superadmin.vendorstudentloader : "");

// const VendorStudentArray = useSelector((state) =>state.superadmin.VendorStudentArray ? state.superadmin.VendorStudentArray : []);
//const data=VendorStudentArray.students ? VendorStudentArray.students :[]
const data= props.data;
// console.log("data data",data)
const action='vendorstudent_details'

useEffect(() => {
       // dispatch(getAllvendorStudents(tokenData.token, params.enterprise_id))
        dispatch(setReqData('VendorCourseStudentName',''))

}, [])

const columns = [
  { title: "Name", field: "full_name" },
  { title: "Email", field: "email"},
  { title: "Phone", field: "phone" },
  { title: "Total_Courses", field: "total_courses" },
  { title: "Referral_ID", field: "genex_referral_code" },

]

const Vendorstudentdetails=(val)=>{
    dispatch(setReqData('VendorCourseStudentName',val.name))
    history.push(`/dashboard/vendor/${params.enterprise_id}/student/${val.student_id}`)
}
  return (
    <>
    <Container>
        
      <Row>
          <Col sm={12} md={12}>
            <Table
              loader={vendorstudentloader}
              actions={action}
              data={data}
              title={"Student List"}
              columns={columns}
              Vendorstudentdetails={Vendorstudentdetails}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(VendorStudentdetails);
