import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { getAllvendorStudentsCourse } from "../../../../redux/Actions/Superadmin";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "reactstrap";
import Table from "../table";

function StudentCourseDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  // const history = useHistory();

  const data = useSelector((state) =>
    state.superadmin.VendorStudentCourseArray ? state.superadmin.VendorStudentCourseArray : []
  );
  const first_name = useSelector((state) =>
    state.superadmin.first_name ? state.superadmin.first_name : ''
  );

  const last_name = useSelector((state) =>
    state.superadmin.last_name ? state.superadmin.last_name : ''
  );
  const vendorstudentcourseloader = useSelector((state) =>
    state.superadmin.vendorstudentcourseloader
      ? state.superadmin.vendorstudentcourseloader
      : ""
  );
  const VendorCourseStudentName = useSelector((state) =>
    state.superadmin.VendorCourseStudentName
      ? state.superadmin.VendorCourseStudentName
      : ""
  );

  // const VendorStudentCourseArray = useSelector((state) =>
  //   state.superadmin.VendorStudentCourseArray
  //     ? state.superadmin.VendorStudentCourseArray
  //     : []
  // );
  // const data = VendorStudentCourseArray.course
  //   ? VendorStudentCourseArray.course
  //   : [];

  useEffect(() => {
    // if (selectedvendor) {
    //   dispatch(getAllvendorStudentsCourse(tokenData.token, params.student_id));
    // } else {
    //   history.push("/dashboard/vendors");
    // }
    dispatch(getAllvendorStudentsCourse(tokenData.token, params.student_id));

  }, []);

  const columns = [
    { title: "Order_id", field: "order_id",render: (rowData) =>{
      return "#"+ rowData.order_id
    } },
    { title: "Purchase_Date", field: "purchase_date" },
    { title: "Course", field: "course_name" },
    { title: "Categoty", field: "course_category" },
    { title: "Purchased_Amount", field: "fee" },
    // { title: "Duration", field: "hour" },
  ];

  // const StudentCourseDetails = (val) => {
  //   history.push(
  //     `/dashboard/vendorstudent/${val.enterprise_id}/${val.student_id}`
  //   );
  // };
  // const Goback = () => {
  //   history.goBack();
  // };
  // const urll='/dashboard/vendors'+val.enterprise_id+val.student_id
  return (
    <>
      <Container>
        <Row>
          <Col sm={12} md={12}>
            <Breadcrumb style={{width:'100%'}}>
              <BreadcrumbItem>
                <Link to="/dashboard">
                  <FontAwesomeIcon icon={faHome} />
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to="/dashboard/vendors">Partner List</Link>
              </BreadcrumbItem>
              {/* <BreadcrumbItem>
                <Link to={{
                    pathname: `/dashboard/vendors/${params.enterprise_id}`
                  }}>
                  Vendor Details
                </Link>
                
              </BreadcrumbItem> */}
              <BreadcrumbItem active>Course Details</BreadcrumbItem>
            </Breadcrumb>
            <div
              className="app-page-title"
              style={{
                margin: "0px 0px 15px 0px",
                borderRadius: "6px",
                backgroundColor: "rgb(0, 69, 139)",
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={cx("page-title-icon")}>
                <i className="fas fa-user-graduate icon-gradient bg-sunny-morning" />
              </div>
              <div>
                <span style={{ textTransform: "uppercase" }}>
                  {first_name}{' '}{last_name}
                </span>{" "}
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12}>
            <Table
              loader={vendorstudentcourseloader}
              // actions={action}
              data={data}
              title={"Course List"}
              columns={columns}
              // Vendorstudentdetails={()=>Vendorstudentdetails()}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default StudentCourseDetails;
