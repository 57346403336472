import React, { useState, useEffect, useRef } from "react";
import autosize from "autosize"
import {
  Row,
  Col,
  // Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  Label,
  CardTitle,
  FormFeedback,
  Button,
  TabContent, TabPane, Nav, NavItem, NavLink, CardHeader,
  Alert

} from "reactstrap";
import classnames from 'classnames';
import WarningIcon from '@material-ui/icons/Warning';
import { useParams } from 'react-router-dom'
// import { getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
import { requiredValidator } from "../../../utilities/Validator";
import { Progress } from "react-sweet-progress";
import cx from "classnames";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import {
  getEditCourseDetails, UpdateCourse, setCourseData,
  // deleteLiveClass, 
  // deleteOndemandVideoClass, 
  deleteGroup, deleteSubjectModules, EditDate
} from "../../../redux/Actions/vendor_course";
import { setReqData } from "../../../redux/Actions/Superadmin"
// import Tabl from '../../common/DataTable'
// import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogoutSession } from "../../../redux/Actions/genexhome";
import { authCheck } from "../../../redux/Actions/auth";
// import Table from "./table"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Modals from '../../common/Modal'
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SweetAlert from 'sweetalert-react';
import ShowClass from "../../../Components/Class/ShowClass"

import ContentLoader from 'react-content-loader'
// import htmlToDraft from 'html-to-draftjs';

import {
  EditorState,
  ContentState,
  // convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AssignStudents from '../../VideoSession/VideoClass/assignStudents'
import VideoClass from "../../VideoSession/VideoClass/index"
import Select from 'react-select';
import { CameraTagKey } from '../../../config.json';

import "../CreateCourse/cameratag.css"
const formData = new FormData();
let CameraTag

const Editcourse = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const params = useParams()

  // const tStore = useSelector(({ homeTeacher }) => homeTeacher);

  const [LiveClass, setLiveClass] = useState(false);
  const [LiveClass_EditCheck, setLiveClassEditCheck] = useState(false);

  const [OnDemand, setOnDemand] = useState(false);
  const [OnDemand_EditCheck, setOnDemandEditCheck] = useState(false);


  const [Certification, setCertification] = useState();
  const [Desc, setDesc] = useState("");
  const [Description, setDescription] = useState([]);
  const [VideoFile, setVideoFile] = useState(null);
  const [ImageFile, setImageFile] = useState(null);

  const [editForm, setEditForm] = useState(null)

  const [publish_date, setPublish] = useState(new Date())
  const [start_date, setStartDate] = useState(new Date())
  const [end_date, setEndDate] = useState(new Date())
  const [enroll_start_date, setEnrollStart] = useState(null)
  const [enroll_end_date, setEnrollEnd] = useState(null)
  const [successModal, setSuccessModal] = useState(false);
  const [CourseDescription, setCourseDescription] = useState() //literal description about course
  const [editorHTML,seteditorHTML] = useState(''); // added by shilpa
  const [showEditorCode,setshowEditorCode] = useState(false)
  const textareaEditor = useRef('')
  const [SiteImageFile, setSiteImageFile] = useState(null);
  const [schedule, setSchedule] = useState(false);

  const [selectedTags, setselectedTags] = useState([])
  const [taglist, setTaglist] = useState([])

  const [cameratag_file, setCameraTagFile] = useState('')
  const [uuid, setUuid] = useState('')
  const [cameratag_loader, setCameraTagLoader] = useState(false)
  const [controll, setControll] = useState(false)

  // const [end_date_min, setEndDatemin] = useState(new Date())//to set course start date minimum value
  const EditBatchList = useSelector((state) => state.course.EditBatchList ? state.course.EditBatchList : []);
  const EditSubjectList = useSelector((state) => state.course.EditSubjectList ? state.course.EditSubjectList : []);
  const videoStore = useSelector(({ playlist }) => playlist)
  const editloader = useSelector((state) => state.course.editloader ? state.course.editloader : false)
  const batchloader = useSelector((state) => state.course.batchloader ? state.course.batchloader : false)
  // const tag_array = useSelector((state) => state.superadmin.tag_filtered_array ? state.superadmin.tag_filtered_array : []);

  useEffect(() => {

    return () => {
      CameraTag.cameras['DemoCameraEdit'].destroy()
    }
  }, [])

  useEffect(() => {
    if (uuid && controll) {
      formData.append('uuid', uuid)
      setControll(false)
      SubmitWithUuid()
    }
  }, [uuid])

  useEffect(() => {
    dispatch(authCheck(tokenData.token, cbLogOut));
    dispatch(setCourseData('uploadProgressVideo', ''))
    dispatch(getEditCourseDetails(tokenData.token, params.course_id, cb))
  }, []);

  const onEditorStateChange = (editorState) => {
    setCourseDescription(editorState);
  };
  ///new change
  const onEditorStateChangee = editor => {
    const editorHTML = draftToHtml(convertToRaw(editor.getCurrentContent()))
    // seteditor(editor)
    setCourseDescription(editor)
    seteditorHTML(editorHTML)
  }
  const onEditEditorHTML = e => {
    const editorHTML = e.target.value
  
    let editor
    const contentBlock = htmlToDraft(editorHTML)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      editor = EditorState.createWithContent(contentState)
    } else {
      editor = EditorState.createEmpty()
    }
    // seteditor(editor)
    setCourseDescription(editor)
    seteditorHTML(editorHTML)
    // this.setState({ editor, editorHTML })
  }
  
  const toggleEditorCode = () => {
    // setshowEditorCode(showEditorCode)
    // let showEditorCode = true
    setshowEditorCode(!showEditorCode , () => {
      if (!showEditorCode) {
        autosize(textareaEditor)
        autosize.update(textareaEditor)
      } else {
        autosize.destroy(textareaEditor)
      }
    })
  }
  const ShowEditorCode = () => (
    <div className="rdw-option-wrapper"
      onClick={toggleEditorCode}
      title="Source Code"
      >
      {showEditorCode ? '< >' : '< >'} 
    </div>)
  
  
  const submit = e => {
    e.preventDefault()
    // const { editorHTML } =
    seteditorHTML(editorHTML)
    // console.log(editorHTML)
  }
  /////

  const uploadProgressVideo = useSelector((state) =>
    state.course.uploadProgressVideo ? state.course.uploadProgressVideo : ""
  );

  const tab = useSelector((state) => state.superadmin.tab ? state.superadmin.tab : "1");

  function handleDelete(e, point) {
    e.preventDefault();
    setDescription(Description.filter((item) => item !== point));
  }
  const cbLogOut = () => {
    dispatch(LogoutSession(tokenData.token));
    localStorage.removeItem("authDetails");
    history.push("/");
    Swal.fire({
      icon: "error",
      title: "",
      text: "Token Expired",
    });
  };


  if (CameraTag && CameraTag !== undefined && cameratag_file && !uuid) {
    CameraTag.observe('DemoCameraEdit', 'published', function () {
      let myCam = CameraTag.cameras["DemoCameraEdit"];
      let myVideo = myCam.getVideo();
      setUuid(myVideo.uuid)

    });
  }
  if (CameraTag && CameraTag !== undefined && !cameratag_file) {
    CameraTag.observe('DemoCameraEdit', 'uploadFileSelected', function (file) {
      setCameraTagFile(file)
    });
  }
  const UploadFn = () => {
    let myCam = CameraTag.cameras["DemoCameraEdit"];
    myCam.startUpload()
  }

  const Reset = () => {
    dispatch(setCourseData('batchloader', true))
    dispatch(setCourseData('uploadProgressVideo', ''))
    dispatch(getEditCourseDetails(tokenData.token, params.course_id, cb))
  }
  const handleSiteImageUpload = (e) => {
    setSiteImageFile(e.target.files[0]);
  };

  const hadleCloseAssignModal = () => {
    setAssignModal(false)
  }

  // const Addone = () => {
  //   let blist = [...EditBatchList]
  //   let data = {}
  //   data.batch_name = ''
  //   data.total_seats = ''
  //   blist.push(data)
  //   dispatch(setCourseData('EditBatchList', blist))
  // }

  const Addone = (typ) => {

    if (typ === 'batch') {
      let blist = [...EditBatchList]
      let data = {}
      data.batch_name = ''
      data.total_seats = ''
      blist.push(data)
      dispatch(setCourseData('EditBatchList', blist))
    }
    else {
      let slist = [...EditSubjectList]
      let data = {}
      data.subject_name = ''

      slist.push(data)
      dispatch(setCourseData('EditSubjectList', slist))
    }
  }

  const Removeone = (i, val) => {
    if (val.batch_id) {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure !',
        text: 'All the classes under this batch will be deleted',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "CONFIRM",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {

          dispatch(deleteGroup(tokenData.token, val.batch_id, params.course_id, i, Callback_Delete))

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Action cancelled',
            'error'
          )
        }
      })
    }
    else {
      let blist = [...EditBatchList]
      blist.splice(i, 1)
      dispatch(setCourseData('EditBatchList', blist))
    }
  }

  const RemoveOneSubject = (i, val) => {
    if (val.subject_id) {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure !',
        text: 'All The Classes For This Module Also Will Be Deleted',
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "CONFIRM",
        cancelButtonText: "CANCEL",
      }).then((result) => {
        if (result.value) {
          // let slist = [...EditSubjectList]
          // slist.splice(i, 1)
          // dispatch(setCourseData('EditSubjectList', slist))
          dispatch(deleteSubjectModules(tokenData.token, val.subject_id, params.course_id, i, Cb_Sub_Delete))

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Action cancelled',
            'error'
          )
        }
      })
    }
    else {
      let slist = [...EditSubjectList]
      slist.splice(i, 1)
      dispatch(setCourseData('EditSubjectList', slist))
    }
  }

  const Cb_Sub_Delete = (i, msg) => {
    let slist = [...EditSubjectList]
    slist.splice(i, 1)
    if (slist.length === 0) {
      let arr = []
      let data = {}
      data.subject_name = ''

      arr.push(data)
      dispatch(setCourseData('EditSubjectList', arr))
    }
    else {
      dispatch(setCourseData('EditSubjectList', slist))
    }
    // dispatch(setCourseData('EditBatchList', blist))
    setMsg(msg)
    SetBatchmodal(true)
  }

  const [batchmodal, SetBatchmodal] = useState(false)
  const [msg, setMsg] = useState(false)

  const Callback_Delete = (i, msg) => {
    let blist = [...EditBatchList]
    blist.splice(i, 1)
    if (blist.length === 0) {
      let arr = []
      let data = {}
      data.batch_name = ''
      data.total_seats = ''
      arr.push(data)
      dispatch(setCourseData('EditBatchList', arr))
    }
    else {
      dispatch(setCourseData('EditBatchList', blist))
    }
    // dispatch(setCourseData('EditBatchList', blist))
    setMsg(msg)
    SetBatchmodal(true)
  }

  // const handleChangeName = (e, i) => {
  //   let blist = [...EditBatchList]
  //   blist[i].batch_name = e.target.value
  //   dispatch(setCourseData('EditBatchList', blist))
  // }

  const handleChangeName = (e, i) => {

    const { name, value } = e.target;

    if (name === "batch") {

      let blist = [...EditBatchList]
      blist[i].batch_name = value
      dispatch(setCourseData('EditBatchList', blist))
    }
    else {
      let slist = [...EditSubjectList]
      slist[i].subject_name = value
      dispatch(setCourseData('EditSubjectList', slist))
    }
  }


  const handleChangeSeat = (e, i) => {
    let blist = [...EditBatchList]
    blist[i].total_seats = e.target.value
    dispatch(setCourseData('EditBatchList', blist))
  }



  const cb = (fromData) => {
    dispatch(setCourseData('batchloader', false))
    setErrors({})
    fromData.certified ? setCertification(true) : setCertification(false)
    fromData.live_class_enabled ? setLiveClass(true) : setLiveClass(false)
    fromData.live_class_enabled ? setLiveClassEditCheck(true) : setLiveClassEditCheck(false)
    fromData.schedule_type === 'pre_scheduled' ? setSchedule(true) : setSchedule(false)
    fromData.ondemand_video_enabled ? setOnDemand(true) : setOnDemand(false)
    fromData.ondemand_video_enabled ? setOnDemandEditCheck(true) : setOnDemandEditCheck(false)

    let des = fromData.learning_contents ? fromData.learning_contents : []
    setDescription([...des])
    setPublish(new Date(fromData.publish_date))
    setStartDate((fromData.course_start_date === null || fromData.course_start_date === '0000-00-00') ? new Date() : new Date(fromData.course_start_date))
    setEndDate((fromData.course_end_date === null || fromData.course_end_date === '0000-00-00') ? null : new Date(fromData.course_end_date))
    // setEndDate(fromData.course_end_date ? new Date(fromData.course_end_date) : new Date())

    setEnrollStart((fromData.enrollment_start_date === null || fromData.enrollment_start_date === '0000-00-00') ? null : new Date(fromData.enrollment_start_date))
    setEnrollEnd((fromData.enrollment_end_date === null || fromData.enrollment_end_date === '0000-00-00') ? null : new Date(fromData.enrollment_end_date))
    // setEndDatemin(new Date(fromData.course_end_date))
    setEditForm(fromData)
    let taglist = fromData.tags ? fromData.tags : []
    taglist.forEach(val => {
      val.value = val.tag_id
      val.label = val.tag_name
    })
    setTaglist(taglist)
    let filtered_tag = []
    let arr = fromData.tags ? fromData.tags : []
    arr.forEach(val => {
      if (val.course_id) {
        val.value = val.tag_id
        val.label = val.tag_name
        filtered_tag.push(val)
      }
    });
    setselectedTags(filtered_tag)


    const html = fromData.description ? fromData.description : ''
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    setCourseDescription(editorState)
    CameraTag = window.CameraTag
    CameraTag.setup()
  }

  const toggle = (val) => {

    if (tab !== val) {
      // setTab(val)
      dispatch(setReqData('tab', val))
    }
  }


  const handleChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  const handleVideoUpload = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleImageUpload = (e) => {
    setImageFile(e.target.files[0]);
  };



  const handleAdd = (e) => {
    e.preventDefault();
    if (!Desc) {
      Swal.fire({
        icon: "warning",
        title: "Required",
        text: "Enter a description to add",
        confirmButtonText: "Okay",
      });
    } else {
      let arr = [...Description];
      arr.push(Desc);
      setDescription(arr);
      setDesc("");
    }
  }

  // const deleteClass = (val) => {
  //   Swal.fire({
  //     icon: 'warning',
  //     title: 'Are you sure !',
  //     //text: 'All the classes under the subject will be deleted',

  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "CONFIRM",
  //     cancelButtonText: "CANCEL",

  //   }).then((result) => {
  //     if (result.value) {

  //       dispatch(deleteLiveClass(tokenData.token, val.class_id, refetch))


  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Delete action cancelled',
  //         'error'
  //       )
  //     }
  //   })
  // }

  // const DeleteVideoClass = (val) => {

  //   Swal.fire({
  //     icon: 'warning',
  //     title: 'Are you sure !',
  //     //text: 'All the classes under the subject will be deleted',

  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "CONFIRM",
  //     cancelButtonText: "CANCEL",

  //   }).then((result) => {
  //     if (result.value) {

  //       dispatch(deleteOndemandVideoClass(tokenData.token, val.video_class_id, refetch))

  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Delete action cancelled',
  //         'error'
  //       )
  //     }
  //   })

  // }

  // const TimeSchedule = (rowData) => {
  //   console.log(rowData)
  // }
  const refetch = () => {
    dispatch(getEditCourseDetails(tokenData.token, params.course_id, cb))
  }

  const handleChangeInput = (e) => {
    // const { name, value } = e.target;
    setEditForm({ ...editForm, [e.target.name]: e.target.value });
  };
  const [errors, setErrors] = useState({});

  const submitForm = (e) => {
    e.preventDefault();
    // let description_length = Description.length > 0 ? '' : "*Required"
    // let htmlElem = CourseDescription.getCurrentInlineStyle()
    // let livesession
    let batchname
    let seat
    // console.log("console log",htmlElem)
    // let subject
    let type
    //let desc = CourseDescription ? `<div style="color: rgb(256,,0);background-color: rgb(33,37,41) >${htmlElem}</div>` : ""
    let desc = CourseDescription ? draftToHtml(convertToRaw(CourseDescription.getCurrentContent())) : ""

    // let desc_length = desc.length
    let enroll_from
    let enroll_to
    let tagErr = selectedTags.length > 0 ? "" : "* Required"
    let course_end_date
    if (LiveClass) {
      // livesession = editForm.live_class_count ? "" : '*Required'
      batchname = EditBatchList.some((element) => element.batch_name === '')
      seat = EditBatchList.some((element) => element.total_seats === '')
      // subject = EditSubjectList.some((element) => element.subject_name === '')
      type = editForm.live_class_type ? "" : '*Required'
      enroll_from = editForm.enrollment_start_date ? "" : '*Required'
      enroll_to = editForm.enrollment_end_date ? "" : '*Required'
      course_end_date = requiredValidator(end_date)

    }

    // let ondemandvalidat
    // if (OnDemand) {
    //   ondemandvalidat = editForm.ondemand_video_count ? "" : '*Required'
    // }

    const err = {
      course_name: requiredValidator(editForm.course_name),
      proposed_fee: requiredValidator(editForm.proposed_fee),
      course_subject_name: requiredValidator(editForm.course_subject_name),

      // description: description_length,
      // publish_date: requiredValidator(editForm.publish_date),
      enrollment_start_date: enroll_from,
      enrollment_end_date: enroll_to,
      // course_start_date: requiredValidator(editForm.course_start_date),
      course_end_date: course_end_date,

      // live_class_count: livesession,
      batch_name: batchname ? "*Fill All Field" : '',
      // subject: subject ? "*Fill All Field" : '',
      total_seat: seat ? "*Fill All Field" : '',
      // ondemand_video_count: ondemandvalidat,
      live_class_type: type,
      tagerr: tagErr
    };
    // console.log("errorsrr", err)
    if (
      !err.course_name &&
      !err.proposed_fee &&
      !err.course_subject_name &&
      !err.enrollment_start_date &&
      !err.enrollment_end_date &&
      // !err.course_start_date &&
      !err.course_end_date &&

      // !err.subject &&
      !err.batch_name &&
      !err.total_seat &&

      !err.live_class_type &&
      !err.tagerr
      // !err.description 

    ) {
      // if (desc_length < 68) { //+8 offset (60 letter long description)
      //   Swal.fire({
      //     icon: 'warning',
      //     text: 'Description must contains 60 charactors'
      //   })
      // }
      // else {
      setErrors(err)
      if (VideoFile) {
        formData.append("video_file", VideoFile);
      }
      if (ImageFile) {
        formData.append("image_file", ImageFile);
      }
      if (SiteImageFile) {
        formData.append("header_banner", SiteImageFile);
      }
      if (LiveClass) {
        formData.append("live_class_type", editForm.live_class_type);
        formData.append("batch_details", JSON.stringify(EditBatchList));
        formData.append("enrollment_start_date", editForm.enrollment_start_date);
        formData.append("enrollment_end_date", editForm.enrollment_end_date);
        formData.append("subjects", JSON.stringify(EditSubjectList));
        formData.append("course_start_date", editForm.course_start_date);
        formData.append("course_end_date", editForm.course_end_date);
      }
      else {
        formData.append("live_class_type", '');
        formData.append("batch_details", JSON.stringify([]));
        formData.append("enrollment_start_date", '');
        formData.append("enrollment_end_date", '');
        formData.append("subjects", JSON.stringify([]));
        formData.append("course_start_date", '');
        formData.append("course_end_date", '');
      }
      formData.append("course_name", editForm.course_name);
      formData.append("grade_id", editForm.grade_id);
      formData.append("subject_id", editForm.subject_id);
      formData.append("course_subject_name", editForm.course_subject_name);

      formData.append("degree_id", editForm.degree_id);
      // formData.append("course_hour", editForm.course_hour);
      // formData.append("total_sessions", editForm.total_sessions);
      // formData.append("total_seats", editForm.total_seats);
      formData.append("proposed_fee", editForm.proposed_fee);
      //  formData.append("publish_date", editForm.publish_date);


      // formData.append("total_assignments", editForm.total_assignments);
      // formData.append("ondemand_video_count", editForm.ondemand_video_count);
      // formData.append("live_class_count", editForm.live_class_count);
      formData.append("ondemand_video_count", OnDemand ? 1 : 0);
      formData.append("live_class_count", LiveClass ? 1 : 0);

      formData.append("certification", Certification ? 1 : 0);
      formData.append("description", desc);
      formData.append("contents", JSON.stringify(Description));
      formData.append('course_id', params.course_id)
      formData.append("schedule", schedule ? 'pre_scheduled' : 'post_scheduled');

      let arr = []
      for (let i in selectedTags) {
        let obj = {}
        obj.tag_id = selectedTags[i].tag_id
        obj.tag_name = selectedTags[i].tag_name
        arr.push(obj)
      }
      formData.append("tags", JSON.stringify(arr));

      if (cameratag_file) {
        setCameraTagLoader(true)
        setControll(true)
        UploadFn()
      } else {
        dispatch(UpdateCourse(tokenData.token, formData, handleOpenSuccessModal))
      }
    }
    else {

      // console.log('eeeeeeeeeeeeeeeeeee',err)
      setErrors(err);
      if (err.course_name) {
        Showerror('course_name')
      }
      if (err.proposed_fee) {
        Showerror('proposed_fee')
      }
      if (err.course_subject_name) {
        Showerror('course_subject_name')
      }
      if (err.enrollment_start_date) {
        Showerror('enrollstart')
      }
      if (err.enrollment_end_date) {
        Showerror('enrollend')
      }
      if (err.live_class_type) {
        Showerror('liveclasstype')
      }
      if (err.total_seat) {
        Showerror('total_seat')
      }
      if (err.batch_name) {
        Showerror('batch_name')
      }
      if (err.tagerr) {
        Showerror('tag_id')
      }
    }
  };

  const SubmitWithUuid = () => {
    setCameraTagLoader(false)
    dispatch(UpdateCourse(tokenData.token, formData, handleOpenSuccessModal))
  }


  const Showerror = (val) => {
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }

  const handleOpenSuccessModal = () => {
    setSuccessModal(true)

  }
  const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
    history.push('/dashboard/showCourse')

  }


  const handleChangeFormDate = (e, val) => {
    const dt = moment(e).format("YYYY-MM-DD");

    if (val === 'publish_date') {
      setPublish(e)
    }
    else if (val === 'course_start_date') {
      // setStartDate(e)
      // setEditForm({ ...editForm, course_start_date: dt,course_end_date: '',enrollment_start_date:'',enrollment_end_date:'' });
      // setEndDate(null)
      // setEnrollStart(null)
      // setEnrollEnd(null)
      if (new Date(start_date) < e) {
        Swal.fire({
          icon: 'warning',
          title: 'Are you sure !',
          text: 'All the classes under this date will be deleted',

          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "CONFIRM",
          cancelButtonText: "CANCEL",

        }).then((result) => {
          if (result.value) {
            ////do api call
            dispatch(EditDate(tokenData.token, dt, null, params.course_id, cbdate, 'start_date', e, dt))

          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Action cancelled',
              'error'
            )
          }
        })
      }
      else {
        setStartDate(e)
        setEditForm({ ...editForm, course_start_date: dt, });
        // setEndDate(null)
        // setEnrollStart(null)
        // setEnrollEnd(null)
      }


    }
    else if (val === 'course_end_date') {
      // setEndDate(e)
      // setEnrollStart(null)
      // setEnrollEnd(null)
      // setEditForm({ ...editForm, course_end_date: dt,enrollment_start_date:'',enrollment_end_date:'' });
      if (e < new Date(end_date)) {
        Swal.fire({
          icon: 'warning',
          title: 'Are you sure !',
          text: 'All the classes above this date will be deleted',

          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "CONFIRM",
          cancelButtonText: "CANCEL",

        }).then((result) => {
          if (result.value) {
            ////do api call
            dispatch(EditDate(tokenData.token, null, dt, params.course_id, cbdate, 'end_date', e, dt))


          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Action cancelled',
              'error'
            )
          }
        })
      }
      else {
        setEndDate(e)
        setEditForm({ ...editForm, course_end_date: dt });
      }
    }
    else if (val === 'enrollment_start_date') {
      setEnrollStart(e)
      setEditForm({ ...editForm, enrollment_start_date: dt });

    }
    else {
      setEnrollEnd(e)
      setEditForm({ ...editForm, enrollment_end_date: dt });

    }
  }

  const cbdate = (i, e, dt, message) => {
    if (i === 'start_date') {
      setStartDate(e)
      setEditForm({ ...editForm, course_start_date: dt });
      if (end_date < e) {
        setEditForm({ ...editForm, course_end_date: '', enrollment_start_date: '', enrollment_end_date: '' });
        setEndDate(null)
        setEnrollStart(null)
        setEnrollEnd(null)
      }
    }
    else {
      setEndDate(e)
      setEditForm({ ...editForm, course_end_date: dt });
    }
    setMsg(message)
    SetBatchmodal(true)
  }

  const class_data = editForm ? editForm.live_class_details ? editForm.live_class_details : [] : []

  const ondemand_video_data = editForm ? editForm.ondemand_video_details ? editForm.ondemand_video_details : [] : []


  const reject_reason = editForm ? editForm.reject_reasons ? editForm.reject_reasons : [] : []



  const [AssignModal, setAssignModal] = useState(false);
  const [ClassId, setClassId] = useState();

  // const hadleOpenModal = (video_class_id) => {
  //   if (tokenData.enterpriseUser) {
  //     dispatch(getTeacherAssignedGroups(tokenData.token, video_class_id, "", params.course_id, "video"))

  //   }
  //   setClassId(video_class_id)
  //   setAssignModal(true)
  // }
  const handleChange = (opt) => {
    setselectedTags(opt)
  }
  return (

    <div>
      {
        batchloader ?
          <div style={{ width: '100%' }}>
            <ContentLoader
              speed={2}
              width={'100%'}
              height={'70vh'}
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
              <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
              <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              <circle cx="20" cy="20" r="20" />
            </ContentLoader>
          </div>
          :
          <>
            {
              editForm ?
                <>
                  {
                    reject_reason.length > 0 ?
                      <Card>
                        <CardHeader>Reasons for Rejection</CardHeader>
                        <CardBody>
                          <Row>
                            {
                              reject_reason.map((val, ky) => {
                                if (val.reason) {
                                  return (
                                    <Col xs={6} key={ky} style={{ marginTop: '5px' }}>
                                      <Alert color="warning">
                                        <span style={{ marginRight: '6px' }}><WarningIcon /></span>
                                        {
                                          val.type === 'banner' ?
                                            <span style={{ marginLeft: '5px', color: 'black' }}>banner -</span>
                                            :
                                            <span style={{ marginLeft: '5px', color: 'black' }}>{val.type} -</span>

                                        }
                                        <span style={{ marginLeft: '5px' }}>{val.reason}</span>
                                      </Alert>
                                    </Col>
                                  )
                                }
                                else {
                                  return (
                                    null
                                  )
                                }

                              })
                            }

                          </Row>
                        </CardBody>
                      </Card>
                      :
                      null
                  }


                  <Card tabs="true" className="mb-3" style={{ marginTop: '15px' }}>
                    <CardHeader>
                      <Nav justified>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({ active: tab === "1" })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Edit Course
                          </NavLink>
                        </NavItem>
                        {
                          class_data.length > 0 ?
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({ active: tab === "2" })}
                                onClick={() => {
                                  toggle("2");
                                }}
                              >
                                Edit Live Class
                              </NavLink>
                            </NavItem>
                            : ''
                        }


                        {
                          ondemand_video_data.length > 0 ?
                            <NavItem>
                              <NavLink
                                href="#"
                                className={classnames({ active: tab === "3" })}
                                onClick={() => {
                                  toggle("3");
                                }}
                              >
                                Edit On demand Class
                              </NavLink>
                            </NavItem>
                            : ''
                        }

                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                          {/* <Form onSubmit={submitForm} id="class-form"> */}
                          <Card className="main-card mb-3">
                            <CardBody>
                              <CardTitle>GENERAL DETAILS</CardTitle>

                              <FormGroup row>
                                <Col style={{ borderRight: "solid 1px #ced4db" }} sm={12}>
                                  <Row>
                                    <Col sm={6}>
                                      <Label for="exampleNameF">
                                        <span className="text-danger">*</span> Course Name
                                      </Label>
                                      <Input
                                        type="text"
                                        name="course_name"
                                        id='course_name'
                                        invalid={errors.course_name}
                                        onChange={handleChangeInput}
                                        value={editForm.course_name ? editForm.course_name : ''}
                                        autocomplete="off"
                                      />
                                      <FormFeedback>{errors.course_name}</FormFeedback>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                      <span className="text-danger">*</span> Proposed Fee
                                      <Input
                                        name="proposed_fee"
                                        id='proposed_fee'
                                        onChange={handleChangeInput}
                                        value={editForm.proposed_fee}
                                        type="number"
                                        invalid={errors.proposed_fee}
                                        autocomplete="off"
                                      ></Input>
                                      <FormFeedback>{errors.proposed_fee}</FormFeedback>
                                    </Col>
                                    {/* <Col className="pt-1" sm={6}>
                                        <Label for="exampleNameF">
                                          <span className="text-danger">*</span>Grade
                                        </Label>

                                        <Input
                                          type="text"
                                          name="grade"
                                          value={editForm.grade_name}
                                          readOnly
                                        />

                                      </Col> */}

                                    <Col className="pt-1" xs={12} sm={6}>

                                      <Label for="exampleNameF">
                                        <span className="text-danger">*</span>Subject
                                      </Label>
                                      <Input
                                        name="course_subject_name"
                                        id="course_subject_name"
                                        onChange={handleChangeInput}
                                        value={editForm.course_subject_name}
                                        type="textarea"
                                        rows={2}
                                        invalid={errors.course_subject_name}
                                      ></Input>

                                      <FormFeedback>{errors.course_subject_name}</FormFeedback>
                                    </Col>


                                    <Col className="pt-3" xs={12} sm={6}>
                                      <Label for="exampleNameF">
                                        <span className="text-danger">*</span>Select Category Tags
                                      </Label>
                                      <Select
                                        isMulti
                                        name="tags"
                                        options={taglist}
                                        value={selectedTags}
                                        onChange={handleChange}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder='Select Category Tags'
                                        id='tag_id'
                                      />
                                      <span style={{ color: 'red' }}>{errors.tagerr}</span>

                                    </Col>
                                  </Row>
                                </Col>

                              </FormGroup>

                            </CardBody>
                          </Card>


                          {/* new */}
                          <Card className="">
                            <CardBody>
                              <CardTitle>Course Details    <span id='course_detail' style={{ color: 'red' }}>{errors.course_detail}</span></CardTitle>

                              <Col sm={12}>
                                <Row>
                                  <Col xs={12} sm={6} md={3}>

                                    <Col >
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        {" "}
                                        <label className="pt-2">
                                          {/* <span className="text-danger">*</span> */}
                                          Certification
                                        </label>{" "}
                                        <div
                                          className="switch has-switch ml-1 "
                                          data-on-label="ON"
                                          data-off-label="OFF"
                                          onClick={() => {
                                            setCertification(!Certification);
                                          }}
                                          style={{ zIndex: 0 }}

                                        >
                                          <div
                                            className={cx("switch-animate", {
                                              "switch-on": Certification,
                                              "switch-off": !Certification,
                                            })}
                                          >
                                            <input type="checkbox" />

                                            <span className="switch-left bg-success">YES</span>
                                            <label>&nbsp;</label>
                                            <span className="switch-right bg-danger">NO</span>
                                          </div>
                                        </div>
                                      </div>
                                      <Input
                                        name="certification"
                                        value={Certification}
                                        type="hidden"
                                      />
                                    </Col>
                                  </Col>

                                  <Col xs={12} sm={6} md={3}>
                                    <Col >
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        {" "}
                                        <label className="pt-2">
                                          Pre Schedule
                                        </label>{" "}
                                        <div
                                          className="switch has-switch ml-1 "
                                          data-on-label="ON"
                                          data-off-label="OFF"
                                          onClick={() => {
                                            setSchedule(!schedule);
                                          }}
                                          style={{ zIndex: 0 }}
                                        >
                                          <div
                                            className={cx("switch-animate", {
                                              "switch-on": schedule,
                                              "switch-off": !schedule,
                                            })}
                                          >
                                            <input type="checkbox" />

                                            <span className="switch-left bg-success">YES</span>
                                            <label>&nbsp;</label>
                                            <span className="switch-right bg-danger">NO</span>
                                          </div>
                                        </div>
                                      </div>
                                      <Input
                                        name="certification"
                                        value={Certification}
                                        type="hidden"
                                      />
                                    </Col>
                                  </Col>

                                  <Col xs={12} sm={6} md={3}>

                                    <Col>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        {" "}
                                        <label className="pt-2">
                                          {/* <span className="text-danger">*</span> */}
                                          On demand
                                        </label>{" "}
                                        {
                                          OnDemand_EditCheck ?
                                            <div
                                              className="switch has-switch ml-1 "
                                              data-on-label="ON"
                                              data-off-label="OFF"
                                              style={{ zIndex: 0 }}

                                            // onClick={() => {
                                            // setOnDemand(!OnDemand)
                                            // }}
                                            >
                                              <div
                                                className={cx("switch-animate", {
                                                  "switch-on": OnDemand,
                                                  "switch-off": !OnDemand,
                                                })}
                                              >
                                                <input disabled={true} type="checkbox" />

                                                <span className="switch-left bg-success">YES</span>
                                                <label>&nbsp;</label>
                                                <span className="switch-right bg-danger">NO</span>
                                              </div>
                                            </div>
                                            :
                                            <div
                                              className="switch has-switch ml-1 "
                                              data-on-label="ON"
                                              data-off-label="OFF"
                                              onClick={() => {
                                                setOnDemand(prev => !prev)
                                              }}
                                              style={{ zIndex: 0 }}
                                            >
                                              <div
                                                className={cx("switch-animate", {
                                                  "switch-on": OnDemand,
                                                  "switch-off": !OnDemand,
                                                })}
                                              >
                                                <input type="checkbox" />

                                                <span className="switch-left bg-success">YES</span>
                                                <label>&nbsp;</label>
                                                <span className="switch-right bg-danger">NO</span>
                                              </div>
                                            </div>
                                        }

                                      </div>

                                    </Col>

                                  </Col>

                                  <Col xs={12} sm={6} md={3}>
                                    <Col >
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        {" "}
                                        <label className="pt-2">
                                          {/* <span className="text-danger">*</span> */}
                                          Live Class
                                        </label>{" "}
                                        {
                                          LiveClass_EditCheck ?
                                            <div
                                              className="switch has-switch ml-1 "
                                              data-on-label="ON"
                                              data-off-label="OFF"
                                              style={{ zIndex: 0 }}

                                            >
                                              <div
                                                className={cx("switch-animate", {
                                                  "switch-on": LiveClass,
                                                  "switch-off": !LiveClass,
                                                })}
                                              >
                                                <input disabled={true} type="checkbox" />

                                                <span className="switch-left bg-success">YES</span>
                                                <label>&nbsp;</label>
                                                <span className="switch-right bg-danger">NO</span>
                                              </div>
                                            </div>
                                            :
                                            <div
                                              className="switch has-switch ml-1 "
                                              data-on-label="ON"
                                              data-off-label="OFF"
                                              onClick={() => {
                                                setLiveClass(prev => !prev)
                                              }}
                                              style={{ zIndex: 0 }}
                                            >
                                              <div
                                                className={cx("switch-animate", {
                                                  "switch-on": LiveClass,
                                                  "switch-off": !LiveClass,
                                                })}
                                              >
                                                <input type="checkbox" />

                                                <span className="switch-left bg-success">YES</span>
                                                <label>&nbsp;</label>
                                                <span className="switch-right bg-danger">NO</span>
                                              </div>
                                            </div>
                                        }

                                      </div>

                                    </Col>
                                  </Col>

                                  {
                                    LiveClass ?
                                      <>
                                        <Col sm={6}>
                                          <Label for="exampleInput">
                                            <span className="text-danger">*</span>
                                            Course start date
                                          </Label>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                              format="dd/MM/yyyy"
                                              // label="Course start date"
                                              size='small'
                                              inputVariant="outlined"
                                              value={start_date}
                                              onChange={date => handleChangeFormDate(date, 'course_start_date')}
                                              // minDate={new Date()}
                                              // disablePast
                                              fullWidth
                                              views={["year", "month", "date"]}
                                              openTo="year"
                                            />
                                          </MuiPickersUtilsProvider>

                                        </Col>
                                        <Col sm={6}>
                                          <Label for="exampleInput">
                                            <span className="text-danger">*</span>
                                            Course end date
                                          </Label>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                              format="dd/MM/yyyy"
                                              // label="Course end date"
                                              size='small'
                                              inputVariant="outlined"
                                              value={end_date}
                                              onChange={date => handleChangeFormDate(date, 'course_end_date')}
                                              minDate={new Date()}
                                              fullWidth
                                              views={["year", "month", "date"]}
                                              openTo="year"
                                            />
                                          </MuiPickersUtilsProvider>


                                          <span style={{ color: 'red' }}>{errors.course_end_date}</span>
                                        </Col>

                                        <Col xs={12} sm={4} className='mt-2'>
                                          <Label for="exampleInput">
                                            <span className="text-danger">*</span>
                                            Type
                                          </Label>
                                          <Input
                                            // readOnly
                                            name="live_class_type"
                                            type="select"
                                            value={editForm.live_class_type}
                                            disabled={LiveClass_EditCheck}
                                            onChange={handleChangeInput}
                                            invalid={errors.live_class_type}
                                            id='liveclasstype'
                                          >
                                            <option value={""}>select</option>
                                            <option value={'oneday'}>One day</option>
                                            <option value={'repetitive'}>Schedule</option>
                                          </Input>
                                          <FormFeedback>{errors.live_class_type}</FormFeedback>
                                        </Col>

                                        <Col xs={12} sm={4} className='mt-2'>
                                          <Label for="exampleInput">
                                            <span className="text-danger">*</span>
                                            Admission Start Date
                                          </Label>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                              format="dd/MM/yyyy"
                                              label="Admission Start Date"
                                              size='small'
                                              inputVariant="outlined"
                                              value={enroll_start_date}
                                              onChange={date => handleChangeFormDate(date, 'enrollment_start_date')}
                                              // minDate={new Date()}
                                              maxDate={end_date}
                                              fullWidth
                                              views={["year", "month", "date"]}
                                              openTo="year"
                                              id='enrollstart'
                                            />
                                          </MuiPickersUtilsProvider>

                                          <span style={{ color: 'red' }}>{errors.enrollment_start_date}</span>
                                        </Col>
                                        <Col xs={12} sm={4} className='mt-2'>
                                          <Label for="exampleInput">
                                            <span className="text-danger">*</span>
                                            Admission End Date
                                          </Label>

                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                              format="dd/MM/yyyy"
                                              label="Admission End Date"
                                              size='small'
                                              inputVariant="outlined"
                                              value={enroll_end_date}
                                              onChange={date => handleChangeFormDate(date, 'enrollment_end_date')}
                                              minDate={enroll_start_date ? enroll_start_date : new Date()}
                                              maxDate={end_date}
                                              fullWidth
                                              views={["year", "month", "date"]}
                                              openTo="year"
                                              id='enrollend'
                                            />
                                          </MuiPickersUtilsProvider>

                                          <span style={{ color: 'red' }}>{errors.enrollment_end_date}</span>
                                        </Col>
                                        <Row className="border rounded m-4 p-3 pb-4" style={{ width: '100%' }}>
                                          <Row style={{ width: '100%', color: 'blue', fontWeight: '3px' }}> <h6 className="pl-2 pt-0">Update Batches</h6></Row>

                                          {
                                            EditBatchList.map((val, ky) => {
                                              return (
                                                <>
                                                  <Col xs={6} sm={5}>
                                                    <span className="text-danger">*</span>
                                                    Batch Name
                                                    {
                                                      ky === 0 ?
                                                        <span id='batch_name' style={{ color: 'red' }}>{errors.batch_name}</span>
                                                        : ''
                                                    }
                                                    <Input type='text' name='batch' autocomplete="off" onChange={(e) => { handleChangeName(e, ky) }} value={val.batch_name ? val.batch_name : ''}></Input>
                                                  </Col>
                                                  <Col xs={6} sm={5}>
                                                    <span className="text-danger">*</span>
                                                    Total Seats
                                                    {
                                                      ky === 0 ?
                                                        <span id='total_seat' style={{ color: 'red' }}>{errors.total_seat}</span>
                                                        : ''
                                                    }
                                                    <Input onChange={(e) => { handleChangeSeat(e, ky) }} value={val.total_seats ? val.total_seats : ''} type='number'></Input>
                                                  </Col>

                                                  {
                                                    ky === 0 ?
                                                      <>
                                                        <Col xs={12} sm={1} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                                          <Tooltip title='Add More' arrow>
                                                            <Button onClick={() => { Addone("batch") }} color='primary' size='xs'>
                                                              <AddIcon fontSize='small' />
                                                            </Button>
                                                          </Tooltip>
                                                        </Col>
                                                        {/* {
                                                            !val.batch_id ?
                                                              "" :
                                                              <Col xs={12} sm={1} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                                                <Tooltip title='Delete this row' arrow>
                                                                  <Button onClick={() => { Removeone(ky, val) }} size='xs' color='danger'>
                                                                    <DeleteOutlineIcon fontSize='small' />
                                                                  </Button>
                                                                </Tooltip>
                                                              </Col>
                                                          } */}


                                                      </>
                                                      :
                                                      <Col xs={12} sm={2} style={{ paddingTop: '20px' }}>
                                                        <Tooltip title='Delete this row' arrow>
                                                          <Button onClick={() => { Removeone(ky, val) }} size='xs' color='danger'>
                                                            <DeleteOutlineIcon fontSize='small' />
                                                          </Button>
                                                        </Tooltip>
                                                      </Col>
                                                  }
                                                </>

                                              )
                                            })
                                          }
                                        </Row>
                                        <Row className="border rounded m-4 p-3 pb-4" style={{ width: '100%' }}>
                                          <Row style={{ width: '100%', color: 'blue', fontWeight: '3px' }}> <h6 className="pl-2 pt-0">Update Subject Modules</h6></Row>

                                          {
                                            EditSubjectList.map((val, ky) => {
                                              return (
                                                <>
                                                  <Col xs={10} sm={10}>
                                                    {/* <span className="text-danger">*</span> */}
                                                    Module Name
                                                    {/* <span id='subject_name' style={{ color: 'red' }}>{errors.subject}</span> */}
                                                    <Input type='text' name='subject' autocomplete="off" onChange={(e) => { handleChangeName(e, ky) }} value={val.subject_name ? val.subject_name : ''}></Input>
                                                  </Col>


                                                  {
                                                    ky === 0 ?
                                                      <>
                                                        <Col xs={12} sm={1} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                                          <Tooltip title='Add More' arrow>
                                                            <Button onClick={() => { Addone("subject") }} color='primary' size='xs'>
                                                              <AddIcon fontSize='small' />
                                                            </Button>
                                                          </Tooltip>
                                                        </Col>
                                                        {
                                                          !val.subject_id ?
                                                            "" :
                                                            <Col xs={12} sm={1} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                                              <Tooltip title='Delete this row' arrow>
                                                                <Button onClick={() => { RemoveOneSubject(ky, val) }} size='xs' color='danger'>
                                                                  <DeleteOutlineIcon fontSize='small' />
                                                                </Button>
                                                              </Tooltip>
                                                            </Col>
                                                        }

                                                      </>
                                                      :
                                                      <Col xs={12} sm={2} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                                        <Tooltip title='Delete this row' arrow>
                                                          <Button onClick={() => { RemoveOneSubject(ky, val) }} size='xs' color='danger'>
                                                            <DeleteOutlineIcon fontSize='small' />
                                                          </Button>
                                                        </Tooltip>
                                                      </Col>
                                                  }
                                                </>


                                              )
                                            })
                                          }
                                        </Row>
                                      </>


                                      : ''
                                  }


                                </Row>

                              </Col>
                            </CardBody>

                          </Card>

                          <Card className="mb-4" style={{ padding: "4px 4px 34px 4px" }}>
                            <Col className="pt-4 pb-0" md={12}>
                              <CardTitle>Course Contents</CardTitle>
                              <Label for="exampleInput">
                                {/* <span className="text-danger">*</span> */}
                                Write what students should expect from your course
                              </Label>
                            </Col>
                            <Row>
                              <Col className="align-center pl-4 my-auto" xs={8} md={10}>
                                <Input 
                                  onChange={handleChangeDesc} 
                                  id='description' 
                                  type="textarea"
                                  rows={2} 
                                  value={Desc} />
                                <Input
                                  type="hidden"
                                  name="contents"
                                  value={Description}
                                // invalid={errors.description}
                                ></Input>
                                
                                {/* <FormFeedback>{errors.description}</FormFeedback> */}
                              </Col>
                              <Col xs={4} md={2}>
                                <Button className="align-center my-auto" type='button' onClick={handleAdd}>
                                  Add
                                </Button>
                              </Col>
                            </Row>
                            <CardBody>
                              <ul>
                                {Description.map((point, index) => (
                                  <>
                                    <Row key={index}>
                                      <Col xs={8} md={8}>
                                        <li className="mb-3">
                                          {point} {"  "}
                                        </li>
                                      </Col>
                                      <Col xs={4} md={4}>
                                        <Button
                                          color="danger"
                                          size="xs"
                                          onClick={(e) => handleDelete(e, point)}
                                        >
                                          <FontAwesomeIcon
                                            size="xs"
                                            color="white"
                                            icon={faTrash}
                                          />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </>
                                ))}
                              </ul>
                            </CardBody>
                          </Card>

                          <Card className="main-card mb-3">
                            <CardBody>
                              <CardTitle>COURSE DESCRIPTION</CardTitle>
                              {console.log("CourseDescription",CourseDescription)}
                              {/* <Editor
                                // toolbarHidden
                                editorState={CourseDescription}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                                stripPastedStyles={true}
                                toolbar={{
                                  inline: { inDropdown: true },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                  image: { inDropdown: false },
                                  options: ['inline', 'link', 'history', 'fontSize', 'fontFamily', 'list']
                                }}
                              /> */}
                              <form name="form" onSubmit={submit} autoComplete="off">
                                <div>
                                      <Editor
                                        editorState={CourseDescription}
                                        wrapperClassName="demo-wrapper"
                                        stripPastedStyles={true}
                                        editorClassName={showEditorCode ? 'editor' : 'editorHide'}
                                        onEditorStateChange={onEditorStateChangee}
                                        toolbarCustomButtons={[<ShowEditorCode />]}
                                      />
                                    { showEditorCode && 
                                    <textarea
                                      className="textarea-style"
                                      ref={ textareaEditor }
                                      value={editorHTML}
                                      onChange={onEditEditorHTML}
                                      readOnly = {false}
                                    /> }
                                  </div>
                              </form>
                            </CardBody>
                          </Card>

                          <Card className="main-card mb-3">
                            <CardBody>
                              <CardTitle>MEDIA UPLOAD</CardTitle>
                              <Row>
                                <Col sm={12} md={4} className="mt-2">
                                  <Label for="exampleInput">
                                    Preview video
                                  </Label>
                                  {
                                    editloader || cameratag_loader ?
                                      <Input
                                        id="exampleInput"
                                        type="file"
                                        name="preview_video"
                                      ></Input>
                                      :
                                      <div>
                                        <camera style={{ height: 'auto', width: '100%', background: '#f8fbff', fontSize: 'unset' }} id='DemoCameraEdit' data-app-id={CameraTagKey.camera_tag_id} data-sources='upload' data-upload-on-select='false'></camera>
                                        <div id="DemoCameraEdit-start-screen">
                                          <div class="cameratag_upload button_wrapper">
                                            <button class="cameratag_upload_button">Choose file</button>
                                            <div style={{ width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
                                              {
                                                cameratag_file ?
                                                  <span className="camera_tag_span">{cameratag_file.name}</span>
                                                  :
                                                  <span className="camera_tag_span">No file chosen</span>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  }


                                </Col>

                                <Col sm={12} md={4} className="mt-2">
                                  <Label for="exampleInput">
                                    Banner Image (6250 x 1250)px
                                  </Label>
                                  <Input
                                    id="exampleInput"
                                    type="file"
                                    name="subject_id"
                                    onChange={handleSiteImageUpload}
                                    accept="image/*"
                                  ></Input>
                                </Col>

                                <Col sm={12} md={4} className="mt-2">
                                  <Label for="exampleInput">
                                    Course Logo (200 x 200)px
                                  </Label>
                                  <Input
                                    id="exampleInput"
                                    type="file"
                                    name="site_image"
                                    //required
                                    onChange={handleImageUpload}
                                    accept="image/*"
                                  ></Input>
                                  {/* <span style={{ color: 'red' }}>{errors.site}</span> */}
                                </Col>


                                <Col sm={12}>
                                  {(uploadProgressVideo !== "" && (VideoFile || ImageFile || SiteImageFile)) ? (
                                    <>
                                      <strong style={{ color: "rgb(19, 140, 228)" }}>
                                        Uploading Files...
                                      </strong>
                                      <Progress percent={uploadProgressVideo} />
                                    </>
                                  ) : null}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>

                          <LaddaButton
                            className="mb-2 mr-2 btn btn-primary"
                            loading={editloader || cameratag_loader}
                            // type="submit"
                            data-style={ZOOM_IN}
                            onClick={submitForm}
                          >
                            Update Course Details
                          </LaddaButton>

                          <LaddaButton
                            className="mb-2 mr-2 btn btn-danger"
                            loading={batchloader}
                            onClick={Reset}
                            type="button"
                            data-style={ZOOM_IN}
                          >
                            Reset
                          </LaddaButton>

                          {/* </Form> */}
                        </TabPane>
                        <TabPane tabId="2">
                          {
                            class_data.length > 0 ?
                              <Row>
                                <Col xs={12} style={{ marginTop: '5px' }}>

                                  <ShowClass type="course" id={params.course_id} />

                                </Col>
                              </Row>
                              : ''
                          }


                        </TabPane>
                        <TabPane tabId="3">
                          {
                            ondemand_video_data.length > 0 ?
                              // <Row>
                              //   <Col xs={12}>
                              //     <Table
                              //       // loader={vendorcourseloader}
                              //       actions={action_ondemand}
                              //       data={ondemand_video_data}
                              //       title={"Video Class"}
                              //       columns={ondemand_columns}
                              //       EditvideoClass={Edit_Video_Class}
                              //       ViewvideoClass={View_Video_Class}
                              //       DeleteVideoClass={DeleteVideoClass}
                              //       hadleOpenModal={hadleOpenModal}
                              //     />
                              <VideoClass course_id={params.course_id} />
                              //   </Col>

                              // </Row>
                              : 'No Data'
                          }
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>


                </>
                :
                ''
            }
          </>
      }


      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Updated successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={batchmodal}
        text={msg ? msg : "Updated successfully"}
        type="success"
        onConfirm={() => SetBatchmodal(false)} />

      <Modals
        open={AssignModal}
        handleClose={hadleCloseAssignModal}
        title={`Assign students`}
      >
        <AssignStudents
          loader={videoStore.specificStudentLoader}
          classId={ClassId}
          // data={tokenData.enterpriseUser ? tStore.specificGroup : videoStore.specificStudent}

          data={videoStore.specificStudent}
          handleCloseStudentModal={hadleCloseAssignModal}
          timeData={"timeData"}
        />
      </Modals>

    </div>
  );
};

export default Editcourse;
