
import axios from 'axios'
import { development } from '../../config.json'

import Swal from 'sweetalert2'
// import { withRouter } from 'react-router';









export const basicPlanMigrate = (token, plan_id, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    const data = { plan_id: plan_id }
    dispatch({ type: 'BASIC_PLAN_REQUEST' })
    axios.post(`${development.baseUrl}payment`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SET_PLIVILLAGES', payload: res.data.auth_details.privilege })
                dispatch({ type: 'PAYMENT_SUCCESS', payload: res.data.plan_name })
                localStorage.setItem('authDetails', JSON.stringify(res.data.auth_details));
                cb(res.data)
            }
        })
        .catch(err => {
            dispatch({ type: 'BASIC_PLAN_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        //  cb()
                    }
                }
            }
        })
}



export const getPaymentHistory = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'PAYMENT_HISTORY_REQUEST' })
    axios.get(`${development.baseUrl}getPaymentHistory`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'PAYMENT_HISTORY_SUCCESS', payload: res.data.payment_history })


            }
        })
        .catch(err => {
            dispatch({ type: 'PAYMENT_HISTORY_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        //  cb()
                    }
                }
            }
        })
}


export const updatePaymentStatus = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'PAYMENT_REQUEST' })
    //console.log(`${development.baseUrl}populatUserClasses`, data);
    axios.post(`${development.baseUrl}updatePaymentStatus`, data, { headers: head })
        .then(res => {
            // console.log("auth check res====", res.data)
            if (res.data.result) {
                dispatch({ type: 'SET_PLIVILLAGES', payload: res.data.auth_details.privilege })

                dispatch({ type: 'PAYMENT_SUCCESS', payload: res.data.plan_name })
                // debugger



                localStorage.setItem('authDetails', JSON.stringify(res.data.auth_details));


                cb(res.data)
            } else {
                cb(res.data)

            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'PAYMENT_FAILED' })

            // console.log("errorrrrrrrrrrrrrrr", err.response)
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        //  cb()
                    }
                }

            }

        }
        )
}

export const payment = (token, plan_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    const data = { plan_id: plan_id }
    dispatch({ type: 'CREATE_ORDER_REQUEST' })
    //console.log(`${development.baseUrl}populatUserClasses`, data);
    axios.post(`${development.baseUrl}payment`, data, { headers: head })
        .then(res => {
            // console.log("auth check res====", res.data)
            if (res.data.result) {
                dispatch({ type: 'CREATE_ORDER_SUCCESS' })

                cb(res.data)
            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'CREATE_ORDER_FAILED' })

            // console.log("errorrrrrrrrrrrrrrr", err.response)
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        //  cb()
                    }
                }

            }

        }
        )
}

export const authCheck = (token, cb, authenticated) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TOKEN_CHECK_EXPIRY_REQUEST' })
    //console.log(`${development.baseUrl}populatUserClasses`, data);
    axios.get(`${development.baseUrl}validateToken`, { headers: head })
        .then(res => {
            // console.log("auth check res====", res.data)
            if (res.data.result) {
                dispatch({ type: 'TOKEN_CHECK_EXPIRY_SUCCESS', payload: res.data })

                authenticated()
            }
            // cb(res.status)
        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'TOKEN_CHECK_EXPIRY_FAILED' })

            // console.log("errorrrrrrrrrrrrrrr", err.response)
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        cb()
                    }
                }

            }

        }
        )
}


// export const setExp = () => (dispatch) => {

//     console.log("  dispatch({ type: 'RESET_EXPIRE' })")

// }

export const authRequestEnterprice = (authData, cb) => (dispatch) => {

    dispatch({ type: 'USER_AUTH_ENTERPRISE_REQUEST' })
    axios.post(`${development.baseUrlEnte}login`, authData)
        .then(res => {
            dispatch({ type: 'USER_AUTH_ENTERPRISE_SUCCESS', payload: res.data })
            //console.log("res=====", res.data)
            if (res.data.result) {

                const tok = {
                    result: res.data.result,
                    role: res.data.role,
                    token: res.data.api_token,
                    name: res.data.name,
                    emailVerified: res.data.email_verified,
                    enterpriseUser: res.data.enterprise_user,
                    privilege: res.data.privilege,
                    logo: res.data.enterprise_logo_url,
                    enterprice_Name: res.data.enterprise_name,
                    description: res.data.enterprise_description


                }
                localStorage.setItem('authDetails', JSON.stringify(tok));

                // const localstore = {
                //     date: null,
                //     count: null
                // }
                // localStorage.setItem('whatsnew', JSON.stringify(localstore));

                //localStorage.setItem('token', res.data.api_token)
                cb();
            }
        })
        .catch(err => {
            dispatch({ type: 'USER_AUTH_ENTERPRISE_FAILED', payload: err })
        })
}

export const authRequest = (authData, cb) =>
    (dispatch) => {
        dispatch({ type: 'USER_AUTH_REQUEST' })

        axios.post(`${development.baseUrl}login`, authData)

            .then(res => {
                dispatch({ type: 'USER_AUTH_SUCCESS', payload: res.data })
      
                if (res.data.result) {
                    const tok = {
                        result: res.data.result,
                        role: res.data.role,
                        token: res.data.api_token,
                        name: res.data.name,
                        emailVerified: res.data.email_verified,
                        privilege: res.data.privilege,
                        enterpriseUser: res.data.enterprise_user,
                        blocked_status:res.data.blocked_status,
                        subscrition_status:res.data.subscrition_status
                        // plan_id: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_id : '',
                        // plan_name: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_name : '',
                        // class_duration: res.data.plan_details.length > 0 ? res.data.plan_details[0].classe_duration : '',
                        // number_of_student: res.data.plan_details.length > 0 ? res.data.plan_details[0].number_of_student : '',
                        // allowed_classes: res.data.plan_details.length > 0 ? res.data.plan_details[0].allowed_classes : ''
                    }

                    localStorage.setItem('authDetails', JSON.stringify(tok));
                    cb();
                }
                else{
                    dispatch({ type: 'USER_AUTH_SUCCESS', payload: res.data })
                }


            })
            .catch(err => {
                dispatch({ type: 'USER_AUTH_FAILED', payload: err })
            })
    }


export const recoverPassword = (mail, cb, cb2) => (dispatch) => {
    const data = {
        email: mail
    }
    dispatch({ type: 'RECOVER_PASSWORD_REQUEST' })

    axios.post(`${development.baseUrl}sendPasswordResetMail`, data)
        .then(res => {
            dispatch({ type: 'RECOVER_PASSWORD_SUCCESS' })
            // console.log("RECOVER_PASSWORD_SUCCESS", res.data)
            if (res.data.result) {
                cb(res.data)
            }
            if (res.data.result === false) {
                cb(res.data)
            }

        }

        ).catch(err => {
            dispatch({ type: 'RECOVER_PASSWORD_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        // console.log("RECOVER_PASSWORD_FAILED", err.response)
                        cb2(err.response.data.message)
                    }
                }

            }
        })
}



export const changePassword = (data, cb) => (dispatch) => {


    dispatch({ type: 'RESETT_PASSWORD_REQUEST' })

    axios.post(`${development.baseUrl}passwordReset`, data)
        .then(res => {
            dispatch({ type: 'RESETT_PASSWORD_SUCCESS' })
            // console.log("RESETT_PASSWORD_SUCCESS", res.data)
            if (res.data.result) {
                cb("success")
            }
            if (res.data.result === false) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.message,

                })
            }
        }

        ).catch(err => {
            dispatch({ type: 'RESETT_PASSWORD_FAILED' })
            //console.log("RESETT_PASSWORD_FAILED", err.response.status)
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        // var val = Object.values(err.response.data.errors)

                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: err.response.data.message,

                        })
                    }
                }

            }
        })
}

export const logout = () => (dispatch) => {


    dispatch({ type: 'LOG_OUT' })




}


export const updateGenexPaymentStatus = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'PAYMENT_REQUEST' })
    //console.log(`${development.baseUrl}populatUserClasses`, data);
    axios.post(`${development.baseUrl}updateOrderStatus`, data, { headers: head })
        .then(res => {
            // console.log("auth check res====", res.data)
            if (res.data.result) {
                // dispatch({ type: 'SET_PLIVILLAGES', payload: res.data.auth_details.privilege })

                dispatch({ type: 'PAYMENT_SUCCESS', payload: res.data.plan_name })
                // debugger
                // localStorage.setItem('authDetails', JSON.stringify(res.data.auth_details));
                cb(res.data)
            } else {
                dispatch({ type: 'PAYMENT_FAILED' })
                cb(res.data)

            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'PAYMENT_FAILED' })

            // console.log("errorrrrrrrrrrrrrrr", err.response)
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        //  cb()
                    }
                }

            }

        }
        )
}