

export default function Superadmin(state={},action)

{
 switch (action.type) {
    case "SET_SUPERADMIN_DATA": {
      return { ...state, ...action.payload };
    }

    ///////////vendor//////////////////

    case "VENDORLIST_ALL_REQUEST": {
      return { ...state, ...action.payload,vendorloader: true, }
    }
    case "VENDORLIST_ALL_SUCCESS": {
      return { ...state, vendorloader: false, ...action.payload }
    }
    case "VENDORLIST_ALL_FAILED": {
      return { ...state,vendorloader: false, }
    }

    case "VENDORLISTCOURSE_ALL_REQUEST": {
      return { ...state, ...action.payload,vendorcourseloader: true, }
    }
    case "VENDORLISTCOURSE_ALL_SUCCESS": {
      return { ...state, vendorcourseloader: false, ...action.payload }
    }
    case "VENDORLISTCOURSE_ALL_FAILED": {
      return { ...state,vendorcourseloader: false, ...action.payload }
    }

    case "VENDORLISTSTUDENT_ALL_REQUEST": {
      return { ...state, ...action.payload,vendorstudentloader: true, }
    }
    case "VENDORLISTSTUDENT_ALL_SUCCESS": {
      return { ...state, vendorstudentloader: false, ...action.payload }
    }
    case "VENDORLISTSTUDENT_ALL_FAILED": {
      return { ...state,vendorstudentloader: false, }
    }

    case "VENDORLISTSTUDENTCOURSE_ALL_REQUEST": {
      return { ...state, ...action.payload,vendorstudentcourseloader: true, }
    }
    case "VENDORLISTSTUDENTCOURSE_ALL_SUCCESS": {
      return { ...state, vendorstudentcourseloader: false, ...action.payload }
    }
    case "VENDORLISTSTUDENTCOURSE_ALL_FAILED": {
      return { ...state,vendorstudentcourseloader: false, }
    }


    case "VENDORSINGLECOURSE_ALL_REQUEST": {
      return { ...state, ...action.payload, }
    }
    case "VENDORSINGLECOURSE_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }
    case "VENDORSINGLECOURSE_ALL_FAILED": {
      return { ...state,singlecourseloader: false, }
    }

    case "VENDOR_ALL_REQUEST": {
      return { ...state, ...action.payload, }
    }
    case "VENDOR_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }
    case "VENDOR_ALL_FAILED": {
      return { ...state,...action.payload }
    }
      /////////////reseller/////////////

      case "VALIDATE_PIN_REQUEST": {
        return { ...state,pinloader: true}
      }
      case "VALIDATE_PIN_SUCCESS": {
        return { ...state, pinloader: false}
      }
      case "VALIDATE_PIN_FAILED": {
        return { ...state,pinloader: false, ...action.payload }
      }  

    case "RESLLERLIST_ALL_REQUEST": {
      return { ...state, ...action.payload }
    }
    case "RESLLERLIST_ALL_SUCCESS": {
      return { ...state, vendorloader: false, ...action.payload }
    }
    // case "VENDORLIST_ALL_FAILED": {
    //     return { ...state }
    // }
    
    case "RESLLERSTUDENT_ALL_REQUEST": {
      return { ...state, ...action.payload }
    }
    case "RESLLERSTUDENT_ALL_SUCCESS": {
      return { ...state, vendorloader: false, ...action.payload }
    }
    case "RESLLERSTUDENT_ALL_FAILED": {
        return { ...state }
    }

    case "STATE_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }
    case "DISTRICTS_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }
    case "PIN_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }
    case "PANCHAYATH_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }

    case "RESET_DISTRICT_PANCHAYATH": {
      return {...state, panchayath:null,districts:null}
    }
    case "RESET_PANCHAYATH": {
      return {...state, panchayath:null}
    }
    case "PIN_UNUSED_SUCCESS": {
      return { ...state, ...action.payload }
    }

    case "RESLLER_ALL_REQUEST": {
      return { ...state, ...action.payload }
    }
    case "RESLLER_ALL_SUCCESS": {
      return { ...state, resellercreate: false, ...action.payload }
    }
    case "RESLLER_ALL_FAILED": {
        return { ...state, ...action.payload }
    }
    case "FILTER_RESELLER_SUCCESS": {
      return { ...state,  ...action.payload }
    }
    ////////////school///////////
    // case "SCHOOL_ALL_REQUEST": {
    //   return { ...state, ...action.payload }
    // }
    // case "SCHOOL_ALL_SUCCESS": {
    //   return { ...state, schoolloader: false, ...action.payload }
    // }
    // case "SCHOOL_ALL_FAILED": {
    //     return { ...state,...action.payload  }
    // }

    case "SCHOOL_ALL_REQUEST": {
      return { ...state, ...action.payload }
    }
    case "SCHOOL_ALL_SUCCESS": {
      return { ...state, ...action.payload }
    }
    case "SCHOOL_ALL_FAILED": {
        return { ...state, ...action.payload }
    }

    ////////////student///////////
    case "STUDENT_ALL_REQUEST": {
      return { ...state, ...action.payload }
    }
    case "STUDENT_ALL_SUCCESS": {
      return { ...state, studentloader: false, ...action.payload }
    }
    case "STUDENT_ALL_FAILED": {
        return { ...state, ...action.payload  }
    }

    // case "STUDENT_ALL_REQUEST": {
    //   return { ...state, ...action.payload }
    // }
    // case "STUDENT_ALL_SUCCESS": {
    //   return { ...state, studentcreate: false, ...action.payload }
    // }
    // case "STUDENT_ALL_FAILED": {
    //     return { ...state,...action.payload  }
    // }


//////////////ticket///////////////
case "TICKET_ALL_REQUEST": {
  return { ...state, ...action.payload }
}
case "TICKET_ALL_SUCCESS": {
  return { ...state,  ...action.payload }
}
case "TICKET_ALL_FAILED": {
    return { ...state,...action.payload  }
}


case "REJECT_ALL_REQUEST": {
  return { ...state, ...action.payload, }
}
case "REJECT_ALL_SUCCESS": {
  return { ...state,...action.payload }
}
case "REJECT_ALL_FAILED": {
  return { ...state,...action.payload }

}
    
    case "UPLOAD_PROGRESS": {
      return { ...state,...action.payload  }
  }
    case "UPLOAD_PROGRESS_MANUAL": {
      return { ...state,...action.payload  }
  }
    case "RESET_MANUAL_PROGRESS": {
      return { ...state,percetage_manual:''  }
  }

  //SITE SETTINGS
    case "SITE_SETTINGS_SUCCESS": {
      return { ...state,...action.payload  }
  }

  case "ACTIVE_COURSE_REQUEST": {
    return { ...state,...action.payload  }
}
case "ACTIVE_COURSE_SUCCESS": {
  return { ...state,...action.payload  }
}
    case "ACTIVE_COURSE_FAILED": {
      return { ...state,...action.payload  }
  }


    case "PENDING_COURSE_REQUEST": {
      return { ...state,...action.payload  }
  }
  case "PENDING_COURSE_SUCCESS": {
    return { ...state,...action.payload  }
}
case "PENDING_COURSE_FAILED": {
  return { ...state,...action.payload  }
}

case "SALES_ALL_REQUEST": {
  return { ...state,...action.payload  }
}
case "SALES_ALL_SUCCESS": {
return { ...state,...action.payload  }
}
case "SALES_ALL_FAILED": {
return { ...state,...action.payload  }
}

    case "REJECTED_COURSE_SUCCESS": {
      return { ...state,...action.payload  }
  }

  case "LIST_PAYMENT_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "LIST_PAYMENT_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "LIST_PAYMENT_FAILED": {
    return { ...state, ...action.payload }
  }

  case "LIST_FILTERPAYMENT_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "LIST_FILTERPAYMENT_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "LIST_FILTERPAYMENT_FAILED": {
    return { ...state, ...action.payload }
  }


  case "GROUPSTUDENT_ALL_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "GROUPSTUDENT_ALL_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "GROUPSTUDENT_FAILED": {
    return { ...state, }
  }

  case "COMMISSION_ALL_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "COMMISSION_ALL_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "COMMISSION_ALL_FAILED": {
    return { ...state,...action.payload }
  }


  case "ACCEPT_ALL_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "ACCEPT_ALL_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "ACCEPT_ALL_FAILED": {
    return { ...state,...action.payload }
  }
  

  /////////testimonial
  case "TESTMONY_ALL_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "TESTMONY_ALL_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "TESTMONY_ALL_FAILED": {
    return { ...state, ...action.payload  }
  }
 
  case "REFUND_ALL_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "REFUND_ALL_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "REFUND_ALL_FAILED": {
    return { ...state, ...action.payload  }
  }
  case "REFUND_PENDING_REQUEST": {
    return { ...state, ...action.payload, }
  }
  case "REFUND_PENDING_SUCCESS": {
    return { ...state, ...action.payload }
  }
  case "REFUND_PENDING_FAILED": {
    return { ...state, ...action.payload  }
  }
  
    default: {
      return { ...state }
    }

  }
}

// const Superadmin = (state = initialState, { type, payload }) => {
//     switch (type) {

//         case 'SET_PLIVILLAGES': {
//             return {
//                 ...state, privillages: payload
//             }
//         }
//         case 'PAYMENT_UPGRADE_POPUP_REQUEST': {
//             return {
//                 ...state, payment: { ...state.payment, payment_alert: true }
//             }
//         }
//         case 'PAYMENT_HISTORY_REQUEST': {
//             return {
//                 ...state, paymentHistoryLoader: true
//             }
//         }
//         case 'PAYMENT_HISTORY_SUCCESS': {
//             return {
//                 ...state, paymentHistoryLoader: false, payment_history: payload
//             }
//         }
//         case 'PAYMENT_SUCCESSFULLY_COMPLETED': {
//             return {
//                 ...state, payment: { ...state.payment, payment_alert: false }

//             }
//         }
//         case 'CHANGE_PLAN': {
//             let copy = state.payment
//             copy.plan_id = payload
//             return {
//                 ...state, payment: copy

//             }
//         }
//         case 'TOKEN_CHECK_EXPIRY_SUCCESS': {
//             return {
//                 ...state, payment: payload

//             }
//         }
//         case 'RESETT_PASSWORD_REQUEST': {
//             return {
//                 ...state, createPasswordLoader: true
//             }
//         }
//         case 'RESETT_PASSWORD_SUCCESS': {
//             return {
//                 ...state, createPasswordLoader: false
//             }
//         }
//         case 'RESETT_PASSWORD_FAILED': {
//             return {
//                 ...state, createPasswordLoader: false
//             }
//         }
//         case 'USER_AUTH_REQUEST': {
//             //console.log("loading")
//             return {
//                 ...state, loader: true
//             }
//         }
//         case 'AUTH_CHECK_SUCCESS': {

//             return {
//                 ...state, tokenExpire: payload
//             }
//         }
//         case 'USER_AUTH_FAILED': {
//             return {
//                 ...state, loader: false
//             }
//         }

//         case 'RESET_EXPIRE': {

//             return {
//                 ...state, tokenExpire: null
//             }
//         }
//         case 'USER_AUTH_ENTERPRISE_REQUEST': {
//             return {
//                 ...state, enterpriseLoader: true
//             }
//         }
//         case 'USER_AUTH_ENTERPRISE_SUCCESS': {
//             return {
//                 ...state, enterpriseLoader: false, currentUser: payload,

//             }
//         }
//         case 'USER_AUTH_SUCCESS': {
//             // console.log("success", payload)
//             return {
//                 ...state, loader: false, currentUser: payload,

//             }
//         }
//         case 'RESET_VALIDATION': {
//             return {
//                 ...state, currentUser: {},
//             }
//         }

//         default:
//             return state
//     }

// }

// export default Superadmin