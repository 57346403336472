import React,{useState,} from 'react'
import { Label, Input, Form, Col } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from "react-ladda";
import Swal from 'sweetalert2'
import {useDispatch} from 'react-redux'
import {createRefund,listAllPayments } from '../../../redux/Actions/Superadmin'

function RefundAmount(props) {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch ()

    const {student,setRefundModal} = props

    // console.log("student",student)
   
    const AmountAsArray = student.fee.split(" ") ;
    const Amount = AmountAsArray[0] ;

    const [Refund, setRefund] = useState()
    const [FullAmountEnabled, setFullAmountEnabled] = useState(false)

    const submitRefund = () => {

     

      if(FullAmountEnabled){

        Swal.fire({
          icon: 'warning',
          title: 'Are you sure you want to refund ' + student?.student_name+  ' with ' + Amount,
          //text: 'All the classes under the subject will be deleted',
    
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "CONFIRM",
          cancelButtonText: "CANCEL",
    
        }).then((result) => {
          if (result.value) {
            setLoader(true)
            dispatch(createRefund(tokenData.token,student.order_id,Amount, cb,cb2))
    
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Cancelled',
              'error'
            )
          }
        })

      }
     else{
      if(Number(Refund)>Number(Amount))
      {
       Swal.fire('Exceeds','Entered Refund Amount Is Greater Than Paid Amount', 'error')
        }else{
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to refund ' + student?.student_name+  ' with ' + Refund,
            //text: 'All the classes under the subject will be deleted',
      
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
      
          }).then((result) => {
            if (result.value) {
              setLoader(true)
              dispatch(createRefund(tokenData.token,student.order_id,Refund,cb,cb2))
      
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Cancelled',
                'error'
              )
            }
          })
        } 
     }
    }

    const [Loader, setLoader] = useState(false)

    const cb = () =>{
      dispatch(listAllPayments(tokenData.token))
      setLoader(false)
      setRefundModal(false)
    }
    const cb2=()=>{
      setLoader(false)
    }
    return (
        <>
            <Form>
               <div style={{display:'flex', justifyContent:'space-between'}}>  <Label>Amount  </Label> <span > Full Amount <input value={FullAmountEnabled} onChange={()=> setFullAmountEnabled(!FullAmountEnabled)} type='checkbox'></input></span> </div>
                <Input type='number' value={FullAmountEnabled? Amount : Refund} onChange={ (e) => setRefund(e.target.value)} disabled={FullAmountEnabled} ></Input>
               
                <Col xs={12} style={{textAlign:'right', paddingTop:'5px'}}>
                <LaddaButton className=" btn btn-primary"
                  loading={Loader}
                  type="button"
                  onClick={() => submitRefund()}
                  data-style={ZOOM_IN}
                >
                  Submit
                </LaddaButton>
                 </Col>

            </Form>
        </>
    )
}

export default RefundAmount
