import React, { Fragment, useEffect, useState } from 'react';
// import logo1 from "./images/logotwo.png"
import logo from "./images/logo-white.png"
import logoblue from "../../assets/images/Skillablers_logo1.png"
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom'
import { getAllSiteSettings, setHomeData,CommonsearchCourse,LogoutSession } from "../../redux/Actions/genexhome"
import { useDispatch, useSelector } from "react-redux";
import ScriptTag from 'react-script-tag';

import './css/animate.css';
import './css/elegant_icon.css';
import "./css/genex-custom.css"
import './css/hover-min.css';
import "./css/style.css"
import "./css/style.css.map"
import "./css/swiper.css"

function Header() {
    const history = useHistory();
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const Menu_section = useSelector((state) => state.genexhome.Menu_section ? state.genexhome.Menu_section : "");
    const tab_data_length = useSelector((state) => state.genexhome.Menu_section ? state.genexhome.Menu_section[3] ? state.genexhome.Menu_section[3].menu_content ? state.genexhome.Menu_section[3].menu_content.length : 0 : 0 : 0);
    const social_media_links = useSelector((state) => state.genexhome.HomeArray ? state.genexhome.HomeArray.social_media_links ? state.genexhome.HomeArray.social_media_links:[]:[]);
    const Common_Search_loading = useSelector((state) => state.genexhome.Common_Search_loading ? state.genexhome.Common_Search_loading:false);


    
    useEffect(() => {
        dispatch(getAllSiteSettings())
        dispatch(setHomeData('page_number', 1))
    }, []);

  
    const [clicked, setClicked] = useState(false)
    const [id, setId] = useState(false)
    const [menu, setMenu] = useState(false)
    const [search_term, setSearchtearm] = useState('')


    const Change = (ky) => {
        setClicked((prev) => !prev)
        setId(ky)
    }
    const two = { display: 'block' }

    const Menuclick = () => {
        setMenu((prev) => !prev)

    }

    const Handlechange=(e)=>{
        setSearchtearm(e.target.value)
    }

    const logoutClick = () => {
        dispatch(LogoutSession(tokenData.token))
        localStorage.removeItem("authDetails");
        history.push('/login')
    }

    const SearchCourse=(e)=>{
        e.preventDefault();
        dispatch(setHomeData('search_term',search_term))
        dispatch(CommonsearchCourse(search_term,1,cb))
    }

    const cb=()=>{
        history.push(`/search-results`);
    }
    const Homepageskillabers=()=>
    {
        window.open("https://www.skillablers.com/#/home",'_self')
    }
    return (
        <Fragment>


            <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + "/js/js/bootstrap.min.js"}></ScriptTag>
            {

                Menu_section ?
                    <header class="gradient-white box-shadow">

                        <div class="header-output" style={{ backgroundColor: '#cce7f3' }}>
                            <div class="container header-in">
                                <div class="row">
                                    <div class="col-xl-2 col-lg-2 col-sm-12 pr-md-0 pr-3">
                                        {/* <Link id="logo " to="/" class="d-inline-block margin-tb-5px"><img src={logoblue} height='60px' alt="" /></Link> */}    {/* commented by dhanasekar */}
                                        <div class="d-inline-block margin-tb-5px"><img src={logoblue} height='60px' alt="" onClick={Homepageskillabers}/></div>
                                        <a style={{ color: 'white', cursor: 'pointer' }} class="mobile-toggle padding-15px background-main-color" onClick={Menuclick}><i class="fas fa-bars"></i></a>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-sm-2 d-none d-lg-block d-md-none d-sm-none col-sm-2 my-auto">
                                        <div style={{width:'100%'}}>
                                            
                                            <form onSubmit={SearchCourse}>
                                                <div class="input-group" style={{border:'2px solid #20a8d8',borderRadius:'5px',boxSizing:'border-box',padding:'2px 0px'}}>
                                                    <input
                                                        type={"text"}
                                                        class="form-control gn-form-control"
                                                        aria-label="Small"
                                                        placeholder="What are you looking for"
                                                        aria-describedby=""
                                                        name='password'
                                                        value={search_term}
                                                        autocomplete="off"
                                                        onChange={Handlechange}
                                                        style={{borderRadius:'15px',marginLeft:'5px',color:'black',background:'#cce7f3',border:'none'}}
                                                    />
                                                    <div class="input-group-append" style={{marginRight:'8px'}}>
                                                        {
                                                            Common_Search_loading ? 
                                                                <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                                    <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                </span>
                                                                :
                                                                <span style={{ cursor: 'pointer',background:'#cce7f3',color:'black' }} onClick={SearchCourse} class="input-group-text" id=""><i class={"fa fa-search"}></i></span>

                                                        }
                                                           
                                                    </div>

                                                </div>
                                            </form>
                                        </div>  
                                    </div>
                    
                                    <div class="col-xl-7 col-lg-7  position-inherit">
                                        <div class="float-lg-right">
                                            <ul id={menu ? "menu-main" : 'menu-mainhide'} class="float-lg-left nav-menu link-padding-tb-25px dropdown-dark">
                                            <li><Link id="logo" to="/" style={{color:'black',opacity:'.9',marginRight:'15px'}}>Home</Link></li>
                                                {
                                                    Menu_section.map((val, ky) => {
                                                        if (ky === 3) {
                                                            return (
                                                                <li class="mega-menu mega-links mega-links-4columns" style={{ cursor: 'pointer' }} key={ky}><a style={{color:'black',opacity:'.9',marginRight:'15px'}} onClick={() => { Change(ky) }}>{val.menu_name}</a>
                                                                    {
                                                                        val.menu_content.length > 0 ?
                                                                            <div class="mega-menu-out sub-menu-out">
                                                                                <ul style={(clicked && menu && id === ky) ? two : {}} class="sub-menu-columns">
                                                                                    {
                                                                                        val.menu_content.map((val2, ky2) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <li>
                                                                                                        <ul class="mega-menu-list" style={(clicked && menu && id === ky) ? two : {}}>
                                                                                                            <li key={ky2}>
                                                                                                                <Link  to={{ pathname: `/categories/${val2.tag_id}` }}>{val2.tag_name}</Link>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </li>
                                                                                                    {
                                                                                                        ky2 + 1 === tab_data_length && tab_data_length !== 0 ?
                                                                                                            <li>
                                                                                                                <ul class="mega-menu-list" style={(clicked && menu && id === ky) ? two : {}}>
                                                                                                                    <li>
                                                                                                                        <Link to='/categories' style={{ color: '#000' }}><i class="fa fa-angle-double-right mr-2"></i>View More</Link>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </li>
                                                                                                            : ''

                                                                                                    }

                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            :
                                                                            ''
                                                                    }
                                                                </li>
                                                            )
                                                        }
                                                    })
                                                }
                                                <li><Link style={{color:'black',opacity:'.9',marginRight:'15px'}} to='/vendors'>Providers</Link></li>
                                                <li><Link style={{color:'black',opacity:'.9',marginRight:'15px'}} to='/subscriptions'>Subscriptions</Link></li>
                                                <li><Link style={{color:'black',opacity:'.9',marginRight:'15px'}} to="/contact" class="">Contact Us</Link></li>
                                                {
                                                !tokenData ?
                                                    <>
                                                        <li style={{marginRight:'4px'}}><button  onClick={()=>history.push('/login')} 
                                                        style={{border:'none',borderRadius:'20px',color:'white',background:'#20a8d8',padding:'5px 15px'}}
                                                        >Login</button></li>
                                                        <li class='signup-margintop'><button  onClick={()=>history.push('/student-register')} 
                                                        style={{border:'none',borderRadius:'20px',color:'white',background:'#20a8d8',padding:'5px 15px'}}
                                                        >Sign up</button></li>
                                                    </>
                                                    :
                                                    <>
                                                        <li class="list-inline-item  padding-right-0px"><Link to="/dashboard" style={{color:'black',opacity:'.9'}}>Dashboard</Link></li>
                                                        <li><button onClick={logoutClick} style={{border:'none',borderRadius:'20px',color:'white',background:'rgb(68 172 255)',padding:'5px 15px'}}>Logout</button></li>
                                                    </>
                                            }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    :
                    ''
            }

        </Fragment>

    )
}

export default Header


