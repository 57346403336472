import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  // Form,
  // FormGroup,
  Input,
  Label,
  Container,
  FormFeedback,
} from "reactstrap";
import SweetAlert from 'sweetalert-react';

import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { emailValidator, requiredValidator,} from '../../../utilities/Validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./styleph.scss"
import { populateResellerDetail,updateReseller } from "../../../redux/Actions/Superadmin"
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
import { useParams } from 'react-router-dom'
// import { getDerivedStateFromError } from "react-widgets/lib/Combobox";
import { useHistory } from "react-router-dom";
import Modal from '../../common/Modal'



const EditReseller = () => {
 
  const dispatch=useDispatch()
  const history = useHistory();
  


  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const params = useParams()

  const [inputField,SetInput]=useState(null)
  const [errors, setErrors] = useState({})
  // const [value, setValue] = useState()
  const [successModal, setSuccessModal] = useState(false);

  const edit_rellseller = useSelector((state) =>state.superadmin.edit_rellseller ? state.superadmin.edit_rellseller : false);


  useEffect(()=>{
    dispatch(populateResellerDetail(tokenData.token,params.reseller_id,cb))
   
  },[])

  const cb=(formData)=>{
    SetInput(formData)
    
  } 

  const handleChangeInput=(event)=>{
    SetInput({...inputField,[event.target.name]:event.target.value})
  }

  const Submit = () => {
    const err = {
      email: emailValidator(inputField.email),
      reseller_name: requiredValidator(inputField.reseller_name),
    //   password: requiredValidator(inputField.password),
      number: requiredValidator(inputField.contact_number),
      area: requiredValidator(inputField.area),
      pin: requiredValidator(inputField.pin),
      address: requiredValidator(inputField.address),
      commision: requiredValidator(inputField.commision),
    };
    if (
      !err.email &&
    //   !err.password &&
      !err.number &&
      !err.reseller_name &&
      !err.area &&
      !err.pin &&
      !err.address &&
      !err.commision
    ) {
      setErrors(err);
        dispatch(updateReseller(
            tokenData.token,
            inputField.reseller_name,
            inputField.contact_number,
            inputField.email,
            inputField.password ? inputField.password:'',
            inputField.address,
            inputField.area,
            inputField.pin,
            inputField.commision,
            params.reseller_id,
            handleOpenSuccessModal,
            errormodel
        ))
    } else {
      setErrors(err);
      if(err.reseller_name){
        Showerror('reseller_name')
      }
      // else if(err.number){
      //   Showerror('number')
      // }
      else if(err.email){
        Showerror('email')
      }
      else if(err.address){
        Showerror('address')
      }
      else if(err.address){
        Showerror('area')
      }
      else if(err.address){
        Showerror('pin')
      }
      else{
        Showerror('commision')
      }
    }
  };
  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

  const [err, setErr] = useState({
    open_err:false,
    err_list:[]
  })
  const errormodel=(val)=>{
    setErr({
      open_err:true,
      err_list:val
    })  
  }
  const closeErr = () => {
    setErr({
      open_err:false,
      err_list:[]
    })
  }

    const handleOpenSuccessModal = () => {
        setSuccessModal(true)

    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        history.push('/dashboard/reseller')
    }


  const Handlechangephone=(e)=>{
    SetInput({...inputField,number:e})

  }
  return (
    <div>
      {
          inputField ? 
          <>
<PageTitle
          style={{ display: "none" }}
          heading="Edit Reseller"
          subheading="Edit Reseller Details"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardBody>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>BASIC DETAILS</CardTitle>
                    <Container>
                      <Row>
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Reseller Name
                          </Label>
                          <Input
                            type="text"
                            name="reseller_name"
                            invalid={errors.reseller_name}
                            onChange={handleChangeInput}
                            value={inputField.reseller_name}
                            id='reseller_name'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.reseller_name}</FormFeedback>

                        </Col>
                        
                        <Col sm={12}  className="assign-form-grid">
                          {/* <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Number
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            name='number'
                            value={inputField.number}
                            invalid={errors.number}
                            onChange={handleChangeInput}

                          />
                          <FormFeedback>{errors.number}</FormFeedback> */}

                                <Label for="exampleName">
                                <span className="text-danger">*</span>
                                {' '}Mobile Number</Label>
        
                                <PhoneInput
                                value={inputField.contact_number}
                                name="contact_number"
                                country={'in'}
                                onChange={(e) => Handlechangephone(e)}
                                invalid={errors.number}
                                id='number'
                                />
                                {
                                  errors.number ? 
                                  <span style={{color:'red'}}>{errors.number}</span>:''
                                }
                                
                                {/* <PhoneInput
                                      placeholder="Enter phone number"
                                      value={value}
                                      onChange={setValue}/> */}
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span> Email ID
                          </Label>
                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            style={{ width: "100%" }}
                            name="email"
                            invalid={errors.email}
                            value={inputField.email}
                            id='email'
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.email}</FormFeedback>
                        </Col>
                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Password
                          </Label>
                          <Input
                            type="password"
                            style={{ width: "100%" }}
                            value={inputField.password ? inputField.password :''}
                            name="password"
                            onChange={handleChangeInput}
                            autocomplete="off"
                          />
                        </Col>
                        
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            rows={4}
                            style={{ width: "100%" }}
                            name="address"
                            value={inputField.address}
                            invalid={errors.address}
                            onChange={handleChangeInput}
                            id='address'
                          />
                          <FormFeedback>{errors.address}</FormFeedback>
                        </Col>                      

                        <Col xs={12} sm={4} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Municipality
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            name="area"
                            value={inputField.area}
                            invalid={errors.area}
                            onChange={handleChangeInput}
                            id='area'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.area}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={4} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            PIN
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            name="pin"
                            value={inputField.pin}
                            invalid={errors.pin}
                            onChange={handleChangeInput}
                            id='pin'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.pin}</FormFeedback>
                        </Col>

                        
                        <Col xs={12} sm={4} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Commision
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            name="commision"
                            value={inputField.commision}
                            invalid={errors.commision}
                            onChange={handleChangeInput}
                            id='commision'
                            min={0}
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.commision}</FormFeedback>
                        </Col>
                          
                       

                      </Row>
                    </Container>
                </CardBody>
              </Card>
              <LaddaButton
                className="mb-2 mr-2 btn btn-primary"
                loading={edit_rellseller}
                type="submit"
                data-style={ZOOM_IN}
                onClick={Submit}
              >
                UPDATE RESLLER
              </LaddaButton>
          </CardBody>
        </Card>
          </>
          :''
      }
        

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error,ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>
    </div>
  );
};

export default EditReseller;
