import React, { useState, Fragment,useEffect } from 'react';
import { useDispatch, } from "react-redux";
import { changePassword } from '../../redux/Actions/homeTeacher'
import { resetPassword } from '../../redux/Actions/Superadmin'
import SweetAlert from 'sweetalert-react';
import {
    Label,
    FormGroup,
    Form,
    Row, Col,
    Container,
    Card,
    CardBody,
    CardTitle,
    Input,
    FormFeedback,
    // Card
} from 'reactstrap';
import { requiredValidator} from '../../utilities/Validator'
import LaddaButton, { ZOOM_IN } from "react-ladda";
import Swal from 'sweetalert2'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom'

const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const history = useHistory()
    const [successModal, setSuccessModal] = useState();
    const [message, setmessage] = useState();
    const [messageType, setmessageType] = useState();
    const [err, setErr] = useState({});
    const [passwordValid, setPasswordValid] = useState('');
    const [Loader, setLoader] = useState(false);

    const [loading,setLoading]=useState(false)
    useEffect(()=>{
        dispatch(resetPassword(tokenData.token,cb))
    },[])

    const cb=(val)=>{
        if(val){
            setLoading(true)
        }
        else{
            Swal.fire({
                icon: 'warining',
                // title: 'Token Expired',
                text: 'This service is not available right now'
            }).then(()=>{
                history.pushState('/dashboard/overview')
            })
        }
    }


    const [password,setPassword]=useState({
        current_password:'',
        new_password:'',
        confirm_password:''
    })

    const handleOpenSuccessModal = (resultMessage, messageType) => {
        setLoader(false)
        setmessage(resultMessage)
        setmessageType(messageType)
        setSuccessModal(true)


    }

    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
    }

    const submitForm = (e) => {
        e.preventDefault();
        setPasswordValid('')
        const error={
            current_password:requiredValidator(password.current_password),
            new_password:requiredValidator(password.new_password),
            confirm_password:requiredValidator(password.confirm_password),
        }
        if( !error.current_password && !error.new_password && !error.confirm_password){
            let check=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[A-Za-z\d@#$%^&+=]{8,}$/
            if (password.new_password.length < 8 || password.new_password.length > 20) {
                setPasswordValid('Password length should be minimum 8 and maximum 20')
            }
            else if(!check.test(password.new_password)){
                setPasswordValid('Password should contain at least 1 uppercase, 1 lowercase, any 1 special character from @#$%^&+= and at least 1 digit')
            }
            else{
                setErr(error)
                setLoader(true)
                dispatch(changePassword(tokenData.token, password, handleOpenSuccessModal))
            }
        }
        else{
            setErr(error)
        }
    }
    
    const HandleChange=(e)=>{
        const {name,value}=e.target
        setPassword({...password,[name]:value})
    }
    return (
        <>
        {
            loading ? 
            <Form onSubmit={submitForm} id="class-form" >

                <Fragment>
                    <Container fluid>
                        <Row>
                            <Col md="3">
                            </Col>
                            <Col md="6">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <CardTitle>Change Password</CardTitle>

                                        <FormGroup>
                                            <Label for="examplePassword">Current Password</Label>
                                            <Input 
                                                type="password" 
                                                name="current_password" 
                                                id="examplePassword"
                                                placeholder="" 
                                                onChange={HandleChange}
                                                invalid={err.current_password}
                                                value={password.current_password}

                                                />
                                                 <FormFeedback>{err.current_password}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="examplePassword">New Password</Label>
                                            <Input 
                                                type="password" 
                                                name="new_password" 
                                                id="examplePassword"
                                                placeholder="" 
                                                onChange={HandleChange}
                                                invalid={err.new_password || passwordValid}
                                                value={password.new_password}
                                                />
                                                <FormFeedback>{err.new_password || passwordValid}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="examplePassword">Confirm Password</Label>
                                            <Input 
                                                type="password" 
                                                name="confirm_password" 
                                                id="examplePassword"
                                                placeholder="" 
                                                onChange={HandleChange}
                                                invalid={err.confirm_password}
                                                value={password.confirm_password}

                                                />
                                                <FormFeedback>{err.confirm_password}</FormFeedback>
                                        </FormGroup>

                                        <LaddaButton
                                            className="mb-2 mr-2 btn btn-primary"
                                            loading={Loader}
                                            type="submit"
                                            data-style={ZOOM_IN}
                                        >
                                            Submit
                                        </LaddaButton>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>



                    </Container>
                </Fragment >
            </Form>
            :
            <>
                        <Card style={{ padding: '30px' }}>
                            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                <Skeleton height={30} count={5} />
                            </SkeletonTheme>
                        </Card>
                        <Card style={{ padding: '30px', marginTop: '25px' }}>
                            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

                                <Skeleton height={30} count={4} />
                            </SkeletonTheme>
                        </Card>
                    </>
        }
            

            <SweetAlert
                title="Message"
                confirmButtonColor=""
                show={successModal}
                text={message}
                type={messageType}
                onConfirm={() => handleCloseSuccesstModal()}
            />
        </>
    )

}
export default AnalyticsDashboard1