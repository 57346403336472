import React,{useEffect,useState} from "react";
import {
  Row,
  Col,
  Card,
  Badge,
 
} from "reactstrap";
import {
  faUser,
  faEnvelope,
  faPhone,
  faCalendarAlt,
  faLocationArrow,
  faSchool,
  faPassport
} from "@fortawesome/free-solid-svg-icons";
// import ViewStudent from './viewStudent'
// import Swal from 'sweetalert2'
import { Link,useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TimeShedule from './viewTime'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import AssignStudents from './addStudentToClass'
// import Modals from '../../common/Modal'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
 import { useDispatch } from 'react-redux'
// import { deleteClasses, validateClassTiming, editClass, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateAllEnterpriseUserClass, getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
// import { useHistory } from "react-router-dom";
import {getStudentDetails} from '../../../redux/Actions/Superadmin'
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import {
    faHome

} from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

const Singlestudent = () => {

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const dispatch = useDispatch()
  const params = useParams()

  const [PersonalDetails, setPersonalDetails] = useState()
  const [StudentDetails, setStudentDetails] = useState()
  const [Courses, setCourses] = useState()
  const [Payments, setPayments] = useState()
  const [Tickets, setTickets] = useState()

  useEffect(() => {
   dispatch(getStudentDetails(tokenData.token,params.student_id,cb))
    
  }, [])

  const cb = (student) => {
    setStudentDetails(student)
    setPersonalDetails(student.student)
    setCourses(student.courses)
    setPayments(student.payments)
    setTickets(student.tickets)
  }

  // const history = useHistory();

  const columns = [
    { title: "Order ID", field: "order_id" },
    { title: "Course", field: "course_name" },
    { title: "Purchased_Date", field: "purchase_date" },
    { title: "Fees", field: "fee" },
    {
      title: "Payment_Status",
      field: "status",
      render: (rowData) => {
        return rowData.status === "Pending" ? (
          <Badge color="warning" pill>
            {rowData.status}
          </Badge>
        ) : (
          <Badge color="success" pill>
            {rowData.status}
          </Badge>
        );
      },
    },
  ];
  // const data = [
  //   {orderid: "1233",course: 'ASDF',purchasedate:'12-02-2021',fee:'300',status:'Completed'},
  //   {orderid: "1233",course: 'ASDF',purchasedate:'12-02-2021',fee:'300',status:'Due'},

  // ]


  const columns2 = [
    { title: "Date", field: "payment_date" },
    { title: "Order Id", field: "order_id" },
    { title: "Course", field: "course_name" },
    { title: "Amount", field: "fee" },
   
  ];
  
  // const data2 = [
  //   {date:'12-02-2021',orderid: "1233",course: 'ASDF',fee:'300'},
  //   {date:'12-02-2021',orderid: "3222",course: 'KJHGF',fee:'900'},
  //   {date:'12-02-2021',orderid: "234",course: 'DSDA',fee:'300'}
  // ]
  const columns3 = [
    { title: "Issued", field: "issued_date" },
    { title: "Order ID", field: "order_id" },
    { title: "Course", field: "course" },
    { title: "Title", field: "title" },
    { title: "Status", field: "status",
    render: (rowData) => {
      return rowData.status === 'Pending' ? (
        <Badge color="danger" pill>
          {"Pending"}
        </Badge>
      ) : rowData.status === 'Inprogress' ? (
        <Badge color="warning" pill>
          {"Inprogress"}
        </Badge>
      ) : (
        <Badge color="success" pill>
          {"Resolved"}
        </Badge>
      );
    },
  },
  ];
  // const data3 = [
  //   {date:'12-02-2021',orderid: "1233",course: 'ASDF',title:'order',status:'Pending'},
  //   {date:'12-02-2021',orderid: "3222",course: 'KJHGF',title:'general',status:'Inprogress'},
  //   {date:'12-02-2021',orderid: "234",course: 'DSDA',title:'order',status:'Resolved'}
  // ]


  //   const action = "student_table"

  return (
    <>
    <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
         <Row>
          <Col item xs={12}>
            <Breadcrumb  style={{width:'100%'}}>
          <BreadcrumbItem>
            <Link to="/dashboard">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
          {
            tokenData.role==='superAdmin' ? 
            <Link to="/dashboard/student">Student List</Link>
            :
            <Link to="/dashboard/studentList">Student List</Link>

          }
          </BreadcrumbItem>
          <BreadcrumbItem active>Details</BreadcrumbItem>
        </Breadcrumb>
        </Col>
        </Row>

        <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white",display:'flex',alignItems:'center' }}>
          <div
              className={cx("page-title-icon")}>
              <i className='pe-7s-user icon-gradient bg-sunny-morning' />
          </div>
          <div>
              <span style={{ textTransform: 'uppercase' }}>{PersonalDetails?.full_name}</span> <br/>
              {/* <span style={{}}>Active</span> */}
          </div>
      </div>

      <Row>
        <Col xs={12}>
        <Row>
            <Col sm="12" md="6">
              <Card
                style={{ height: "310px" }}
                className="assign-details-card assign-details-card-descrip"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>
                    <strong>Details</strong>
                  </h6>
                </div>
                <hr />
                <Row>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    {"Name"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.full_name}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    {"Email"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.email}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    {"Phone"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.phone}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </span>
                    {"PIN"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.pin}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </span>
                    {"Area"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.area}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faSchool} />
                    </span>
                    {"School"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.student_school}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faPassport} />
                    </span>
                    {"Referral_ID"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{PersonalDetails?.genex_referral_code}</span>
                  </Col>

                </Row>
              </Card>
            </Col>
            <Col sm="12" md="6" style={{ marginTop: "15px" }}>
              <Row>
                <Col md="12">
                  <div
                    style={{ color: "white", backgroundColor: "#d92550" }}
                    className="card mb-3 widget-chart card-hover-shadow-2x text-left"
                  >
                    <div className="icon-wrapper border-light rounded">
                      <div className="icon-wrapper-bg bg-light" />
                      <i className="lnr-cart icon-gradient bg-heavy-rain" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-numbers">{StudentDetails?.total_orders}</div>
                      <div className="widget-subheading">Purchased Orders</div>
                    </div>
                  </div>
                </Col>

                <Col md="12">
                  <div
                    style={{ color: "white", backgroundColor: "#3FD2C7 " }}
                    className="card mb-3 widget-chart card-hover-shadow-2x text-left"
                  >
                    <div className="icon-wrapper border-light rounded">
                      <div className="icon-wrapper-bg bg-light" />
                      <i className="lnr-tag icon-gradient bg-heavy-rain" />
                    </div>
                    <div className="widget-chart-content jms-opacity-overwrite">
                      <div className="widget-numbers">{StudentDetails?.total_tickets}</div>
                      <div className="widget-subheading">Ticket Raised</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
        <Row>
          <Col sm={12} md={12} style={{marginTop:'6px'}}>
            <Table
              // actions={action}
              data={Courses}
              title={"Course Details"}
              columns={columns}
              // DetailsofStudent={Studentview}
            />
          </Col>
      </Row>
        

        <Row>
          <Col xs={12} style={{marginTop:'6px'}}>
            <Card>
              <CardHeader>Ticket Details</CardHeader>
              <CardBody>
                <Row>
                    <Col xs="12" sm="4" >
                        <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-danger border-danger">
                              <div className="widget-chat-wrapper-outer">
                                  <div className="widget-chart-content">
                                      <div className="widget-title opacity-5 text-uppercase">
                                          Pending
                                      </div>
                                      <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                          <div className="widget-chart-flex align-items-center">
                                              <div>
                                                  {StudentDetails?.total_pending}
                                              </div>
                                              <div className="ml-auto">
                                                  <div className="icon-wrapper rounded-circle">
                                                      <div className="icon-wrapper-bg opacity-9 bg-danger"/>
                                                          <i className="lnr-enter text-white"/>
                                                      </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </Card>
                      </Col>
                      <Col xs="12" sm="4"  style={{marginTop:'6px'}}>
                      <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-warning border-warning">
                              <div className="widget-chat-wrapper-outer">
                                  <div className="widget-chart-content">
                                      <div className="widget-title opacity-5 text-uppercase">
                                          In Progress
                                      </div>
                                      <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                          <div className="widget-chart-flex align-items-center">
                                              <div>
                                              {StudentDetails?.total_inprogress}
                                              </div>
                                              <div className="ml-auto">
                                                  <div className="icon-wrapper rounded-circle">
                                                      <div className="icon-wrapper-bg opacity-10 bg-warning"/>
                                                          <i className="lnr-hourglass text-dark opacity-8"/>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                      </Card>
                    </Col>
                    <Col xs="12" sm="4"  style={{marginTop:'6px'}}>
                          <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-success border-success">
                            <div className="widget-chat-wrapper-outer">
                                <div className="widget-chart-content">
                                    <div className="widget-title opacity-5 text-uppercase">
                                        Resolved
                                    </div>
                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                        <div className="widget-chart-flex align-items-center">
                                            <div>
                                            {StudentDetails?.total_resolved}
                                            </div>
                                            <div className="ml-auto">
                                            <div className="icon-wrapper rounded-circle">
                                                <div className="icon-wrapper-bg opacity-9 bg-success"/>
                                                    <i className="lnr-checkmark-circle text-white"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
      
                    <Col sm={12} md={12}>
                      <Table
                        // actions={action}
                        data={Tickets}
                        title={"Ticket Details"}
                        columns={columns3}
                        // DetailsofStudent={Studentview}
                      />
                    </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={12} style={{marginTop:'6px'}}>
            <Table
              // actions={action}
              data={Payments}
              title={"Payment Details"}
              columns={columns2}
              // DetailsofStudent={Studentview}
            />
          </Col>
        </Row>
      </CSSTransitionGroup>
    </>
  );
};

export default Singlestudent;
