import React from 'react';
import { ModalHeader, ModalBody,} from 'reactstrap';
import Rodal from 'rodal';

function Index(props){

    const { title, children, handleClose, open, size,anim } = props;
    return(
        <Rodal visible={open}
                onClose={handleClose}
                animation={anim?anim:'door'}
                showMask={false}
                size={size ? size :"md"}
        >
            <ModalHeader style={{backgroundColor:'white'}}>
                    {title ? title:''}
            </ModalHeader>
            <ModalBody>
                {children ? children:null}
            </ModalBody>
        </Rodal>

        // <Modal isOpen={open} fade={true} toggle={handleClose} size='md' 
        //     // className={this.props.className}
        // >
        //   <ModalHeader toggle={handleClose}>{title ? title:''}</ModalHeader>
        //   <ModalBody>
        //         {children ? children:null}
        //     </ModalBody>
 
        // </Modal>
    )
}
export default Index