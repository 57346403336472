
// import axios from 'axios';
// import Dropzone from 'react-dropzone'
import Table from './table'
// import DatePicker from 'react-datepicker';
import './assign.scss'
import { Progress } from 'react-sweet-progress';
// import serialize from 'form-serialize'
import moment from 'moment'
import {
  Alert,
  FormFeedback,
  Row, Col, Form, Input,
  Card, Label, Badge,

} from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { useDispatch, useSelector } from "react-redux";
import { updateAssignment, getAssignmentDescription, downloadTeacherAssignments, removeAssignment, populateStudentAssignment, getStudentAssignmentRemark, getAssignmnetMark, downloadAssignment, downloadStudentAssignments, getAssignmentsData, addAssignment, getStudentAssignments, getAssignmentData, filterBatchByClass } from '../../redux/Actions/assignment'
import { populateAllStartedClasses, populateAllUserClass, populateMySubscription } from '../../redux/Actions/homeTeacher'
import { populateMyCourse, populateClassForCourse, filterClassBySubscription } from '../../redux/Actions/vendor_course'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import SweetAlert from 'sweetalert-react';
import Modals from '../common/Modal'
import StudentTable from './StudentList/table'
import RespondToAssignment from './AssigmentRespond'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import LoadingOverlay from 'react-loading-overlay'
import { nameValid, requiredValidator } from '../../utilities/Validator'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker
} from '@material-ui/pickers'
import AccessTimeIcon from '@material-ui/icons/AccessTime';


const formData = new FormData();

const AddAssignment = (props) => {

  const courses = useSelector((state) =>
    state.homeTeacher.classes ? state.homeTeacher.classes : ""
  );
  const subscriptions = useSelector((state) =>
    state.homeTeacher.teacherSubscriptions ? state.homeTeacher.teacherSubscriptions : ""
  );
  const classes = useSelector((state) =>
    state.course.classesOfCourse ? state.course.classesOfCourse : ""
  );

  const loadclass = useSelector((state) =>
    state.course.loadclass ? state.course.loadclass : false
  );

  const history = useHistory();

  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const [Type, setType] = useState('')
  const [errorsModel, setErrorModel] = useState({})

  // useEffect(() => {


  // }, [history.location.pathname])

  useEffect(() => {
    // dispatch({type:'POPULATE_ALL_ASSIGNMENT_RESET'})
    if (history.location.pathname === '/dashboard/subscriptions/assignments') {
      setType('subscription')
      if (tokenData.role === 'teacher') {
        dispatch(populateMySubscription(tokenData.token))
        dispatch(getAssignmentsData(tokenData.token, 'subscription'))
      } else {
        dispatch(getStudentAssignments(tokenData.token, 'subscription'))
      }

    } else {
      setType('course')
      if (tokenData.role === 'teacher') {
        dispatch(getAssignmentsData(tokenData.token, 'course'))
        dispatch(populateAllUserClass(tokenData.token))
      } else {
        dispatch(getStudentAssignments(tokenData.token, 'course'))
      }
    }


  }, [history.location.pathname])


  useEffect(() => {


    if (tokenData.role === 'teacher') {
      // dispatch(populateAllUserClass(tokenData.token))
      dispatch(populateAllStartedClasses(tokenData.token))


    }
    // else if (tokenData) {
    //   console.log('ELSE  ROLE');
    //   dispatch(getStudentAssignments(tokenData.token))
    // }

    return () => {
      formData.delete('assignment[]');
      dispatch({ type: 'FILTER_BATCH_BY_CLASS_RESET' })
    }

  }, [history]);
  // const state = {
  //   file: []

  // }
  const [loader, setLoader] = useState();

  const [editData, setEditData] = useState();
  const [editModal, setEditModal] = useState(false)

  const [DeadLineDate, setDeadLineDate] = useState('')
  const [DeadLineTime, setDeadLineTime] = useState('')

  const editAssignment = (rowData) => {
    setEditData('')
    setInputfield({ ...inputField, 'deadline_date': '' })
    setDefaultEdit(null)
    setDeadLineEdit(null)
    setDeadLineDate(rowData.deadline_date)
    setDeadLineTime(rowData.deadline_time)
    setEditTimeAssignment(null)
    setEditData(rowData)
    setEditModal(true)
  }
  const columns = (Type === 'course' ? [
    {
      title: 'Assignment', field: 'title',
      render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Badge color="danger" pill>{rowData.new_notification}</Badge></>
    },
    { title: ' Course', field: 'course_name' },
    { title: ' Subject', field: 'subject_name' },
    { title: 'Class', field: 'class_name' },
    { title: 'Created_On', field: 'created_at' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: rowData => <Badge color="warning" pill>{rowData.deadline}</Badge>

    },
    {
      title: 'Status',
      render: rowData => <Badge color={`${rowData.submittedStatus ? `success` : `danger`}`} pill>{rowData.submittedStatus ? 'Submitted' : 'Not Submitted'}</Badge>

    },

  ] : [
    {
      title: 'Assignment', field: 'title',
      render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Badge color="danger" pill>{rowData.new_notification}</Badge></>
    },
    { title: ' Subscription', field: 'subscription_name' },
    { title: ' Subject', field: 'subject_name' },
    { title: 'Class', field: 'class_name' },
    { title: 'Created_On', field: 'created_at' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: rowData => <Badge color="warning" pill>{rowData.deadline}</Badge>

    },
    {
      title: 'Status',
      render: rowData => <Badge color={`${rowData.submittedStatus ? `success` : `danger`}`} pill>{rowData.submittedStatus ? 'Submitted' : 'Not Submitted'}</Badge>

    },

  ])

  const columnsTeacher = (Type === 'course' ? [
    {
      title: 'Assignment', field: 'title',
      render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Badge color="danger" pill>{rowData.new_notification}</Badge></>
    },
    { title: ' Course', field: 'course_name' },
    { title: ' Subject', field: 'subject_name' },
    { title: 'Class', field: 'class_name' },
    { title: 'Created_On', field: 'created_at' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: rowData => <Badge color="warning" pill>{rowData.deadline}</Badge>

    },

  ] : [
    {
      title: 'Assignment', field: 'title',
      render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Badge color="danger" pill>{rowData.new_notification}</Badge></>
    },
    { title: ' Subscription', field: 'subscription_name' },
    { title: ' Subject', field: 'subject_name' },
    { title: 'Class', field: 'class_name' },
    { title: 'Created_On', field: 'created_at' },
    {
      title: 'Deadline',
      field: 'deadline',
      render: rowData => <Badge color="warning" pill>{rowData.deadline}</Badge>

    },

  ])



  const columns2 = [
    { title: 'Name', field: 'first_name' },
    { title: ' LastName', field: 'last_name' },
    { title: ' status', field: 'status' },

  ]

  const handleChangeCourse = e => {
    const { name, value } = e.target;
    setInputfield({ ...inputField, [name]: value,class_id:'',group_id:'' })
    formData.set('course_id', value)
    dispatch(populateClassForCourse(tokenData.token, value))

  };

  const handleChangeSubscription = e => {
    const { name, value } = e.target;
    setInputfield({ ...inputField, [name]: value })
    formData.set('subscription_id', value)
    dispatch(filterClassBySubscription(tokenData.token, value))

  };
  const handleChangeBatch = e => {
    const { name, value } = e.target;
    setInputfield({ ...inputField, [name]: value })
    formData.set('batch_id', value)


  };


  const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)
  const assignment = useSelector(({ assignments }) => assignments)


  const downloadMyAssignment = (rowData) => {

    dispatch(downloadAssignment(tokenData.token, rowData.assignment_id, rowData.class_id, reloadTbl));

  }
  const teacherDownloadFile = (rowData) => {
    dispatch(downloadTeacherAssignment(tokenData.token, rowData.assignment_id, rowData.class_id, reloadTbl));

  }

  const downloadStudentFile = (rowData) => {

    dispatch(downloadAssignment(tokenData.token, rowData.assignment_id, rowData.class_id, reloadTbl));

  }

  const getTeacherRemark = (rowData) => {

    dispatch(getAssignmnetMark(tokenData.token, rowData.assignment_id, rowData.student_id, viewRemark));
  }
  const viewRemark = () => {
    var titleForStudentMark;
    if (assignment.remarkNew.mark == null)
      titleForStudentMark = 'Marks Not Added'
    else
      titleForStudentMark = assignment.remarkNew.mark + "/" + assignment.remarkNew.total + ' marks'
    Swal.fire({
      icon: '',
      title: titleForStudentMark,
      text: assignment.remarkNew.teacher_remark,

    })
  }

  const downloadStudentAssignment = (rowData) => {
    if (rowData.status == 'submitted') {
      dispatch(downloadStudentAssignments(tokenData.token, rowData.assignment_id, rowData.class_id, rowData.student_id, reloadTbl));
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Assignment not submited',

      })

    }
  }

  const handleTitleChange = (e) => {
    setEditData({ ...editData, title: e.target.value })
  }

  const downloadTeacherAssignment = (rowData) => {
    dispatch(downloadTeacherAssignments(tokenData.token, rowData.assignment_id, rowData.class_id, rowData.student_id, reloadTbl));
    // history.push(`https://srjtest.mojgenie.com/downloadAssignment?assignment_id=`+rowData.assignment_id+'&class_id='+rowData.class_id+'&student_id='+rowData.student_id);
  }
  const reloadTbl = () => {
    if (tokenData.role === 'teacher') {
      dispatch(populateAllStartedClasses(tokenData.token))
      dispatch(getAssignmentData(tokenData.token))


    }
    else if (tokenData) {
      if (Type === 'subscription') {
        dispatch(getStudentAssignments(tokenData.token, 'subscription'))
      } else {
        dispatch(getStudentAssignments(tokenData.token, 'course'))
      }
    }
  }
  // const [stList, setList] = useState();
  const populateStudents = (rowData) => {

    // dispatch(populateStudentAssignment(tokenData.token,rowData.assignment_id,rowData.class_id,loadStudentTable ))
    history.push(`/dashboard/assignments/${rowData.assignment_id}/${rowData.class_id}`)

  }



  // const loadStudentTable = () => {
  //   console.log('pop ', assignment);
  //   // if(assignment.length>0){
  //   setStudents(assignment.assignmentss);
  //   // history.push('/dashboard/assignments/students')
  //   // openStudentsModel();
  //   // }


  // }
  const deleteAssignment = (assign_id) => {
    ;
    dispatch(removeAssignment(tokenData.token, assign_id))


  }
  const [successModal, setSuccessModal] = useState();
  const [studModel, setStudModel] = useState();
  // const [students, setStudents] = useState([]);

  const [respond, setRespond] = useState();
  const [aAssignmentId, setaAssignmentId] = useState();
  const [class_id, setClassId] = useState();
  const [aAssignmentTitle, setTitle] = useState();
  // const [aAssignmentDescription, setDescription] = useState();

  const respondToAssignment = (rowData) => {

    if (rowData.deadline_status == 'completed') {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Submission has closed',

      })
    } else {
      setRespond(true);
      setaAssignmentId(rowData.assignment_id);
      setClassId(rowData.class_id);
    }
  }

  // const openStudentsModel = () => {
  //   setStudModel(true);

  // }
  const closeRespondModel = () => {
    setRespond(false);

  }

  // const openRespondModel = () => {
  //   setStudModel(true);

  // }
  const closeStudentsModel = () => {
    setStudModel(false);

  }

  const handleOpenSuccessModal = () => {
    setInputfield({
      title: '',
      grade_id: '',
      group_id: '',
      deadline: '',
      deadlineTime: '',
      assignment: '',
      description: '',
      marks: ''
    })
    setLoader(false);
    setDeadLine(null)
    setDeadLineEdit(null)
    setDefault(null)
    //debugger
    formData.delete('assignment[]');
    setSuccessModal(true)
    setVisible(false);
    if (history.location.pathname === '/dashboard/subscriptions/assignments') {
      dispatch(getAssignmentsData(tokenData.token, 'subscription'))
    }
    else {
      dispatch(getAssignmentsData(tokenData.token, "course"))
    }
    // document.getElementById("title").value = "";
    // document.getElementById("description").value = "";
    // document.getElementById("deadline").value = "";
    // document.getElementById("assingment").value = "";
    // document.getElementById("marks").value = "";
    document.getElementById("class-form").reset();



  }
  const [visible, setVisible] = useState(false);
  // const [demo, setDemo] = useState(false);
  const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
    resetForm();
  }

  const [errors, setErrors] = useState({})
  const onSubmit = (e) => {
    e.preventDefault()
    const err = {
      title: nameValid(inputField.title),
      course_id: requiredValidator(inputField.course_id) && requiredValidator(inputField.subscription_id),
      class_id: requiredValidator(inputField.class_id),
      group_id:requiredValidator(inputField.group_id),
      deadline: requiredValidator(inputField.deadline),
      deadlineTime: requiredValidator(inputField.deadlineTime),
      // assignment: requiredValidator(inputField.assignment),
      description: requiredValidator(inputField.description),
      marks: requiredValidator(inputField.marks)
    }
    // var imagefile = document.querySelector('#assignment');
    // this.props.onSubmitForm(this.formData);
    //debugger
    if (marksView) {
      if (!err.title && !err.course_id && !err.class_id && !err.deadline && !err.deadlineTime && !err.description && !err.marks && !err.group_id) {
        dispatch(addAssignment(tokenData.token, formData, handleOpenSuccessModal))
        setErrors(err)
        setLoader(true);
        setVisible(true)


      } else {
        setErrors(err)
        if (err.title) {
          Showerror('title')
        }
        else if (err.class_id) {
          Showerror('class_id')
        }
        else if (err.course_id) {
          Showerror('course_id')
        }
        else if (err.deadline) {
          Showerror('deadline')
        }
        else if (err.deadlineTime) {
          Showerror('timepicker')
        }
        else {
          Showerror('description')
        }
      }
    } else {
      if (!err.title && !err.course_id && !err.class_id && !err.deadline && !err.deadlineTime && !err.description && !err.group_id) {
        formData.append('type', Type)
        dispatch(addAssignment(tokenData.token, formData, handleOpenSuccessModal))
        setErrors(err)
        setLoader(true);
        setVisible(true)


      } else {
        setErrors(err)
        if (err.title) {
          Showerror('title')
        }
        else if (err.class_id) {
          Showerror('class_id')
        }
        else if (err.course_id) {
          Showerror('course_id')
        }
        else if (err.deadline) {
          Showerror('deadline')
        }
        else if (err.deadlineTime) {
          Showerror('timepicker')
        }
        else {
          Showerror('description')
        }
      }
    }
  }

  const Showerror = (val) => {
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
  }

  // const onSubmit = (e) => {
  //   e.preventDefault()

  //   const options = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;
  //       let percent = Math.floor((loaded * 100) / total)
  //       console.log(`${loaded}kb of ${total}kb | ${percent}%`);

  //       // if( percent < 100 ){
  //       //   this.setState({ uploadPercentage: percent })
  //       // }
  //     }
  //   }
  //   const head = {
  //     Accept: 'application/json',
  //     Authorization: `Bearer ${tokenData.token}`,
  //     'content-type': 'multipart/form-data'
  //   }
  //   axios.post("https://backend.mymots.com/api/uploadAssignments", formData, options, { headers: head }).then(res => {
  //     console.log("reee", res)
  //     // this.setState({ avatar: res.data.url, uploadPercentage: 100 }, () => {
  //     setTimeout(() => {
  //       // this.setState({ uploadPercentage: 0 })
  //     }, 1000);
  //     // })
  //   })
  // }






  const onChangeTitle = (e) => {
    const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })
    formData.set('title', value)
  }
  const onChangeMarks = (e) => {
    const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })


    formData.set('marks', e.target.value)
  }
  const onChangeDescription = (e) => {
    const { name, value } = e.target
    formData.set('description', e.target.value)
    setInputfield({ ...inputField, [name]: value })

  }

  const [DeadLine, setDeadLine] = useState(null)
  const [DeadLineEdit, setDeadLineEdit] = useState(null)



  const onChangeDeadline = (e, n) => {

    if (n === 'deadline_date') {
      setDeadLineEdit(e)
    }
    else { setDeadLine(e) }

    const value = moment(e).format("YYYY-MM-DD");
    const name = n
    // const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })

    formData.set('deadline', value)
    // console.log('date ', value)
    // var selDate = new Date(value);
    // var today = new Date();
    //  var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // console.log('selDate ', selDate)
    //  console.log('today ', today)

    // if (selDate.setHours(0, 0, 0, 0) != today.setHours(0, 0, 0, 0) && selDate < today) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: '',
    //     text: 'Enter a valid Date.',

    //   })
    //   e.target.value = "";
    // }


    // if (selDate < today && selDate != today) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: '',
    //     text: 'Enter a valid Date.',

    //   })
    // //  e.target.value = "";
    // }
  }

  const [Default, setDefault] = useState(null)
  const [DefaultEdit, setDefaultEdit] = useState(null)
  // const [EditValue, setEditValue] = useState(null)
  const [EditTimeAssignment, setEditTimeAssignment] = useState(null)

  const onChangeDeadlineTime = (e, n) => {
    const name = n;


    let a = String(e);
    const value = a.slice(16, 21);

    if (name === 'deadlineTime') {
      setInputfield({ ...inputField, [name]: value })
      formData.set(name, value)
    }
    else {
      // setEditData(value)
      setEditTimeAssignment(value)
    }

  }
  // const onChangeClassId = (e) => {
  //   formData.set('class_id', e.target.value)
  // }
  const onChange = (e) => {
    const { name, value } = e.target
    setInputfield({ ...inputField, [name]: value })

    formData.delete('assignment[]');
    for (let i = 0; i < e.target.files.length; i++) {

      formData.append('assignment[]', e.target.files[i])
    }


  }
  const resetForm = () => {

    document.getElementById("class-form").reset();
    setMarksView(false);

  }
  // const handleStudentList = () => {
  //   setSuccessModal(false)
  // }
  const [marksView, setMarksView] = useState(false);
  const handleChange = (val) => {

    if (val) {
      // console.log('toggle ', val);
      formData.set('grading_system', 'marks')
      setMarksView(true);
    } else {
      setMarksView(false);
      formData.delete('marks')
      formData.set('grading_system', 'grading')
    }
  }

  const handleChangeClass = (e) => {
    const { name, value } = e.target;
    setInputfield({ ...inputField, [name]: value })
    formData.set('class_id', value)
    dispatch(filterBatchByClass(tokenData.token, value))
  }


  const getDescription = (rowData) => {
    dispatch(getAssignmentDescription(tokenData.token, rowData.assignment_id, openDescription))
  }
  const [assignDescription, setAssignDescription] = useState({

  })
  const openDescription = (data) => {
    setAssignDescription({ ...assignDescription, popup: true, description: data.description })
  }
  const closeAssignDescription = () => {
    setAssignDescription({ ...assignDescription, popup: false, description: '' })

  }
  const handleClick = (rowData) => {
    history.push(`/dashboard/assignments/${rowData.assignment_id}/${rowData.class_id}`)
  }
 
  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {}
    obj.assignment_id = editData.assignment_id
    obj.deadline_date = inputField.deadline_date ? inputField.deadline_date : editData.deadline_date
    obj.deadline_time = EditTimeAssignment ? EditTimeAssignment : editData.deadline_time
    obj.deadline_title = editData.title
    // const form = e.currentTarget
    // let body = serialize(form, { hash: true, empty: true })

    const err = {
      // deadlinedate: inputField.deadline_date ? '' : '*Required',
      // deadlinetime: EditTimeAssignment ? "" : '*Required',
      title: editData.title ? "" : '*Required',
    };
    //  if (!err.deadlinedate && !err.deadlinetime && !err.title) {
    if (!err.title) {
      setErrorModel(err)
      dispatch(updateAssignment(tokenData.token, obj, updateSuccess))
    }
    else {
      setErrorModel(err)
    }


  }
  const updateSuccess = () => {
    // dispatch(getAssignmentsData(tokenData.token))
    if (history.location.pathname === '/dashboard/subscriptions/assignments') {
      dispatch(getAssignmentsData(tokenData.token, 'subscription'))
    }
    else {
      dispatch(getAssignmentsData(tokenData.token, "course"))
    }

    setEditModal(false)
  }
  const [inputField, setInputfield] = useState({
    title: '',
    grade_id: '',
    deadline: '',
    deadlineTime: '',
    assignment: '',
    description: '',
    marks: ''
  })

  const CloseUpdateModel = () => {
    setEditModal(false)
    setErrorModel('')
  }
  return (
    <>
      <>
        {tokenData.role === 'teacher' ? <>
          <>{
            Array.isArray(homeDetail.startClasses) ? homeDetail.startClasses.length == 0 ? <Alert color="danger" isOpen={true}
            //toggle={onDismiss}
            //toggle={""}
            > <strong>{`You have to start class first`}</strong>
            </Alert> : null : null
          }</>

          <LoadingOverlay tag="div" active={Array.isArray(homeDetail.startClasses) ? homeDetail.startClasses.length !== 0 ? true : false : false}
            styles={{
              overlay: (base) => ({
                ...base,
                background: '#fff',
                opacity: 0.5
              })
            }}
          >
            < Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary">
              <div className="widget-chat-wrapper-outer">
                {/* <LoadingOverlay tag="div" active={visible}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5
                  })
                }}
                spinner={
                  <Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />
                }> */}

                <form onSubmit={onSubmit} id="class-form" enctype="multipart/form-data" >
                  <Row>
                    <input name='type' type='hidden' value={Type} ></input>
                    <Col md={12} className="assign-form-grid" style={{ paddingTop: 10 }}>
                      <h6> <strong>ADD ASSIGNMENT</strong> </h6>
                      {/* <p style={{ color: 'gray', fontSize: '10px' }}>* Assignments only for started class</p> */}
                    </Col>
                    <Col md={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Title
                      </Label>
                      <Input invalid={true}
                        type="text" style={{ "width": "100%" }}
                        onChange={onChangeTitle} id="title"
                        name="title"
                        // value={inputField.title}
                        id='title'
                        invalid={errors.title}
                      />
                      <FormFeedback >{errors.title}</FormFeedback>

                    </Col>
                    {Type === 'course' ?
                      <Col md={4} className="assign-form-grid">
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span>
                          {' '} Course
                        </Label>
                        <Input type="select"
                          native
                          labelId="demo-simple-select-outlined-label"
                          onChange={handleChangeCourse}
                          style={{ "width": "100%" }}
                          id="course_id"
                          name='course_id'
                          label="Grade"
                          // value={inputField.grade_id}
                          invalid={errors.course_id}
                        >

                          <option value={''}>{'Select Course'}</option>

                          {courses ?
                            courses.map((course, k) => {
                              return (
                                <option key={k} value={course.course_id}>{course.course_name}</option>
                              )
                            }) : null
                          }

                        </Input>
                        <FormFeedback >{errors.course_id}</FormFeedback>

                      </Col>
                      :
                      <Col md={4} className="assign-form-grid">
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span>
                          {' '} Subscription
                        </Label>
                        <Input type="select"
                          native
                          labelId="demo-simple-select-outlined-label"
                          onChange={handleChangeSubscription}
                          style={{ "width": "100%" }}
                          id="subscription_id"
                          name='subscription_id'
                          label="Grade"
                          // value={inputField.grade_id}
                          invalid={errors.course_id}
                        >

                          <option value={''}>{'Select Subscription'}</option>

                          {subscriptions ?
                            subscriptions.map(subscription => {
                              return (
                                <option value={subscription.subscription_id}>{subscription.subscription_name}</option>
                              )
                            }) : null
                          }

                        </Input>
                        <FormFeedback >{errors.course_id}</FormFeedback>

                      </Col>
                    }
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Class
                      </Label>
                      <div class="form-group">
                        <div class="input-group">
                          <Input type="select"
                            native
                            labelId="demo-simple-select-outlined-label"
                            onChange={handleChangeClass}
                            // style={{ "width": "100%" }}
                            id="class_id"
                            name='class_id'
                            label="Grade"
                            value={inputField.class_id}
                            invalid={errors.class_id}

                          >

                            <option value={''}>{'Select Class'}</option>
                            {classes ?
                              classes.map((c,k) => {
                                return (
                                  <option key={k} value={c.class_id}>{c.class_name}</option>
                                )
                              }) : null
                            }

                          </Input>

                          {
                            loadclass ?
                              <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>

                              :
                              null
                          }
                        </div>
                      </div>

                      <FormFeedback >{errors.class_id}</FormFeedback>

                    </Col>

                    {/* ////group new////////// */}
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Group
                      </Label>
                      <div class="form-group">
                        <div class="input-group">
                          <Input type="select"
                            native
                            labelId="demo-simple-select-outlined-label"
                             onChange={handleChangeBatch}
                            id="group_id"
                            name='group_id'
                            label="Grade"
                            invalid={errors.group_id}
                            value={inputField.group_id}
                          >

                            <option value={''}>{'Select Batch'}</option>
                            {assignment.batchesinClass ?
                             assignment.batchesinClass.map((b,k) => {
                                return (
                                  <option key={k} value={b.group_id}>{b.group_name}</option>
                                )
                              }) : null
                            }

                          </Input>

                          {
                            assignment.batchesinClassLoader ?
                            <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>
                              :
                              null
                          }
                        </div>
                        <FormFeedback>{errors.group_id}</FormFeedback>

                      </div>
                    </Col>
                    <Col md={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Last date of submission
                      </Label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          format="dd/MM/yyyy"
                          label=""
                          size='small'
                          inputVariant="outlined"
                          value={DeadLine}
                          onChange={(e) => onChangeDeadline(e, 'deadline')}
                          minDate={new Date()}
                          fullWidth
                          id='deadline'
                          views={["year", "month", "date"]}
                          openTo="year"
                        />
                      </MuiPickersUtilsProvider>
                      <span style={{ color: 'red' }}>{errors.deadline}</span>



                    </Col>
                    <Col md={6} className="assign-form-grid">
                      <label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Last time for submission
                      </label>


                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          keyboardIcon={<AccessTimeIcon />}
                          size="small"
                          inputVariant="outlined"
                          invalidLabel={false}
                          // margin="normal"
                          id="timepicker"
                          // label="From Time"
                          //  name="class_from_time"
                          //value={"Sat Apr 24 2021 01:00:31 GMT+0530 (India Standard Time)"}
                          value={Default}
                          onChange={(e) => { onChangeDeadlineTime(e, "deadlineTime"); setDefault(e); }}
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          fullWidth
                        />
                      </MuiPickersUtilsProvider>
                      <span style={{ color: 'red' }}>{errors.deadlineTime}</span>

                    </Col>
                    <Col md={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Description
                      </Label>
                      <Input type="textarea" rows="4"
                        style={{ "width": "100%" }} onChange={onChangeDescription}
                        id="description" name="description"
                        //value={inputField.description}
                        invalid={errors.description}
                      />
                      <FormFeedback >{errors.description}</FormFeedback>

                    </Col>
                    <Col md={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Choose assignment file
                      </Label>
                      <Input
                        style={{ lineHeight: "27px" }} type="file"
                        id="assignment"
                        // onBlur={onChange} 
                        onChange={onChange}
                        name="assignment" multiple
                        // value={inputField.assignment}
                        invalid={errors.assignment}
                      />
                      <FormFeedback >{errors.assignment}</FormFeedback>

                    </Col>
                    <Col md={5} >
                      <Label for="exampleNameF">

                        For assignment evaluation
                      </Label>
                      <Row>
                        <Col md={4}>
                          <BootstrapSwitchButton
                            checked={marksView}
                            onlabel='Marks'
                            offlabel='Grade'
                            width={100}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={8}>
                          {
                            marksView ? <>
                              <Input aria-label="Username" type="number"
                                placeholder="Enter marks " style={{ "width": "100%" }}
                                onBlur={onChangeMarks} id="marks" name="marks"
                                // value={inputField.marks}
                                invalid={errors.marks}
                              />
                              <FormFeedback >{errors.marks}</FormFeedback>
                            </>
                              : false
                          }

                        </Col>



                      </Row>

                    </Col>
                    <Col md={3} className="assign-button-grid">

                      <LaddaButton className="mb-2 mr-2 btn btn-danger assign-form-button"
                        loading={loader}
                        type="submit"
                        data-style={ZOOM_IN}   >
                        Add New Assignment
                      </LaddaButton>
                    </Col>

                  </Row>



                  <br />
                </form>
                {/* </LoadingOverlay> */}

                {

                  assignment.uploadProgress !== '' ?
                    <>
                      <strong style={{ color: 'rgb(19, 140, 228)' }}>Uploading Files...</strong>
                      <Progress
                        percent={assignment.uploadProgress}
                      />
                    </> : null

                }

              </div>

            </Card>
          </LoadingOverlay>
        </>
          : null

        }


        <Row>
          <Col sm={12} md={12}>

            <Table

              actions={tokenData.role}
              columns={tokenData.role === "teacher" ? columnsTeacher : columns}
              loader={assignment.loader}
              data={assignment.assignmentss ? assignment.assignmentss : []}
              downloadMyAssignment={downloadMyAssignment}
              downloadTeacherAssignment={downloadTeacherAssignment}
              populateStudents={populateStudents}
              deleteAssignment={deleteAssignment}
              teacherDownloadFile={teacherDownloadFile}
              downloadStudentFile={downloadStudentFile}
              respondToAssignment={respondToAssignment}
              getTeacherRemark={getTeacherRemark}
              getDescription={getDescription}
              handleClick={handleClick}
              editAssignment={editAssignment}
            />
          </Col>
        </Row>



        <Modals
          open={editModal}
          handleClose={() => CloseUpdateModel()}
          title={'Edit Assignment'}
        //columns={columns}
        //loader={assignment.loader}

        //downloadFile={downloadFile}

        >
          <Form onSubmit={handleSubmit} >
            <Row>
              <Col md={12}>
                Title
              </Col>

              <Col md={12}>
                <strong style={{ textTransform: 'capitalize' }}>
                  {editData &&
                    <Input value={editData.title} onChange={handleTitleChange} ></Input>
                  }
                </strong>
                <span style={{ color: 'red' }}>{errorsModel.title}</span>
                <hr />
              </Col>
              <Col md={6}>
                Deadline
              </Col>
              <Col md={12}>
                <strong>
                  {editData && editData.deadline}

                </strong>
                <hr />
              </Col>

              <Col md={6}>
                <Label for="exampleNameF">
                  {/* <span className="text-danger">*</span> */}
                  {' '}Deadline Date
                </Label>
                {/* <Input type="hidden" name="assignment_id" value={editData && editData.assignment_id} /> */}

                {/* <Input type="hidden" value={inputField.deadline_date} required name="deadline_date" /> */}

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    label=""
                    size='small'
                    inputVariant="outlined"
                    value={DeadLineEdit}
                    //  value={ moment('02-02-2021').format() }

                    onChange={(e) => onChangeDeadline(e, 'deadline_date')}
                    //  minDate={new Date()}
                    fullWidth
                    views={["year", "month", "date"]}
                    openTo="year"
                  />
                </MuiPickersUtilsProvider>
                <span style={{ color: 'red' }}>{errorsModel.deadlinedate}</span>

              </Col>
              <Col md={6}>
                <Label for="exampleNameF">
                  {/* <span className="text-danger">*</span> */}
                  {' '}Deadline Time
                </Label>
                {/* <Input type="hidden" value={editData}  name="deadline_time" /> */}

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    keyboardIcon={<AccessTimeIcon />}
                    size="small"
                    inputVariant="outlined"
                    invalidLabel={false}
                    // margin="normal"
                    id="time-picker"
                    // label="From Time"
                    // name="class_from_time"
                    //value={"Sat Apr 24 2021 01:00:31 GMT+0530 (India Standard Time)"}
                    // value={'2014-09-08T08:02:17-05:00'}
                    value={DefaultEdit}
                    onChange={(e) => { onChangeDeadlineTime(e, "deadline_time"); setDefaultEdit(e); }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <span style={{ color: 'red' }}>{errorsModel.deadlinetime}</span>

              </Col>
              <div style={{ textAlign: 'right', width: '100%' }} >
                {/* <Col md={6}> */}
                {/* <hr /> */}

                <LaddaButton className="mb-2 mr-2 mt-3 btn btn-primary"
                  loading={assignment.updateAssignLoader}
                  type="submit"
                  data-style={ZOOM_IN}  >
                  UPDATE ASSIGNMENT
                </LaddaButton>


                {/* </Col> */}
              </div>
            </Row>
          </Form>

        </Modals>


      </>


      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />
      <Modals
        open={studModel}
        handleClose={closeStudentsModel}

      >
        <StudentTable
          actions={'dashboard'}
          columns={columns2}
          loader={assignment.loader}
          data={assignment.students ? assignment.students : []}

          downloadStudentAssignment={downloadStudentAssignment}
          populateStudents={populateStudents}
        />

      </Modals>

      <Modals
        open={respond}
        handleClose={closeRespondModel}
        title="Upload Assignment"
      >
        <RespondToAssignment
          id={aAssignmentId}
          class_id={class_id}
          closeRespondModel={closeRespondModel}
          title={aAssignmentTitle}
        />
      </Modals>


      <Modals
        open={assignDescription.popup}
        handleClose={closeAssignDescription}
        title={`Description`}
      >

        <div>
          {assignDescription.description}
        </div>
      </Modals>
      {/* <Modal isOpen={assignment.uploadProgress == '' || assignment.uploadProgress == 100 ? false : true}  >

        <ModalBody> */}


      {/* </ModalBody>

      </Modal> */}

    </>
  )


}

export default AddAssignment;
