import React, { useState, useEffect, Fragment } from 'react';
import image from "../LandingPageNew/images/login.png"
// import about from "../LandingPageNew/images/about-01.svg";


import "../LandingPageNew/css/genex-custom.css"
import "../LandingPageNew/css/custome.css"
// import "../LandingPageNew/css/bootstrap.min.css"
import './login.css'
import { requiredValidator, } from '../../utilities/Validator'
import { authRequest, } from '../../redux/Actions/auth'
import { setHomeData } from "../../redux/Actions/genexhome"

import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import LaddaButton, { L, ZOOM_IN } from 'react-ladda';
// import { Input, Label } from 'reactstrap'
// import firebase from "../push-notification"
// import CloseIcon from '@material-ui/icons/Close';
import { validate } from 'email-validator';
function Login() {

    const history = useHistory();
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {

        if (tokenData) {
            history.push('/dashboard')
        }
        // if (firebase.messaging.isSupported()) {
        //     firebase.messaging().getToken().then((currentToken) => {
        //         if (currentToken) {
        //             dispatch(setHomeData('message_tok', currentToken))
        //         } else {
        //             console.log(
        //                 'No Instance ID token available. Request permission to generate one.')
        //         }
        //     }).catch(() => {
        //         console.log('error')
        //     })

        //     return () => {
        //         dispatch({ type: 'RESET_LOGIN_STORE' })
        //     }
        // }


        /////////////////////old///////////////
        // const messaging=firebase.messaging()
        // messaging.requestPermission().then(()=>{
        //     return messaging.getToken()
        // }).then(token=>{
        //     // console.log('TokenNEW: ',token)
        //     dispatch(setHomeData('message_tok',token))
        // }).catch(()=>{
        //     console.log('error')
        // })

        return () => {
            dispatch({ type: 'RESET_LOGIN_STORE' })
            dispatch(setHomeData('login_from_course', false))
        }
    }, []);

    const AuthData = useSelector(({ auth }) => auth)
    const login_from_course = useSelector((state) => state.genexhome.login_from_course ? state.genexhome.login_from_course : false);
    const message_tok = useSelector((state) => state.genexhome.message_tok ? state.genexhome.message_tok : '');
    const login_from_subscription = useSelector((state) => state.genexhome.login_from_subscription ? state.genexhome.login_from_subscription : false);

    const [authFields, setAuthFields] = useState({
        email: '',
        password: ''
    })
    const [errors, setErrors] = useState({})
    // const [show, setShow] = useState(false)

    const [Student, setStudent] = useState(true)
    const [login_role, setLoginRole] = useState({student:true,vendor:false,teacher:false})

    const toggle = (role) => {
        // setStudent(!Student)
        if(role==='student'){
            setLoginRole({student:true,vendor:false,teacher:false})
        }
        else if(role==='vendor'){
            setLoginRole({student:false,vendor:true,teacher:false})
        }
        else{
            setLoginRole({student:false,vendor:false,teacher:true})
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setAuthFields({ ...authFields, [name]: value })
    }
    const cb = () => {

        if (login_from_course) {
            history.goBack()
        }
        else if (login_from_subscription) {
            history.goBack()
        }
        else {
            history.push('/dashboard')
        }
    }

    const SubmitLogin = (e) => {
        e.preventDefault()

        const err = {
            email: requiredValidator(authFields.email),
            password: requiredValidator(authFields.password)
        }

        if (!err.email && !err.password) {
            setErrors(err)
            let obj = {}
            if (validate(authFields.email)) {
                obj.email = authFields.email
                obj.login_type = 'email'
            }
            else {
                obj.username = authFields.email
                obj.login_type = 'username'
            }
            obj.password = authFields.password
            // obj.role = Student ? "student" : "vendor"
            if(login_role.student){
                obj.role='student'
            }
            else if(login_role.vendor){
                obj.role='vendor'
            }
            else{
                obj.role='teacher'
            }
            obj.device_token = message_tok
            dispatch(authRequest(obj, cb))

        } else {
            setErrors(err)
        }
    }
    const newstyle = { marginTop: '15px' }

    const ShowSignUp = () => {
        // setShow((prev) => !prev)
        history.push('/student-register')
    }
    const toggleViewPassword = () => {
        setViewPassword(!ViewPassword);
    }

    const [ViewPassword, setViewPassword] = useState(false);
    return (

        <Fragment>
            <section class="padding-top-60px padding-bottom-60px" style={{ backgroundColor: '#ffffff' }}>
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-sm-12 col-md-12 col-lg-9 col-12">
                            <div class="row form-main-col">
                                <div class="col-sm-12 col-md-6 col-lg-6 col-12 order-lg-1 order-2">
                                    <img src={image} alt="" class="img-fluid" />
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6 col-12 login-col order-lg-2 order-1">
                                    <div class="col-md-12 col-lg-12 col-12 my-auto pl-0 pr-0">
                                        <form onSubmit={SubmitLogin} autocomplete="off">
                                            <h2 class="text-center text-title-medium mt-2" style={{ color: '#212529' }}>SignIn</h2>

                                            <div class="col-md-12 col-lg-12 col-12">
                                                <hr class="opacity-5" />
                                                <div class="input-group input-group-sm mb-1">
                                                    <div class="input-group-prepend form-group-prepend">
                                                        <span class="input-group-text" id=""><i class="fa fa-envelope"></i></span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        class="form-control gn-form-control"
                                                        aria-label="Small"
                                                        placeholder="Email or Username"
                                                        aria-describedby=""
                                                        name='email'
                                                        value={authFields.email}
                                                        onChange={handleChange}
                                                        autocomplete="off"
                                                    />
                                                </div>
                                                <span style={{ color: 'red' }}>{errors.email}</span>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12">
                                                <div class="input-group input-group-sm mb-1" style={newstyle}>
                                                    <div class="input-group-prepend form-group-prepend">
                                                        <span class="input-group-text" id=""><i class="fa fa-lock"></i></span>
                                                    </div>
                                                    <input
                                                        type={ViewPassword ? "text" : "password"}
                                                        class="form-control gn-form-control"
                                                        aria-label="Small"
                                                        placeholder="Password"
                                                        aria-describedby=""
                                                        name='password'
                                                        onChange={handleChange}
                                                        value={authFields.password}
                                                        autocomplete="off"
                                                    />
                                                    <div class="input-group-append form-group-append">
                                                        <span style={{ cursor: 'pointer' }} onClick={toggleViewPassword} class="input-group-text" id=""><i class={ViewPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                                    </div>

                                                </div>
                                                <span style={{ color: 'red' }}>{errors.password}</span>
                                            </div>

                                            <div class="col-md-12 col-lg-12 col-12 mt-2 mb-2 ">
                                                <div style={{ display:'flex',justifyContent:'center',alignItems:'center' }}>

                                                    <span style={{ marginRight: '3px' }}>Student </span>  
                                                    <input style={{ marginRight: '20px',cursor:'pointer' }} 
                                                        checked={login_role.student} 
                                                        onChange={()=>{toggle('student')}} type='radio'
                                                    ></input>

                                                    <span style={{ marginRight: '3px' }} > Partner </span>
                                                    <input style={{cursor:'pointer',marginRight: '20px'}} 
                                                        checked={login_role.vendor} 
                                                        onChange={()=>{toggle('vendor')}} type='radio'
                                                    ></input>

                                                    <span style={{ marginRight: '3px' }} > Facilitator </span>
                                                    <input style={{cursor:'pointer'}} 
                                                        checked={login_role.teacher} 
                                                        onChange={()=>{toggle("teacher")}} type='radio'
                                                    ></input>

                                                </div>
                                            </div>
                                            {/* {console.log('AuthData',AuthData)} */}
                                            <div class="col-md-12 col-lg-12 col-12">
                                                {AuthData.currentUser.result === false ?
                                                    <span style={{ color: 'red' }} >Invalid Credentials</span>
                                                    : null}

                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12">

                                                <LaddaButton className="form-input-btn btn-block"
                                                    loading={AuthData.loader}
                                                    type="submit"
                                                    data-style={ZOOM_IN}
                                                    data-size={L}
                                                >
                                                    Login
                                                </LaddaButton>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12">
                                                <p class="text-center mb-2 mt-2"><Link to="/forgot" class="font-12">Forgot Password?</Link></p>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12">
                                                <p class="text-center mb-0 mt-1 font-12">Don't have an account? <span class="font-12 text-link" style={{ position: 'relative', cursor: 'pointer' }} onClick={ShowSignUp}>SignUp</span></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </Fragment>
    )
}
export default Login