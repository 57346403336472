import React, { Fragment, useState } from 'react';
import banner from "../images/inner-banner-contact.jpg"

import LaddaButton, { ZOOM_IN } from "react-ladda";
import { emailValidator, requiredValidator, } from '../../../utilities/Validator'
import { useDispatch, useSelector } from "react-redux";
import { sendContactMail } from "../../../redux/Actions/genexhome"

import {
    ToastContainer,
    toast,
} from 'react-toastify';

function ContactUs() {

    const dispatch = useDispatch();


    const loading = useSelector((state) => state.genexhome.contactusloader ? state.genexhome.contactusloader : false);

    const [inputField, setInput] = useState({
        name: '',
        email: '',
        contact_no: '',
        address: ''
    })
    const [errors, setErrors] = useState({})


    const handleChange = (e) => {
        const { name, value } = e.target
        setInput({ ...inputField, [name]: value })
    }
    const SubmitFotm = (e) => {
        e.preventDefault();
        const err = {
            email: emailValidator(inputField.email),
            name: requiredValidator(inputField.name),
            address: requiredValidator(inputField.address),
            contact_no: requiredValidator(inputField.contact_no),
        };
        if (
            !err.email &&
            !err.contact_no &&
            !err.address &&
            !err.name

        ) {
            setErrors(err);
            dispatch(sendContactMail(inputField, cb))
        }
        else {
            setErrors(err);
        }
    }

    const cb = (res) => {
        setInput({
            name: '',
            email: '',
            contact_no: '',
            address: ''
        })
        if (res.result) {
            toast["success"](res.message, { containerId: 'A' });

        } else if (res.result === false) {
            toast["danger"](res.message, { containerId: 'A' });
        }
    }
    const co = '#212529'
    return (
        <Fragment>
            <ToastContainer containerId={'A'} />
            <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})` }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-9 col-lg-9 col-12">
                            <h2 class="text-center font-weight-600 banner-title">CONTACT US</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section class="padding-top-120px padding-bottom-80px" style={{ backgroundColor: '#fff' }}>
                <div class="container">
                    <div class="col-md-12 col-lg-12 col-12 contact-main-col wow fadeInUp" data-wow-delay="0.2s">
                        <div class="row">
                            <div class="col-lg-7 col-md-7 contact-form-col">
                                <h1 style={{ color: co }} >Send us a message</h1>
                                <hr class="opacity-7" />
                                <form class="contact-form" onSubmit={SubmitFotm} >
                                    <div class="row">
                                        <div class="col-md-6 col-lg-6 col-12">
                                            <div class="input-group input-group-sm mb-3">
                                                <div class="input-group-prepend form-group-prepend">
                                                    <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-user"></i></span>
                                                </div>
                                                <input
                                                    type="text"
                                                    class="form-control gn-form-control"
                                                    aria-label="Small"
                                                    placeholder="Name"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    name='name'
                                                    value={inputField.name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <span style={{ color: 'red' }}>{errors.name}</span>
                                        </div>
                                        <div class="col-md-6 col-lg-6 col-12">
                                            <div class="input-group input-group-sm mb-3">
                                                <div class="input-group-prepend form-group-prepend">
                                                    <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-phone"></i></span>
                                                </div>
                                                <input
                                                    type="number"
                                                    class="form-control gn-form-control"
                                                    aria-label="Small"
                                                    placeholder="Contact Number"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    name='contact_no'
                                                    value={inputField.contact_no}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <span style={{ color: 'red' }}>{errors.contact_no}</span>

                                        </div>
                                        <div class="col-md-12 col-lg-12 col-12">
                                            <div class="input-group input-group-sm mb-3">
                                                <div class="input-group-prepend form-group-prepend">
                                                    <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-envelope"></i></span>
                                                </div>
                                                <input
                                                    type="email"
                                                    class="form-control gn-form-control"
                                                    aria-label="Small"
                                                    placeholder="Email"
                                                    aria-describedby="inputGroup-sizing-sm"
                                                    name='email'
                                                    value={inputField.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <span style={{ color: 'red' }}>{errors.email}</span>

                                        </div>
                                        <div class="col-md-12 col-lg-12 col-12">
                                            <div class="input-group input-group-sm mb-3">
                                                <div class="input-group-prepend form-group-prepend">
                                                    <span class="input-group-text" id="message"><i class="fa fa-pen"></i></span>
                                                </div>
                                                <textarea
                                                    id="message"
                                                    cols="30"
                                                    rows="2"
                                                    placeholder="Message"
                                                    class="form-control gn-form-control"
                                                    name='address'
                                                    value={inputField.address}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                            <span style={{ color: 'red' }}>{errors.address}</span>

                                        </div>
                                        <div class="col-md-6 col-lg-6 col-6">
                                            {/* <span style={{color:'green'}}>{contact_message}</span> */}
                                        </div>
                                        <div class="col-md-6 col-lg-6 col-6 text-md-right text-center">
                                            <LaddaButton
                                                className="btn btn-gn"
                                                loading={loading}
                                                type="submit"
                                                data-style={ZOOM_IN}
                                            >
                                                Submit
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-5 col-md-5 pl-md-0 pr-md-0 pl-0 pr-0">
                                <div class="contact-dtls-col">
                                <h1  style={{color:co}} >Get in touch with us</h1>
                                    <hr/>
                                {/* <h5 style={{color:co}}>Phone </h5> */}
                                {/* <span class="d-block font-weight-500"><i class="fa fa-mobile text-main-color margin-right-10px" aria-hidden="true"></i>  +9186429 00300</span> */}
                                {/* <span class="d-block font-weight-500"><i class="fa fa-mobile text-main-color margin-right-10px" aria-hidden="true"></i>  +9186429 00300</span> */}
                                {/* <span class="d-block font-weight-500"><i class="fa fa-phone text-main-color margin-right-10px" aria-hidden="true"></i>  +9186429 00300</span> */}
                                <h5 class="margin-top-20px" style={{color:co}}>Address</h5>
                                <span class="d-block font-weight-500"> <i class="fa fa-map text-main-color margin-right-10px" aria-hidden="true"></i>
                                    SKILLABLERS Technologies PVT. LTD.<br/>
                                    EDC Conclave, Off ITPL Road,<br/>
                                    Bangalore 560 037, INDIA 
                                </span>
                                <span class="d-block mt-3 font-weight-500"><i class="fa fa-envelope-open text-main-color margin-right-10px" aria-hidden="true"></i><a href="mailto:connect@skillablers.com">connect@skillablers.com</a></span>
                            </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


        </Fragment>
    )
}
export default ContactUs