export default function Subscription(state={},action)

{
 switch (action.type) {
    case "SET_SUBSCRIPTION_DATA": {
      return { ...state, ...action.payload };
    }
    case "SUBSCRIPTION_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "SUBSCRIPTION_ALL_SUCCESS": {
        return { ...state,  ...action.payload }
      }
      case "SUBSCRIPTION_ALL_FAILED": {
        return { ...state, }
      }
    default: {
      return { ...state }
    }

  }
}