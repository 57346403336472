import React, { useState, useEffect, } from 'react'
import { 
    Row, Col,Badge,Card,Input,Label,
     
} from 'reactstrap';
// import ViewStudent from './viewStudent'
// import Swal from 'sweetalert2'

// import TimeShedule from './viewTime'
// import Loader from 'react-loader'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import AssignStudents from './addStudentToClass'
import Modals from '../../common/Modal'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
 import { useDispatch, useSelector } from 'react-redux'
// import { deleteClasses, validateClassTiming, editClass, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateAllEnterpriseUserClass, getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
import {listAllPayments,listAllFilteredPayments,getAllStudent} from '../../../redux/Actions/Superadmin'
import { useHistory } from "react-router-dom";
import PageTitle from '../../common/HeaderTitle/PageTitle'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import moment from 'moment'
import RefundAmount from './RefundAmount'

const Paymentlist = () => {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
   // const Payments = useSelector(state => state.superadmin.paymentsArray?state.superadmin.paymentsArray:"")
    const paymentsArray = useSelector(state => state.superadmin.paymentsArray ? state.superadmin.paymentsArray : [])
    const filterloader = useSelector(state => state.superadmin.filterloader ? state.superadmin.filterloader : false)
    const paymentloader = useSelector(state => state.superadmin.paymentloader ? state.superadmin.paymentloader : false)

    const StudentArray = useSelector((state) =>
    state.superadmin.StudentArray ? state.superadmin.StudentArray : []
    );
    const students=StudentArray ? StudentArray.students ? StudentArray.students:[]:[]
    const [filtered,setFiltered]=useState(false)
    const [std,setStd]=useState('')

    useEffect(() => {
        dispatch(listAllPayments(tokenData.token))
        dispatch(getAllStudent(tokenData.token))
    }
    , [])
    
    // console.log("paymentess",paymentsArray)
    var columns

    const history = useHistory();
    const dispatch = useDispatch()

    columns=[
        { title: 'Date', field: 'payment_date' },
        { title: 'Student', field: 'student_name' },
        { title: 'Course', field: 'course_name' },
        { title: 'Partner', field: 'vendor_name' },
        { title: 'Amount', field: 'fee', 
        render: (rowData) => { 
            return(
               <Badge color="success">{rowData.fee}</Badge>
            )
        }
    },
        // { title: 'Status', field: 'status' },

        // { title: 'PIN', field: 'pin' },
        // { title: 'Referal_Code', field: 'referalcode' },
        // { title: 'Status', field: 'status' },
        // { title: 'Students', field: 'totalstudents' },

      ]
      

      const action = "payment_table"

    //   const Vendorview=()=>{
    //     history.push('/dashboard/singleresller')
    //   }

    const [RefundModal, setRefundModal] = useState(false);
    const [SelectedStudent, setSelectedStudent] = useState()

    const initiateRefund = (rowData) => {

        setSelectedStudent(rowData)
        setRefundModal(true)
    }
    
    // console.log('roww row', SelectedStudent)

      const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -32),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const filter=()=>{
            let from_date= moment(state[0].startDate).format("YYYY-MM-DD")
            let to_date=moment(state[0].endDate).format("YYYY-MM-DD")
            
            dispatch(listAllFilteredPayments(tokenData.token,from_date,to_date,std,cb))

      }
    const cb=()=>{
        setFiltered(true)
    }

    const Clearfilter=()=>{
        setFiltered(false)
        // setState({
        //     startDate: addDays(new Date(), -32),
        //     endDate: new Date(),
        //     key: 'selection'
        // })
        setStd('')

        dispatch(listAllPayments(tokenData.token))
    }

    const handleChange=(e)=>{
        setStd(e.target.value)
    }
    return (
    <>

        <Row>
            <Col sm={12}>
            <PageTitle
                heading="Payment Details"
                subheading="Payment details"
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
                />
            </Col>
            <Col sm={12}>
                <Card style={{ marginBottom: 20, padding: 20 }}>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        // onChange={handleSelect}

                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        maxDate={new Date()}
                        direction="horizontal"
                        color='#fed858'
                    />
                <hr />

                <Row>
                    <Col xs={8} md={10}>

                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}Select Student
                        </Label>

                        <Input type="select"
                            name='user_id'
                            required
                            value={std ? std:''}
                            onChange={handleChange}
                        >

                            <option value={''}>{'select'}</option>
                            {
                                students ? students.map((val) => {
                                    return (
                                        <option value={val.student_id}>{val.full_name}</option>
                                    )
                                })
                                    : []
                            }

                        </Input>
                
                    </Col>

                    <Col xs={4} md={2}>
                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                            <span className="text-danger">*</span>
                            {' '}
                        </Label>
                            {
                                filtered ? 
                                <LaddaButton className=" mr-2 btn btn-danger"
                                style={{ width: '-webkit-fill-available' }}
                                loading={paymentloader}
                                type="button"
                                onClick={Clearfilter}
                                data-style={ZOOM_IN}  >
                                Clear
                                </LaddaButton>

                                :

                                <LaddaButton className=" mr-2 btn btn-primary"
                                    style={{ width: '-webkit-fill-available' }}
                                    loading={filterloader}
                                    type="button"
                                    onClick={filter}
                                    data-style={ZOOM_IN}  >
                                    Filter
                                    </LaddaButton>
                                    }
                        
                    </Col>

                    </Row>
                </Card>
            </Col>
            <Col sm={12} md={12} style={{marginTop:'10px'}}>
                <Table
                    actions={action}
                    data={paymentsArray}
                    title={'Payments'}
                    columns={columns}
                    initiateRefund={initiateRefund}
                    loader={paymentloader}
                />
            </Col>
         {SelectedStudent &&  
            <Modals
            title="Refund" 
            open={RefundModal}
            handleClose={ () => setRefundModal(false)}
            >
             <RefundAmount setRefundModal={setRefundModal} student={SelectedStudent} />
            </Modals>
        }
        </Row >

    </>
    )
}

export default Paymentlist
