import React, { Fragment, useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom'
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux'
import bg1 from '../../assets/utils/images/originals/city.jpg';
import bg2 from '../../assets/utils/images/originals/citydark.jpg';
import bg3 from '../../assets/utils/images/originals/citynights.jpg';
// import logo from '../../assets/utils/images/logoMots.png'
import { changePassword } from '../../redux/Actions/auth'
import { Col, Row,  Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import SweetAlert from 'sweetalert-react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';


const ForgotPassword = () => {

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true

    };
    const [inputField, setInputField] = useState({
        password: '',
        password_confirmation: '',
        token: ''
    })
    const param = useParams()
    const history = useHistory()
    const authStore = useSelector(({ auth }) => auth)

    useEffect(() => {
        setInputField({ ...inputField, token: param.token })

    }, [])

    //  alert(param.token)
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value })

    }
    const [error, setError] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (inputField.password === inputField.password_confirmation) {
            dispatch(changePassword(inputField, cb))
        } else {
            setError(true)
        }
    }

    const [successModal, setSuccessModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);
    const cb = (status) => {
        if (status === "success") {

            setSuccessModal(true)
        } else if (status === "failed") {

            setFailedModal(true)
        }


    }

    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        setFailedModal(false)

        history.push('/')
    }
    return (

        <Fragment>
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="4" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg1 + ')'
                                        }} />

                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />

                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                    <div className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg2 + ')'
                                        }} />

                                </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
                            {/* <div className="app-logo" /> */}
                            {/* <img src={logo} width="120px" height="180px" style={{ paddingBottom: '20px' }} /> */}

                            <h4>
                                <div>Create new password</div>
                                <span>Use the form below to recover it.</span>
                            </h4>
                            <div>

                                <Form onSubmit={handleSubmit}>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Email</Label>
                                                <Input type="email" name="email" id="exampleEmail"
                                                    value={inputField.email}
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="exampleEmail">New Password</Label>
                                                <Input type="password" name="password" id="exampleEmail"
                                                    value={inputField.password}
                                                    required
                                                    minLength="8"
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Confirm Password</Label>
                                                <Input type="password" name="password_confirmation" id="exampleEmail"
                                                    value={inputField.password_confirmation}
                                                    required
                                                    minLength="8"
                                                    onChange={handleChange}
                                                    invalid={error}

                                                />
                                                <FormFeedback>oops! the password not matching</FormFeedback>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 d-flex align-items-center">
                                        {/* <h6 className="mb-0">
                                            <a href="/#" className="text-primary">Sign in existing account</a>
                                        </h6> */}
                                        <div className="ml-auto">
                                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                style={{ width: '-webkit-fill-available' }}
                                                loading={authStore.createPasswordLoader}
                                                type="submit"
                                                data-style={ZOOM_IN}  >
                                                Create Password
                        </LaddaButton>
                                            {/* <Button type="submit" color="primary" size="lg" type="submit">Create Password</Button> */}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
            <SweetAlert
                title="Success"
                confirmButtonColor=""
                show={successModal}
                text="Your Password changed successfully "
                type="success"
                onConfirm={() => handleCloseSuccesstModal()} />
            <SweetAlert
                title="Failed"
                confirmButtonColor=""
                show={failedModal}
                text="Token data invalid"
                type="error"
                onConfirm={() => handleCloseSuccesstModal()} />
    )
        </Fragment>
    );

}
export default ForgotPassword