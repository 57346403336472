import React, { useState, useEffect,useRef } from "react";
import {
  Row,
  Col,
  // Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  Label,
  CardTitle,
  FormFeedback,
  Button,
} from "reactstrap";
import { requiredValidator } from "../../../utilities/Validator";
import { Progress } from "react-sweet-progress";

import cx from "classnames";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "./style.scss";
import { createCourse, setCourseData, getAllCurrencies } from "../../../redux/Actions/vendor_course";
// import Tabl from '../../common/DataTable'
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getGradeListLifeLong } from "../../../redux/Actions/signUp";
import {
  // populateSubject,
  populateRegisteredTeachers,
} from "../../../redux/Actions/homeTeacher";
import { useHistory } from "react-router-dom";

import { authCheck } from "../../../redux/Actions/auth";
import { setReqData } from "../../../redux/Actions/Superadmin"
import { LogoutSession } from "../../../redux/Actions/genexhome";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

// import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import SweetAlert from 'sweetalert-react';
// import { getAllvendorCourses } from '../../../redux/Actions/Superadmin'
import Modal from '../../common/Modal'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState, ContentState, convertToRaw,
} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import autosize from 'autosize'

import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import animationData from '../../Dashboard/animation/check-circle.json'
import Lottie from 'react-lottie';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Select from 'react-select';
import { CameraTagKey } from '../../../config.json';

import "./cameratag.css"

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const formData = new FormData();
let CameraTag

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  // const tStore = useSelector(({ homeTeacher }) => homeTeacher);
  // const Grades = useSelector(({ signUp }) => signUp);

  // const [teacherId, setTeacherId] = useState();
  // editor: EditorState.createEmpty(),
  // editorHTML: '',
  // showCode: false

  // const [editor,seteditor] = useState(EditorState.createEmpty())
  const [editorHTML,seteditorHTML] = useState('')
  const [showEditorCode,setshowEditorCode] = useState(false)
  const textareaEditor = useRef('')

  const [LiveClass, setLiveClass] = useState(false);
  const [OnDemand, setOnDemand] = useState(false);
  const [Certification, setCertification] = useState(false);
  const [Desc, setDesc] = useState("");
  const [Description, setDescription] = useState([]);
  const [VideoFile, setVideoFile] = useState(null);
  const [ImageFile, setImageFile] = useState(null);
  const [SiteImageFile, setSiteImageFile] = useState(null);
  const [successModal, setSuccessModal] = useState({ open: false, id: '' });
  const [livecheck, setLivecheck] = useState(false);
  const [videocheck, setVideocheck] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [selectedTags, setselectedTags] = useState([])

  const [cameratag_file, setCameraTagFile] = useState('')
  const [uuid, setUuid] = useState('')
  const [cameratag_loader, setCameraTagLoader] = useState(false)
  const [controll, setControll] = useState(false)

  const [CourseDescription, setCourseDescription] = useState() //literal description about course
  const UploadCourseLoader = useSelector((state) =>
    state.course.UploadCourseLoader ? state.course.UploadCourseLoader : ""
  );
  const uploadProgressVideo = useSelector((state) =>
    state.course.uploadProgressVideo ? state.course.uploadProgressVideo : ""
  );

  const BatchList = useSelector((state) => state.superadmin.BatchList ? state.superadmin.BatchList : []);
  const SubList = useSelector((state) => state.superadmin.SubList ? state.superadmin.SubList : []);
  const tag_array = useSelector((state) => state.superadmin.tag_filtered_array ? state.superadmin.tag_filtered_array : []);

  useEffect(() => {
    CameraTag = window.CameraTag
    CameraTag.setup()
  }, [])

  useEffect(() => {
    return () => {
      CameraTag.cameras['DemoCamera'].destroy()
    }
  }, [])

  function handleDelete(e, point) {
    e.preventDefault();
    setDescription(Description.filter((item) => item !== point));
  }
  const cbLogOut = () => {
    dispatch(LogoutSession(tokenData.token));
    localStorage.removeItem("authDetails");
    history.push("/");
    Swal.fire({
      icon: "error",
      title: "",
      text: "Token Expired",
    });
  };
  useEffect(() => {
    if (uuid && controll) {
      formData.append('uuid', uuid)
      // CameraTag.cameras['DemoCamera'].destroy();
      // CameraTag.cameras['DemoCamera'].reset();
      setControll(false)
      SubmitWithUuid()
    }
  }, [uuid])

  useEffect(() => {
    dispatch(authCheck(tokenData.token, cbLogOut));
    dispatch(setCourseData('uploadProgressVideo', ''))
    dispatch(getAllCurrencies(tokenData.token, cb));
    dispatch(getGradeListLifeLong(null, 3));
    if (tokenData.enterpriseUser) {
      dispatch(populateRegisteredTeachers(tokenData.token));
    }
    // dispatch(listAllTagsByFilter(tokenData.token,3))

    let blist = []
    let data = {}
    data.batch_name = ''
    data.total_seat = ''
    blist.push(data)
    dispatch(setReqData('BatchList', blist))

    let slist = []
    let data2 = {}
    data2.subject_name = ''
    slist.push(data2)
    dispatch(setReqData('SubList', slist))
  }, []);


  // if (CameraTag && CameraTag !== undefined) {
  //   CameraTag.observe('DemoCamera', "initialized")
  //   console.log('initializedinitializedinitializedinitialized')
  // }

  if (CameraTag && CameraTag !== undefined && cameratag_file && !uuid) {
    CameraTag.observe('DemoCamera', 'published', function () {
      let myCam = CameraTag.cameras["DemoCamera"];
      let myVideo = myCam.getVideo();
      setUuid(myVideo.uuid)

    });
  }
  if (CameraTag && CameraTag !== undefined && !cameratag_file) {
    CameraTag.observe('DemoCamera', 'uploadFileSelected', function (file) {
      setCameraTagFile(file)
    });
  }
  const UploadFn = () => {
    let myCam = CameraTag.cameras["DemoCamera"];
    myCam.startUpload()
  }

  const onEditorStateChange = (editorState) => {
    setCourseDescription(editorState);
  };

  const handleSiteImageUpload = (e) => {
    setSiteImageFile(e.target.files[0]);
  };

  function handleDelete(e, point) {
    e.preventDefault();
    setDescription(Description.filter((item) => item !== point));
  }

  const Addone = (typ) => {

    if (typ === 'batch') {
      let blist = [...BatchList]
      let data = {}
      data.batch_name = ''
      data.total_seat = ''
      blist.push(data)
      dispatch(setReqData('BatchList', blist))
    }
    else {
      let slist = [...SubList]
      let data = {}
      data.subject_name = ''

      slist.push(data)
      dispatch(setReqData('SubList', slist))
    }
  }
  const [Currencies, setCurrencies] = useState()
  const cb = (data) => {
    setCurrencies(data.currencies)
    // console.log("dattttt",data)
  }
  const Removeone = (i, typ) => {
    if (typ === 'batch') {
      let blist = [...BatchList]
      blist.splice(i, 1)
      dispatch(setReqData('BatchList', blist))
    }
    else {
      let slist = [...SubList]
      slist.splice(i, 1)
      dispatch(setReqData('SubList', slist))
    }
  }

  const handleChangeName = (e, i) => {

    const { name, value } = e.target;

    if (name === "batch") {

      let blist = [...BatchList]
      blist[i].batch_name = value
      dispatch(setReqData('BatchList', blist))
    }
    else {
      let slist = [...SubList]
      slist[i].subject_name = value
      dispatch(setReqData('SubList', slist))
    }
  }
  const handleChangeSeat = (e, i) => {
    let blist = [...BatchList]
    blist[i].total_seat = e.target.value
    dispatch(setReqData('BatchList', blist))
  }


  const handleChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  const handleVideoUpload = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleImageUpload = (e) => {
    setImageFile(e.target.files[0]);
  };


  function handleAdd(e) {
    e.preventDefault();
    if (!Desc) {
      Swal.fire({
        icon: "warning",
        title: "Required",
        text: "Enter a description to add",
        confirmButtonText: "Okay",
      });
    } else {
      let arr = [...Description];
      arr.push(Desc);
      setDescription(arr);
      setDesc("");
    }
  }

  // const handleChangeGrade = (e) => {
  //   const { name, value } = e.target;

  //   setInputField({ ...inputField, [name]: value, subject_id: "" });

  //   // dispatch(populateSubject(tokenData.token, value, 3, teacherId));
  // };


  const [inputField, setInputField] = useState({
    course_name: "",
    grade_id: "",
    subject_id: "",
    proposed_fee: "",
    // publish_date: "",
    enrollment_start_date: "",
    enrollment_end_date: "",
    course_start_date: "",
    course_end_date: "",
    ondemand_video_count: "",
    live_class_count: "",
    number_of_batches: "",
    scheduled: "",
    course_subject_name: ""
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setInputField({ ...inputField, [name]: value });
  };
  const [errors, setErrors] = useState({});

  const submitForm = (e) => {
    e.preventDefault();

    // let description_length = Description.length > 0 ? '' : "*Required"
    // let banner = ImageFile ? "" : "*Required"
    // let preview = VideoFile ? "" : '*Required'
    // let site = SiteImageFile ? "" :"*Required"
    let desc = CourseDescription ? draftToHtml(convertToRaw(CourseDescription.getCurrentContent())) : ""
    // let desc_length = desc.length
    let tagErr = selectedTags.length > 0 ? "" : "* Required"

    // let course_detail
    // if (LiveClass || OnDemand) {
    //   course_detail = ''
    // }
    // else {
    //   course_detail = '*Select Atleast One Course Type'
    // }
    let enroll_from
    let enroll_to
    // let livesession
    let type
    let batchname
    // let subjectname
    let seat
    let course_start_date
    let course_end_date
    if (LiveClass) {
      // livesession = inputField.live_class_count ? "" : '*Required'
      enroll_from = inputField.enrollment_start_date ? "" : '*Required'
      enroll_to = inputField.enrollment_end_date ? "" : '*Required'
      type = inputField.live_class_type ? "" : '*Required'
      batchname = BatchList.some((element) => element.batch_name === '')
      seat = BatchList.some((element) => element.total_seat === '')
      // subjectname = SubList.some((element) => element.subject_name === '')
      course_start_date = requiredValidator(inputField.course_start_date)
      course_end_date = requiredValidator(inputField.course_end_date)
    }

    // let ondemandvalidat
    // if (OnDemand) {
    //   ondemandvalidat = inputField.ondemand_video_count ? "" : '*Required'
    // }

    const err = {
      course_name: requiredValidator(inputField.course_name),
      // grade_id: requiredValidator(inputField.grade_id),
      // subject_id: requiredValidator(inputField.subject_id),
      course_subject_name: requiredValidator(inputField.course_subject_name),

      proposed_fee: requiredValidator(inputField.proposed_fee),
      currency_id: requiredValidator(inputField.currency_id),
      // description: description_length,
      // banner: banner,
      // preview: preview,
      // site:site,
      enrollment_start_date: enroll_from,
      enrollment_end_date: enroll_to,
      course_start_date: course_start_date,
      course_end_date: course_end_date,
      // course_detail: course_detail,
      // live_class_count: livesession,
      live_class_type: type,
      batch_name: batchname ? "*Fill All Field" : '',
      // subject_name: batchname ? "*Fill All Field" : '',
      total_seat: seat ? "*Fill All Field" : '',
      // ondemand_video_count: ondemandvalidat
      tagerr: tagErr
    };

    if (
      !err.course_name &&
      // !err.grade_id &&
      // !err.subject_id &&
      !err.course_subject_name &&
      !err.proposed_fee &&
      // !err.banner &&
      // !err.preview &&
      // !err.site &&
      !err.enrollment_start_date &&
      !err.enrollment_end_date &&
      !err.course_start_date &&
      !err.course_end_date &&
      // !err.course_detail &&
      // !err.subject_name &&
      !err.currency_id &&
      !err.live_class_type &&
      !err.batch_name &&
      !err.total_seat &&
      !err.tagerr

      // !err.description

    ) {
      // if (desc_length < 68) { //+8 offset (60 letter long description)
      //   Swal.fire({
      //     icon: 'warning',
      //     text: 'Description must contains 60 charactors'
      //   })
      // }
      // else {
      setErrors(err)
      if (VideoFile) {
        formData.append("video_file", VideoFile);
      }
      if (ImageFile) {
        formData.append("image_file", ImageFile);
      }
      if (SiteImageFile) {
        formData.append("header_banner", SiteImageFile);
      }

      if (LiveClass) {
        formData.append('batch_details', JSON.stringify(BatchList))
        formData.append('subjects', JSON.stringify(SubList))
        formData.append("live_class_type", inputField.live_class_type);
        formData.append("enrollment_start_date", inputField.enrollment_start_date);
        formData.append("enrollment_end_date", inputField.enrollment_end_date);
        formData.append("course_start_date", inputField.course_start_date);
        formData.append("course_end_date", inputField.course_end_date);
      }
      else {
        formData.append('batch_details', JSON.stringify([]))
        formData.append('subjects', JSON.stringify([]))
        formData.append("live_class_type", '');
        formData.append("enrollment_start_date", '');
        formData.append("enrollment_end_date", '');
        formData.append("course_start_date", '');
        formData.append("course_end_date", '');
      }
      formData.append("course_name", inputField.course_name);
      formData.append("grade_id", "");
      formData.append("category_id", 3);
      formData.append("subject_id", inputField.subject_id);
      formData.append("course_subject_name", inputField.course_subject_name);

      formData.append("proposed_fee", inputField.proposed_fee);
      formData.append("publish_date", inputField.course_start_date);

      formData.append("ondemand_video_count", OnDemand ? 1 : 0);
      formData.append("live_class_count", LiveClass ? 1 : 0);
      formData.append("certification", Certification ? 1 : 0);
      formData.append("contents", JSON.stringify(Description));
      formData.append("description", desc);
      formData.append("currency_id", inputField.currency_id);
      formData.append("schedule", schedule ? 'pre_scheduled' : 'post_scheduled');
      formData.append("type", "institute");

      let arr = []
      for (let i in selectedTags) {
        let obj = {}
        obj.tag_id = selectedTags[i].tag_id
        obj.tag_name = selectedTags[i].tag_name
        arr.push(obj)
      }
      formData.append("tags", JSON.stringify(arr));

      if (cameratag_file) {
        setCameraTagLoader(true)
        setControll(true)
        UploadFn()
      } else {
        dispatch(createCourse(tokenData.token, formData, handleOpenSuccessModal, reset, errormodel));
      }
    }
    // }
    else {
      setErrors(err);
      if (err.course_name) {
        Showerror('course_name3')
      }
      else if (err.proposed_fee) {
        Showerror('proposed_fee3')
      }
      else if (err.grade_id) {
        Showerror('grade_id3')
      }
      else if (err.course_subject_name) {
        Showerror('course_subject_name3')
      }
      // else if(err.description){
      //   Showerror('description3')
      // }
      else if (err.live_class_type) {
        Showerror('live_class_type3')
      }
      else if (err.course_start_date) {
        Showerror('course_start_date3')
      }
      else if (err.course_end_date) {
        Showerror('course_end_date3')
      }
      else if (err.enrollment_start_date) {
        Showerror('enrollment_start_date3')
      }
      else if (err.enrollment_end_date) {
        Showerror('enrollment_end_date3')
      }
      else if (err.batch_name) {
        Showerror('batch_name3')
      }
      else if (err.total_seat) {
        Showerror('total_seat3')
      }

      else {
        Showerror('currency_id3')
      }
    }
  };

  const SubmitWithUuid = () => {
    setCameraTagLoader(false)
    dispatch(createCourse(tokenData.token, formData, handleOpenSuccessModal, reset, errormodel));
  }

  const Showerror = (val) => {
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();

  }

  const [err, setErr] = useState({
    open_err: false,
    err_list: []
  })
  const errormodel = (val) => {
    setErr({
      open_err: true,
      err_list: val
    })
  }
  const closeErr = () => {
    setErr({
      open_err: false,
      err_list: []
    })
  }
  const handleOpenSuccessModal = (val) => {
    if (val.live_class_count === "1") {
      setLivecheck(true)
    }
    if (val.ondemand_video_count === "1") {
      setVideocheck(true)
    }
    setSuccessModal({ open: true, id: val.course_id })

  }
  const handleCloseSuccesstModal = () => {
    // CameraTag = window.CameraTag
    // CameraTag.setup()
    setVideocheck(false)
    setLivecheck(false)
    setSuccessModal({ open: false, id: '' })
    history.push('/dashboard/showCourse')
  }
  const reset = () => {
    // let enterprise_id = ""
    // dispatch(getAllvendorCourses(tokenData.token, enterprise_id))
    setUuid('')
    setCameraTagFile('')
    setInputField({
      course_name: "",
      grade_id: "",
      subject_id: "",
      proposed_fee: "",
      publish_date: "",
      enrollment_start_date: "",
      enrollment_end_date: "",
      course_start_date: "",
      course_end_date: "",
      ondemand_video_count: "",
      live_class_count: "",
      live_class_type: "",
      course_subject_name: "",
      currency_id: ""
    })
    let blist = []
    let data = {}
    data.batch_name = ''
    data.total_seat = ''
    blist.push(data)
    dispatch(setReqData('BatchList', blist))
    dispatch(setCourseData('uploadProgressVideo', ''))

    let slist = []
    let data2 = {}
    data2.subject_name = ''
    slist.push(data2)
    dispatch(setReqData('SubList', slist))

    setCertification(false)
    setDescription([]);
    setVideoFile(null);
    setImageFile(null);
    setCourseDescription(null);
    setStartDate(null);
    setEndDate(null);
    setEnrollStart(null);
    setEnrollEnd(null);
    setLiveClass(false);
    setOnDemand(false)
    setSchedule(false)
    setselectedTags([])
    document.getElementById('exampleInputlogo3').value = null
    document.getElementById('exampleInputbanner3').value = null
    document.getElementById('exampleInputvideo3').value = null
    //document.getElementById("life-form").reset();

  }

  const [publish_date, setPublish] = useState()
  const [start_date, setStartDate] = useState(null)
  const [end_date, setEndDate] = useState(null)
  const [enroll_start_date, setEnrollStart] = useState(null)
  const [enroll_end_date, setEnrollEnd] = useState(null)

  const handleChangeFormDate = (e, val) => {
    const dt = moment(e).format("YYYY-MM-DD");
    if (val === 'publish_date') {
      setPublish(e)
    }
    else if (val === 'course_start_date') {
      setStartDate(e)
      // setPublish(e)
    }
    else if (val === 'course_end_date') {
      setEndDate(e)
    }
    else if (val === 'enrollment_start_date') {
      setEnrollStart(e)
    }
    else {
      setEnrollEnd(e)
    }
    setInputField({ ...inputField, [val]: dt });
  }

  const handleChange = (opt) => {
    setselectedTags(opt)
  }

///new change
const onEditorStateChangee = editor => {
  const editorHTML = draftToHtml(convertToRaw(editor.getCurrentContent()))
  // seteditor(editor)
  setCourseDescription(editor)
  seteditorHTML(editorHTML)
}

const onEditEditorHTML = e => {
  const editorHTML = e.target.value

  let editor
  const contentBlock = htmlToDraft(editorHTML)
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    editor = EditorState.createWithContent(contentState)
  } else {
    editor = EditorState.createEmpty()
  }
  // seteditor(editor)
  setCourseDescription(editor)
  seteditorHTML(editorHTML)
  // this.setState({ editor, editorHTML })
}

const toggleEditorCode = () => {
  // setshowEditorCode(showEditorCode)
  // let showEditorCode = true
  setshowEditorCode(!showEditorCode , () => {
    if (!showEditorCode) {
      autosize(textareaEditor)
      autosize.update(textareaEditor)
    } else {
      autosize.destroy(textareaEditor)
    }
  })
}
const ShowEditorCode = () => (
  <div className="rdw-option-wrapper"
    onClick={toggleEditorCode}
    title="Source Code"
    >
    {showEditorCode ? '< >' : '< >'} 
  </div>)


const submit = e => {
  e.preventDefault()
  // const { editorHTML } =
  seteditorHTML(editorHTML)
  // console.log(editorHTML)
}
/////

  const modelstyle = { borderRadius: '5px', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', width: '100px', height: '100px', border: '2px solid rgb(1, 123, 254)', margin: 'auto', cursor: 'pointer' }

  return (
    <>
      <div>
        {/* <Form onSubmit={submitForm} id="class-form"> */}
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>GENERAL DETAILS</CardTitle>

            <FormGroup row>
              <Col sm={12}>
                <Row>
                  <Col sm={6}>
                    <Label for="exampleNameF">
                      <span className="text-danger">*</span> Course Name
                    </Label>
                    <Input
                      type="text"
                      name="course_name"
                      id='course_name3'
                      invalid={errors.course_name}
                      onChange={handleChangeInput}
                      value={inputField.course_name}
                      autocomplete="off"
                    />
                    <FormFeedback>{errors.course_name}</FormFeedback>
                  </Col>

                  <Col xs={12} sm={3}>
                    <Label for="exampleNameF">
                      <span className="text-danger">*</span> Proposed Fee
                    </Label>
                    <Input
                      name="proposed_fee"
                      id='proposed_fee3'
                      onChange={handleChangeInput}
                      value={inputField.proposed_fee}
                      invalid={errors.proposed_fee}
                      type="number"
                      autocomplete="off"
                    ></Input>
                    <FormFeedback>{errors.proposed_fee}</FormFeedback>
                  </Col>
                  <Col xs={12} sm={3}>
                    <Label for="exampleNameF">
                      <span className="text-danger" >*</span> Currency
                    </Label>
                    <Input
                      name="currency_id"
                      onChange={handleChangeInput}
                      value={inputField.currency_id}
                      type="select"
                      invalid={errors.currency_id}
                      id='currency_id3'
                    >
                      <option value={""}>Select</option>
                      {
                        Currencies?.map(currency => {
                          return <option value={currency.currency_id}>{currency.currency_name}</option>
                        })
                      }
                    </Input>
                    <FormFeedback>{errors.currency_id}</FormFeedback>
                  </Col>

                  {/* <Col className="pt-1" sm={6}>
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span> Select Course
                      </Label>

                      <Input
                        type="select"
                        onChange={(e) => handleChangeGrade(e)}
                        name="grade_id"
                        id='grade_id3'
                        //required
                        // onChange={handleChangeInput}
                        invalid={errors.grade_id}
                        value={inputField.grade_id}
                      >
                        <option value={""}>{"select"}</option>
                        {Grades.gradeLifeLong.grades
                          ? Grades.gradeLifeLong.grades.map((data) => {
                            return (
                              <option
                                value={data.grade_id}
                                key={data.grade_id}
                              >
                                {data.grade_name}
                              </option>
                            );
                          })
                          : []}
                      </Input>
                      <FormFeedback>{errors.grade_id}</FormFeedback>
                    </Col> */}


                  <Col className="pt-3" xs={12} sm={6}>

                    <Label for="exampleNameF">
                      <span className="text-danger">*</span>Enter Subject
                    </Label>
                    <Input
                      name="course_subject_name"
                      id="course_subject_name3"
                      onChange={handleChangeInput}
                      value={inputField.course_subject_name}
                      type="textarea"
                      rows={2}
                      invalid={errors.course_subject_name}
                    ></Input>

                    <FormFeedback>{errors.course_subject_name}</FormFeedback>

                  </Col>

                  <Col className="pt-3" sm={12} sm={6}>
                    <Label for="exampleNameF">
                      <span className="text-danger">*</span>Select Category Tags
                    </Label>
                    <Select
                      isMulti
                      name="tags"
                      options={tag_array}
                      value={selectedTags}
                      onChange={handleChange}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder='Select Category Tags'
                    />
                    <span style={{ color: 'red' }}>{errors.tagerr}</span>
                  </Col>

                </Row>
              </Col>

            </FormGroup>
          </CardBody>
        </Card>


        {/* <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>DURATION DETAILS</CardTitle>
              
            </CardBody>
          </Card> */}


        <Card>
          <CardBody>
            <CardTitle>Course Details
              {/* <span id='course_detail3' style={{ color: 'red' }}>{errors.course_detail}</span> */}
            </CardTitle>

            <Col sm={12}>
              <Row>
                <Col xs={12} sm={6} md={3}>

                  <Col >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <label className="pt-2">
                        {/* <span className="text-danger">*</span> */}
                        Certification
                      </label>{" "}
                      <div
                        className="switch has-switch ml-1 "
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={() => {
                          setCertification(!Certification);
                        }}
                        style={{ zIndex: 0 }}

                      >
                        <div
                          className={cx("switch-animate", {
                            "switch-on": Certification,
                            "switch-off": !Certification,
                          })}
                        >
                          <input type="checkbox" />

                          <span className="switch-left bg-success">YES</span>
                          <label>&nbsp;</label>
                          <span className="switch-right bg-danger">NO</span>
                        </div>
                      </div>
                    </div>
                    <Input
                      name="certification"
                      value={Certification}
                      type="hidden"
                    />
                  </Col>
                </Col>

                <Col xs={12} sm={6} md={3}>

                  <Col >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <label className="pt-2">
                        Pre Schedule
                      </label>{" "}
                      <div
                        className="switch has-switch ml-1 "
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={() => {
                          setSchedule(!schedule);
                        }}
                        style={{ zIndex: 0 }}

                      >
                        <div
                          className={cx("switch-animate", {
                            "switch-on": schedule,
                            "switch-off": !schedule,
                          })}
                        >
                          <input type="checkbox" />

                          <span className="switch-left bg-success">YES</span>
                          <label>&nbsp;</label>
                          <span className="switch-right bg-danger">NO</span>
                        </div>
                      </div>
                    </div>
                    <Input
                      name="certification"
                      value={Certification}
                      type="hidden"
                    />
                  </Col>
                </Col>

                <Col xs={12} sm={6} md={3} >

                  <Col >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <label className="pt-2">
                        {/* <span className="text-danger">*</span> */}
                        On demand
                      </label>{" "}
                      <div
                        className="switch has-switch ml-1 "
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={() => {
                          setOnDemand(!OnDemand)
                        }}
                        style={{ zIndex: 0 }}

                      >
                        <div
                          className={cx("switch-animate", {
                            "switch-on": OnDemand,
                            "switch-off": !OnDemand,
                          })}
                        >
                          <input type="checkbox" />

                          <span className="switch-left bg-success">YES</span>
                          <label>&nbsp;</label>
                          <span className="switch-right bg-danger">NO</span>
                        </div>
                      </div>
                    </div>

                  </Col>

                </Col>

                <Col xs={12} sm={6} md={3}>
                  <Col >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <label className="pt-2">
                        {/* <span className="text-danger">*</span> */}
                        Live Class
                      </label>{" "}
                      <div
                        className="switch has-switch ml-1 "
                        data-on-label="ON"
                        data-off-label="OFF"
                        onClick={() => {
                          setLiveClass(!LiveClass)
                        }}
                        style={{ zIndex: 0 }}
                      >
                        <div
                          className={cx("switch-animate", {
                            "switch-on": LiveClass,
                            "switch-off": !LiveClass,
                          })}
                        >
                          <input type="checkbox" />

                          <span className="switch-left bg-success">YES</span>
                          <label>&nbsp;</label>
                          <span className="switch-right bg-danger">NO</span>
                        </div>
                      </div>
                    </div>

                  </Col>
                </Col>

                {
                  LiveClass ?
                    <>

                      <Row className="border rounded m-4 p-3 pb-4" style={{ width: '100%' }}>

                        <Col xs={12} sm={6}>
                          <Label for="exampleInput">
                            <span className="text-danger">*</span>
                            Course start date
                          </Label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              format="dd/MM/yyyy"
                              label=""
                              size='small'
                              inputVariant="outlined"
                              value={start_date}
                              onChange={date => handleChangeFormDate(date, 'course_start_date')}
                              minDate={new Date()}
                              fullWidth
                              id='course_start_date3'
                              views={["year", "month", "date"]}
                              openTo="year"
                            />
                          </MuiPickersUtilsProvider>

                          <span style={{ color: 'red' }}>{errors.course_start_date}</span>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Label for="exampleInput" >
                            <span className="text-danger">*</span>
                            Course end date
                          </Label>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              format="dd/MM/yyyy"
                              label=""
                              size='small'
                              inputVariant="outlined"
                              value={end_date}
                              onChange={date => handleChangeFormDate(date, 'course_end_date')}
                              minDate={start_date ? start_date : ''}
                              fullWidth
                              id='course_end_date3'
                              views={["year", "month", "date"]}
                              openTo="year"
                            />
                          </MuiPickersUtilsProvider>

                          <span style={{ color: 'red' }}>{errors.course_end_date}</span>
                        </Col>

                        <Col xs={12} sm={4} className='mt-2'>
                          <Label for="exampleInput">
                            <span className="text-danger">*</span>
                            Type
                          </Label>
                          <Input
                            disabled={LiveClass ? false : true}
                            name="live_class_type"
                            onChange={handleChangeInput}
                            type="select"
                            id='live_class_type3'
                            invalid={errors.live_class_type}
                          >
                            <option value={""}>select</option>
                            <option value={'oneday'}>One day</option>
                            <option value={'repetitive'}>Schedule</option>
                          </Input>
                          <FormFeedback>{errors.live_class_type}</FormFeedback>
                        </Col>

                        {
                          start_date && end_date ?
                            <>
                              <Col xs={12} sm={4} className='mt-2'>
                                <Label for="exampleInput">
                                  <span className="text-danger">*</span>
                                  Admission Start Date
                                </Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    format="dd/MM/yyyy"
                                    label=""
                                    size='small'
                                    inputVariant="outlined"
                                    value={enroll_start_date}
                                    onChange={date => handleChangeFormDate(date, 'enrollment_start_date')}
                                    minDate={new Date()}
                                    maxDate={start_date}
                                    fullWidth
                                    id='course_end_date3'
                                    views={["year", "month", "date"]}
                                    openTo="year"
                                    id='enrollment_start_date3'
                                  />
                                </MuiPickersUtilsProvider>


                                <span style={{ color: 'red' }}>{errors.enrollment_start_date}</span>
                              </Col>
                              <Col xs={12} sm={4} className='mt-2'>
                                <Label for="exampleInput" >
                                  <span className="text-danger">*</span>
                                  Admission End Date
                                </Label>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <DatePicker
                                    format="dd/MM/yyyy"
                                    label=""
                                    size='small'
                                    inputVariant="outlined"
                                    value={enroll_end_date}
                                    onChange={date => handleChangeFormDate(date, 'enrollment_end_date')}
                                    minDate={enroll_start_date ? enroll_start_date : new Date()}
                                    maxDate={end_date}
                                    fullWidth
                                    id='course_end_date3'
                                    views={["year", "month", "date"]}
                                    openTo="year"
                                    id='enrollment_end_date3'
                                  />
                                </MuiPickersUtilsProvider>
                                <span style={{ color: 'red' }}>{errors.enrollment_end_date}</span>

                              </Col>
                            </>
                            :
                            ''
                        }
                      </Row>
                      <Row className="border rounded m-4 p-3 pb-4" style={{ width: '100%' }}>
                        <Row style={{ width: '100%', color: 'blue', fontWeight: '3px' }}> <h6 className="pl-2 pt-0">Create Batches</h6></Row>
                        {
                          BatchList.map((val, ky) => {
                            return (
                              <>
                                <Col xs={6} sm={5}>
                                  <span className="text-danger">*</span>
                                  Batch Name
                                  {
                                    ky === 0 ?
                                      <span style={{ color: 'red' }} id='batch_name3'>{errors.batch_name}</span>
                                      : ''
                                  }
                                  <Input type='text' name='batch' autocomplete="off" onChange={(e) => { handleChangeName(e, ky) }} value={val.batch_name ? val.batch_name : ''}></Input>
                                </Col>
                                <Col xs={6} sm={5}>
                                  <span className="text-danger">*</span>
                                  Total Seats
                                  {
                                    ky === 0 ?
                                      <span style={{ color: 'red' }} id='total_seat3'>{errors.total_seat}</span>
                                      : ''
                                  }

                                  <Input onChange={(e) => { handleChangeSeat(e, ky) }} value={val.total_seat ? val.total_seat : ''} type='number'></Input>
                                </Col>

                                {
                                  ky === 0 ?
                                    <Col xs={12} sm={2} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                      <Tooltip title='Add More' arrow>
                                        <Button onClick={() => { Addone('batch') }} color='primary' size='xs'>
                                          <AddIcon fontSize='small' />
                                        </Button>
                                      </Tooltip>
                                    </Col>
                                    :
                                    <Col xs={12} sm={2} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                      <Tooltip title='Delete this row' arrow>
                                        <Button onClick={() => { Removeone(ky, 'batch') }} size='xs' color='danger'>
                                          <DeleteOutlineIcon fontSize='small' />
                                        </Button>
                                      </Tooltip>
                                    </Col>
                                }
                              </>

                            )
                          })
                        }
                      </Row>

                      <Row className="border rounded m-4 p-3 pb-4" style={{ width: '100%' }}>
                        <Row style={{ width: '100%', color: 'blue', fontWeight: '3px' }}> <h6 className="pl-2 pt-0">Create Subject Modules</h6></Row>
                        {
                          SubList.map((val, ky) => {
                            return (
                              <>

                                <Col xs={6} sm={10}>
                                  {/* <span className="text-danger">*</span> */}
                                  Subject Name
                                  {/* <span style={{ color: 'red' }} id='subject_name'>{errors.subject_name}</span> */}
                                  <Input type='text' name={"subject"} autocomplete="off" onChange={(e) => { handleChangeName(e, ky) }} value={val.subject_name ? val.subject_name : ''}></Input>
                                </Col>


                                {
                                  ky === 0 ?
                                    <Col xs={12} sm={2} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                      <Tooltip title='Add More' arrow>
                                        <Button onClick={() => { Addone("subject") }} color='primary' size='xs'>
                                          <AddIcon fontSize='small' />
                                        </Button>
                                      </Tooltip>
                                    </Col>
                                    :
                                    <Col xs={12} sm={2} style={{ textAlign: 'right', paddingTop: '20px' }}>
                                      <Tooltip title='Delete this row' arrow>
                                        <Button onClick={() => { Removeone(ky, 'subject') }} size='xs' color='danger'>
                                          <DeleteOutlineIcon fontSize='small' />
                                        </Button>
                                      </Tooltip>
                                    </Col>
                                }

                              </>

                            )
                          })
                        }
                      </Row>
                    </>


                    : ''
                }

              </Row>

            </Col>
          </CardBody>

        </Card>
        <Card className="mb-4 mt-4" style={{ padding: "4px 4px 34px 4px" }}>
          <Col className="pt-4 pb-0" md={12}>
            <CardTitle>Course description</CardTitle>
            <Label for="exampleInput">
              {/* <span className="text-danger">*</span> */}
              Write what students should expect from your course
            </Label>
          </Col>
          <Row>
            <Col className="align-center pl-4 my-auto" xs={8} md={10}>
              <Input onChange={handleChangeDesc} type="text" id='description3' value={Desc} />
              <Input
                type="hidden"
                name="contents"
                value={Description}
              // invalid={errors.description}
              ></Input>
              {/* <FormFeedback>{errors.description}</FormFeedback> */}
            </Col>
            <Col xs={4} md={2}>
              <Button className="align-center my-auto" onClick={handleAdd}>
                Add
              </Button>
            </Col>
          </Row>
          <CardBody>
            <ul>
              {Description.map((point, index) => (
                <>
                  <Row key={index}>
                    <Col xs={8} md={8}>
                      <li className="mb-3">
                        {point} {"  "}
                      </li>
                    </Col>
                    <Col xs={4} md={1}>
                      <Button
                        color="danger"
                        size="xs"
                        onClick={(e) => handleDelete(e, point)}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          color="white"
                          icon={faTrash}
                        />
                      </Button>
                    </Col>
                  </Row>
                </>
              ))}
            </ul>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>COURSE DESCRIPTION</CardTitle>
            {/* <Editor
              // toolbarHidden
              editorState={CourseDescription}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
              stripPastedStyles={true}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                fontFamily: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: { inDropdown: false },
                options: ['inline', 'link', 'history', 'fontSize', 'fontFamily', 'list']
              }}
            /> */}

            <form name="form" onSubmit={submit} autoComplete="off">
              <div>
                    <Editor
                      editorState={CourseDescription}
                      wrapperClassName="demo-wrapper"
                      stripPastedStyles={true}
                      editorClassName={showEditorCode ? 'editor' : 'editorHide'}
                      onEditorStateChange={onEditorStateChangee}
                      toolbarCustomButtons={[<ShowEditorCode />]}
                    />
                  { showEditorCode && 
                  <textarea
                    className="textarea-style"
                    ref={ textareaEditor }
                    value={editorHTML}
                    onChange={onEditEditorHTML}
                    readOnly = {false}
                  /> }
                </div>
            </form>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>MEDIA UPLOAD</CardTitle>
            <Row>
              <Col sm={12} md={4} className="mt-2">
                <Label for="exampleInput">
                  Preview video
                </Label>
                {
                  UploadCourseLoader || cameratag_loader ?
                    <Input
                      id="exampleInputvideo3"
                      type="file"
                      name="preview_video"
                    ></Input>
                    :
                    <div>
                      <camera style={{ height: 'auto', width: '100%', background: '#f8fbff', fontSize: 'unset' }} id='DemoCamera' data-app-id={CameraTagKey.camera_tag_id} data-sources='upload' data-upload-on-select='false'></camera>
                      <div id="DemoCamera-start-screen">
                        <div class="cameratag_upload button_wrapper">
                          <button class="cameratag_upload_button">Choose file</button>
                          <div style={{ width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
                            {
                              cameratag_file ?
                                <span className="camera_tag_span">{cameratag_file.name}</span>
                                :
                                <span className="camera_tag_span">No file chosen</span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                }


              </Col>


              <Col sm={12} md={4} className="mt-2">
                <Label for="exampleInput">
                  {/* <span className="text-danger">*</span> */}
                  Banner Image (6250 x 1250)px
                </Label>
                <Input
                  id="exampleInputbanner3"
                  type="file"
                  name="site_image"
                  //required
                  onChange={handleSiteImageUpload}
                  accept="image/*"
                ></Input>
                <span style={{ color: 'red' }}>{errors.site}</span>
              </Col>

              <Col sm={12} md={4} className="mt-2" >
                <Label for="exampleInput" >
                  {/* <span className="text-danger">*</span> */}
                  Course Logo (200 x 200)px
                </Label>
                <Input
                  id="exampleInputlogo3"
                  type="file"
                  onChange={handleImageUpload}
                  invalid={errors.subject}
                  accept="image/*"
                ></Input>
                {/* <span style={{ color: 'red' }}>{errors.banner}</span> */}
              </Col>

              <Col sm={12}>
                {(uploadProgressVideo !== "" && (VideoFile || ImageFile || SiteImageFile)) ? (
                  <>
                    <strong style={{ color: "rgb(19, 140, 228)" }}>
                      Uploading Files...
                    </strong>
                    <Progress percent={uploadProgressVideo} />
                  </>
                ) : null}
              </Col>



              {/* <Col sm={4}>
                <div className="button_wrapper">
                  <button class="cameratag_upload cameratag_upload_button">Choose file</button>
                </div>
              </Col> */}
            </Row>
          </CardBody>
        </Card>

        <LaddaButton
          className="mb-2 mr-2 btn btn-primary"
          loading={UploadCourseLoader || cameratag_loader}
          type="submit"
          data-style={ZOOM_IN}
          onClick={submitForm}
        >
          Save Course Details
        </LaddaButton>
        {/* </Form> */}
      </div>

      {/* <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} /> */}
      <Dialog
        open={successModal.open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='xs'
        fullWidth={true}
        onClose={handleCloseSuccesstModal}
      >
        <DialogTitle style={{ textAlign: 'center' }}><span style={{ fontWeight: 'bold', letterSpacing: '1px' }}>Success</span></DialogTitle>
        <Divider />
        <DialogContent>
          <Row style={{ fontSize: '15px', color: '#333' }}>
            <Col md={12}>
              <Lottie options={defaultOptions}
                height={100}
                width={100}
              />
            </Col>
            <Col xs={12} style={{ color: 'green', textAlign: 'center', marginBottom: '10px' }}>
              <span>Course Created Successfully.</span>
            </Col>
            <Col xs={12} style={{ color: 'cornflowerblue', textAlign: 'center', marginBottom: '10px' }}>
              <span>{livecheck || videocheck ? "Update created course's class details" : ''}</span>
            </Col>
            {
              livecheck ?
                <Col xs={6}>
                  <div
                    style={modelstyle}
                    onClick={() => { history.push('/dashboard/createClass/' + successModal.id) }}
                  >
                    <LiveTvIcon fontSize='large' />
                    <span>Add Live</span>
                  </div>
                </Col>
                :
                ''
            }
            {
              videocheck ?
                <Col xs={6}>
                  <div
                    style={modelstyle}
                    onClick={() => { history.push('/dashboard/createVideoSession/' + successModal.id) }}
                  >
                    <VideoCallIcon fontSize='large' />
                    <span>Add Video</span>
                  </div>
                </Col>
                :
                ''
            }
          </Row>
        </DialogContent>
        <DialogActions>
          {/* {
            livecheck ? 
            <Button className='btn btn-primary' 
              onClick={()=>{history.push('/dashboard/createClass/' + successModal.id)}}
            >
              Live
            </Button>:''
          }
          {
            videocheck ?
            <Button  
              className='btn btn-primary'
              onClick={()=>{history.push('/dashboard/createVideoSession/' + successModal.id)}}
              >
              Video
            </Button>:''
          } */}
          <Button onClick={handleCloseSuccesstModal} className='btn btn-danger'>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={err.open_err}
        handleClose={closeErr}
        title={`Errors`}
      >
        {
          <ul>

            {
              err.err_list.map((error, ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

            }
          </ul>

        }
      </Modal>
    </>
  );
};

export default Index;
