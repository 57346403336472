import React,{useEffect,useState} from 'react'
import { useDispatch, } from "react-redux";
import {  editCategory, updateCourseCategories } from '../../../redux/Actions/Superadmin'
import {useParams} from 'react-router-dom'
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
//import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    EditorState,
    ContentState,
    convertToRaw,
} from "draft-js";
import {
    Row,
    Col,
    Form,
    Input, 
    Card,
    CardBody,
    Label,
    CardHeader,
    // FormFeedback,
    // Button,
} from "reactstrap";
import Swal from 'sweetalert2';
import htmlToDraft from 'html-to-draftjs';


function EditCategory() {
   
    const params = useParams()
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const [Name, setName] = useState(null);
    const [CourseLogo, setCourseLogo] = useState(null);
    const formData = new FormData();
    const [Description, setDescription] = useState(null);
    const [Loader, setLoader] = useState(false);

    const onEditorStateChange = (editorState) => {
        setDescription(editorState);
    };

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleSubmitCreateCategories = (e) => {

        e.preventDefault();
        let desc = Description? draftToHtml(convertToRaw(Description.getCurrentContent())) : "";
        
        if(desc.length>8 && Name ){
        formData.append('grade_id', params.grade_id);
        formData.append('grade_name', Name);
        formData.append('logo', CourseLogo);
        formData.append('grade_description', desc )
        setLoader(true);
        dispatch(updateCourseCategories(tokenData.token,formData,cb2))
        }else {
            Swal.fire('Required','Fill the Required Fields', 'warning')
        }
    }
  
    const cb2 = (msg) => {
        setLoader(false);
        Swal.fire('Success',msg, 'success')
    }


    useEffect(() => {
        dispatch(editCategory(tokenData.token,params.grade_id,cb))
        
    }, [])

    const cb = (data) => {
        setName(data.grade.grade_name)
        const html = data.grade.grade_description
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setDescription(editorState)
    }

    return (
        <div>
             <Card className="m-4" >
                <Form onSubmit={handleSubmitCreateCategories}>
                    <CardHeader className="pl-2 pt-2" >Edit Course Categories</CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={9}>
                                <span className="text-danger">*</span> <Label>Name</Label>
                                <Input value={Name} onChange={handleNameChange}></Input>
                            </Col>
                            <Col sm={3}>
                                <Label>Logo</Label>
                                <Input type='file' onChange={(e) => setCourseLogo(e.target.files[0])}></Input>
                            </Col>
                        </Row>

                        <Row className="pt-4">
                            <Col sm={12}>
                                <span className="text-danger">*</span>   <Label>Description</Label>
                                <Editor
                                    // toolbarHidden
                                    editorState={Description}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                        image: { inDropdown: false },
                                        options: ['inline', 'link','history']
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row className="pt-4">
                            <Col sm={10}></Col>
                            <Col sm={2}>
                                <LaddaButton type="submit"
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={Loader}
                                    data-style={ZOOM_IN}>Submit</LaddaButton>
                            </Col>
                        </Row>
                        </CardBody>
                        </Form>
                        </Card>
        </div>
    )
}

export default EditCategory
