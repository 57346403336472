import React, { useEffect } from 'react';
import { Row, Col, } from 'reactstrap'
import Table from '../common/DataTable';
// import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { listRecordings,  } from '../../redux/Actions/Recordings'
import { development } from '../../config.json'
import copy from 'copy-to-clipboard';
import {  toast } from 'react-toastify';

var columns
columns = [
    { title: '#', field: 'tableData.id' },
    { title: 'Class', field: 'class_name' },
    { title: 'Grade', field: 'grade_name' },
    { title: 'Subject', field: 'subject_name' },
    { title: 'Date', field: 'created_at' },
    { title: 'Created at', field: 'created_at' },
]

const Recordings = () => {
    const dispatch = useDispatch()
    const recordStore = useSelector(({ Recordings }) => Recordings)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(listRecordings(tokenData.token))

    }, [])


    if (tokenData.enterpriseUser && (tokenData.role === 'staff' || tokenData.role === 'principal'))
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Facilitator', field: 'teacher_name' },
            { title: 'Class', field: 'class_name' },
            { title: 'Grade', field: 'grade_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Date', field: 'created_at' },
            { title: 'Created at', field: 'created_at' },
        ]
    else
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Class', field: 'class_name' },
            { title: 'Grade', field: 'grade_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Date', field: 'created_at' },
            { title: 'Created at', field: 'created_at' },
        ]


    const onDownload = (rowData) => {
        var basreUrl = development.baseUrl2;
      //  var url = `${basreUrl}downloadMyRecordings?session_id=${rowData}`
         var url = rowData.download_link
        // console.log("urll",rowData)
        window.location.href = url;
        // dispatch(downloadRecording(tokenData.token, rowData))
    }
    const onCopy = (url) => {

        toast.configure();
        copy(url);
        toast.success("Copied", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }
    return (

        <>

            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Recording'}
                        columns={columns}
                        actions={"recording_list"}
                        data={recordStore.records}
                        // loader={tStore.schoolSubLoader}
                        onDownload={onDownload}
                        onCopy={onCopy}
                    />
                </Col>

            </Row>
        </>
    )
}

export default Recordings;