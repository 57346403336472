import React from 'react'
import DataTable from '../../common/DataTable'
import {
    Card,
    CardBody,
} from "reactstrap";

export default function TagList({columns,deleteTag,tag_data,editTag,Loader,viewTag}) {
    return (
        <Card className="m-4" >
                <CardBody>
                    <DataTable
                        actions={'course_tags'}
                        columns={columns}
                        data={tag_data}
                        title={"Category Tags"}
                        loader={Loader}
                        deleteTag={(val)=>deleteTag(val)}
                        editTag={(val)=>editTag(val)}
                        viewTag={(val)=>viewTag(val)}
                    />
                </CardBody>
            </Card>
    )
}
