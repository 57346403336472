import React from 'react'
import DataTable from '../../common/DataTable'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { downloadStudyMaterial, deleteStudyMaterial } from '../../../redux/Actions/homeTeacher'
import Swal from 'sweetalert2'
function Index({ action , downloadcb,loading,setLoading }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const homeTeacher = useSelector(({ homeTeacher }) => homeTeacher)
    const handleView = (rowData) => {
        history.push(`/dashboard/study-materials-view/${rowData.class_id}/${rowData.study_material_id}`)
    }

    const handleDelete = (rowData) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                setLoading()
                dispatch(deleteStudyMaterial(tokenData.token, rowData.study_material_id, downloadcb ))
            }
          })
       
    }

    // const downloadcb = () => {
    //   //  Swal.fire('Deleted')
    // }

    const handleDownload = (rowData) => {
        // var link = document.createElement("a");
        //     link.href = url;
        //     link.target = "_blank"
        //     link.click();
        //     link.remove();
        // console.log("row data",rowData)
        dispatch(downloadStudyMaterial(tokenData.token, rowData.study_material_id, rowData.class_id))
    }

    const columns = [
        {
            title: "Title ",
            field: "title",
        },
        {
            title: "Course ",
            field: "course_name",
        },
        {
            title: "Class ",
            field: "class_name",
        },
        {
            title: "Created at",
            field: "created_at",
        }

    ]

    return (
        <div>
            <DataTable
                title={'Study Materials'}
                actions={action}
                loader={loading}
                // role={props.role}
                columns={columns}
                data={homeTeacher.MyStudyMaterials}
                handleDownload={handleDownload}
                handleView={handleView}
                handleDelete={handleDelete}
            />

        </div>
    )
}

export default Index
