const initialState = {
  subAnswer: [],
  review_marked: [],
};

const answerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_LOCAL_ANSWER": {
      const newArray = [...state.subAnswer];
      newArray[payload.question_no] = {
        answer: payload.answer,
        type: payload.type,
        local: true,
        remote: false,
      };

      return {
        ...state,
        subAnswer: newArray,
      };
    }
    case "SAVE_ANSWER_SUCCESS": {
      const newArray = [...state.subAnswer];
      newArray[payload.questionNumber].remote = true;

      return {
        ...state,
        subAnswer: newArray,
      };
    }

    case "MARK_REVIEW": {
      let newArray = [...state.review_marked];
      // console.log("the array", newArray);
      // newArray.splice(1, 1, payload.answer);
      newArray[payload.Qno] = true;

      return {
        ...state,
        review_marked: newArray,
      };
    }

    case "RESET_ANSWER": {
      // console.log("reset", state.subAnswer);
      return {
        ...state,
        subAnswer: [],
      };
    }

    default:
      return state;
  }
};

export default answerReducer;
