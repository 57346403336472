import React,{useState,useEffect} from 'react'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    // CardBody,
    CardHeader,
} from "reactstrap";import 

classnames from "classnames";
import{useDispatch}from 'react-redux';
import Create from './CreateGroup';
import Manage from './ManageGroup';
import UnassignedIndex from "./UnassignedStudents"
import { populateGroup,setGroupData} from '../../../../redux/Actions/grouping'
// import { useParams } from 'react-router-dom'

function Index() {

    const dispatch = useDispatch();
    // const params = useParams()
    const [activeTab, setactiveTab] = useState("1")
    const tokenData = JSON.parse(localStorage.getItem('authDetails'))

    useEffect(() => {
        
        return () => {
            dispatch(setGroupData('destinationGroupStudents',[]))
            dispatch(setGroupData('sourceGroupStudents',[]))
        }
    }, [])

    const toggle = (tab) => {

        if (activeTab !== tab) {
            if(tab === "2" || tab === "3")
            {
                setactiveTab(tab)
                dispatch(populateGroup(tokenData.token))
                dispatch(setGroupData('destinationGroupStudents',[]))
                dispatch(setGroupData('sourceGroupStudents',[]))
            }else{
                setactiveTab(tab)
            }

        }
    }

    return (
        <div>
            <Card tabs={true} className="mb-3">
                <CardHeader>
                    <Nav justified>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => {
                                    toggle("1");
                                }}
                            >
                               Create Group
                        </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                    toggle("2");
                                }}
                            >
                                Group Students
                        </NavLink>
                        </NavItem>
                        {/* {
                            tokenData.subscrition_status === true ? 
                            <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "3" })}
                                onClick={() => {
                                    toggle("3");
                                }}
                            >
                                Group UnAssigned Students
                        </NavLink>
                        </NavItem>
                        :''
                        } */}
                        
                    </Nav>
                </CardHeader>
               
                    <TabContent activeTab={activeTab}>
                        <TabPane className='m-3' tabId="1">
                           
                           <Create />
                        </TabPane>
                        <TabPane className='m-3' tabId="2">
                           <Manage />
                        </TabPane>

                        <TabPane className='m-3' tabId="3">
                           <UnassignedIndex />
                        </TabPane>
                    </TabContent>
               
            </Card>
        </div>
    )
}

export default Index
