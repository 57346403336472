import React, { useState, useEffect } from 'react'
import {
    TabContent, TabPane,
    Row, Col, CardHeader,
    Card, CardBody,
    Button, ButtonGroup, Container,
} from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import classnames from 'classnames';
import Swal from 'sweetalert2'
import DataTable from '../../common/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { listAllRefunds, listAllPaymentsForRefunds } from '../../../redux/Actions/Superadmin'
import PageTitle from '../../../Components/common/HeaderTitle/PageTitle'
import Modals from '../../common/Modal'
import RefundAmount from '../payments/RefundAmount'
import {createRefund} from '../../../redux/Actions/Superadmin'

function Index() {

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"))

    const RefundData = useSelector(state => state.superadmin.RefundData ? state.superadmin.RefundData : [])
    const Refundloader = useSelector(state => state.superadmin.Refundloader ? state.superadmin.Refundloader : false)

    const RefundPending = useSelector(state => state.superadmin.RefundPending ? state.superadmin.RefundPending : [])
    const Pendingloader = useSelector(state => state.superadmin.Pendingloader ? state.superadmin.Pendingloader : false)

    const columns = [
        { title: 'Student', field: 'student_name' },
        { title: 'Course', field: 'course_name' },
        { title: 'Payment_Date', field: 'payment_date' },
        { title: 'Total_Amount', field: 'course_amount' },
        { title: 'Refund_Date', field: 'refund_date' },
        { title: 'Refund_Amount', field: 'refund_amount' },
    ]
    const pending_columns = [
        { title: 'Student', field: 'student_name' },
        { title: 'Course', field: 'course_name' },
        { title: 'Payment_Date', field: 'payment_date' },
        { title: 'Payment_Status', field: 'payment_status' },
        { title: 'Total_Amount', field: 'total_amount' },
    ]

    useEffect(() => {
        dispatch(listAllPaymentsForRefunds(tokenData.token))
    }, []
    )

    const [tab, setTab] = useState("1")

    const [RefundModal, setRefundModal] = useState(false);
    const [SelectedStudent, setSelectedStudent] = useState()

    const [Loader, setLoader] = useState(false)

    const cb = () =>{
      setLoader(false)
      setRefundModal(false)
    }

    const initiateRefund = (rowData) => {

       // setSelectedStudent(rowData)
       // setRefundModal(true)

       const AmountAsArray = rowData.fee.split(" ") ; //to remove the currency code
       const Amount = AmountAsArray[0] ;
       
           Swal.fire({
             icon: 'warning',
             title: 'Are you sure you want to refund ' + rowData.student_name +  ' with ' + rowData.fee ,
             //text: 'All the classes under the subject will be deleted',
       
             showCancelButton: true,
             confirmButtonColor: "#DD6B55",
             confirmButtonText: "CONFIRM",
             cancelButtonText: "CANCEL",
       
           }).then((result) => {
             if (result.value) {
               setLoader(true)
               dispatch(createRefund(tokenData.token,rowData.order_id,Amount,cb))
       
             } else if (result.dismiss === Swal.DismissReason.cancel) {
               Swal.fire(
                 'Cancelled',
                 'Cancelled',
                 'error'
               )
             }
           })
         
    }

    console.log("refundddddd", RefundPending)

    const downloadInvoice = (url) => {
        window.open(url)
    }


    const toggle = (val) => {


        if (tab !== val) {
            if (val === '1') {
                //  dispatch(getAllPendingTickets(tokenData.token))
                 dispatch(listAllPaymentsForRefunds(tokenData.token))
            }
            else if (val === '2') {
                dispatch(listAllRefunds(tokenData.token))
            }
            else {
                // dispatch(getAllResolvedTickets(tokenData.token))
            }
            setTab(val)
        }
    }
    return (
        <div>

            <PageTitle
                heading="Refund Details"
                subheading="Details of all refunds"
                icon="pe-7s-wallet icon-gradient bg-happy-itmeo"
            />
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}
            >
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Card className="main-card mb-3">
                                <CardHeader>
                                    <i className="header-icon pe-7s-back icon-gradient bg-mixed-hopes"> </i>
                                Refunds
                                <div className="btn-actions-pane-right">
                                        <ButtonGroup size="sm">
                                            <Button color="danger"
                                                className={"btn-pill pl-3 " + classnames({ active: tab === '1' })}
                                                onClick={() => {
                                                    toggle('1');
                                                }}
                                            >
                                                Pending
                                        </Button>
                                            <Button color="success"
                                                className={"btn-pill pr-3 " + classnames({ active: tab === '2' })}
                                                onClick={() => {
                                                    toggle('2');
                                                }}
                                            >
                                                Refunded
                                        </Button>
                                        </ButtonGroup>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <TabContent activeTab={tab} className="main-card mb-3">
                                        <TabPane tabId="1" className="main-card mb-3">
                                        <DataTable
                                                loader={Pendingloader}
                                                initiateRefund={initiateRefund}
                                                actions={'payment_table'}
                                                // role={props.role}
                                                columns={pending_columns}
                                                //Ticketdetails={(id) => { props.Ticketdetails(id) }}
                                                //Changestatus={(id) => { props.Statuschange(id) }}
                                                data={RefundPending}
                                                title={"Pending Refunds"}

                                            />
                                           
                                        </TabPane>

                                        <TabPane tabId="2">
                                        <DataTable
                                                loader={Refundloader}
                                                // hadleOpenModal={(id) => props.hadleOpenModal(id)}
                                                actions={"download_invoice"}
                                                // role={props.role}
                                                columns={columns}
                                                downloadInvoice={downloadInvoice}
                                                data={RefundData}
                                                title={"Paid Refunds"}

                                            />
                                        </TabPane>

                                    </TabContent>

                                </CardBody>

                            </Card>
                            <Modals
                                title="Refund"
                                open={RefundModal}
                                handleClose={() => setRefundModal(false)}
                            >
                                <RefundAmount setRefundModal={setRefundModal} student={SelectedStudent} />
                            </Modals>
                        </Col>
                    </Row>
                </Container>
            </CSSTransitionGroup>
        </div>
    )
}

export default Index
