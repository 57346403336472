import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {getEditApprovalPendingCourses} from "../../../../redux/Actions/Superadmin"
import Badge from "reactstrap/lib/Badge";
import {
    Row,
    Col,
    // Badge,
    // Card,
    // CardBody,
    // Button,
    // CardHeader,
    Container,
  } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Table from "../table";
import {useHistory} from 'react-router-dom'



function ApprovedEditCourselist() {
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const history = useHistory()

  const EditApprovedCourseArray = useSelector(state => state.superadmin.EditApprovedCourseArray ? state.superadmin.EditApprovedCourseArray:[])
  const EditApprovedloader = useSelector(state => state.superadmin.EditApprovedloader ? state.superadmin.EditApprovedloader:false)

  const dispatch = useDispatch()

  const Viewcourse = (id) =>{
    history.push('/dashboard/pendingcourses/course/'+id)
  }
  
  useEffect(() => {
      dispatch(getEditApprovalPendingCourses(tokenData.token))  
  
  }, [])

  const columns = [
    { title: "Course", field: "course_name" },
    { title: "Product_Code", field: "product_code" },
    { title: "Partner", field: "vendor_name" },
    { title: "Course_Date", field: "course_date", 
    render: (rowData)=>{
      return < Badge>{rowData.course_date}</Badge>
    }
  },
    { title: "Enrollment_Date", field: "enrollment_date", 
    render: (rowData)=>{
      return < Badge>{rowData.enrollment_date}</Badge>
    }
  },
  { title: "Type", field: "course_type" },
  { title: "Live_Class", field: "live_class_count" },
  { title: "Video_Class", field: "ondemand_video_count" },
    { title: "Seats", field: "seats" },
    { title: "Proposed_fee", field: "proposed_fee" },
    { title: "Total_fee", field: "total_fee" },
]
    // const data=[        
    //     { course_name: 'dsds', type:'1', total_seats: '20',available_seats:'12',field:600, },
    //     ]
    const action='pending_courses'
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container>
          <Row>
            <Col sm={12}>
              <Table
                loader={EditApprovedloader}
                actions={action}
                data={EditApprovedCourseArray}
                title={"Edited courses Requests"}
                columns={columns}
                Viewcourse={Viewcourse}
             />
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>
    </>
  );
}
export default ApprovedEditCourselist;

