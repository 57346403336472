import React, { useEffect, useState, } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { requiredValidator } from "../../../../../utilities/Validator";
import { 
  // populateCreateClassDetails, 
  getSubscriptionDetailsForClass } 
from "../../../../../redux/Actions/vendor_course";
import { populateGrade } from '../../../../../redux/Actions/homeTeacher'
import ShowClass from "../ListLiveClass"
import { validateClassTiming, addClassForSubscription, populateAllEnterpriseUserClass } from "../../../../../redux/Actions/homeTeacher"
import serialize from "form-serialize";
import uuid from "react-uuid";
import Swal from 'sweetalert2'
import swal from 'sweetalert'
// import DatePicker from 'react-datepicker';
// import { Table } from 'reactstrap';
//import swal from 'sweetalert-react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  // FormGroup,
  Input,
  // InputGroupAddon,
  Card,
  CardBody,
  Label,
  CardTitle,
  FormFeedback,
  // Button,
} from "reactstrap";

import PageTitle from "../../../../HeaderTitle/PageTitle";
import CreateTimeSlot from "./CreateTimeSlot";
// import { mt } from "date-fns/locale";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { populateDegree,getGradeList } from "../../../../../redux/Actions/signUp";

import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


function Index() {

  const params = useParams();

  const tStore = useSelector(({ homeTeacher }) => homeTeacher);

  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const [courseStart, setcourseStart] = useState();
  const [courseEnd, setcourseEnd] = useState();
  // const [subjects, setsubjects] = useState();
  const [courseId, setcourseId] = useState();
  const [SlotLoader, setSlotLoader] = useState(false)
  const [classStart, setclassStart] = useState();
  const [classEnd, setclassEnd] = useState();
  const [realCard, setRealCard] = useState([]);
  const [slotError, setslotError] = useState({})
  const [Errors, setErrors] = useState({})

  const [Loader, setLoader] = useState(false)

  const [dateRange, setDateRange] = useState();

  const [onedayClass, setonedayClass] = useState(false);

  const handleChangeDegree = (value) => {
    dispatch(getGradeList(value, 2));
  }

  const Grades = useSelector(({ signUp }) => signUp);

  let err = null;
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December"
  // ];
  // console.log("one day class tru", onedayClass)

  const validateSlot = () => {
    // dispatch(validateSlot(tokenData.token,tempCard))
    // let startTime = String(tempCard.class_from_time) ;
    // let startTime2 = startTime.slice(16,21);

    // alert(startTime2)

    // settempCard({...tempCard,class_from_time:startTime2})  //
    settempCard({ ...tempCard, subscription_id: courseId })  //
    err = {
      day: requiredValidator(tempCard.day),
      teacher: requiredValidator(tempCard.teacher_id),
      group: requiredValidator(tempCard.group_id),
      class_from_time: requiredValidator(tempCard.class_from_time),
      class_to_time: requiredValidator(tempCard.class_to_time),
      //category_id: requiredValidator(tempCard.grade_id),
     // grade_id: requiredValidator(tempCard.grade_id),
     // subject_name: requiredValidator(tempCard.subject_name),
    }
    console.clear()
    console.log("qweasd", err)
    if (!err.day && !err.teacher && !err.group && !err.class_from_time && !err.class_to_time) {
      setSlotLoader(true)

      //var startTime = moment(classStart, "hh:mm");
      //var endTime = moment(classEnd, "hh:mm");
      //var duration = endTime.diff(startTime, "minutes");
      dispatch(validateClassTiming(tokenData.token, tempCard, createCard, "", courseId))
    }

    else {
      setslotError(err);
    }
  }

  const createCard = () => {
    // setDateDisabliyt(true);

    let err;

    if (realCard.length === 0) {
      setRealCard([...realCard, tempCard]);
      document.getElementById("slot_form").reset();
      setSlotLoader(false)
      console.log("temp card", tempCard)
      settempCard({
        ...tempCard,
        day: "",
        class_from_time: "",
        class_to_time: "",
        group_id: "",
        group_name: "",
        teacher_id: "",
        teacher_name: "",
        key: "" //random uuid key not needed  though
      })
      setslotError({})
      // settempCard({
      //   class_from_date: "",
      //   class_to_date: "",
      //   day: "",
      //   class_from_time: "",
      //   class_to_time: "",
      // });
      //setSlotLoader(false);
    } else {
      setSlotLoader(false)
      realCard.forEach((item) => {
        if ((tempCard.day === item.day) && (tempCard.teacher_id === item.teacher_id) && (tempCard.group_id === item.group_id) ) {
          
          if (
            tempCard.class_from_time !== item.class_from_time &&
            tempCard.class_to_time !== item.class_to_time
          ) {
            const fr = moment(tempCard.class_from_time, "hh:mm").isBetween(
              moment(item.class_from_time, "hh:mm"),
              moment(item.class_to_time, "hh:mm")
            );
            const to = moment(tempCard.class_to_time, "hh:mm").isBetween(
              moment(item.class_from_time, "hh:mm"),
              moment(item.class_to_time, "hh:mm")
            );
            const rfr = moment(item.class_from_time, "hh:mm").isBetween(
              moment(tempCard.class_from_time, "hh:mm"),
              moment(tempCard.class_to_time, "hh:mm")
            );
            const rto = moment(item.class_to_time, "hh:mm").isBetween(
              moment(item.class_from_time, "hh:mm"),
              moment(item.class_to_time, "hh:mm")
            );

            if (fr || to || rfr || rto) {
              err = "from or to slot already taken";
            }
          } else {
            err = "from time t0 time same so notpossible";
          }
        }
      });
      if (!err) {
        setRealCard([...realCard, tempCard]);
        console.log("calllll")
        document.getElementById("slot_form").reset();
        setslotError({})
        settempCard({
          ...tempCard,
          day: "",
          class_from_time: "",
          class_to_time: "",
          group_id: "",
          group_name: "",
          teacher_id: "",
          teacher_name: "",
          key: "" //random uuid key 
        })
        setSlotLoader(false)
      } else {
        Swal.fire({
          icon: "warning",
          title: "Not avilable",
          text: "This slot is not possible",
        });
        setSlotLoader(false)
      }
    }

    // console.log("tmp=============", tempCart);
  };

  const removeCard = (id) => {
    const updatedCard = realCard.filter((card) => !id.includes(card.key));
    setRealCard(updatedCard);
  };

  // const submitForm = (e) => {
  //   e.preventDefault()
  //   const form = e.currentTarget;
  //    let body = serialize(form, { hash: true, empty: false });
  //   console.log("body", body)
  // }

  const submitForm = (e) => {
    e.preventDefault();
    console.log("input field", inputField)
    let error = null;
    if (tokenData.enterpriseUser) {
      error = {
        class_name: requiredValidator(inputField.class_name),
        category_id: requiredValidator(inputField.category_id),
        grade_id: requiredValidator(inputField.grade_id),
        subject_name: requiredValidator(inputField.subject_name),
      };
      console.clear()
      console.log("errror",error)
      if (
        !(error.class_name)
      ) {
        if (realCard.length > 0) {
          const form = e.currentTarget;
          let body = serialize(form, { hash: true, empty: true });
          body.category_id = Category;
          const type = tokenData.enterpriseUser;
          setLoader(true)
          dispatch(
            addClassForSubscription(
              tokenData.token,
              body,
              handleOpenSuccessModal,
              resetForm,
              handleOpenFailedModal,
              type
            )
          );
          // dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm))
        } else {
          Swal.fire({
            icon: 'warning',
            title: "",
            text: "Create atleast one time slot",
          });
        }
      } else {
        setErrors(error);
        Showerror('class_name')
      }
    }
  };

  const Showerror = (val) => {
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
  }

  const handleOpenSuccessModal = () => {
    Swal.fire('Success', 'Class Created Successfully', 'success')
    setLoader(false)
  };
  const handleOpenFailedModal = (msg) => {
    // alert("called")
    setLoader(false)
    Swal.fire('Failed', msg, 'error')
  };

  const setStartTime = (e) => {

    let a = String(e)
    let b = a.slice(16, 21)

    settempCard({ ...tempCard, key: uuid(), class_from_time: b })

    //setinputField({...inputField, class_start_date:b})
  }

  const setEndTime = (e) => {

    let a = String(e)
    let b = a.slice(16, 21)

    settempCard({ ...tempCard, key: uuid(), class_to_time: b })

    //setinputField({...inputField, class_start_date:b})
  }

  const resetForm = () => {
    dispatch(populateAllEnterpriseUserClass(tokenData.token, params.subscription_id,'subscription'))
    setinputField({
      subscription_id: "",
      teacher_id: "",
      group_id: "",
      class_name: "",
      class_type: "",
      class_start_date: null,
      class_to_date: "",
      class_from_time: "",
      class_to_time: "",
      day: "",
      class_end_date: null

    })
    setRealCard("")
    console.log("reset form")
  }
  const [inputField, setinputField] = useState({
    subscription_id: "",
    teacher_id: "",
    group_id: "",
    class_name: "",
    class_type: "",
    class_start_date: null,
    class_to_date: "",
    class_from_time: "",
    class_to_time: "",
    day: "",
    class_end_date: null
  });

  const [tempCard, settempCard] = useState({
    class_from_date: "",
    class_to_date: "",
    class_from_time: "",
    class_to_time: "",
    teacher_name: "",
    group_name: "",
    teacher_id: "",
    group_id: "",
    day: "",
  });

  const changeField = (e) => {
    const { name, value } = e.target;
    //setinputField({ ...inputField, [name]: value });
    settempCard({ ...tempCard, key: uuid(), [name]: value })

    if (name === "teacher_id") {
      var teacher_name = e.target[e.target.selectedIndex].getAttribute("t-name")
      settempCard({ ...tempCard, key: uuid(), teacher_name: teacher_name, [name]: value })
    }
    if (name === "group_id") {
      var group_name = e.target[e.target.selectedIndex].getAttribute("g-name")
      settempCard({ ...tempCard, key: uuid(), group_name: group_name, [name]: value })
    }
  }

  // const handleChangeInput = (e) => {

  //   const { name, value } = e.target;
  //   setInputField({ ...inputField, [name]: value });

  // };

  // const classDetails = useSelector((state) =>
  //   state.course.classDetails ? state.course.classDetails : ""
  // );

  useEffect(() => {
    // dispatch(populateCreateClassDetails(tokenData.token));
    dispatch(getSubscriptionDetailsForClass(tokenData.token, params.subscription_id, cb));

  }, []);

  const [Category, setCategory] = useState()

  const cb = (details) => {
    setcourseStart(details.subscription_details.subscription_start_date)
    setcourseEnd(details.subscription_details.subscription_end_date)
    setcourseId(details.subscription_details.subscription_id)
    // setsubjects(details.course_subjects)
    if (details.course_details ? details.course_details.live_class_type === "oneday":'') {
      setonedayClass(true)
    }
  }


  console.log("crs start", courseStart)
  console.log("crs end", courseEnd)


  console.log("real card", realCard)

  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];

    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }
    setDateRange(dates);
  };

  console.log("class", dateRange);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setinputField({ ...inputField, [name]: value });
  };



  const handleStartDate = (e, name) => {

    //const { name, value } = e.target;

    const value = e

    if (realCard.length > 0) {
      swal({
        title: "Are you sure?",
        text: "If you change date, Slots will be cleared!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            setRealCard("")
            var d = new Date(value);
            setclassStart(d);
            settempCard({ ...tempCard, class_from_date: value })
            setinputField({ ...inputField, [name]: value });

            if (onedayClass) {
              getDatesBetweenDates(d, d);
              settempCard({ ...tempCard, class_from_date: value, class_to_date: value })
              setinputField({ ...inputField, class_start_date: value, class_to_date: value });
            }
          } else {
            console.log("not cleared")
          }
        })
    }

    else {
      var d = new Date(value);
      setclassStart(d);
      settempCard({ ...tempCard, class_from_date: value })
      setinputField({ ...inputField, [name]: value });

      if (onedayClass) {
        getDatesBetweenDates(d, d);
        settempCard({ ...tempCard, class_from_date: value, class_to_date: value })
        setinputField({ ...inputField, class_start_date: value, class_to_date: value });
      }
    }
  }

  const handleEndDate = (e, name) => {

    const value = e

    if (realCard.length > 0) {
      swal({
        title: "Are you sure?",
        text: "If you change date, Slots will be cleared!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            setRealCard("")
            //const { name, value } = e.target;
            var dt = new Date(value);
            setclassEnd(dt);
            settempCard({ ...tempCard, class_to_date: value })
            setinputField({ ...inputField, [name]: value });
            getDatesBetweenDates(classStart, dt);
          } else {
            console.log("not cleared")
          }
        })
    }
    else {
      //    const { name, value } = e.target;
      var dt = new Date(value);
      setclassEnd(dt);
      settempCard({ ...tempCard, class_to_date: value })
      setinputField({ ...inputField, [name]: value });
      getDatesBetweenDates(classStart, dt);
    }
  }




  // const handleChangeCourse = (e) => {
  //   console.log("value", e.target.value);
  //   var str = e.target[e.target.selectedIndex].getAttribute("data-strdate");
  //   var end = e.target[e.target.selectedIndex].getAttribute("data-enddate");

  //   setcourseStart(str);
  //   setcourseEnd(end);
  // };
  const handleChangeCategory = (value) => {
    if (value === '1') {
      dispatch(populateGrade(null, 1));
    }
    if (value === '2') {
      dispatch(populateDegree());
    }
    if (value === '3') {
      dispatch(populateGrade(null, 3));
    }
    setCategory(value)
    setinputField({ ...inputField, category_id : value });
  }

  console.log("dates",Category);
  return (
    <div>
      <PageTitle
        style={{ display: "none" }}
        heading="Create Class"
        subheading="Create class for a Subscription"
        icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
      />
      <Form onSubmit={submitForm} id="class-form">
        <Card className="p-4">
          <Card className="pb-4">
            <CardTitle className="pl-2 pt-2">Class Details</CardTitle>
            <CardBody>
              <Row>

                <Col sm={12} ></Col>




                <Col sm={6}>
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> Class Name
                  </Label>
                  <Input type="hidden" value={params.subscription_id} name="subscription_id"></Input>
                  <Input onChange={handleChangeInput} invalid={Errors.class_name} id='class_name' value={inputField.class_name} name="class_name"></Input>
                  <FormFeedback>{Errors.class_name}</FormFeedback>
                </Col>
                <Col sm={6}>
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> Category
                  </Label>

                  <Input invalid={Errors.category_id}  type="select" onChange={(e) => handleChangeCategory(e.target.value)} >
                    <option value="">Select</option>
                    <option value='1'>School</option>
                    <option value='2'>College</option>
                    <option value='3'>Institute</option>
                  </Input>
                  <FormFeedback>{Errors.category_id}</FormFeedback>
                </Col>
                {Category &&
                  <>
                    {Category === '1' &&
                      <Col sm={6}>
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span> Grade
                        </Label>

                        <Input type="select"  onChange={handleChangeInput} name='grade_id' invalid={Errors.grade_id} >
                          <option value='' >Select</option>
                          {tStore.grade
                            ? tStore.grade.map((grade) => {
                              return (
                                <option value={grade.grade_id}>
                                  {grade.grade_name}
                                </option>
                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                              );
                            })
                            : []}
                        </Input>
                        <FormFeedback>{Errors.grade_id}</FormFeedback>
                      </Col>
                      
                      }


                    {Category === '2' &&
                     <>
                       <Col sm={3}>
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span> Course
                        </Label>

                        <Input type="select" onChange={ (e)=> handleChangeDegree(e.target.value)} name='degree_id' invalid={Errors.degree_id} >
                        <option value={""}>{"select"}</option>
                        {Grades.degree
                          ? Grades.degree.map((data) => {
                            return (
                              <option
                                value={data.degree_id}
                                key={data.degree_id}
                              >
                                {data.degree_name}
                              </option>
                            );
                          })
                          : []}
                        </Input>
                        <FormFeedback>{Errors.degree_id}</FormFeedback>
                      </Col>
                      <Col sm={3}>
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span> Specialization
                        </Label>
                        <div class="form-group">
                        <div class="input-group">

                        <Input  type="select" onChange={handleChangeInput} invalid={Errors.grade_id} id='grade_id'  name="grade_id">
                        <option value={""}>{"select"}</option>
                            {Grades.gradeCollege.grades
                              ? Grades.gradeCollege.grades.map((data) => {
                                return (
                                  <option
                                    value={data.grade_id}
                                    key={data.grade_id}
                                  >
                                    {data.grade_name}
                                  </option>
                                );
                              })
                              : []}
                        </Input>
                        {
                            Grades.loadgrade ?
                              <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>
                              :
                              null
                          }
                        </div>
                      </div>
                      
                        <FormFeedback>{Errors.grade_id}</FormFeedback>
                      </Col>
                    
                      </>
                    }

                {Category === '3' &&
                      <Col sm={6}>
                        <Label for="exampleNameF">
                          <span className="text-danger">*</span> Course
                        </Label>

                        <Input type="select" onChange={handleChangeInput} name='grade_id' invalid={Errors.grade_id} >
                          <option value='' >Select</option>
                          {tStore.grade
                            ? tStore.grade.map((grade) => {
                              return (
                                <option value={grade.grade_id}>
                                  {grade.grade_name}
                                </option>
                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                              );
                            })
                            : []}
                        </Input>
                        <FormFeedback>{Errors.grade_id}</FormFeedback>
                      </Col>
                      
                      }


                    <Col sm={6}>
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span> Subject
                      </Label>

                      <Input type="text" onChange={handleChangeInput} invalid={Errors.subject_name} id='subject_name'  name="subject_name">
                      </Input>
                      <FormFeedback>{Errors.subject_name}</FormFeedback>
                    </Col>
                  </>
                }
                <Col sm={6}>
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span>Start date
                  </Label>
                  <Input
                    name="class_start_date"
                    value={moment(inputField.class_start_date).format('YYYY-MM-DD')}
                    //value={new Date(inputField.class_start_date)}
                    //disabled={!courseEnd}
                    type="hidden"

                  ></Input>
                  {/* <DatePicker

                        className="form-control"
                        selected={inputField.class_start_date}
                        onChange={(e) => handleStartDate(e, "class_start_date")}
                        withPortal
                        dateFormat="dd/MM/yyyy"
                        minDate={moment(courseStart)?.toDate()}
                        maxDate={moment(courseEnd)?.toDate()}
                        required
                      /> */}

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      label=""
                      size='small'
                      inputVariant="outlined"
                      value={inputField.class_start_date ? inputField.class_start_date : null}
                      onChange={(e) => handleStartDate(e, "class_start_date")}
                      minDate={moment(courseStart)?.toDate()}
                      maxDate={moment(courseEnd)?.toDate()}
                      fullWidth
                      required
                      views={["year", "month", "date"]}
                      openTo="year"
                    />
                  </MuiPickersUtilsProvider>

                  {/* <DatePicker
                     className="form-control"
                     selected={inputField.class_start_date}
                     onChange={(e) => handleStartDate(e, "class_start_date")}
                     withPortal
                     dateFormat="dd/MM/yyyy"
                     minDate={moment(courseStart)?.toDate()}
                     maxDate={moment(courseEnd)?.toDate()}
                     required
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      increaseYear,
                      decreaseYear,

                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                          {"<<"}
                        </Button>
                        <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </Button>

                        <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                        <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </Button>
                        <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                          {">>"}
                        </Button>
                      </div>
                    )}

                  /> */}
                </Col>

                <Col sm={6}>

                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> End date
                  </Label>

                  <Input
                    name="class_end_date"
                    // disabled={onedayClass}
                    // value={moment(inputField.class_start_date).format('YYYY-MM-DD')}
                    value={
                      onedayClass
                        ? moment(inputField.class_start_date).format('YYYY-MM-DD')
                        : moment(inputField.class_end_date).format('YYYY-MM-DD')
                    }
                    // disabled={!courseEnd}
                    type="hidden"
                     min={inputField.class_start_date}
                     max={courseEnd}
                    onChange={onedayClass
                      ? ""
                      : handleEndDate}
                  ></Input>

                  {/* <DatePicker

                        className="form-control"
                        selected={onedayClass? inputField.class_start_date: inputField.class_end_date}
                        onChange={(e) => handleEndDate(e, "class_end_date")}
                        withPortal
                        disabled={onedayClass}
                        dateFormat="dd/MM/yyyy"
                        minDate={moment(inputField.class_start_date)?.toDate()}
                        maxDate={moment(courseEnd)?.toDate()}
                        required
                      /> */}

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      label=""
                      size='small'
                      inputVariant="outlined"
                      value={onedayClass ? inputField.class_start_date : inputField.class_end_date}
                      onChange={(e) => handleEndDate(e, "class_end_date")}
                      minDate={moment(inputField.class_start_date)?.toDate()}
                      maxDate={moment(courseEnd)?.toDate()}
                      fullWidth
                      disabled={onedayClass}
                      required
                      views={["year", "month", "date"]}
                      openTo="year"
                    />
                  </MuiPickersUtilsProvider>

                  {/* <DatePicker
                       className="form-control"
                       selected={onedayClass? inputField.class_start_date: inputField.class_end_date}
                       onChange={(e) => handleEndDate(e, "class_end_date")}
                       withPortal
                       disabled={onedayClass}
                       dateFormat="dd/MM/yyyy"
                       minDate={moment(inputField.class_start_date)?.toDate()}
                       maxDate={moment(courseEnd)?.toDate()}
                       required
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      increaseYear,
                      decreaseYear,

                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                          {"<<"}
                        </Button>
                        <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </Button>

                        <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                        <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </Button>
                        <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                          {">>"}
                        </Button>
                      </div>
                    )}

                  /> */}
                </Col>



              </Row>
            </CardBody>
          </Card>
          <div className="pt-4">
            <CreateTimeSlot
              Errors={slotError}
              tempCard={tempCard}
              settempCard={settempCard}
              handleChangeInput={changeField}
              //dateRange={dateRange?dateRange:[new Date()]}
              dateRange={dateRange?.slice(0, 7)}
              createCard={validateSlot}
              SlotLoader={SlotLoader}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
            />
            <Row>
              {realCard
                ? realCard.map((days) => {
                  console.log("dayss", days)
                  return (
                    <Col md="6" lg="3">
                      <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary">
                        <div className="widget-chat-wrapper-outer">
                          <div className="widget-chart-content">
                            {" "}
                            <p style={{ textAlign: "right" }}>
                              <FontAwesomeIcon
                                onClick={() => removeCard(days.key)}
                                icon={faWindowClose}
                              />
                            </p>
                            <div className="widget-title opacity-5 text-uppercase">
                              <h6>
                                {`${days
                                  ? moment(
                                    days.class_from_time,
                                    "hh:mm "
                                  ).format("h:mm A")
                                  : null
                                  } - ${days
                                    ? moment(
                                      days.class_to_time,
                                      "hh:mm "
                                    ).format("h:mm A")
                                    : null
                                  }`}
                              </h6>
                              <h6>{days.teacher_name}</h6>
                              <h6>{days.group_name}</h6>
                              <input
                                value={days ? days.class_from_time : null}
                                type="hidden"
                                name={
                                  days.day === "sun"
                                    ? "week[sun][class_from_time][]"
                                    : days.day === "mon"
                                      ? "week[mon][class_from_time][]"
                                      : days.day === "tue"
                                        ? "week[tue][class_from_time][]"
                                        : days.day === "wed"
                                          ? "week[wed][class_from_time][]"
                                          : days.day === "thu"
                                            ? "week[thu][class_from_time][]"
                                            : days.day === "fri"
                                              ? "week[fri][class_from_time][]"
                                              : days.day === "sat"
                                                ? "week[sat][class_from_time][]"
                                                : "name from"
                                }
                                id="from"
                              />

                              <input
                                type="hidden"
                                value={days ? days.teacher_id : null}
                                id="to"
                                name={
                                  days.day === "sun"
                                    ? "week[sun][teacher_id][]"
                                    : days.day === "mon"
                                      ? "week[mon][teacher_id][]"
                                      : days.day === "tue"
                                        ? "week[tue][teacher_id][]"
                                        : days.day === "wed"
                                          ? "week[wed][teacher_id][]"
                                          : days.day === "thu"
                                            ? "week[thu][teacher_id][]"
                                            : days.day === "fri"
                                              ? "week[fri][teacher_id][]"
                                              : days.day === "sat"
                                                ? "week[sat][teacher_id][]"
                                                : "name to"
                                }
                              />
                              <input
                                type="hidden"
                                value={days ? days.group_id : null}
                                id="to"
                                name={
                                  days.day === "sun"
                                    ? "week[sun][group_id][]"
                                    : days.day === "mon"
                                      ? "week[mon][group_id][]"
                                      : days.day === "tue"
                                        ? "week[tue][group_id][]"
                                        : days.day === "wed"
                                          ? "week[wed][group_id][]"
                                          : days.day === "thu"
                                            ? "week[thu][group_id][]"
                                            : days.day === "fri"
                                              ? "week[fri][group_id][]"
                                              : days.day === "sat"
                                                ? "week[sat][group_id][]"
                                                : "name to"
                                }
                              />
                              <input
                                type="hidden"
                                value={days ? days.class_to_time : null}
                                id="to"
                                name={
                                  days.day === "sun"
                                    ? "week[sun][class_to_time][]"
                                    : days.day === "mon"
                                      ? "week[mon][class_to_time][]"
                                      : days.day === "tue"
                                        ? "week[tue][class_to_time][]"
                                        : days.day === "wed"
                                          ? "week[wed][class_to_time][]"
                                          : days.day === "thu"
                                            ? "week[thu][class_to_time][]"
                                            : days.day === "fri"
                                              ? "week[fri][class_to_time][]"
                                              : days.day === "sat"
                                                ? "week[sat][class_to_time][]"
                                                : "name to"
                                }
                              />
                            </div>
                            <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                              <div className="widget-chart-flex align-items-center">
                                <div>
                                  <h4>
                                    {days
                                      ? days.day === "sun"
                                        ? "Sunday"
                                        : days.day === "mon"
                                          ? "Monday"
                                          : days.day === "tue"
                                            ? "Tuesday"
                                            : days.day === "wed"
                                              ? "Wednesday"
                                              : days.day === "thu"
                                                ? "Thursday"
                                                : days.day === "fri"
                                                  ? "Friday"
                                                  : days.day === "sat"
                                                    ? "Saturday"
                                                    : null
                                      : null}
                                  </h4>
                                </div>
                                <div style={{ display: "none" }}>
                                  <select id="day" name="day">
                                    <option value={days ? days.day : null}>
                                      {days
                                        ? days.day === "sun"
                                          ? "Sunday"
                                          : days.day === "mon"
                                            ? "Monday"
                                            : days.day === "tue"
                                              ? "Tuesday"
                                              : days.day === "wed"
                                                ? "Wednesday"
                                                : days.day === "thu"
                                                  ? "Thursday"
                                                  : days.day === "fri"
                                                    ? "Friday"
                                                    : days.day === "sat"
                                                      ? "Saturday"
                                                      : null
                                        : null}
                                    </option>
                                  </select>
                                </div>
                                <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                  <div className="ml-auto">
                                    <FontAwesomeIcon
                                      style={{ fontSize: "40px" }}
                                      color="#545bd8"
                                      icon={faClock}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                })
                : null}
            </Row>
          </div>


          {/* <Col className={"mt-4"} sm={12}>
          <ShowClass subscription_id={params.subscription_id} />
          </Col> */}


          <Col sm={10}>{" "}</Col>
          <Col sm={2}>
            <LaddaButton
              className="mb-2 mr-2 btn btn-primary"
              loading={Loader}
              type="submit"
              data-style={ZOOM_IN}
            >
              CREATE CLASS
            </LaddaButton>
            {/* // <Button type="submit">Create class</Button> */}
          </Col>

          <Col className={"mt-4"} sm={12}>
            <ShowClass type="subscription" id={params.subscription_id} />
          </Col>


        </Card>
      </Form>
    </div>
  );
}

export default Index;
