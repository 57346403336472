import React, { useState } from "react";
import {  useSelector } from "react-redux";
import {
  // Row,
  Col,
  Form,
  FormGroup,
  Input,
  // CustomInput,
  Card,
  CardBody,
  Label,
  CardTitle,
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import FormFeedback from "reactstrap/lib/FormFeedback";
//import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';


const Index = (props) => {
  const classDetails = useSelector((state) =>
    state.course.subscriptionClassDetails ? state.course.subscriptionClassDetails : ""
  );
  const { handleChangeInput, dateRange, createCard, SlotLoader, Errors, tempCard, loader, setStartTime, setEndTime } = props;
  // console.log("arrror", Errors)
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var dayValue = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const [Default, setDefault] = useState(null)        // needed because material ui time picker's value field must be bounded to a state that set on handle change
  const [DefaultToTime, setDefaultToTime] = useState(null)

  // console.log("date range from slot", dateRange);
  return (
    <Card className="main-card mb-3">
      <CardBody>
        <CardTitle>TIMESLOT DETAILS</CardTitle>
        <Form className="mt-4" id="slot_form">
          <FormGroup row>
            <Col xs={12} sm={4}>
              <Label for="exampleNameF">
                <span className="text-danger">*</span> Day
              </Label>
              <Input
                invalid={Errors.day}
                type="select"
                id="day"
                name="day"
                onChange={handleChangeInput}
              // value={this.state.loaderType}
              >
                <option value={""}>select</option>

                {dateRange
                  ? dateRange.map((date) => {
                    return (
                      <option value={dayValue[date.getDay()]}>
                        {days[date.getDay()]}
                      </option>
                      //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                    );
                  })
                  : []}
              </Input>
              <FormFeedback>{Errors.day}</FormFeedback>
            </Col>
            <Col xs={12} sm={4}>
              <Label for="exampleNameF">
                <span className="text-danger">*</span> Facilitator
              </Label>
              <Input
                invalid={Errors.teacher}
                name="teacher_id"
                onChange={handleChangeInput}
                type="select"
              >
                <option value={""}>{"select"}</option>
                {classDetails.teacher_details
                  ? classDetails.teacher_details.map((teacher) => {
                    // console.log("classssscdet", classDetails)
                    return (
                      <option t-name={teacher.first_name} value={teacher.user_id}>
                        {teacher.first_name}
                      </option>
                      //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                    );
                  })
                  : []}
              </Input>
              <FormFeedback>{Errors.teacher}</FormFeedback>
            </Col>
            <Col xs={12} sm={4}>
              <Label for="exampleNameF">
                <span className="text-danger">*</span> Batch
              </Label>
              <Input invalid={Errors.group} name="group_id" onChange={handleChangeInput} type="select">
                <option value={""}>{"select"}</option>

                {classDetails.subscription_details ?
                  classDetails.subscription_details.batch_details.map((group) => {
                    return (
                      <option g-name={group.group_name} value={group.group_id}>
                        {group.group_name}
                      </option>
                    )
                  }) : ""}


                {/* {classDetails.course_details
                  ? classDetails.course_details.map((course) => {
                   return course.batch_details.map((group) => {
                     
                      return(
                        <option g-name={group.group_name} value={group.group_id}>
                           {group.group_name}
                        </option>
                      )
                    })
                    // return (
                    //     <option value={group.group_id}>
                    //       {group.group_name}
                    //     </option>
                    //     //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                    //   );

                    })
                  : []} */}
              </Input>
              <FormFeedback>{Errors.group}</FormFeedback>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> From Time
                </Label>

                {/* <Input
                  invalid={Errors.class_from_time}
                  onChange={handleChangeInput}
                  type="time"
                  name="class_from_time"
                  placeholder="time placeholder"
                /> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                   keyboardIcon = {<AccessTimeIcon />}
                    size="small"
                    inputVariant="outlined"
                    invalidLabel={false}
                    // margin="normal"
                    id="time-picker"
                    // label="From Time"
                    name="class_from_time"
                   //value={"Sat Apr 24 2021 01:00:31 GMT+0530 (India Standard Time)"}
                    value={Default}
                    onChange={(e) => { setStartTime(e); setDefault(e);  }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
              <span style={{color:'red'}} >{Errors.class_from_time}</span>
            </Col>
            <Col xs={12} sm={4}>
              <FormGroup>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> To Time
                </Label>
              {/*   <Input
                  invalid={Errors.class_to_time}
                  id="class_to_time"
                  onChange={handleChangeInput}
              //  onChange={(e)=>console.log("event time",e.target.value)} 
                name="class_to_time"
                  type="time"
                /> */}

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    keyboardIcon = {<AccessTimeIcon />}
                    // fullWidth={false}
                    size="small"
                    inputVariant="outlined"
                    // margin="normal"
                    id="time-picker"
                    // label="To Time"
                    value={DefaultToTime}
                    name="class_to_time"
                    onChange={(e) => { setEndTime(e); setDefaultToTime(e) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
               
              </FormGroup>
              <span style={{color:'red'}}>{Errors.class_to_time}</span>
            </Col>
            {/* <Col sm={10}>{" "}</Col> */}
            <Col xs={12} sm={4} style={{marginTop:'35px'}}>
              <FormGroup>
                
                {/* <Button style={{ width: '-webkit-fill-available' }} color="danger" onClick={() => createCard()}>Create time slot</Button> */}
                    {/* {console.log("id id idd",classDetails)} */}
                <LaddaButton
                  className="mb-2 mr-2 btn btn-danger"
                  loading={SlotLoader}
                  style={{ width: "-webkit-fill-available" }}
                  onClick={() => {
                    createCard(classDetails.id);
                    setDefault(null);
                    setDefaultToTime(null);
                  }}
                  type="button"
                  data-style={ZOOM_IN}
                >
                  Create time slot
                </LaddaButton>
                {/* <Input
                                    type="button"
                                    name="time"
                                    id="exampleTime"
                                    color="primary"
                                //placeholder="time placeholder"
                                /> */}
              </FormGroup>
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Index;
