import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import './style.scss'
import Nav from '../AppNav/VerticalNavWrapper';

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

import {
    setEnableMobileMenu
} from '../../reducers/ThemeOptions';

const AppSidebar = (props) => {

    const [state, setState] = useState();
    const location = useLocation();
    const toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = props;
        setEnableMobileMenu(!enableMobileMenu);
    }


    let {
        backgroundColor,
        enableBackgroundImage,
        enableSidebarShadow,
        backgroundImage,
        backgroundImageOpacity,
    } = props;
    // console.log(location.pathname.includes('examHall'));
    return (
        <Fragment>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
            <CSSTransitionGroup
                component="div"
                className={cx("app-sidebar", backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
                transitionName="SidebarAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}>
                <HeaderLogo />
                <PerfectScrollbar>
                    <div className={`app-sidebar__inner ${location.pathname.includes('examHall') ? "disabledbutton" : null}`}>
                        <Nav
                            enterprise_user={props.enterprise_user}
                            privillage={props.privillage}
                            role={props.role} />
                    </div>
                </PerfectScrollbar>
                <div
                    className={cx("app-sidebar-bg", backgroundImageOpacity)}
                    style={{
                        backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null
                    }}>
                </div>
            </CSSTransitionGroup>
        </Fragment>
    )

}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({

    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);