import React,{useState} from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import moment from 'moment'

import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { 
    Row, Col,Card,Label,
     
} from 'reactstrap';
import PageTitle from '../../common/HeaderTitle/PageTitle'
import Table from "./table"
import { useDispatch, useSelector } from 'react-redux'
import { listSalesUnderSchool,exportSalesUnderSchool } from '../../../redux/Actions/school'
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
// import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Swal from 'sweetalert2'

function SalesList(){

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const salesloader = useSelector(state => state.superadmin.salesloader ? state.superadmin.salesloader : false)
    const saleinvoicesloader = useSelector(state => state.superadmin.saleinvoicesloader ? state.superadmin.saleinvoicesloader : false)

    const dispatch = useDispatch()

    // const SchoolArray = useSelector((state) =>
    // state.superadmin.SchoolArray ? state.superadmin.SchoolArray : []);
    // const school_data = SchoolArray? SchoolArray.schools? SchoolArray.schools: []: [];

    // const StudentArray = useSelector((state) =>
    // state.superadmin.StudentArray ? state.superadmin.StudentArray : []);
    // const student_data=StudentArray ? StudentArray.students ? StudentArray.students :[]:[]

    // const [student_id,setStudent]=useState('')
    // const [school_id,setSchool]=useState('')
    const student_id=''
    const school_id=''
    // useEffect(()=>{
        // dispatch(listSchoolsUnderSchool(tokenData.token));
        // dispatch(listStudentsUnderSchool(tokenData.token))

    // },[])
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -32),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const action='sales_table'
    const columns=[
        { title: 'Order', field: 'order_id',
            render: (rowData) => {
                return  (
                <span>#{rowData.order_id}</span>
                );
            } 
        },
        { title: 'Student', field: 'student_name' },
        { title: 'Course', field: 'course_name' },
        { title: 'Vendor', field: 'vendor_name' },
        { title: 'Date', field: 'payment_date' },
        { title: 'Fees', field: 'fee'
        },
        { title: 'Commision', field: 'commision'
        },


]

const [Sales, setSales] = useState()

const cb = (data) =>{
    setSales(data)
}

const filter=()=>{
    let from_date= moment(state[0].startDate).format("YYYY-MM-DD")
    let to_date=moment(state[0].endDate).format("YYYY-MM-DD")
    
    dispatch(listSalesUnderSchool(tokenData.token,from_date,to_date,school_id,student_id,cb))

}

// const handleChangeSchool=(e)=>{
//     setSchool(e.target.value)
// }
// const handleChangeStudent=(e)=>{
//     setStudent(e.target.value)
// }

const Dwnload=()=>{
    let from_date= moment(state[0].startDate).format("YYYY-MM-DD")
    let to_date=moment(state[0].endDate).format("YYYY-MM-DD")
    dispatch(exportSalesUnderSchool(tokenData.token,from_date,to_date,school_id,student_id,Dwnload_Invoice))
}
const Dwnload_Invoice=(val)=>{
    if(val){
        window.open(val,"_blank")
    }
    else{
        Swal.fire({
            icon: 'error',
            title: 'Download Failed',
            text: "Can't Download this Invoice"
        })
    }
}
    return(

        <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
            <Col sm={12}>
            <PageTitle
                heading="Sales Details"
                subheading="Sales details"
                icon="pe-7s-credit icon-gradient bg-happy-itmeo"
                />
            </Col>

            <Col sm={12}>
                <Card style={{ marginBottom: 20, padding: 20 }}>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        // onChange={handleSelect}

                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        maxDate={new Date()}
                        direction="horizontal"
                        color='#fed858'
                    />
                <hr />

                <Row>
                    <Col  md={8}>

                        
                </Col>

                    <Col xs={6} md={2}>
                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                            <span className="text-danger">*</span>
                            {' '}
                        </Label>
                                                      

                            <LaddaButton className=" mr-2 btn btn-primary"
                                style={{ width: '-webkit-fill-available' }}
                                loading={salesloader}
                                type="button"
                                onClick={filter}
                                data-style={ZOOM_IN}  >
                                Filter
                                </LaddaButton>
                    </Col>
                    
                    <Col xs={6} md={2}>
                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                            <span className="text-danger">*</span>
                            {' '}
                        </Label>
                        <Tooltip title='Download Invoice'>
                            <LaddaButton className=" mr-2 btn btn-secondary"
                                style={{ width: '-webkit-fill-available' }}
                                loading={saleinvoicesloader}
                                type="button"
                                onClick={Dwnload}
                                data-style={ZOOM_IN}  >
                                <GetAppOutlinedIcon fontSize='small'/>Invoice
                            </LaddaButton>
                        </Tooltip>
                    </Col>
                    </Row>
                </Card>
            </Col>

            <Row>
                <Col xs={12}>
                    <Table 
                        actions={action}
                        data={Sales}
                        title={'Sales Table'}
                        columns={columns}
                        loader={salesloader}
                    />
                </Col>
            </Row>
        </div>
        </CSSTransitionGroup>
    )
}

export default SalesList