import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import animationData from "../../../Dashboard/animation/check-circle.json";
import errorAnimation from "../../../Dashboard/animation/error.json";
import { updatePurchaseSubscription } from '../../../../redux/Actions/genexhome'
import { Row, Col, Button, } from 'reactstrap'
import Lottie from "react-lottie";
import Modals from '../../../common/Modal'

function PaymentStatus() {

    const history = useHistory()
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const search = useLocation().search;

    const [Loader, setLoader] = useState(true);

    const [successModal, setSuccessModal] = useState({ open: false });
    const [failModal, setFailModal] = useState({ fail: false, msg: "" });

    useEffect(() => {
        const status = new URLSearchParams(search).get('enc_status');
        const order_id = new URLSearchParams(search).get('enc_mots_order_id');



        let data = { order_id: order_id }
        dispatch(updatePurchaseSubscription(tokenData.token, data, cb))


    }, [])

    const closeSuccessModal = () => {
        //  dispatch({type:'PAYMENT_SUCCESSFULLY_COMPLETED'})
        setSuccessModal({ ...successModal, open: false })
        //history.push('/')
        history.push('/dashboard')
    }

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successModal.open
            ? animationData
            : failModal
                ? errorAnimation
                : null,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };



    const cb = (res) => {
        if (res.result) {
            setSuccessModal({ open: true, res })
            setLoader(false);
        } else {
            setFailModal({ fail: true, msg: res.message })
            setLoader(false);
        }
    }
    return (
        <>
            {Loader ?
                <div className='w-100 h-100 d-flex justify-content-center align-items-center flex-column' >
                    <div style={{ fontSize: '20pt' }} className='font-weight-bold' >Payment is processing dont close or refresh this page</div>
                    <div class="spinner-border mt-2" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> : ''
            }
            <Modals
                open={successModal.open}
                handleClose={closeSuccessModal}
                size="sm"
            >
                <Row>
                    <Col md={12}>
                        <Lottie
                            options={defaultOptions}
                            height={145}
                            width={145}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={12}
                        style={{ fontSize: "20px", color: "green", textAlign: "center" }}
                    >
                        Success
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: "center", color: "#5e8e5f" }}>
                        <strong style={{ fontWeight: 400 }}>
                            Payment completed successfully
                        </strong>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: "center" }}>
                        <Button type="button" onClick={closeSuccessModal} color="success">
                            Okay
                        </Button>
                    </Col>
                </Row>
            </Modals>

            <Modals
                open={failModal.fail}
                handleClose={() => {
                    setFailModal({ fail: false, msg: "" })
                    history.push('/')
                }}
                size="sm"
            >
                <Row>
                    <Col md={12}>
                        <Lottie
                            options={defaultOptions}
                            height={145}
                            width={145}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={12}
                        style={{ fontSize: "20px", color: "#c0392b", textAlign: "center" }}
                    >
                        Failed
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: "center", color: "#ab2d20" }}>
                        <strong style={{ fontWeight: 400 }}>{failModal.msg}</strong>
                    </Col>
                </Row>
                <Col md={12} style={{ textAlign: "center", paddingTop: 25 }}>
                    <Button
                        type="button"
                        onClick={() => {
                            setFailModal({ fail: false, msg: "" })
                           // window.location.reload()
                           history.push('/')
                        }}
                        color="danger"
                    >
                        Okay
                    </Button>
                </Col>
            </Modals>
        </>
    )
}

export default PaymentStatus
