import axios from 'axios'
import { development } from '../../config.json'

import Swal from 'sweetalert2'


export function setReqStudentData(name, value) {
    return {
        type: 'SET_STUDENT_DATA',
        payload: { [name]: value },
    }
}

// dispatch({ type: 'CALLERID_REQUEST_TERMINATE', payload: { status: "error", message: err.error_description } })

export const getTicketReason = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TICKETREASON_ALL_REQUEST' })
    axios.get(`${development.baseUrl}reasonsForTickets`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKETREASON_ALL_SUCCESS', payload:{ ReasonArray:res.data,}})

            }
        })
        .catch(err => {
            dispatch({ type: 'TICKETREASON_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}


export const getAllStudentTickets = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TICKET_ALL_REQUEST', payload:{ ticket_student_loader:true} })
    axios.get(`${development.baseUrl}listStudentTickets`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
                    ticketarray:res.data.tickets,
                    ticket_student_loader:false
            }})

            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload:{ ticket_student_loader:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}


export const createTicketStudent = (token,reason_id,order_id,title,message,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={reason_id:reason_id,order_id:order_id,title:title,message:message}
    dispatch({ type: 'TICKET_ALL_REQUEST', payload:{ ticket_create_loader:true} })
    axios.post(`${development.baseUrl}createTicket`,data,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ticket_create_loader:false}})
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload:{ ticket_create_loader:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

export const getSingleTicket = (token,ticket_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={ticket_id:ticket_id}
    dispatch({ type: 'TICKET_ALL_REQUEST' })
    axios.post(`${development.baseUrl}getTicketDetails`,data,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
                    ticket_details:res.data.ticket,
                    Chatdata:res.data.messages
                }})

            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}


export const sendTicketMessage = (token,ticket_id,message,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={ticket_id:ticket_id,message:message}
    dispatch({ type: 'TICKET_ALL_REQUEST', payload:{ ticket_create_loader:true} })
    axios.post(`${development.baseUrl}createTicketMessage`,data,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKET_ALL_SUCCESS'})
                cb()
            }
            else{
                dispatch({ type: 'TICKET_ALL_FAILED', payload:{ ticket_create_loader:false} })
                Swal.fire({
                    icon:'error',
                    title:'Something Went Wrong',
                    text:res.data.message ? res.data.message:'failed to send message'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload:{ ticket_create_loader:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

///////course list///////////

export const getAllStudentCourse = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST' })
    axios.get(`${development.baseUrl}getMyCourse`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    StudentCourseArray:res.data.course_details,
                    counter:res.data.counter,
                    purchase_details:res.data.purchase_details
                }})
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}


export const getMyCourseDetails = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST' })
    axios.get(`${development.baseUrl}getMyCourseDetails`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    HomeStudentCourseArray:res.data.course_details,
                    Homecounter:res.data.counter,
                    Homepurchase_details:res.data.purchase_details
                }})
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}



///////////dwnload order invoice//////
export const getInvoice = (token,order_id,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={order_id:order_id}
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST',payload:{invoiceloader:true} })
    axios.post(`${development.baseUrl}getInvoice`,data,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    // order_invoice:res.data.invoice,
                    invoiceloader:false
                }})
                cb(res.data.invoice)
            }
            else{
                Swal.fire({
                    icon:'error',
                    text:'Download Failed'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{invoiceloader:false}})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

export const listAllRefundsForStudents = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
   // dispatch({ type: 'TICKETREASON_ALL_REQUEST' })
    axios.get(`${development.baseUrl}listAllRefundsForStudent`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'REFUND_STUDENT_SUCCESS', payload:{ StudentRefunds:res.data.refunds}})

            }
        })
        .catch(err => {
           // dispatch({ type: 'REFUND_STUDENT_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

///////subscription expired list///////////

export const listAllExpiredSubscription = (token,page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST',payload:{exp_load:true} })
    axios.get(`${development.baseUrl}listAllExpiredSubscription?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    Expired_Data:res.data.subscriptions ? res.data.subscriptions:[],
                    Expired_Array:res.data.subscriptions ? res.data.subscriptions.data:[],
                    next_page:res.data.subscriptions ? res.data.subscriptions.next_page_url :'',
                    prev_page:res.data.subscriptions ? res.data.subscriptions.prev_page_url :'',
                    // purchase_details:res.data.purchase_details
                    exp_load:false
                }})
            }
            else{
                dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{exp_load:false} })
                Swal.fire({
                    icon:'error',
                    title: 'Something Went Wrong',
                    text:res.data.message? res.data.message:'failed'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{exp_load:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

///////subscription renewed list///////////

export const listAllRenewedSubscription = (token,page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST',payload:{renw_load:true} })
    axios.get(`${development.baseUrl}listAllRenewalSubscription?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    Renew_Data:res.data.subscriptions ? res.data.subscriptions:[],
                    Renew_Array:res.data.subscriptions ? res.data.subscriptions.data:[],
                    Renew_next_page:res.data.subscriptions ? res.data.subscriptions.next_page_url :'',
                    Renew_prev_page:res.data.subscriptions ? res.data.subscriptions.prev_page_url :'',
                    renw_load:false
                }})
            }
            else{
                dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{renw_load:false} })
                Swal.fire({
                    icon:'error',
                    title: 'Something Went Wrong',
                    text:res.data.message? res.data.message:'failed'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{renw_load:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

///////subscription All list///////////

export const listAllNewSubscription = (token,page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST',payload:{all_sub_load:true} })
    axios.get(`${development.baseUrl}listAllNewSubscription?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    All_Subscription_Data:res.data.subscriptions ? res.data.subscriptions:[],
                    All_Subscription_Array:res.data.subscriptions ? res.data.subscriptions.data:[],
                    All_Subscription_next_page:res.data.subscriptions ? res.data.subscriptions.next_page_url :'',
                    All_Subscription_prev_page:res.data.subscriptions ? res.data.subscriptions.prev_page_url :'',
                    all_sub_load:false
                }})
            }
            else{
                dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{all_sub_load:false} })
                Swal.fire({
                    icon:'error',
                    title: 'Something Went Wrong',
                    text:res.data.message? res.data.message:'failed'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{all_sub_load:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}

////////////////subscription list for home page//////////////

export const getMySubscriptionDetails  = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST' })
    axios.get(`${development.baseUrl}getMySubscriptionDetails `, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_SUBSCRIPTIONS_SUCCESS', payload:{ 
                   SubscriptionArray:res.data.subscription_details,
                }})
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}


export const filterStudentBatchByClass = (token,class_id,student_id ) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    
    const data={
        class_id:class_id ? class_id:undefined,
        student_id:student_id ? student_id:undefined
    }

    dispatch({ type: 'STUDENT_LIST_ALL_REQUEST',payload:{batch_loader:true} })
    axios.post(`${development.baseUrl}filterStudentBatchByClass`,data,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_LIST_ALL_SUCCESS', payload:{ 
                    student_batches:res.data ? res.data.batches:[],
                    batch_loader:false
                }})
            }
            
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_LIST_ALL_FAILED',payload:{batch_loader:false}})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        // text:'You are logged in to another System'
                    })
                }
            }
        })
}