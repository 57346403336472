import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import ReactHtmlParser from 'react-html-parser';
import Swal from 'sweetalert2'
import spinner from '../../../assets/animated/Spinner.gif'
import { Modal,ModalHeader,ModalBody } from 'reactstrap';
import previewdummy from "../../LandingPageNew/images/previewdummy.png"
import Tooltip from '@material-ui/core/Tooltip';


import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
const useStyles = makeStyles((theme) =>({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default function MediaCard(props) {

  const classes = useStyles();
  const [video,setVideo]=useState({show:false,url:''})

  const [expanded, setExpanded] = useState(false);
  const [id, setId] = useState('');

  const handleExpandClick = (id) => {
    setId(id)
    setExpanded(!expanded);
  };

  const Playvideo=(rowdata,embed)=>{
    if(rowdata){
        let frstvideo=rowdata ? rowdata[0]:''
        let thumblink=frstvideo[1] ? frstvideo[1].link:''
        if (thumblink.includes('default')) {
            Swal.fire({
                icon: 'warning',
                title: 'Processing',
                // text: "Video on process"

            })
        } 
        else {
        setVideo({show:true,url:embed})
        }
    }
    else{
        Swal.fire({
            icon: 'warning',
            title: 'No Video',
            text: "There is no video"

        })
    }

}
const CloseVideo=()=>{
  setVideo({show:false,url:''})
}
  
    if(props.value.type==='image'){
    return (
  
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={props.value.image}
            title="Contemplative Reptile"
          />
          <CardContent style={{minHeight:'50px'}}>
            <Typography gutterBottom variant="body1">
              {props.value.title}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <IconButton aria-label="delete">
            <DeleteIcon onClick={()=>{props.Removemeadia(props.value.id)}}/>
          </IconButton>
          {
            props.value.description ? 
          <Tooltip title='view description'>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={()=>{handleExpandClick(props.value.id)}}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>:''
          }
            
        </CardActions>
        <Collapse in={expanded && id===props.value.id} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            {props.value.description}
          </Typography>
        </CardContent>
      </Collapse>
      </Card>
    );
    
}
  else{
    return(
      <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={props.value.thumbnails ? props.value.thumbnails[3] ? props.value.thumbnails[3] ? props.value.thumbnails[3].link:previewdummy:previewdummy:previewdummy}
            title="Contemplative Reptile"
          />
          <CardContent style={{minHeight:'50px'}}>
            <Typography gutterBottom variant="body1">
              {props.value.title}
            </Typography>
           
          </CardContent>
        </CardActionArea>
      
        <CardActions disableSpacing>
          <Tooltip title='play video'>
            <IconButton aria-label="play">
              <PlayArrowIcon onClick={()=>{Playvideo(props.value.thumbnails,props.value.embed)}}/>
            </IconButton>
          </Tooltip>
          <IconButton aria-label="delete">
            <DeleteIcon onClick={()=>{props.Removemeadia(props.value.id)}}/>
          </IconButton>
          {
            props.value.description ? 
            <Tooltip title='view description'>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={()=>{handleExpandClick(props.value.id)}}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
          :''
          }
          
        </CardActions>
        <Collapse in={expanded && id===props.value.id} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            {props.value.description}
          </Typography>
        </CardContent>
      </Collapse>
      </Card>

      <Modal
            isOpen={video.show}
            toggle={CloseVideo}
            size={'md'}
        >
            <ModalHeader toggle={CloseVideo}>
                Preview Video
            </ModalHeader>
            <ModalBody>
            {
                video.url ? 
                <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                  {ReactHtmlParser(video.url)}
                </div>
                :
                <div style={{width:'100%',height:'100%',justifyContent:'center',display:'flex',alignItems:'flex'}}>
                    <img  src={previewdummy} alt='' width='200px' height='200px'/>
                </div>
              }
            </ModalBody>
        </Modal>
      </>
    )
  }
  
}

