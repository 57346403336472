import React from 'react';
import coursedummy from "../../images/coursedummy.png"
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning';
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';

function Benefit(props) {

  const vendorname_label = { fontFamily: 'revert', color: 'rgb(0 0 0)' }
  const vendorname = { fontFamily: 'revert', color: 'rgb(20 73 144)', fontWeight: '600' }
  const history = useHistory();

  const arr = props.course_array ? props.course_array : []
  const options = {
    responsiveClass: true,
    nav: false,
    // loop: true,
    margin: 10,
    // dots: arr.length > 4 ? true :false,
    dots: true,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };
  const reschedule = { backgroundColor: 'rgb(49 49 49 / 55%)', borderColor: 'white', position: 'absolute', top: '10px', left: '10px', color: 'white', padding: '0px 2px', borderRadius: '5px', boxSizing: 'border-box' }
  const certification = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#202A60', padding: '3px 20px', background: 'white', width: '96px', boxSizing: 'border-box', display: 'inline-block' }
  const timeleft = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }
  const ShowCourse = (val) => {

    if (val.edit_status === 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Rescheduling!',
        text: 'Will be available soon',
      })
    } else {
      history.push(`/view-course/${val.course_id}`);
    }

    // history.push(`/viewCourse/${val.course_id}`);

  }
  return (
    <>
      {
        arr.length > 0 ?
          <section class="padding-tb-80px" style={{ backgroundColor: '#f5f6f8' }}>
            <div class="container">


              <div class="text-center margin-bottom-35px wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                <h3 style={{ color: '#212529' }} class="font-weight-700 text-title-large" ><span class="fw-200">RECENT </span> COURSES</h3>
              </div>

              <div class="swiper-container swiper-testimonial2 pb-4">
                <div class="swiper-wrapper wow fadeInUp" data-wow-delay="0.2s">
                  <OwlCarousel
                    id="feature-slide"
                    className="owl-carousel owl-theme"
                    {...options}>
                    {
                      arr.map((val, ky) => {
                        let vendor = val.vendor_name ? val.vendor_name : ''
                        let v_name
                        if (vendor.length > 15) {
                          let apend = vendor.slice(0, 14)
                          v_name = apend + '...'
                        }
                        else {
                          v_name = vendor
                        }
                        return (

                          <div class="swiper-slide " style={{ paddingBottom: '5px', cursor: 'pointer' }} key={ky}>
                            {/* <Link to={{pathname: `/viewCourse/${val.course_id}` }}> */}
                            <div class="blog-item thum-hover featured-institute-main certi-hght ">
                              <div class="position-relative">
                                <div onClick={() => { ShowCourse(val) }} class="item-thumbnail recntigcr"><img src={val.banner_url ? val.banner_url : coursedummy} alt="" width='100%' height='200px' 
                                // style={{ objectFit: 'contain', transform: 'scale(0.8)' }} 
                                /></div>
                                <div class="cat-div">{val.course_category}</div>
                                {
                                  val.edit_status === 1 ?
                                    <div style={reschedule}><WarningIcon style={{ color: '#d8d81c', fontSize: '15px' }} /> Rescheduling</div>
                                    : ''
                                }
                              </div>
                              <div class="padding-20px">
                                <div onClick={() => { ShowCourse(val) }} >
                                  <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                    <span class="f-i-title" style={{ color: '#000' }}>{val.course_name}</span>
                                  </div>
                                  <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                    <p class="font-14 mb-1" style={{ color: '#000' }}>{val.course_subject_name}</p>
                                  </div>
                                  <p class="mb-0"><span class="font-16 font-weight-500 text-blue">{val.course_amount}</span></p>
                                </div>
                                <hr class="mt-1 mb-1" />
                                <p class="mb-0">
                                  {
                                    val.certified === 1 ?
                                      <div style={certification}>Certified</div>
                                      : null
                                  }
                                  {
                                    val.days_left ?
                                      <div style={timeleft}>{val.days_left}</div>
                                      :
                                      <span style={{ visibility: 'hidden' }} class="mr-1 course-badges badge badge-dark">{0}</span>
                                  }
                                </p>
                                <Tooltip arrow title={val.vendor_name}>
                                  <Link to={{ pathname: `/vendor/${val.vendor_id}/${val.skl_user_id}` }} >
                                    <span>
                                      <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                    </span>
                                  </Link>
                                </Tooltip>
                              </div>
                            </div>
                            {/* </Link> */}
                          </div>
                        )
                      })
                    }

                  </OwlCarousel>

                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-lg-12 col-12 text-center mt-0">
                  <Link to="/courseList" class="btn btn-gn border-radius-30 wow fadeInUp" data-wow-delay="0.5s">View All</Link>
                </div>
              </div>
            </div>
          </section>
          :
          ''
      }




    </>
  )
}

export default Benefit