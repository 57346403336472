import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Form, FormGroup, Label, FormFeedback, Input, Button } from 'reactstrap'
import PlaylistTumnile from '../../common/Thumbnile/playlist'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { requiredValidator } from '../../../utilities/Validator'
import serialize from 'form-serialize'
import { populatePlayList, createPlayList, updatePlaylist, deletePlaylist, } from '../../../redux/Actions/playlist'
import Modals from '../../common/Modal'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'

const School = () => {

  const params = useParams()

  const [editMode, setEditMode] = useState(false);
  const [playlistDetails, setPlaylistDetails] = useState("");
  const dispatch = useDispatch()
  const playlistStore = useSelector(({ playlist }) => playlist)
  const [updating, setUpdating] = useState([]);


  const tokenData = JSON.parse(localStorage.getItem("authDetails"));


  useEffect(() => {

    dispatch(populatePlayList(tokenData.token, params.course_id))

  }, [])

  const openEdit = (playlist_name, playlist_id, category_id) => {
    setEditMode(true);
    setPlaylistDetails({
      playlist_name: playlist_name,
      playlist_id: playlist_id,
      category_id: category_id,
    });
  };

  const handleChange = (e) => {
    console.log(e);
    // alert(playlist_id)
    const { value } = e.target;
    // alert(value)
    setPlaylistDetails({ ...playlistDetails, playlist_name: value });

  };

  const [inputField, setInputField] = useState({
    playlist_name: '',
    grade_id: '',
    subject_id: '',
  })
  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setInputField({ ...inputField, [name]: value })
  }
  const [errors, setErrors] = useState({})

  const EditPlaylists = (playlist_id, playlist_name, playlistDetails) => {
    // setUpdating([...updating, playlist_id])
    if (!playlist_name) {
      Swal.fire({
        icon: "error",
        title: "Required",
        text: "Playlist name  required",
      });
    } else {
      dispatch(
        updatePlaylist(
          tokenData.token,
          playlist_id,
          playlist_name,
          playlistDetails,
          updated
        )
      );
    }
    // alert(playlist_name)
  };
  const updated = (playlist_id) => {
    // const removed = updating.filter(id => id != playlist_id)
    setEditMode(false);
    // setUpdating(removed)
    dispatch(populatePlayList(tokenData.token, params.course_id))
    Swal.fire({
      icon: "success",
      title: "Updated",
      text: "Playlist Updated  successfully",
    });
  };
  const cancelEdit = (playlist_id) => {
    const removed = updating.filter((id) => id !== playlist_id);
    setEditMode(false);
    setUpdating(removed);
  };
  const handleCloseEdit = () => {
    setEditMode(false);
  };

  const DeletePlaylists = (playlist_id, category_id) => {
    // alert(category_id)
    Swal.fire({
      icon: "warning",
      // title: "Format not supported",
      text: "Are you sure,do you want to delete ",
      confirmButtonText: "Yes",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        dispatch(deletePlaylist(tokenData.token, playlist_id, category_id ,cbdelete));
      }
      
    });
  };

  const cbdelete = () => {
    dispatch(populatePlayList(tokenData.token, params.course_id))
  }

  const formSubmit = (e) => {
    e.preventDefault()
    const form = e.currentTarget
    let body = serialize(form, { hash: true, empty: true })
    let error;
    error = {
      playlist_name: requiredValidator(inputField.playlist_name),
     // course_id: requiredValidator(inputField.course_id),
    }
    if (!error.playlist_name ) {
      dispatch(createPlayList(tokenData.token, body, reset))
      setErrors(error)
    } else {
      setErrors(error)

    }

  }
  const handleSort = (e) => {
    console.log("handle sort",e.target.value)
   const {value} = e.target
   if(value){
     dispatch(populatePlayList(tokenData.token, params.course_id,null,value))
   }
  
  }

  const reset = () => {
    dispatch(populatePlayList(tokenData.token, params.course_id))
    document.getElementById("create-form-school").reset();
  }
  return (
    <>
      {/* 
        <PageTitle
        //style={{ display: 'none' }}
        heading="Create Playlist"
        subheading="Create playlist to attach with video session"
        icon="pe-7s-film icon-gradient bg-happy-itmeo"
      /> */}


      <Form onSubmit={formSubmit} id="create-form-school">


        <Card className="m-3">
          <Row className="jms-subject-row-pad ">
            <Col md={1}></Col>
            <Col md={7}>
              <FormGroup>
                <Label for="exampleCustomSelectDisabled"> <span className="text-danger">*</span>{' '}Playlist Title</Label>
                <Input type="text" name="playlist_name"
                  onChange={handleChangeInput}
                  invalid={errors.playlist_name}
                />
                <FormFeedback >{errors.playlist_name}</FormFeedback>

              </FormGroup>
            </Col>
            <Input type="hidden" value={params.course_id} name="course_id"
                ></Input>

            <Col md={3}>
              <FormGroup>
                <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label>

                <LaddaButton className="mb-2 btn btn-danger"
                  loading={playlistStore.createPlayListSchoolLoader}  
                  // onClick={formSubmit}
                  type="submit"
                  data-style={ZOOM_IN}
                  style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available' }}

                >
                  Create Playlist
                </LaddaButton>
              </FormGroup>
            </Col>
          </Row>
        </Card>
      </Form>
     {/* <Row> */}
           <div style={{ width:'100%', display: 'flex',justifyContent:'space-between'}}>
            <div style={{ paddingTop: 20, fontWeight: 700, paddingLeft:20 }}>{'PlayLists'} </div>
            <div style={{  height:'5px',paddingTop:10, paddingRight:10 }} > <Input type='select' onChange={handleSort} >
              <option value='null'>sort by created date</option>
              <option value='ascending'>Ascending</option>
              <option value='descending'>Descending</option>
              </Input> </div>

          </div>
     {/* </Row> */}


           
            <hr />
          
      <Row className="m-3">

        {

          playlistStore.populatePlaylistSchoolLoader === true ?
            Array(3).fill().map(() => {
              return (
                <Col md={4} style={{ paddingBottom: '10px' }}>
                  <Card style={{ padding: '30px' }}>
                    <Row>
                      <Col md={3}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                          <Skeleton circle={true} height={50} width={50} count={1} />
                        </SkeletonTheme>
                      </Col>
                      <Col md={9}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                          <Skeleton height={20} count={4} />
                        </SkeletonTheme>
                      </Col>
                    </Row>

                  </Card>
                </Col>
              )
            })

            : playlistStore.playlistSchool ? playlistStore.playlistSchool.map((playlist, index) => {
              return (
                <Col md={4} style={{ paddingBottom: '25px' }}>

                  <PlaylistTumnile
                    to={playlist.playlist_id}
                    category_id={playlist.category_id}
                    subject_id={playlist.subject_id}
                    index={index}
                    title={playlist.playlist_name}
                    subject={playlist.subject_name}
                    grade={playlist.grade_name}
                    count={playlist.video_count}
                    DeletePlaylists={DeletePlaylists}
                    EditPlaylists={EditPlaylists}
                    editMode={editMode}
                    openEdit={openEdit}
                    handleChange={handleChange}
                    cancelEdit={cancelEdit}
                    course_id={params.course_id}
                  />


                </Col>
              )
            }) : null
        }




      </Row>

      <Modals
        open={editMode}
        size="sm"
        handleClose={handleCloseEdit}
      // title={`Email verification`}
      >
        <div>
          <Row style={{ padding: 15 }}>
            <Input
              type="text"
              value={playlistDetails.playlist_name}
              onChange={handleChange}
              name="title"
            />
          </Row>
          <Row style={{ paddingTop: 10 }}>
            <Col md={6}>
              <Button
                onClick={() => handleCloseEdit()}
                className="jms-playlist-save"
              >
                Cancel
                  </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={() =>
                  EditPlaylists(
                    playlistDetails.playlist_id,
                    playlistDetails.playlist_name,
                    playlistDetails.category_id
                  )
                }
                color="info"
                className="jms-playlist-save"
              >
                {"Update"}
              </Button>
            </Col>
          </Row>
        </div>
      </Modals>



    </>

  )
}

export default School
