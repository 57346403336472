import React, { useEffect, useState } from 'react'
// import PageTitle from '../../../common/HeaderTitle/PageTitle'
import { Card, Col, Row, Input, Label, FormFeedback } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
//import Table from '../../common/DataTable'
import { populateGroupStudents, migrateGroupStudents } from '../../../../redux/Actions/grouping'
import serialize from 'form-serialize'
import Swal from 'sweetalert2'
import { requiredValidator } from '../../../../utilities/Validator'
import { useDispatch, useSelector } from 'react-redux'
//import AssignTeacher from './assignTeacher'
//import Modals from '../common/Modal'

import { TableRow } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import spinner from '../../../../assets/animated/Spinner.gif'
import EmailIcon from '@material-ui/icons/Email';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PersonIcon from '@material-ui/icons/Person';

import { getUnAssignedStudents,setSubscriptionData} from "../../../../redux/Actions/subscription-action"
import nodata from '../../../LandingPageNew/images/nodata.png'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';

const UnassignedIndex = () => {


    // const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const groupStore = useSelector(({ grouping }) => grouping)

    const page = useSelector((state) =>state.Subscription.unassigned_page ? state.Subscription.unassigned_page : 1);
    const UnassignedStudents = useSelector((state) =>state.Subscription.unassigned_Array ? state.Subscription.unassigned_Array : []);
    const next_page = useSelector((state) =>state.Subscription.unassigned_next_page_url ? state.Subscription.unassigned_next_page_url : '');
    const prev_page = useSelector((state) =>state.Subscription.unassigned_first_page_url ? state.Subscription.unassigned_first_page_url : '');
    const from = useSelector((state) =>state.Subscription.from_unassigned ? state.Subscription.from_unassigned : '');
    const to = useSelector((state) =>state.Subscription.to_unassigned ? state.Subscription.to_unassigned : '');
    const loader = useSelector((state) =>state.Subscription.unassigned_loader ? state.Subscription.unassigned_loader : false);

    useEffect(() => {
        dispatch(getUnAssignedStudents(tokenData.token,page,search))
    }, [page])

    const [mutliSelect, setMultiSelect] = useState([])
    const [all, setAll] = useState(false)
    const [search, setSearch] = useState('')

    const [inputField, setInputField] = useState({
        destination_group_id: ''
    })
    const handleChangeGroup = (e, type) => {
        const { value, name } = e.target;
        setInputField({ ...inputField, [name]: value })
        if (type === 'source') {
            setMultiSelect([])

            dispatch({ type: 'RESET_SOURCE_STUDENT_LIST' })
        } else {
            dispatch({ type: 'RESET_DESTINATION_STUDENT_LIST' })

        }
        if (value !== '') {
            dispatch(populateGroupStudents(tokenData.token, value, type))

        }

    }

    const multiSelectStudent = (student_id) => {

        if (mutliSelect.includes(student_id)) {
            const filtered = mutliSelect.filter(val => val !== student_id)
            setMultiSelect(filtered)

        } else {
            setMultiSelect([...mutliSelect, student_id])
        }

    }

    const [migrationType, setMigrationType] = useState()
    const copyHere = (type) => {
        setMigrationType(type)

    }
    const [errors, setErrors] = useState({})
    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        body.students = mutliSelect
        body.type = migrationType
        body.source_group_id=UnassignedStudents ? UnassignedStudents[0].group_id:''
        const err = {
            destination_group_id: requiredValidator(inputField.destination_group_id),
        }
        if (!err.destination_group_id) {
            setErrors(err)
            if (mutliSelect.length === 0) {

                Swal.fire(
                    'Required',
                    'You have to select minimum 1 student',
                    'warning'
                )
            } else {
                dispatch(migrateGroupStudents(tokenData.token, body, resetForm))

            }
        } else {
            setErrors(err)
        }


    }

    const resetForm = () => {
        setMultiSelect([])
        if(page===1){
            dispatch(getUnAssignedStudents(tokenData.token,1,search))
        }
        else{
            dispatch(setSubscriptionData('unassigned_page',1))
        }
        //document.getElementById("group-form").reset();
    }

    const NextPage=()=>{
        dispatch(setSubscriptionData('unassigned_page',page+1))
    }
    const PreviousPage=()=>{
        dispatch(setSubscriptionData('unassigned_page',page-1))
    }

    const SelectAll=(e)=>{
        setAll(e.target.checked)
        if(e.target.checked===true){
            let arr=[]
            UnassignedStudents.forEach(element => {
                arr.push(element.user_id)
            });
            setMultiSelect(arr)
        }
        else{
            setMultiSelect([])
        }
    }

    const HandleSearch=(e)=>{
        if(e.target.value){
            setSearch(e.target.value)
        }
        else{
            setSearch('')
            if(page===1){
                dispatch(getUnAssignedStudents(tokenData.token,1,''))
            }
            else{
                dispatch(setSubscriptionData('unassigned_page',1))
            }
        }
    }

    const SearchSub=(e)=>{
        e.preventDefault()
        if(page===1){
            dispatch(getUnAssignedStudents(tokenData.token,1,search))
        }
        else{
            dispatch(setSubscriptionData('unassigned_page',1))
        }
    }
    return (
        <>

            <Card style={{ padding: 20 }}>
                <Row>
                    <Col xs={12} sm={3}>
                        <h6>Group UnAssigned Students</h6>
                    </Col>
                    <Col xs={6} sm={3}>
                        <Input
                            type='search'
                            name='search_sub'
                            onChange={HandleSearch}
                            value={search}
                            style={{width:'100%'}}
                        />
                    </Col>
                    <Col xs={6} sm={4}>
                    <Button
                            disabled={search ? false :true}
                            onClick={SearchSub}
                            variant='outlined'
                            color='primary'
                            size='medium'
                        >
                            search
                        </Button>
                    </Col>
                </Row>
                <hr />
                <form onSubmit={formSubmit} id="group-form">
                    <Row >
                        <Col sm={12} md={8} >
                        {
                            loader ? 
                            <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                            </div>
                        :
                        <>
                        {
                            UnassignedStudents.length > 0 ?
                            <>
                                    <Table  aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='center'>
                                                  <Input type="checkbox" name='all' onChange={SelectAll} checked={all}></Input>
                                                </TableCell>
                                                <TableCell>Student <span><PersonIcon style={{fontSize:'15px'}} /> </span></TableCell>
                                                <TableCell>Email <span><EmailIcon  style={{fontSize:'15px'}} /> </span></TableCell>
                                                <TableCell>Phone <span><PhoneAndroidIcon  style={{fontSize:'15px'}}/> </span></TableCell>
                                                <TableCell>Subscription</TableCell>
                                            </TableRow>
                                        </TableHead>
                            
                                        <TableBody>
                                        {
                                        UnassignedStudents.map((val,ky)=>{
                                            return(
                                            <TableRow key={ky}>
                                                <TableCell align='center'>
                                                    <Input type="checkbox" onChange={() => multiSelectStudent(val.user_id)} value={val.user_id} checked={mutliSelect.includes(val.user_id) ? true : false} />
                                                </TableCell>
                                                <TableCell>{val.first_name} {val.last_name}</TableCell>
                                                <TableCell>{val.email}</TableCell>
                                                <TableCell>{val.parent_mobile}</TableCell>
                                                <TableCell>{val.subscription_name}</TableCell>
                                            </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                    
                                </Table>
                                <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <div style={{width:'50%',display:'flex',height:'50px',justifyContent:'flex-end'}}>

                                        <span style={{marginTop:'13px'}}>{from}-{to}</span>
                                        <Button
                                            onClick={PreviousPage}
                                            disabled={prev_page ? false :true}
                                        >
                                            <NavigateBeforeIcon />
                                        </Button>
                                        <Button
                                            onClick={NextPage}
                                            disabled={next_page ? false :true}
                                        >
                                            <NavigateNextIcon />
                                        </Button>
                                    </div>
                                </div>
                            </>
                            :
                            <img src={nodata} width='100%' height='250px' alt='' />
                        }
                    </>
                                
                    } 

                        </Col>

                        <Col sm={12} md={4} style={{ borderLeft: '1px solid #a0a4aa' }}>
                            <Row>
                                <Col md={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                        {' '}Destination Group
                                    </Label>
                                    <Input type="select"
                                        onChange={(e) => handleChangeGroup(e, 'destination')}
                                        name='destination_group_id'
                                        // required
                                        // value={this.state.loaderType}
                                        invalid={errors.destination_group_id}
                                    >

                                        <option value={''}>{'select'}</option>
                                        {groupStore.groupList.map(listGroup => {
                                            return (
                                                <option value={listGroup.group_id}>{listGroup.group_name}</option>

                                            )
                                        })
                                        }

                                    </Input>
                                    <FormFeedback >{errors.destination_group_id}</FormFeedback>

                                </Col>
                                <Col md={12} style={{ padding: 20 }}>
                                    {groupStore.destinationGroupStudentsLoader ? 'Fetching....' : null}
                                    {groupStore.destinationGroupStudents.length > 0 ? groupStore.destinationGroupStudents.map(student => {
                                        return (
                                            <Row style={{ paddingTop: 10 }}>
                                                <Col md={8}>
                                                    {student.full_name}</Col>
                                                <Col md={4}> {student.user_id}</Col>
                                            </Row>
                                        )
                                    }) : null
                                    }
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                    <hr />
                    <Row style={{ paddingTop: 15 }}>

                        <Col xs={4} md={9}></Col>
                        <Col xs={8} md={3} style={{textAlign:'right'}}>

                            <LaddaButton className="mb-2  btn btn-danger"
                                loading={groupStore.copyButtonLoader}
                                type="submit"
                                name="copy"
                                onClick={() => copyHere("copy")}
                                data-style={ZOOM_IN}  
                                disabled={mutliSelect.length > 0 ? false :true}
                                >
                                Move here
                            </LaddaButton>
                            {/* <LaddaButton className="mb-2 ml-2 btn btn-danger"
                                loading={groupStore.moveButtonLoader}
                                type="submit"
                                name="move"
                                onClick={(e) => copyHere("move")}

                                data-style={ZOOM_IN}  >
                                Move here
                            </LaddaButton> */}
                        </Col>
                        {/* <Col md={2}>
                            
                        </Col> */}


                    </Row>
                </form>
            </Card>



        </>
    )
}

export default UnassignedIndex
