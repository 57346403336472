import React from 'react'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                // hadleOpenModal={(id) => props.hadleOpenModal(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns} handleEdit
                // handleEdit={(class_id, teacher_id) => props.handleEdit(class_id, teacher_id)}
                ViewDetails={(id)=>{props.ViewDetails(id)}}
                Editschool={(id)=>{props.Editschool(id)}}
                Deleteschool={(id)=>{props.Deleteschool(id)}}
                Updatestatus={(id)=>{props.Updatestatus(id)}}
                CreditDebit={(id)=>{props.CreditDebit(id)}}
                data={props.data}
                title={props.title}
                loader={props.loader}

            />
        </>
    )
}

export default Table
