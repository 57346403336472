import React, { useEffect,useState } from 'react';
import PageTitle from "../../HeaderTitle/PageTitle";
import nodata from '../../LandingPageNew/images/nodata.png'
import coursedummy from "../../LandingPageNew/images/coursedummy.png"

import dateicon from "./icons/coursedate.png"
import video from "./icons/totalvideoclass.png"
import live from "./icons/totalliveclass .png"
import grade from "./icons/grade.png"
import subject from "./icons/subject.png"
import seats from "./icons/seats.png"
import enroll from "./icons/enrolldate.png"
import status from "./icons/coursestatus.png"
import fees from "./icons/fees.png"
import type from "./icons/type.png"
import classtype from "./icons/classtype.png"
import category_tg from "./icons/category_tag.png"

import {
    Input, InputGroup, 
    Row, Col,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    CardHeader,
    CardFooter,

    Card, CardBody,
    DropdownItem,
    Form

} from 'reactstrap';


import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import EmailIcon from '@material-ui/icons/Email';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
// import PersonIcon from '@material-ui/icons/Person';

import {

    faArrowRight,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllvendorCourses, 
    setReqData,
    getAllvendorCoursesSearch,
    // getCoursePurchasedStudent 
} from '../../../redux/Actions/Superadmin'
import { deleteCourse } from '../../../redux/Actions/vendor_course'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import LoadingOverlay from 'react-loading-overlay';
import SweetAlert from 'sweetalert-react';
import "./card.css"

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Badge from 'reactstrap/lib/Badge';

// import { TableRow } from "@material-ui/core";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
import Model from "../../common/Modal/index"
// import spinner from '../../../assets/animated/Spinner.gif'
// import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Chip from '@material-ui/core/Chip';

function Cards() {


    const dispatch = useDispatch();
    const history = useHistory();

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const [pagestd,setPage]=useState(1)
    const [clear,setClear]=useState(false)
    // const [open,setOpen]=useState(false)
    // const [course,setCoure]=useState('')
    const [showtag,setShowtag]=useState({show:false,tags:[]})

    const VendorCourseArray = useSelector((state) => state.superadmin.VendorCourseArray ? state.superadmin.VendorCourseArray : '');
    const data = VendorCourseArray.courses ? VendorCourseArray.courses.data : []

    const vendor_nextpage = useSelector((state) => state.superadmin.vendor_nextpage ? state.superadmin.vendor_nextpage : '');
    const vendor_prevpage = useSelector((state) => state.superadmin.vendor_prevpage ? state.superadmin.vendor_prevpage : '');
    const vendorcourseloader = useSelector((state) => state.superadmin.vendorcourseloader ? state.superadmin.vendorcourseloader : false);
   
    // const purchased_student_loader = useSelector((state) => state.superadmin.purchased_student_loader ? state.superadmin.purchased_student_loader : false);
    // const purchased_list = useSelector((state) => state.superadmin.purchased_list ? state.superadmin.purchased_list : []);

    const page = useSelector((state) => state.superadmin.vendor_page ? state.superadmin.vendor_page : 1);
    const search = useSelector((state) => state.superadmin.vendor_search_term ? state.superadmin.vendor_search_term : '');
    const searched = useSelector((state) => state.superadmin.vendor_searched ? state.superadmin.vendor_searched : false);
    const filter = useSelector((state) => state.superadmin.vendor_filter ? state.superadmin.vendor_filter : '');

    // const next_page_student = useSelector((state) => state.superadmin.next_page_student ? state.superadmin.next_page_student : '');
    // const prev_page_student = useSelector((state) => state.superadmin.prev_page_student ? state.superadmin.prev_page_student : '');
    // const from = useSelector((state) => state.superadmin.from_student ? state.superadmin.from_student : '');
    // const to = useSelector((state) => state.superadmin.to_student ? state.superadmin.to_student : '');

    useEffect(() => {
        if(searched){
            dispatch(getAllvendorCoursesSearch(tokenData.token,'',page,search,filter,clearbutton))

            window.scrollTo({top:0,behavior:'smooth'});
        }
        else{
            dispatch(getAllvendorCourses(tokenData.token,'',page))
            window.scrollTo({top:0,behavior:'smooth'});
        }
      }, [page])


      ////////////////student model pagination/////
    //   useEffect(()=>{
    //     if(open){
    //         dispatch(getCoursePurchasedStudent(tokenData.token,course,pagestd))
    //     }
    //   },[pagestd])

    const NextPage=()=>{
        dispatch(setReqData('vendor_page',page+1))
    }
    const PreviousPage=()=>{
        dispatch(setReqData('vendor_page',page-1))
    }


    const viewcoursedetail = (val) => {
        history.push('/dashboard/showCourse/' + val.course_id)
      }

    const editcourse = (val) => {
    dispatch(setReqData('tab', '1'))
    history.push('/dashboard/editCourse/' + val.course_id)
    }
    

    const DeleteCourse = (val) => {

        Swal.fire({
          icon: 'warning',
          title: 'Are you sure !',
    
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "CONFIRM",
          cancelButtonText: "CANCEL",
    
        }).then((result) => {
          if (result.value) {
    
            dispatch(deleteCourse(tokenData.token, val.course_id, cb))
    
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Delete action cancelled',
              'error'
            )
          }
        })
      }

const [successModal, setSuccessModal] = useState(false);


const cb = () => {
// let enterprise_id = ''
//////////if delete after search
    dispatch(setReqData('vendor_searched',false))
    setClear(false)
    dispatch(setReqData('vendor_search_term',''))
    dispatch(setReqData('vendor_filter',''))

    if(page===1){
        dispatch(getAllvendorCourses(tokenData.token,'',1))
    }
    else{
        dispatch(setReqData('vendor_page',1))
    }
    setSuccessModal(true)
}

const handleCloseSuccesstModal = () => {
    
    setSuccessModal(false)
}

const CreateVideoSession = (val) => {
    history.push('/dashboard/createVideoSession/' + val.course_id)
}
  const CreateLiveClass = (val) => {
    history.push('/dashboard/createClass/' + val.course_id)
}

const demo = () => {
    return (
        <div>
            <div className="pane-right">
                <Form onSubmit={Searchcourse} >
                <InputGroup>
                    <Input type='search' placeholder="Search..." onChange={HandleChange} value={search ? search:''} style={{marginRight:'5px'}}/>
                   
                    <Input type='select' onChange={HandleChangeStatus} value={filter ? filter:''} style={{marginRight:'5px'}}>
                    <option value={''}>{'Select Status'}</option>
                    <option value={'approved'}>{'Approved'}</option>
                    <option value={'rejected'}>{'Rejected'}</option>
                    <option value={'requested'}>{'Requested'}</option>
                    <option value={'unrequested'}>{'Unrequested'}</option>

                    </Input>
                    
                    <Button color='primary' variant='contained' onClick={Searchcourse} size='small' disabled={(search || filter) ? false:true} style={{marginRight:'5px'}}>
                            apply
                    </Button>
                    {
                        clear ? 
                        <Button color='secondary' variant='outlined' onClick={ClearSearch} size='small'>
                           clear
                        </Button>
                        :
                        ''
                    }
                    
  
                </InputGroup>
                </Form>
            </div>
        </div>
    )
}

const HandleChange=(e)=>{
    e.preventDefault()
    dispatch(setReqData('vendor_search_term',e.target.value))

}
const HandleChangeStatus=(e)=>{
    e.preventDefault()
    dispatch(setReqData('vendor_filter',e.target.value))

}
const Searchcourse=(e)=>{
    /////////to be change
   e.preventDefault()
    let arr=[]
    arr.push(filter)
 
    if(page===1){
        dispatch(getAllvendorCoursesSearch(tokenData.token,'',1,search,arr,clearbutton))
    }
    else{
        dispatch(setReqData('vendor_searched',true))
        setClear(true)
        dispatch(setReqData('vendor_page',1))

    }
}
const clearbutton=()=>{
    dispatch(setReqData('vendor_searched',true))
    setClear(true)
}

const ClearSearch=(e)=>{
    // setSearched(false)
    e.preventDefault()
    dispatch(setReqData('vendor_searched',false))
    setClear(false)
    dispatch(setReqData('vendor_search_term',''))
    dispatch(setReqData('vendor_filter',''))
    if(page===1){
        dispatch(getAllvendorCourses(tokenData.token,'',1))
    }
    else{
        dispatch(setReqData('vendor_page',1))

    }
}

const Preview=(val)=>{  
    history.push(`/dashboard/showCourse/Preview/${val.course_id}`)

}
const StudentList=(val)=>{
    history.push(`/dashboard/course/${val.course_id}/student-list`)
    // setCoure(val.course_id)
    // dispatch(getCoursePurchasedStudent(tokenData.token,val.course_id,pagestd))
    // setOpen(true)
}

// const CloseModel=()=>{
//     setPage(1)
//     setCoure('')
//     dispatch(setReqData('next_page_student',''))
//     dispatch(setReqData('prev_page_student',''))
//     dispatch(setReqData('from_student',''))
//     dispatch(setReqData('to_student',''))
//     setOpen(false)
// }

// const NextPageStudent=()=>{
//     setPage((prev)=>prev+1)
// }
// const PreviousPageStudent=()=>{
//     setPage((prev)=>prev-1)
// }

const ViewTags=(val)=>{
    setShowtag({show:true,tags:val ? val:[]})
}

const CloseModelTag=()=>{
    setShowtag({show:false,tags:[]})
}

const status_style1={backgroundColor:'rgb(49 49 49 / 55%)',borderColor:'white',position:'absolute',bottom:'0px',left:'0px',padding:'0px 2px',borderRadius:'5px',boxSizing:'border-box'}
const status_style2={backgroundColor:'rgb(49 49 49 / 55%)',borderColor:'white',position:'absolute',bottom:'0px',right:'0px',padding:'0px 2px',borderRadius:'5px',boxSizing:'border-box'}
const status_head={color:'white',fontSize:'10px'}
const status_val={color:'snow'}

    return (
        <div>
        {
            VendorCourseArray ? 
            <>
            <PageTitle
                style={{ display: "none" }}
                heading="Show Courses"
                subheading="List of all courses "
                icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
                actions={demo}
            />
            <LoadingOverlay
                active={vendorcourseloader}
                spinner
                text='Loading your content...'
            >
                {
                    data.length===0 ?
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={nodata} width='600px' height='400px' />
                    </div>
                    :
                 
            <Row style={{ margin: "10px" }} lg="6" xl="4">
                    {
                        data.map((val,ky)=>{
                            return(
                                <Card style={{ marginRight:"20px", width: "300px" }} className="mb-3 " key={ky}>
                                        <CardHeader className="card-header-tab">
                                            <div style={{ width: "250px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "clip" }}
                                                className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                                    {val.course_name}
                                                </div>

                                                {
                                                    (val.request_status === "requested" || (val.request_status === "approved" && val.edit_status===0 )) ?
                                                    <div className="btn-actions-pane-right actions-icon-btn">
                                                        <UncontrolledButtonDropdown direction='left'>
                                                            <DropdownToggle className="btn-icon btn-icon-only" color="link">
                                                                <i className="pe-7s-config btn-icon-wrapper" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                                                                <DropdownItem header>Actions</DropdownItem>

                                                                    <DropdownItem onClick={()=>{Preview(val)}}>
                                                                        <i className="dropdown-icon lnr-frame-expand"> </i>
                                                                        <span>Preview Course</span>
                                                                    </DropdownItem>

                                                                    <DropdownItem onClick={()=>{StudentList(val)}}>
                                                                        <i className="dropdown-icon lnr-user"> </i>
                                                                        <span>Student List</span>
                                                                    </DropdownItem>
                                                            </DropdownMenu>
                                                            
                                                        </UncontrolledButtonDropdown>
                                                        </div>        
                                                    :
                                                    
                                                    <div className="btn-actions-pane-right actions-icon-btn">
                                                        <UncontrolledButtonDropdown direction='left'>
                                                            <DropdownToggle className="btn-icon btn-icon-only" color="link">
                                                                <i className="pe-7s-config btn-icon-wrapper" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                                                                <DropdownItem header>Actions</DropdownItem>

                                                                    <DropdownItem onClick={()=>{Preview(val)}}>
                                                                        <i className="dropdown-icon lnr-frame-expand"> </i>
                                                                        <span>Preview Course</span>
                                                                    </DropdownItem>

                                                                    <DropdownItem onClick={()=>{StudentList(val)}}>
                                                                        <i className="dropdown-icon lnr-user"> </i>
                                                                        <span>Student List</span>
                                                                    </DropdownItem>
                                                                {
                                                                     (val.live_class_enabled)  ?
                                                                    
                                                                    <DropdownItem onClick={()=>{CreateLiveClass(val)}}>
                                                                        <i className="dropdown-icon lnr-inbox"> </i>
                                                                        <span>Add Live Class</span>
                                                                    </DropdownItem>

                                                                    : ''
                                                                }
                                                                
                                                                {
                                                                     (val.ondemand_video_enabled)  ?
                                                                    
                                                                    <DropdownItem onClick={()=>{CreateVideoSession(val)}}>
                                                                        <i className="dropdown-icon lnr-file-empty"> </i>
                                                                        <span>Add Video Class</span>
                                                                    </DropdownItem>
                                                                    
                                                                    :
                                                                    ''
                                                                }
                                                                
                                                                
                                                                <div className="p-1 text-right">
                                                                    {
                                                                        (val.request_status === "requested" || (val.request_status === "approved" && val.edit_status===0) ) ?
                                                                        ''
                                                                        :
                                                                        <>
                                                                            <DropdownItem divider />
                                                                            <Button color='primary' size='small' variant='contained' style={{marginRight:'5px'}} onClick={()=>{editcourse(val)}}>Edit</Button>
                                                                            <Button color='secondary' size='small' variant='contained' onClick={()=>{DeleteCourse(val)}}>Delete</Button>
                                                                        </>
                                                                    }
                                                                    
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                }
                                            
                                        </CardHeader>
                                        <div style={{position:'relative'}} className="image-clean recntigcr">
                                            <img  alt="banner" width="100%" src={val.logo_url ? val.logo_url :coursedummy} height='260px' ></img>
                                            {
                                                val.edit_request_status ? 
                                                    <div style={status_style1}>
                                                        <span style={status_head}>Edit status</span><br/><span style={status_val}>{val.edit_request_status}</span>
                                                    </div>:''
                                            }
                                            {
                                                val.edited_request_status ? 
                                                    <div style={status_style2}>
                                                        <span style={status_head}>Edited status</span><br/><span style={status_val}>{val.edited_request_status}</span>
                                                    </div>:''
                                            }   
                                                
                                        </div>
                                        <CardBody className="pt-2 pb-0">
                                        <Row>
                                            
                                            <Col xs={12} sm={6}>
                                                
                                               
                                                    <Badge color='warning'>{val.product_code}</Badge>
                                            </Col>
                                            <Col xs={12} sm={6} style={{textAlign:'right'}}>
                                                <Tooltip arrow title='course status'>
                                                    <span
                                                    className={val.request_status === 'requested' ? 'req'
                                                            : val.request_status === 'rejected' ? 'rej'
                                                                : val.request_status === 'approved' ? 'app'
                                                                    : val.request_status === 'unrequested' ? 'unreq' : null}>{val.request_status} </span>
                                                </Tooltip>
                                            </Col>
                                            
                                        </Row>
                                            <div className='cardscroll'>
                                                <Row>
                                                <Col xs={12}>
                                                    <span style={{fontSize:'16px',fontWeight:'700',color:'#333'}}>
                                                        {val.course_name}
                                                    </span>
                                                </Col>
                                                

                                                    <Col xs={7} style={{marginTop:'5px'}}>
                                                        <img src={fees} width='20px' height='20px' />   
                                                        <span className='headcourse'>Proposed Fee</span>
                                                    </Col>
                                                    <Col xs={5}  style={{marginTop:'5px',fontSize:'15px'}}>{val.proposed_fee}</Col>
                                                    
                                                    <Col xs={7} style={{marginTop:'5px'}}>
                                                        <img src={type} width='20px' height='20px' />   
                                                        <span className='headcourse'>Course Type</span>
                                                    </Col>
                                                    <Col xs={5}  style={{marginTop:'5px',fontSize:'15px'}}>{val.course_type}</Col>

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={grade} width='20px' height='20px' />   
                                                        <span className='headcourse'>Grade</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.grade_name}</Col>

                                            
                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={subject} width='20px' height='20px' />   

                                                            <span className='headcourse'>Subject</span>
                                                        </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.course_subject_name}</Col>

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={category_tg} width='20px' height='20px' />   
                                                            <span className='headcourse'>Tags</span>
                                                        </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>
                                                            <Button
                                                                size='small'
                                                                variant='outlined'
                                                                color='primary'
                                                                onClick={()=>{ViewTags(val.tags)}}
                                                            >
                                                                view
                                                            </Button>
                                                    </Col>

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={seats} width='20px' height='20px' />   

                                                        <span className='headcourse'>Seats</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.seats}</Col>
                                                    {
                                                        val.live_class_enabled ? 
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={live} width='20px' height='20px' />   
                                                            <span className='headcourse'>Total live Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.live_class_count}</Col>

                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={classtype} width='20px' height='20px' />   
                                                            <span className='headcourse'>Class Type</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.class_type ? val.class_type:'-'}</Col>

                                                        </>
                                                        :
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={live} width='20px' height='20px' />   
                                                            <span className='headcourse'>Total live Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>-</Col>

                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={classtype} width='20px' height='20px' />   
                                                            <span className='headcourse'>Class Type</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>-</Col>
                                                        </>
                                                    }
                                                    
                                                    {
                                                        val.ondemand_video_enabled ?
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={video} width='19px' height='19px' />   

                                                        <span className='headcourse'>Total video Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.ondemand_video_count}</Col>
                                                        </>
                                                        :
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={video} width='19px' height='19px' />   

                                                        <span className='headcourse'>Total video Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>-</Col>
                                                        </>
                                                    }

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={enroll} width='20px' height='20px' />   

                                                        <span className='headcourse'>Admission Date</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.enrollment_date}</Col>

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={dateicon} width='20px' height='20px' />   
                                                        <span className='headcourse'>Course Date</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.course_date}</Col>

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={status} width='20px' height='20px' />   
                                                        <span className='headcourse'>Course Status</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px'}}>
                                                    {
                                                        val.course_status===1 ?
                                                        <span style={{color:'green',fontSize:'15px',fontWeight:'bold'}}>ACTIVE</span>
                                                        :
                                                        <span style={{color:'red',fontSize:'15px',fontWeight:'bold'}}>IN ACTIVE</span>

                                                    }
                                                    </Col>
                                                </Row>
                                                  
                                            </div>
                                        </CardBody>
                        <CardFooter className="d-block text-center rm-border">
                            <Button color="primary" variant='contained'  size='medium' onClick={()=>{viewcoursedetail(val)}}>
                                View Full Details
                                            <span className="text-white pl-2 opacity-3">
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                            </Button>
                        </CardFooter>
                    </Card>   
                            )
                        })
                    }
            </Row>
            }
            {
                data.length > 0 && (vendor_prevpage || vendor_nextpage) ? 
                <Row>
                <Col xs={12}>
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div style={{width:'25%',display:'flex',justifyContent:'space-evenly'}}>

                        <Button 
                            color="primary" 
                            onClick={PreviousPage}
                            disabled={vendor_prevpage ? false :true}
                            variant='contained'
                            // size='small'
                            >
                                Prev
                            </Button>


                            <Button 
                            color="primary" 
                            onClick={NextPage}
                            disabled={vendor_nextpage ? false :true}
                            variant='contained'
                            // size='small'
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            :''

            }
            
            </LoadingOverlay>


            <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Deleted successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />

        {/* <Model
          open={open}
          handleClose={CloseModel}
          title={'Student List'}
      >
          {
            purchased_student_loader ? 
            <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

            </div>
            :
            <>
            {
                purchased_list.length > 0 ?
                <>
                        <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Student <span><PersonIcon style={{fontSize:'15px'}} /> </span></TableCell>
                                    <TableCell>Email <span><EmailIcon  style={{fontSize:'15px'}} /> </span></TableCell>
                                    <TableCell>Phone <span><PhoneAndroidIcon  style={{fontSize:'15px'}}/> </span></TableCell>
                                </TableRow>
                            </TableHead>
                
                            <TableBody>
                            {
                            purchased_list.map((val,ky)=>{
                                return(
                                <TableRow key={ky}>
                                    <TableCell>{val.first_name}</TableCell>
                                    <TableCell>{val.email}</TableCell>
                                    <TableCell>{val.parent_mobile}</TableCell>
                                </TableRow>
                                )
                            })
                            }
                        </TableBody>
                        
                    </Table>
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                        <div style={{width:'50%',display:'flex',height:'50px',justifyContent:'flex-end'}}>

                            <span style={{marginTop:'13px'}}>{from}-{to}</span>
                            <Button
                                onClick={PreviousPageStudent}
                                disabled={prev_page_student ? false :true}
                            >
                                <NavigateBeforeIcon />
                            </Button>
                            <Button
                                onClick={NextPageStudent}
                                disabled={next_page_student ? false :true}
                            >
                                <NavigateNextIcon />
                            </Button>
                        </div>
                    </div>
                </>
                :
                <img src={nodata} width='100%' height='250px' />
            }
        </>
                    
        } 
                
      </Model> */}

      <Model
          open={showtag.show}
          handleClose={CloseModelTag}
          title={'Category Tags'}
      >
          {
              showtag.tags.length > 0 ? 
                <>
                    {
                        showtag.tags.map((val,ky)=>{
                            return(
                                <Chip
                                    key={ky}
                                    // size="small"
                                    label={val.tag_name}
                                    color="primary"
                                    variant='outlined'
                                    style={{margin:'3px 0px 0px 3px'}}
                                    clickable
                                    onClick={()=>{history.push(`/categories/${val.tag_id}`)}}
                                />
                            )
                        })
                    }
                </>
                :
                <>
                {
                    showtag.show &&<img src={nodata} width='100%' height='250px' />
                }
                </>

          }
      </Model>
        </>
        :
        <>
        <Card style={{ padding: '30px' }}>
          <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
          </SkeletonTheme>
        </Card>
        <Card style={{ padding: '30px' }}>
          <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
          </SkeletonTheme>
        </Card>
        </>
        }
        </div>
        
    )
}

export default Cards
