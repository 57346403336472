import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Badge,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Container,
} from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import bg1 from '../../../assets/utils/images/dropdown-header/abstract1.jpg';
import bg2 from '../../../assets/utils/images/dropdown-header/abstract2.jpg';
import bg3 from '../../../assets/utils/images/dropdown-header/abstract3.jpg';
import bg4 from '../../../assets/utils/images/dropdown-header/abstract4.jpg';
import bg5 from '../../../assets/utils/images/dropdown-header/abstract5.jpg';
import bg6 from '../../../assets/utils/images/dropdown-header/abstract6.jpg';

import Slider from "react-slick";

import Table from "./table";

import { useDispatch, useSelector } from "react-redux";
import { getAllvendors, setReqData, deleteVendor, blockVendor, subscriptionStatus, enableSubscription, disableSubscription } from "../../../redux/Actions/Superadmin";
import { useHistory } from "react-router-dom";

import Swal from 'sweetalert2'
import SweetAlert from 'sweetalert-react';

const Index = () => {
  var columns;
  // var action
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const VendorArray = useSelector((state) =>
    state.superadmin.VendorArray ? state.superadmin.VendorArray : []
  );
  const vendorloader = useSelector((state) =>
    state.superadmin.vendorloader ? state.superadmin.vendorloader : ""
  );


  useEffect(() => {
    if (!localStorage.getItem("authDetails")) {
      history.push("/");
    }
    else {
      dispatch(getAllvendors(tokenData.token));
      dispatch(setReqData('selectedvendor', ''))

    }
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
  ]
  };

  columns = [
    { title: "Name", field: "enterprise_name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "contact_number" },
    { title: "PIN", field: "pin" },
    {
      title: "Balance", field: "balance", render: (rowData) => {
        return "Rs. " + rowData.balance
      }
    },
    {
      title: "Signup", field: "signup_status",
      render: (rowData) => {
        return rowData.signup_status === 1 ? (
          <Badge color="success" pill>
            {"COMPLETED"}
          </Badge>
        ) : (
          <Badge color="warning" pill>
            {"PARTIAL"}
          </Badge>
        );
      },
    },
    // { title: "Courses", field: "total_courses" },
    {
      title: 'Status', field: 'status',
      render: (rowData) => {
        return (
          rowData.status === 0 ?
            <Badge color="success" pill>{'Active'}</Badge>
            :
            <Badge color="danger" pill>{'Blocked'}</Badge>
        )
      }
    },

  ];

  const data = VendorArray ? VendorArray.vendors ? VendorArray.vendors : [] : [];

  const recentdata = VendorArray ? VendorArray.recentVendors ? VendorArray.recentVendors : [] : []

  const action = "vendor_table";

  // const VendorviewStudents = (val) => {
  //   dispatch(setReqData('vendorname',val.enterprise_name))
  //   history.push(`/dashboard/vendorstudent/${val.enterprise_id}`);
  // };

  // const ViewCourse=(val)=>{
  //   // console.log('rowDatarowDatarowDatarowDatarowDatav',val)
  //   dispatch(setReqData('vendorname',val.enterprise_name))
  //   history.push(`/dashboard/vendorcourse/${val.enterprise_id}`);
  // }

  const Vendordetails = (val) => {
    dispatch(setReqData('selectedvendor', val))
    history.push(`/dashboard/vendor/${val.enterprise_id}/${val.user_id}`)
  }

  const EditVendor = (val) => {
    history.push(`/dashboard/editVendor/${val.user_id}`)
  }

  const Deletevendor = (val) => {
    console.log(val)
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(deleteVendor(tokenData.token, val.user_id, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Delete action cancelled',
          'error'
        )
      }
    })
  }

  const [successModal, setSuccessModal] = useState(false);
  const [Message, setMessage] = useState(null)

  const handleOpenSuccessModal = (message) => {
    dispatch(getAllvendors(tokenData.token));
    setMessage(message)
    setSuccessModal(true)
  }
  const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
    setMessage(null)
  }

  const subscriptionActive = (val) => {
    alert(val.user_id)
  }

  const Updatestatus = (val) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(blockVendor(tokenData.token, val.user_id, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Cancelled',
          'error'
        )
      }
    })
  }

  const UpdateSubscriptionStatus = (val) => {
    console.log("logging sub", val)
    // Swal.fire({
    //   icon: 'warning',
    //   title: 'Are you sure!',
    //   //text: 'All the classes under the subject will be deleted',
    //   showCancelButton: true,
    //   confirmButtonColor: "#DD6B55",
    //   confirmButtonText: "CONFIRM",
    //   cancelButtonText: "CANCEL",

    // }).then((result) => {
    //   if (result.value) {
    //     dispatch(subscriptionStatus(tokenData.token, val.user_id, handleOpenSuccessModal))
    //   }
    // })
    if (val.subscription_enabled) {
      dispatch(disableSubscription(tokenData.token, "disable", val.user_id, handleOpenSuccessModal))
    } else {
      dispatch(enableSubscription(tokenData.token, "enable", val.user_id, handleOpenSuccessModal))
    }
  }
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container >
          {
            recentdata.length > 0 ?
              <Row>
                <Col md="12">
                  <Card className="main-card mb-3">
                    <CardHeader>Recently Added Partners</CardHeader>
                    <CardBody>
                      <Container>
                        <Row>
                          <Col sm={12}>


                            <Slider {...settings}>

                              {
                                recentdata.map((val, ky) => {
                                  return (
                                    // <div style={{padding:'20px',boxSizing:'border-box',backgroundColor:'red'}}>
                                    <div style={{ boxSizing: 'border-box' }}>
                                      <Card
                                        style={{ cursor: 'pointer', width: '90%', }}
                                        onClick={() => { Vendordetails(val) }}
                                      >
                                        <div className="dropdown-menu-header">
                                          <div className={
                                            ky === 0 ? "dropdown-menu-header-inner bg-primary" :
                                              ky === 1 ? "dropdown-menu-header-inner bg-danger" :
                                                ky === 2 ? "dropdown-menu-header-inner bg-success" :
                                                  ky === 3 ? "dropdown-menu-header-inner bg-dark" :
                                                    ky === 4 ? "dropdown-menu-header-inner bg-primary" :
                                                      ky === 5 ? "dropdown-menu-header-inner bg-danger" :
                                                        ky === 6 ? "dropdown-menu-header-inner bg-success" : ''
                                          }
                                          >
                                            <div className="menu-header-image"
                                              style={{

                                                backgroundImage:
                                                  ky === 0 ? 'url(' + bg1 + ')' :
                                                    ky === 1 ? 'url(' + bg2 + ')' :
                                                      ky === 2 ? 'url(' + bg3 + ')' :
                                                        ky === 3 ? 'url(' + bg4 + ')' :
                                                          ky === 4 ? 'url(' + bg5 + ')' :
                                                            ky === 5 ? 'url(' + bg6 + ')' :
                                                              ky === 6 ? 'url(' + bg2 + ')' : ''

                                              }}
                                            />
                                            <div style={{ height: '50px', wordWrap: 'break-word' }} className="menu-header-content">
                                              <h5 className="menu-header-title">{val.enterprise_name.length > 40 ? <span>{val.enterprise_name.slice(0, 40)}.. </span> : val.enterprise_name}</h5>
                                              <h6 className="menu-header-subtitle">{val.email}</h6>
                                            </div>
                                          </div>
                                        </div>
                                        <Nav vertical>
                                          <NavItem className="nav-item-header">
                                            Contact Details
                                          </NavItem>
                                          <NavItem>
                                            <NavLink href="#">
                                              <i className="nav-link-icon pe-7s-phone"> </i>

                                              Phone:
                                              <div className="ml-auto ">{val.contact_number}</div>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink href="#">
                                              <i className="nav-link-icon pe-7s-user"> </i>

                                              Person:
                                              <div className="ml-auto ">{val.contact_person}</div>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem className="nav-item-header">
                                            More Details
                                          </NavItem>
                                          <NavItem>
                                            <NavLink href="#">
                                              <i className="nav-link-icon fas pe-7s-study"> </i>


                                              Total Courses:
                                              <div className="ml-auto badge badge-success">{val.total_courses}</div>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink href="#">
                                              <i className="nav-link-icon pe-7s-users"> </i>
                                              Total Students
                                              <div className="ml-auto badge badge-warning">{val.total_students ? val.total_students : 0}</div>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                      </Card>
                                    </div>
                                    // </div> 

                                  )
                                })
                              }
                            </Slider>
                          </Col>

                        </Row>
                      </Container>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              :
              ''
          }

          <Row>
            <Col sm={12} md={12}>
              <Table
                loader={vendorloader}
                actions={action}
                data={data}
                // handleEdit={handleEdit}
                title={"Partner List"}
                columns={columns}
                // DetailsofVendor={VendorviewStudents}
                // DetailsofCourse={ViewCourse}
                subscriptionActive={subscriptionActive}
                Vendordetails={Vendordetails}
                Editvendor={EditVendor}
                Deletevendor={Deletevendor}
                Updatestatus={Updatestatus}
                UpdateSubscriptionStatus={UpdateSubscriptionStatus}
              />
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text={Message ? Message : "Completed successfully"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />
    </>
  );
};

export default Index;
