import React from 'react';
import ScriptTag from 'react-script-tag';

import HomePage from "./HomePage/HomePage"
import Mainhead from "./header"

import './css/animate.css';
import './css/elegant_icon.css';
import "./css/genex-custom.css"
import './css/hover-min.css';
import "./css/style.css"
import "./css/style.css.map"
import "./css/swiper.css"

const LandingPageNew = () => {
	return (
		<div class="background-white">
			<Mainhead />
			{/* <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/jquery.themepunch.tools.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/jquery.themepunch.revolution.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.actions.min.js"}></ScriptTag> */}
			{/* <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.carousel.min.js"}></ScriptTag> */}
			{/* <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.kenburn.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.layeranimation.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.migration.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.navigation.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.parallax.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.slideanims.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/assests/revslider/js/extensions/revolution.extension.video.min.js"}></ScriptTag> */}
			{/* <ScriptTag type="text/javascript" src={process.env.PUBLIC_URL + "/js/slider.js"} /> */}
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/sticky-sidebar.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/YouTubePopUp.jquery.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/imagesloaded.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/wow.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/popper.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/bootstrap.min.js"}></ScriptTag>
			<ScriptTag type="text/javascript" src={process.env.PUBLIC_URL +"/js/js/custom.js"}></ScriptTag>
            <HomePage/>
		</div>
	);
}
export default LandingPageNew;