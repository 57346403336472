import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Row, Col, Input } from 'reactstrap'
// import { Multiselect } from 'react-widgets'
import { createResellerPincode, getAllStates, fillterDistricts, getPincodes ,getPincodesUnderReseller} from '../../../../redux/Actions/Superadmin'
import { useDispatch, useSelector } from 'react-redux'
// import { components } from 'react-select'
import Creatable from "react-select/lib/Creatable";
import { useParams } from 'react-router-dom'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

function Index (props) {

    const {  setPinModel,Reseller_id } = props
    const params = useParams()

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const states = useSelector(state => state.super)
    const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
    const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
    const pincodes = useSelector((state) => state.superadmin.UnusedPincodes ? state.superadmin.UnusedPincodes : []);

    const [PinCodesSelected, setPinCodesSelected] = useState()
    const [LaddaLoader, setLaddaLoader] = useState(false)
    const [PinCodes, setPinCodes] = useState()

    const handleSubmit = () => {
        setLaddaLoader(true)
        let arr = []
        PinCodesSelected.map((area) => {
            arr.push(area.pincode_id)
        })
        console.clear()
        console.log(arr)
        dispatch(createResellerPincode(tokenData.token, params.reseller_id, arr, cb))
    }
    const cb = () => {
        setPinModel(false);
        setLaddaLoader(false);
    }

    console.log("states", pincodes)

    useEffect(() => {
        dispatch(getAllStates(tokenData.token))
        dispatch(getPincodesUnderReseller(tokenData.token,Reseller_id,cb2))

    }, [])

   const cb2 = (data) => {
       setPinCodes(data)
       setPinCodesSelected(data)
   }

   console.log("check this out",PinCodes,PinCodesSelected)

    return (
        <div>
            <Card >
                <CardBody>
                    <CardTitle>Add Pin</CardTitle>
                    <Row form>
                       
                            <Col sm={3} >

                         
                            <label>State</label>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_PINCODES"})}}>
                                <option value={null} >Select</option>
                                {states.map((s) => {
                                    return (
                                        <option value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>

                            </Col>
                            <Col sm={3}>
                            <label>District</label>
                            <Input type='select' onChange={(e) => dispatch(getPincodes(tokenData.token, e.target.value))} >
                                <option value={null} >Select</option>
                                {districts.map((s) => {
                                    return (
                                        <option value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>
                            </Col>
                            <Col sm={5}>
                            <label>Pin</label>
                       
                

                                <Creatable
                                    defaultValue={PinCodes}
                                    // defaultValue={[{grade_id: 75, grade_name: "React", selected: true}]}
                                    //  components={{ Menu }}
                                    // value={SelectedOptions}
                                    isMulti
                                    getOptionLabel={option =>
                                        `${option.pin}-${option.place}`
                                    }
                                    getOptionValue={option =>
                                        `${option.pincode_id}`
                                    }

                                    name="list"
                                    options={pincodes}
                                    // options={[{grade_id: 734, grade_name: "Angular", selected: true},{grade_id: 754, grade_name: "Android", selected: false},{grade_id: 754, grade_name: "Laravel", selected: false}]}
                                    //  isValidNewOption={isValidNewOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => setPinCodesSelected(e)}
                                /> 
                          
                          </Col>
                        {/* <Col sm={10}>{" "}</Col> */}
                        {/* <Col className="pt-3 mr-3" sm={1}><Button disable={LaddaLoader} onClick={handleSubmit} color='primary'>Submit</Button></Col> */}
                        <Col sm={1}><LaddaButton
                            className="mb-2 mr-2 btn btn-primary"
                            loading={LaddaLoader}
                            type="submit"
                            data-style={ZOOM_IN}
                            onClick={handleSubmit}
                        >
                            Submit
              </LaddaButton></Col>

                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default Index