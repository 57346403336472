import React from 'react'
// import './video.css'
// import { Button, Row, Col, Input } from 'reactstrap'
// import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
// import Swal from 'sweetalert2'

// import { deletePlaylistVideos, updatePlaylistVideos } from '../../../redux/Actions/playlist'
const Index = (props) => {

    const { thumbnile, embed, openVideo, data, title, duration, type,processing,camera_tag_url } = props
    const params = useParams()

    // const storeData = useSelector(({ playlist }) => playlist)

    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));
//   console.log('MMMMMIIIIIIDDDDDDDDDDDD',params.category_id)
    
    return (
        <>
            <div  style={{ cursor:'pointer',backgroundSize:'cover',borderRadius:'5px',height:'270px',width:'100%',position:'relative',backgroundImage: `url(${thumbnile})` }}
                onClick={type === "playlist" ? () => openVideo(embed, thumbnile,processing,camera_tag_url) : null}
            >
                {
                    params.category_id || params.vendor_id? 
                    <>
                        <div style={{position:'absolute',top:'15px',left:'20px',borderRadius:'4px',background:'#2f2d2d',color:'white',fontSize:'15px'}}>{title}</div>
                        {
                            embed ? 
                            <div style={{position:'absolute',top:'15px',right:'20px',borderRadius:'4px',background:'#2f2d2d',color:'white',fontSize:'15px'}}>{duration}</div>
                            :null
                        }
                        {thumbnile.includes('default') && <div className="jms-thumb-card-processing1" style={{fontSize:'15px'}}>{"Processing"}</div>}
                    </>
                    :
                    <>
                        <div style={{position:'absolute',top:'15px',left:'20px',borderRadius:'4px',background:'#2f2d2d',color:'white',fontSize:'15px'}}>{title}</div>
                        {
                            embed ? 
                            <div style={{position:'absolute',top:'15px',right:'20px',borderRadius:'4px',background:'#2f2d2d',color:'white',fontSize:'15px'}}>{duration}</div>
                            :null
                        }
                        {thumbnile.includes('default') && <div className="jms-thumb-card-processing1" style={{fontSize:'15px'}}>{"Processing"}</div>}
                    </>
                }
                {/* <div style={{position:'absolute',top:'15px',left:'20px',borderRadius:'4px',background:'#2f2d2d',color:'white'}}>{title}</div>
                <div style={{position:'absolute',top:'15px',right:'20px',borderRadius:'4px',background:'#2f2d2d',color:'white'}}>{duration}</div>
                {thumbnile.includes('default') && <div className="jms-thumb-card-processing1">{"Processing"}</div>} */}

                

            </div >
            {/* <div className="jms-choose-from-playlist-thumb-actions" >
            
                {
                    type === "player" ?
                        <Row>

                            <Col md={12}>
                                <p style={{ fontSize: 10, margin: 0, fontWeight: 500 }}>
                                    {`Video available from ${data.from_time} to ${data.to_time} `}
                                </p>
                            </Col>

                            <Col md={12}>
                                <p style={{ fontSize: 10, margin: 0, fontWeight: 500 }}>
                                    {` between date ${data.from_date} to ${data.to_date}`}
                                </p>
                            </Col>
             
                        </Row>
                        : <Row>

                            <Col md={6}>
                                <Button onClick={() => openEdit(video_id, title)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="info" >Edit</Button>

                            </Col>
                            <Col md={6}>
                                <Button onClick={() => deleteVideo(video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="danger">{storeData.deleteVideoLoader ? 'Deleting...' : 'Delete'}</Button>


                            </Col>
                        </Row>
                }

            </div> */}
        </>
    )
}

export default Index
