import React, { useState, useEffect } from 'react';
import { Row, Col,Card,CardBody,CardHeader } from 'reactstrap'
// import Table from './table';
import { useDispatch, useSelector } from 'react-redux'
import { populateAllVideoClassForDragg, } from '../../../redux/Actions/playlist'
// import { getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
import {deleteOndemandVideoClass}from '../../../redux/Actions/vendor_course'
import { useHistory } from "react-router-dom";
import Modals from '../../common/Modal'
import AssignStudents from './assignStudents'
import Swal from 'sweetalert2'

import { List, arrayMove } from "react-movable";
import EjectIcon from '@material-ui/icons/Eject';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Tooltip from '@material-ui/core/Tooltip';
// import CardHeader from 'reactstrap/lib/CardHeader';
// import CardBody from 'reactstrap/lib/CardBody';
import {ReorderVideoClass} from "../../../redux/Actions/Superadmin"

import LoadingOverlay from 'react-loading-overlay'
import { Loader, } from 'react-loaders';

const tableStyles = {
    background: '#eaebec',
    borderSpacing: 0
  };
  
  const thStyles = {
    borderBottom: '2px solid #ddd',
    padding: '10px',
    background: '#ededed',
    color: '#666',
    textAlign: 'center',
    fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif'
  }
  
  const tdStyles = {
    borderBottom: '1px solid #ddd',
    color: '#666',
    fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
    padding: '20px',
    textAlign: 'center',
    width: '150px'
  }

  const tdStyles2 = {
    borderBottom: '1px solid #ddd',
    color: '#666',
    fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
    padding: '20px',
    textAlign: 'center',
    width: '150px',
    cursor:'default'
  }

// const columns = [
//     { title: '#', field: 'tableData.id' },
//     {
//         title: 'Class ', field: 'video_class_name',
//     },
//     { title: ' Grade', field: 'grade_name' },


// ]


const VideoClass = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const videoStore = useSelector(({ playlist }) => playlist)

    
    const {course_id} = props
    // console.log("videoo list", videoStore);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dragLoader = useSelector((state) =>state.playlist.dragLoader ? state.playlist.dragLoader : false);

    // const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    // const [items, setItems] = useState(["Item 1", "Item 2", "Item 3"]);
    const [items, setItems] = useState([]);
    // const [itemsComapre, setItemsCompare] = useState([]);
    const [chk, setChk] = useState(false);

    useEffect(() => {
        dispatch(populateAllVideoClassForDragg(tokenData.token,course_id,'',setClasses))
    }, [props.control])

    useEffect(() => {
        if(chk){
            dispatch(ReorderVideoClass(tokenData.token,items,course_id,'',cb))
        }
    }, [items])
    const setClasses=(val)=>{
        setItems(val)
        // setItemsCompare(val)
    }

    const onVideopress = (classId) => {
        // console.log("videooooossss", classId);
        history.push(`/dashboard/videolist/${classId}`)
    }
    const [openAssignModal, setAssignModal] = useState(false)
    const [classID, setClassID] = useState()
    // const hadleOpenStudentModal = (classId, gradeId) => {
    //     if (tokenData.enterpriseUser) {
    //         dispatch(getTeacherAssignedGroups(tokenData.token, classId, "",course_id, "video"))

    //     } else {
    //         dispatch(populateVideoClassStudents(tokenData.token, classId, gradeId))

    //     }
    //     setClassID(classId)

    //     setAssignModal(true)

    // }
    const hadleCloseStudentModal = () => {

        setAssignModal(false)

    }
    const editClass = (val) => {
        history.push(`/dashboard/editCourse/${course_id}/editVideoClass/${val.video_class_id}`)
    }

    const handleDelete = (video_id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
        
        }).then((result) => {
            if (result.value) {
                
                dispatch(deleteOndemandVideoClass(tokenData.token,video_id,cb))
        
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
     
    }

    // const changeStatus = (class_id, status, teacher_id) => {
    //     let activeClass = status
    //     if (activeClass === 1) {
    //         activeClass = 0
    //     } else {
    //         activeClass = 1
    //     }


    //     dispatch(changeStatusOfVideoClass(tokenData.token, class_id, activeClass, teacher_id, cb))
    // }
    const cb = () => {
        setChk(false)
        dispatch(populateAllVideoClassForDragg(tokenData.token,course_id,'',setClasses))

    }
    // console.log("tStore.specificGroup", tStore.specificGroup);
    // console.log({ tStore });

 
    return (
        <>
            {/* <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        role={tokenData.role}
                        title={'Video Class'}
                        columns={columns}
                        actions={"video_class"}
                        editClass={editClass}
                        data={videoStore.allVideoClasses}
                        loader={videoStore.allVideoClassesLoader}
                        handleDelete={handleDelete}
                        onVideopress={onVideopress}
                        hadleOpenStudentModal={hadleOpenStudentModal}
                        changeStatus={changeStatus}
                    />
                </Col>

            </Row> */}
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader>Video Class</CardHeader>
                        <CardBody>
                        <LoadingOverlay tag="div" active={dragLoader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                        
                            <List
                                values={items}
                                onChange={({ oldIndex, newIndex }) =>{
                                    setChk(true)

                                // let cmp=[...itemsComapre]
                                // let position
                                // console.log('old data',cmp[newIndex].video_class_id)
                                // console.log('old data2',cmp[oldIndex].video_class_id)

                                // if(oldIndex > newIndex){
                                //     position='up'
                                // }
                                // else{
                                //     position='down'

                                // }
                                setItems(arrayMove(items, oldIndex, newIndex))  
                                // setItemsCompare(arrayMove(items, oldIndex, newIndex))  
                                // setTimeout(() => {
                                    // let a=items.findIndex((val)=>val.video_class_id===cmp[oldIndex].video_class_id)
                                // dispatch(ReorderVideoClass(tokenData.token,cmp[newIndex].video_class_id,position,cmp[oldIndex].video_class_id,items,cb))
                                // }, 500);
                                }
                            }
                                renderList={({ children, props, isDragged }) => (
                                    <table
                                    style={{
                                        ...tableStyles,
                                        cursor: isDragged ? 'grabbing' : undefined
                                    }}
                                    >
                                    <thead>
                                        <tr>
                                        <th style={thStyles}>{}</th>
                                        <th style={thStyles}>Class</th>
                                        <th style={thStyles}>Grade</th>
                                        <th style={thStyles}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody {...props}>{children}</tbody>
                                    </table>
                                )}
                                renderItem={({ value, props, isDragged, isSelected }) => {
                                    const row = (
                                    <tr
                                        {...props}
                                        style={{
                                        ...props.style,
                                        cursor: isDragged ? 'grabbing' : 'grab',
                                        backgroundColor: isDragged || isSelected ? '#EEE' : '#fafafa'
                                        }}
                                    >
                                        <td style={tdStyles} data-movable-handle>
                                            <Tooltip arrow title='Click and Drag'>
                                                <EjectIcon /> 
                                            </Tooltip>
                                        </td>
                                        <td style={tdStyles2} >{value.video_class_name}</td>
                                        <td style={tdStyles2}>{value.grade_name}</td>
                                        <td style={tdStyles2}>

                                            <Tooltip arrow title='Edit'>
                                                <EditIcon style={{cursor:'pointer'}} onClick={()=>{editClass(value)}} />
                                            </Tooltip>

                                            <Tooltip arrow title='Delete'>
                                                <DeleteIcon style={{color:'red',cursor:'pointer',margin:'0px 8px 0px 8px'}} onClick={()=>{handleDelete(value.video_class_id)}}/> 
                                            </Tooltip>
                                            
                                            <Tooltip arrow title='View Videos'>
                                                <PlayCircleFilledIcon style={{cursor:'pointer',color: 'rgb(41, 183, 155)' }} onClick={()=>{onVideopress(value.video_class_id)}}  />
                                            </Tooltip>

                                        </td>
                                    </tr>
                                    );
                                    return isDragged ? (
                                    <table style={{ ...props.style, borderSpacing: 0 }}>
                                        <tbody>{row}</tbody>
                                    </table>
                                    ) : (
                                    row
                                    );
                                }}
                            />
                            </LoadingOverlay>
                        </CardBody>
                    </Card>
                
                </Col>
            </Row>
            <Modals
                open={openAssignModal}
                handleClose={hadleCloseStudentModal}
                title={`Assign students`}
            >
                <AssignStudents
                    loader={videoStore.specificStudentLoader}
                    classId={classID}
                    // data={tokenData.enterpriseUser ? tStore.specificGroup : videoStore.specificStudent}

                    data={videoStore.specificStudent}
                    handleCloseStudentModal={hadleCloseStudentModal}
                    timeData={"timeData"}
                />
            </Modals>
        </>
    )
}

export default VideoClass;