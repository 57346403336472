import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import rootreducer from './rootReducer'
// import { jwt } from './Middleware'
// const store = createStore(rootreducer, applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// export default store
import { createLogger } from "redux-logger"

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)

const store = createStoreWithMiddleware(rootreducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store

