import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import {
    Row, Col,
    Card, CardBody, CardTitle,
    CardHeader, CardFooter, Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    CustomInput,
    FormGroup, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AddStudentToClass = (props) => {


    return (


        <Card className="main-card mb-3">
            <CardHeader>
                <Col md={6} sm={6}>
                    Day
                    </Col>
                <Col md={3} sm={3}>
                    To Time
                    </Col>
                <Col md={3} sm={3}>
                    To Time
                    </Col>
            </CardHeader>
            <CardBody>
                <div className="scroll-area-sm">
                    <PerfectScrollbar>
                        {props.data ? props.data.map(dt => (
                            <Row key={dt.user_id}>
                                <Col md={6} sm={6} style={{ paddingLeft: '30px' }}>
                                    {`${dt.day} `}
                                </Col>
                                <Col md={3} sm={3} style={{ paddingLeft: '20px' }}>
                                    {`${dt.time_from} `}
                                </Col>
                                <Col md={3} sm={3} style={{ paddingLeft: '20px' }}>
                                    {`${dt.time_to}     `}
                                </Col>

                            </Row>

                        )) : null}

                    </PerfectScrollbar>
                </div>
            </CardBody>

        </Card>

    )
}

export default AddStudentToClass
