import axios from 'axios'
import { development } from '../../config.json'
// import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'

export function setSubscriptionData(name, value) {
    return {
        type: 'SET_SUBSCRIPTION_DATA',
        payload: { [name]: value },
    }
}


export const createSubscription = (token, formdata,cb,) => (dispatch) => {
  
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
      },
      
      // onUploadProgress: (progressEvent) => {
      //   const { loaded, total } = progressEvent;
      //   let percent = Math.floor((loaded * 100) / total);
      //   dispatch({ type: "SET_SUBSCRIPTION_DATA", payload: {percentage:percent} });
  
      // },
    };
    dispatch({ type: "SUBSCRIPTION_ALL_REQUEST",payload:{create_loader:true} });
    axios
      .post(`${development.baseUrl}createSubscription`, formdata,options)
      .then((res) => {
        if (res.data.result) {
          dispatch({
            type: "SUBSCRIPTION_ALL_SUCCESS",payload: {create_loader:false}});
            cb()
        } 
        else {
          let percent=''
          dispatch({ type: "SUBSCRIPTION_ALL_FAILED",payload: {create_loader:false} });
          dispatch({ type: "SET_SUBSCRIPTION_DATA", payload: {percentage:percent} });
  
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.data.message,
          });
        }
      })
      .catch((err) => {
        let percent=''
        dispatch({ type: "SUBSCRIPTION_ALL_FAILED",payload: {create_loader:false} });
        dispatch({ type: "SET_SUBSCRIPTION_DATA", payload: {percentage:percent} });
        if (err) {
          if (err.response) {
            //   if (err.response.status === 422) {
            //       var val = Object.values(err.response.data.errors)
            //       cb3(val)
            //   }
            if (err.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: 'Token Expired',
                    // text: 'Token Expired'
                })
                window.location.reload()
            }
          }
      }
      else {
          Swal.fire({
              icon: 'error',
              title: 'Something Went Wrong',
              // text: 'You are logged in to another System'
          })
      }
  
  
      });
  };

  export const editSubscription = (token, subscription_id,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
      id: subscription_id
    }
    // debugger
    dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST', payload: { populate_sub: true } })
    axios.post(`${development.baseUrl}editSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', payload: {populate_sub: false } })
                let data=res.data ? res.data.subscription_details ? res.data.subscription_details[0] :[]:[]
                cb(data)
            }
            else{
              Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                text: "Try Again"
            })
            }
        })
        .catch(err => {
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: { populate_sub: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const updateSubscription = (token, formdata,cb,) => (dispatch) => {
  
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "content-type": "multipart/form-data",
    },
    
    // onUploadProgress: (progressEvent) => {
    //   const { loaded, total } = progressEvent;
    //   let percent = Math.floor((loaded * 100) / total);
    //   dispatch({ type: "SET_SUBSCRIPTION_DATA", payload: {percentage:percent} });

    // },
  };
  dispatch({ type: "SUBSCRIPTION_ALL_REQUEST",payload:{create_loader:true} });
  axios
    .post(`${development.baseUrl}updateSubscription`, formdata,options)
    .then((res) => {
      if (res.data.result) {
        dispatch({
          type: "SUBSCRIPTION_ALL_SUCCESS",payload: {create_loader:false}});
          cb()
      } 
      else {
        let percent=''
        dispatch({ type: "SUBSCRIPTION_ALL_FAILED",payload: {create_loader:false} });
        dispatch({ type: "SET_SUBSCRIPTION_DATA", payload: {percentage:percent} });

        Swal.fire({
          icon: "error",
          title: "Error",
          text: res.data.message,
        });
      }
    })
    .catch((err) => {
      let percent=''
      dispatch({ type: "SUBSCRIPTION_ALL_FAILED",payload: {create_loader:false} });
      dispatch({ type: "SET_SUBSCRIPTION_DATA", payload: {percentage:percent} });
      if (err) {
        if (err.response) {
          //   if (err.response.status === 422) {
          //       var val = Object.values(err.response.data.errors)
          //       cb3(val)
          //   }
          if (err.response.status === 401) {
            Swal.fire({
                icon: 'error',
                title: 'Token Expired',
                // text: 'Token Expired'
            })
            window.location.reload()
          }
        }
    }
    else {
        Swal.fire({
            icon: 'error',
            title: 'Something Went Wrong',
            // text: 'You are logged in to another System'
        })
    }


    });
};

export const deleteSubscription = (token, subscription_id, cb) => (dispatch) => {
  const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
  }
  let data = { id: subscription_id }
  // debugger
  dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST', payload: {subloader:true } })
  axios.post(`${development.baseUrl}deleteSubscription`, data, { headers: head })
      .then(res => {
          if (res.data.result) {
              dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', payload: {subloader:false } })
              cb(res.data.message)//to open success popup
          }
          else{
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {subloader:false } })
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Delete action failed'
            })
          }
      })
      .catch(err => {
          dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {subloader:false } })
          if (err) {
              if (err.response) {
                  if (err.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Token Expired',
                        // text: 'Token Expired'
                    })
                    window.location.reload()
                  }
              }
          }
          else {
              Swal.fire({
                  icon: 'error',
                  title: 'Something Went Wrong',
                  // text: 'You are logged in to another System'
              })
          }
      })
}

export const listAllSubscriptions = (token,page,search,enterprise_id) => (dispatch) => {
  const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
  }
  dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST', payload: {subloader:true } })
  axios.get(`${development.baseUrl}listAllVendorSubscriptionPaginated?page=${page}&search_term=${search}&enterprise_id=${enterprise_id}`,{ headers: head })    
  .then(res => {
          if (res.data.result) {
              dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', 
              payload: { 
                  Subscription_Details:res.data,
                  subloader:false,
                  Subscription_Array:res.data.subscriptions.data,
                  Subscription_first_page_url:res.data.subscriptions.prev_page_url,
                  Subscription_next_page_url:res.data.subscriptions.next_page_url,
                  from_sub:res.data.subscriptions.from,
                  to_sub:res.data.subscriptions.to
              } })
          }  
          else{
            Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                text: res.data.message ? res.data.message:'failed to load'
            })
          } 
      })
      .catch(err => {
          dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {subloader:false} })
          if (err) {
              if (err.response) {
                  if (err.response) {
                      if (err.response.status === 401) {
                          Swal.fire({
                              icon: 'error',
                              title: 'Token Expired',
                            //   text: 'Token Expired'
                          })
                          window.location.reload()
                    }
                  }
              }
              else {
                  Swal.fire({
                      icon: 'error',
                      title: 'Something Went Wrong',
                      // text: 'You are logged in to another System'
                  })
              }
          }
      })
}


export const getUnAssignedStudents = (token,page,search) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST', payload: {unassigned_loader:true } })
    axios.get(`${development.baseUrl}getUnAssignedStudents?page=${page}&search_term=${search}`,{ headers: head })    
    .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', 
                payload: { 
                    unassigned_loader:false,
                    unassigned_Array:res.data.students.data,
                    unassigned_first_page_url:res.data.students.prev_page_url,
                    unassigned_next_page_url:res.data.students.next_page_url,
                    from_unassigned:res.data.students.from,
                    to_unassigned:res.data.students.to
                } })
            }  
            else{
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {unassigned_loader:false} })
              Swal.fire({
                  icon: 'error',
                  title: 'Something Went Wrong',
                  text: res.data.message ? res.data.message:'failed to load'
              })
            } 
        })
        .catch(err => {
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {unassigned_loader:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
  }


  //////////////////subscription details////////////////

  export const viewSubscriptionDetails  = (token, subscription_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { subscription_id: subscription_id }
    dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST',payload:{subscription_details_loader:true} })
    axios.post(`${development.baseUrl}viewSubscriptionDetails `, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', payload: { 
                    subscription_details_loader:false,
                    Subscription_Data: res.data.subscription_details ? res.data.subscription_details:[], 
                    Subscription_Live: res.data.subscription_details ? res.data.subscription_details ? res.data.subscription_details.live_classes:[]:[], 
                    Subscription_Video: res.data.subscription_details ? res.data.subscription_details ? res.data.subscription_details.ondemand_classes:[]:[], 

                } })
            }
            else{
                dispatch({ type: 'SUBSCRIPTION_ALL_FAILED',payload:{subscription_details_loader:false} })
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: 'Failed to load data'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED',payload:{subscription_details_loader:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

/////////////renewal subscription/////////////

export const updateRenewSubscription = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST', payload: {pay_loader:true,renw_load:true,exp_load:true} })
    axios.post(`${development.baseUrl}updateRenewSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', payload: {pay_loader:false,renw_load:false,exp_load:false} })
                cb(res.data)
            } else {
                dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {pay_loader:false,renw_load:false,exp_load:false} })
                cb(res.data)

            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {pay_loader:false,renw_load:false,exp_load:false} })
            if (err) {
                if (err.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        text: 'Order Failed'
                    })
                }

            }

        }
        )
}
export const renewSubscription = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

   // dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST', payload: {pay_loader:true,renw_load:true,exp_load:true} })
    axios.post(`${development.baseUrl}renewSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
              //  dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', payload: {pay_loader:false,renw_load:false,exp_load:false} })
                cb(res.data)
            } else {
                //dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {pay_loader:false,renw_load:false,exp_load:false} })
               // cb(res.data)
                Swal.fire('Error','Something Went Wrong','error')
            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED', payload: {pay_loader:false,renw_load:false,exp_load:false} })
            if (err) {
                if (err.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        text: 'Order Failed'
                    })
                }

            }

        }
        )
}


////////////////////
export const renewalListForSubscription  = (token, order_id,page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { order_id: order_id,page:page }
    dispatch({ type: 'SUBSCRIPTION_ALL_REQUEST',payload:{renewalList_details_loader:true} })
    axios.post(`${development.baseUrl}renewalListForSubscription `, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SUBSCRIPTION_ALL_SUCCESS', payload: { 
                    renewalList_Data: res.data.payments.data ? res.data.payments.data:[], 
                    renewalList_details_loader:false,
                    renewalList_prevpage: res.data.payments ? res.data.payments.prev_page_url :'', 
                    renewalList_nextpage: res.data.payments ? res.data.payments.next_page_url :'', 
                    renewalList_frome: res.data.payments.from ? res.data.payments.from :'', 
                    renewalList_to: res.data.payments.to ? res.data.payments.to :'', 

                } })
            }
            else{
                dispatch({ type: 'SUBSCRIPTION_ALL_FAILED',payload:{renewalList_details_loader:false} })
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: 'Failed to load data'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'SUBSCRIPTION_ALL_FAILED',payload:{renewalList_details_loader:false} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}