import React,{useState} from 'react'
import DataTable from '../../common/DataTable'
import Model2 from "../../common/Modal/index"
import SweetAlert from 'sweetalert-react';
import {
    Row,
    Col,
    // Badge,
    // Card,
    // CardBody,
    // Button,
    // CardHeader,
    // Container,
    Label,
    Input,
    FormFeedback
  } from "reactstrap";
  // import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
  import { payoffCommision,correctCommision } from "../../../redux/Actions/Superadmin";
  import { requiredValidator,} from '../../../utilities/Validator'
  import { useDispatch,useSelector } from "react-redux";
  import LaddaButton, { ZOOM_IN } from 'react-ladda';

const Table = (props) => {
    // console.log("table=======data", props.data)
    const dispatch = useDispatch();
    const [resller_data,setReseller]=useState({id:'',open:false})
    const [inputField,SetInput]=useState({id:'',amount:'',remarks:''})
    const [errors, setErrors] = useState({})
      const tokenData = JSON.parse(localStorage.getItem("authDetails"));

      const payoffloader = useSelector((state) =>
    state.superadmin.payoffloader ? state.superadmin.payoffloader : false
  )

      const [successModal, setSuccessModal] = useState(false);
      const [Message, setMessage] = useState(null)

      const handleOpenSuccessModal = (message) => {
       // dispatch(getAllReseller(tokenData.token));
        setMessage(message)
        setReseller({id:'',open:false})
        setSuccessModal(true)
      }
      const handleCloseSuccesstModal = () => {
        setMessage(null)
        SetInput({id:'',amount:'',remarks:''})
        setSuccessModal(false)
      }


    const CreditDebit=(val)=>{
        setErrors({})
        setReseller({id:val.user_id,open:true})
    }
    const CloseModel=()=>{
      setReseller({id:'',open:false})
    }
    
    const Handlechange=(event)=>{
      SetInput({...inputField,[event.target.name]:event.target.value})
    }
    
    const submitPayoff=()=>{
      const err = {
        id: requiredValidator(inputField.id),
        amount: requiredValidator(inputField.amount),
        remarks: requiredValidator(inputField.remarks),
    
      };
      if (
        !err.id &&
        !err.amount &&
        !err.remarks
      ) {
        setErrors(err);
        if(inputField.id==='debit'){
          dispatch(payoffCommision(tokenData.token,resller_data.id,inputField.amount,inputField.remarks,handleOpenSuccessModal))
        }
        else{
          dispatch(correctCommision(tokenData.token,resller_data.id,inputField.amount,inputField.remarks,handleOpenSuccessModal))
        }
      }
      else{
        setErrors(err)
      }
    }

    return (
        <>
            <DataTable
                loader={props.loader}
                // hadleOpenModal={(id) => props.hadleOpenModal(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns} handleEdit
                // handleEdit={(class_id, teacher_id) => props.handleEdit(class_id, teacher_id)}
                // DetialsofVendor={(id)=>{props.DetailsofVendor(id)}}
                // DetailsofCourse={(id)=>{props.DetailsofCourse(id)}}
                Vendorstudentdetails={(id)=>{props.Vendorstudentdetails(id)}}
                DetailsofVendor={(id)=>{props.Vendordetails(id)}}
                Editvendor={(id)=>{props.Editvendor(id)}}
                Deletevendor={(id)=>{props.Deletevendor(id)}}
                Updatestatus={(id)=>{props.Updatestatus(id)}}
                subscriptionActive={(id)=>{props.subscriptionActive(id)}}
                DetialsofVendorCourse={(id)=>{props.Vendorcourse(id)}}
                data={props.data}
                title={props.title}
                CreditDebit={CreditDebit}
                UpdateSubscriptionStatus={(id)=>{props.UpdateSubscriptionStatus(id)}}
            />
              <Model2
        open={resller_data.open ? resller_data.open:false}
        handleClose={CloseModel}
        title='New Payments'
        >
          <Row>
            <Col xs={12}>
              <Input 
                type='select'  
                style={{ width: "100%" }}
                onChange={Handlechange}
                name='id'
                value={inputField.id}
                invalid={errors.id}

                >
                  <option value=''>Select Type</option>
                  <option value='credit'>Credit</option>
                  <option value='debit'>Debit</option>
              </Input>
              <FormFeedback>{errors.id}</FormFeedback>

            </Col>
            <Col xs={12} style={{marginTop:'10px'}}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span>
                  Amount
                </Label>
              <Input 
                 min={0}
                 type="number"
                 style={{ width: "100%" }}
                 name="amount"
                 value={inputField.amount}
                 onChange={Handlechange}
                 invalid={errors.amount}
              />
              <FormFeedback>{errors.amount}</FormFeedback>
            </Col>

            <Col xs={12} style={{marginTop:'10px'}}>
                <Label for="exampleNameF">
                <span className="text-danger">*</span>
                  Remarks
                </Label>
              <Input 
                 type="textarea"
                 rows={3}
                 style={{ width: "100%" }}
                 name="remarks"
                 value={inputField.remarks}
                 onChange={Handlechange}
                 invalid={errors.remarks}
              />
              <FormFeedback>{errors.remarks}</FormFeedback>

            </Col>

            <Col xs={12} style={{marginTop:'5px'}}>
                <LaddaButton className=" btn btn-primary"
                  loading={false}
                  type="button"
                  onClick={() => submitPayoff()}
                  loading={payoffloader}
                  data-style={ZOOM_IN}
                >
                  Submit
                </LaddaButton>
            </Col>
          </Row>
        </Model2>
        <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text= {Message?Message:"Successfully completed"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />
        </>
    )
}

export default Table
