import React, { useEffect, useState, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
    faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Container from "reactstrap/lib/Container";
import {
    Row,
    Col,
    Card, CardHeader,

} from "reactstrap";


import { useParams } from 'react-router-dom'
import { setReqData } from '../../../redux/Actions/Superadmin'
import { getInvoice } from "../../../redux/Actions/Student"
import { getAssessmentDetails } from "../../../redux/Actions/genexhome"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Model from "../../SuperadminComponents/Vendor/Model"
import spinner from '.../../assets/animated/Spinner.gif'
import ReactHtmlParser from 'react-html-parser';

import Swal from 'sweetalert2'


import VideoCard from '../../SuperadminComponents/Vendor/Vendordetails/Videocard'
import Index from "../../SuperadminComponents/Vendor/Vendordetails/Coursefull/index"
import Tooltip from '@material-ui/core/Tooltip';

import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SweetAlert from 'sweetalert-react';
import CircularProgress from "@material-ui/core/CircularProgress";
import vendordummy from '../../LandingPageNew/images/vendordummy.png'
import "react-datepicker/dist/react-datepicker.css";
import coursedummy from "../../LandingPageNew/images/coursedummy.png"
import previewdummy from "../../LandingPageNew/images/previewdummy.png"

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Chip from '@material-ui/core/Chip';

import { getSingleCourseDetails, setHomeData } from "../../../redux/Actions/genexhome"
import ReactPlayer from 'react-player';
import { development } from '../../../../src/config.json';



function CouseFulldetails() {

    const dispatch = useDispatch();
    const history = useHistory();

    const params = useParams()
    const [nowPlaying, setNowPlaying] = useState({ open: false, iframe: "<iframe></iframe>", camera_tag_url: '' })
    const [successModal, setSuccessModal] = useState(false);

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const role = tokenData.role

    const check_api_call = useSelector((state) => state.genexhome.check_api_call ? state.genexhome.check_api_call : true);

    const course_data_loading = useSelector((state) => state.genexhome.course_data_loading ? state.genexhome.course_data_loading : false);

    const SingleCourseDetail = useSelector((state) => state.genexhome.Single_Course_Details ? state.genexhome.Single_Course_Details : '');
    const imageview = useSelector((state) => state.superadmin.imageview ? state.superadmin.imageview : false);
    const video = SingleCourseDetail ? SingleCourseDetail.preview_video ? SingleCourseDetail.preview_video : [] : []
    const frstvideo = video ? video[0] : ''
    const thumb = frstvideo ? frstvideo.thumbnails : false
    // const thumblink = thumb[1] ? thumb[1].link : previewdummy
    const thumblink = SingleCourseDetail.camera_tag_thumbnail_path ? SingleCourseDetail.camera_tag_thumbnail_path : thumb[1] ? thumb[1].link : previewdummy

    const dur = frstvideo ? frstvideo.video_duration : 'no'
    const course_tags = useSelector((state) => state.genexhome.course_tags ? state.genexhome.course_tags : []);

    const liveclass_length = SingleCourseDetail ? SingleCourseDetail.live_classes ? SingleCourseDetail.live_classes.length : 0 : 0
    const videoclass_length = SingleCourseDetail ? SingleCourseDetail.ondemand_classes ? SingleCourseDetail.ondemand_classes.length : 0 : 0

    const invoiceloader = useSelector((state) => state.student.invoiceloader ? state.student.invoiceloader : false);

    const user_details = SingleCourseDetail ? SingleCourseDetail.user_details ? SingleCourseDetail.user_details : {} : [];
    const CourseAssesmentDetails = useSelector((state) => state.genexhome.CourseAssesmentDetails ? state.genexhome.CourseAssesmentDetails : {});

    useEffect(() => {
        if (check_api_call) {
            dispatch(getSingleCourseDetails(params.token, params.course_id, cb))
        }
        else {
            dispatch(setHomeData('check_api_call', true))
        }
    }, [])

    const cb = (val) => {
        if (!val) {
            history.push('/')
        }
    }

    const openVideo = async (player, thumbnile, processing, camera_tag_url) => {
        if (processing) {
            Swal.fire({
                icon: 'warning',
                title: 'Processing',
                // text: "Video on process"

            })
        } else {
            dispatch(setReqData('prevent_idle_timeout', true))
            setNowPlaying({ open: true, iframe: player, camera_tag_url: camera_tag_url })

        }
    }


    const DownloadInvoice = () => {
        dispatch(getInvoice(tokenData.token, params.order_id, dwnload))
    }
    const dwnload = (val) => {
        if (val) {
            window.open(val, "_blank")
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Download Failed',
                text: "Can't Download this Invoice"
            })
        }
    }
    const ClosePreview = () => {
        dispatch(setReqData('prevent_idle_timeout', false))
        setNowPlaying({ open: false, iframe: '',camera_tag_url:'' })
    }

    const Viewimage = () => {
        dispatch(setReqData('imageview', true))
    }
    const CloseImage = () => {
        dispatch(setReqData('imageview', false))
    }


    const leftmargin = { marginTop: '15px' }
    const valstyle = { fontSize: '16px', marginTop: '15px' }
    const requiredvalstyle = { fontSize: '16px', marginTop: '15px', color: 'red' }


    const TagLabelCheck = () => {
        if (course_tags.length > 0) {
            return (
                <Col xs={12} className="assign-details-card-div-head">
                    <span style={leftmargin}>Course Category Tags</span>
                </Col>
            )
        }
        else {
            return null
        }
    }
    // take assessment //
    useEffect(() => {
        if (tokenData.role === 'student' && user_details.skl_user_id !== undefined) {
          dispatch(getAssessmentDetails(params.course_id,user_details.skl_user_id))
        }
      }, [params.course_id,user_details.skl_user_id])
    
      const redirecttoQuiz = ()=>{
        let Assessment = Object.keys(CourseAssesmentDetails).length > 0 ? CourseAssesmentDetails:{};   
        let RedirectURL = window.location.href;
        if((((SingleCourseDetail.ondemand_classes.reduce((accu, val) => accu + val.percentage, 0)) / (SingleCourseDetail.ondemand_classes.length * 100)) * 100)===100  && Object.keys(CourseAssesmentDetails).length > 0){
            let obj = {'Quiz_Id':Assessment.Quiz_Id,"CANUID":user_details.skl_user_id,"IsRetake":Assessment.IsRetake,"RedirectURL":RedirectURL}
            obj = btoa(JSON.stringify(obj))
            let URL =  development.assessmentUrl +'?'+ obj;
            let a = document.createElement('a');
            a.href = URL;
            a.click();
        }
      
      }

    return (
        <Fragment>
            {
                course_data_loading && SingleCourseDetail ?
                    <>
                        <Card style={{ padding: '30px' }}>
                            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                <Skeleton height={30} count={5} />
                            </SkeletonTheme>
                        </Card>
                        <Card style={{ padding: '30px', marginTop: '25px' }}>
                            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

                                <Skeleton height={30} count={4} />
                            </SkeletonTheme>
                        </Card>
                    </>
                    :

                    <div>

                        <Container>

                            <Row>
                                <Col sm={12} md={12} >
                                    <Breadcrumb style={{ width: '100%' }}>
                                        <BreadcrumbItem>
                                            <Link to="/dashboard">
                                                <FontAwesomeIcon icon={faHome} />
                                            </Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link to="/dashboard/myCourse">My Courses</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>Course Details</BreadcrumbItem>
                                    </Breadcrumb>
                                </Col>
                            </Row>




                            <Row>
                                <Col sm={12} md={12}>

                                    <div
                                        className="app-page-title bg-plum-plate"
                                        style={{
                                            margin: "0px 0px 15px 0px",
                                            borderRadius: "6px",
                                            // backgroundColor: "rgb(0, 69, 139)",
                                            height: '160px',
                                            color: "white",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >


                                        <div style={{ paddingRight: "20px" }} >
                                            <img style={{ border: "3px white solid", borderRadius: "4px" }} alt={"vendor_logo"} width="164px" height="112px" src={SingleCourseDetail.vendor_logo ? SingleCourseDetail.vendor_logo : vendordummy}></img>
                                        </div>

                                        <div>
                                            <span style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: '18px' }}>
                                                {SingleCourseDetail.course_name}
                                            </span>{" "}
                                            <br />


                                            <span style={{ textTransform: "uppercase", fontWeight: 'bold', fontSize: '15px', color: "black" }}>
                                                {SingleCourseDetail.vendor_name}
                                            </span>{" "}
                                            <br />

                                            <span style={{ fontSize: '15px', fontWeight: 'bold' }}> {SingleCourseDetail.course_fee}</span>

                                            <br />



                                        </div>
                                        {
                                            role === 'student' ?
                                                <div className="page-title-actions">
                                                    <span>Order Id: </span> <span style={{ fontSize: '20px' }}># {params.order_id}</span>
                                                    <span style={{ cursor: 'pointer' }}>
                                                        {
                                                            invoiceloader ?
                                                                <CircularProgress color="secondary" size={20} />
                                                                :
                                                                <Tooltip title='Download Invoice'>
                                                                    <GetAppOutlinedIcon onClick={DownloadInvoice} />
                                                                </Tooltip>
                                                        }

                                                    </span>
                                                </div> : ''
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="7">
                                    <div style={{ backgroundColor: '#ffffff', minHeight: '580px', border: '1px solid #d1d7dc', borderRadius: '4px', padding: '15px 0px 20px 0px', boxSizing: 'border-box', wordWrap: 'break-word', paddingLeft: '10px' }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }} >
                                            <span style={{ color: '#1c1d1f', fontWeight: '600', letterSpacing: '-.02rem', fontSize: '1.4rem' }}>Course Details
                                            </span>

                                        </div>

                                        <hr style={{ marginRight: '10px' }} />

                                        <Row>


                                            <Col md={5} className="assign-details-card-div-head" >
                                                <span style={leftmargin}>Course Name</span>
                                            </Col>
                                            <Col md={7} className="assign-details-card-div">
                                                <span style={valstyle}>{SingleCourseDetail.course_name}</span>
                                            </Col>
                                            <Col md={5} className="assign-details-card-div-head" >
                                                <span style={leftmargin}>Product Code</span>
                                            </Col>
                                            <Col md={7} className="assign-details-card-div">
                                                <span style={valstyle}>{SingleCourseDetail.product_code}</span>
                                            </Col>


                                            <Col md={5} className="assign-details-card-div-head">
                                                <span style={leftmargin}>Subject</span>
                                            </Col>
                                            <Col md={7} className="assign-details-card-div">
                                                <span style={valstyle}>{SingleCourseDetail.course_subject_name}</span>
                                            </Col>

                                            <Col md={5} className="assign-details-card-div-head">
                                                <span style={leftmargin}>Grade</span>
                                            </Col>
                                            <Col md={7} className="assign-details-card-div">
                                                <span style={valstyle}>{SingleCourseDetail.grade_name}</span>
                                            </Col>

                                            <Col md={5} className="assign-details-card-div-head">
                                                <span style={leftmargin}>Course Start Date</span>
                                            </Col>
                                            <Col md={7} className="assign-details-card-div">
                                                <span style={valstyle}>{SingleCourseDetail.course_start_date}</span>
                                            </Col>

                                            <Col md={5} className="assign-details-card-div-head">
                                                <span style={leftmargin}>Course End Date</span>
                                            </Col>
                                            <Col md={7} className="assign-details-card-div">
                                                <span style={valstyle}>{SingleCourseDetail.course_end_date}</span>
                                            </Col>

                                            {
                                                SingleCourseDetail.live_class_enabled ?
                                                    <>
                                                        <Col md={5} className="assign-details-card-div-head">
                                                            <span style={leftmargin}>Class Type</span>
                                                        </Col>
                                                        <Col md={7} className="assign-details-card-div">
                                                            <span style={valstyle}>{SingleCourseDetail.live_class_type}</span>
                                                        </Col>
                                                        <Col md={5} className="assign-details-card-div-head">
                                                            <span style={leftmargin}>Admission Start Date</span>
                                                        </Col>
                                                        <Col md={7} className="assign-details-card-div">
                                                            <span style={valstyle}>{SingleCourseDetail.enrollment_start_date}</span>
                                                        </Col>
                                                        <Col md={5} className="assign-details-card-div-head">
                                                            <span style={leftmargin}>Admission End Date</span>

                                                        </Col>
                                                        <Col md={7} className="assign-details-card-div">
                                                            {
                                                                SingleCourseDetail.enrollment_end_date ?
                                                                    <span style={valstyle}>{SingleCourseDetail.enrollment_end_date}</span>
                                                                    :
                                                                    <span style={requiredvalstyle}>*Required</span>

                                                            }

                                                        </Col>

                                                        <Col md={5} className="assign-details-card-div-head">
                                                            <span style={leftmargin}>Live Class</span>
                                                        </Col>
                                                        <Col md={7} className="assign-details-card-div">
                                                            {
                                                                SingleCourseDetail.total_live_calsses ?
                                                                    <span style={valstyle}>{SingleCourseDetail.total_live_calsses}</span>
                                                                    :
                                                                    <span style={{ fontSize: '16px', marginTop: '15px' }}>-</span>
                                                            }
                                                        </Col>
                                                    </>
                                                    :
                                                    ''
                                            }

                                            {
                                                SingleCourseDetail.ondemand_video_enabled ?
                                                    <>
                                                        <Col md={5} className="assign-details-card-div-head">
                                                            <span style={leftmargin}>On Demand Class</span>
                                                        </Col>
                                                        {
                                                            videoclass_length === 0 ?
                                                                <Col md={7} className="assign-details-card-div">
                                                                    <span style={{ fontSize: '16px', marginTop: '15px' }}>-</span>
                                                                </Col>
                                                                :
                                                                <Col md={7} className="assign-details-card-div">
                                                                    <span style={valstyle}>{videoclass_length}</span>
                                                                </Col>
                                                        }

                                                    </>
                                                    :
                                                    ''
                                            }



                                            {
                                                SingleCourseDetail.certified === 1 ?
                                                    <>
                                                        <Col md={5} className="assign-details-card-div-head">
                                                            <span style={leftmargin}>Cretification</span>
                                                        </Col>
                                                        <Col md={7} className="assign-details-card-div">
                                                            <div >
                                                                <span style={valstyle}>Certified</span>
                                                            </div>
                                                        </Col>
                                                    </>
                                                    :
                                                    ''
                                            }


                                            {TagLabelCheck()}

                                            <Col xs={12}>
                                                {
                                                    course_tags.map((val, ky) => {
                                                        return (
                                                            <Chip
                                                                key={ky}
                                                                size="small"
                                                                label={val.tag_name}
                                                                color="primary"
                                                                variant='outlined'
                                                                style={{ margin: '3px 0px 0px 3px' }}
                                                                onClick={() => { history.push(`/categories/${val.tag_id}`) }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                        { user_details.skl_user_id !== undefined && Object.keys(CourseAssesmentDetails).length > 0 ? 
                                            <div className="assign-details-card-div-head col-11 d-flex justify-content-end px-0 py-0">
                                            <button
                                            className={(((SingleCourseDetail.ondemand_classes.reduce((accu, val) => accu + val.percentage, 0)) / (SingleCourseDetail.ondemand_classes.length * 100)) * 100)===100 ?"btn btn-primary":"btn btn-primary opac-view"} 
                                            disabled ={(((SingleCourseDetail.ondemand_classes.reduce((accu, val) => accu + val.percentage, 0)) / (SingleCourseDetail.ondemand_classes.length * 100)) * 100)===100 ? true : false}
                                            onClick={()=>{redirecttoQuiz()}}> 
                                            {user_details.skl_user_id !== undefined &&  CourseAssesmentDetails.IsRetake ?  "Retake Final Assessment" : "Final Assessment"}
                                            </button>
                                            </div>
                                            :''
                                        }
                                    </div>
                                </Col>

                                <Col sm="12" md="5">
                                    <Row>
                                        <Col sm={12}>

                                            <div style={{ backgroundColor: '#ffffff', height: '580px', width: '100%', border: '1px solid #d1d7dc', borderRadius: '4px', boxSizing: 'border-box', wordWrap: 'break-word', padding: '15px 0px 20px 0px', }}>
                                                <span style={{ marginLeft: '10px', color: '#1c1d1f', fontWeight: '600', letterSpacing: '-.02rem', fontSize: '1.4rem' }}>
                                                    Media Content
                                                </span>
                                                <hr />

                                                <div style={{ height: '90%', width: '100%', boxSizing: 'border-box', padding: '0px 10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                                    <Tooltip arrow title='View Banner'>
                                                        <div style={{ width: '100%', height: '200px', borderRadius: '10px', border: '1px solid #E6E6FA', cursor: 'pointer', display: 'flex', flexDirection: 'column' }} onClick={Viewimage}>
                                                            <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center' }}>
                                                                <span style={{ marginLeft: '10px', }} className="assign-details-card-div-head">
                                                                    Course Logo
                                                                </span>
                                                                <hr />
                                                            </div>

                                                            <div style={{ width: '100%', height: '150px', display: 'flex', justifyContent: 'center' }}>
                                                                <img src={SingleCourseDetail.banner_url ? SingleCourseDetail.banner_url : coursedummy} width='70%' height='100%' alt='' />
                                                            </div>
                                                        </div>
                                                    </Tooltip>

                                                    <Tooltip arrow title='View Preview Video'>
                                                        <div style={{ backgroundColor: '#f7f9fa', minHeight: '250px', width: '100%', }}>
                                                            {
                                                                video.length > 0 ?
                                                                    <VideoCard
                                                                        type="playlist"
                                                                        title={'Preview Video'}
                                                                        thumbnile={thumblink}
                                                                        duration={dur}
                                                                        openVideo={openVideo}
                                                                        embed={frstvideo.embed}

                                                                    /> :
                                                                    ''
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                </div>

                                            </div>

                                        </Col>
                                    </Row>

                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <div style={{ backgroundColor: '#ffffff', height: 'auto', width: '100%', border: '1px solid #d1d7dc', borderRadius: '4px', boxSizing: 'border-box', marginTop: '10px', wordWrap: 'break-word', padding: '15px 0px 20px 0px', }}>
                                        <span style={{ marginLeft: '10px', color: '#1c1d1f', fontWeight: '600', letterSpacing: '-.02rem', fontSize: '1.4rem' }}>
                                            Course Summary
                                        </span>
                                        <hr />
                                        <ul>
                                            <Row className="padd-2 wid100-marg-auto">
                                                {
                                                    SingleCourseDetail ? SingleCourseDetail.learning_contents ? SingleCourseDetail.learning_contents.length > 0 ? SingleCourseDetail.learning_contents.map((val, ky) => {
                                                        return (
                                                            <Col xs={12} sm={6}>
                                                                <li key={ky} style={{ color: 'grey', lineHeight: '23px', fontSize: '13px' }}>{val}</li>
                                                            </Col>
                                                        )
                                                    })
                                                        : ''
                                                        : ''
                                                        : ''

                                                }
                                            </Row>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pt-4">
                                <Col sm={12}>
                                    <Card>
                                        <CardHeader style={{}} >Course Description</CardHeader>


                                        <div
                                            style={{
                                                minHeight: "250px",
                                                maxHeight: "500px",
                                                overflowY: "scroll",
                                                padding: "10px",
                                            }}
                                            dangerouslySetInnerHTML={{ __html: SingleCourseDetail.description }}
                                        />

                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12}>
                                    <Index data={SingleCourseDetail} tabval={liveclass_length === 0 ? "3" : "1"} id={params.course_id} />
                                </Col>
                            </Row>

                            <Model
                                open={nowPlaying.open}
                                handleClose={ClosePreview}
                                size='md'
                                title={'Preview Video'}
                            >
                                {
                                    nowPlaying.iframe || nowPlaying.camera_tag_url ?
                                        <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                                            {
                                                nowPlaying.camera_tag_url ?
                                                    <div className='player-wrapper'>
                                                        <ReactPlayer
                                                            url={nowPlaying.camera_tag_url}
                                                            controls={true}
                                                        />
                                                    </div>
                                                    :
                                                    ReactHtmlParser(nowPlaying.iframe)
                                            }
                                        </div>
                                        :
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <img src={previewdummy} alt='no video' width='100%' height='100%' />
                                        </div>
                                }


                            </Model>

                        </Container>
                        <Model
                            open={imageview ? imageview : false}
                            handleClose={CloseImage}
                            title='View Banner'
                        >
                            <div style={{ width: '100%', height: '258px', borderRadius: '10px', display: 'flex', justifyContent: 'center' }}>
                                <img src={SingleCourseDetail.banner_url ? SingleCourseDetail.banner_url : coursedummy} width='267px' height='258px' style={{ borderRadius: '10px' }} alt='' />
                            </div>
                        </Model>



                        <SweetAlert
                            title="Success"
                            confirmButtonColor=""
                            show={successModal}
                            text="Completed successfully"
                            type="success"
                            onConfirm={() => setSuccessModal(false)} />


                    </div>

            }

        </Fragment>

    );
}

export default CouseFulldetails;
