import React from 'react'
import DataTable from '../../common/AssignmentTable'

const Table = (props) => {
    return (
        <>
            <DataTable
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns}
                data={props.data}
                loader={props.loader}
                downloadFile={(data) => props.downloadFile(data)}
                downloadStudentAssignment={(data) => props.downloadStudentAssignment(data)}
                downloadAssignmentStudent={(data) => props.downloadAssignmentStudent(data)}
                populateStudents={(data) => props.populateStudents(data)}
                viewDiscription={(data)=> props.viewDiscription(data)}
                addMarks={(data)=>props.addMarks(data)}
                downloadTeacherAssignment={(data)=>props.downloadTeacherAssignment(data)}
            />
        </>
    )
}

export default Table
