import axios from 'axios'
import { development } from '../../config.json'
// import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
// import swal from 'sweetalert'



export function setReqData(name, value) {
    return {
        type: 'SET_SUPERADMIN_DATA',
        payload: { [name]: value },
    }
}

// // dispatch({ type: 'CALLERID_REQUEST_TERMINATE', payload: { status: "error", message: err.error_description } })
// dispatch({ type: 'DNC_REQUEST_TERMINATE', payload: { DncArray:data}});
export const superAdminDashboard = (token, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // let data={ticket_id:ticket_id}
    //dispatch({ type: 'TICKET_ALL_REQUEST' })
    // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
    axios.get(`${development.baseUrl}getAdminDashboard`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SUPER_ADMIN_DASHBOARD_SUCCESS', payload: { dashboard_data: res.data } })
                cb(res.data)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message ? res.data.message : 'failed'
                })
            }
        })
        .catch(err => {
            //  dispatch({ type: 'TICKET_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        //text:'You are logged in to another System'
                        // text:err
                    })
                }
            }
        })
}
export const getAllvendors = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'VENDORLIST_ALL_REQUEST', payload: { vendorloader: true } })
    axios.get(`${development.baseUrl}listAllVendors`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDORLIST_ALL_SUCCESS', payload: { VendorArray: res.data, vendorloader: false } })

            }
            else {
                dispatch({ type: 'VENDORLIST_ALL_FAILED', payload: { vendorloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORLIST_ALL_FAILED', payload: { vendorloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const listAllPayments = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'LIST_PAYMENT_REQUEST', payload: { paymentloader: true } })
    axios.get(`${development.baseUrl}listAllPayments`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'LIST_PAYMENT_SUCCESS', payload: { paymentsArray: res.data.payments, paymentloader: false } })
            }
            else {
                dispatch({ type: 'LIST_PAYMENT_FAILED', payload: { paymentloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'LIST_PAYMENT_FAILED', payload: { paymentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const listAllFilteredPayments = (token, from_date, to_date, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'LIST_FILTERPAYMENT_REQUEST', payload: { filterloader: true, paymentloader: true } })
    axios.get(`${development.baseUrl}listAllPayments?from_date=${from_date}&to_date=${to_date}&user_id=${user_id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'LIST_FILTERPAYMENT_SUCCESS', payload: { paymentsArray: res.data.payments, paymentloader: false, filterloader: false } })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'LIST_FILTERPAYMENT_FAILED', payload: { filterloader: false, paymentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const AddNewVendor = (token, data, cb, cb2, cb3) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorcreate: true } })

    axios.post(`${development.baseUrl}createVendor`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorcreate: false } })
                cb()//to open success popup
                cb2()//to reset form
            }
            else {
                dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorcreate: false, files: [] } })
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorcreate: false, files: [] } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const getVendorDetails = (token, vendor_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: vendor_id
    }
    //  dispatch({ type: "VENDOR_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}vendorDetails`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "VENDOR_ALL_SUCCESS", payload: {} });
                cb(res.data)

            }
        })
        .catch((err) => {
            dispatch({ type: "VENDOR_ALL_FAILED", payload: { error: err } });
        });
};
export const populateVendorDetail = (token, vendor_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: vendor_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "VENDOR_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}editVendor`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "VENDOR_ALL_SUCCESS", payload: {} });
                cb(res.data.vendor)
                // console.log('populateVendorDetail', res.data)
            }
        })
        .catch((err) => {
            dispatch({ type: "VENDOR_ALL_FAILED", payload: { error: err } });
        });
};
export const getStudentDetails = (token, student_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: student_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "SPECIFIC_STUDENT_REQUEST" });
    axios
        .post(`${development.baseUrl}studentDetails`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "SPECIFIC_STUDENT_SUCCESS", payload: {} });
                cb(res.data)
                // console.log('populateVendorDetail', res.data)
            }
        })
        .catch((err) => {
            dispatch({ type: "SPECIFIC_STUDENT_FAILED", payload: { error: err } });
        });
};


export const updateVendor = (token, data, cb, cb3) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { editvendor_loader: true } })

    axios.post(`${development.baseUrl}updateVendor`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { editvendor_loader: false } })
                cb()//to open success popup
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', payload: { editvendor_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}



export const getAllvendorCourses = (token, enterprise_id, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    // let data = { enterprise_id: enterprise_id }

    dispatch({ type: 'VENDORLISTCOURSE_ALL_REQUEST', payload: { vendorcourseloader: true } })
    axios.get(`${development.baseUrl}listVendorCourses?enterprise_id=${enterprise_id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'VENDORLISTCOURSE_ALL_SUCCESS', payload: {
                        VendorCourseArray: res.data,
                        VendorCourseArrayData: res.data.courses ? res.data.courses.data : [],
                        vendorcourseloader: false,
                        vendor_nextpage: res.data.courses ? res.data.courses.next_page_url : '',
                        vendor_prevpage: res.data.courses ? res.data.courses.prev_page_url : '',
                        from: res.data.courses ? res.data.courses.from : '',
                        to: res.data.courses ? res.data.courses.to : '',

                    }
                })

            }
            else {
                dispatch({ type: 'VENDORLISTCOURSE_ALL_FAILED', payload: { vendorcourseloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORLISTCOURSE_ALL_FAILED', payload: { vendorcourseloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const getAllvendorCoursesSearch = (token, enterprise_id, page, search, filter, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    // let data = { enterprise_id: enterprise_id }

    dispatch({ type: 'VENDORLISTCOURSE_ALL_REQUEST', payload: { vendorcourseloader: true } })
    axios.get(`${development.baseUrl}searchVendorCourses?enterprise_id=${enterprise_id}&page=${page}&search_term=${search}&request_status=${filter}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'VENDORLISTCOURSE_ALL_SUCCESS', payload: {
                        VendorCourseArray: res.data,
                        VendorCourseArrayData: res.data.courses ? res.data.courses.data : [],
                        vendorcourseloader: false,
                        vendor_nextpage: res.data.courses ? res.data.courses.next_page_url : '',
                        vendor_prevpage: res.data.courses ? res.data.courses.prev_page_url : '',
                        from: res.data.courses ? res.data.courses.from : '',
                        to: res.data.courses ? res.data.courses.to : '',
                    }
                })
                cb()

            }
            else {
                dispatch({ type: 'VENDORLISTCOURSE_ALL_FAILED', payload: { vendorcourseloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORLISTCOURSE_ALL_FAILED', payload: { vendorcourseloader: false } })
            // console.log('eeeeerrrrrrrrrrrrrrrroorrrr',err)
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const getAllvendorCoursesSearchAdmin = (token, enterprise_id, page, search) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    // let data = { enterprise_id: enterprise_id }

    dispatch({ type: 'VENDORLISTCOURSE_ALL_REQUEST', payload: { vendorcourseloader: true } })
    axios.get(`${development.baseUrl}searchVendorCourses?enterprise_id=${enterprise_id}&page=${page}&search_term=${search}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'VENDORLISTCOURSE_ALL_SUCCESS', payload: {
                        VendorCourseArray: res.data,
                        VendorCourseArrayData: res.data.courses ? res.data.courses.data : [],
                        vendorcourseloader: false,
                        vendor_nextpage: res.data.courses ? res.data.courses.next_page_url : '',
                        vendor_prevpage: res.data.courses ? res.data.courses.prev_page_url : '',
                        from: res.data.courses ? res.data.courses.from : '',
                        to: res.data.courses ? res.data.courses.to : '',
                    }
                })

            }
            else {
                dispatch({ type: 'VENDORLISTCOURSE_ALL_FAILED', payload: { vendorcourseloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORLISTCOURSE_ALL_FAILED', payload: { vendorcourseloader: false } })
            // console.log('eeeeerrrrrrrrrrrrrrrroorrrr',err)
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getAllvendorStudents = (token, enterprise_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { enterprise_id: enterprise_id }
    dispatch({ type: 'VENDORLISTSTUDENT_ALL_REQUEST', payload: { vendorstudentloader: true } })
    axios.post(`${development.baseUrl}listAllVendorStudents`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDORLISTSTUDENT_ALL_SUCCESS', payload: { VendorStudentArray: res.data, vendorstudentloader: false } })
            }
            else {
                dispatch({ type: 'VENDORLISTSTUDENT_ALL_FAILED', payload: { vendorstudentloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORLISTSTUDENT_ALL_FAILED', payload: { vendorstudentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getAllvendorStudentsCourse = (token, student_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // let data = { student_id: student_id }
    dispatch({ type: 'VENDORLISTSTUDENTCOURSE_ALL_REQUEST', payload: { vendorstudentcourseloader: true } })
    axios.get(`${development.baseUrl}getMyCourse?student_id=${student_id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'VENDORLISTSTUDENTCOURSE_ALL_SUCCESS', payload: {
                        VendorStudentCourseArray: res.data.course_details,
                        first_name: res.data.course_details ? res.data.course_details[0] ? res.data.course_details[0].first_name : '-' : '-',
                        last_name: res.data.course_details ? res.data.course_details[0] ? res.data.course_details[0].last_name : '' : '',
                        vendorstudentcourseloader: false
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORLISTSTUDENTCOURSE_ALL_FAILED', payload: { vendorstudentcourseloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const getVendorSingleCoursedetails = (token, course_id, type) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id, type: type }
    dispatch({ type: 'VENDORSINGLECOURSE_ALL_REQUEST', payload: { singlecourseloader: true } })
    axios.post(`${development.baseUrl}courseDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'VENDORSINGLECOURSE_ALL_SUCCESS', payload: {
                        SingleCourseDetail: res.data,
                        singlecourseloader: false,
                        Course_Edited_status: res.data.edited_request_status ? res.data.edited_request_status : 'No Request',
                        Course_Edit_status: res.data.edit_request_status ? res.data.edit_request_status : 'No Request',
                        course_tags: res.data ? res.data.tags ? res.data.tags : [] : []
                    }
                })

            }
            else {
                dispatch({ type: 'VENDORSINGLECOURSE_ALL_FAILED', payload: { singlecourseloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDORSINGLECOURSE_ALL_FAILED', payload: { singlecourseloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const deleteVendor = (token, vendor_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: vendor_id }
    // debugger
    dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorloader: true } })
    axios.post(`${development.baseUrl}deleteVendor`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const blockVendor = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id }
    // debugger
    dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorloader: true } })
    axios.post(`${development.baseUrl}blockVendor`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
                Swal.fire({
                    text: res.data.message,
                    icon: 'warning'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
/////////////////reseller///////////////
export const getAllReseller = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'RESLLERLIST_ALL_REQUEST', payload: { resellerloader: true } })
    axios.get(`${development.baseUrl}listAllResellers`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLERLIST_ALL_SUCCESS', payload: { ResellerArray: res.data, resellerloader: false } })

            }
            else {
                dispatch({ type: 'RESLLERLIST_ALL_FAILED', payload: { resellerloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLERLIST_ALL_FAILED', payload: { resellerloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const getResellerDetails = (token, reseller_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: reseller_id
    }
    //  dispatch({ type: "VENDOR_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}resellerDetails`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "RESELLER_SINGLE_SUCCESS", payload: {} });
                cb(res.data)

            }
        })
        .catch((err) => {
            dispatch({ type: "RESELLER_SINGLE_FAILED", payload: { error: err } });
        });
};
export const validatePincode = (token, pincode, cb, errorcb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        pincode: pincode
    }
    dispatch({ type: "VALIDATE_PIN_REQUEST" });
    axios
        .post(`${development.baseUrl}validatePincode`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "VALIDATE_PIN_SUCCESS" });
                cb(pincode)

            }
            else {
                dispatch({ type: "VALIDATE_PIN_FAILED", payload: res.data });
                errorcb(res.data.message)
            }
        })
        .catch((err) => {
            // dispatch({ type: "VALIDATE_PIN_FAILED", payload: { error: err } });
        });
};

export const createResellerPincode = (token, user_id, pincode_ids, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: user_id,
        pincode_ids: pincode_ids
    }
    // dispatch({ type: "VALIDATE_PIN_REQUEST" });
    axios
        .post(`${development.baseUrl}createResellerPincode`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                // dispatch({ type: "VALIDATE_PIN_SUCCESS" });
                // cb(pincode)
                Swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    text: res.data.message
                })
                cb()
            }
            else {
                // dispatch({ type: "VALIDATE_PIN_FAILED", payload: res.data });
                // errorcb(res.data.message)
                Swal.fire({
                    icon: 'eror',
                    title: 'Error',
                    text: res.data.message
                })
                cb()
            }
        })
        .catch((err) => {
            // dispatch({ type: "VALIDATE_PIN_FAILED", payload: { error: err } });
        });
};

export const getAllResellerStudent = (token, resellerid) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        reseller_id: resellerid
    }
    // debugger
    dispatch({ type: 'RESLLERSTUDENT_ALL_REQUEST', payload: { resellerstudentloader: true } })
    axios.post(`${development.baseUrlente}resllersStudents`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLERSTUDENT_ALL_SUCCESS', payload: { ResellerStudentArray: res.data, resellerstudentloader: false } })
            }
            else {
                dispatch({ type: 'RESLLERSTUDENT_ALL_FAILED', payload: { resellerstudentloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLERSTUDENT_ALL_FAILED', payload: { resellerstudentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const AddNewReseller = (token, reseller_name, contact_number, email, password, address, area, pin, cb, cb2, cb3) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let p = Number(pin)
    let data = { reseller_name: reseller_name, contact_number: contact_number, email: email, password: password, address: address, area: area, pin: p }
    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { resellercreate: true } })
    axios.post(`${development.baseUrl}createReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { resellercreate: false } })
                cb()//to open success popup
                cb2()//to reset form
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { resellercreate: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { resellercreate: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const populateResellerDetail = (token, reseller_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: reseller_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "RESLLER_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}editReseller`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data) {
                dispatch({ type: "RESLLER_ALL_SUCCESS", payload: {} });
                cb(res.data.reseller)
            }
        })
        .catch((err) => {
            dispatch({ type: "RESLLER_ALL_FAILED", payload: { error: err } });
        });
};



export const updateReseller = (token, reseller_name, contact_number, email, password, address, area, pin, commision, reseller_id, cb, cb3) => (dispatch) => {


    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data
    let p = Number(pin)
    if (password) {
        data = { user_id: reseller_id, reseller_name: reseller_name, contact_number: contact_number, email: email, password: password, address: address, area: area, pin: p, commision: commision }
    }
    else {
        data = { user_id: reseller_id, reseller_name: reseller_name, contact_number: contact_number, email: email, address: address, area: area, pin: p, commision: commision }
    }


    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { edit_rellseller: true } })

    axios.post(`${development.baseUrl}updateReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { edit_rellseller: false } })
                cb()//to open success popup
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { edit_rellseller: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { edit_rellseller: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}



export const deleteReseller = (token, reseller_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: reseller_id }
    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { resellerloader: true } })
    axios.post(`${development.baseUrl}deleteReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { resellerloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { resellerloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { resellerloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const blockReseller = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id }
    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { resellerloader: true } })
    axios.post(`${development.baseUrl}blockReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { resellerloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { resellerloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { resellerloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const payoffCommision = (token, user_id, amount, remarks, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id, amount: amount, remarks: remarks }
    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { payoffloader: true } })
    axios.post(`${development.baseUrl}payoffCommision`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { payoffloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { payoffloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { payoffloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
export const correctCommision = (token, user_id, amount, remarks, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id, amount: amount, remarks: remarks }
    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { payoffloader: true } })
    axios.post(`${development.baseUrl}correctCommision`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { payoffloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { payoffloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { payoffloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const listAllResellerTransaction = (token, user_id, from_date, to_date,) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'RESLLER_ALL_REQUEST', payload: { transactions_loader: true } })
    axios.get(`${development.baseUrl}listAllTransaction?user_id=${user_id}&from_date=${from_date}&to_date=${to_date}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'RESLLER_ALL_SUCCESS', payload: { transactions: res.data.transactions, transactions_loader: false } })
                // cb()//to open success popup
            }
            else {
                dispatch({ type: 'RESLLER_ALL_FAILED', payload: { transactions_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'RESLLER_ALL_FAILED', payload: { transactions_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

///////////////schoool//////////
export const getAllSchool = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { schoolloader: true } })
    axios.get(`${development.baseUrl}listAllSchools`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { SchoolArray: res.data, schoolloader: false } })
            }
            else {
                dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const AddNewSchool = (token, school_name, contact_number, contact_person, email, password, address, area, pin, panchayath_id, cb, cb2, cb3) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let p = Number(pin)
    let data = { school_name: school_name, contact_number: contact_number, contact_person: contact_person, email: email, password: password, address: address, area: area, pin: p, panchayath_id: panchayath_id }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { schoolcreate: true } })
    axios.post(`${development.baseUrl}createSchool`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { schoolcreate: false } })
                cb()//to open success popup
                cb2()//to reset form
            }
            else {
                dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolcreate: false } })

                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message ? res.data.messag : '',

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolcreate: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const populateSchoolDetail = (token, school_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: school_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "SCHOOL_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}editSchool`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "SCHOOL_ALL_SUCCESS", payload: {} });
                cb(res.data.school)
            }
        })
        .catch((err) => {
            dispatch({ type: "SCHOOL_ALL_FAILED", payload: { error: err } });
        });
};



export const UpdateSchool = (token, school_id, school_name, contact_number, contact_person, email, password, address, area, pin, panchayath_id, commision, cb, cb3) => (dispatch) => {


    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let p = Number(pin)
    let data
    if (password) {
        data = { user_id: school_id, school_name: school_name, contact_number: contact_number, contact_person: contact_person, email: email, password: password, address: address, area: area, pin: p, panchayath_id: panchayath_id, commision: commision }
    }
    else {
        data = { user_id: school_id, school_name: school_name, contact_number: contact_number, contact_person: contact_person, email: email, address: address, area: area, pin: p, panchayath_id: panchayath_id, commision: commision }
    }

    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { schooledit_loader: true } })

    axios.post(`${development.baseUrl}updateSchool`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { schooledit_loader: false } })
                cb()//to open success popup
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schooledit_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}



export const deleteSchool = (token, school_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: school_id }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { schoolloader: true } })
    axios.post(`${development.baseUrl}deleteSchool`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { schoolloader: false } })
                cb(res.data.message)//to open success popup
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const blockSchool = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { schoolloader: true } })
    axios.post(`${development.baseUrl}blockSchool`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { schoolloader: false } })
                cb(res.data.message)//to open success popup
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const getSchoolDetails = (token, user_id) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: user_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "SCHOOL_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}schoolDetails`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({
                    type: "SCHOOL_ALL_SUCCESS", payload: {
                        SchoolDetails: res.data.school,
                        wallet_balance: res.data.balance
                    }
                });
            }
        })
        .catch((err) => {
            dispatch({ type: "SCHOOL_ALL_FAILED", payload: { error: err } });
        });
};


export const listAllSchoolTransaction = (token, user_id, from_date, to_date,) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { transactions_loader_school: true } })
    axios.get(`${development.baseUrl}listAllTransaction?user_id=${user_id}&from_date=${from_date}&to_date=${to_date}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { transactions_school: res.data.transactions, transactions_loader_school: false } })
                // cb()//to open success popup
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { transactions_loader_school: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
///////////////////student/////////////////////
export const getAllStudent = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'STUDENT_ALL_REQUEST', payload: { studentloader: true } })
    axios.get(`${development.baseUrl}listAllStudents`, { headers: head })
        .then(res => {
            if (res.data) {
                dispatch({ type: 'STUDENT_ALL_SUCCESS', payload: { StudentArray: res.data, studentloader: false } })

            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const AddNewStudent = (token, student_name, contact_number, email, password, address, area, pin, panchayath_id, genex_referral_code, cb, cb2, cb3) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let p = Number(pin)
    let data = { name: student_name, contact_number: contact_number, email: email, password: password, address: address, area: area, pin: p, panchayath_id: panchayath_id, genex_referral_code: genex_referral_code }
    // debugger
    dispatch({ type: 'STUDENT_ALL_REQUEST', payload: { studentcreate: true } })
    axios.post(`${development.baseUrl}createStudent`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_ALL_SUCCESS', payload: { studentcreate: false } })
                cb()//to open success popup
                cb2()//to reset form
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentcreate: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const populateStudentDetail = (token, student_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: student_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "STUDENT_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}editStudent`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data) {
                dispatch({ type: "STUDENT_ALL_SUCCESS", payload: {} });
                cb(res.data.student)
            }
        })
        .catch((err) => {
            dispatch({ type: "STUDENT_ALL_FAILED", payload: { error: err } });
        });
};


export const UpdateStudent = (token, student_id, first_name, contact_number, email, password, address, area, pin, panchayath_id, genex_referral_code, cb, cb3) => (dispatch) => {


    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let p = Number(pin)
    let data
    if (password) {
        data = { user_id: student_id, name: first_name, contact_number: contact_number, email: email, password: password, address: address, area: area, pin: p, panchayath_id: panchayath_id, genex_referral_code: genex_referral_code }
    }
    else {
        data = { user_id: student_id, name: first_name, contact_number: contact_number, email: email, address: address, area: area, pin: p, panchayath_id: panchayath_id, genex_referral_code: genex_referral_code }
    }


    // debugger
    dispatch({ type: 'STUDENT_ALL_REQUEST', payload: { editloader: true } })

    axios.post(`${development.baseUrl}updateStudent`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_ALL_SUCCESS', payload: { editloader: false } })
                cb()//to open success popup
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_ALL_FAILED', payload: { editloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const deleteStudent = (token, student_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: student_id }
    // debugger
    dispatch({ type: 'STUDENT_ALL_REQUEST', payload: { studentloader: true } })
    axios.post(`${development.baseUrl}deleteStudent`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_ALL_SUCCESS', payload: { studentloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentloader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const blockStudent = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id }
    // debugger
    dispatch({ type: 'STUDENT_ALL_REQUEST', payload: { studentloader: true } })
    axios.post(`${development.baseUrl}blockStudent`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_ALL_SUCCESS', payload: { studentloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
                dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
///////////////////ticket////////////////////

export const getAllPendingTickets = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TICKET_ALL_REQUEST', payload: { ticket_pending_loader: true } })
    axios.get(`${development.baseUrl}listAllTickets/Pending`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TICKET_ALL_SUCCESS', payload: {
                        pendingArray: res.data.tickets,
                        ticket_pending_loader: false,
                        pending_count: res.data.total_pending,
                        progress_count: res.data.total_inprogress,
                        resolved_count: res.data.total_resolved

                    }
                })
            }
            else {
                dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_pending_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_pending_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const getAllInProgressTickets = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TICKET_ALL_REQUEST', payload: { ticket_inprogress_loader: true } })
    axios.get(`${development.baseUrl}listAllTickets/Inprogress`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TICKET_ALL_SUCCESS', payload: {
                        progressArray: res.data.tickets,
                        pending_count: res.data.total_pending,
                        progress_count: res.data.total_inprogress,
                        resolved_count: res.data.total_resolved,
                        ticket_inprogress_loader: false
                    }
                })

            }
            else {
                dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_inprogress_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_inprogress_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const getAllResolvedTickets = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'TICKET_ALL_REQUEST', payload: { ticket_resolved_loader: true } })
    axios.get(`${development.baseUrl}listAllTickets/Resolved`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TICKET_ALL_SUCCESS', payload: {
                        resolvedArray: res.data.tickets,
                        pending_count: res.data.total_pending,
                        progress_count: res.data.total_inprogress,
                        resolved_count: res.data.total_resolved,
                        ticket_resolved_loader: false
                    }
                })

            }
            else {
                dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_resolved_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_resolved_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const UpdateTicketStatus = (token, ticket_id, status, cb) => (dispatch) => {


    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { ticket_id: ticket_id, status: status }


    // debugger
    dispatch({ type: 'TICKET_ALL_REQUEST', payload: { status_update_loader: true } })

    axios.post(`${development.baseUrl}updateTicketStatus`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKET_ALL_SUCCESS', payload: { status_update_loader: false } })
                cb()//to open success popup
            }
            else {
                dispatch({ type: 'TICKET_ALL_FAILED', payload: { status_update_loader: false } })
            }

        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload: { status_update_loader: false } })
            // console.log('CCCAAATTCCCHHHHHeeeeeeeeeeeeeeeeeeeeerrrrrrrrrrrrrrrrrrrr')
            // Swal.fire({
            //     icon:'error',
            //     title:'Something Went Wrong',
            //     text:'Failed in loading data'
            // })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const getSingleTicket = (token, ticket_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { ticket_id: ticket_id }
    dispatch({ type: 'TICKET_ALL_REQUEST' })
    axios.post(`${development.baseUrl}getTicketDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TICKET_ALL_SUCCESS', payload: {
                        ticket_details: res.data.ticket,
                        Chatdata: res.data.messages
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const sendTicketMessage = (token, ticket_id, message, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { ticket_id: ticket_id, message: message }
    dispatch({ type: 'TICKET_ALL_REQUEST', payload: { ticket_create_loader: true } })
    axios.post(`${development.baseUrl}createTicketMessage`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TICKET_ALL_SUCCESS' })
                cb()
            }
            else {
                dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_create_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TICKET_ALL_FAILED', payload: { ticket_create_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

//SITE SETTINGS

export const getSiteSettings = (token, cb) => (dispatch) => {
    ///////////////////////////////

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'SITE_SETTINGS_REQUEST' })
    axios.get(`${development.baseUrl}getSiteSettings`, { headers: head })
        .then(res => {
            if (res.data.result) {
                // let options = res.data.tab_section
                let options = res.data.tag_tab_section;
                let selected = res.data.tag_tab_section.filter(tab => tab.selected);

                let categories = res.data.tag_browse_section;
                let selected_categories = res.data.tag_browse_section.filter(tab => tab.selected);

                let menu_categories = res.data.menu_tag_section;
                let selected_menu_categories = res.data.tag_browse_section.filter(tab => tab.selected === true);

                
                let all = res.data.tag_tab_section

                let social_media_section = res.data.social_media_section

                dispatch({ type: 'SITE_SETTINGS_REQUEST_SUCCESS', payload: { selectedCourse: selected, avilableOptions: options } })
                cb(options, selected, categories, selected_categories, all, selected_menu_categories, menu_categories, social_media_section)
            }
        })
        .catch(err => {
            dispatch({ type: 'SITE_SETTINGS_REQUEST_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const showUserManual = (token, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'SITE_SETTINGS_REQUEST' })
    axios.get(`${development.baseUrl}showUserManual`, { headers: head })
        .then(res => {
            if (res.data.result) {
                // let options = res.data.tab_section

                cb(res.data.details)
            }
        })
        .catch(err => {
            // dispatch({ type: 'SITE_SETTINGS_REQUEST_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong'

                    })
                }
            }
        })
}


export const addSiteSettings = (token, arr, type, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        selections: arr,
        type: type
    }
    dispatch({ type: 'SITE_SETTINGS_ADD_REQUEST' })
    cb(true)
    axios.post(`${development.baseUrl}addSiteSettings`, data, { headers: head })
        .then(res => {
            cb(false)
            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Site settings',
                    text: res.data.message
                })
                dispatch({ type: 'SITE_SETTINGS_ADD_SUCCESS', payload: {} })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Site settings',
                    text: res.data.message
                })
            }
        })
        .catch((err) => {
            dispatch({ type: 'SITE_SETTINGS_ADD_FAILED' })
            cb(false)
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
        })

}
export const deleteUserManual = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        id: id
    }
    // dispatch({ type: 'SITE_SETTINGS_ADD_REQUEST' })

    axios.post(`${development.baseUrl}deleteUserManual`, data, { headers: head })
        .then(res => {

            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Site settings',
                    text: res.data.message
                })
                //  dispatch({ type: 'SITE_SETTINGS_ADD_SUCCESS', payload: {} })
                cb()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Site settings',
                    text: res.data.message
                })
            }
        })
        .catch((err) => {
            dispatch({ type: 'SITE_SETTINGS_ADD_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
        })

}
export const downloadUserManual = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        id: id
    }
    // dispatch({ type: 'SITE_SETTINGS_ADD_REQUEST' })
    // cb(true)
    axios.post(`${development.baseUrl}downloadUserManual`, data, { headers: head })
        .then(res => {

            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Site settings',
                    text: res.data.message
                })
                //  dispatch({ type: 'SITE_SETTINGS_ADD_SUCCESS', payload: {} })

            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Site settings',
                    text: res.data.message
                })
            }
        })
        .catch((err) => {
            dispatch({ type: 'SITE_SETTINGS_ADD_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
        })

}

// .catch(err => {
//     dispatch({ type: 'SITE_SETTINGS_ADD_FAILED' })
//     if (err) {
//         if (err.response) {
//             if (err.response) {
//                 if (err.response.status === 401) {
//                     Swal.fire({
//                         icon: 'error',
//                         text: 'You are logged in to another System'
//                     })
//                 }
//             }
//         }
//         else {
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Something Went Wrong',
//                 text: 'You are logged in to another System'
//             })
//         }
//     })


export const rejectCourse = (token, reasons, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id, reject_reasons: reasons }
    // debugger
    dispatch({ type: 'REJECT_ALL_REQUEST', payload: { reject_loader: true } })
    axios.post(`${development.baseUrl}rejectCourse`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'REJECT_ALL_SUCCESS', payload: { reject_loader: false } })
                cb()//to open success popup
            }
            else {
                dispatch({ type: 'REJECT_ALL_FAILED', payload: { reject_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'REJECT_ALL_FAILED', payload: { reject_loader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const rejectEditRequest = (token, reasons, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id, reject_reasons: reasons }
    // debugger
    dispatch({ type: 'REJECT_ALL_REQUEST', payload: { reject_loader: true } })
    axios.post(`${development.baseUrl}rejectEditRequest`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'REJECT_ALL_SUCCESS', payload: { reject_loader: false } })
                cb()//to open success popup
            }
            else {
                dispatch({ type: 'REJECT_ALL_FAILED', payload: { reject_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'REJECT_ALL_FAILED', payload: { reject_loader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const rejectEditedCourseRequest = (token, reasons, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id, reject_reasons: reasons }
    // debugger
    dispatch({ type: 'REJECT_ALL_REQUEST', payload: { reject_loader: true } })
    axios.post(`${development.baseUrl}rejectEditedCourseRequest`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'REJECT_ALL_SUCCESS', payload: { reject_loader: false } })
                cb()//to open success popup
            }
            else {
                dispatch({ type: 'REJECT_ALL_FAILED', payload: { reject_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'REJECT_ALL_FAILED', payload: { reject_loader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const acceptCourse = (token, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id }
    // debugger
    dispatch({ type: 'ACCEPT_ALL_REQUEST', payload: { accept_loader: true } })
    axios.post(`${development.baseUrl}approveCourse`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ACCEPT_ALL_SUCCESS', payload: { accept_loader: false } })
                cb()
            }
            else {
                dispatch({ type: 'ACCEPT_ALL_FAILED', payload: { accept_loader: false } })
            }

        })
        .catch(err => {
            dispatch({ type: 'ACCEPT_ALL_FAILED', payload: { accept_loader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
//////////////////////edit approvels//////////////
export const approveEditRequest = (token, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id }
    // debugger
    dispatch({ type: 'ACCEPT_ALL_REQUEST', payload: { accept_loader: true } })
    axios.post(`${development.baseUrl}approveEditRequest`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ACCEPT_ALL_SUCCESS', payload: { accept_loader: false } })
                cb()
            }
            else {
                dispatch({ type: 'ACCEPT_ALL_FAILED', payload: { accept_loader: false } })
            }

        })
        .catch(err => {
            dispatch({ type: 'ACCEPT_ALL_FAILED', payload: { accept_loader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const approveEditedCourseRequest = (token, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id }
    // debugger
    dispatch({ type: 'ACCEPT_ALL_REQUEST', payload: { accept_loader: true } })
    axios.post(`${development.baseUrl}approveEditedCourseRequest`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ACCEPT_ALL_SUCCESS', payload: { accept_loader: false } })
                cb()
            }
            else {
                dispatch({ type: 'ACCEPT_ALL_FAILED', payload: { accept_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'ACCEPT_ALL_FAILED', payload: { accept_loader: false } })

            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


//////////////////////////////////////////////////
export const addCourseCommission = (token, type, value, course_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        commission_type: type,
        commission: value,
        course_id: course_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "COMMISSION_ALL_REQUEST", payload: { commission_loader: true } });
    axios
        .post(`${development.baseUrl}addCourseCommission`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "COMMISSION_ALL_SUCCESS", payload: { commission_loader: false } });
                cb()
            }
            else {
                dispatch({ type: "COMMISSION_ALL_SUCCESS", payload: { commission_loader: false } });
            }
        })
        .catch((err) => {
            dispatch({ type: "COMMISSION_ALL_FAILED", payload: { error: err, commission_loader: false } });
        });
};

export const downloadVendorDoc = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {

        user_id: user_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    //  dispatch({ type: "VENDOR_ALL_REQUEST" });
    axios
        .post(`${development.baseUrl}downloadVendorDoc`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                //  dispatch({ type: "VENDOR_ALL_SUCCESS", payload: {} });
                // cb(res.data.vendor)
                //console.log("calllllllllllllllllllll")

                // Swal.fire({
                //     icon: 'success',
                //     title: 'File download will start shortly',
                //     text: res.data.message
                // })
                // history.push()
                cb(res.data.download_url)

                var link = document.createElement("a");
                link.href = res.data.download_url;

                link.click();
                link.remove();
                // console.log("calllllllllllllllllllll")

                // fake server request, getting the file url as response


            }
            else {

                Swal.fire({
                    icon: "error",
                    title: 'Download',
                    text: res.data.message

                }
                )
            }
        })
        .catch((err) => {
            // dispatch({ type: "VENDOR_ALL_FAILED", payload: { error: err } });
        });
};

export const getActiveCourses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ACTIVE_COURSE_REQUEST', payload: { activeloader: true } })
    axios.get(`${development.baseUrl}getActiveCourses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ACTIVE_COURSE_SUCCESS', payload: { ActiveCourseArray: res.data.courses, activeloader: false } })

            }
        })
        .catch(err => {
            dispatch({ type: 'ACTIVE_COURSE_FAILED', payload: { activeloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const getPendingCourses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'PENDING_COURSE_REQUEST', payload: { pendingloader: true } })
    axios.get(`${development.baseUrl}getPendingCourses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'PENDING_COURSE_SUCCESS', payload: { PendingCourseArray: res.data.courses, pendingloader: false } })

            }
        })
        .catch(err => {
            dispatch({ type: 'PENDING_COURSE_FAILED', payload: { pendingloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const getRejectedCourses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'REJECTED_COURSE_REQUEST', payload: { rejectloader: true } })
    axios.get(`${development.baseUrl}getRejectedCourses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'REJECTED_COURSE_SUCCESS', payload: { RejectedCourseArray: res.data.courses, rejectloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'REJECTED_COURSE_FAILED', payload: { rejectloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const activateUser = (token, vendor_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: vendor_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "VENDOR_ACCEPT_REQUEST" });
    axios
        .post(`${development.baseUrl}activateUser`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "VENDOR_ACCEPT_SUCCESS", payload: {} });
                cb(res.data.message)

            }
        })
        .catch((err) => {
            dispatch({ type: "VENDOR_ACCEPT_FAILED", payload: { error: err } });
        });
};

export const rejectUser = (token, vendor_id, cb) => (dispatch) => {
    const head = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        user_id: vendor_id
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "VENDOR_REJECT_REQUEST" });
    axios
        .post(`${development.baseUrl}rejectUser`, data, {
            headers: head,
        })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data.result) {
                dispatch({ type: "VENDOR_REJECT_SUCCESS", payload: {} });
                cb(res.data.message)

            }
        })
        .catch((err) => {
            dispatch({ type: "VENDOR_REJECT_FAILED", payload: { error: err } });
        });
};


////////////////populateGroupStudents/////////////

export const populateGroupStudents = (token, group_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { group_id: group_id }
    dispatch({ type: 'GROUPSTUDENT_ALL_REQUEST', })
    axios.post(`${development.baseUrlente}populateGroupStudents`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GROUPSTUDENT_ALL_SUCCESS', payload: { GroupStudentArray: res.data.details, } })

            }
        })
        .catch(err => {
            dispatch({ type: 'GROUPSTUDENT_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getDefaultCommisions = (token, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'GET_DEFAULT_COMMISIONS_REQUEST', payload: {} })
    axios.get(`${development.baseUrl}getDefaultCommision`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_DEFAULT_COMMISIONS_SUCCESS', payload: {} })
                cb(res.data)
            }
        })
        .catch(err => {
            dispatch({ type: 'GET_DEFAULT_COMMISIONS_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const updateDefaultCommision = (token, Commisions, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { "reseller_commision": Commisions.reseller_commision, "school_commision": Commisions.school_commision }
    dispatch({ type: 'UPDATE_DEFAULT_COMMISION_REQUEST', })
    axios.post(`${development.baseUrl}updateDefaultCommision`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPDATE_DEFAULT_COMMISION_SUCCESS', payload: {} })
                cb()
                Swal.fire({
                    icon: 'success',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_DEFAULT_COMMISION_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


///////////for reseller////////
export const listStudentsUnderReseller = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'STUDENT_ALL_REQUEST', payload: { studentloader: true } })
    axios.get(`${development.baseUrl}listStudentsUnderReseller`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STUDENT_ALL_SUCCESS', payload: { StudentArray: res.data, studentloader: false } })

            }
        })
        .catch(err => {
            dispatch({ type: 'STUDENT_ALL_FAILED', payload: { studentloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const listSchoolsUnderReseller = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { schoolloader: true } })
    axios.get(`${development.baseUrl}listSchoolsUnderReseller`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { SchoolArray: res.data, schoolloader: false } })

            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const listSalesUnderReseller = (token, from_date, to_date, school_id, student_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { from_date: from_date, to_date: to_date, school_id: school_id, student_id: student_id }
    dispatch({ type: 'SALES_ALL_REQUEST', payload: { salesloader: true } })
    axios.post(`${development.baseUrl}listSalesUnderReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SALES_ALL_SUCCESS', payload: { sales: res.data, salesloader: false } })
                cb(res.data)
            }
        })
        .catch(err => {
            dispatch({ type: 'SALES_ALL_FAILED', payload: { salesloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

////reseller invoive dwnload
export const exportSalesUnderReseller = (token, from_date, to_date, school_id, student_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { from_date: from_date, to_date: to_date, school_id: school_id, student_id: student_id }
    dispatch({ type: 'SALES_ALL_REQUEST', payload: { saleinvoicesloader: true } })
    axios.post(`${development.baseUrl}exportSalesUnderReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SALES_ALL_SUCCESS', payload: { saleinvoicesloader: false } })
                cb(res.data.url)
            }
        })
        .catch(err => {
            dispatch({ type: 'SALES_ALL_FAILED', payload: { saleinvoicesloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

//reselller pincode apis
export const getAllStates = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'STATE_ALL_REQUEST' })
    axios.get(`${development.baseUrl}listAllStates`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'STATE_ALL_SUCCESS', payload: { states: res.data.states } })

            }
        })
        .catch(err => {
            //   dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const fillterDistricts = (token, id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { district_loader: true } })
    axios.get(`${development.baseUrl}listAllDistricts?state_id=${id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { district_loader: false } })
                dispatch({ type: 'DISTRICTS_ALL_SUCCESS', payload: { districts: res.data.districts } })
                // listAllDistricts?state_id=7
            }
            else {
                dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { district_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { district_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const fillterPincodes = (token, id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.get(`${development.baseUrl}listAllPincodes?district_id=${id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'PIN_ALL_SUCCESS', payload: { pincodes: res.data.pincodes } })
                // listAllDistricts?state_id=7
            }
        })
        .catch(err => {
            //   dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const getPincodes = (token, id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.get(`${development.baseUrl}listAllUnusedPincodes?district_id=${id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'PIN_UNUSED_SUCCESS', payload: { UnusedPincodes: res.data.pincodes } })
                // listAllDistricts?state_id=7
            }
        })
        .catch(err => {
            //   dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
export const filterPincodeAssignment = (token, filter, pincode_ids, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        state_id: filter.state_id,
        district_id: filter.district_id,
        pincode_ids: pincode_ids

    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.post(`${development.baseUrl}filterPincodesAssignment`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'FILTER_RESELLER_SUCCESS', payload: { resellersFiltered: res.data.data } })
                // listAllDistricts?state_id=7
                cb()
            }
        })
        .catch(err => {
            //   dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
export const getPincodesUnderReseller = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        reseller_id: id

    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.post(`${development.baseUrl}getPanchayathUnderReseller`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({ type: 'FILTER_RESELLER_SUCCESS', payload: {resellersFiltered: res.data.data} })
                // listAllDistricts?state_id=7
                cb(res.data.panchayath)
            }
        })
        .catch(err => {
            //   dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { schoolloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const unAssignPincode = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        panchayath_ids: id
    }
    //dispatch({ type: 'VENDORLIST_ALL_REQUEST', payload: { vendorloader: true } })
    axios.post(`${development.baseUrl}unAssignPanchayath`, data, { headers: head })
        .then(res => {
            if (res.data) {
                //  dispatch({ type: 'VENDORLIST_ALL_SUCCESS', payload: { VendorArray: res.data, vendorloader: false } })
                cb()
            }
        })
        .catch(err => {
            //  dispatch({ type: 'VENDORLIST_ALL_FAILED', payload: { vendorloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        text: 'You are logged in to another System'
                    })
                }
            }
        })
}


/////sign up toggle vendor///
export const updateProfileStatus = (token, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { user_id: user_id }
    dispatch({ type: 'VENDOR_ALL_REQUEST' })
    axios.post(`${development.baseUrl}updateProfileStatus`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', })
                cb(res.data.message)
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


/////////////vendor attendence side


export const populateMyCourseStudent = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.get(`${development.baseUrl}populateMyCourse`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { student_course: res.data.courses } })
                // listAllDistricts?state_id=7
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const createCourseCategories = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        // onUploadProgress: (progressEvent) => {
        // const { loaded, total } = progressEvent;
        // let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        //    dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        // },
    };


    // debugger
    //dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorcreate: true } })

    axios.post(`${development.baseUrl}createGrade`, data, options)
        .then(res => {
            if (res.data.result) {
                //  dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorcreate: false } })
                cb()//to open success popup
                //  cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            //   dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorcreate: false, files: [] } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        //   var val = Object.values(err.response.data.errors)
                        //  cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const populateCourseCategories = (token, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.get(`${development.baseUrl}listAllGrade`, { headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { student_course: res.data.courses} })
                // listAllDistricts?state_id=7
                cb(res.data)
            }
        })
        .catch(err => {
            // dispatch({ type: 'VENDOR_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const editCategory = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        grade_id: id
    }
    // debugger
    // dispatch({ type: 'STATE_ALL_REQUEST'})
    axios.post(`${development.baseUrl}editGrade`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { student_course: res.data.courses} })
                // listAllDistricts?state_id=7
                cb(res.data)
            }
        })
        .catch(err => {
            // dispatch({ type: 'VENDOR_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const updateCourseCategories = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        // onUploadProgress: (progressEvent) => {
        //     const { loaded, total } = progressEvent;
        //     let percent = Math.floor((loaded * 100) / total);
        //     // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
        //   //  dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        // },
    };


    // debugger
    //dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorcreate: true } })

    axios.post(`${development.baseUrl}updateGrade`, data, options)
        .then(res => {
            if (res.data.result) {
                //  dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorcreate: false } })
                cb(res.data.message)
                //  cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            //   dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorcreate: false, files: [] } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        //   var val = Object.values(err.response.data.errors)
                        //  cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
export const deleteCategory = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        grade_id: id
    }


    // debugger
    //dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorcreate: true } })

    axios.post(`${development.baseUrl}deleteGrade`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                //  dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorcreate: false } })
                cb(res.data.message)//to open success popup
                //  cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            //   dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorcreate: false, files: [] } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        //   var val = Object.values(err.response.data.errors)
                        //  cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


/////////super admin setings curreny


export const updateExchangeRate = (token, currency_id, rate, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { currency_id: currency_id, exchange_rate: rate }
    dispatch({ type: 'VENDOR_ALL_REQUEST' })
    axios.post(`${development.baseUrl}updateExchangeRate`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VENDOR_ALL_SUCCESS', })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const ListCurrency = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'VENDOR_ALL_REQUEST' })
    axios.get(`${development.baseUrl}listAllCurrencies`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: "VENDOR_ALL_SUCCESS", payload: {
                        currency_list: res.data.currencies ? res.data.currencies : [],
                    }
                });
            }
        })
        .catch(err => {
            dispatch({ type: 'VENDOR_ALL_FAILED', })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const fillterPanchayat = (token, id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'SCHOOL_ALL_REQUEST', payload: { panchayath_loader: true } })
    axios.get(`${development.baseUrl}listAllPanchayath?district_id=${id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_ALL_SUCCESS', payload: { panchayath_loader: false } })
                dispatch({ type: 'PANCHAYATH_ALL_SUCCESS', payload: { panchayath: res.data.panchayath } })
                // listAllDistricts?state_id=7
            }
            else {
                dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { panchayath_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'SCHOOL_ALL_FAILED', payload: { panchayath_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',

                })
            }
        })
}

export const addUserManual = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS_MANUAL', payload: { percetage_manual: percent } })
        },
    };


    // debugger
    //dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorcreate: true } })

    axios.post(`${development.baseUrl}addUserManual`, data, options)
        .then(res => {
            if (res.data.result) {
                //  dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorcreate: false } })
                cb(res.data.message)
                //  cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            //   dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorcreate: false, files: [] } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        //   var val = Object.values(err.response.data.errors)
                        //  cb3(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

////////////////testimonila

export const createTestimonial = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { testimony: true } })

    axios.post(`${development.baseUrl}createTestimonial`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { testimony: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { testimony: false, } })
            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const listAllTestimonial = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { testimonyloader: true } })
    axios.get(`${development.baseUrl}listAllTestimonial`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        TestimonyData: res.data ? res.data.testimonials ? res.data.testimonials : [] : [],
                        testimonyloader: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { testimonyloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}



export const editTestimonial = (token, testimonial_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        testimonial_id: testimonial_id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}editTestimonial`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {}
                })
                cb(res.data.testimonial)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const deleteTestimonial = (token, testimonial_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        testimonial_id: testimonial_id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { testimonyloader: true } })
    axios.post(`${development.baseUrl}deleteTestimonial`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: { testimonyloader: false }
                })
                cb()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { testimonyloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const updateTestimonial = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { testimony: true } })

    axios.post(`${development.baseUrl}updateTestimonial`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { testimony: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { testimony: false, } })
            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const listAllRefunds = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'REFUND_ALL_REQUEST', payload: { Refundloader: true } })
    axios.get(`${development.baseUrl}listAllRefunds`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'REFUND_ALL_SUCCESS', payload: {
                        RefundData: res.data.refunds, Refundloader: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'REFUND_ALL_FAILED', payload: { Refundloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const listAllPaymentsForRefunds = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'REFUND_PENDING_REQUEST', payload: { Pendingloader: true } })
    axios.get(`${development.baseUrl}listAllPaymentsForRefunds`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'REFUND_PENDING_SUCCESS', payload: {
                        RefundPending: res.data.payments, Pendingloader: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'REFUND_PENDING_FAILED', payload: { Pendingloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const createRefund = (token, order_id, amount, cb, cb2) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        order_id: order_id,
        refund_amount: amount
    }

    axios.post(`${development.baseUrl}createRefund`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    text: res.data.message
                })
                cb()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
                cb2()
            }
        })
        .catch(err => {
            // dispatch({ type: 'VENDOR_ALL_FAILED',})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


///////////gallery
export const createGallery = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { gallery: true } })

    axios.post(`${development.baseUrl}createGallery`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { gallery: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'error'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { gallery: false, } })
            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const addGalleryDetails = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { gallery: true } })

    axios.post(`${development.baseUrl}addGalleryDetails`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { gallery: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: 0 } })

                Swal.fire({
                    icon: 'error',
                    text: 'error'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { gallery: false, } })
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: 0 } })

            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const listAllGalleries = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { galleryloader: true } })
    axios.get(`${development.baseUrl}listAllGalleries`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        Gallerydata: res.data ? res.data.galleries ? res.data.galleries : [] : [],
                        galleryloader: false,
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { galleryloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const getGalleryDetails = (token, id, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { gallerydetailsloader: true } })
    axios.get(`${development.baseUrl}getGalleryDetails?gallery_id=${id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        Gallery_Array_details: res.data.galleries.data,
                        gallery_first_page_url: res.data.galleries.prev_page_url,
                        gallery_next_page_url: res.data.galleries.next_page_url,
                        from_gal_details: res.data.galleries.from,
                        to_gal_details: res.data.galleries.to,
                        gallerydetailsloader: false,
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { gallerydetailsloader: false } })
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { gallerydetailsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const editGallery = (token, gallery_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        gallery_id: gallery_id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}editGallery`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {}
                })
                cb(res.data.gallery)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const updateGallery = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { galleryloaderupdate: true } })

    axios.post(`${development.baseUrl}updateGallery`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { galleryloaderupdate: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { galleryloaderupdate: false, } })
            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const deleteGallery = (token, gallery_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        gallery_id: gallery_id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { galleryloader: true } })
    axios.post(`${development.baseUrl}deleteGallery`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: { galleryloader: false }
                })
                cb()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { galleryloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const deleteGalleryMedia = (token, id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        id: id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { gallerydetailsloader: true } })
    axios.post(`${development.baseUrl}deleteGalleryMedia`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: { gallerydetailsloader: false }
                })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { gallerydetailsloader: false } })
                Swal.fire({
                    icon: 'error',
                    // title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { gallerydetailsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
///////////////////edit course requested tables/////////////
export const getEditRequestedCourses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'PENDING_COURSE_REQUEST', payload: { pendingloader: true } })
    axios.get(`${development.baseUrl}getEditRequestedCourses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'PENDING_COURSE_SUCCESS', payload: {
                        EditPendingCourseArray: res.data.courses,
                        editpendingloader: false
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'PENDING_COURSE_FAILED', payload: { pendingloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getEditApprovalPendingCourses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ACTIVE_COURSE_REQUEST', payload: { activeloader: true } })
    axios.get(`${development.baseUrl}getEditedCourses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'ACTIVE_COURSE_SUCCESS', payload: {
                        EditApprovedCourseArray: res.data.courses,
                        EditApprovedloader: false
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'ACTIVE_COURSE_FAILED', payload: { activeloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

//////////////////////news//////////////////////////////
export const listAllNews = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { newsloader: true } })
    axios.get(`${development.baseUrl}listAllNews`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        Newsdata: res.data ? res.data.news ? res.data.news : [] : [],
                        newsloader: false,
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { newsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const createNews = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { news: true } })

    axios.post(`${development.baseUrl}createNews`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { news: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'error'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { news: false, } })
            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const editNews = (token, news_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        news_id: news_id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}editNews`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {}
                })
                cb(res.data.news)
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Something Went Wrong',
                            // text: 'You are logged in to another System'
                        })
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const updateNews = (token, data, cb) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS', payload: { percetage: percent } })
        },
    };


    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { newsloaderupdate: true } })

    axios.post(`${development.baseUrl}updateNews`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { newsloaderupdate: false } })
                cb()//to open success popup
                // cb2()//to reset form
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: 'errr'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { newsloaderupdate: false, } })
            if (err) {
                if (err.response) {
                    // if (err.response.status === 422) {
                    //     var val = Object.values(err.response.data.errors)
                    //     cb3(val)
                    // }
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const deleteNews = (token, news_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        news_id: news_id
    }
    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { newsloader: true } })
    axios.post(`${development.baseUrl}deleteNews`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: { newsloader: false }
                })
                cb()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { newsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

///////////////teacher invoice download/////////////
export const exportTeacherAttendance = (token, class_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { class_id: class_id }
    dispatch({ type: 'SALES_ALL_REQUEST', payload: { teacherinvoiceloader: true } })
    axios.post(`${development.baseUrl}exportTeacherAttendance`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SALES_ALL_SUCCESS', payload: { teacherinvoiceloader: false } })
                cb(res.data.url)
            }
        })
        .catch(err => {
            dispatch({ type: 'SALES_ALL_FAILED', payload: { teacherinvoiceloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

////////////view student list of each courses in vendor side////////
export const getCoursePurchasedStudent = (token, course_id, page) => (dispatch) => {
    const header = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    };
    const data = {
        course_id: course_id, page: page
    }
    //console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: "STUDENT_ALL_REQUEST", payload: { purchased_student_loader: true } });
    axios
        .post(`${development.baseUrl}getCoursePurchasedStudent`, data, { headers: header, })

        .then((res) => {
            //  dispatch({ type: 'POPULATE_STUDENT_SUCCESS', payload: res.data })
            if (res.data) {
                dispatch({
                    type: "STUDENT_ALL_SUCCESS", payload: {
                        purchased_student_loader: false,
                        purchased_list: res.data.data,
                        next_page_student: res.data.next_page_url,
                        prev_page_student: res.data.prev_page_url,
                        from_student: res.data.from,
                        to_student: res.data.to,

                    }
                });
            }
        })
        .catch((err) => {
            dispatch({ type: "STUDENT_ALL_FAILED", payload: { error: err, purchased_student_loader: false } });
        });
};

//////////////scheduling course list////////////////////
export const getReschedulingCourses = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'ACTIVE_COURSE_REQUEST', payload: { activeloader: true } })
    axios.get(`${development.baseUrl}getReschedulingCourses`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'ACTIVE_COURSE_SUCCESS', payload: {
                        ReschedulingCourseArray: res.data.courses,
                        activeloader: false
                    }
                })
            }
            else {
                dispatch({ type: 'ACTIVE_COURSE_FAILED', payload: { activeloader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'ACTIVE_COURSE_FAILED', payload: { activeloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const ReorderVideoClass = (token, items, course_id, subscription_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let order = []
    items.forEach(element => {
        order.push(element.video_class_id)
    });
    let data
    if (subscription_id) {
        data = {
            reorder_class: order,
            subscription_id: subscription_id
        }
    }
    else {
        data = {
            reorder_class: order,
            course_id: course_id
        }
    }

    // debugger
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}reorderVideoClass`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {}
                })
                cb()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const addOrUpdateAnalatics = (token, video_class_id, percent, cb, start_from) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        percentage: percent,
        video_class_id: video_class_id,
        video_stopped_at: start_from
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}addOrUpdateAnalatics`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {}
                })
                cb(percent)
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

export const addOrUpdateAnalatics2 = (token, video_class_id, percent, start_from) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        percentage: percent,
        video_class_id: video_class_id,
        video_stopped_at: start_from
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}addOrUpdateAnalatics`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {}
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}
//////////////////techer submit when block////////
export const blockTeacherAndMigrateClass = (token, teacher_id, assigned_teacher_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data
    if (assigned_teacher_id) {
        data = {
            teacher_id: teacher_id,
            assigned_teacher_id: assigned_teacher_id
        }
    }
    else {
        data = {
            teacher_id: teacher_id,
        }
    }

    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { blockteacher: true } })
    axios.post(`${development.baseUrl}blockTeacherAndMigrateClass`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { blockteacher: false } })
                // Swal.fire({
                //     icon: 'success',               
                //     text: res.data.message
                // })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { blockteacher: false } })
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { blockteacher: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const addTeacherAndMigrateClass = (token, data, cb, cb2) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // const data={
    //     id:image_id
    // }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { createteacher_block_loader: true } })
    axios.post(`${development.baseUrl}addTeacherAndMigrateClass`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { createteacher_block_loader: false } })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { createteacher_block_loader: false } })
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { createteacher_block_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb2(val)
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

////////slider image upload///////////////

export const AddSliderImages = (token, data, cb, cb2) => (dispatch) => {
    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },


        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            dispatch({ type: 'UPLOAD_PROGRESS_MANUAL', payload: { sliderprogress: percent } })
        },
    };

    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { sliderloader: true } })
    axios.post(`${development.baseUrl}addSlideImages`, data, options)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { sliderloader: false } })
                cb(res.data.message)
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { sliderloader: false } })

                Swal.fire({
                    icon: 'error',
                    text: res.data.message ? res.data.message : 'failed,tri again'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { sliderloader: false } })
            dispatch({ type: 'UPLOAD_PROGRESS_MANUAL', payload: { sliderprogress: null } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)
                        cb2(val)
                    }
                }
                if (err.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Token Expired',
                        // text: 'Token Expired'
                    })
                    window.location.reload()
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

/////////////////list slider images///////////
export const getSlideImages = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.get(`${development.baseUrl}getSlideImages`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        slider_images: res.data.details ? res.data.details : []
                    }
                })

            }
        })
        .catch(err => {
            // dispatch({ type: 'VENDOR_ALL_FAILED',})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

////////////////session logout/////////////
export const logoutSession = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: {} })
    axios.get(`${development.baseUrl}logoutSession`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: {} })
            }
        })
        .catch(err => {
            // dispatch({ type: 'VENDOR_ALL_FAILED',})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

///////remove slider image /////
export const deleteSlideImage = (token, image_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        id: image_id
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { slidedelete: true } })
    axios.post(`${development.baseUrl}deleteSlideImages`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { slidedelete: false } })
                Swal.fire({
                    icon: 'success',
                    // title: 'Something Went Wrong',
                    text: res.data.message ? res.data.message : 'success'
                })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { slidedelete: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { slidedelete: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

//////////////////////generate payment link////////////////
export const generatePaymentLink = (token, course_id, group_id, referral_code, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        course_id: course_id,
        group_id: group_id,
        referral_code: referral_code
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { generatelink_loader: true } })
    axios.post(`${development.baseUrl}generatePaymentLink`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { generatelink_loader: false } })
                cb(res.data)
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { generatelink_loader: false } })
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { generatelink_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

//////////////////tags//////////////
export const deleteTag = (token, tag_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        tag_id: tag_id
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_loader: true } })
    axios.post(`${development.baseUrl}deleteTag`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { tag_loader: false } })
                Swal.fire({
                    icon: 'success',
                    text: res.data.message ? res.data.message : 'success'
                })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { tag_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const listAllTags = (token,) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_loader: true } })
    axios.get(`${development.baseUrl}listAllTags`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        tag_loader: false,
                        tag_array: res.data.tags
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { tag_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const createTag = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data",
    }
    // const data = {
    //     tag_name: tag_name,
    //     category_id: tag_category
    // }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_add_loader: true } })
    axios.post(`${development.baseUrl}createTagLocally`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { tag_add_loader: false } })
                Swal.fire('Success', 'Tag Created Successfully', 'success')
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_add_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { tag_add_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

export const updateTag = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        "content-type": "multipart/form-data"
    }
    // const data = {
    //     tag_id: tag_id,
    //     tag_name: tag_name,
    //     category_id: tag_category
    // }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_add_loader: true } })
    axios.post(`${development.baseUrl}updateTagLocally`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'TESTMONY_ALL_SUCCESS', payload: { tag_add_loader: false } })
                Swal.fire({
                    icon: 'success',
                    text: res.data.message ? res.data.message : 'success'
                })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { tag_add_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { tag_add_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const listAllTagsByFilter = (token, type) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'TESTMONY_ALL_REQUEST', })
    axios.get(`${development.baseUrl}listAllTags?category_id=${type}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                let arr = res.data.tags ? res.data.tags : []
                arr.forEach((val) => {
                    val.value = val.tag_id
                    val.label = val.tag_name
                })
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        tag_filtered_array: arr
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_REQUEST', })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}


export const getAllSubscriptionPurchasedStudent = (token, subscription_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        subscription_id: subscription_id,
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { purchased_loader: true } })
    axios.post(`${development.baseUrl}getAllSubscriptionPurchasedStudent`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        StudentList: res.data.students ? res.data.students : [],
                        coursename: res.data.subscription_details ? res.data.subscription_details.subscription_name : '',
                        ondemand_video_enabled: res.data.subscription_details ? res.data.subscription_details.ondemand_enabled : false,
                        purchased_loader: false
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { purchased_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { purchased_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}
export const getAllCoursePurchasedStudent = (token, course_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        course_id: course_id,
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { purchased_loader: true } })
    axios.post(`${development.baseUrl}getAllCoursePurchasedStudent`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        StudentList: res.data.students ? res.data.students : [],
                        coursename: res.data.course_details ? res.data.course_details.course_name : '',
                        ondemand_video_enabled: res.data.course_details ? res.data.course_details.ondemand_enabled : false,
                        purchased_loader: false
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { purchased_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { purchased_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

export const getStudentOndemandVideoClasses = (token, course_id, student_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        course_id: course_id,
        student_id
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { ondemand_loader: true } })
    axios.post(`${development.baseUrl}getStudentOndemandVideoClasses`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        OndemandDetails: res.data.video_class_details ? res.data.video_class_details : [],
                        ondemand_loader: false
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { ondemand_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { ondemand_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

export const getStudentSubscriptionOndemandVideoClasses = (token, subscription_id, student_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        subscription_id: subscription_id,
        student_id
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { ondemand_loader: true } })
    axios.post(`${development.baseUrl}getStudentSubscriptionOndemandVideoClasses`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        OndemandDetails: res.data.video_class_details ? res.data.video_class_details : [],
                        ondemand_loader: false
                    }
                })
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { ondemand_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { ondemand_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

export const resetPassword = (token, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { reset_loader: true } })
    axios.get(`${development.baseUrl}resetPassword`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        reset_loader: false
                    }
                })
                cb(true)
            }
            else {
                cb(false)
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { reset_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { reset_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

export const addSocialMediaLinks = (token, data_social, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    data_social.forEach((val) => {
        if (!val.link) {
            val.link = '#'
        }
    })
    let data = {
        social_media_settings: data_social
    }
    dispatch({ type: 'TESTMONY_ALL_REQUEST', payload: { social_loader: true } })
    axios.post(`${development.baseUrl}addSocialMediaLinks`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'TESTMONY_ALL_SUCCESS', payload: {
                        social_loader: false
                    }
                })
                cb()
            }
            else {
                dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { social_loader: false } })
            }
        })
        .catch(err => {
            dispatch({ type: 'TESTMONY_ALL_FAILED', payload: { social_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                })
            }
        })
}

export const disableSubscription = (token, type,user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { userIds: [user_id] }
    // debugger
  //  dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorloader: true } })
    axios.post(`${development.baseUrl}disableSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
              //  dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
              //  dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
                Swal.fire({
                    text: res.data.message,
                    icon: 'warning'
                })
            }
        })
        .catch(err => {
         //   dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}
export const enableSubscription = (token, type, user_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { userIds: [user_id] }

    // debugger
   // dispatch({ type: 'VENDOR_ALL_REQUEST', payload: { vendorloader: true } })
    axios.post(`${development.baseUrl}enableSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
               // dispatch({ type: 'VENDOR_ALL_SUCCESS', payload: { vendorloader: false } })
                cb(res.data.message)//to open success popup
            }
            else {
              //  dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
                Swal.fire({
                    text: res.data.message,
                    icon: 'warning'
                })
            }
        })
        .catch(err => {
          //  dispatch({ type: 'VENDOR_ALL_FAILED', payload: { vendorloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}