import React,{useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {
    FormFeedback,Input  
  } from "reactstrap";

import { useDispatch, useSelector } from 'react-redux'
import {rejectCourse,setReqData,getVendorSingleCoursedetails,rejectEditRequest,rejectEditedCourseRequest} from "../../../../redux/Actions/Superadmin"
// import SweetAlert from 'sweetalert-react';
import { useParams } from 'react-router-dom'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import {requiredValidator,} from "../../../../utilities/Validator";
import Swal from "sweetalert2";
function Rejectcourse() {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const params = useParams()

    const rejected = useSelector((state) =>state.superadmin.reject_reasons ? state.superadmin.reject_reasons : []);
    const reject_loader = useSelector((state) =>state.superadmin.reject_loader ? state.superadmin.reject_loader : false);
    // const rejectsuccess = useSelector((state) =>state.superadmin.rejectsuccess ? state.superadmin.rejectsuccess : false);
    const SingleCourseDetail = useSelector((state) =>state.superadmin.SingleCourseDetail ? state.superadmin.SingleCourseDetail : []);

    
  const [checked, setChecked] = React.useState({
      banner:rejected[0] ? rejected[0].selected ? rejected[0].selected:false:false,
      video:rejected[2] ? rejected[2].selected ? rejected[2].selected:false:false,
      fees:rejected[1] ? rejected[1].selected ? rejected[1].selected:false:false,
      coursedetails:rejected[4] ? rejected[4].selected ? rejected[4].selected:false:false,
      coursedescription:rejected[5] ? rejected[5].selected ? rejected[5].selected:false:false,
      ondemand:rejected[3] ? rejected[3].selected ? rejected[3].selected:false:false,
  });

  const handleChange = (event) => {
    //   const {name,checked }=event.target
      setChecked({ ...checked, [event.target.name]: event.target.checked });
    };

    const [inputField, setInput] = React.useState({
        banner:rejected[0] ? rejected[0].reason ? rejected[0].reason:'':'',
        video:rejected[2] ? rejected[2].reason ? rejected[2].reason:'':'',
        fees:rejected[1] ? rejected[1].reason ? rejected[1].reason:'':'',
        coursedetails:rejected[4] ? rejected[4].reason ? rejected[4].reason:'':'',
        coursedescription:rejected[5] ? rejected[5].reason ? rejected[5].reason:'':'',
        ondemand:rejected[3] ? rejected[3].reason ? rejected[3].reason:'':'',
        aditional:''
    });

    const handleChangeInput = (event) => {
        //   const {name,checked }=event.target
        setInput({ ...inputField, [event.target.name]: event.target.value });
        };

    const [errors, setErrors] = useState({});
    const Submit=()=>{
        if(SingleCourseDetail.request_status==="approved" && SingleCourseDetail.edit_status===0 && SingleCourseDetail.edit_request_status==='requested' ){
            let err={
                coursedescription: requiredValidator(inputField.coursedescription) && checked.coursedescription ? "*Required":'',
            }
            if(!err.coursedescription){
                setErrors(err);
                let arrr=[]

                let additional={}
                    additional.reason=inputField.aditional
                    additional.selected=true
                    additional.type='additional_remarks'
                    arrr.push(additional)
                    let course_id = params.course_id

                dispatch(rejectEditRequest(tokenData.token,arrr,course_id,handleOpenSuccessModalreason))
            }else{
                setErrors(err);
            }
        }
        else{
            if(checked.banner || checked.video || checked.ondemand || checked.coursedetails || checked.fees || checked.coursedescription ||inputField.aditional){
                const err = {
                    banner: requiredValidator(inputField.banner) && checked.banner ? "*Required":'',
                    fees: requiredValidator(inputField.fees) && checked.fees ? "*Required":'',
                    video: requiredValidator(inputField.video) && checked.video ? "*Required":'',
                    ondemand: requiredValidator(inputField.ondemand) && checked.ondemand ? "*Required":'',
                    coursedetails: requiredValidator(inputField.coursedetails) && checked.coursedetails ? "*Required":'',
                    coursedescription: requiredValidator(inputField.coursedescription) && checked.coursedescription ? "*Required":'',
                };
            
                if (
                    !err.banner &&
                    !err.fees &&
                    !err.video &&
                    !err.ondemand &&
                    !err.coursedetails &&
                    !err.coursedescription 
                
                ){
                    setErrors(err);
                    let arr=[]
                    let banner={}
                    banner.reason=inputField.banner
                    banner.selected=checked.banner
                    banner.type='banner'
                    arr.push(banner)

                    let fees={}
                    fees.reason=inputField.fees
                    fees.selected=checked.fees
                    fees.type='fees'
                    arr.push(fees)

                    let preview_video={}
                    preview_video.reason=inputField.video
                    preview_video.selected=checked.video
                    preview_video.type='preview_video'
                    arr.push(preview_video)

                    let ondemand_video={}
                    ondemand_video.reason=inputField.ondemand
                    ondemand_video.selected=checked.ondemand
                    ondemand_video.type='ondemand_video'
                    arr.push(ondemand_video)

                    let course_details={}
                    course_details.reason=inputField.coursedetails
                    course_details.selected=checked.coursedetails
                    course_details.type='course_details'
                    arr.push(course_details)

                    let course_description={}
                    course_description.reason=inputField.coursedescription
                    course_description.selected=checked.coursedescription
                    course_description.type='course_description'
                    arr.push(course_description)


                    let additional={}
                    additional.reason=inputField.aditional
                    additional.selected=true
                    additional.type='additional_remarks'
                    arr.push(additional)

                    let course_id = params.course_id

                    // console.log(checked)
                    // console.log(inputField)
                    if(SingleCourseDetail.request_status==="approved" && SingleCourseDetail.edit_status===1 && SingleCourseDetail.edited_request_status==='requested' ){
                        dispatch(rejectEditedCourseRequest(tokenData.token,arr,course_id,handleOpenSuccessModalreason))
                    }
                    else{
                        dispatch(rejectCourse(tokenData.token,arr,course_id,handleOpenSuccessModalreason))
                    }
                }
                else{
                    setErrors(err);
                    console.log(err)
                }
            }
            else{
                Swal.fire({
                    icon: 'warning',
                    title: 'Specify Atleast One Reason!',
                })
            }    
        }
    }
    
    
// const [successModalreject, setSuccessModalreject] = useState(false);

const handleOpenSuccessModalreason = () => {
    dispatch(getVendorSingleCoursedetails(tokenData.token, params.course_id))

    dispatch(setReqData('reject_reasons_model',false))
    dispatch(setReqData('successreject',true))

    // setSuccessModalreject(true)

}
// const handleCloseSuccesstModalreason = () => {
//     dispatch(setReqData('reject_reasons_model',false))
//     setSuccessModalreject(false)

// }

  return (
    <>
    <Grid container>

        {
            SingleCourseDetail.request_status==="approved" && SingleCourseDetail.edit_status===0 && SingleCourseDetail.edit_request_status==='requested' ? 
            <>
                <Grid item xs={12}>Additional Remarks</Grid>

                <Grid item xs={12}>
                    <Input
                    type="textarea"
                    rows={2}
                    style={{ width: "100%" }}
                    name='aditional'
                    value={inputField.aditional}
                    onChange={handleChangeInput}
                    />
                </Grid>
            </>
            :
            <>
        <Grid item xs={12}>Media Content</Grid>
            <Grid item xs={5}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checked.banner}
                        onChange={handleChange}
                        name="banner"
                        color="primary"
                    />
                    }
                    label="Course Logo"
                />
            </Grid>
            <Grid item xs={7}>     
            {
                checked.banner===true ?
                <>
                <Input
                    type="textarea"
                    name="banner"
                    rows={1}
                    invalid={errors.banner}
                    onChange={handleChangeInput}
                    value={inputField.banner}
                    />
                    <FormFeedback>{errors.banner}</FormFeedback>
                    </>

                    :''
                    
            }
            </Grid>

            <Grid item xs={5}>
                <FormControlLabel
                control={
                <Checkbox
                    checked={checked.video}
                    onChange={handleChange}
                    name="video"
                    color="primary"
                />
                }
                label="Video"
            />

            </Grid>

        <Grid item xs={7}>     
            {
                checked.video===true ?
                <>
                <Input
                    type="textarea"
                    name="video"
                    rows={1}
                    invalid={errors.video}
                    onChange={handleChangeInput}
                    value={inputField.video}
                    />
                    <FormFeedback>{errors.video}</FormFeedback>
                    </>
                    :''
            } 
            </Grid>


            <Grid item xs={5}>
                <FormControlLabel
                control={
                <Checkbox
                    checked={checked.ondemand}
                    onChange={handleChange}
                    name="ondemand"
                    color="primary"
                />
                }
                label="Ondemand Video"
            />
            </Grid>

        <Grid item xs={7}>     
            {
                checked.ondemand===true ?
                <>
                <Input
                    type="textarea"
                    name="ondemand"
                    rows={1}
                    invalid={errors.ondemand}
                    onChange={handleChangeInput}
                    value={inputField.ondemand}
                    />
                    <FormFeedback>{errors.ondemand}</FormFeedback>
                    </>
                    :''
            } 
            </Grid>


            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>Course Content</Grid>

            <Grid item xs={5}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checked.coursedetails}
                        onChange={handleChange}
                        name="coursedetails"
                        color="primary"
                    />
                    }
                    label="Course Details"
                />
            </Grid>
            <Grid item xs={7}>     
            {
                checked.coursedetails===true ?
                <>
                <Input
                    type="textarea"
                    name="coursedetails"
                    rows={1}
                    invalid={errors.coursedetails}
                    onChange={handleChangeInput}
                    value={inputField.coursedetails}
                    />
                    <FormFeedback>{errors.coursedetails}</FormFeedback>
                </>
                    :''
            } 
            </Grid>


            <Grid item xs={5}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={checked.coursedescription}
                        onChange={handleChange}
                        name="coursedescription"
                        color="primary"
                    />
                    }
                    label="Course Description"
                />
            </Grid>
            <Grid item xs={7}>     
            {
                checked.coursedescription===true ?
                <>
                <Input
                    type="textarea"
                    name="coursedescription"
                    rows={1}
                    invalid={errors.coursedescription}
                    onChange={handleChangeInput}
                    value={inputField.coursedescription}
                    />
                    <FormFeedback>{errors.coursedescription}</FormFeedback>
                </>
                    :''
            } 
            </Grid>

            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>Fees</Grid>

            <Grid item xs={5}>
                <FormControlLabel
                control={
                <Checkbox
                    checked={checked.fees}
                    onChange={handleChange}
                    name="fees"
                    color="primary"
                />
                }
                label="Fees"
            />
            </Grid>
            <Grid item xs={7}>     
            {
                checked.fees===true ?
                <>
                <Input
                    type="textarea"
                    name="fees"
                    rows={1}
                    onChange={handleChangeInput}
                    value={inputField.fees}
                    invalid={errors.fees}
                    />
                    <FormFeedback>{errors.fees}</FormFeedback>
                </>
                    :''
            } 
            </Grid>

            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>Additional Remarks</Grid>

            <Grid item xs={12}>
                <Input
                type="textarea"
                rows={2}
                style={{ width: "100%" }}
                name='aditional'
                value={inputField.aditional}
                onChange={handleChangeInput}
                />
            </Grid>
            </>
        }
        

        <Grid item xs={12} style={{marginTop:'5px'}}>
        <LaddaButton
            className="mb-2 mr-2 btn btn-danger"
            loading={reject_loader}
            type="button"
            onClick={() => Submit()}
            data-style={ZOOM_IN}
            // disabled={checked.banner || checked.video || checked.ondemand || checked.coursedetails || checked.fees || checked.coursedescription}
            >
            Submit
        </LaddaButton>
            {/* <Button className="mb-2 mr-2 btn-hover-shine" color="danger" onClick={Submit}>Submit</Button> */}
        </Grid>
    </Grid>


    {/* <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModalreject}
        text="Submitted successfully"
        type="success"
        onConfirm={handleCloseSuccesstModalreason} /> */}
    </>
  );
}
export default Rejectcourse;
