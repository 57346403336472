import React, { useEffect, useState } from 'react'
import { useLocation, useHistory, Redirect } from "react-router-dom";

function FirstPaymentStatus() {
      const location = useLocation();
    const history = useHistory()

    console.log("window ljm", window.location.href)

    // const [HashedRoute, setHashedRoute] = useState(null)

    useEffect(() => {
        let url = window.location.href
        console.log("gaf", location)
        var link = document.createElement("a");
        link.href = url.replace("?", "#/payment-status-conformation-subscription/?");
        // link.href = "http://localhost:3000/#/payment-status/?enc_status=Success&enc_orderid=SKL-CAN-MOTS-1632482882797&enc_trackingid=310007582003&enc_RegId=CAN18A00115&enc_entityId=186&enc_transdate=24%2F09%2F2021+16%3A59%3A01&enc_amount=100.00&enc_mots_order_id=327#/payment-status-1/http://localhost:3000/?enc_status=Success&enc_orderid=SKL-CAN-MOTS-1632482882797&enc_trackingid=310007582003&enc_RegId=CAN18A00115&enc_entityId=186&enc_transdate=24%2F09%2F2021+16%3A59%3A01&enc_amount=100.00&enc_mots_order_id=327#/payment-status-1/";
        //  link.target = "_blank" opens in new tab
        link.click();
        link.remove();

    }, [])


    return (
        <div className='w-100 h-100 d-flex justify-content-center align-items-center flex-column' >
            <div style={{fontSize:'20pt'}} className='font-weight-bold' >Payment is processing dont close or refresh this page</div>
            <div class="spinner-border mt-2"  role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        // <Redirect to={"payment-status/?enc_status=Success&enc_orderid=SKL-CAN-MOTS-1632482882797&enc_trackingid=310007582003&enc_RegId=CAN18A00115&enc_entityId=186&enc_transdate=24%2F09%2F2021+16%3A59%3A01&enc_amount=100.00&enc_mots_order_id=327#/payment-status-1/http://localhost:3000/?enc_status=Success&enc_orderid=SKL-CAN-MOTS-1632482882797&enc_trackingid=310007582003&enc_RegId=CAN18A00115&enc_entityId=186&enc_transdate=24%2F09%2F2021+16%3A59%3A01&enc_amount=100.00&enc_mots_order_id=327#/payment-status-1/"} />


    )
}

export default FirstPaymentStatus
