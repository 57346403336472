export default function GenexhomeReducer(state={},action)

{
    switch (action.type) {
        case "SET_HOME_DATA": {
          return { ...state, ...action.payload };
        }


      case "HOME_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "HOME_ALL_SUCCESS": {
        return { ...state, ...action.payload }
      }
      case "HOME_ALL_FAILED": {
        return { ...state,...action.payload}
      }

        default: {
            return { ...state }
          }
    }

}