import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {google} from '../../../../config.json'
import { Room } from '@material-ui/icons'

const AnyReactComponent = ({ text }) =><Room  style={{color:'red', opacity:'1'}} />;

//console.log("google",google.apiKey)



class SimpleMap extends Component {


  

  static defaultProps = {
    center: {
      lat: 12.9629692,
      lng: 77.7196124
    },
    zoom: 16
  };

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: google.apiKey}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          <AnyReactComponent
            lat={12.9629692}
            lng={77.7196124}
            text="skillablers"
          />
      
        </GoogleMapReact>
      </div>
    );
    
  }
}

export default SimpleMap;