import React, { Fragment } from 'react';

import { useSelector } from "react-redux";

import Header from '../../header';
import SingleTag from './SingleTag'
import Footer from "../../HomePage/Footer/Footer"

import CircularProgress from "@material-ui/core/CircularProgress";

import '../../css/animate.css';
import '../../css/elegant_icon.css';
import "../../css/genex-custom.css"
import '../../css/hover-min.css';
import "../../css/style.css"
import "../../css/style.css.map"
import "../../css/swiper.css"


const Index = () => {
	const Menu_section = useSelector((state) =>state.genexhome.Menu_section ? state.genexhome.Menu_section : "");

	return (
		<Fragment>
			<Header/>

			{
				Menu_section ?
				<>

					<SingleTag/>
					<Footer />
				</>
				:
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						minHeight: "100vh",
					}}
        		>
					<CircularProgress />
				</div>
			}

		</Fragment>
	);
}
export default Index;