import axios from 'axios'
import { development } from '../../config.json'
import Swal from 'sweetalert2';

export const getGradeList = (id, category_id) =>
    (dispatch) => {
        // console.log("resdddd", id)

        const data = {
            degree_id: id,
            category_id: category_id
        }
        dispatch({ type: 'GRADE_REQUEST' })
        axios.post(`${development.baseUrl}filterGrade`, data)
            .then(res => {
                if (typeof id === 'undefined' || id == null)
                    dispatch({ type: 'GRADE_SUCCESS_SCHOOL', payload: res.data })
                else
                    dispatch({ type: 'GRADE_SUCCESS_COLLEGE', payload: res.data })

                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'GRADE_FAILED', payload: err })
            })
    }

export const getGradeListLifeLong = (id, category_id, cb) =>
    (dispatch) => {
        // console.log("resdddd", id)

        const data = {
            degree_id: id,
            category_id: category_id
        }
        dispatch({ type: 'GRADE_SUCCESS_LIFE_LONG_REQUEST' })
        axios.post(`${development.baseUrl}filterGrade`, data)
            .then(res => {


                dispatch({ type: 'GRADE_SUCCESS_LIFE_LONG_SUCCESS', payload: res.data })
                cb(res.data.grades)
            })
            .catch(err => {
                dispatch({ type: 'GRADE_SUCCESS_LIFE_LONG_FAILED', payload: err })
            })
    }


export const populateDegree = () =>

    (dispatch) => {


        dispatch({ type: 'DEGREE_REQUEST' })
        axios.get(`${development.baseUrl}populateDegree`)
            .then(res => {
                dispatch({ type: 'DEGREE_SUCCESS', payload: res.data })
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'DEGREE_FAILED', payload: err })
            })
    }
export const requestOtp = (mobile,type,codevalue,first_name,email) =>

    (dispatch) => {
        let code=mobile
        const data = {}
        if(type==='email'){
            data.first_name=first_name
            data.email=email
            data.type=type
        }
        else{
            data.mobile=code
            data.type=type
        }

        dispatch({ type: 'OTP_REQUEST' })
        axios.post(`${development.baseUrl}requestOtp`, data)

            .then(res => {
                dispatch({ type: 'OTP_RECEIVED', payload: res.data })
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'OTP_NOT_RECIEVED', payload: err })
            })
    }
export const requestOtpSkillablers = (mobile) =>

    (dispatch) => {
        let code='91'+mobile
        const data = {
            mobile_number: code
        }

        dispatch({ type: 'OTP_REQUEST' })
        axios.post(`${development.baseUrl}sendOtp`, data)

            .then(res => {
                dispatch({ type: 'OTP_RECEIVED', payload: res.data })
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'OTP_NOT_RECIEVED', payload: err })
            })
    }

export const sentEmailSkillablers = (email) =>

    (dispatch) => {
       
        const data = {
            email: email
        }

        dispatch({ type: 'OTP_REQUEST' })
        axios.post(`${development.baseUrl}skill/sendMail`, data)

            .then(res => {
                dispatch({ type: 'OTP_RECEIVED', payload: res.data })
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'OTP_NOT_RECIEVED', payload: err })
            })
    }
export const verifyEMAIL = (mail) =>

    (dispatch) => {

        const data = {
            email: mail
        }
        dispatch({ type: 'EMAIL_VALIDATION_REQUEST' })
        axios.post(`${development.baseUrl}validateUserEmail`, data)

            .then(res => {
                dispatch({ type: 'EMAIL_VALIDATION_SUCCESS', payload: res.data })
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'EMAIL_VALIDATION_FAILED', payload: err })
            })
    }

    export const verifyEMAILSkillablers = (mail) =>

    (dispatch) => {

        const data = {
            email: mail
        }
        dispatch({ type: 'EMAIL_VALIDATION_REQUEST' })
        axios.post(`${development.baseUrl}skill/checkUserEMailId`, data)

            .then(res => {
                console.log("response",res)
                dispatch({ type: 'EMAIL_VALIDATION_SUCCESS', payload: res.data })
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'EMAIL_VALIDATION_FAILED', payload: err })
            })
    }




export const validatingOtp = (mobile, otp,ForeignUser,email, cb) =>

    (dispatch) => {
        // let code='91'+mobile
        const data = {
            // mobile: mobile,
            otp: otp,
            type:ForeignUser ? 'email' : 'mobile'
        }
        if(ForeignUser){
           data.email=email
        }
        else{
            data.mobile=mobile
        }
        dispatch({ type: 'OTP_VALIDATE_REQUEST' })
        axios.post(`${development.baseUrl}validateOtp`, data)
            .then(res => {
                dispatch({ type: 'OTP_VALIDATION_SUCCESS', payload: res.data })

                if (res.data.result) {

                    dispatch({ type: 'OTP_VALIDATION_SUCCESS', payload: res.data })

                    cb()
                }
                else {
                    dispatch({ type: 'OTP_VALIDATION_FAILED' })
                }
                // console.log("res=====", res.data)
            })
            .catch(err => {
                dispatch({ type: 'OTP_VALIDATION_FAILED', payload: err })
            })
    }

export const mobileAvailability = (num, cb) =>

    (dispatch) => {
        const data = {
            mobile: num
        }

        dispatch({ type: 'STUDENT_NUMBER_VALIDATE_REQUEST' })
        axios.post(`${development.baseUrl}validateParentMobile`, data)
            .then(res => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_SUCCESS', payload: res.data })
                if (res.data.result) {
                    cb()
                }
            })
            .catch(err => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_FAILED', payload: err })
            })
    }

export const registerStudent = (data, cb) => (dispatch) => {
    dispatch({ type: 'REGISTER_STUDENT_REQUEST' })
    axios.post(`${development.baseUrl}register`, data)
        .then(res => {
            dispatch({ type: 'REGISTER_STUDENT_SUCCESS' })
            // const tok = {
            //     role: res.data.role,
            //     token: res.data.api_token,
            //     name: res.data.name
            // }
            const tok = {
                result: res.data.result,
                role: res.data.role,
                token: res.data.api_token,
                name: res.data.name,
                emailVerified: res.data.email_verified,
                privilege: res.data.privilege,
                enterpriseUser: res.data.enterprise_user,
                plan_id: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_id : '',
                plan_name: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_name : '',
                class_duration: res.data.plan_details.length > 0 ? res.data.plan_details[0].classe_duration : '',
                number_of_student: res.data.plan_details.length > 0 ? res.data.plan_details[0].number_of_student : '',
                allowed_classes: res.data.plan_details.length > 0 ? res.data.plan_details[0].allowed_classes : ''
            }
            localStorage.setItem('authDetails', JSON.stringify(tok));
            if (res.data.result === true) {
                cb();
            }
            // console.log("local storage", tok)
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_STUDENT_FAILED', payload: err })
        })
}

export const validateTeacherMobile = (num, cb) => (dispatch) => {
    let code
    if(num){
        code='91'+num
    }
    else{
        code=num
    }
    const data = {
        contact_number: code
    }
    dispatch({ type: 'TEACHER_NUMBER_VALIDATE_REQUEST' })
    // axios.post(`${development.baseUrl}validateTeacherMobile`, data)
    axios.post(`${development.baseUrl}checkContactNumberExist`, data)

        .then(res => {
            dispatch({ type: 'TEACHER_NUMBER_VALIDATE_SUCCESS', payload: res.data })
            // console.log("nummmmm", res.data)
            if (res.data.result) {
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'TEACHER_NUMBER_VALIDATE_FAILED', payload: err })
        })
}
export const validateReferralCode = (ref, cb) => (dispatch) => {
    const data = {
        teacher_referral_code: ref
    }
    dispatch({ type: 'VALIDATE_REFFERAL_REQUEST' })
    axios.post(`${development.baseUrl}validateReferralCode`, data)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'VALIDATE_REFFERAL_SUCCESS', payload: res.data })
                cb()
            }
            else {
                dispatch({ type: 'VALIDATE_REFFERAL_SUCCESS', payload: res.data })

            }

            // console.log("VALIDATE_REFFERAL_", res.data)
        })
        .catch(err => {
            dispatch({ type: 'VALIDATE_REFFERAL_FAILED' })
        })
}

export const enquirySubmission = (data, cb) => (dispatch) => {
    dispatch({ type: 'REGISTER_ENQUIRY' })
    axios.post(`${development.baseUrl}sendEnquiryMail`, data)
        .then(res => {
            dispatch({ type: 'ENQUIRY_SUCCESS', payload: res.data })
            if (res.data.result === true) {
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: res.data.message,

                })
                cb();
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: '',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_ENQUIRY_FAILED', payload: err })
        })
}

export const subscribeSite = (email, cb) => (dispatch) => {
    // console.log("local storage", email)
    dispatch({ type: 'REGISTER_SUBSCRIPTION' })
    axios.post(`${development.baseUrl}addSubscription`, email)
        .then(res => {

            // if (res.data.result === true) {
            dispatch({ type: 'SUBSCRIPTION_SUCCESS', payload: res.data.result })
            // }
            // else{
            // dispatch({ type: 'SUBSCRIPTION_SUCCESS', payload: res.data.result })
            // }
        })
        .catch(err => {
            dispatch({ type: 'SUBSCRIPTION_FAILED', payload: err })
        })
}


///////////////////genex///////////////////
// export const studentSignup = (firstname,lastname,email,password,mobile,address,pin,panchayath_id,dob,school,genex_referral_code, cb) => (dispatch) => {
export const studentSignup = (firstname,lastname,email,password,mobile,code,address,type, cb,cb2) => (dispatch) => {    
dispatch({ type: 'REGISTER_STUDENT_REQUEST' })
    let data={first_name:firstname,last_name:lastname,email:email,password:password,contact_number:mobile,std_code:code,address:address,verify_type:type}
    axios.post(`${development.baseUrl}studentSignup`, data)
        .then(res => {
            dispatch({ type: 'REGISTER_STUDENT_SUCCESS' })
            // const tok = {
            //     role: res.data.role,
            //     token: res.data.api_token,
            //     name: res.data.name
            // }
            const tok = {
                result: res.data.result,
                role: res.data.role,
                token: res.data.api_token,
                name: res.data.name,
                emailVerified: res.data.email_verified,
                privilege: res.data.privilege,
                enterpriseUser: res.data.enterprise_user,
                // plan_id: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_id : '',
                // plan_name: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_name : '',
                // class_duration: res.data.plan_details.length > 0 ? res.data.plan_details[0].classe_duration : '',
                // number_of_student: res.data.plan_details.length > 0 ? res.data.plan_details[0].number_of_student : '',
                // allowed_classes: res.data.plan_details.length > 0 ? res.data.plan_details[0].allowed_classes : ''
            }
            localStorage.setItem('authDetails', JSON.stringify(tok));
            if (res.data.result === true) {
                cb();
            }
            // console.log("local storage", tok)
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_STUDENT_FAILED', payload: err })
            if (err.response.status === 422) {
                var val = Object.values(err.response.data.errors)
                cb2(val)
            }
        })
}


export const vendorSignup = (vendorname,email,password,mobile,address,pin, cb) => (dispatch) => {
    dispatch({ type: 'REGISTER_STUDENT_REQUEST' })
    let code='91'+mobile
    let data={vendor_name:vendorname,email:email,password:password,contact_number:code,address:address,pin:pin}
    axios.post(`${development.baseUrl}vendorSignup`, data)
        .then(res => {
            if(res.data.result){
                dispatch({ type: 'REGISTER_STUDENT_SUCCESS' })
                const tok = {
                    result: res.data.result,
                    role: res.data.role,
                    token: res.data.api_token,
                    name: res.data.name,
                    emailVerified: res.data.email_verified,
                    privilege: res.data.privilege,
                    enterpriseUser: res.data.enterprise_user,
                    // plan_id: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_id : '',
                    // plan_name: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_name : '',
                    // class_duration: res.data.plan_details.length > 0 ? res.data.plan_details[0].classe_duration : '',
                    // number_of_student: res.data.plan_details.length > 0 ? res.data.plan_details[0].number_of_student : '',
                    // allowed_classes: res.data.plan_details.length > 0 ? res.data.plan_details[0].allowed_classes : ''
                }
                localStorage.setItem('authDetails', JSON.stringify(tok));

            }
            if (res.data.result === true) {
                cb();
            }
            else{
                dispatch({ type: 'REGISTER_STUDENT_FAILED' })
                Swal.fire({
                    icon: 'error',
                    // title: 'Something Went Wrong',
                    text: res.data.message ? res.data.message:'Failed'
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_STUDENT_FAILED', payload: err })
        })
}

export const schoolSignup = (school_name,email,password,mobile,address,pin,panchayath_id,contact_person,area,cb) => (dispatch) => {
    dispatch({ type: 'REGISTER_STUDENT_REQUEST' })
    let code='91'+mobile
    let p=Number(pin)
    let data = { school_name: school_name, contact_number: code, contact_person: contact_person, email: email, password: password, address: address, area: area, pin: p, panchayath_id:panchayath_id }
    axios.post(`${development.baseUrl}schoolSignUp`, data)
        .then(res => {
            dispatch({ type: 'REGISTER_STUDENT_SUCCESS' })
            // const tok = {
            //     role: res.data.role,
            //     token: res.data.api_token,
            //     name: res.data.name
            // }
            const tok = {
                result: res.data.result,
                role: res.data.role,
                token: res.data.api_token,
                name: res.data.name,
                emailVerified: res.data.email_verified,
                privilege: res.data.privilege,
                enterpriseUser: res.data.enterprise_user,
                // plan_id: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_id : '',
                // plan_name: res.data.plan_details.length > 0 ? res.data.plan_details[0].plan_name : '',
                // class_duration: res.data.plan_details.length > 0 ? res.data.plan_details[0].classe_duration : '',
                // number_of_student: res.data.plan_details.length > 0 ? res.data.plan_details[0].number_of_student : '',
                // allowed_classes: res.data.plan_details.length > 0 ? res.data.plan_details[0].allowed_classes : ''
            }
            localStorage.setItem('authDetails', JSON.stringify(tok));
            if (res.data.result === true) {
                cb();
            }
        })
        .catch(err => {
            dispatch({ type: 'REGISTER_STUDENT_FAILED', payload: err })
        })
}



export const checkContactNumberExist = (num,type,cb,codevalue,first_name) =>

    (dispatch) => {
        // let code
        // if(num){
        //     code=codevalue+num
        // }
        // else{
        //     code=codevalue+num
        // } 
        const data = {
            mobile: num,
            type:type
        }
        if(type==='email'){
            data.first_name=first_name
        }
        dispatch({ type: 'STUDENT_NUMBER_VALIDATE_REQUEST' })
        axios.post(`${development.baseUrl}validateParentMobile`, data)
            .then(res => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_SUCCESS', payload: res.data })
                if (res.data.result) {
                    cb(type,codevalue,first_name)
                }
                // else{
                //     dispatch({ type: 'STUDENT_NUMBER_VALIDATE_FAILED', payload: res.data.message })
                // }
            })
            .catch(err => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_FAILED', payload: err })
            })
    }

export const checkContactNumberExistSkillablers = (num, cb) =>

    (dispatch) => {
        let code
        if(num){
            code='91'+num
        }
        else{
            code=num
        } 
        const data = {
            mobile_number: code,
            dialing_code:91
        }

        dispatch({ type: 'STUDENT_NUMBER_VALIDATE_REQUEST' })
        axios.post(`${development.baseUrl}skill/checkUserMobileNumber`, data)
            .then(res => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_SUCCESS', payload: res.data })
                if (res.data.result) {
                    cb()
                }
            })
            .catch(err => {
                dispatch({ type: 'STUDENT_NUMBER_VALIDATE_FAILED', payload: err })
            })
    }
