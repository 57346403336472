import React, { useEffect, Fragment } from 'react';
import banner from "../images/inner-banner-categories.jpg"

import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { listAllHomeTagsCat, setHomeData } from "../../../redux/Actions/genexhome"
import nodata from "../images/nodata.png"


function TagCategory() {
    const dispatch = useDispatch();
    const Catogory_Details = useSelector((state) => state.genexhome.Tag_More_Details_Cat ? state.genexhome.Tag_More_Details_Cat : "");
    const Catogory_Array = useSelector((state) => state.genexhome.Tag_More__Array_Cat ? state.genexhome.Tag_More__Array_Cat : []);
    const cat_first_page_url = useSelector((state) => state.genexhome.Tag_More_first_page_url_Cat ? state.genexhome.Tag_More_first_page_url_Cat : '');
    const cat_next_page_url = useSelector((state) => state.genexhome.Tag_More_next_page_url_Cat  ? state.genexhome.Tag_More_next_page_url_Cat     : '');
    const cat_loading = useSelector((state) => state.genexhome.tag_cat_loading ? state.genexhome.tag_cat_loading : false);

    const page = useSelector((state) => state.genexhome.search_tag_cat_page ? state.genexhome.search_tag_cat_page : 1);
    const search = useSelector((state) => state.genexhome.search_tag_cat ? state.genexhome.search_tag_cat : '');

    useEffect(() => {
        dispatch(listAllHomeTagsCat(page,search))
    }, [page])


    const NextPage = () => {
        dispatch(setHomeData('search_tag_cat_page', page + 1))
    }
    const PreviousPage = () => {
        dispatch(setHomeData('search_tag_cat_page', page - 1))
    }

    const SearchCat = (e) => {
        e.preventDefault();
        if (page === 1) {
            dispatch(listAllHomeTagsCat(1, search))
        }
        else {
            dispatch(setHomeData('search_tag_cat_page', 1))

        }

    }

    const Handlechange = (e) => {
        if (e.target.value) {
            dispatch(setHomeData('search_tag_cat', e.target.value))
        }
        else {
            dispatch(setHomeData('search_tag_cat', e.target.value))
            if (page === 1) {
                dispatch(listAllHomeTagsCat(1,''))
            }
            else {
                dispatch(setHomeData('search_tag_cat_page', 1))
            }
        }
    }

    return (
        <Fragment>
            {
                Catogory_Details ?
                    <>
                        <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})` }} >
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-md-9 col-lg-9 col-12">
                                        <h2 class="text-center font-weight-600 banner-title">OUR CATEGORIES</h2>
                                    </div>
                                </div>
                            </div>

                            <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12 col-12">
                                        <form onSubmit={SearchCat} class="search-form-main">
                                            <div class="row justify-content-center">
                                                <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                    <div class="form-group">
                                                        <input type='search' value={search} onChange={Handlechange} class="form-control" id="" placeholder="Search here" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                    <div class="form-group">

                                                        <input
                                                            type="button"
                                                            class="btn btn-block search-btn"
                                                            value="Search"
                                                            onClick={SearchCat}
                                                            disabled={search ? false : true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                            </div>
                        </section>


                        <section class="padding-top-120px padding-bottom-60px" style={{ backgroundColor: '#fff' }}>
                            <LoadingOverlay
                                active={cat_loading}
                                spinner
                                text='Loading your content...'
                            >
                                <div class="container-fluid">
                                    <div class="col-md-12 col-lg-12 col-12">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12 col-lg-12 col-12 ">
                                                <div class="text-center  margin-bottom-35px wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                                                    <h3 style={{ color: '#212529' }} class="font-weight-700 text-title-large wow fadeInUp" data-wow-delay="0.2s"><span class="fw-200">BROWSE OUR  </span> ONLINE COURSES</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            {
                                                Catogory_Array.length > 0 ?
                                                    <>

                                                        <div class="col-lg-12 col-md-12 col-12 wow fadeInUp" data-wow-delay="0.2s">
                                                            <div class="row">
                                                                {
                                                                    Catogory_Array.map((val, ky) => {
                                                                        return (
                                                                            <div key={ky} class="col-lg-2 col-md-3 pl-2 pr-2 mb-2 mt-2">
                                                                                <Link to={{ pathname: `/categories/${val.tag_id}` }}>
                                                                                    <div class="category-col-inner text-center">
                                                                                        <h5>{val.tag_name}</h5>
                                                                                        <p class="mb-0">Course [{val.total_courses}]</p>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <img src={nodata} width='600px' height='400px' alt='' />
                                                    </div>
                                            }
                                        </div>

                                        {
                                            Catogory_Array.length > 0 && (cat_first_page_url || cat_next_page_url) ?
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                                                        <ul class="pagination-list">
                                                            {
                                                                cat_first_page_url ?
                                                                    <li style={{ cursor: 'pointer' }}><a onClick={PreviousPage}>Prev</a></li>
                                                                    :
                                                                    <li><a style={{ color: '#dddddd' }}>Prev</a></li>

                                                            }
                                                            {
                                                                cat_next_page_url ?
                                                                    <li style={{ cursor: 'pointer' }}><a onClick={NextPage}>Next</a></li>
                                                                    :
                                                                    <li><a style={{ color: '#dddddd' }}>Next</a></li>

                                                            }

                                                        </ul>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                    </div>
                                </div>
                            </LoadingOverlay>
                        </section>

                    </>
                    :
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </div>
            }
        </Fragment>
    )
}
export default TagCategory