import React, { useState,useEffect } from 'react'
import { 
    Row, Col, Badge, 
    // TabContent, TabPane, Nav, NavItem, NavLink,
    // Card, CardBody, FormText, Label,
    // CardTitle, CardHeader, CardFooter,
    // Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from 'reactstrap';


import Table from "./table";
import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { getAllStudent,deleteStudent,blockStudent,listStudentsUnderReseller,payoffCommision,correctCommision } from '../../../redux/Actions/Superadmin'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'



const Studentlist = () => {

    var columns
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  
    const StudentArray = useSelector((state) =>
    state.superadmin.StudentArray ? state.superadmin.StudentArray : []
    );
    const studentloader = useSelector((state) =>
    state.superadmin.studentloader ? state.superadmin.studentloader : ""
    );

  ;
  

    useEffect(()=>{
        if(tokenData.role==='superAdmin'){
            dispatch(getAllStudent(tokenData.token))
        }
        else{
            dispatch(listStudentsUnderReseller(tokenData.token))
        }
    },[])

    columns=[
        { title: 'Name', field: 'full_name' },
        { title: 'Panchayath', field: 'panchayath_name' },
        { title: 'District', field: 'district_name' },
        { title: 'Email', field: 'email' },
        { title: 'Phone', field: 'phone' },
        { title: 'Referral_ID', field: 'genex_referral_code' },
        { title: 'Status', field: 'status',
        render: (rowData) => { 
            return(
                rowData.status===1 ?
                <Badge color="danger" pill>Blocked</Badge>
                :
                <Badge color="success" pill>Active</Badge>
            )
        } 
        },

      ]
      const data=StudentArray ? StudentArray.students ? StudentArray.students :[]:[]

      const action = "student_table"

      const Edit=(val)=>{
        history.push('/dashboard/editStudent/' + val.student_id)
      }
      const Viewdetail=(val)=>{
        history.push('/dashboard/studentdetails/' + val.student_id)
      }

    

      const Deletestudent=(val)=>{
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',
    
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
    
        }).then((result) => {
            if (result.value) {
                dispatch(deleteStudent(tokenData.token,val.student_id,handleOpenSuccessModal))
    
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }


const [successModal, setSuccessModal] = useState(false);
// const [Message, setMessage] = useState("Action Successful")

const [Message, setMessage] = useState('')
const handleOpenSuccessModal = (message) => {
    dispatch(getAllStudent(tokenData.token))
    setMessage(message)
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
  setSuccessModal(false)
}

const Updatestatus=(val)=>{
    Swal.fire({
        icon: 'warning',
        title: 'Are you sure !',
        //text: 'All the classes under the subject will be deleted',
    
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "CONFIRM",
        cancelButtonText: "CANCEL",
    
    }).then((result) => {
        if (result.value) {
            dispatch(blockStudent(tokenData.token,val.student_id,handleOpenSuccessModal))
    
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'Cancelled',
                'error'
            )
        }
    })
}




    return (
    <>
         
        <Row>
            <Col sm={12} md={12}>
                <Table
                    loader={studentloader}
                    actions={action}
                    data={data}
                    title={'Student List'}
                    columns={columns}
                    Editstudent={Edit}
                    Studentdetail={Viewdetail}
                    Deletestudent={Deletestudent}
                    Updatestatus={Updatestatus}
                />
            </Col>
        </Row >

        <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text= {Message?Message:"Successfully completed"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />



  
    </>
    )
}

export default React.memo(Studentlist)
