import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import serialize from "form-serialize";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import ChooseFromPlaylist from "./editForm";
import {  requiredValidator } from "../../../../../utilities/Validator";

// import { setReqData } from "../../../../../redux/Actions/Superadmin"
// import Modal from "../../../../common/Modal/index"
import { Row, Col, FormGroup, Input, Card, Label, FormFeedback } from "reactstrap";
import {
  // populatePlayListInClass,
  updateVideoClass,
  editClassVideos,
} from "../../../../../redux/Actions/playlist";

import PageTitle from "../../../../HeaderTitle/PageTitle";
import "../Playlist/playlist.scss";
import "./subject.scss";
import moment from "moment";
import Snackbar from '@material-ui/core/Snackbar';


const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const playlistStore = useSelector(({ playlist }) => playlist);
  const params = useParams();
  const [editClassDetails, setEditClassDetails] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  // let initialPlaylist = null;
  const [initialPlaylist, setInitialPlaylist] = useState("");

  // const video_desc = useSelector((state) => state.superadmin.video_desc_edit ? state.superadmin.video_desc_edit : []);

  // const [open, setOpen] = useState({id:'',open_model:false});
  // const [desc, setDesc] = useState('');
  // const [percentLimit, setPercentLimit] = useState(0);
  const [check, setCheck] = useState({percentage:false,description:false});
  const [defaultval, setDefault]= useState(false);
 
  const [newVideoList, setNewVideoList] = useState([]);
  const [removedVideoList, setRemovedList] = useState([]);
  const [initialVideoList, setInitialVideoList] = useState();
  const [initialVideoListDetails, setInitialVideoListDetails] = useState([]);

  const [show, setShow]= useState(false);

  useEffect(() => {
    dispatch(
      editClassVideos(
        tokenData.token,'',
        params.video_class_id,
        setSelected,
        params.subscription_id,
      )
    );
  }, []);
  const [selectedId, setSelectedId] = useState({});
 

  const setSelected = (playlist_id, details) => {
  
    setInitialPlaylist(playlist_id);
    setEditClassDetails(details);
    setSelectedPlaylist(playlist_id);
    setCheck({percentage:details.percentage_boolean ? details.percentage_boolean:false,description:details.description_boolean ? details.description_boolean:false})
    setDefault(false)
    let selectedVideos = details.playlists.filter(
      (playlist) => playlist.playlist_id == playlist_id
    );
    let indexOfVideo = selectedVideos[0].videos.map((video, index) => {
      if (video.selected === true) {
        return index;
      }
    });
  
    indexOfVideo.map((setIndex) => {
     
      const updatedState = selectedId;
      updatedState[setIndex] = [
        `fromDate-${setIndex}`,
        `toDate-${setIndex}`,
        `fromTime-${setIndex}`,
        `toTime-${setIndex}`,
      ];
      setSelectedId(updatedState);

    });

    selectedVideos.findIndex((video) => video.selected == true);
    let arr=[]
    let arr_seleted_details=[]
    selectedVideos = selectedVideos[0].videos.map((video) => {
      if (video.selected === true) {
        arr.push(video.video_id)
        arr_seleted_details.push(video)
        return video.video_id;
      }
    });
    setMultiSelect(selectedVideos);
    setInitialVideoList(arr)
    setInitialVideoListDetails(arr_seleted_details)
    let selectedPlaylistVideo = details.playlists.filter(
      (playlist) => playlist.playlist_id == playlist_id
    );
    selectedPlaylistVideo = selectedPlaylistVideo[0].videos.map((video) => {
      if (video.selected === true) {
        return video.playlist_video_id;
      }
    });
    setPlaylistsVideoId(selectedPlaylistVideo);

  };
  
  const getVideos = (playlist_id) => {
    setRemovedList([])
    setNewVideoList([])
    if (initialPlaylist === playlist_id) {
      dispatch(
        editClassVideos(
          tokenData.token,
          params.course_id,
          params.video_class_id,
          setSelected
        )
      );
    }
    setMultiSelect([]);
    setPlaylistsVideoId([]);
    setSelectedPlaylist(playlist_id);
    let selectedVideos = editClassDetails.playlists.filter(
      (playlist) => playlist.playlist_id == playlist_id
    );

    let arr=[]
    let arr_seleted_details=[]
    selectedVideos = selectedVideos[0].videos.map((video) => {
      if (video.selected === true) {
        arr.push(video.video_id)
        arr_seleted_details.push(video)
        return video.video_id;
      }
    });
    setInitialVideoList(arr)
    setMultiSelect(selectedVideos);
    setInitialVideoListDetails(arr_seleted_details)
    setCheck({percentage:false,description:false})
  };

  const handleTime = (e) => {
    const { id, value } = e.target;
    const date = id.split("-");
    
    let keyName = date[1];
    if (selectedId[keyName]) {
      if (!selectedId[keyName].includes(id)) {
        selectedId[keyName].push(id);
      }
    }
   
  };


  const [mutliSelect, setMultiSelect] = useState([]);
  const [playlistsVideoId, setPlaylistsVideoId] = useState([]);
  const [selectedIdOrigin, setSelectedIdOrigin] = useState({});

  const multiSelectVideo = (video_id, playlists_video_id, index,class_video_id) => {
  

    if (playlistsVideoId.includes(playlists_video_id)) {
      const filtered = playlistsVideoId.filter(
        (videoId) => videoId != playlists_video_id
      );
      setPlaylistsVideoId(filtered);
      delete selectedId[index];
    } else {
      setSelectedId({ ...selectedId, [index]: [] });

      setPlaylistsVideoId([...playlistsVideoId, playlists_video_id]);
    }

    if (mutliSelect.includes(video_id)) {
      const filtered = mutliSelect.filter((videoId) => videoId != video_id);
      setMultiSelect(filtered);
     
    } else {
      setMultiSelect([...mutliSelect, video_id]);
    }
    if(initialVideoList.includes(video_id)){
      if(removedVideoList.includes(class_video_id)){
        let filtered = removedVideoList.filter((id) => id !== class_video_id);
        setRemovedList(filtered)
      }
      else{
        setShow(true)
        setRemovedList([class_video_id,...removedVideoList])
      }
      
    }
    else{
      if(newVideoList.includes(video_id)){
        let filtered = newVideoList.filter((videoId) => videoId !== video_id);
        setNewVideoList(filtered)
      }
      else{
        setNewVideoList([...newVideoList,video_id])
      }
    }

  };
  const [syncVideo, setSyncVideo] = useState([]);

  const handleClickSync = (video_id) => {
    if (syncVideo.includes(video_id)) {
      const filtered = syncVideo.filter((videoId) => videoId != video_id);
      setSyncVideo(filtered);
    } else {
      setSyncVideo([...syncVideo, video_id]);
    }
  };
  const handleChange = (e, name, videoId,id) => {
   
    let copy = editClassDetails;
    if(!name){
      if (e.target.name == "title") {
        copy.class_name = e.target.value;
      } 
      else{
        copy.video_class_description = e.target.value;
      }
    }
    else {
      copy.playlists
        .filter((playlist) => playlist.playlist_id == selectedPlaylist)[0]
        .videos.map((video) => {
          if (video.video_id == videoId) {
            if (name === "from_date") {
              video.from_date = moment(e).format("YYYY-MM-DD");
            } else if (name === "to_date") {
              video.to_date =  moment(e).format("YYYY-MM-DD");
            } else if (name === "from_time") {
              video.from_time = e;
            } else if (name === "to_time") {
              video.to_time = e;
            }
          }
        });

      const date = id.split("-");
      let keyName = date[1];
    
      if (selectedId[keyName]) {
        if (!selectedId[keyName].includes(id)) {
          selectedId[keyName].push(id);
        }
   
      }
    
    }
    setEditClassDetails({ ...editClassDetails, copy });
  };
  
  // const canCallApi = (_selectedId) => {
  //   if(defaultval===false && check.datetime){
  //     for (let key in _selectedId) {
  //       if (_selectedId[key].length < 4) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   }
  //   else{
  //   return(true)
  //   }
    
  // };


  const [errors, setErrors] = useState({});

  const formSubmit = (e) => {
    e.preventDefault();
  
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    let desc=body.videos ? body.videos.description ? body.videos.description:[]:[]
    let arr=body.videos.video_id
    let order=body.videos.order_of_video

    let updated_order=[]

   if(initialVideoList.length > 0){
     initialVideoList.forEach((val)=>{
      let i=arr.findIndex(x=>x==val)
      let video_class_id_index=initialVideoListDetails.find((x)=>x.video_id==val)
      let obj={}
      obj.order=order[i]
      obj.video_class_id =video_class_id_index.class_video_id
      obj.description=desc[i]
      if(!removedVideoList.includes(video_class_id_index.class_video_id)){
        updated_order.push(obj)
      }
   })
   body.updated_order=updated_order

   }

    let new_added_arr=[]
    if(newVideoList.length > 0){
     
      newVideoList.forEach((val)=>{
        let for_order=arr.findIndex(x=>x==val)

        let obj={}
        obj.video_id=val
        obj.playlist_video_id=val
        obj.video_from_date='2021-03-20'
        obj.video_from_time='10:26 am'
        obj.video_to_date='2021-03-24'
        obj.video_to_time='10:30 am'
        obj.order=order[for_order]
        obj.description=desc[for_order]
        new_added_arr.push(obj)
      })
      body.newly_added=new_added_arr
    }
    else{
      body.newly_added=null
    }

    if(check.description){
      body.description_boolean=1
    }
    else{
      body.description_boolean=0
    }
   
    if(check.percentage===false){
      body.percentage_boolean=0
    }
    else{
      body.percentage_boolean=1
    }
   
    setSelectedIdOrigin(selectedId);


    let error = {
      title: requiredValidator(body.title),
    };

    if (selectedPlaylist) {
      if (!error.title) {
        dispatch(updateVideoClass(tokenData.token, body, updatedSuccess));
        setErrors(error)
      } else {
        setErrors(error)
      }

    } else {
      alert("Select playlist");
    }

  };
  const updatedSuccess = () => {
    setRemovedList([])
    setNewVideoList([])
    dispatch(
      editClassVideos(
        tokenData.token,'',
        params.video_class_id,
        setSelected,
        params.subscription_id,
      )
    );
    // history.goBack()
  };


  
  const Checkbox=(e,type)=>{
    setCheck({...check,[type]:e.target.checked})
 
  }

 

  return (
    <>
      <PageTitle
        style={{ display: "none" }}
        heading="Edit Video Session"
        subheading="Edit your video session under the subscription"
        icon="pe-7s-video icon-gradient bg-happy-itmeo"
      />

      <form onSubmit={formSubmit} id="create_video_class">
        <input type="hidden" value={params.class_id} name="video_class_id" />
        {playlistStore.editClassDetails ? (
          <>
            <Card style={{ padding: "25px 20px", marginBottom: 25 }}>
              <Row>

                <Col md={12}>
                  <Label for="exampleCustomSelectDisabled"><span style={{color:'red'}}>*</span>Video Class Title</Label>

                  <Input
                    type="text"
                    name="title"
                    value={editClassDetails ? editClassDetails.class_name : ""}
                    onChange={handleChange}
                    invalid={errors.title}
                  />
                  <FormFeedback >{errors.title}</FormFeedback>

                </Col>

                <Col md={12}>

                  <Input
                    type="hidden"
                    name="video_class_description"
                    value={editClassDetails ? editClassDetails.video_class_description : ""}
                    onChange={handleChange}
                    rows={2}
                  />

                </Col>

                <Col xs={12} sm={6} style={{marginTop:'20px'}}>
                    <Input
                      type='checkbox'
                      onChange={(e)=>{Checkbox(e,'description')}}
                      name='description_boolean'
                      checked={(check.description===1 || check.description===true) ? true :false}
                      style={{marginLeft:'30px'}}
                    />
                    <span style={{marginLeft:'50px'}}>Description</span>
                  </Col>

                  {/* <Col xs={12} sm={6} style={{marginTop:'20px'}}>
                    <Input
                        type='hidden'
                        onChange={(e)=>{Checkbox(e,'percentage')}}
                        name='percentage_boolean'
                        value={check.percentage ? 1 :0}
                        style={{marginLeft:'30px'}}
                      />
                      <span style={{marginLeft:'50px'}}>Percentage Limit</span>
                    </Col> */}
              </Row>
            </Card>
            <Card style={{ padding: "10px 20px" }}>
              <Row className="jms-create-video-session">
                <Col md={6}> Playlist </Col>
                <Col md={6}> Videos</Col>
                
                </Row>
              <Row>
                <input
                  type="hidden"
                  value={selectedPlaylist}
                  name="playlist_id"
                />

                <Col md={4} className="jms-choose-from-col-title">
                  {playlistStore.editClassDetails.playlists.map((playlist) => {
                    return (
                      <div
                        className={`jms-choose-from-title ${
                          selectedPlaylist === playlist.playlist_id
                            ? "jms-active-playlist"
                            : null
                          }`}
                        onClick={() => getVideos(playlist.playlist_id)}
                      >
                        {playlist.playlist_name}
                      </div>
                    );
                  })}
                </Col>

                <Col md={8} className="jms-choose-from-col-videos">

                  {editClassDetails && selectedPlaylist
                    ? editClassDetails.playlists
                      .filter(
                        (playli) => playli.playlist_id == selectedPlaylist
                      )[0]
                      .videos.map((video, index) => {                      
                        return (
                          <ChooseFromPlaylist
                            thumbnile={
                              video.camera_tag_thumbnail_path ? video.camera_tag_thumbnail_path:video?.thumbnails?.length > 0
                              ? video.thumbnails[2].link
                              : null
                              // video?.thumbnails?.length > 0
                              //   ? video.thumbnails[2].link
                              //   : null
                            }
                            type={"create_class"}
                            videoId={video.video_id}
                            playlist_video_id={video.playlist_video_id}
                            title={video.video_title}
                            //url={video.url}
                            duration={video.video_duration}
                            //format={'mp4'}
                            index={index}
                            fromDate={video.from_date}
                            toDate={video.to_date}
                            fromTime={video.from_time.length===5 || video.from_time.length===8 ? '2001-01-01 '+video.from_time:video.from_time}
                            toTime={video.to_time.length===5 || video.to_time.length===8? '2001-01-01 '+video.to_time: video.to_time}
                            order={video.video_order}
                            watch={video.percentage}
                            //selected={video.selected}
                            handleChange={handleChange}
                            multiSelectVideo={multiSelectVideo}
                            mutliSelect={mutliSelect}
                            playlistsVideoId={playlistsVideoId}
                            handleClickSync={handleClickSync}
                            syncVideo={syncVideo}
                            handleTime={handleTime}
                            selectedIdOrigin={selectedIdOrigin}
                            check={check}
                            limit={video.limit ? video.limit:0}
                            description={video.video_class_description ? video.video_class_description:''}
                            class_video_id={video.class_video_id}
                          />
                        );
                      })
                    : null}
                </Col>
              </Row>
            </Card>
          </>
        ) : playlistStore.classPlaylistLoader ? (
          <>
            <Card style={{ padding: 20 }}>
              <Row>
                <Col md={3}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={50} count={4} />
                  </SkeletonTheme>
                </Col>
                <Col md={9}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={100} count={2} />
                  </SkeletonTheme>
                </Col>
              </Row>
            </Card>
          </>
        ) : null}

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label
                for="exampleCustomSelectDisabled"
                style={{ visibility: "hidden" }}
              >
                Subject
              </Label>

              <LaddaButton
                className="mb-2 mr-2 btn btn-danger"
                loading={playlistStore.classUpdateLoader}
                // onClick={formSubmit}
                type="submit"
                data-style={ZOOM_IN}
                style={{
                  whiteSpace: "nowrap",
                  marginRight: "0px",
                  width: "-webkit-fill-available",
                }}
                disabled={mutliSelect.length > 0 ? false:true}
              >
                UPDATE CLASS
              </LaddaButton>
            </FormGroup>
          </Col>
        </Row>

        <Input name='subscription_id'  value={params.subscription_id} type='hidden'/>
        <Input name='video_class_id'  value={params.video_class_id} type='hidden'/>

      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={show}
        onClose={()=>{setShow(false)}}
        message="All student data will be deleted for this video!"
        autoHideDuration={1500}
      />
    </>
  );
};

export default Index;
