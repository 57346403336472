import React, {Fragment, Component} from "react";
import Slider from "react-slick";
import {Card} from 'reactstrap'
import CardBody from "reactstrap/lib/CardBody";

    function MultipleItems(props) {
        
      const {sdata,school,handleDetailsClick} = props
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3
        };
        return (
            <Fragment>
                <Slider {...settings}>
                
                        {sdata.map((item)=> { return(
                            <div>
                        <div onClick={()=>handleDetailsClick(school?item.school_id:item.student_id)} className="slider-item">
                            <Card style={{height:'100%'}} className={school?"bg-success":"bg-primary"}>
                                <CardBody>
                                  <h5 style={{color:'white',fontWeight:'bold'}}>{school? item.school_name: item.student_name}</h5>
                                  <h6 style={{color:'white',fontWeight:'50px'}}>{item.area}</h6>
                                  <h6 style={{color:'white',fontWeight:'lighter',fontStyle:'italic'}}>{item.email}</h6>
                                  <h6 style={{color:'white',fontWeight:'normal'}}>{item.phone}</h6>
                                </CardBody>
                            </Card>
                        </div>
                        </div>
                        )}
                        )
                        }
                  
                    
                </Slider>
            </Fragment>
        );
    }
    export default  MultipleItems; 