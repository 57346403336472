import React, { useEffect, useState } from "react";
// import { razorpaykey } from "../../../config.json";
import animationData from "../../Dashboard/animation/check-circle.json";
import Lottie from "react-lottie";
import errorAnimation from "../../Dashboard/animation/error.json";
import { useDispatch,useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { Row, Col, Button, } from 'reactstrap'
import { validatePaymentLink, updateOrderViaPaymentLink } from '../../../redux/Actions/genexhome'
import Modals from '../../common/Modal'
import Swal from 'sweetalert2'
// import logo from "../images/logo.png"
import Terms from '../SkillablersPayment'
import { Modal } from 'reactstrap';


function PaymentFromLink() {


  const dispatch = useDispatch();
  const params = useParams()
  const history = useHistory();
  const [OrderData, setOrderData] = useState(null)
  const [loader, setLoader] = useState(false)

  const [successModal, setSuccessModal] = useState({ open: false });
  const [failModal, setFailModal] = useState({ fail: false, msg: "" });

  const Single_Course_Details = useSelector((state) => state.genexhome.Single_Course_Details ? state.genexhome.Single_Course_Details : '');

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successModal.open
      ? animationData
      : failModal
        ? errorAnimation
        : null,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    let parameters = params.code.replace('&', '=').split('=')
    dispatch(validatePaymentLink(parameters[1], parameters[3], Success, expired))
  }, []);

  const expired = (msg) => {
    Swal.fire({
      icon: 'error',
      title: 'Link Expired!',
      text: msg ? msg : 'Payment link has expired!',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "OK",
    }).then((result) => {
      history.push('/')
    })
  }

  const Success = (data) => {
    // console.log('dddddaaataaaaaaaaaa',data)
    setOrderData(data)
    setLoader(false)
  }

  const handleCloseModal = () => {
    setOrderData(null)
    // setTermsAndConditions(false)
  }

  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  // const displayRazorpay = async (data) => {
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );
  //   if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     window.location.reload()
  //     return;
  //   } else {
  //     const ordrid=data.order_id
  //     const options = {
  //         key: razorpaykey.razorkey,
  //         currency: data.currency,
  //         amount: data.course_amount.toString(),
  //         order_id: data.payment_order_id,
  //         name: 'SKILLABLERS',
  //         image: logo,
  //         handler: function (response) {
  //             const details = {
  //                 razorpay_payment_id: response.razorpay_payment_id,
  //                 razorpay_order_id: response.razorpay_order_id,
  //                 razorpay_signature: response.razorpay_signature,
  //                 order_id:ordrid
  //             }
  //             dispatch(updateOrderViaPaymentLink(details,paymentStatus))
  //         },
  //         prefill: {
  //             email: '',
  //             phone_number: ''
  //         }
  //     }
  //     const paymentObject = new window.Razorpay(options)
  //     paymentObject.open()
  //   }

  // };

  // const paymentStatus = (res) => {
  //   if (res.result) {
  //     setSuccessModal({ open: true, res })
  //   } else {
  //     setFailModal({ fail: true, msg: res.message })
  //   }
  // }

  const closeSuccessModal = () => {
    dispatch({ type: 'PAYMENT_SUCCESSFULLY_COMPLETED' })
    setSuccessModal({ ...successModal, open: false })
    history.push('/')
  }

  return (

    <section class="padding-top-80px padding-bottom-80px" style={{ backgroundColor: '#ffffff' }}>

      <div class="container">

        <div class="row justify-content-center">
          <div class="col-md-9 col-lg-9 col-12 ">
            <div class="text-center  margin-bottom-35px wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
              <h3 style={{ color: '#212529' }} class="font-weight-700 text-title-large wow fadeInUp" data-wow-delay="0.2s">Skillablers</h3>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={successModal.open}
        handleClose={closeSuccessModal}
        size="sm"
      >
        <Row>
          <Col md={12}>
            <Lottie
              options={defaultOptions}
              height={145}
              width={145}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={12}
            style={{ fontSize: "20px", color: "green", textAlign: "center" }}
          >
            Success
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: "center", color: "#5e8e5f" }}>
            <strong style={{ fontWeight: 400 }}>
              Payment completed successfully
            </strong>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: "center" }}>
            <Button type="button" onClick={closeSuccessModal} color="success">
              Okay
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={failModal.fail}
        handleClose={() => {
          setFailModal({ fail: false, msg: "" })
          history.push('/')
        }}
        size="sm"
      >
        <Row>
          <Col md={12}>
            <Lottie
              options={defaultOptions}
              height={145}
              width={145}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={12}
            style={{ fontSize: "20px", color: "#c0392b", textAlign: "center" }}
          >
            Failed
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ textAlign: "center", color: "#ab2d20" }}>
            <strong style={{ fontWeight: 400 }}>{failModal.msg}</strong>
          </Col>
        </Row>
        <Col md={12} style={{ textAlign: "center", paddingTop: 25 }}>
          <Button
            type="button"
            onClick={() => {
              setFailModal({ fail: false, msg: "" })
              window.location.reload()
            }}
            color="danger"
          >
            Okay
          </Button>
        </Col>
      </Modal>

      <Modal
        isOpen={OrderData}
        // handleClose={handleCloseModal}
        toggle={handleCloseModal}
        title={"Terms&Conditions"}
      // size='lg'
      >
        <div class='class="modal-content"'>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
            <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Order</h5>
            <button type="button" class="close" onClick={() => { handleCloseModal(false) }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 col-lg-12 col-12">
              <div style={{ display: 'flex', justifyContent: 'center' }} class="row">
                <div style={{ textAlign: 'center' }} class="col-md-12 col-lg-12 col-12">
                  <img style={{ borderRadius: '4px' }} src={OrderData? OrderData.banner_url : ''} alt="" width='200px' height='100px' class="img-fluid" />
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px' }} class="col-md-12 col-lg-12 col-12 ">
                  <div style={{ fontSize: '15pt', fontWeight: 'bold', color: '#000000' }} >{OrderData ? OrderData.course_name:''}</div>
                  <div style={{ fontWeight: 'bold', color: '#003366' }}>{OrderData?OrderData.course_amount:''}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-12 col-12">
                  <hr />
                </div>
                {OrderData &&
                  <Terms
                    course_id={OrderData.course_id}
                    name={OrderData.name}
                    email={OrderData.email}
                    phone={OrderData.phone}
                    reg_id={OrderData.reg_id}
                    service_type={OrderData.service_type}
                    url_page={OrderData.url_page}
                    course_amount={OrderData.course_amount}
                    mots_order_id={OrderData.order_id}
                  />
                }
              </div>


            </div>
          </div>
        </div>

      </Modal>

    </section>
  );
}

export default PaymentFromLink;
