import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { TeacherSubNav, studentSubNav, principalSubNav,SuperAdminSubNav } from "./NavItems";




///////////////////student//////////////

const SchoolSubNav  = [


  {
      icon: 'pe-7s-edit',
      label: 'My Account',
      content: [],
      to: '#/dashboard/my-account'
  },
 
];
const studentprivilages=[
          {
            content: [],
            icon: "pe-7s-home",
            label: "Home",
            module_id: 1,
            to: "#/dashboard/overview"          
          },
          {
            content: [],
            icon: "pe-7s-home",
            label: "My Course",
            module_id: 1,
            to: "#/dashboard/myCourse"          
          },
          {
            content:[
              {
                label: "Show Class",
                module_id: 27,
                to: "#/dashboard/show-class",
              },
              {
                label: "Special Class",
                module_id: 28,
                to: "#/dashboard/specialClass",
              },
              {
                label: "Video Class",
                module_id: 29,
                to: "#/dashboard/videoClass",
              },
              // {
              //   label: "Show Exams",
              //   module_id: 43,
              //   to: "#/dashboard/showExam"
              // }
            ],
            icon: "pe-7s-display2",
            label: "Class",
            module_id: 2,
            to: null,
          },
          {
            content: [],
            icon: "pe-7s-note2",
            label: "Assignments",
            module_id: 3,
            to: "#/dashboard/assignments"
          },
          {
            content: [],
            icon: "pe-7s-id",
            label: "Attendance",
            module_id: 4,
            to: "#/dashboard/show-attendance"
          },
          {
            icon: 'pe-7s-ticket',
            label: 'Tickets',
            content: [],
            module_id: 5,
            to: '#/dashboard/tickets'
          }
        ]
class Nav extends Component {
  state = {};

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };
  render() {
    // console.log("this.props.privillage", this.props.privillage);

    return (
      <Fragment>
        {this.props.role ? (
          this.props.role === "teacher" &&
          this.props.enterprise_user === false ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={TeacherSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : 
          //////////sssuuuppperrr admin newly addedd by midhlaj
            this.props.role === "superAdmin"  ? (
              <>
                <h5 className="app-sidebar__heading">Menu</h5>
                <MetisMenu
                  content={this.props.privillage}
                  onSelected={this.toggleMobileSidebar}
                  activeLinkFromLocation
                  className="vertical-nav-menu"
                  iconNamePrefix=""
                  classNameStateIcon="pe-7s-angle-down"
                />
                <h5 className="app-sidebar__heading">System</h5>
                <MetisMenu
                  content={SuperAdminSubNav}
                  onSelected={this.toggleMobileSidebar}
                  activeLinkFromLocation
                  className="vertical-nav-menu"
                  iconNamePrefix=""
                  classNameStateIcon="pe-7s-angle-down"
                />
              </>
            ) :
          this.props.role === "student" &&
            this.props.enterprise_user === false ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={studentSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : this.props.role === "principal" ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={principalSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : this.props.role === "staff" &&
            this.props.enterprise_user === true ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={principalSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : this.props.role === "teacher" &&
            this.props.enterprise_user === true ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={principalSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : 
          ////////////////////changed by midhlaj student menu
          this.props.role === "student" &&
            this.props.enterprise_user === true ? (
            <>
          
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={principalSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : this.props.role === "reseller"  ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={SchoolSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) : this.props.role === "school"  ? (
            <>
              <h5 className="app-sidebar__heading">Menu</h5>
              <MetisMenu
                content={this.props.privillage}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
              <h5 className="app-sidebar__heading">System</h5>
              <MetisMenu
                content={SchoolSubNav}
                onSelected={this.toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </>
          ) :null
        ) : null}

        {/* <h5 className="app-sidebar__heading">UI Components</h5>
                <MetisMenu content={ComponentsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
                <h5 className="app-sidebar__heading">Dashboard Widgets</h5>
                <MetisMenu content={WidgetsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
                <h5 className="app-sidebar__heading">Forms</h5>
                <MetisMenu content={FormsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
                <h5 className="app-sidebar__heading">Charts</h5>
                <MetisMenu content={ChartsNav} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" /> */}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
