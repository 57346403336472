import React from 'react';
import { useHistory } from "react-router-dom";
import img1 from '../../images/vendordummy.png'
import nodata from "../../images/nodata.png"
import OwlCarousel from "react-owl-carousel";
import {Link }from 'react-router-dom'

function Vendor(props){
    const history = useHistory();
    const options = {
        responsiveClass: true,
        nav: false,
        // loop: true,
        margin: 10,
        // dots: props.vendor_array.length > 4 ? true :false,
        dots:true,
        autoplay:false,
        responsive: {
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 2,
          },
          700: {
            items: 2,
          },
          1000: {
            items: 4,
          },
        },
      };

    const ViewSingleVnedor=(val)=>{
        history.push(`/vendor/${val.vendor_id}/${val.skl_user_id}`)
    }

    return(
<section class="padding-tb-80px">
    <div class="container">

        <div class="text-center margin-bottom-35px wow fadeInUp" style={{visibility:'visible',animationName:'fadeInUp'}}>
            <h3 style={{color:'#212529'}} class="font-weight-700 text-title-large"><span class="fw-200">OUR </span> PROVIDERS</h3>
        </div>

        {
            props.vendor_array.length > 0 ?
            ""
            :
            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                <img src={nodata} width='500px' height='300px' loading='lazy' alt='' />
            </div>
        }

        <div class="swiper-container swiper-testimonial pb-4">
            <div class="swiper-wrapper wow fadeInUp" data-wow-delay="0.2s">
            <OwlCarousel
                id="feature-slide"
                className="owl-carousel owl-theme"
                {...options}>
                    {
                        props.vendor_array.map((val,ky)=>{
                            return(
                                <div class="swiper-slide" key={ky} style={{cursor:'pointer'}} onClick={()=>{ViewSingleVnedor(val)}}>
                                    <div class="review-box institute-col">
                                        <div class="institute-media" 
                                        style={{height:'180px'}}
                                        >
                                            <img 
                                                src={val.logo_url ? val.logo_url:img1} 
                                                alt='' 
                                                height='180px'
                                                // id="objfit-iaggg"
                                                />
                                        </div>
                                        <div style={{width:'100%',height:'43px',wordWrap:'break-word',overflow:'hidden'}}>
                                            <h5>{val.vendor_name}</h5>
                                        </div>
                                        <div style={{width:'100%',height:'50px',overflow:'hidden',wordWrap:'break-word'}}>
                                            <p class="read mb-1">{val.short_description}</p>
                                        </div>
                                        <p class="mb-1"><span href="#" class="btn courst-btn">{val.total_courses} Courses</span></p>
                                    </div>
                                </div>
                            )
                        })
                    }
            </OwlCarousel>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-12 text-center mt-0">
                <Link to="/vendors" class="btn btn-gn border-radius-30 wow fadeInUp" data-wow-delay="0.2s">View All</Link>
            </div>
        </div>
    </div>
</section>
    )
}
export default Vendor



