export default function StudentReducer(state={},action)

{
    switch (action.type) {
        case "SET_STUDENT_DATA": {
          return { ...state, ...action.payload };
        }


      case "TICKETREASON_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "TICKETREASON_ALL_SUCCESS": {
        return { ...state, ...action.payload }
      }
      case "TICKETREASON_ALL_FAILED": {
        return { ...state,}
      }

      case "STUDENT_LIST_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "STUDENT_LIST_ALL_SUCCESS": {
        return { ...state, ...action.payload }
      }
      case "STUDENT_LIST_ALL_FAILED": {
        return { ...state,...action.payload }
      }

      case "TICKET_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "TICKET_ALL_SUCCESS": {
        return { ...state, ...action.payload }
      }
      case "TICKET_ALL_FAILED": {
        return { ...state,...action.payload }
      }
      case "REFUND_STUDENT_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "REFUND_STUDENT_SUCCESS": {
        return { ...state, ...action.payload }
      }
      case "REFUND_STUDENT_FAILED": {
        return { ...state,...action.payload }
      }
      case "STUDENT_SUBSCRIPTIONS_SUCCESS": {
        return { ...state,...action.payload }
      }

        default: {
            return { ...state }
          }
    }

}