import React, { useEffect } from 'react'
import Table from '../Table'
import { useDispatch } from 'react-redux'
import { getStudyMaterials } from '../../../redux/Actions/homeTeacher'

function StudyMaterials() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(getStudyMaterials(tokenData.token))
    }, [])

    return (
        <div>
            <Table
            action='study_material_student'
            />
        </div>
    )
}

export default StudyMaterials
