import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom'

// import TitleComponent1 from './PageTitleExamples/Variation1'
// import TitleComponent2 from './PageTitleExamples/Variation2'
// import TitleComponent3 from './PageTitleExamples/Variation3'

class PageTitle extends Component {
    randomize(myArray) {
        return myArray[Math.floor(Math.random() * myArray.length)];
    }

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,
            actions,
            heading,
            icon,
            subheading,
            link,
            course_id
        } = this.props;
        // var arr = [<TitleComponent1 />, <TitleComponent2 />, <TitleComponent3 />]
        // var arr = [<TitleComponent3 />]

        return (

            <div className="app-page-title">
                <div className='wrap-title-btn'>
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div
                                className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}
                            >
                                <i className={icon} />
                            </div>
                            <div>
                                {heading}
                                <div
                                    className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}
                                >
                                    {/* {subheading} */}
                                    {course_id !== undefined ?
                                    <Link to={link} > {subheading}</Link>
                                    :
                                    <>{subheading}</>
                                    }                                    
                                </div>
                            </div>
                        </div>
                        <div className="page-title-actions">
                        {actions? actions():''}
                        </div>
                    </div>
                    {course_id !== undefined &&
                    <div className='btnCourse-main-div'>
                        <Link to={link} ><button class="btn btn-primary">Back to Course</button></Link>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);