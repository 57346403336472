import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Badge,
  // FormGroup,
  Input,
  Label,
  Container,
  FormFeedback,
} from "reactstrap";
import SweetAlert from 'sweetalert-react';

import LaddaButton, { ZOOM_IN } from "react-ladda";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { emailValidator, requiredValidator,} from '../../../utilities/Validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./styleph.scss"
import {AddNewReseller,getAllReseller,deleteReseller,blockReseller,payoffCommision,correctCommision,
  // getAllStates, fillterDistricts, fillterPincodes 
} from "../../../redux/Actions/Superadmin"
import { useHistory } from "react-router-dom";
import Table from "./table";
import Swal from 'sweetalert2'
import Modal from '../../common/Modal'
// import { getAllStates, fillterDistricts, fillterPincodes } from '../../../redux/Actions/Superadmin'
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'



const CreateReseller = () => {
 
  const dispatch=useDispatch()
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const history = useHistory();
  // const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
  // const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
  // const pincodes = useSelector((state) => state.superadmin.pincodes ? state.superadmin.pincodes : []);
  
  useEffect(()=>{
    dispatch(getAllReseller(tokenData.token));
   // dispatch(getAllStates(tokenData.token))
  },[])

  const Updatestatus=(val)=>{
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',
  
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",
  
  }).then((result) => {
      if (result.value) {
          dispatch(blockReseller(tokenData.token,val.reseller_id,handleOpenSuccessModal))
  
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Cancelled',
              'error'
          )
      }
  })
  }


  const [inputField,SetInput]=useState({
    reseller_name:'',
    email:'',
    password:'',
    number:'',
    area:'',
    pin:'',
    address:'',

  })
  const [errors, setErrors] = useState({})
  // const [value, setValue] = useState()
  const [successModal, setSuccessModal] = useState(false);

  const resellercreate = useSelector((state) =>state.superadmin.resellercreate ? state.superadmin.resellercreate : false);
  const ResellerArray = useSelector((state) =>
  state.superadmin.ResellerArray ? state.superadmin.ResellerArray : []
);
const resellerloader = useSelector((state) =>
state.superadmin.resellerloader ? state.superadmin.resellerloader : ""
);
const payoffloader = useSelector((state) =>
  state.superadmin.payoffloader ? state.superadmin.payoffloader : false
);
  const handleChangeInput=(event)=>{
    SetInput({...inputField,[event.target.name]:event.target.value})
  }

  const Submit = () => {
    const err = {
      email: emailValidator(inputField.email),
      reseller_name: requiredValidator(inputField.reseller_name),
      password: requiredValidator(inputField.password),
      number: requiredValidator(inputField.number),
      area: requiredValidator(inputField.area),
      pin: requiredValidator(inputField.pin),
      address: requiredValidator(inputField.address),
    };
    if (
      !err.email &&
      !err.password &&
      !err.number &&
      !err.reseller_name &&
      !err.area &&
      !err.pin &&
      !err.address
    ) {
      setErrors(err);
        dispatch(AddNewReseller(
            tokenData.token,
            inputField.reseller_name,
            inputField.number,
            inputField.email,
            inputField.password,
            inputField.address,
            inputField.area,
            inputField.pin,
            handleOpenSuccessModal,
            reset,
            errormodel
        ))
    } else {
      setErrors(err);
      if(err.reseller_name){
        Showerror('reseller_name')
      }
      // else if(err.number){
      //   Showerror('number')
      // }
      else if(err.email){
        Showerror('email')
      }
      else if(err.address){
        Showerror('address')
      }
      else if(err.address){
        Showerror('area')
      }
      else if(err.address){
        Showerror('pin')
      }
      else{
        Showerror('password')
      }
    }
  };
  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

  const [err, setErr] = useState({
    open_err:false,
    err_list:[]
  })
  const errormodel=(val)=>{
    setErr({
      open_err:true,
      err_list:val
    })  
  }
  const closeErr = () => {
    setErr({
      open_err: false,
      err_list: []
    })
  }


    const handleOpenSuccessModal = () => {
        setReseller({id:'',open:false})
        setSuccessModal(true)

    }
    const handleCloseSuccesstModal = () => {
      dispatch(getAllReseller(tokenData.token));
      SetInputmodel({id:'',amount:'',remarks:''})
      setSuccessModal(false)
        // window.location.reload(false);

    }

    const reset=()=>{
      SetInput({
        reseller_name:'',
        email:'',
        password:'',
        number:'',
        area:'',
        pin:'',
        address:'',
      })

      
    }
  const Handlechangephone=(e)=>{
    SetInput({...inputField,number:e})

  }


  const columns = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "contact_number" },
    { title: "Area", field: "area" },
    { title: "PIN", field: "pin" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return rowData.status === 1 ? (
          <Badge color="danger" pill>
            {"Blocked"}
          </Badge>
        ) : (
          <Badge color="success" pill>
            {"Active"}
          </Badge>
        );
      },
    },
    // { title: "Total_Students", field: "total_students" },
  ];
  const data = ResellerArray
    ? ResellerArray.resellers
      ? ResellerArray.resellers
      : []
    : [];
  

  const action = "rseller_table";

  const Studentview = (val) => {
    // console.log('AAAAASSSSSdddd',val)
    history.push(`/dashboard/resllerstudent/${val}`);
  };

  const Editreseller=(val)=>{
    history.push(`/dashboard/editReseller/${val.reseller_id}`)
  }


  const DeleteReseller = (val) => {
    Swal.fire({
        icon: 'warning',
        title: 'Are you sure !',
        //text: 'All the classes under the subject will be deleted',

        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "CONFIRM",
        cancelButtonText: "CANCEL",

    }).then((result) => {
        if (result.value) {
            dispatch(deleteReseller(tokenData.token, val.reseller_id,deleteSuccessModal))

        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
                'Cancelled',
                'Delete action cancelled',
                'error'
            )
        }
    })
}
const [deleteModal, setdeleteModal] = useState(false);

const deleteSuccessModal = () => {
  dispatch(getAllReseller(tokenData.token));
  setdeleteModal(true)

}
const deleteCloseSuccesstModal = () => {
  setdeleteModal(false)
}

const [resller_data,setReseller]=useState({id:'',open:false})
const [inputFieldmodel,SetInputmodel]=useState({id:'',amount:'',remarks:''})
const [errorsmodel, setErrorsmodel] = useState({})

const CreditDebit=(val)=>{
  setErrorsmodel({})
  setReseller({id:val.reseller_id,open:true})
}
const CloseModel=()=>{
setReseller({id:'',open:false})
}

const Handlechange=(event)=>{
  SetInputmodel({...inputFieldmodel,[event.target.name]:event.target.value})
}
const submitPayoff=(val)=>{
  let err = {
    id: requiredValidator(inputFieldmodel.id),
    amount: requiredValidator(inputFieldmodel.amount),
    remarks: requiredValidator(inputFieldmodel.remarks),

  };
  if (
    !err.id &&
    !err.amount &&
    !err.remarks
  ) {
    setErrorsmodel(err);
    if(inputFieldmodel.id==='debit'){
      dispatch(payoffCommision(tokenData.token,resller_data.id,inputFieldmodel.amount,inputFieldmodel.remarks,handleOpenSuccessModal))
    }
    else{
      dispatch(correctCommision(tokenData.token,resller_data.id,inputFieldmodel.amount,inputFieldmodel.remarks,handleOpenSuccessModal))
    }
  }
  else{
    setErrorsmodel(err)
  }
}
const ViewReseller=(val)=>{
  history.push(`/dashboard/viewReseller/${val.reseller_id}`)
}
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          style={{ display: "none" }}
          heading="Create Reseller"
          subheading="Create Reseller With Basic Details"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardBody>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>BASIC DETAILS</CardTitle>
                    <Container>
                      <Row>
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Reseller Name
                          </Label>
                          <Input
                            type="text"
                            name="reseller_name"
                            invalid={errors.reseller_name}
                            onChange={handleChangeInput}
                            value={inputField.reseller_name}
                            id='reseller_name'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.reseller_name}</FormFeedback>

                        </Col>
                        
                        <Col sm={12}  className="assign-form-grid">
                          {/* <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Number
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            name='number'
                            value={inputField.number}
                            invalid={errors.number}
                            onChange={handleChangeInput}

                          />
                          <FormFeedback>{errors.number}</FormFeedback> */}

                                <Label for="exampleName">
                                <span className="text-danger">*</span>
                                {' '}Mobile Number</Label>
        
                                <PhoneInput
                                value={inputField.number}
                                name="number"
                                country={'in'}
                                onChange={(e) => Handlechangephone(e)}
                                invalid={errors.number}
                                id='number'
                                />
                                {
                                  errors.number ? 
                                  <span style={{color:'red'}}>{errors.number}</span>:''
                                }
                                
                                {/* <PhoneInput
                                      placeholder="Enter phone number"
                                      value={value}
                                      onChange={setValue}/> */}
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span> Email ID
                          </Label>
                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            style={{ width: "100%" }}
                            name="email"
                            invalid={errors.email}
                            value={inputField.email}
                            id='email'
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.email}</FormFeedback>
                        </Col>
                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Password
                          </Label>
                          <Input
                            type="password"
                            style={{ width: "100%" }}
                            value={inputField.password}
                            invalid={errors.password}
                            name="password"
                            onChange={handleChangeInput}
                            id='password'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.password}</FormFeedback>
                        </Col>

                        {/* <Col md={4}>
                            <label>State</label>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_PINCODES"})}}>
                                <option value={null} >Select</option>
                                {states.map((s) => {
                                    return (
                                        <option value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>

                    </Col>
                    <Col sm={4}>
                            <label>District</label>
                            <Input type='select' onChange={(e) => dispatch(fillterPincodes(tokenData.token, e.target.value))} >
                                <option value={null} >Select</option>
                                {districts.map((s) => {
                                    return (
                                        <option value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>                         
                            
                        </Col>
                       <Col sm={4}>
                            <label>Panchayat</label>
                            <Input type='select'  >
                                <option value={null} >Select</option>
                                {pincodes.map((p) => {
                                    return (
                                        <option value={p.pincode_id}>{p.place}</option>
                                    )
                                }

                                )}
                            </Input>                         
                            
                        </Col>
                         */}
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            rows={4}
                            style={{ width: "100%" }}
                            name="address"
                            value={inputField.address}
                            invalid={errors.address}
                            onChange={handleChangeInput}
                            id='address'
                          />
                          <FormFeedback>{errors.address}</FormFeedback>
                        </Col>                      

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Municipality
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            name="area"
                            value={inputField.area}
                            invalid={errors.area}
                            onChange={handleChangeInput}
                            id='area'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.area}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            PIN
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            name="pin"
                            value={inputField.pin}
                            invalid={errors.pin}
                            onChange={handleChangeInput}
                            id='pin'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.pin}</FormFeedback>
                        </Col>

                      </Row>
                    </Container>
                </CardBody>
              </Card>
              <LaddaButton
                className="mb-2 mr-2 btn btn-primary"
                loading={resellercreate}
                type="submit"
                data-style={ZOOM_IN}
                onClick={Submit}
              >
                CREATE RESLLER
              </LaddaButton>
          </CardBody>
        </Card>

        <Row>
            <Col sm={12} md={12}>
              <Table
                loader={resellerloader}
                actions={action}
                data={data}
                title={"Reseller List"}
                columns={columns}
                DetailsofVendor={Studentview}
                EditReseller={Editreseller}
                DeleteReseller={DeleteReseller}
                Updatestatus={Updatestatus}
                CreditDebit={CreditDebit}
                ViewReseller={ViewReseller}
              />
            </Col>
          </Row>

      </CSSTransitionGroup>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={deleteModal}
        text="Deleted successfully"
        type="success"
        onConfirm={() => deleteCloseSuccesstModal()} />

      <Modal
        open={resller_data.open ? resller_data.open:false}
        handleClose={CloseModel}
        title='New Payments'
        >
          <Row>
            <Col xs={12}>
              <Input 
                type='select'  
                style={{ width: "100%" }}
                onChange={Handlechange}
                name='id'
                value={inputFieldmodel.id}
                invalid={errorsmodel.id}

                >
                  <option value=''>Select Type</option>
                  <option value='credit'>Credit</option>
                  <option value='debit'>Debit</option>
              </Input>
              <FormFeedback>{errorsmodel.id}</FormFeedback>

            </Col>
            <Col xs={12} style={{marginTop:'10px'}}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span>
                  Amount
                </Label>
              <Input 
                 min={0}
                 type="number"
                 style={{ width: "100%" }}
                 name="amount"
                 value={inputFieldmodel.amount}
                 onChange={Handlechange}
                 invalid={errorsmodel.amount}
              />
              <FormFeedback>{errorsmodel.amount}</FormFeedback>
            </Col>

            <Col xs={12} style={{marginTop:'10px'}}>
            <span className="text-danger">*</span>
                <Label for="exampleNameF">
                  Remarks
                </Label>
              <Input 
                 type="textarea"
                 rows={3}
                 style={{ width: "100%" }}
                 name="remarks"
                 value={inputFieldmodel.remarks}
                 onChange={Handlechange}
                 invalid={errorsmodel.remarks}
              />
              <FormFeedback>{errorsmodel.remarks}</FormFeedback>

            </Col>

            <Col xs={12} style={{marginTop:'5px'}}>
                <LaddaButton className=" btn btn-primary"
                  loading={payoffloader}
                  type="button"
                   onClick={() => submitPayoff()}
                  data-style={ZOOM_IN}
                >
                  Submit
                </LaddaButton>
            </Col>
          </Row>
        </Modal>


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error,ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>
    </>
  );
};

export default CreateReseller;
