import React, { useState, useEffect, Fragment } from 'react'
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    Form,
    Input,
    Card,
    CardBody,
    Label,
    CardHeader,
    Button
} from "reactstrap";
import Swal from 'sweetalert2';
import { requiredValidator } from "../../../utilities/Validator";
import Modal from '../../../Components/common/Modal'
import TagList from "./TagList"
import { deleteTag, listAllTags, createTag, updateTag } from '../../../redux/Actions/Superadmin'

export default function TagIndex() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const loader = useSelector((state) => state.superadmin.tag_loader ? state.superadmin.tag_loader : false);
    const tag_data = useSelector((state) => state.superadmin.tag_array ? state.superadmin.tag_array : []);
    const create_loader = useSelector((state) => state.superadmin.tag_add_loader ? state.superadmin.tag_add_loader : false);


    useEffect(() => {
        dispatch(listAllTags(tokenData.token))
    }, [])

    const [Name, setName] = useState(null)
    const [Errors, setErrors] = useState({})
    const [Cat, setCat] = useState('')
    const [edit, setEdit] = useState(false)
    const [tagid, setTagid] = useState('')
    const [File, setFile] = useState(null);


    const columns = [
        { title: "Tag Name", field: "tag_name" },
    ]

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const CloseModal = () => {
        setImageModal(false);
    }

    const Submit = (e) => {
        e.preventDefault();
        let err = {
            name: requiredValidator(Name),
        }
        if (!err.name) {
            setErrors(err)
            if (tagid && edit) {

                const formData = new FormData();

                formData.append('tag_id', tagid)
                formData.append('tag_name', Name)
                formData.append('category_id', 4)
                if (File) {
                    formData.append('image_file', File)
                }

                dispatch(updateTag(tokenData.token, formData, deletecb))
            }
            else {

                const formData = new FormData();

                formData.append('tag_name', Name)
                formData.append('category_id', 4)
                if (File) {
                    formData.append('image_file', File)
                }


                dispatch(createTag(tokenData.token, formData, deletecb))
            }
        }
        else {
            setErrors(err)
        }
    }

    // const CategoryChange = (e) => {
    //     setCat(e.target.value)
    // }

    const DeleteTags = (val) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteTag(tokenData.token, val.tag_id, deletecb))
            }
        })
    }

    const EditTag = (val) => {
        setErrors({})
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setEdit(true)
        setTagid(val.tag_id)
        setName(val.tag_name)
        setCat(val.category_id)
        setTagData(val)
    }

    const [ImageModal, setImageModal] = useState(false);
    const [TagData, setTagData] = useState(null);


    const viewTag = (val) => {
        setTagData(val)
        setImageModal(true);
    }
    // const handleLogoView = () => {
    //     setImageModal(true);
      
    // }
    const CancelEdit = () => {
        setErrors({})
        setName('')
        setCat('')
        setTagid('')
        setEdit(false)
        setTagData(null)
    }
    const deletecb = () => {

        const element = document.getElementById('form');
        element.reset();

        setName('')
        setCat('')
        setTagid('')
        setEdit(false)
        dispatch(listAllTags(tokenData.token))
    }


    // const selectFile = (e) => {
    //     setFile(e.target.files[0])
    // }

    return (
        <Fragment>
            <Card className="m-4" >
                <Form id="form" onSubmit={Submit}>
                    <CardHeader className="pl-2 pt-2" >
                        {
                            edit ?
                                'Update Category Tags' : 'Create Category Tags'

                        }

                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12} sm={6}>
                                {/* <span className="text-danger">*</span> <Label>Name</Label> */}
                                <Input
                                    type='text'
                                    value={Name}
                                    onChange={handleNameChange}
                                    autocomplete="off"
                                    placeholder='Tag Name'
                                ></Input>
                                <span style={{ color: 'red' }}>{Errors.name}</span>


                            </Col>
                            

                            {/* <Col xs={12} sm={6}>
                                <Label>Logo</Label>
                                <Input
                                    type='file'
                                    onChange={selectFile}
                                >
                                </Input>
                            </Col>
                            { edit &&
                            <Col className='mt-5' xs={6}>
                               
                                <Button onClick={handleLogoView}>View Logo</Button>
                            </Col>

                          } */}
                    
                            <Col xs={12} sm={6}>
                                {
                                    edit ?
                                        <>
                                            <LaddaButton type="submit"
                                                className="mb-2 btn btn-primary"
                                                loading={create_loader}
                                                data-style={ZOOM_IN}>Update
                                            </LaddaButton>

                                            <Button
                                                className='btn btn-danger mb-2 mr-1 ml-1'
                                                onClick={CancelEdit}
                                            >
                                                Cancel
                                            </Button>
                                        </>

                                        :
                                        <LaddaButton type="submit"
                                            className="mb-2 btn btn-primary"
                                            loading={create_loader}
                                            data-style={ZOOM_IN}>Submit
                                        </LaddaButton>

                                }

                            </Col>
                        </Row>
                    </CardBody>
                </Form>
            </Card>

            <Row>
                <Col xs={12}>
                    <TagList
                        columns={columns}
                        deleteTag={DeleteTags}
                        tag_data={tag_data}
                        editTag={EditTag}
                        viewTag={viewTag}
                        Loader={loader}
                    />
                </Col>
            </Row>


            <Modal
                open={ImageModal}
                handleClose={CloseModal}
                title={TagData?.tag_name}
            > {TagData &&

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', width: '100%', height: '105px', justifyContent: 'center', alignItems: 'center' }}>
                        <img width="103px" height="103px" alt="logo" src={TagData.image}></img>

                    </div>



                </div>

                }
            </Modal>

        </Fragment>
    )
}
