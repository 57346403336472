import React,{useEffect,useState} from 'react'
import { getUserLoginDetails } from "../../../redux/Actions/genexhome"
import { logout } from '../../../redux/Actions/auth'

import { useDispatch } from "react-redux";
import { useHistory,useParams } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay'
import { Loader, } from 'react-loaders';
import { Redirect,history } from "react-router-dom";


export default function Index() {

    const dispatch = useDispatch()
    const history = useHistory()
    const [check,setCheck]=useState(false)
    const params = useParams()
    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(()=>{
        dispatch(getUserLoginDetails(params.token,cb))
    },[])

    const cb=(val)=>{
        if(val){
            setCheck(true)
        }
        else{
            dispatch(logout())
            localStorage.removeItem("authDetails");
            history.push('/')
        }
    }

    return (
         <LoadingOverlay tag="div" active={true}
        styles={{
            overlay: (base) => ({
                ...base,
                background: '#fff',
                opacity: 0.5
            })
        }}
        spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
        <div style={{width:'100%',height:'100vh'}}>
            {
                check ?
                <Redirect to={`/dashboard`}/>
                :
                ''
            }
        </div>
        </LoadingOverlay>
    )
}
