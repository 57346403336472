import React, { useEffect, useState, useRef } from 'react';
import banner from "../../images/banner-6.png"
import logo from "../../images/logo.png"
import coursedummy from "../../images/coursedummy.png"
import previewdummy from "../../images/previewdummy.png"
import Terms from '../../SkillablersPayment'
// import digital from "../../images/digital-marketing-banner.jpg"
// import img3 from "../../images/img-3.jpg"
// import videoimage from "../../images/video-img.jpg"
import './style.scss'
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { development, razorpaykey } from '../../../../config.json'
import axios from 'axios'
import { updateGenexPaymentStatus } from '../../../../redux/Actions/auth'
import Modals from '../../../common/Modal'
import { Row, Col, Button, Badge, } from 'reactstrap'
import animationData from '../../../Dashboard/animation/check-circle.json'
import Lottie from 'react-lottie';
import errorAnimation from '../../../Dashboard/animation/error.json'

import { showCourseDetails, LoginedCoursedetails, setHomeData, ApplyCoupenCode, createOrder,LogoutSession } from "../../../../redux/Actions/genexhome"
import { generatePaymentLink } from "../../../../redux/Actions/Superadmin"
import CircularProgress from "@material-ui/core/CircularProgress";

import VideoCard from '../../../SuperadminComponents/Vendor/Vendordetails/Videocard'
import ReactHtmlParser from 'react-html-parser';
import spinner from '../../../../assets/animated/Spinner.gif'
import Swal from 'sweetalert2'
// import Model2 from "../../../common/Modal/index"
import { authCheck, logout } from '../../../../redux/Actions/auth'
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import Label from 'reactstrap/lib/Label';
import { Link } from 'react-router-dom'


import { Modal } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loaders'
import Chip from '@material-ui/core/Chip';
import ReactPlayer from 'react-player'

const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const certification = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#202A60', padding: '3px 20px', background: 'white', width: '96px', boxSizing: 'border-box', display: 'inline-block' }
const timeleft = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }

function SingleCourse() {

    const dispatch = useDispatch();
    const params = useParams()
    const history = useHistory();
    const copyRef = useRef(null);

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [successModal, setSuccessModal] = useState({ open: false })
    const [failModal, setFailModal] = useState({ fail: false, msg: '' })
    const [coupen_code, setCoupen_code] = useState('')

    const [copySuccess, setCopySuccess] = useState('');

    // const [group_id,setGroup_id]=useState('')
    // const [batchname,setBatchname]=useState('')

    const [loader, setLoader] = useState(false)
    const [open_coupen, setOpencoupen] = useState(false)
    const [open_payment_link, setOpenPayment] = useState({ message: '', open: false, link: '' })


    const Single_Course_Details = useSelector((state) => state.genexhome.Single_Course_Details ? state.genexhome.Single_Course_Details : '');
    const pay_laoder = useSelector((state) => state.auth.pay_laoder ? state.auth.pay_laoder : false);
    const video = Single_Course_Details ? Single_Course_Details.preview_video ? Single_Course_Details.preview_video : [] : []
    const frstvideo = video ? video[0] : ''
    const thumb = frstvideo ? frstvideo.thumbnails : []
    // const thumblink = thumb[1] ? thumb[1].link : previewdummy
    const thumblink = Single_Course_Details.camera_tag_thumbnail_path ? Single_Course_Details.camera_tag_thumbnail_path : thumb[1] ? thumb[1].link : previewdummy

    const dur = frstvideo ? frstvideo.video_duration : 'no'
    const purchased = Single_Course_Details ? Single_Course_Details.purchased ? Single_Course_Details.purchased : false : false

    const role = tokenData ? tokenData.role : ''
    const coupenloader = useSelector((state) => state.genexhome.coupenloader ? state.genexhome.coupenloader : false);
    const message = useSelector((state) => state.genexhome.message ? state.genexhome.message : '');
    const discounted_amount = useSelector((state) => state.genexhome.discounted_amount ? state.genexhome.discounted_amount : '');

    let lerning_length = Single_Course_Details.learning_contents ? Single_Course_Details.learning_contents.length : 0
    const half_lerning = (lerning_length / 2).toFixed(0)

    let liveclasscount = Single_Course_Details ? Single_Course_Details.live_classes ? Single_Course_Details.live_classes.length : 0 : 0
    let bannerurl = Single_Course_Details ? Single_Course_Details.header_banner_url ? Single_Course_Details.header_banner_url : banner : banner

    const group_id = useSelector((state) => state.genexhome.selected_gorup_id ? state.genexhome.selected_gorup_id : '');
    const batchname = useSelector((state) => state.genexhome.selected_batch_name ? state.genexhome.selected_batch_name : '');
    const selected_course_id = useSelector((state) => state.genexhome.selected_course_id ? state.genexhome.selected_course_id : 'no');

    const generatelink_loader = useSelector((state) => state.superadmin.generatelink_loader ? state.superadmin.generatelink_loader : false);

    const tags = Single_Course_Details ? Single_Course_Details.tags ? Single_Course_Details.tags : [] : []
    useEffect(() => {
        dispatch(setHomeData('login_from_course', false))
        dispatch(setHomeData('Single_Course_Details', ''))
        dispatch(setHomeData('message', ''))

        if (selected_course_id !== params.course_id) {
            dispatch(setHomeData('selected_gorup_id', ''))
            dispatch(setHomeData('selected_batch_name', ''))
            dispatch(setHomeData('selected_course_id', ''))
        }
        if (tokenData) {
            dispatch(authCheck(tokenData.token, cb, authSuccess))
        }
        else {
            dispatch(showCourseDetails(params.course_id, Reschedule))
        }
    }, [])

    const Reschedule = () => {
        if (Single_Course_Details.edit_status === 1) {
            Swal.fire({
                icon: 'warning',
                title: 'Rescheduling!',
                text: 'Will be available soon',
            }).then(() => {
                history.push('/')
            })
        }
    }
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successModal.open ? animationData : failModal ? errorAnimation : null,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    const [TermsAndConditions, setTermsAndConditions] = useState(false);
    const handleCloseModal = () => {
        setOrderData(null)
        // setTermsAndConditions(false)
    }

    const BuyCourse = () => {



        if (!tokenData) {
            Swal.fire({
                icon: 'warning',
                title: 'Please Login!',
                text: 'Log in to your account',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "LOGIN",
                cancelButtonText: "CANCEL",

            }).then((result) => {
                if (result.value) {
                    dispatch(setHomeData('login_from_course', true))
                    history.push('/login')

                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'Action cancelled',
                        'error'
                    )
                }
            })

        }
        // else{
        //     dispatch(authCheck(tokenData.token,cb,authSuccess))
        // }
        else if (liveclasscount > 0 && (Single_Course_Details.schedule_type === 'pre_scheduled' || Single_Course_Details.schedule_type === null) && !group_id) {
            Swal.fire({
                icon: 'warning',
                title: 'Select',
                text: "Choose a Batch"

            })
        }
        else {
            if (Single_Course_Details.schedule_type === 'post_scheduled') {
                setLoader(true)
                // displayRazorpay(params.course_id,Single_Course_Details.course_group_id ? Single_Course_Details.course_group_id:'')
                dispatch(createOrder(tokenData.token, params.course_id, Single_Course_Details.course_group_id ? Single_Course_Details.course_group_id : '', success))
                // setTermsAndConditions(true)
            }
            else {
                setLoader(true)
                dispatch(createOrder(tokenData.token, params.course_id, group_id, success))
                // displayRazorpay(params.course_id,group_id)

            }
        }
    }
    const [OrderData, setOrderData] = useState(null)

    const success = (data) => {
        setOrderData(data)
        //  setTermsAndConditions(true)
        setLoader(false)
    }

    const authSuccess = () => {
        dispatch(LoginedCoursedetails(tokenData.token, params.course_id, Reschedule))
    }

    const cb = () => {

        dispatch(LogoutSession(tokenData.token))
        localStorage.removeItem("authDetails");
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
        history.push('/login')
    }

    const displayRazorpay = async (course_id, group_id) => {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        } else {
            console.log("res",)

        }
        try {
            const order = await axios.post(`${development.baseUrl}createOrder`, { course_id: course_id, group_id: group_id, referral_code: coupen_code ? coupen_code : '' }, {
                headers: { Accept: 'application/json', Authorization: `Bearer ${tokenData.token}` },
                // headers: { Authorization: `Bearer ${tokenData.token}` }

            }).then((respon) => {
                if (respon.data.result) {
                    setLoader(false)
                    const ordrid = respon.data.order.order_id
                    const options = {
                        key: razorpaykey.razorkey,
                        // key:'rzp_test_ro6tnznEjwDx1J',
                        currency: respon.data.order.currency,
                        amount: respon.data.order.course_amount.toString(),
                        order_id: respon.data.order.payment_order_id,
                        name: 'SKILLABLERS',
                        // description: 'Test Transaction',
                        image: logo,
                        handler: function (response) {
                            // alert("hi")
                            // alert(response.razorpay_payment_id)
                            // alert(response.razorpay_order_id)
                            // alert(response.razorpay_signature)
                            const details = {
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_signature: response.razorpay_signature,
                                order_id: ordrid
                            }
                            dispatch(updateGenexPaymentStatus(tokenData.token, details, paymentStatus))
                        },
                        prefill: {
                            // name,
                            email: '',
                            phone_number: ''
                        }
                    }
                    const paymentObject = new window.Razorpay(options)
                    paymentObject.open()
                }
                else {
                    setLoader(false)
                    Swal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: respon.data.message
                    })
                }

            })
                .catch((err) => {
                    if (err) {
                        setLoader(false)
                        if (err.response) {
                            if (err.response.status === 401) {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Please Login!',
                                    text: 'Token Expired',
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "OK",
                                }).then((result) => {
                                    cb()
                                })
                            }
                        }
                    }
                })

            // console.log("order----",order)

        } catch (err) {
            console.log(err)
        }



        const paymentStatus = (res) => {

            if (res.result) {
                dispatch(LoginedCoursedetails(tokenData.token, params.course_id, call_dummy))
                setSuccessModal({ open: true, res })
            } else {
                setFailModal({ fail: true, msg: res.message })
            }
        }

        
        const call_dummy=()=>{
        console.log('')
    }

    }
    const call_dummy = () => {
        console.log('')
    }
    const closeSuccessModal = () => {
        dispatch({ type: 'PAYMENT_SUCCESSFULLY_COMPLETED' })
        dispatch(setHomeData('selected_gorup_id', ''))
        dispatch(setHomeData('selected_batch_name', ''))
        dispatch(setHomeData('selected_course_id', ''))
        dispatch(setHomeData('login_from_course', false))
        setSuccessModal({ ...successModal, open: false })
        history.push('/dashboard')
    }

    const HandleRadio = (val) => {
        // console.log('vvvvvvaaaaaaaaaaalllllllllll',val)
        // setGroup_id(val.batch_id)
        // setBatchname(val.batch_name)
        dispatch(setHomeData('selected_gorup_id', val.batch_id))
        dispatch(setHomeData('selected_batch_name', val.batch_name))
        dispatch(setHomeData('selected_course_id', params.course_id))

    }



    const [nowPlaying, setNowPlaying] = useState({ open: false, iframe: "<iframe></iframe>", camera_tag_url: '' })

    const openVideo = (player, thumbnile, processing, camera_tag_url) => {
        if (processing) {
            Swal.fire({
                icon: 'warning',
                title: 'Processing',
                // text: "Video on process"

            })
        } else {
            // dispatch(setReqData('prevent_idle_timeout',true))
            setNowPlaying({ open: true, iframe: player, camera_tag_url: camera_tag_url })

            // const [hours, minutes, seconds] = dur.split(':');
            // const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
            // setTimeout(()=>{

            // },[totalSeconds])
        }
    }

    // const [open,setOpen]=useState(false)
    // const [schedules,setSchedules]=useState([])
    // const ViewSchudules=(val)=>{
    //     // console.log(val)
    //     setSchedules(val)
    //     setOpen(true)
    // }

    // const CloseModel=()=>{
    //     setSchedules([])
    //     setOpen(false)
    // }

    const CoupenModel = () => {
        // window.scroll(0,150)
        // // alert('open')
        dispatch(setHomeData('message', ''))
        setMessageapplied(null)
        setCoupen_code('')
        setOpencoupen(true)
    }

    const CloseCoupenModel = () => {
        setOpencoupen(false)
    }

    const Handlechange = (e) => {
        setCoupen_code(e.target.value)
    }
    const Apply = () => {

        let splited = Single_Course_Details.course_fee.split(" "); // 123 INR 
        let amount = splited[0]; //["123","INR"]
        dispatch(ApplyCoupenCode(tokenData.token, coupen_code, amount, params.course_id, applied_callback))
    }


    // const [successModalCoupen, setSuccessModalCoupen] = useState(false);
    const [applied, setApplied] = useState(false);
    const [messageapplied, setMessageapplied] = useState(null)
    const applied_callback = () => {
        setApplied(true)
        // setSuccessModalCoupen(true)
        setMessageapplied('Applied Successfully')
        setTimeout(() => {
            CloseSuccessmodel()
        }, 500);

    }
    const CloseSuccessmodel = () => {
        setOpencoupen(false)
        // setSuccessModalCoupen(false)
    }


    const GenerateLink = () => {
        if (liveclasscount > 0 && (Single_Course_Details.schedule_type === 'pre_scheduled' || Single_Course_Details.schedule_type === null) && !group_id) {
            Swal.fire({
                icon: 'warning',
                title: 'Select',
                text: "Choose a Batch"

            })
        }
        else {
            if (Single_Course_Details.schedule_type === 'post_scheduled') {
                dispatch(generatePaymentLink(
                    tokenData.token,
                    params.course_id,
                    Single_Course_Details.course_group_id ? Single_Course_Details.course_group_id : '',
                    coupen_code ? coupen_code : '',
                    OpenModelCopylink
                ))
            }
            else {
                dispatch(generatePaymentLink(
                    tokenData.token,
                    params.course_id,
                    group_id,
                    coupen_code ? coupen_code : '',
                    OpenModelCopylink
                ))

            }

        }

    }

    const OpenModelCopylink = (res) => {
        setCopySuccess('');
        setOpenPayment({ message: res.message, open: true, link: res.payment_link })

    }
    const copyToClipboard = (e) => {
        copyRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('Link Copied!');
    }
    const LoginNoCoupon = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Please Login!',
            text: 'Log in to your account',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "LOGIN",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(setHomeData('login_from_course', true))
                history.push('/login')

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Action cancelled',
                    'error'
                )
            }
        })

    }
    return (
        <>



            {
                Single_Course_Details ?

                    <>

                        <LoadingOverlay
                            active={pay_laoder}
                            spinner
                            text='Loading...'
                        >
                            <section class="course-banner position-relative" style={{ backgroundImage: `url(${bannerurl})` }}>
                                <div class="container"></div>
                            </section>

                            <section class="padding-top-60px padding-bottom-80px" style={{ backgroundColor: '#ffffff' }}>
                                <div class="container">

                                    <div class="row">
                                        <div class="col-md-8 col-lg-8 col-12 ">
                                            <div class="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }} >
                                                <h3 class="font-weight-700 text-title-medium mb-0 wow fadeInUp" style={{ color: '#212529' }} data-wow-delay="0.2s">{Single_Course_Details.course_name}</h3>
                                                <p class="mb-1">
                                                    {
                                                        role ? role === 'student' ?
                                                            <>
                                                                {
                                                                    purchased ?
                                                                        <Badge color='success' style={{ marginTop: '3px' }}>Purchased</Badge>
                                                                        :
                                                                        <>
                                                                            {
                                                                                Single_Course_Details.certified === 1 ?
                                                                                    <div style={certification}>Certified</div>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                Single_Course_Details.days_left ?
                                                                                    <div style={timeleft}>{Single_Course_Details.days_left}</div>
                                                                                    : null
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    Single_Course_Details.certified === 1 ?
                                                                        <div style={certification}>Certified</div>
                                                                        : null
                                                                }
                                                                {
                                                                    Single_Course_Details.days_left ?
                                                                        <div style={timeleft}>{Single_Course_Details.days_left}</div>
                                                                        : null
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    Single_Course_Details.certified === 1 ?
                                                                        <div style={certification}>Certified</div>
                                                                        : null
                                                                }
                                                                {
                                                                    Single_Course_Details.days_left ?
                                                                        <div style={timeleft}>{Single_Course_Details.days_left}</div>
                                                                        : null
                                                                }
                                                            </>

                                                    }

                                                </p>
                                                <Link to={{ pathname: `/vendor/${Single_Course_Details.vendor_id}/${Single_Course_Details.skl_user_id}` }} >
                                                    <span style={{ fontFamily: 'revert', color: 'rgb(0 0 0)' }}>Provided by</span><span style={{ fontFamily: 'revert', color: 'rgb(20 73 144)', fontWeight: '600' }}> {Single_Course_Details.vendor_name}</span>
                                                </Link>

                                                <div class="row">
                                                    <div class="col-md-12 mt-1">
                                                        {
                                                            tags.map((val, ky) => {
                                                                return (
                                                                    <Chip
                                                                        key={ky}
                                                                        // size="small"
                                                                        label={val.tag_name}
                                                                        color="primary"
                                                                        variant='outlined'
                                                                        style={{ margin: '3px 0px 0px 3px' }}
                                                                        clickable
                                                                        onClick={() => { history.push(`/categories/${val.tag_id}`) }}
                                                                    />
                                                                )
                                                            })
                                                        }

                                                    </div>


                                                    <div class="col-md-12 col-xs-12 course-fee-col text-center mb-4 purchase_div_mobile" style={{ width: '95%', margin: 'auto' }}>

                                                        {
                                                            applied ?
                                                                <>
                                                                    <h2 style={{ textDecoration: 'line-through' }} class="text-white font-weight-700">{Single_Course_Details.course_fee}</h2>
                                                                    <h2 class="text-white font-weight-700">{discounted_amount}</h2>
                                                                </>
                                                                :
                                                                <h2 class="text-white font-weight-700">{Single_Course_Details.course_fee}</h2>

                                                        }
                                                        {
                                                            Single_Course_Details.live_classes.length > 0 && (Single_Course_Details.schedule_type === 'pre_scheduled' || Single_Course_Details.schedule_type === null) && purchased === false ?
                                                                <p class="font-weight-500 font-15 text-white">
                                                                    <a href="#" class="black-small-link floating" data-toggle="modal" data-target="#class_batch_selection">
                                                                        Choose Live Batch Time
                                                                    </a>
                                                                </p>
                                                                : null
                                                        }


                                                        {
                                                            Single_Course_Details.live_classes.length > 0 && (Single_Course_Details.schedule_type === 'pre_scheduled' || Single_Course_Details.schedule_type === null) && purchased === false ?
                                                                <>
                                                                    {
                                                                        batchname ?
                                                                            <p style={{ color: 'rgb(185 185 185)' }}>Selected Batch: <span style={{ color: '#efea52e8', fontSize: '14px', fontWeight: '500' }}>{batchname}</span>  </p>
                                                                            :
                                                                            <p style={{ color: 'rgb(185 185 185)' }}>Batch Not Selected Yet!</p>
                                                                    }
                                                                </>
                                                                : ''
                                                        }


                                                        {
                                                            Single_Course_Details.ondemand_classes.length > 0 && Single_Course_Details.schedule_type === 'pre_scheduled' && purchased === false ?
                                                                <p>
                                                                    <a href="#" data-toggle="modal" data-target="#ondemand" class="border-link">
                                                                        On Demand
                                                                    </a>
                                                                </p>
                                                                : ''
                                                        }


                                                        {
                                                            role ? role === 'student' ?
                                                                <>
                                                                    {
                                                                        purchased ?
                                                                            ''
                                                                            :
                                                                            <>
                                                                                <LaddaButton
                                                                                    className="btn btn-block btn-gn"
                                                                                    loading={loader}
                                                                                    type="submit"
                                                                                    onClick={BuyCourse}
                                                                                    data-style={ZOOM_IN}
                                                                                >
                                                                                    join Now
                                                                                </LaddaButton>
                                                                                {
                                                                                    applied ?
                                                                                        ''
                                                                                        :
                                                                                        <p style={{ cursor: 'pointer' }}><a data-toggle="modal" onClick={CoupenModel} class="white-link">Have a Coupon?</a></p>
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {/* not student */}
                                                                </>
                                                                :
                                                                <>
                                                                    <LaddaButton
                                                                        className="btn btn-block btn-gn"
                                                                        loading={loader}
                                                                        type="submit"
                                                                        onClick={BuyCourse}
                                                                        data-style={ZOOM_IN}
                                                                    >
                                                                        join Now
                                                                    </LaddaButton>

                                                                    <p style={{ cursor: 'pointer' }}><a data-toggle="modal" onClick={LoginNoCoupon} class="white-link">Have a Coupon?</a></p>
                                                                </>
                                                        }

                                                        {
                                                            tokenData && purchased === false && role === 'student' ?
                                                                <>
                                                                    {
                                                                        generatelink_loader ?
                                                                            <Loader loaded={true} lines={13} length={20} width={10} radius={30}
                                                                                corners={1} rotate={0} direction={1} color="white" speed={1}
                                                                                trail={60} shadow={false} hwaccel={false} className="spinner"
                                                                                zIndex={2e9} top="50%" left="50%" scale={1.00}
                                                                                loadedClassName="loadedContent" />
                                                                            :
                                                                            <span style={{ cursor: 'pointer', color: 'white' }} onClick={GenerateLink}>Generate Payment Link</span>

                                                                    }

                                                                </>
                                                                :
                                                                ''
                                                        }

                                                    </div>

                                                </div>
                                                <hr class="opacity-4 mt-2 mb-3" />


                                                <h3 class="font-weight-600 mt-4 mb-3 light-bg-title" style={{ color: '#212529' }}>What you'll learn</h3>
                                                <div class="row">
                                                    <div class="col-md-6 col-lg-6 col-12">
                                                        <ul class="course-learn-ul">

                                                            {
                                                                Single_Course_Details.learning_contents.map((val, ky) => {
                                                                    if (ky < half_lerning) {
                                                                        return (
                                                                            <li key={ky}>{val}</li>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (null)
                                                                    }

                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-6 col-lg-6 col-12">
                                                        <ul class="course-learn-ul">

                                                            {
                                                                Single_Course_Details.learning_contents.map((val, ky) => {
                                                                    if (half_lerning <= ky) {
                                                                        return (
                                                                            <li key={ky}>{val}</li>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (null)
                                                                    }

                                                                })
                                                            }
                                                        </ul>
                                                    </div>

                                                </div>

                                                <h3 class="font-weight-600 light-bg-title" style={{ color: '#212529' }}>Description</h3>
                                                <p class="font-15">
                                                    <div className='overwrite-style' >
                                                        {ReactHtmlParser(Single_Course_Details.description)}
                                                    </div>
                                                </p>
                                            </div>
                                        </div>

                                        {/* side panel */}
                                        <div class="col-md-4 col-lg-4 col-12">
                                            <div class="col-md-12 col-lg-12 col-12 course-fee-col text-center mb-4 purchase_div">
                                                {
                                                    applied ?
                                                        <>
                                                            <h2 style={{ textDecoration: 'line-through' }} class="text-white font-weight-700">{Single_Course_Details.course_fee}</h2>
                                                            <h2 class="text-white font-weight-700">{discounted_amount}</h2>
                                                        </>
                                                        :
                                                        <h2 class="text-white font-weight-700">{Single_Course_Details.course_fee}</h2>

                                                }
                                                {
                                                    Single_Course_Details.live_classes.length > 0 && (Single_Course_Details.schedule_type === 'pre_scheduled' || Single_Course_Details.schedule_type === null) && purchased === false ?
                                                        <p class="font-weight-500 font-15 text-white">
                                                            <a href="#" class="black-small-link floating" data-toggle="modal" data-target="#class_batch_selection">
                                                                Choose Live Batch Time
                                                            </a>
                                                        </p>
                                                        : null
                                                }


                                                {
                                                    Single_Course_Details.live_classes.length > 0 && (Single_Course_Details.schedule_type === 'pre_scheduled' || Single_Course_Details.schedule_type === null) && purchased === false ?
                                                        <>
                                                            {
                                                                batchname ?
                                                                    <p style={{ color: 'rgb(185 185 185)' }}>Selected Batch: <span style={{ color: '#efea52e8', fontSize: '14px', fontWeight: '500' }}>{batchname}</span>  </p>
                                                                    :
                                                                    <p style={{ color: 'rgb(185 185 185)' }}>Batch Not Selected Yet!</p>
                                                            }
                                                        </>
                                                        : ''
                                                }


                                                {
                                                    Single_Course_Details.ondemand_classes.length > 0 && Single_Course_Details.schedule_type === 'pre_scheduled' && purchased === false ?
                                                        <p>
                                                            <a href="#" data-toggle="modal" data-target="#ondemand" class="border-link">
                                                                On Demand
                                                            </a>
                                                        </p>
                                                        : ''
                                                }


                                                {
                                                    role ? role === 'student' ?
                                                        <>
                                                            {
                                                                purchased ?
                                                                    ''
                                                                    :
                                                                    <>
                                                                        <LaddaButton
                                                                            className="btn btn-block btn-gn"
                                                                            loading={loader}
                                                                            type="submit"
                                                                            onClick={BuyCourse}
                                                                            data-style={ZOOM_IN}
                                                                        >
                                                                            join Now
                                                                        </LaddaButton>
                                                                        {
                                                                            applied ?
                                                                                ''
                                                                                :
                                                                                <p style={{ cursor: 'pointer' }}><a data-toggle="modal" onClick={CoupenModel} class="white-link">Have a Coupon?</a></p>
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {/* not student */}
                                                        </>
                                                        :
                                                        <>
                                                            <LaddaButton
                                                                className="btn btn-block btn-gn"
                                                                loading={loader}
                                                                type="submit"
                                                                onClick={BuyCourse}
                                                                data-style={ZOOM_IN}
                                                            >
                                                                join Now
                                                            </LaddaButton>

                                                            <p style={{ cursor: 'pointer' }}><a data-toggle="modal" onClick={LoginNoCoupon} class="white-link">Have a Coupon?</a></p>
                                                        </>
                                                }

                                                {
                                                    tokenData && purchased === false && role === 'student' ?
                                                        <>
                                                            {
                                                                generatelink_loader ?
                                                                    <Loader loaded={true} lines={13} length={20} width={10} radius={30}
                                                                        corners={1} rotate={0} direction={1} color="white" speed={1}
                                                                        trail={60} shadow={false} hwaccel={false} className="spinner"
                                                                        zIndex={2e9} top="50%" left="50%" scale={1.00}
                                                                        loadedClassName="loadedContent" />
                                                                    :
                                                                    <span style={{ cursor: 'pointer', color: 'white' }} onClick={GenerateLink}>Generate Payment Link</span>

                                                            }

                                                        </>
                                                        :
                                                        ''
                                                }

                                            </div>

                                            <div class="col-md-12 col-lg-12 col-12 course-right-col">
                                                <h4 class="font-weight-600 mb-1">Preview Video</h4>
                                                <hr class="mt-0 mb-2" />
                                                <div class="video-col">
                                                    <div class="video-container mb-3">
                                                        <div style={{ cursor: 'pointer', backgroundSize: 'cover', borderRadius: '5px', height: '250px', width: '100%', position: 'relative' }} >

                                                            {
                                                                nowPlaying.open ?
                                                                    <>
                                                                        {
                                                                            nowPlaying.iframe || nowPlaying.camera_tag_url ?
                                                                                <div style={{ background: `url(${spinner})`, height: '100%', width: '100%', backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', marginBottom: '10px' }}>
                                                                                    {
                                                                                        nowPlaying.camera_tag_url ?
                                                                                            <div className='player-wrapper-course'>
                                                                                                <ReactPlayer
                                                                                                    url={nowPlaying.camera_tag_url}
                                                                                                    controls={true}
                                                                                                />
                                                                                            </div>
                                                                                            :
                                                                                            ReactHtmlParser(nowPlaying.iframe)
                                                                                    }
                                                                                </div>
                                                                                :

                                                                                <img src={previewdummy} alt='no video' width='100%' height='100%' />

                                                                        }
                                                                    </>
                                                                    :
                                                                    <VideoCard
                                                                        type="playlist"
                                                                        title={'Preview Video'}
                                                                        thumbnile={thumblink}
                                                                        duration={dur}
                                                                        openVideo={openVideo}
                                                                        embed={frstvideo.embed}
                                                                        processing={Single_Course_Details.processing}
                                                                        camera_tag_url={Single_Course_Details.camera_tag_url}
                                                                    />

                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <img src={Single_Course_Details.banner_url ? Single_Course_Details.banner_url : coursedummy} width='100%' height='250px' alt="" class="img-fluid" style={{ borderRadius: '4px', }} />
                                            </div>
                                        </div>

                                        {/* batch model */}
                                        <div class="modal fade" id="class_batch_selection" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="class_batch_selection" aria-hidden="true">
                                            <div class="modal-dialog large-modal modal-dialog-centered" role="document" style={{ boxShadow: 'none' }}>
                                                <div class="modal-content">
                                                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                                                        <h4 class="modal-title text-center text-blue w-100" id="exampleModalLongTitle">Live Class</h4>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form action="#">
                                                            <div class="col-md-12 col-lg-12 col-12" id="main">
                                                                {
                                                                    Single_Course_Details.batches.length > 0 ?
                                                                        <>
                                                                            <div class="accordion" id="faq">
                                                                                {
                                                                                    Single_Course_Details.batches.map((val, ky) => {
                                                                                        let tar = '#faq' + ky
                                                                                        let k = 'faq' + ky
                                                                                        let parentky = 'faqhead' + ky
                                                                                        return (
                                                                                            <div class="card" key={ky}>
                                                                                                <div class="card-header" style={{ display: 'block' }} id={parentky}>
                                                                                                    <div class="btn btn-header-link collapsed" data-toggle="collapse" data-target={tar}
                                                                                                        aria-expanded="true" aria-controls="faq1">
                                                                                                        <div class="col-md-12 col-lg-12 col-12">
                                                                                                            <div class="row">
                                                                                                                <div class="col-md-6 col-lg-6 col-12">
                                                                                                                    <div class="form-check mb-0">
                                                                                                                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" onChange={() => { HandleRadio(val) }} />
                                                                                                                        <label class="form-check-label" for="exampleRadios1">
                                                                                                                            <h4 style={{ fontWeight: '500' }}>{val.batch_name}</h4>
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-md-6 col-lg-6 col-12"><h4 class="mb-0">Total Classes '{val.live_classes ? val.live_classes.length : 0}'
                                                                                                                </h4></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div id={k} class="collapse" aria-labelledby={parentky} data-parent="#faq">
                                                                                                    <div class="card-body">
                                                                                                        <div class="col-md-12 col-lg-12 col-12">
                                                                                                            <div class="row">
                                                                                                                {
                                                                                                                    val.live_classes.length > 0 ?
                                                                                                                        val.live_classes.map((val2, ky2) => {
                                                                                                                            let a = val2.live_class_start_date
                                                                                                                            let b = val2.live_class_end_date
                                                                                                                            const s = a.slice(0, 10)
                                                                                                                            const e = b.slice(0, 10)
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {
                                                                                                                                        val2.schedule ? val2.schedule.map((val3, ky3) => {
                                                                                                                                            return (
                                                                                                                                                <>
                                                                                                                                                    <div key={ky3} class="col-md-3 col-lg-3 col-12">{val2.live_class_subject}</div>
                                                                                                                                                    <div class="col-md-2 col-lg-2 col-12">{val2.live_class_name}</div>
                                                                                                                                                    <div class="col-md-4 col-lg-4 col-12">{val3.day} {val3.from_time} To {val3.to_time}</div>
                                                                                                                                                    <div class="col-md-3 col-lg-3 col-12">{s} To {e}</div>

                                                                                                                                                </>
                                                                                                                                            )
                                                                                                                                        })
                                                                                                                                            :
                                                                                                                                            ''
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                        :
                                                                                                                        ''
                                                                                                                }

                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        ''
                                                                }
                                                                {
                                                                    batchname ?
                                                                        <div class="row">
                                                                            <div class="col-md-12 col-lg-12 col-12  text-center">
                                                                                <button type="button" class="btn btn-gn" data-dismiss="modal" aria-label="Close">
                                                                                    {/* <a href="#" class="btn btn-gn">Submit</a> */}
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* on demand model */}
                                        <div class="modal fade" id="ondemand" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="class_batch_selection" aria-hidden="true">
                                            <div class="modal-dialog large-modal modal-dialog-centered" role="document" style={{ boxShadow: 'none' }}>
                                                <div class="modal-content">
                                                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                                                        <h4 class="modal-title text-center text-blue w-100" id="exampleModalLongTitle">On Demand</h4>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="col-md-12 col-lg-12 col-12" id="main">
                                                            {
                                                                Single_Course_Details.ondemand_classes ?
                                                                    <>
                                                                        <div class="accordion" id="faq">
                                                                            {
                                                                                Single_Course_Details.ondemand_classes.map((val, ky) => {
                                                                                    let k = 'faq' + (ky + 1)
                                                                                    let tar = '#faq' + (ky + 1)
                                                                                    let parentky = 'faqhead' + (ky + 1)
                                                                                    return (
                                                                                        <div class="card" key={ky}>
                                                                                            <div class="card-header" style={{ display: 'block' }} id={parentky}>
                                                                                                <div class="btn btn-header-link collapsed" data-toggle="collapse" data-target={tar}
                                                                                                    aria-expanded="true" aria-controls={k}>
                                                                                                    <div class="col-md-12 col-lg-12 col-12">
                                                                                                        <div class="row">
                                                                                                            <div class="col-md-6 col-lg-6 col-12">
                                                                                                                <h4 class="mb-0">{val.ondemand_class_name}</h4>
                                                                                                            </div>
                                                                                                            <div class="col-md-6 col-lg-6 col-12">
                                                                                                                <h4 class="mb-0">{val.videos ? val.videos.length : 0} Videos</h4>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div id={k} class="collapse" aria-labelledby={parentky} data-parent="#faq">
                                                                                                <div class="card-body">
                                                                                                    <div class="col-md-12 col-lg-12 col-12">
                                                                                                        <div class="row">
                                                                                                            {
                                                                                                                val.videos.length > 0 ?
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            val.videos.map((v, ky2) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <div class="col-md-6 col-lg-6 col-12" key={ky2}>
                                                                                                                                            <div class="row">
                                                                                                                                                <div class="col-md-3 col-lg-3 col-12">
                                                                                                                                                    <img src={v ? v.thumbnails ? v.thumbnails[0].link : '' : ''} alt="" class="img-fluid" />
                                                                                                                                                </div>
                                                                                                                                                <div class="col-md-9 col-lg-9 col-12 my-auto">
                                                                                                                                                    <h5>{v.video_title}</h5>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div class="col-md-6 col-lg-6 col-12 my-auto">
                                                                                                                                            <p class="mb-2">{v.video_duration}</p>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    ''
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    : ''
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </LoadingOverlay>


                    </>

                    :
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </div>
            }
            <Modals
                open={successModal.open}
                // open={true}
                handleClose={closeSuccessModal}
                // title={`Time shedule`}
                size="sm"
            >

                <Row>
                    <Col md={12}>
                        <Lottie options={defaultOptions}
                            height={145}
                            width={145}
                        //isStopped={this.state.isStopped}
                        //isPaused={this.state.isPaused}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col md={12} style={{ fontSize: '20px', color: 'green', textAlign: 'center' }}>
                        Success
                    </Col>

                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: 'center', color: '#5e8e5f' }}>
                        <strong style={{ fontWeight: 400 }}>Payment completed successfully</strong>
                    </Col>
                </Row>
                <Row>

                    <Col md={12} style={{ textAlign: 'center' }}>
                        <Button type="button" onClick={closeSuccessModal} color="success">Okay</Button>
                    </Col>
                </Row>

            </Modals>


            <Modals
                open={failModal.fail}
                handleClose={() => setFailModal({ fail: false, msg: '' })}
                size="sm"
            >
                <Row>
                    <Col md={12}>
                        <Lottie options={defaultOptions}
                            height={145}
                            width={145}
                        //isStopped={this.state.isStopped}
                        //isPaused={this.state.isPaused}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col md={12} style={{ fontSize: '20px', color: '#c0392b', textAlign: 'center' }}>
                        Failed
                    </Col>

                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: 'center', color: '#ab2d20' }}>
                        <strong style={{ fontWeight: 400 }}>{failModal.msg}</strong>
                    </Col>
                </Row>
                <Col md={12} style={{ textAlign: 'center', paddingTop: 25 }}>
                    <Button type="button" onClick={() => setFailModal({ fail: false, msg: '' })} color="danger">Okay</Button>
                </Col>
            </Modals>


            <Modal
                isOpen={open_coupen}
                toggle={CloseCoupenModel}
                size={'md'}
            >
                <div class='class="modal-content"'>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                        <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Apply Coupon</h5>
                        <button type="button" class="close" onClick={CloseCoupenModel}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-lg-12 col-12">
                            <div class="row">
                                <div class="col-md-3 col-lg-3 col-12">
                                    <img src={Single_Course_Details.banner_url ? Single_Course_Details.banner_url : ''} alt="" width='100%' height='200px' class="img-fluid" />
                                </div>
                                <div class="col-md-6 col-lg-6 col-12 pl-md-1 pl-2 my-auto">
                                    <h4 class="mb-1">{Single_Course_Details.course_name}</h4>
                                    <p class="font-weight-600 text-blue">{Single_Course_Details.course_fee}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control coupon-input"
                                            placeholder="Enter coupon code"
                                            onChange={Handlechange}
                                            value={coupen_code}
                                            name="coupen"
                                            style={{ height: '50px' }}
                                        />
                                        <div class="input-group-append">
                                            <LaddaButton className="btn btn-secondary aply-cpn-btn"
                                                loading={coupenloader}
                                                type="button"
                                                onClick={() => Apply()}
                                                data-style={ZOOM_IN}
                                                disabled={coupen_code ? false : true}
                                            >
                                                APPLY COUPON
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Col xs={12} style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <span style={{ color: 'red' }}>{message ? message : ''}</span>
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <span style={{ color: 'green' }}>{messageapplied ? messageapplied : ''}</span>

                            </Col>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                isOpen={open_payment_link.open}
                toggle={() => { setOpenPayment({ message: '', open: false, link: '' }) }}
                size={'md'}
            >
                <div class='class="modal-content"'>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                        <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Payment Link</h5>
                        <button type="button" class="close" onClick={() => { setOpenPayment({ message: '', open: false, link: '' }) }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-lg-12 col-12">
                            <div class="row">
                                <div class="col-md-3 col-lg-3 col-12">
                                    <img src={Single_Course_Details.banner_url ? Single_Course_Details.banner_url : ''} alt="" width='100%' height='200px' class="img-fluid" />
                                </div>
                                <div class="col-md-6 col-lg-6 col-12 pl-md-1 pl-2 my-auto">
                                    <h4 class="mb-1">{Single_Course_Details.course_name}</h4>
                                    <p class="font-weight-600 text-blue">{Single_Course_Details.course_fee}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <hr />
                                </div>
                                <div class="col-md-12 col-lg-12 col-12">
                                    <span style={{ fontWeight: 'bold' }}>{open_payment_link.message}</span>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control coupon-input"
                                            placeholder="Enter coupon code"
                                            value={open_payment_link.link}
                                            style={{ height: '50px' }}
                                            ref={copyRef}
                                        />
                                        <div class="input-group-append">
                                            <LaddaButton className="btn btn-secondary aply-cpn-btn"
                                                type="button"
                                                data-style={ZOOM_IN}
                                                onClick={copyToClipboard}
                                            >
                                                COPY
                                            </LaddaButton>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Col xs={12} style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <span style={{ color: 'red' }}>{message ? message : ''}</span>
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <span style={{ color: 'green' }}>{copySuccess ? copySuccess : ''}</span>

                            </Col>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={OrderData}
                // handleClose={handleCloseModal}
                toggle={handleCloseModal}
                title={"Terms&Conditions"}
            // size='lg'
            >
                <div class='class="modal-content"'>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                        <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Order</h5>
                        <button type="button" class="close" onClick={() => { handleCloseModal(false) }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-lg-12 col-12">
                            <div style={{ display: 'flex', justifyContent: 'center' }} class="row">
                                <div style={{ textAlign: 'center' }} class="col-md-12 col-lg-12 col-12">
                                    <img style={{ borderRadius: '4px' }} src={Single_Course_Details.banner_url ? Single_Course_Details.banner_url : ''} alt="" width='200px' height='100px' class="img-fluid" />
                                </div>
                                <div style={{ textAlign: 'center', marginTop: '10px' }} class="col-md-12 col-lg-12 col-12 ">
                                    <div style={{ fontSize: '15pt', fontWeight: 'bold', color: '#000000' }} >{Single_Course_Details.course_name}</div>
                                    <div style={{ fontWeight: 'bold', color: '#003366' }}>{Single_Course_Details.course_fee}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <hr />
                                </div>
                                {OrderData &&
                                    <Terms
                                        course_id={OrderData.course_id}
                                        name={OrderData.name}
                                        email={OrderData.email}
                                        phone={OrderData.phone}
                                        reg_id={OrderData.reg_id}
                                        service_type={OrderData.service_type}
                                        url_page={OrderData.url_page}
                                        course_amount={OrderData.course_amount}
                                        mots_order_id={OrderData.order_id}
                                    />
                                }
                            </div>


                        </div>
                    </div>
                </div>

            </Modal>

        </>

    )
}

export default SingleCourse
