import React,{useEffect} from "react";
// import Container from "reactstrap/lib/Container";
import { renewalListForSubscription,setSubscriptionData } from "../../../redux/Actions/subscription-action"
import { useDispatch, useSelector } from 'react-redux'

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
// import nodata from '../../LandingPageNew/images/nodata.png'

import {Table,Card,CardHeader } from 'reactstrap';



function RenewalHistory(props) {

const dispatch = useDispatch();
const tokenData = JSON.parse(localStorage.getItem("authDetails"));

const renewalList_Data = useSelector((state) =>state.Subscription.renewalList_Data ? state.Subscription.renewalList_Data : []);
const renewalList_nextpage = useSelector((state) => state.Subscription.renewalList_nextpage ? state.Subscription.renewalList_nextpage : '');
const renewalList_prevpage = useSelector((state) => state.Subscription.renewalList_prevpage ? state.Subscription.renewalList_prevpage : '');
const renewalList_details_loader = useSelector((state) => state.Subscription.renewalList_details_loader ? state.Subscription.renewalList_details_loader : false);

const from = useSelector((state) => state.Subscription.renewalList_frome ? state.Subscription.renewalList_frome : '');
const to = useSelector((state) => state.Subscription.renewalList_to ? state.Subscription.renewalList_to : '');

const page = useSelector((state) => state.Subscription.page_renew_history ? state.Subscription.page_renew_history : 1);

useEffect(() => {
  if(props.order_id){
    dispatch(renewalListForSubscription(tokenData.token,props.order_id,page))
  }
}, [page])

const NextPage=()=>{
  dispatch(setSubscriptionData('page_renew_history',page+1))
}
const PreviousPage=()=>{
  dispatch(setSubscriptionData('page_renew_history',page-1))
}


  return (
        <div>
          {
            renewalList_Data.length > 0 ?
            <LoadingOverlay
                  active={renewalList_details_loader}
                  spinner
                  text='Loading...'
                >
                  <Card className="main-card mb-3">
                    <CardHeader>
                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal" style={{width:'50%'}}>
                        Renewal History List
                      </div>
                    </CardHeader>
     

                      <Table responsive borderless hover className="align-middle text-truncate mb-0">
                      <thead>
                          <tr>
                              <th>Order iD</th>
                              <th>Amount</th>
                              <th>Subscription Type</th>
                              <th>Payment Date</th>
                              <th>Expiry Date</th>
                          </tr>
                      </thead>
                    
                      <tbody>
                        {
                          renewalList_Data.map((val,ky)=>{
                            return(
                              <tr key={ky}>
                                  <td>#{val.order_id}</td>
                                  <td>{val.amount} {val.currency_code}</td>
                                  <td>{val.subscription_type}</td>
                                  <td>{val.payment_date}</td>
                                  <td>{val.expiry_date}</td>
                              </tr>
                            )
                          })
                        }
                     
                    </tbody>
    

                </Table>
          
                
                <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                      <div style={{width:'30%',display:'flex',height:'50px',justifyContent:'flex-end'}}>

                        <span style={{marginTop:'13px'}}>{from}-{to}</span>
                          <Button
                            onClick={PreviousPage}
                            disabled={renewalList_prevpage ? false :true}
                          >
                            <NavigateBeforeIcon />
                          </Button>
                          <Button
                            onClick={NextPage}
                            disabled={renewalList_nextpage ? false :true}
                          >
                            <NavigateNextIcon />
                          </Button>
                      </div>
              </div>
                </Card>
                </LoadingOverlay>
                :''
          }
        </div>

  );
}

export default RenewalHistory
