import React from 'react'
import './videoCard.scss'
import { Button, Row, Col,  } from 'reactstrap'
import {  useSelector } from "react-redux";
// import { useParams } from 'react-router-dom'
// import Swal from 'sweetalert2'
import Tooltip from '@material-ui/core/Tooltip';
// import { deletePlaylistVideos, updatePlaylistVideos } from '../../../redux/Actions/playlist'
// import { Progress } from "react-sweet-progress";
import { Progress } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
// import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
const Index = (props) => {

    const { thumbnile, embed, openVideo, data, title, duration, video_id, deleteVideo, openEdit, type,desc,check_progress,percent,playing,processing,file_url } = props
    
    const storeData = useSelector(({ playlist }) => playlist)

    

    const styles1={ cursor:'pointer',height:'200px',backgroundImage: `url(${thumbnile})`,backgroundRepeat:'no-repeat',backgroundSize:'cover' }
    const styles2={ cursor:'pointer',height:'200px',backgroundImage: `url(${thumbnile})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',border:'2px solid rgb(28 49 152 / 59%)' }
    return (
        <>
            <div className="jms-choose-from-playlist-thumb-card" style={playing ? styles2 : styles1}
                onClick={type === "playlist" ? () => openVideo(embed, thumbnile,processing,file_url) : null}
            >
               
                <div className="jms-choose-from-playlist-thumb-title" > {title}</div>
                <div className="jms-choose-from-playlist-thumb-size">{duration}</div>
                {/* {thumbnile.includes('default') && <div className="jms-thumb-card-processing">{"Processing"}</div>} */}
                {processing && <div className="jms-thumb-card-processing">{"Processing"}</div>}


            </div >
            
            
            <div className="jms-choose-from-playlist-thumb-actions" style={playing ? {border:'2px solid rgb(28 49 152 / 59%)'}:{}}>
                
                {
                    type === "player" ?
                    
                        <Row>
                            <Col xs={12}>
                            {
                                check_progress ? 
                                    <Progress className="progress-bar-xs" color="danger" value={parseInt(percent)}><span style={{fontSize:'9px'}}>{parseInt(percent)}%</span></Progress>
                                :
                                ''
                            }
                            </Col>
                            <Col md={12}>
                                <Tooltip arrow title={ReactHtmlParser(desc)}>    

                                    <div style={{ cursor:'pointer',fontSize: 13, margin: 0, fontWeight: 500,width:'100%',minHeight:'20px',overflow:'hidden',wordWrap:'break-word',borderBottom:'1px solid #cac1c1' }}>
                                        {
                                            desc ? desc.length > 43 ? 
                                            <span>{ReactHtmlParser(desc).slice(0,40)}...</span>
                                            :
                                            <span>{ReactHtmlParser(desc)}</span>
                                            :''

                                        }
                                    </div>
                                    </Tooltip>

                            </Col>
                        </Row>
                       
                        : <Row>

                            <Col md={6}>
                                <Button onClick={() => openEdit(video_id, title)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="info" >Edit</Button>

                            </Col>
                            <Col md={6}>
                                <Button onClick={() => deleteVideo(video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="danger">{storeData.deleteVideoLoader ? 'Deleting...' : 'Delete'}</Button>


                            </Col>
                        </Row>
                }

            </div>
        </>
    )
}

export default Index
