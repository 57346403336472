import React, { Fragment } from "react";

// import im1 from "../../images/softskill.png";
import { Link } from "react-router-dom";
import nodata from "../../images/nodata.png"
// import im1 from "../../images/icon/gym-1.png";

function Category(props) {
    // const history = useHistory();

    // const Showcat = (val) => {
    //     history.push(`/categories/${val.category_id}`);
    // };
    return (
        <Fragment>

            <section class="padding-tb-80px" style={{ backgroundColor: '#f5f6f8' }}>
                <div class="cou-catewid">
                    <div class="text-center  margin-bottom-35px wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                        <h3 style={{ color: '#212529' }} class="font-weight-700 text-title-large"><span class="fw-200">COURSE </span>CATEGORIES</h3>
                    </div>

                    {
                        props.cat_section.length > 0 ?
                            ""
                            :
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <img src={nodata} width='500px' height='300px' loading='lazy' alt='' />
                            </div>
                    }


                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="row">
                            {
                                props.cat_section.map((val, ky) => {
                                    return (

                                        <div class="col-lg-2 col-md-3 pl-2 pr-2 mb-2 mt-2">
                                            <Link to={{ pathname: `/categories/${val.category_id}` }}>
                                                <div class="category-col-inner text-center">
                                                    <h5>{val.category_name}</h5>
                                                    <p class="mb-0">Course [{val.total_courses}]</p>
                                                </div>
                                            </Link>
                                        </div>
                                       
                                    )
                                })
                            }
                            
                        </div>
                    </div>

                    <div class="row">
                        {
                            props.cat_section.length > 0 ?
                                <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                                    <Link to="/categories" class="btn btn-gn border-radius-30 wow fadeInUp" data-wow-delay="0.2s">View All</Link>
                                </div>
                                : ''
                        }
                    </div>

                </div>
            </section>
        </Fragment>
    );
}
export default Category;
