import React, {  useState, useEffect } from 'react';
import { Row, Col,Input,TabContent, TabPane, Nav, NavItem, NavLink,Card,CardHeader,Badge,CardBody } from 'reactstrap'
// import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import { emailValidator } from '../../common/Validators';
import Table from '../table';
import { showTeachers,  deleteUser, updateTeacher } from '../../../redux/Actions/videolist'
import Modals from '../../common/Modal';
// import Teacher from '../Teacher';
import EditTeacher from './EditTeacher';
import EditPrivilege from '../EditPrivilege';
import Swal from 'sweetalert2'
import { nameValid, requiredValidator, mobileValidator, emailValidator } from '../../../utilities/Validator';
import Label from 'reactstrap/lib/Label';
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { blockTeacherAndMigrateClass,addTeacherAndMigrateClass } from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';
import classnames from 'classnames';
import Teacher from "./CreateTeacher"

const columns = [
    {title: 'First_Name ', field: 'first_name',},
    { title: ' Last_Name', field: 'last_name' },
    { title: 'Email', field: 'email' },
    { title: 'Password', field: 'user_password' },
    { title: 'Mobile', field: 'teacher_mobile' },
    { title: 'Status', field: 'block',
    render: (rowData) => { 
        return(
            rowData.block===1 ?
            <Badge color="danger" pill>Blocked</Badge>
            :
            <Badge color="success" pill>Active</Badge>
        )
    } 
    }
]


const ShowTeacher = () => {
    const dispatch = useDispatch()
    const tStore = useSelector(({ videolist }) => videolist)
    
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [successModal, setSuccessModal] = useState(false);
    const createteacher_block_loader = useSelector((state) =>state.superadmin.createteacher_block_loader ? state.superadmin.createteacher_block_loader : false);
    const blockteacher = useSelector((state) =>state.superadmin.blockteacher ? state.superadmin.blockteacher : false);

    

    useEffect(() => {
        dispatch(showTeachers(tokenData.token))
        
    }, [])

    const [openModal, setOpenModal] = useState(false)
    const [openPermissionModal, setPermissionModal] = useState(false)
    const [userId, setUserId] = useState()
    const [openblock, setOpenblock] = useState(false)
    const [teachername, setTeachername] = useState('')
    const [teacherdata, setTeacherdata] = useState('')
    const [state, setState] = useState({
        activeTab: '1',
    })
    const [inputField, setInputField] = useState({})
    const [errors, setErrors] = useState({})
    const [errlist, setErrlist] = useState([])

    const handleCloseStudentModal = () => {
        setOpenModal(false)
    }

    const onEdit = (rowData) => {
        // console.log(rowData);

        const err = {
            enterprise_email: emailValidator(rowData.email),
            first_name: nameValid(rowData.first_name),
            last_name: nameValid(rowData.last_name),
            user_password: requiredValidator(rowData.user_password),
            enterprise_mobile: mobileValidator(rowData.teacher_mobile),

        }
        if (err.enterprise_email) {
            Swal.fire(
                'Email',
                err.enterprise_email,
                'warning'
            )
        } else if (err.first_name) {
            Swal.fire(
                'First name',
                err.first_name,
                'warning'
            )
        } else if (err.last_name) {
            Swal.fire(
                'Last name',
                err.last_name,
                'warning'
            )
        } else if (err.user_password) {
            Swal.fire(
                'Password',
                err.user_password,
                'warning'
            )
        } else if (err.enterprise_mobile) {
            Swal.fire(
                'Mobile',
                err.enterprise_mobile,
                'warning'
            )
        } else {
            dispatch(updateTeacher(tokenData.token, rowData))

        }


    }
    const [userID, setUserID] = useState()
    const onPermission = (user_id) => {
        dispatch({ type: 'RESET_EDIT_PRIVILEGE' })

        setUserID(user_id)
        setPermissionModal(true)
    }

    const handleClosePermissionModal = () => {
        setPermissionModal(false)
    }

    const onDelete = (user_id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',

            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteUser(tokenData.token, user_id,cbdelete))

                // Swal.fire(
                //     'Deleted!',
                //     'Deleted teacher successfully.',
                //     'success'
                // )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }

    const BlockUnblock=(val)=>{
        setState({
            activeTab: '1'
        });
        if(val.block===1){
            Swal.fire({
                icon: 'warning',
                title: 'Are you sure !',
                text: 'Do you want to unblock this teacher?',
        
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "CONFIRM",
                cancelButtonText: "CANCEL",
        
            }).then((result) => {
                if (result.value) {
                    dispatch(blockTeacherAndMigrateClass(tokenData.token,val.user_id,'',handleOpenSuccessModal))
                } 
                // else if (result.dismiss === Swal.DismissReason.cancel) {
                //     Swal.fire({
                //         icon:'success',
                //         // title:'Something Went Wrong',
                //         text:'Action Cancelled'
                //     } 
                // )
                // }
            })
        }
        else{
            setTeacherdata(val.user_id)
            setTeachername('')
            setErrlist([])
            setOpenblock(true)
        }
        
    }

    const handleCloseBlock=()=>{
        setTeachername('')
        setTeacherdata('')
        setOpenblock(false)
    }
    const Submit=()=>{
        dispatch(blockTeacherAndMigrateClass(tokenData.token,teacherdata,teachername,cb))
    }
    const ChnageTeacher=(e)=>{
        setTeachername(e.target.value)
        // if(e.target.value){
        //     let t=tStore.teacherList.find((val)=>val.user_id===e.target.value)
        //     setTeacherdata(t)
        // }
        // else{
        //     setTeacherdata('')
        // }
        
    }
    const cb=()=>{
        dispatch(showTeachers(tokenData.token))
        setTeachername('')
        setErrlist([])
        setOpenblock(false)
        setSuccessModal(true)
    }

    const cbdelete=()=>{
        dispatch(showTeachers(tokenData.token))
    }

    const handleOpenSuccessModal = () => {
        dispatch(showTeachers(tokenData.token))
        setErrlist([])
        setOpenblock(false)
        setSuccessModal(true)
    }
    const errModel=(val)=>{
        setErrlist(val)
    }
    const handleCloseSuccesstModal=()=>{
        setSuccessModal(false)
    }

    const toggle=(tab)=>{
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
    }

    let err;
    let invalid;
    const CreateTeacher=()=>{
        err = {
            first_name: nameValid(inputField.first_name),
            last_name: nameValid(inputField.last_name),
            enterprise_mobile: mobileValidator(inputField.teacher_mobile),
            enterprise_email: emailValidator(inputField.email),
            password: requiredValidator(inputField.password),
        }
        invalid = err.first_name || err.last_name || err.enterprise_mobile || err.enterprise_email || err.password

        if (!invalid) {
            setErrors(err)
            inputField.teacher_id=teacherdata
            let p=["1","2","","27","28","","","","3","","","4","","","7","","","","","","","","","","","",
                    "","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",""]
            inputField.permission=p
            inputField.entry='single'
            inputField.role='3'
            inputField.user_id=''
            dispatch(addTeacherAndMigrateClass(tokenData.token, inputField,handleOpenSuccessModal,errModel ))
        } else {
            setErrors(err)
        }
    }
    return (
        <>
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Show Facilitator'}
                        columns={columns}
                        actions={"show_user"}
                        loader={tStore.userSubLoader}
                        data={tStore.teacherList}
                        loader={tStore.userSubLoader}
                        onEdit={onEdit}
                        onPermission={(user_id) => onPermission(user_id)}
                        onDelete={(user_id) => onDelete(user_id)}
                        BlockUnblock={(val) => BlockUnblock(val)}

                    />
                </Col>

            </Row>
            <Modals
                open={openModal}
                handleClose={handleCloseStudentModal}
                title={`Edit Form`}
            >
                <EditTeacher
                    user_id={userId}
                />
            </Modals>
            <Modals
                open={openPermissionModal}
                handleClose={handleClosePermissionModal}
                title={`Permission`}
            >
                <EditPrivilege
                    user_id={userID}
                    handleClose={handleClosePermissionModal}

                />
            </Modals>

            <Modals
                open={openblock}
                handleClose={handleCloseBlock}
                title={`Block & Migrate Facilitator`}
            >
                <Row>
                    <Col xs={12}>
                        <Card>
                        <CardHeader tabs={true}>
                            <Nav justified>
                                <NavItem>
                                    <NavLink href="#"
                                        className={classnames({ active: state.activeTab === '1' })}
                                        onClick={() => toggle('1')}
                                    >
                                        Choose
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"
                                        className={classnames({ active: state.activeTab === '2' })}
                                        onClick={() => toggle('2')}
                                    >
                                        Create
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={state.activeTab} style={{padding:'0px 20px'}}>
                                <TabPane key="1" tabId="1" >
                                    <Row>
                                    <Col xs={12}>
                                        <Label>
                                            <span style={{color:'red'}}>*</span>
                                        Choose Facilitator
                                        </Label>
                                    </Col>

                                        <Col xs={12}>
                                            <Input 
                                                type='select' 
                                                name='teachername' 
                                                value={teachername} 
                                                onChange={ChnageTeacher}>
                                                    <option value=''>{'Select'}</option>
                                                    {
                                                        tStore.teacherList.map((val,ky)=>{
                                                            return(
                                                                <option value={val.user_id} key={ky}>{val.first_name} {val.last_name}</option>
                                                            )
                                                        })
                                                    }
                                                </Input>
                                        </Col>
                                        
                                        <Col xs={12} className='mt-2 mb-2'>
                                            <LaddaButton
                                                    className="mb-2 mr-2 btn btn-primary"
                                                    loading={blockteacher}
                                                    type="submit"
                                                    onClick={Submit}
                                                    data-style={ZOOM_IN}
                                                    disabled={teachername ? false:true}
                                                >
                                                    Migrate
                                            </LaddaButton>
                                        </Col>

                                    </Row>
                                </TabPane>
                            </TabContent>
                            <TabContent activeTab={state.activeTab}  style={{padding:'0px 20px'}}>
                                <TabPane key="2" tabId="2" >
                                    <Teacher
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                    <Row>
                                        {
                                            errlist.length > 0 ? 
                                            <Col xs={12} className='mt-2 mb-2'>
                                                <ul>
                                                    {
                                                        errlist.map((error,ky) => <li key={ky} style={{ color: 'red'}}>{error}</li>)
                                                    }
                                                </ul>
                                                
                                            </Col>
                                            :
                                            ''
                                        }

                                        <Col xs={12} className='mt-3'>
                                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                loading={createteacher_block_loader}
                                                type="submit"
                                                data-style={ZOOM_IN}  
                                                onClick={CreateTeacher}
                                                >
                                                ADD & MIGRATE
                                            </LaddaButton>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Modals>

            <SweetAlert
                title="Success"
                confirmButtonColor=""
                show={successModal}
                text= {"Successfully completed"}
                type="success"
                onConfirm={() => handleCloseSuccesstModal()} />
        </>
    )
}

export default ShowTeacher;