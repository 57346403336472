import React, { Fragment, useState } from 'react';
// import ScriptTag from 'react-script-tag';
import { Link } from 'react-router-dom'
import logo from "../../images/logo-white.png"
// import logoblue from "../../assets/images/Skillablers_logo1.png"
// import Logocompo from "../Logo/Logo"
import SimpleMap from "../Map/Googlemap"
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Divider } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

function Footer() {

    const social_media_links = useSelector((state) => state.genexhome.HomeArray ? state.genexhome.HomeArray.social_media_links ? state.genexhome.HomeArray.social_media_links : [] : []);

    const [open, setOpen] = useState(false)
    return (
        <Fragment>
            {console.log("social_media_links",social_media_links)}
            <footer class="padding-top-80px" style={{ backgroundColor: 'black' }}>
                <div class="container" style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                    <div class="sm-mb-30px wow ">
                        <ul class="list-inline text-left margin-tb-20px margin-lr-0px text-white">
                            <li>
                                <a href={"https://www.skillablers.com/"} target="_blank" >
                                    <img width="200px" src={logo} alt="" />
                                </a>
                            </li>
                            <li class="padding-tb-5px"><a href="mailto:connect@skillablers.com" class="text-grey-2"><i class="fa fa-envelope mr-2"></i>connect@skillablers.com</a></li>
                            <li class="padding-tb-5px">
                                <i class="fa fa-map-marker mr-2"></i>
                                SKILLABLERS Technologies PVT.LTD.<br/>
                                EDC Conclave, Off ITPL Road, <br/>
                                Bangalore 560 037, INDIA
                            </li>
                                {
                                    social_media_links.length > 0 ? social_media_links.map((val, ky) => {
                                        if (val.link) {
                                            return (
                                                <li key={ky} class="list-inline-item"><a href={val.link} target="_blank" class={`f-s-link ${val.type}`} style={{borderRadius:'50%'}}><i class={val.icon}></i></a></li>
                                            )
                                        }

                                    })
                                        : ''
                                }

                        </ul>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {/* <button onClick={() => { setOpen(true) }} style={{ borderRadius: '21px', backgroundColor: 'white', color: '#202A60', padding: '8px 20px', border: 'none', fontSize: '14px', lineHeight: '17px', marginBottom: '10px' }}>
                            View Location on map
                        </button> */}
                        <div style={{ width: '309px', height: '200px' }}>
                            <SimpleMap />
                        </div>
                    </div>
                </div>

                <div class='container' style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',width:'100%',color:'white'}}>
                    <span> &copy; Skillablers Technologies PVT LTD. 2023 All rights reserved</span>
                    <span>Powered By MOTS</span>
                </div>
                <div class="row padding-bottom-20px padding-top-10px wow fadeInUp"></div>
            </footer>

            {/* <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <div style={{ width: '100%', height: '250px' }}>
                        <SimpleMap />
                    </div>
                </DialogContent>
            </Dialog> */}
        </Fragment>
    )
}

export default React.memo(Footer)
