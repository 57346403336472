import React,{useState,useEffect} from "react";
import {
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody,
    CardHeader, Button,
   
} from 'reactstrap';
import classnames from 'classnames';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TableRow,Grid } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useHistory } from "react-router-dom";
import { setReqData,populateGroupStudents } from '../../../../../redux/Actions/Superadmin'
import { getAssessmentDetails } from "../../../../../redux/Actions/genexhome"
import { useDispatch,useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Model2 from "../../../../common/Modal/index"
// import Model from "../../Model"
import VisibilityIcon from '@material-ui/icons/Visibility';
import Swal from "sweetalert2";
import { development } from '../../../../../../src/config.json'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    color:'black'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}))

function Index(props) {

  const batch=props.data? props.data.batches ? props.data.batches:[]:[]
  const live=props.data? props.data.live_classes ? props.data.live_classes:[]:[]
  const demand=props.data? props.data.ondemand_classes ? props.data.ondemand_classes:[]:[]

    const [tab,setTab]=useState(props.tabval)
    const [expanded, setExpanded] = useState(false);
    const [expandedlive, setExpandedLive] = useState(false);

    const [Open,setOpen]=useState(false)
    const [viewStudnt,setviewStudnt]=useState(false)

    const [Sche,setSchedule]=useState([])
    const [head,sethead]=useState('')


    const classes = useStyles();
    const dispatch = useDispatch();
    const history=useHistory();
    const params = useParams()

    const GroupStudentArray = useSelector((state) =>state.superadmin.GroupStudentArray ? state.superadmin.GroupStudentArray :[] );

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const role=tokenData.role

    ///
    const user_details = props.data ? props.data.user_details ? props.data.user_details : {} : [];
    //const CourseAssesmentDetails = useSelector((state) => state.genexhome.CourseAssesmentDetails ? state.genexhome.CourseAssesmentDetails : {});
    const ModuleAssesmentDetails = useSelector((state) => state.genexhome.ModuleAssesmentDetails ? state.genexhome.ModuleAssesmentDetails : {});
    //console.log('ModuleAssesmentDetails-->',ModuleAssesmentDetails)
    ///

    
  // useEffect(() => {
  //   if (tokenData.role === 'student') {
  //     dispatch(getAssessmentDetails(params.course_id,user_details.skl_user_id))
  //   }
  // }, [user_details.skl_user_id])

    useEffect(()=>{
        dispatch(setReqData('selected_demand',''))
        toggle(props.tabval)
    },[props.tabval])

    const toggle=(val)=> {
        
        if (tab !== val) {
            setTab(val)
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
  };

  const handleChangeLive = (panel) => (event, isExpanded) => {
    setExpandedLive(isExpanded ? panel : false);
};

const VideoList=(val)=>{
    dispatch(setReqData('selected_demand',val))
    
    if(role==='superAdmin')
      history.push(`/dashboard/vendor/course/${val.ondemand_class_id}/videos`)
    else if(role==='principal'){
      history.push(`/dashboard/showCourse/${val.ondemand_class_id}/videos`)
    }
    else{
      if(val.playable){
        ///////////props.id is from the course page that loader from the outside link,props.is is just used to replace params.order_id,
        // (id is not importent)
        history.push(`/dashboard/viewCourse/${val.ondemand_class_id}/${params.order_id ? params.order_id:props.id}/videos`)
      }
      else{
        Swal.fire({
          icon: "warning",
          title: "can't access",
          text: "complete above classes to access this class",
      })
      }
    }
}

const Viewschedule=(val)=>{
  setSchedule(val.schedule)
  let h='Schedules of ' + val.live_class_name
  sethead(h)
  setOpen(true)
}
const CloseModel=()=>{
  setSchedule([])
  sethead('')
  setOpen(false)
}

const ViewStudent=(val)=>{
  // console.log('vaaaaallllll',val)
    dispatch(populateGroupStudents(tokenData.token,val.batch_id))
    let h='Student List of ' + val.batch_name
    sethead(h)
    setviewStudnt(true)
}

const CloseviewStudent=()=>{
  sethead('')
  setviewStudnt(false)
}

const playable={color:'black',cursor:'pointer'}
const notplayable={color:'red',cursor:'pointer'}

/////////skl Assessment///////////
const redirecttoQuiz = (ondemand)=>{
  let Assessment = ModuleAssesmentDetails.length>0 ? ModuleAssesmentDetails.filter(x=>x.Module_Id==ondemand.ondemand_class_id)[0]:{};
  console.log('Assessment-->',Assessment);
  if(ondemand.percentage === 100 && ModuleAssesmentDetails.length>0){
      let obj = {'Quiz_Id':Assessment.Quiz_Id,"CANUID":user_details.skl_user_id,"IsRetake":Assessment.IsRetake,"RedirectURL":window.location.href}
      obj = btoa(JSON.stringify(obj))
      let URL =  development.assessmentUrl +'?'+ obj;
      let a = document.createElement('a');
     // a.target = '_blank';
      a.href = URL;
      a.click();
  }

}
///////////////////////

  return (
    <Card tabs="true" className="mb-3" style={{marginTop:'15px'}}>
      {
        live.length > 0  || demand.length > 0 ?
        <>
        <CardHeader>
        <Nav justified>
          {
            live.length > 0 ? 
            <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: tab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Batches
            </NavLink>
          </NavItem>
          :null
          }
          
          {
            live.length > 0 ? 
            <NavItem>
            <NavLink
              href="#"
              className={classnames({ active: tab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Live Class
            </NavLink>
          </NavItem>
          :null
          }
          
          {
            demand.length > 0 ? 
            <NavItem>
              <NavLink
                href="#"
                className={classnames({ active: tab=== "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                On Demand Class
              </NavLink>
          </NavItem>
          :null
          }
          
        </Nav>
      </CardHeader>
      <CardBody className="card-mob">
        <TabContent activeTab={tab}>
          {
            live.length > 0 ? 
            <TabPane tabId="1">
            {
                batch.length>0 ?
                    <>
                        {
                            batch.map((val,ky)=>{
                                return(
                                    <Accordion key={ky} expanded={expanded === ky} onChange={handleChange(ky)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            >
                                            <Typography className={classes.heading}>{val.batch_name}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                      
                                            <Grid container>
                                              {
                                                (role==='superAdmin' || role==='principal') ?
                                                <Grid item xs={12}>
                                                  <Tooltip title='View all students'>
                                                    <Button className="mb-2 mr-2" size='sm' color="info" onClick={()=>{ViewStudent(val)}}>View Students</Button>
                                                  </Tooltip>
                                                </Grid>
                                                :''
                                              }
                                              
                                              <Grid item xs={12}>
                                              <Table  aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Class Name</TableCell>
                                                        <TableCell>Subject</TableCell>
                                                        <TableCell>Start Date</TableCell>
                                                        <TableCell>End Date</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        val.live_classes.map((i,k)=>{
                                                            return(
                                                                <TableRow key={k} style={{cursor:'pointer'}}>
                                                                        <TableCell>{i.live_class_name}</TableCell>
                                                                        <TableCell>{i.live_class_subject}</TableCell>
                                                                        <TableCell>{i.live_class_start_date}</TableCell>
                                                                        <TableCell>{i.live_class_end_date}</TableCell>
                                                                        <TableCell>
                                                                        <Tooltip title='View Schedule' arrow >
                                                                          <VisibilityIcon style={{color:'black',cursor:'pointer'}} onClick={()=>{Viewschedule(i)}}/>
                                                                          </Tooltip>
                                                                        </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }   
                                                </TableBody>
                                            
                                            </Table>
                                              </Grid>
                                            </Grid>
                                           
                                            
                                        </AccordionDetails>
                                    </Accordion>
                                )
                                
                            })
                        }
                    </>
                :'No Data'
            }
          </TabPane>
            :
            null
          }
          
          {
            live.length > 0 ? 
            <TabPane tabId="2">
          {
                live.length>0 ?
                    <>
                    <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{maxWidth:'70px'}}>Class Name</TableCell>
                                    <TableCell align='left'>Subject</TableCell>
                                    <TableCell>Timing</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>

                        {
                            live.map((val,ky)=>{
                              let s=val.live_class_start_date ? val.live_class_start_date :''
                              let e=val.live_class_end_date ? val.live_class_end_date:''
                              let start=s ? s.slice(0,10):s
                              let end=e ? e.slice(0,10):e
                                return(
                                    <Accordion key={ky} expanded={expandedlive === ky} onChange={handleChangeLive(ky)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                            >
                                            <Typography className={classes.heading}>{val.live_class_name}</Typography>
                                            <Typography className={classes.heading}>{val.subject_name}</Typography>
                                            <Typography className={classes.secondaryHeading} style={{marginRight:'5px'}}>From</Typography>
                                            <Typography >{start}</Typography>
                                            <Typography  className={classes.secondaryHeading} style={{margin:'0px 5px'}}>To</Typography>
                                            <Typography>{end}</Typography>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                            <Table  aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Batch Name</TableCell>
                                                        {/* <TableCell>Subject</TableCell> */}
                                                        <TableCell>Day</TableCell>
                                                        <TableCell>Start Time</TableCell>
                                                        <TableCell>End Time</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                        {
                                                            val.schedule.map((i,k)=>{
                                                                return(

                                                                    <TableRow key={k}>
                                                                        <TableCell>{i.batch_name}</TableCell>
                                                                        {/* <TableCell>{i.subject_name}</TableCell> */}
                                                                        <TableCell>{i.day}</TableCell>
                                                                        <TableCell>{i.class_from_time}</TableCell>
                                                                        <TableCell>{i.class_to_time}</TableCell>
                                                                    </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                                
                            })
                        }
                    </>
                :'No Data'
            }
          </TabPane>
          :
          null

          }
          

          {
            demand.length > 0 ?
            <TabPane tabId="3">
              {
                demand.length > 0 ?
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Class Name</TableCell>
                            <TableCell>Videos</TableCell>
                            {
                              role==='student' ? 
                              <TableCell>Viewed Percentage</TableCell>
                              :''
                            }
                            {/* <TableCell></TableCell> */}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            demand.map((val,k)=>{
                                return(
                                    <TableRow key={k}>
                                        <TableCell>{val.ondemand_class_name}</TableCell>
                                        {
                                            val.videos.length > 0 ?
                                            <TableCell>
                                                <Tooltip title='View Video List'>
                                                <span onClick={()=>{VideoList(val)}}>
                                                  <PlayCircleFilledIcon style={val.playable ? playable : notplayable}/>
                                                </span>
                                                </Tooltip>

                                            </TableCell>
                                            :''
                                        }
                                        {
                                          role==='student' ? 
                                          <TableCell>{val.percentage ? val.percentage:0}%</TableCell>
                                          :''
                                        }
                                        {
                                          role==='student'  && ModuleAssesmentDetails.length > 0 && ModuleAssesmentDetails.filter(x=>x.Module_Id==val.ondemand_class_id).length > 0 ? 
                                        <TableCell>
                                          <button className={val.percentage === 100 ?"btn btn-primary butn-mob":"btn btn-primary opac-view"} 
                                           disabled={val.percentage == 100 ? false : true} onClick={()=>{redirecttoQuiz(val)}}
                                        > { ModuleAssesmentDetails.filter(x=>x.Module_Id==val.ondemand_class_id)[0].IsRetake == 1 ? "Retake Assessment":"Take Assessment" }  </button>
                                        </TableCell>
                                        :""
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            :
            'No Demand Classes'
              }
            
          </TabPane>
          :
          null
          }
          
        </TabContent>
      </CardBody>



      <Model2
          open={Open}
          handleClose={CloseModel}
          title={head}
      >
         <Table  aria-label="simple table">
              <TableHead>
                  <TableRow>
                      <TableCell>Batch Name</TableCell>
                      <TableCell>Day</TableCell>
                      <TableCell>Start Time</TableCell>
                      <TableCell>End Time</TableCell>
                  </TableRow>
              </TableHead>
           
                <TableBody>
                {
                  Sche.map((val,ky)=>{
                    return(
                      <TableRow key={ky}>
                          <TableCell>{val.batch_name}</TableCell>
                          <TableCell>{val.day}</TableCell>
                          <TableCell>{val.from_time}</TableCell>
                          <TableCell>{val.to_time}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
              
          </Table>
      </Model2>

      <Model2
      open={viewStudnt}
      title={head}
      handleClose={CloseviewStudent}
      >
          <Table  aria-label="simple table">
              <TableHead>
                  <TableRow>
                      <TableCell>Student Name</TableCell>
                      <TableCell>Phone</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {
                  GroupStudentArray.map((val,ky)=>{
                      return(
                        <TableRow key={ky}>
                              <TableCell>{val.full_name}</TableCell>
                              <TableCell>{val.enterprise_mobile}</TableCell>
                        </TableRow>
                      )
                  })
                }
              </TableBody>
              </Table>
      </Model2>
        </>
        :''
        // <CardBody>
        // <CardTitle>
        //     Class Details
        // </CardTitle>
        //           <span style={{fontSize:'16px',color:'green'}}>No Data Yet</span>
        // </CardBody>
      }
      
    </Card>
  );
}

export default React.memo(Index)
