import React,{useEffect,useState} from 'react';
import banner from "../../images/sub-banner.jpeg"
import logo from "../../images/logo.png"
import subscriptionimage from "../../images/subscription_image.png"


import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Terms from '../../SkillablersPayment/index'
import { useDispatch,useSelector } from "react-redux";
import { development,razorpaykey } from '../../../../config.json'
import axios from 'axios'
import Modals from '../../../common/Modal'
import { Modal } from 'reactstrap';
import { Row, Col, Button,Badge } from 'reactstrap'
import animationData from '../../../Dashboard/animation/check-circle.json'
import Lottie from 'react-lottie';
import errorAnimation from '../../../Dashboard/animation/error.json'

import CircularProgress from "@material-ui/core/CircularProgress";

import ReactHtmlParser from 'react-html-parser';
import Swal from 'sweetalert2'
import {  authCheck,logout } from '../../../../redux/Actions/auth'
import LaddaButton, { ZOOM_IN } from "react-ladda";
import LoadingOverlay from 'react-loading-overlay';
import { setHomeData,showSubscriptionDetails,getSubscriptionDetails,updatePurchaseSubscription,purchaseSubscription,LogoutSession  } from "../../../../redux/Actions/genexhome"




function SingleSubscription() {

    const dispatch = useDispatch();
    const params = useParams()
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const role=tokenData ? tokenData.role:''

    const [loader,setLoader]=useState(false)
    const [successModal, setSuccessModal] = useState({open:false})
    const [failModal, setFailModal] = useState({fail:false,msg:''})


    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successModal.open ? animationData :failModal? errorAnimation:null,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const Subscription_details = useSelector((state) =>state.genexhome.Single_Subscription_Details ? state.genexhome.Single_Subscription_Details : '');
    const purchased= useSelector((state) =>state.genexhome.Single_Subscription_Details ? state.genexhome.Single_Subscription_Details.purchased ? state.genexhome.Single_Subscription_Details.purchased :false:false);
    const pay_loader = useSelector((state) =>state.genexhome.pay_loader ? state.genexhome.pay_loader : false);

    
    useEffect(()=>{
        dispatch(setHomeData('login_from_subscription',false))
        if(tokenData){
            dispatch(authCheck(tokenData.token,cb,authSuccess))
        }
        else{
            dispatch(showSubscriptionDetails(params.subscription_id))
        }
    },[])

    const authSuccess = () => {
        dispatch(getSubscriptionDetails(tokenData.token,params.subscription_id))
    }

    const handleCloseModal = () => {
        setOrderData(null)
        // setTermsAndConditions(false)
    }
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }


    const BuyCourse=()=>{
       
        if(!tokenData){
            Swal.fire({
                icon: 'warning',
                title: 'Please Login!',
                text: 'Log in to your account',
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "LOGIN",
                cancelButtonText: "CANCEL",
        
            }).then((result) => {
                if (result.value) {
                    dispatch(setHomeData('login_from_subscription',true))
                    history.push('/login')
        
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire(
                        'Cancelled',
                        'Action cancelled',
                        'error'
                    )
                }
            })
        
        }
        else{
           // displayRazorpay(params.subscription_id)
           dispatch(purchaseSubscription(tokenData.token, params.subscription_id, success))

        }
    }
    const [OrderData, setOrderData] = useState()
    const success = (data) => {
        setOrderData(data)
        console.log("data log",data)
        //  setTermsAndConditions(true)
        setLoader(false)
    }
    const displayRazorpay= async (subscription_id)=>{
        setLoader(true)
        const res=await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if(!res){
            alert('Razorpay SDK failed to load. Are you online?')
            setLoader(false)
            return
        }
        else{
             console.log("res",)
        }
            try {
                const order = await axios.post(`${development.baseUrl}purchaseSubscription`, { subscription_id: subscription_id }, {
                    headers: { Accept: 'application/json',Authorization: `Bearer ${tokenData.token}`  },
                    // headers: { Authorization: `Bearer ${tokenData.token}` }

                }).then((respon) => {
                    if (respon.data.result) {
                        setLoader(false)
                        const ordrid=respon.data.order.order_id
                        const options = {
                            key: razorpaykey.razorkey,
                            // key:'rzp_test_ro6tnznEjwDx1J',
                            currency: respon.data.order.currency,
                            amount: respon.data.order.course_amount.toString(),
                            order_id: respon.data.order.payment_order_id,
                            name: 'SKILL ABLERS',
                            image: logo,
                            handler: function (response) {
 
                                const details = {
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    razorpay_order_id: response.razorpay_order_id,
                                    razorpay_signature: response.razorpay_signature,
                                    order_id:ordrid
                                }
                                dispatch(updatePurchaseSubscription(tokenData.token, details, paymentStatus))
                            },
                            prefill: {
                                email: '',
                                phone_number: ''
                            }
                        }
                        const paymentObject = new window.Razorpay(options)
                        paymentObject.open()
                    }
                    else{
                        setLoader(false)
                        Swal.fire({
                            icon: 'warning',
                            title: 'Error',
                            text: respon.data.message
                        })  
                    }

                })
                .catch((err)=>{
                    if (err) {
                        setLoader(false)
                        if (err.response) {
                            if (err.response.status === 401) {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Please Login!',
                                    text: 'Token Expired',
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "OK",                        
                                }).then((result)=>{
                                    cb()
                                })
                            }
                        }
                    }
                })
                
            } catch (err) {
                console.log(err)
            }
    }

    const cb = () => {

        dispatch(LogoutSession(tokenData.token))
        localStorage.removeItem("authDetails");
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
        history.push('/login')
    }

    const paymentStatus = (res) => {

        if (res.result) {
            dispatch(getSubscriptionDetails(tokenData.token,params.subscription_id))
            setSuccessModal({open:true,res})
        } else {
            setFailModal({fail:true,msg:res.message})
        }
    }

    const closeSuccessModal=()=>{
        dispatch({type:'PAYMENT_SUCCESSFULLY_COMPLETED'})
        setSuccessModal({...successModal,open:false})
        dispatch(setHomeData('login_from_subscription',false))
        history.push('/dashboard')
    }

    
    return (
        <div>
            {
                Subscription_details ? 
                <>
                 <LoadingOverlay
                    active={pay_loader}
                    spinner
                    text='Loading...'
                >
                <section class="course-banner position-relative" style={{backgroundImage:`url(${banner})`}}>
                <div class="container"></div>
                </section>

                <section class="padding-top-60px padding-bottom-80px" style={{backgroundColor:'#ffffff'}}>
                    <div class="container">

                        <div class="row">
                            <div class="col-md-8 col-lg-8 col-12 ">
                                <div class="wow fadeInUp" style={{visibility:'visible',animationName:'fadeInUp'}} >
                                    <h3 class="font-weight-700 text-title-medium mb-0 wow fadeInUp" style={{color:'#212529'}} data-wow-delay="0.2s">{Subscription_details.subscription_name}</h3>
                                    <p class="mb-3">
                                        {
                                            role ?  role==='student' ? 
                                            <>
                                            {
                                                purchased ? 
                                                    <Badge color='success' style={{marginTop:'3px'}}>Purchased</Badge>
                                                    :
                                                    <>
                                                    {
                                                        Subscription_details.days_left ?
                                                        <span class="mr-1 course-badges badge badge-dark">{Subscription_details.days_left}</span>
                                                        :null
                                                    }
                                                    </>
                                            }
                                            </>
                                            :
                                            <>
                                            {
                                            Subscription_details.days_left ?
                                                <span class="mr-1 course-badges badge badge-dark">{Subscription_details.days_left}</span>
                                                :null
                                            }
                                            </>
                                            :
                                            <>
                                            {
                                            Subscription_details.days_left ?
                                            <span class="mr-1 course-badges badge badge-dark">{Subscription_details.days_left}</span>
                                            :null
                                            }
                                            </>
                                            
                                        }
                                        
                                    </p>
                                    <hr class="opacity-4 mt-2 mb-3" />
                                    
                                    <h3 class="font-weight-600 light-bg-title" style={{color:'#212529'}}>Description</h3>
                                    <p class="font-15">
                                        {ReactHtmlParser(Subscription_details.description)}
                                    </p>
                                </div>
                            </div>

                        <div class="col-md-4 col-lg-4 col-12">
                        <div class="col-md-12 col-lg-12 col-12 course-fee-col text-center mb-4">

                                <h2 class="text-white font-weight-700">{Subscription_details.subscription_fee} {Subscription_details.currency_code}</h2>
                                {
                                    role ? role==='student' ? 
                                    <>
                                        {
                                            purchased ? 
                                            ''
                                            :
                                            <>
                                            <LaddaButton
                                                className="btn btn-block btn-danger"
                                                loading={loader}
                                                type="submit"
                                                onClick={BuyCourse}
                                                data-style={ZOOM_IN}
                                            >
                                                Subscribe
                                            </LaddaButton>
                        
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                    {/* not student */}
                                    </>
                                    :
                                    <>
                                        <LaddaButton
                                            className="btn btn-block btn-danger"
                                            loading={loader}
                                            type="submit"
                                            onClick={BuyCourse}
                                            data-style={ZOOM_IN}
                                        >
                                            Subscribe
                                        </LaddaButton>
                                        
                                    </>
                                }
                            
                        
                        </div>

                            <div class="col-md-12 col-lg-12 col-12 course-right-col">
                                <h4 class="font-weight-600 mb-1">Preview Logo</h4>
                                <hr class="mt-0 mb-2" />
    
                                    <div class="clearfix"></div>
                                    <img src={subscriptionimage} width='100%' height='250px' alt="" class="img-fluid" style={{borderRadius:'4px',}} />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>    
                </LoadingOverlay>


                <Modals
                    open={successModal.open}
                    // open={true}
                    handleClose={closeSuccessModal}
                    // title={`Time shedule`}
                    size="sm"
                >

                    <Row>
                        <Col md={12}>
                            <Lottie options={defaultOptions}
                                height={145}
                                width={145}
                            //isStopped={this.state.isStopped}
                            //isPaused={this.state.isPaused}
                            />
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} style={{ fontSize: '20px', color: 'green', textAlign: 'center' }}>
                            Success
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: 'center', color: '#5e8e5f' }}>
                            <strong style={{ fontWeight: 400 }}>Payment completed successfully</strong>
                        </Col>
                    </Row>
                    <Row>
                
                        <Col md={12} style={{ textAlign: 'center' }}>
                            <Button type="button" onClick={closeSuccessModal} color="success">Okay</Button>
                        </Col>
                    </Row>

                </Modals>


                <Modals
                    open={failModal.fail}
                    handleClose={() => setFailModal({fail:false,msg:''})}
                    size="sm"
                >
                    <Row>
                        <Col md={12}>
                            <Lottie options={defaultOptions}
                                height={145}
                                width={145}
                            //isStopped={this.state.isStopped}
                            //isPaused={this.state.isPaused}
                            />
                        </Col>
                    </Row>
                    <Row >
                        <Col md={12} style={{ fontSize: '20px', color: '#c0392b', textAlign: 'center' }}>
                            Failed
                        </Col>

                    </Row>
                    <Row>
                        <Col md={12} style={{ textAlign: 'center', color: '#ab2d20' }}>
                            <strong style={{ fontWeight: 400 }}>{failModal.msg}</strong>
                        </Col>
                    </Row>
                    <Col md={12} style={{ textAlign: 'center', paddingTop: 25 }}>
                        <Button type="button" onClick={() => setFailModal({fail:false,msg:''})} color="danger">Okay</Button>
                    </Col>
                </Modals>
                </>
                :
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress />
                </div>
            }
              <Modal
                isOpen={OrderData}
                // handleClose={handleCloseModal}
                toggle={handleCloseModal}
                title={"Terms&Conditions"}
            // size='lg'
            >
                <div class='class="modal-content"'>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                        <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Order</h5>
                        <button type="button" class="close" onClick={() => { handleCloseModal(false) }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-lg-12 col-12">
                            <div style={{ display: 'flex', justifyContent: 'center' }} class="row">
                                <div style={{ textAlign: 'center' }} class="col-md-12 col-lg-12 col-12">
                                    <img style={{ borderRadius: '4px' }} src={subscriptionimage} alt="" width='200px' height='100px' class="img-fluid" />
                                </div>
                                <div style={{ textAlign: 'center', marginTop: '10px' }} class="col-md-12 col-lg-12 col-12 ">
                                    <div style={{ fontSize: '15pt', fontWeight: 'bold', color: '#000000' }} >{Subscription_details.subscription_name}</div>
                                    <div style={{ fontWeight: 'bold', color: '#003366' }}>{Subscription_details.subscription_fee}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <hr />
                                </div>
                            {OrderData &&
                                <Terms
                                    course_id={OrderData.subscription_id} // subscription_id
                                    name={OrderData.name}
                                    email={OrderData.email}
                                    phone={OrderData.phone}
                                    reg_id={OrderData.reg_id}
                                    service_type={OrderData.service_type}
                                    url_page={OrderData.url_page}
                                    course_amount={OrderData.course_amount}
                                    mots_order_id={OrderData.order_id}
                                />
                            }
                            </div>


                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default SingleSubscription
