
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { schoolDashboard } from '../../../redux/Actions/school'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {
//     AreaChart, Area, LineChart, Line,
//     ResponsiveContainer,
// } from 'recharts';
import {
  Row, Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button
} from 'reactstrap';
// import { useHistory } from "react-router-dom";
import CountUp from 'react-countup';
import PageTitle from '../../common/HeaderTitle/PageTitle'
// import Slider from './Slider'
import './style.css'
function Index() {

  // const history = useHistory()
  // const schoolStore = useSelector(({ school }) => school);
  //console.log('rslll', resellerStore)
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  useEffect(() => {
    dispatch(schoolDashboard(tokenData.token, cb))
  }, [])

  const [Counters, setCounters] = useState()

  const handleCopy = () => {
    navigator.clipboard.writeText(Counters.referral_code)
    toast.info('Copied to clipboard', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }

  const cb = (data) => {
    setCounters({
      balance: data.balance,
      referral_code: data.school.referral_code
    })


  }

  // console.log("counters", Counters)

  // const [Counter, setCounter] = useState()
  // const [SchoolSliderData, setSchoolSliderData] = useState()
  // const [StudentSliderData, setStudentSliderData] = useState()

  return (
    <div>
      <PageTitle

        heading="School Dashboard"
        subheading="Monitor you account here"
        icon="pe-7s-users icon-gradient bg-happy-itmeo"
      />
      {Counters &&
        <Row>
          <Col sm="12" md="6" xl="4">
            <div className="card mb-3 widget-chart">
              <div className="widget-chart-content">
                <div className="icon-wrapper rounded">
                  <div className="icon-wrapper-bg bg-success" />
                  <i className="lnr-apartment text-success" />
                </div>
                <div className="widget-numbers">
                  <CountUp start={0}
                    end={6}
                    separator=""
                    decimals={0}
                    decimal="."
                    prefix=""
                    useEasing={false}
                    suffix=""
                    duration="3" />
                </div>
                <div
                  className="widget-subheading fsize-1 pt-2 opacity-10 text-success font-weight-bold">
                  Total Sales
                    </div>
                {/* <div className="widget-description opacity-8">
                                <span className="text-danger pr-1">
                                <FontAwesomeIcon icon={faAngleDown}/>
                                <span className="pl-1">54.1%</span>
                            </span>
                        down last 30 days
                    </div> */}
              </div>
              <div className="widget-chart-wrapper">
                {/* <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                        <LineChart data={data123}
                                   margin={{top: 0, right: 25, left: 25, bottom: 0}}>
                            <Line type='monotone' dataKey='pv' stroke='var(--warning)' strokeWidth={3}/>
                        </LineChart>
                    </ResponsiveContainer> */}
              </div>
            </div>
          </Col>
          <Col sm="12" md="6" xl="4">
            <div className="card mb-3 widget-chart ctnr" >

              <div className="icon-wrapper rounded">
                <div className="icon-wrapper-bg bg-primary" />
                <i className="lnr-users text-primary" />
              </div>
              <div style={{ height: '66px' }} >
                <h1 className='pt-3' >{Counters.referral_code} </h1>
                <Button className="but" size="sm" onClick={handleCopy} >copy to clipboard</Button>
              </div>
              <div
                className=" fsize-1 pt-2 text-primary font-weight-bold rfc">
                Refferal code
                </div>

            </div>
          </Col>
          {/* <Col sm="12" md="6" xl="4">
            <div className="card mb-3 widget-chart">
           
                <div className="icon-wrapper rounded">
                  <div className="icon-wrapper-bg bg-primary" />
                  <i className="lnr-users text-primary" />
                </div>
                <div style={{height:'74px'}} >
               <h1>{Counters.referral_code} </h1> 
               <Button size="sm" onClick={() => {navigator.clipboard.writeText(Counters.referral_code)}} > copy</Button>
                </div>
                <div
                  className="widget-subheading fsize-1 pt-2 opacity-10 text-primary font-weight-bold">
                  Refferal code
                    </div>
               
            
              
            </div>
          </Col> */}
          <Col sm="12" md="12" xl="4">
            <div className="card mb-3 widget-chart">
              <div className="widget-chart-content">
                <div className="icon-wrapper rounded">
                  <div className="icon-wrapper-bg bg-info" />
                  <i className="pe-7s-wallet text-info" />
                </div>
                <div className="widget-numbers text-danger">
                  <CountUp start={0}
                    end={Counters.balance}
                    separator=""
                    decimals={0}
                    useEasing={false}
                    suffix=""
                    duration="3" />
                </div>
                <div
                  className="widget-subheading fsize-1 pt-2 opacity-10 text-info font-weight-bold">
                  Balance
                    </div>
                {/* <div className="widget-description opacity-8">
                        Down by
                        <span className="text-success pl-1">
                                <FontAwesomeIcon icon={faAngleDown}/>
                                <span className="pl-1">21.8%</span>
                            </span>
                    </div> */}
              </div>
              <div className="widget-chart-wrapper">
                {/* <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                        <AreaChart data={data123}
                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                            <Area type='stepAfter' dataKey='uv' strokeWidth='2' stroke='var(--info)'
                                  fill='var(--info)' fillOpacity='.4'/>
                        </AreaChart>
                    </ResponsiveContainer> */}
              </div>
            </div>
          </Col>
        </Row>
      }
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Recent Sales</CardTitle>
              {/* {1 &&
                <Slider sdata={null} school={true} handleDetailsClick={""} />
              } */}
            </CardBody>
            <CardFooter className="text-center d-block p-3">

              <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg"
              //   onClick={() => { history.push('dashboard/schools') }}
              >
                <Row>

                  <span className="mr-1">

                    View All Sales
                </span>

                </Row>

              </Button>
            </CardFooter>
          </Card>
        </Col>

      </Row>

      <ToastContainer 
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>

  )
}

export default Index
