import React, { Fragment, useState } from "react";

import about from "../LandingPageNew/images/about.png";
import image from "../LandingPageNew/images/forgot-password.jpg";


import "../LandingPageNew/css/genex-custom.css"
import "../LandingPageNew/css/custome.css"
import { useDispatch,useSelector } from 'react-redux'

import { recoverPassword } from '../../redux/Actions/auth'

import {
    ToastContainer,
    toast,

} from 'react-toastify';
// import { is } from "date-fns/locale";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';


const ForgotPassword = () => {

    const loadrecover = useSelector((state) =>state.auth.loadrecover ? state.auth.loadrecover : false);

    const dispatch = useDispatch()
    const [inputField, setInputField] = useState('')
    const [emty, setEmty] = useState('')

    const handleChange = (e) => {
        setInputField(e.target.value)

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if(inputField){
            dispatch(recoverPassword(inputField, cb, cb2))
        }
        else{
            setEmty('*email field in required')
        }
    }

    const cb = (res) => {
        if (res.result) {
            toast["success"](res.message, { containerId: 'A' });

        } else if (res.result === false) {
            toast["danger"](res.message, { containerId: 'A' });

        }
    }
    const [error, setError] = useState({
        result: false,
        message: ''
    })
    const cb2 = (msg) => {
        setError({
            result: true,
            message: msg
        })
    }
    const onDismiss = () => {
        setError({
            result: false,
            message: ''
        })
    }


    return (

        

        <Fragment>
             <ToastContainer containerId={'A'} />
             <section class="padding-top-60px padding-bottom-60px" style={{backgroundColor:'#ffffff'}}>
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-md-9 col-lg-9 col-12">
                            <div class="row form-main-col">
                                <div class="col-md-6 col-lg-6 col-12 order-lg-1 order-2">
                                    <img src={image} alt="" class="img-fluid" />
                                </div>
                                <div class="col-md-6 col-lg-6 col-12 login-col order-lg-2 order-1">
                                    <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 mt-lg-5 mt-2">
                                        <form onSubmit={handleSubmit}>
                                            <h3 class="text-center mt-2">Forgot your Password?</h3>
                                            <p class="font-14 text-center font-weight-500">Use the form below to recover it.</p>
        
                                            {
                                                error.result ? <Alert color="danger" isOpen={true} toggle={onDismiss}
                                                >{error.message}
                                                </Alert> : null
                                            }

                                            <div class="col-md-12 col-lg-12 col-12">
                                                <hr class="opacity-5" />
                                                <div class="input-group input-group-sm">
                                                    <div class="input-group-prepend form-group-prepend">
                                                        <span class="input-group-text" id=""><i class="fa fa-envelope"></i></span>
                                                    </div>
                                                    <input 
                                                        type="email" 
                                                        class="form-control gn-form-control" 
                                                        aria-label="Small" 
                                                        placeholder="Email" 
                                                        aria-describedby="" 
                                                        onChange={handleChange}
                                                        name="email" 
                                                        id="exampleEmail"
                                                        />
                                                </div>
                                                <span style={{color:'red'}}>{emty}</span>
                                            </div>

                                            <div class="col-md-12 col-lg-12 col-12" style={{marginTop:'15px'}}>
                                                {/* <input type="submit"
                                                     class="form-input-btn btn-block"
                                                      value="Recover Password" /> */}
                                                    <LaddaButton
                                                        className="form-input-btn btn-block"
                                                        loading={loadrecover}
                                                        type="submit"
                                                        data-style={ZOOM_IN}
                                                    >
                                                        Recover Password
                                                    </LaddaButton>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12">
                                                <p class="text-center mb-0 mt-3 font-12">
                                                    <Link to="/login" class="font-12 text-link">Sign in existing account</Link></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section class="padding-tb-80px bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="">
                                <div class="margin-bottom-20px font-weight-300 wow fadeInUp">
                                    <h1 class="text-title-large  fw-700 font-1 mb-3 text-capitalize" style={{lineHeight:'56px'}}><span class="fw-200">Skillablers</span> <br/>Virtual Edu Park</h1>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12 wow fadeInUp">
                                        <p class="text-para">Tomorrow will be a new dawn.What we make of it is entirely in our own hands.Change is desirable; change is inevitable.Change in fact has been forced upon us.Whether we use the opportunity to advantage or let it pass us by will decide whether the future will shock us or we will create shock- absorbers that will in fact use the impact to cushion us in our journey to a better tomorrow. To experience the next generation education join our virtual community.</p>
                                        <p><Link to="/studentRegister"  class="btn btn-gn border-radius-30 wow fadeInUp" data-wow-delay="0.5s">Signup Now</Link></p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="col-lg-6">
                            <img src={about} alt="" class="img-fluid wow fadeInRight" data-wow-delay="0.2s" />
                        </div>
                    </div>
                </div>
        </section>
        </Fragment>
    );

}
export default ForgotPassword