import React, { useEffect, useState } from 'react'
import DataTable from "./table";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useParams,Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Row,
    Col,
    Container,
    Breadcrumb, BreadcrumbItem
} from "reactstrap";
import { getAllSubscriptionPurchasedStudent, getStudentSubscriptionOndemandVideoClasses, setReqData } from '../../../redux/Actions/Superadmin'
import Model from "../../common/Modal/index"
import spinner from '../../../assets/animated/Spinner.gif'
import { TableRow } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import nodata from '../../LandingPageNew/images/nodata.png'
import cx from 'classnames';

const columns = [
    { title: "Name", field: "first_name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "parent_mobile" },
]
export default function StudentList() {

    const dispatch = useDispatch();
    const params = useParams()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [open, setOpen] = useState(false)

    const students = useSelector((state) => state.superadmin.StudentList ? state.superadmin.StudentList : []);
    const purchased_loader = useSelector((state) => state.superadmin.purchased_loader ? state.superadmin.purchased_loader : false);

    const OndemandDetails = useSelector((state) => state.superadmin.OndemandDetails ? state.superadmin.OndemandDetails : []);
    const ondemand_loader = useSelector((state) => state.superadmin.ondemand_loader ? state.superadmin.ondemand_loader : false);
    const coursename = useSelector((state) => state.superadmin.coursename ? state.superadmin.coursename : '');
    const enabled = useSelector((state) => state.superadmin.ondemand_video_enabled ? state.superadmin.ondemand_video_enabled : false);



    useEffect(() => {
        dispatch(getAllSubscriptionPurchasedStudent(tokenData.token, params.subscription_id))
    }, [])
    const ViewStudents = (id) => {
        dispatch(getStudentSubscriptionOndemandVideoClasses(tokenData.token, params.subscription_id, id))
        setOpen(true)
    }

    const CloseModel = () => {
        dispatch(setReqData('OndemandDetails', []))
        setOpen(false)
    }
    return (
        <div>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}
            >
                <Container>
                    <Row>
                        {/* <Col item xs={12}>
                            <Breadcrumb style={{ width: '100%' }}>
                                <BreadcrumbItem>
                                    <Link to="/dashboard/showCourse">
                                      
                                        Course List
                                    </Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>Purchased Students</BreadcrumbItem>
                            </Breadcrumb>
                        </Col> */}

                        <Col xs={12}>
                            <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white", display: 'flex', alignItems: 'center' }}>
                                <div
                                    className={cx("page-title-icon")}>
                                    <i className='pe-7s-study icon-gradient bg-sunny-morning' />
                                </div>
                                <div>
                                    <span style={{ fontSize: '18px' }}>
                                        {coursename}
                                    </span>
                                </div>
                            </div>

                        </Col>
                        <Col xs={12}>
                            <DataTable
                                loader={purchased_loader}
                                actions={enabled ? 'course_purchased_students' : 'course_purchased_students_novideo'}
                                data={students}
                                title={"Student List"}
                                columns={columns}
                                ViewStudents={ViewStudents}
                            />
                        </Col>
                    </Row>
                </Container>
            </CSSTransitionGroup>

            <Model
                open={open}
                handleClose={CloseModel}
                title={'Ondemand Classes Progress'}
            >
                {
                    ondemand_loader ?
                        <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                        </div>
                        :
                        <>
                            {
                                OndemandDetails.length > 0 ?
                                    <>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Ondemand class</TableCell>
                                                    <TableCell>Viewed Percentage</TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    OndemandDetails.map((val, ky) => {
                                                        return (
                                                            <TableRow key={ky}>
                                                                <TableCell>{val.ondemand_class_name}</TableCell>
                                                                <TableCell>{val.percentage}%</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>

                                        </Table>

                                    </>
                                    :
                                    <img src={nodata} width='100%' height='250px' />
                            }
                        </>

                }
            </Model>
        </div>
    )
}
