const initialState = {
    classes: [],
    loader: false,
    broadcastRecieved: false,
    firstBroadcasting: false,
    sessionStudentId: null,
    seen:[]
    
}

const studentHomeReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        // case 'EDIT_FORM' : {
        //     const newArray = [...state.subAnswer]
        //     newArray[payload.question_no] =    payload.answer
        //     console.log("spliced",newArray)
        //     return{
        //         ...state , subAnswer : newArray
                
        //     }
        // }



        case 'POPULATE_STUDENT_CLASS_REQUEST': {
            // console.log("success")
            return {
                ...state, loader: true,

            }
        }

        case 'POPULATE_STUDENT_CLASS_SUCCESS': {
            // console.log("success")
            return {
                ...state, loader: false, classes: payload.classes

            }
        }
        case 'BROADCAST_INDEX_PAGE': {
            //  alert("broadcast");
            return {
                ...state, broadcastRecieved: !state.broadcastRecieved, firstBroadcasting: true, sessionStudentId: payload
            }
        }

        default:
            return state
    }

}

export default studentHomeReducer