import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  Row,
  Col,
  Card,
  Badge,
  // CardTitle,
  Button,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";
import Model from "../../common/Modal"
// import { Multiselect } from 'react-widgets'
import {
  faUser,
  // faSpinner,
  faEnvelope,
  faPhone,
  faCalendarAlt,
  faHome, faLocationArrow, faRupeeSign
} from "@fortawesome/free-solid-svg-icons";
// import ViewStudent from './viewStudent'
// import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TimeShedule from './viewTime'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import AssignStudents from './addStudentToClass'
// import Modals from '../../common/Modal'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
// import { deleteClasses, validateClassTiming, editClass, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateAllEnterpriseUserClass, getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
// import { useHistory } from "react-router-dom";
import { getResellerDetails, 
  // validatePincode, 
  listAllResellerTransaction, 
  // createResellerPincode, 
  setReqData } from '../../../redux/Actions/Superadmin'

import cx from 'classnames';
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import moment from 'moment'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { Link } from 'react-router-dom';
import PinAssignment from './PinAssignment';

const ResellerDetails = () => {

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const dispatch = useDispatch()
  const params = useParams()

  const [PersonalDetails, setPersonalDetails] = useState()
  const [PinCodes, setPinCodes] = useState()
  const [CommisionModel, setCommisionModel] = useState(false)
  const [walett, setWallet] = useState('')
  const [students, setStudent] = useState([])


  const transactions_loader = useSelector((state) => state.superadmin.transactions_loader ? state.superadmin.transactions_loader : false);
  const transactions = useSelector((state) => state.superadmin.transactions ? state.superadmin.transactions : []);


  // const [StudentDetails, setStudentDetails] = useState()
  // const [Courses, setCourses] = useState()
  // const [Payments, setPayments] = useState()
  // const [Tickets, setTickets] = useState()

  useEffect(() => {
    dispatch(getResellerDetails(tokenData.token, params.reseller_id, cb))
    return () => {
      dispatch(setReqData('transactions', []))
      dispatch({ type: 'RESET_TRANSACTIONS' })
    }
    
  }, [])

  // const handleSubmit = () => {
  //   dispatch(createResellerPincode(tokenData.token, params.reseller_id, PinCodes))
  // }

  const [school, setschool] = useState()

  const cb = (reseller) => {
    setPersonalDetails(reseller.reseller)
    setPinCodes(reseller.panchayath)
    setWallet(reseller.balance)
    setStudent(reseller.students)
    setschool(reseller.schools)
  }


  const filter = () => {
    let from_date = moment(state[0].startDate).format("YYYY-MM-DD")
    let to_date = moment(state[0].endDate).format("YYYY-MM-DD")

    dispatch(listAllResellerTransaction(tokenData.token, params.reseller_id, from_date, to_date))
  }

  // const filtercallback=()=>{
  // setFiltered(true)
  // }

  // const Clearfilter=()=>{
  // setFiltered(false)
  // setState({
  //     startDate: addDays(new Date(), -32),
  //     endDate: new Date(),
  //     key: 'selection'
  // })

  // dispatch(listAllPayments(tokenData.token))
  // }

  // const history = useHistory();

  const columns = [
    { title: '#', field: 'tableData.id' },
    { title: 'Name', field: "full_name" },
    {
      title: 'Transaction_Id', field: 'transaction_id',
      render: (rowData) => {
        return '#' + rowData.transaction_id
      },
    },
    {
      title: 'Order_Id', field: 'order_id',
      render: (rowData) => {
        if (rowData.order_id)
          return '#' + rowData.order_id
        else return " "
      },
    },


    {
      title: "Credit", field: "type",
      render: (rowData) => {
        return rowData.type === 'Credit' ? (
          'Rs. ' + rowData.amount
        ) : (
          ' '
        );
      },
    },
    {
      title: "Debit", field: "type",
      render: (rowData) => {
        return rowData.type === 'Debit' ? (
          'Rs. ' + rowData.amount
        ) : (
          ' '
        );
      },
    },
    {
      title: "Balance", field: "balance",
      render: (rowData) => {
        return 'Rs. ' + rowData.balance
      }
    },

  ];

  const columns_student = [
    { title: "Name", field: "full_name" },
    { title: "Panchayath", field: "panchayath_name" },
    { title: "District", field: "district_name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
    {
      title: "Status", field: "status",
      render: (rowData) => {
        return (
          rowData.status === 1 ?
            <Badge color="danger" pill>Blocked</Badge>
            :
            <Badge color="success" pill>Active</Badge>
        )
      }
    },
  ]
 
  const columns_school = [
    { title: "Name", field: "school_name" },
    { title: "Panchayath", field: "panchayath_name" },
    { title: "District", field: "district_name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "contact_number" },
    {
      title: "Status", field: "status",
      render: (rowData) => {
        return (
          rowData.status === 1 ?
            <Badge color="danger" pill>Blocked</Badge>
            :
            <Badge color="success" pill>Active</Badge>
        )
      }
    },
  ]
  // const cb2 = (e) => {
  //   setPinCodes([...PinCodes, e])
  //   setSpinner(false)
  // }

  // const errorcb = (message) => {
  //   setSpinner(false)
  //   Swal.fire({
  //     title: 'warning',
  //     icon: 'warning',
  //     text: message
  //   })
  // }

  // const [Spinner, setSpinner] = useState(false)

  // const handleCreate = (e) => {
  //   let length = e.length

  //   if (length === 6) {
  //     setSpinner(true)
  //     dispatch(validatePincode(tokenData.token, e, cb2, errorcb))

  //   } else {
  //     alert("pin number must be 6 digits")
  //   }
  // }



  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), -32),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  return (
    <>
      { PersonalDetails ?
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Row>
            <Col item xs={12}>
              <Breadcrumb style={{ width: '100%' }}>
                <BreadcrumbItem>
                  <Link to="/dashboard">
                    <FontAwesomeIcon icon={faHome} />
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/dashboard/reseller">Reseller List</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>Details</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>

          <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white", display: 'flex', alignItems: 'center' }}>
            <div
              className={cx("page-title-icon")}>
              <i className='pe-7s-user icon-gradient bg-sunny-morning' />
            </div>
            <div>
              <span style={{ textTransform: 'uppercase' }}>{PersonalDetails?.reseller_name}</span> <br />
              {/* <span style={{}}>Active</span> */}
            </div>

          </div>

          <Row>
            <Col xs={12}>
              <Row>
                <Col sm="12" md="6">
                  <Card
                    style={{ height: "310px" }}
                    className="assign-details-card assign-details-card-descrip"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>
                        <strong>Details</strong>
                      </h6>
                      <div >   <Button onClick={() => { setCommisionModel(true) }} color="danger">View/Update Panchayath</Button> </div>
                    </div>
                    <hr />
                    <Row>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        {"Name"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.reseller_name}</span>
                      </Col>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                        {"Email"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.email}</span>
                      </Col>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faPhone} />
                        </span>
                        {"Phone"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.contact_number}</span>
                      </Col>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                        {"PIN"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.pin}</span>
                      </Col>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faHome} />
                        </span>
                        {"Address"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.address}</span>
                      </Col>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faLocationArrow} />
                        </span>
                        {"Area"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.area}</span>
                      </Col>
                      <Col md={5} className="assign-details-card-div-head">
                        <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                          {" "}
                          <FontAwesomeIcon icon={faRupeeSign} />
                        </span>
                        {"Commision"}
                      </Col>
                      <Col md={7} className="assign-details-card-div">
                        <span style={{ marginLeft: '10px' }}>{PersonalDetails?.commision} %</span>
                      </Col>
                      {/* <Col md={7} >
                    <Button onClick={()=>{setCommisionModel(true)}} color="danger">View Pincodes</Button>
                  </Col> */}
                      <Col md={7} className="assign-details-card-div">
                        {" "}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col sm="12" md="6" style={{ marginTop: "15px" }}>
                  <Row>
                    <Col md="12">
                      <div
                        style={{ color: "white", backgroundColor: "#d92550" }}
                        className="card mb-3 widget-chart card-hover-shadow-2x text-left"
                      >
                        <div className="icon-wrapper border-light rounded">
                          <div className="icon-wrapper-bg bg-light" />
                          <i className="lnr-users icon-gradient bg-heavy-rain" />
                        </div>
                        <div className="widget-chart-content">
                          <div className="widget-numbers">{students.length}</div>
                          <div className="widget-subheading">Total students</div>
                        </div>
                      </div>
                    </Col>

                    <Col md="12">
                      <div
                        style={{ color: "white", backgroundColor: "#3FD2C7 " }}
                        className="card mb-3 widget-chart card-hover-shadow-2x text-left"
                      >
                        <div className="icon-wrapper border-light rounded">
                          <div className="icon-wrapper-bg bg-light" />
                          <i className="lnr-briefcase icon-gradient bg-heavy-rain" />
                        </div>
                        <div className="widget-chart-content jms-opacity-overwrite">
                          <div className="widget-numbers">Rs. {walett}</div>
                          <div className="widget-subheading">Wallet Balance</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Card style={{ marginTop: '15px', padding: '15px' }}>
            <CardHeader>Transaction Details</CardHeader>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <Card style={{ marginBottom: 20, padding: 20 }}>
                    {/* <CardHeader>Transaction Details</CardHeader> */}
                    <DateRangePicker
                      onChange={item => setState([item.selection])}
                      // onChange={handleSelect}

                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      maxDate={new Date()}
                      direction="horizontal"
                      color='#fed858'
                    />
                    <hr />
                    <Row>
                      <Col xs={6} sm={9}></Col>
                      <Col xs={6} sm={3} style={{ textAlign: 'right' }}>
                        <LaddaButton className=" mr-2 btn btn-primary"
                          style={{ width: '-webkit-fill-available' }}
                          loading={transactions_loader}
                          type="button"
                          onClick={filter}
                          data-style={ZOOM_IN}  >
                          Filter
                    </LaddaButton>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col xs={12}>
                  <Table
                    loader={transactions_loader}
                    // actions={action}
                    data={transactions}
                    title={"Transactions"}
                    columns={columns}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row style={{ marginTop: '10px' }}>
            <Col xs={12}>
              <Table
                // loader={transactions_loader}
                // actions={action}
                data={students}
                title={"Student List"}
                columns={columns_student}

              />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col xs={12}>
              <Table
                // loader={transactions_loader}
                // actions={action}
                data={school}
                title={"School List"}
                columns={columns_school}

              />
            </Col>
          </Row>
          {PinCodes &&
            <Model
              size='xl'
              open={CommisionModel}
              handleClose={() => { setCommisionModel(false) }}
              title='Update Panchayath'

            >
              <PinAssignment 
                 reseller_data={PinCodes[0] }
                 reseller_id={params.reseller_id}
                 setPinModel={setCommisionModel}
              />
            </Model>
           } 
        </CSSTransitionGroup>
        : <>       
        <Card style={{ padding: '30px' }}>
          <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
          </SkeletonTheme>
        </Card>
          <Card style={{ padding: '30px', marginTop: '25px' }}>
            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

              <Skeleton height={30} count={4} />
            </SkeletonTheme>
          </Card>
          </>
      } 
      </>

  );
};

export default ResellerDetails;
