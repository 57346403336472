import React from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { addAssignmentResponse } from '../../../redux/Actions/assignment'
import { useState } from 'react'
import SweetAlert from 'sweetalert-react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { Progress } from 'react-sweet-progress';

// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Loader, Types } from 'react-loaders';
// import LoadingOverlay from 'react-loading-overlay'
const Index = (props) => {
  const [successModal, setSuccessModal] = useState();
  const dispatch = useDispatch();
  const formData = new FormData();
  const [loader, setLoader] = useState();
  const [visible, setVisible] = useState(false);
  // toast.configure();

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const assignment = useSelector(({ assignments }) => assignments)
  // const da = { assignment_id: 20, class: "dsf", assignment: [1, 2, 3, 4], remark: "efssdf" }

  const onSubmit = e => {
    // const { name, value } = e.target;
    setLoader(true);
    // console.log('form '+formData);
    // toast.success("File is being uploaded...", {
    //   position: toast.POSITION.BOTTOM_CENTER
    // });
    setVisible(true)
    dispatch(addAssignmentResponse(tokenData.token, formData, handleSunbmit))
  }

  const handleSunbmit = () => {
    setLoader(false);
    setVisible(false)
    setSuccessModal(true)
  }

  const handleCloseSuccesstModal = () => {
    setSuccessModal(false);
    props.closeRespondModel();
  }


  const handleDesc = e => {
    // const { name, value } = e.target;
    formData.set('remark', e.target.value);
  }

  const onChange = (e) => {
    //debugger;
    // console.log('form '+props.id);
    formData.set("class_id", props.class_id);
    formData.set("assignment_id", props.id);
    for (let i = 0; i < e.target.files.length; i++) {

      formData.append('assignment[]', e.target.files[i])
    }


  }
  // console.log('formiiiiiii',formData);

  // const { title, children, handleClose, open } = props;
  return (
    <div>
      <div align="center">
        {/* <LoadingOverlay tag="div" active={visible}
        styles={{
          overlay: (base) => ({
              ...base,
              background: '#fff',
              opacity: 0.5
          })
      }}
      spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}> */}
        <table>
          <tr><th>
            Remarks

          </th></tr>

          <tr>
            <th align="center">
              <textarea rows="4" style={{ "width": "100%" }} required onBlur={handleDesc} id="description" name="description" />
            </th>
          </tr>
          <tr>
            <th>
              <br />

              <Input type="file" id="assignment" onBlur={onChange} name="assignment" multiple />

            </th>
          </tr>
          <tr
            align="right">
            <th align="right">
              <br />
              <LaddaButton className="mb-2 mr-2 btn btn-danger"
                type="submit"
                loading={loader}
                onClick={() => onSubmit()}
                data-style={ZOOM_IN}   >
                Upload Assignment
              </LaddaButton>
            </th>
          </tr>
        </table>

        {/* </LoadingOverlay> */}
        <SweetAlert
          title="Success"
          confirmButtonColor=""
          show={successModal}
          text="Completed successfully"
          type="success"
          onConfirm={() => handleCloseSuccesstModal()} />
      </div>

      {
        assignment.uploadProgress != '' ? <>
          <strong style={{ color: 'rgb(19, 140, 228)' }}>Uploading Files...</strong>
          <Progress
            percent={assignment.uploadProgress}
          /></> : null
      }


    </div>

  )
}

export default Index
