import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Card from '@material-ui/core/Card';
import { listAllNewSubscription,setReqStudentData } from "../../../redux/Actions/Student"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import WarningIcon from '@material-ui/icons/Warning';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import nodata from '../../LandingPageNew/images/nodata.png'
import PageTitle from "../../HeaderTitle/PageTitle";
import LoadingOverlay from 'react-loading-overlay';
import subscriptionimage from "../../LandingPageNew/images/subscription_image.png"

function SubscriptionAll(){
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    const All_Subscription_Data = useSelector((state) =>state.student.All_Subscription_Data ? state.student.All_Subscription_Data : '');
    const Sub_Array = useSelector((state) =>state.student.All_Subscription_Array ? state.student.All_Subscription_Array :[]);
    const next_page = useSelector((state) =>state.student.All_Subscription_next_page ? state.student.All_Subscription_next_page : '');
    const prev_page = useSelector((state) =>state.student.All_Subscription_prev_page ? state.student.All_Subscription_prev_page : '');
	const page = useSelector((state) =>state.student.subscription_all_page ? state.student.subscription_all_page :1);
	const loading = useSelector((state) =>state.student.all_sub_load ? state.student.all_sub_load :false);

    useEffect(()=>{
        dispatch(listAllNewSubscription(tokenData.token,page))
    },[page])

    const Viewcourse=(val)=>{
        history.push(`/dashboard/subscription/${val.subscription_id}`)
    }

    const NextPage=()=>{
        dispatch(setReqStudentData('subscription_all_page',page+1))
    }
    const PreviousPage=()=>{
        dispatch(setReqStudentData('subscription_all_page',page-1))

    }

    return(
        
        <div>
            {
                All_Subscription_Data ? 
                <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
             <PageTitle
                // style={{ display: "none" }}
                heading="Subscriptions"
                subheading="List of Susbcriptions"
                icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
            />
            <LoadingOverlay
                active={loading}
                spinner
                text='Loading your content...'
            >     
                <Grid container> 
                {
                    Sub_Array.length > 0 ?
                    <>
                        {
                            Sub_Array.map((val,ky)=>{
                                return(
                                    <Grid item xs={12} sm={4} md={3} key={ky} style={{marginTop:'10px',padding:'10px',boxSizing:'border-box'}}>
                                       
                                        <div class="blog-item thum-hover featured-institute-main" style={{cursor:'pointer'}} onClick={()=>{Viewcourse(val)}}>
                                            <div class="position-relative">
                                                <div class="item-thumbnail"><img src={subscriptionimage} alt="" width='100%' height='200px' /></div>
                                                <div class="cat-div">{val.subscription_type}</div>
                                            </div>
                                            <div class="padding-20px bgclrbtm-of-course">
                                                <div style={{height:'50px',overflow:'hidden',wordWrap:'break-word'}}>
                                                    <span class="f-i-title">{val.subscription_name}</span>
                                                </div>
                                                <p class="font-14 mb-2">{val.course_subject_name}</p>
                                                <p class="mb-0"><span style={{color:'#6a6f73'}}>Order Id </span><span class="font-16 font-weight-500 text-blue">#{val.order_id}</span>
                                                </p>
                                                <hr class="mt-1 mb-2" />
                                                <p class="mb-0">
                                                    <span class="mr-1 course-badges badge badge-success">{val.payment_date}</span>
                                                    <span class="mr-1 course-badges badge badge-dark">{val.course_amount} {val.currency_code}</span>
                                    
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </>
                    :
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={nodata} width='600px' height='400px' />
                    </div>
                }  
            </Grid>
            {
                    Sub_Array.length > 0 && (next_page || prev_page)  ?
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                            <ul class="pagination-list">
                                {
                                    prev_page ? 
                                    <li style={{cursor:'pointer'}}><a onClick={PreviousPage}>Prev</a></li>
                                    :
                                    <li><a style={{color:'#dddddd'}}>Prev</a></li>

                                }
                                {
                                    next_page ? 
                                    <li style={{cursor:'pointer'}}><a onClick={NextPage}>Next</a></li>
                                    :
                                    <li><a style={{color:'#dddddd'}}>Next</a></li>

                                }
                            </ul>
                        </div>
                    </div>

                    :
                    ''
                    
                }
            </LoadingOverlay>   

            </CSSTransitionGroup>

            :
            <>
                <Card style={{ padding: '30px' }}>
                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={30} count={5} />
                </SkeletonTheme>
                </Card>
                <Card style={{ padding: '30px' }}>
                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={30} count={5} />
                </SkeletonTheme>
                </Card>
            </>
            }
        </div>
    )
}

export default SubscriptionAll