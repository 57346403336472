import React from 'react'
//import DataTable from '../../common/AttendanceTable'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
               // hadleOpenModal={(id) => props.hadleOpenModal(id)}
               title={props.title}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns}
                data={props.data}
               // tab={props.tab}
                loader={props.loader}
                handleSelectedRow={(class_id, session) => props.handleSelectedRow(class_id, session)}

            />
        </>
    )
}

export default Table