import React, { useEffect, useState } from 'react'
// import PageTitle from "../../HeaderTitle/PageTitle";
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Form, FormGroup, Label, FormFeedback, Input, Button } from 'reactstrap'
import PlaylistTumnile from './PlayListThumb'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { requiredValidator } from '../../../../../utilities/Validator'
//import {deletePlaylist} from '../../../../redux/Actions/playlist'
// import { getGradeList, populateDegree } from '../../../redux/Actions/signUp'
// import { populateSubject,  } from '../../../redux/Actions/homeTeacher'
// import { populateVendorCourse, populateClassForCourse } from '../../../redux/Actions/vendor_course'
import serialize from 'form-serialize'
import { populatePlayList, createPlayList, updatePlaylist, deletePlaylist, } from '../../../../../redux/Actions/playlist'
import Modals from '../../../../common/Modal'
// import Table from '../common/DataTable'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { populateGrade } from '../../../../../redux/Actions/homeTeacher'
import { populateDegree, getGradeList } from "../../../../../redux/Actions/signUp"
const School = () => {

  const params = useParams()

  const [editMode, setEditMode] = useState(false);
  const [playlistDetails, setPlaylistDetails] = useState("");
  const dispatch = useDispatch()
  // const tStore = useSelector(({ homeTeacher }) => homeTeacher)
  const playlistStore = useSelector(({ playlist }) => playlist)

  // const courses = useSelector(state => state.course.courses ? state.course.courses : false)

  // const platlistvideos = useSelector(state => state.playlist.classPlaylist ? state.playlist.classPlaylist : [])

  const [updating, setUpdating] = useState([]);


  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const tStore = useSelector(({ homeTeacher }) => homeTeacher);
  const Grades = useSelector(({ signUp }) => signUp);
  useEffect(() => {


    // dispatch(populatePlayList(tokenData.token, 1))

    // dispatch(populateVendorCourse(tokenData.token,1))
    dispatch(populatePlayList(tokenData.token,"",params.subscription_id))


    // return () => {
    //   console.log("unmount school")
    // }
  }, [])

  const openEdit = (playlist_name, playlist_id, category_id) => {
    setEditMode(true);
    setPlaylistDetails({
      playlist_name: playlist_name,
      playlist_id: playlist_id,
      category_id: category_id,
    });
  };

  const handleChange = (e) => {
    // console.log(e);
    // alert(playlist_id)
    const { value } = e.target;
    // alert(value)
    setPlaylistDetails({ ...playlistDetails, playlist_name: value });

    // dispatch({ type: 'CHANGE_PLAYLIST_TITLE', payload: { title: value, id: playlist_id :} })
  };

  const handleChangeDegree = (value) => {
    dispatch(getGradeList(value, 2));
  }



  // const [coursename, setCousrename] = useState('')
  // const handleChangeCourse = e => {
  //   const { name, value } = e.target;
  //   //setInputField({ ...inputField, [name]: value })
  //   // console.log("called")
  //   // dispatch(populateClassForCourse(tokenData.token,value))
  //   setInputField({ ...inputField, [name]: value })
  //   if (e.target.value) {
  //     let n = courses.find((val) => val.course_id === Number(value))
  //     setCousrename(n.course_name)
  //     dispatch(populatePlayList(tokenData.token, e.target.value))
  //   }

  // };
  // const handleChangeGrade = e => {
  //   const { name, value } = e.target;
  //   //setInputField({ ...inputField, [name]: value })
  //   dispatch(populateSubject(tokenData.token, value, 1))
  //   setInputField({ ...inputField, [name]: value })
  // };


  // const onEdit = (rowData) => {
  //     console.log("row-", rowData)
  //     dispatch(updateSubjectSchhol(tokenData.token, rowData))

  // }

  // const onDelete = (id) => {

  //     Swal.fire({
  //         icon: 'warning',
  //         title: 'Are you sure !',
  //         // text: 'All the classes under the subject will be deleted',
  //         showCancelButton: true,
  //         confirmButtonColor: "#DD6B55",
  //         confirmButtonText: "CONFIRM",
  //         cancelButtonText: "CANCEL",

  //     }).then((result) => {
  //         if (result.value) {
  //             dispatch(deleteSubjectSchool(tokenData.token, id))

  //             Swal.fire(
  //                 'Deleted!',
  //                 'Subject has been deleted.',
  //                 'success'
  //             )

  //         } else if (result.dismiss === Swal.DismissReason.cancel) {
  //             Swal.fire(
  //                 'Cancelled',
  //                 'Delete action cancelled',
  //                 'error'
  //             )
  //         }
  //     })

  // }
  const [inputField, setInputField] = useState({
    playlist_name: '',
    grade_id: '',
    category_id: '',
  })
  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setInputField({ ...inputField, [name]: value })
  }
  const [errors, setErrors] = useState({})
  const [Category, setCategory] = useState()

  const handleChangeCategory = (value) => {
    if (value === '1') {
      dispatch(populateGrade(null, 1));
    }
    if (value === '2') {
      dispatch(populateDegree());
    }
    if (value === '3') {
      dispatch(populateGrade(null, 3));
    }
    setCategory(value)
    setInputField({ ...inputField, category_id: value });
    console.log("cat cat",inputField)
  }

  const EditPlaylists = (playlist_id, playlist_name, playlistDetails) => {
    // setUpdating([...updating, playlist_id])
    if (!playlist_name) {
      Swal.fire({
        icon: "error",
        title: "Required",
        text: "Playlist name  required",
      });
    } else {
      dispatch(
        updatePlaylist(
          tokenData.token,
          playlist_id,
          playlist_name,
          playlistDetails,
          updated
        )
      );
    }
    // alert(playlist_name)
  };
  const updated = (playlist_id) => {
    // const removed = updating.filter(id => id != playlist_id)
    setEditMode(false);
    // setUpdating(removed)
    Swal.fire({
      icon: "success",
      title: "Updated",
      text: "Playlist Updated  successfully",
    });
  };
  const cancelEdit = (playlist_id) => {
    const removed = updating.filter((id) => id != playlist_id);
    setEditMode(false);
    setUpdating(removed);
  };
  const handleCloseEdit = () => {
    setEditMode(false);
  };

  const DeletePlaylists = (playlist_id, category_id) => {
    // alert(category_id)
    Swal.fire({
      icon: "warning",
      // title: "Format not supported",
      text: "Are you sure,do you want to delete ",
      confirmButtonText: "Yes",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.value) {
        dispatch(deletePlaylist(tokenData.token, playlist_id, category_id, cbdelete));
      }
      // else {
      //     //  formData.delete('assignment[]')

      // }
    });
  };

  const cbdelete = () => {
    dispatch(populatePlayList(tokenData.token,"",params.subscription_id))
  }

  const formSubmit = (e) => {
    e.preventDefault()
    const form = e.currentTarget
    let body = serialize(form, { hash: true, empty: true })
    let error;
    error = {
      playlist_name: requiredValidator(inputField.playlist_name),
      grade_id: requiredValidator(inputField.grade_id),
      category_id: requiredValidator(inputField.category_id),
      subject_name: requiredValidator(inputField.subject_name),
    }
    if (!error.playlist_name && !error.category_id && !error.grade_id && !error.subject_name) {
      dispatch(createPlayList(tokenData.token, body, reset))
      setErrors(error)
    } else {
      console.log('eerrr',error)
      setErrors(error)

    }

  }
  const reset = () => {
    dispatch(populatePlayList(tokenData.token,'',params.subscription_id))
    document.getElementById("create-form-school").reset();
  }
  return (
    <>
      {/* 
        <PageTitle
        //style={{ display: 'none' }}
        heading="Create Playlist"
        subheading="Create playlist to attach with video session"
        icon="pe-7s-film icon-gradient bg-happy-itmeo"
      /> */}


      <Form onSubmit={formSubmit} id="create-form-school">


        <Card >
          <Row className="jms-subject-row-pad ">
            <Col md={6}>
              <FormGroup>
                <Label for="exampleCustomSelectDisabled"> <span className="text-danger">*</span>{' '}Playlist Title</Label>
                <Input type="text" name="playlist_name"
                  onChange={handleChangeInput}
                  invalid={errors.playlist_name}
                />
                <FormFeedback >{errors.playlist_name}</FormFeedback>

              </FormGroup>
            </Col>
            <Input type="hidden" value={params.subscription_id} name="subscription_id"
            ></Input>

            <Col sm={6}>
              <Label for="exampleNameF">
                <span className="text-danger">*</span> Category
              </Label>

              <Input type="select" name='category_id' invalid={errors.category_id} onChange={(e) => handleChangeCategory(e.target.value)} >
                <option value="">Select</option>
                <option value='1'>School</option>
                <option value='2'>College</option>
                <option value='3'>Institute</option>
              </Input>
              <FormFeedback>{errors.category_id}</FormFeedback>
            </Col>
            {Category &&
              <>
                {Category === '1' &&
                  <Col sm={6}>
                    <Label for="exampleNameF">
                      <span className="text-danger">*</span> Grade
                    </Label>

                    <Input type="select" onChange={handleChangeInput} name='grade_id' invalid={errors.grade_id} >
                      <option value='' >Select</option>
                      {tStore.grade
                        ? tStore.grade.map((grade) => {
                          return (
                            <option value={grade.grade_id}>
                              {grade.grade_name}
                            </option>
                            //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                          );
                        })
                        : []}
                    </Input>
                    <FormFeedback>{errors.grade_id}</FormFeedback>
                  </Col>

                }


                {Category === '2' &&
                  <>
                    <Col sm={3}>
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span> Course
                      </Label>

                      <Input type="select" onChange={(e) => handleChangeDegree(e.target.value)} name='degree_id' invalid={errors.degree_id} >
                        <option value={""}>{"select"}</option>
                        {Grades.degree
                          ? Grades.degree.map((data) => {
                            return (
                              <option
                                value={data.degree_id}
                                key={data.degree_id}
                              >
                                {data.degree_name}
                              </option>
                            );
                          })
                          : []}
                      </Input>
                      <FormFeedback>{errors.degree_id}</FormFeedback>
                    </Col>
                    <Col sm={3}>
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span> Specialization
                      </Label>

                      <Input required type="select" onChange={handleChangeInput} invalid={errors.grade_id} id='grade_id' name="grade_id">
                        <option value={""}>{"select"}</option>
                        {Grades.gradeCollege.grades
                          ? Grades.gradeCollege.grades.map((data) => {
                            return (
                              <option
                                value={data.grade_id}
                                key={data.grade_id}
                              >
                                {data.grade_name}
                              </option>
                            );
                          })
                          : []}
                      </Input>
                      <FormFeedback>{errors.grade_id}</FormFeedback>
                    </Col>

                  </>
                }

                {Category === '3' &&
                  <Col sm={6}>
                    <Label for="exampleNameF">
                      <span className="text-danger">*</span> Course
                    </Label>

                    <Input type="select" onChange={handleChangeInput} name='grade_id' invalid={errors.grade_id} >
                      <option value='' >Select</option>
                      {tStore.grade
                        ? tStore.grade.map((grade) => {
                          return (
                            <option value={grade.grade_id}>
                              {grade.grade_name}
                            </option>
                            //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                          );
                        })
                        : []}
                    </Input>
                    <FormFeedback>{errors.grade_id}</FormFeedback>
                  </Col>

                }


                <Col sm={6}>
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> Subject
                  </Label>

                  <Input required type="text" onChange={handleChangeInput} invalid={errors.subject_name} id='subject_name' name="subject_name">
                  </Input>
                  <FormFeedback>{errors.subject_name}</FormFeedback>
                </Col>
              </>
            }

            <Col md={10}>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label>

                <LaddaButton className="mb-2 mr-2 btn btn-danger"
                  loading={playlistStore.createPlayListSchoolLoader}
                  // onClick={formSubmit}
                  type="submit"
                  data-style={ZOOM_IN}
                  style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available' }}

                >
                  Create Playlist
                </LaddaButton>
              </FormGroup>
            </Col>
          </Row>
        </Card>
      </Form>

      <div style={{ paddingTop: 20, fontWeight: 700, paddingLeft: 10 }}>{'PlayLists'} </div>


      <hr />



      <Row className="m-3">

        {

          playlistStore.populatePlaylistSchoolLoader === true ?
            Array(3).fill().map(() => {
              return (
                <Col md={4} style={{ paddingBottom: '10px' }}>
                  <Card style={{ padding: '30px' }}>
                    <Row>
                      <Col md={3}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                          <Skeleton circle={true} height={50} width={50} count={1} />
                        </SkeletonTheme>
                      </Col>
                      <Col md={9}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                          <Skeleton height={20} count={4} />
                        </SkeletonTheme>
                      </Col>
                    </Row>

                  </Card>
                </Col>
              )
            })

            : playlistStore.playlistSchool ? playlistStore.playlistSchool.map((playlist, index) => {
              return (
                <Col md={4} style={{ paddingBottom: '25px' }}>

                  <PlaylistTumnile
                    to={playlist.playlist_id}
                    category_id={playlist.category_id}
                    subject_id={playlist.subject_id}
                    index={index}
                    title={playlist.playlist_name}
                    subject={playlist.subject_name}
                    grade={playlist.grade_name}
                    count={playlist.video_count}
                    DeletePlaylists={DeletePlaylists}
                    EditPlaylists={EditPlaylists}
                    editMode={editMode}
                    openEdit={openEdit}
                    handleChange={handleChange}
                    cancelEdit={cancelEdit}
                  />


                </Col>
              )
            }) : null
        }




      </Row>

      <Modals
        open={editMode}
        size="sm"
        handleClose={handleCloseEdit}
      // title={`Email verification`}
      >
        <div>
          <Row style={{ padding: 15 }}>
            <Input
              type="text"
              value={playlistDetails.playlist_name}
              onChange={handleChange}
              name="title"
            />
          </Row>
          <Row style={{ paddingTop: 10 }}>
            <Col md={6}>
              <Button
                onClick={() => handleCloseEdit()}
                className="jms-playlist-save"
              >
                Cancel
              </Button>
            </Col>
            <Col md={6}>
              <Button
                onClick={() =>
                  EditPlaylists(
                    playlistDetails.playlist_id,
                    playlistDetails.playlist_name,
                    playlistDetails.category_id
                  )
                }
                color="info"
                className="jms-playlist-save"
              >
                {"Update"}
              </Button>
            </Col>
          </Row>
        </div>
      </Modals>



    </>

  )
}

export default School
