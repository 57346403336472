import React,{useEffect,useState} from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  Row,
  Col,
  Card,
  BreadcrumbItem,
  Breadcrumb
} from "reactstrap";

import {
  faUser,
  faReply,
  faEnvelope,
  faPhone,
  faCalendarAlt,
  faHome,faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
// import ViewStudent from './viewStudent'
// import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TimeShedule from './viewTime'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import AssignStudents from './addStudentToClass'
// import Modals from '../../common/Modal'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
 import { useDispatch, useSelector } from 'react-redux'
// import { deleteClasses, validateClassTiming, editClass, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateAllEnterpriseUserClass, getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
// import { useHistory } from "react-router-dom";
import {getSchoolDetails,listAllSchoolTransaction,setReqData} from '../../../redux/Actions/Superadmin'

import cx from 'classnames';
import CardHeader from "reactstrap/lib/CardHeader";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import moment from 'moment'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import {Link} from "react-router-dom"
const SchoolDetails = () => {

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const dispatch = useDispatch()
  const params = useParams()


  // const [filtered,setFiltered]=useState(false)


  const transactions_loader_school = useSelector((state) =>state.superadmin.transactions_loader_school ? state.superadmin.transactions_loader_school : false);
  const SchoolDetails = useSelector((state) =>state.superadmin.SchoolDetails ? state.superadmin.SchoolDetails : []);
  const transactions_school = useSelector((state) =>state.superadmin.transactions_school ? state.superadmin.transactions_school : []);
  const wallet_balance = useSelector((state) =>state.superadmin.wallet_balance ? state.superadmin.wallet_balance : 0);

  
  
  // const [StudentDetails, setStudentDetails] = useState()
  // const [Courses, setCourses] = useState()
  // const [Payments, setPayments] = useState()
  // const [Tickets, setTickets] = useState()

  useEffect(() => {
   dispatch(getSchoolDetails(tokenData.token,params.school_id))
   return()=>{
    dispatch(setReqData('transactions_school',[]))
  } 
  }, [])



  const filter=()=>{
    let from_date= moment(state[0].startDate).format("YYYY-MM-DD")
    let to_date=moment(state[0].endDate).format("YYYY-MM-DD")
    
    dispatch(listAllSchoolTransaction(tokenData.token,params.school_id,from_date,to_date))
}

// const filtercallback=()=>{
  // setFiltered(true)
// }

// const Clearfilter=()=>{
  // setFiltered(false)
  // setState({
  //     startDate: addDays(new Date(), -32),
  //     endDate: new Date(),
  //     key: 'selection'
  // })

  // dispatch(listAllPayments(tokenData.token))
// }

  // const history = useHistory();

  const columns = [
    { title: '#', field: 'tableData.id' },
    {title:'Name',field:"full_name"},
    { title: 'Transaction_Id', field: 'transaction_id',
    render: (rowData) => {
        return '#'+rowData.transaction_id
        },
    },
        { title: 'Order_Id', field: 'order_id',
        render: (rowData) => {
          if(rowData.order_id)
          return '#' + rowData.order_id
          else return " " 
        },
        },
  
    { title: "Credit", field: "type",
      render: (rowData) => {
        return rowData.type === 'Credit' ? (
          'Rs. '+ rowData.amount
        ) : (
          ' '
        );
      }, 
    },
    { title: "Debit", field: "type",
    render: (rowData) => {
      return rowData.type === 'Debit' ? (
        'Rs. '+ rowData.amount
      ) : (
        ' '
      );
    }, 
  },
  { title: "Balance", field: "balance",
  render: (rowData) => {
      return 'Rs. '+rowData.balance
      } },
  ];
 

 

  const [state, setState] = useState([
    {
        startDate: addDays(new Date(), -32),
        endDate: new Date(),
        key: 'selection'
    }
  ]);

  return (
    <>
    {SchoolDetails ?
    <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col item xs={12}>
            <Breadcrumb  style={{width:'100%'}}>
          <BreadcrumbItem>
            <Link to="/dashboard">
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
          {
            tokenData.role==='superAdmin' ? 
            <Link to="/dashboard/schoollist">School List</Link>
            :
            <Link to="/dashboard/schools">School List</Link>
          }
          </BreadcrumbItem>
          <BreadcrumbItem active>Details</BreadcrumbItem>
        </Breadcrumb>
        </Col>
        </Row>
        <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white",display:'flex',alignItems:'center' }}>
          <div
              className={cx("page-title-icon")}>
              <i className='pe-7s-study icon-gradient bg-sunny-morning' />
          </div>
          <div>
              <span style={{ textTransform: 'uppercase' }}>{SchoolDetails.school_name}</span> <br/>
              {/* <span style={{}}>Active</span> */}
          </div>
      </div>

      <Row>
        <Col xs={12}>
        <Row>
            <Col sm="12" md="6">
              <Card
                style={{ height: "310px" }}
                className="assign-details-card assign-details-card-descrip"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>
                    <strong>Details</strong>
                  </h6>
                </div>
                <hr />
                <Row>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    {"Name"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails.school_name}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    {"Email"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails.email}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    {"Phone"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails.contact_number}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </span>
                    {"PIN"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails.pin}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faHome} />
                    </span>
                    {"Address"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails.address}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </span>
                    {"Area"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails?.area}</span>
                  </Col>
                  <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faReply} />
                    </span>
                    {"Referral  Code"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{SchoolDetails?.referral_code}</span>
                  </Col>
                  {/* <Col md={5} className="assign-details-card-div-head">
                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                      {" "}
                      <FontAwesomeIcon icon={faRupeeSign} />
                    </span>
                    {"Commision"}
                  </Col>
                  <Col md={7} className="assign-details-card-div">
                    <span style={{marginLeft:'10px'}}>{'SchoolDetails?.commision'}</span>
                  </Col> */}
                </Row>
              </Card>
            </Col>
            <Col sm="12" md="6" style={{ marginTop: "15px" }}>
              <Row>
                <Col md="12">
                  <div
                    style={{ color: "white", backgroundColor: "#d92550" }}
                    className="card mb-3 widget-chart card-hover-shadow-2x text-left"
                  >
                    <div className="icon-wrapper border-light rounded">
                      <div className="icon-wrapper-bg bg-light" />
                      <i className="lnr-briefcase icon-gradient bg-heavy-rain" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-numbers">Rs. {wallet_balance}</div>
                      <div className="widget-subheading">Wallet Balance</div>
                    </div>
                  </div>
                </Col>

                {/* <Col md="12">
                  <div
                    style={{ color: "white", backgroundColor: "#3FD2C7 " }}
                    className="card mb-3 widget-chart card-hover-shadow-2x text-left"
                  >
                    <div className="icon-wrapper border-light rounded">
                      <div className="icon-wrapper-bg bg-light" />
                      <i className="lnr-apartment icon-gradient bg-heavy-rain" />
                    </div>
                    <div className="widget-chart-content jms-opacity-overwrite">
                      <div className="widget-numbers">{"null"}</div>
                      <div className="widget-subheading">Total ?</div>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
     
    <Row style={{marginTop:'15px'}}>
        <Col xs={12}>
          <Card style={{ marginBottom: 20, padding: 20 }}>
            <CardHeader>Transaction Details</CardHeader>
              <DateRangePicker
                  onChange={item => setState([item.selection])}
                  // onChange={handleSelect}

                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  maxDate={new Date()}
                  direction="horizontal"
                  color='#fed858'
                  // direction="vertical"
                  // scroll={{ enabled: true }}
              />
            <hr />
            <Row>
              <Col xs={6} sm={9}></Col>
              <Col xs={6} sm={3} style={{textAlign:'right'}}>
                <LaddaButton className=" mr-2 btn btn-primary"
                  style={{ width: '-webkit-fill-available' }}
                  loading={transactions_loader_school}
                  type="button"
                  onClick={filter}
                  data-style={ZOOM_IN}  >
                  Filter
                </LaddaButton>
              </Col>
            </Row>
          </Card>
      </Col>

      <Col xs={12}>
        <Table
          loader={transactions_loader_school}
          data={transactions_school}
          title={"Transactions"}
          columns={columns}
        />
      </Col>
    </Row>

      </CSSTransitionGroup>
   
   : <>       <Card style={{ padding: '30px' }}>
          <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
          </SkeletonTheme>
        </Card>
          <Card style={{ padding: '30px', marginTop: '25px' }}>
            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

              <Skeleton height={30} count={4} />
            </SkeletonTheme>
          </Card></>
   }</>
  );
};

export default SchoolDetails;
