import React, { useEffect, Fragment } from "react";
import banner from "../images/vendorbanner.jpg";

import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory } from "react-router-dom";

import {
  getAllVendors,
  searchVendors,
  setHomeData,
} from "../../../redux/Actions/genexhome";
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import img1 from "../images/vendordummy.png";
import nodata from "../images/nodata.png";

function Vendors() {
  const dispatch = useDispatch();
  const history = useHistory();

  const Vendor_Details = useSelector((state) =>
    state.genexhome.Vendor_Details ? state.genexhome.Vendor_Details : ""
  );
  const Vendor_Array = useSelector((state) =>
    state.genexhome.Vendor_Array ? state.genexhome.Vendor_Array : []
  );
  const vendor_first_page_url = useSelector((state) =>
    state.genexhome.vendor_first_page_url
      ? state.genexhome.vendor_first_page_url
      : ""
  );
  const vendor_next_page_url = useSelector((state) =>
    state.genexhome.vendor_next_page_url
      ? state.genexhome.vendor_next_page_url
      : ""
  );
  const vendor_loading = useSelector((state) =>
    state.genexhome.vendor_loading ? state.genexhome.vendor_loading : false
  );

  const page = useSelector((state) =>
    state.genexhome.vendor_page ? state.genexhome.vendor_page : 1
  );
  const serach = useSelector((state) =>
    state.genexhome.vendor_search ? state.genexhome.vendor_search : ""
  );
  const serached = useSelector((state) =>
    state.genexhome.vendor_searched ? state.genexhome.vendor_searched : false
  );

  useEffect(() => {
    if (serached) {
      dispatch(searchVendors(page, serach));
    } else {
      dispatch(getAllVendors(page));
    }
  }, [page]);

  const NextPage = () => {
    dispatch(setHomeData("vendor_page", page + 1));
  };
  const PreviousPage = () => {
    dispatch(setHomeData("vendor_page", page - 1));
  };

  const ViewVendor = (val) => {
    history.push(`/vendor/${val.vendor_id}/${val.skl_user_id}`);
  };

  const Searchvn = (e) => {
    e.preventDefault();
    dispatch(setHomeData("vendor_searched", true));
    if (page === 1) {
      dispatch(searchVendors(1, serach));
    } else {
      dispatch(setHomeData("vendor_page", 1));
    }
  };
  const Handlechange = (e) => {
    if (e.target.value) {
      dispatch(setHomeData("vendor_search", e.target.value));
    } else {
      dispatch(setHomeData("vendor_searched", false));
      dispatch(setHomeData("vendor_search", ""));

      if (page === 1) {
        dispatch(getAllVendors(1));
      } else {
        dispatch(setHomeData("vendor_page", 1));
      }
    }
  };

  return (
    <Fragment>
      {Vendor_Details ? (
        <>
          <section
            class="top-banner position-relative"
            //  style="background-image: url('assets/img/inner-banner.jpg')"
            style={{ backgroundImage: `url(${banner})` }}
          >
            <div class="container">
              <h2 class="text-center font-weight-600 banner-title">
                Our Providers
              </h2>
            </div>

            <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-12">
                  <form onSubmit={Searchvn} class="search-form-main">
                    <div class="row justify-content-center">
                      <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                        <div class="form-group">
                          <input
                            type="search"
                            class="form-control"
                            id=""
                            placeholder="What are you looking for"
                            value={serach}
                            onChange={Handlechange}
                          />
                        </div>
                      </div>
                      <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                        <div class="form-group">
                          <input
                            type="button"
                            class="btn btn-block search-btn"
                            value="Search"
                            onClick={Searchvn}
                            disabled={serach ? false : true}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                <h2 class="text-center search-col-title">Find providers</h2>
              </div>
            </div>
          </section>

          <section
            class="padding-top-120px padding-bottom-60px"
            style={{ backgroundColor: "#fff" }}
          >
            <div class="container">
              <LoadingOverlay
                active={vendor_loading}
                spinner
                text="Loading your content..."
              >
                <div class="row" data-wow-delay="0.2s">
                  {Vendor_Array.length > 0 ? (
                    <>
                      {Vendor_Array.map((val, ky) => {
                        return (
                          <div
                            class="col-md-4 col-sm-6 col-lg-3 col-12 mb-3 pl-2 pr-2"
                            key={ky}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              ViewVendor(val);
                            }}
                          >
                            {/* <a class="" href="#"> */}
                            <div class="review-box institute-col">
                              <div class="institute-media">
                                <img
                                  src={val.logo_url ? val.logo_url : img1}
                                  // src={logo}
                                  alt=""
                                  // class="img-fluid"
                                  width="100%"
                                  height="140px"
                                  id="objfit-iaggg"
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "43px",
                                  wordWrap: "break-word",
                                  overflow: "hidden",
                                }}
                              >
                                <h5>{val.vendor_name}</h5>
                              </div>
                              <p class="read mb-1">
                                <div
                                  style={{
                                    width: "100%",
                                    height: "50px",
                                    overflow: "hidden",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  {val.short_description}
                                </div>
                              </p>
                              <p class="mb-1">
                                <span class="btn courst-btn">
                                {val.total_courses} Courses
                                </span>
                              </p>
                            </div>
                            {/* </a> */}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={nodata} width="600px" height="400px" alt="" />
                    </div>
                  )}
                </div>
              </LoadingOverlay>

              {Vendor_Array.length > 0 &&
              (vendor_first_page_url || vendor_next_page_url) ? (
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                    <ul class="pagination-list">
                      {vendor_first_page_url ? (
                        <li style={{ cursor: "pointer" }}>
                          <a onClick={PreviousPage}>Prev</a>
                        </li>
                      ) : (
                        <li>
                          <a style={{ color: "#dddddd" }}>Prev</a>
                        </li>
                      )}
                      {vendor_next_page_url ? (
                        <li style={{ cursor: "pointer" }}>
                          <a onClick={NextPage}>Next</a>
                        </li>
                      ) : (
                        <li>
                          <a style={{ color: "#dddddd" }}>Next</a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Fragment>
  );
}

export default Vendors;
