import React from 'react'
import { Card, Button, CardFooter } from 'reactstrap'
import avatar3 from '../../../assets/utils/images/myicon/user.png';

const Profile = (props) => {
    const { name, grade, email, handleClick, submittedOn, mark, Total_mark, markType } = props
    return (
        <Card className="card-shadow-primary card-border text-white mb-3 jms-bg-over2" color="primary">
            <div className="dropdown-menu-header">
                <div className="dropdown-menu-header-inner bg-primary jms-bg-overrwrite ">
                    <div className="menu-header-content">
                        <div className="avatar-icon-wrapper mb-3 avatar-icon-xl">
                            <div className="avatar-icon">
                                <img src={avatar3} alt="Avatar 5" />
                            </div>
                        </div>
                        <div>
                            <h5 className="menu-header-title">{name}</h5>
                            <p style={{ color: '#968c8c', fontWeight: 600, textAlign: 'center' }}>{grade}</p>
                            <h6 className="menu-header-subtitle" style={{ lineHeight: '1px', fontSize: '14px' }}><i style={{ fontSize: '17px', fontWeight: 600, verticalAlign: 'middle' }} className="pe-7s-mail"> </i>&nbsp;&nbsp;{email}</h6>
                            <hr />
                            {
                                mark ?
                                    <h4 class="menu-header-subtitle"><span style={{ fontSize: '13px' }}>{markType === "marks" ? "Mark" : "Grade"} </span> {mark}<span style={{ fontSize: '13px' }}>{markType === "marks" ? `/${Total_mark}` : null}</span></h4>
                                    : 'Not evaluated'
                            }
                            <p style={{ fontSize: '11px', color: 'rgb(3, 68, 139)', fontWeight: 700, textAlign: 'center' }} className="menu-header-subtitle">Submitted on : {submittedOn}</p>

                        </div>
                        {/* <div className="menu-header-btn-pane pt-1">
                            <Button size="sm" className="btn-icon"
                                color="dark">
                                <i className="pe-7s-config btn-icon-wrapper"> </i>
                                            View Complete Profile
                                        </Button>
                        </div> */}

                    </div>
                </div>
            </div>
            <CardFooter className="text-center d-block">
                <Button
                    style={{ backgroundColor: 'rgb(3, 68, 139)', borderColor: 'rgb(3, 68, 139)' }}
                    onClick={handleClick}
                    className="btn-shadow-dark btn-wider" color="dark">
                    <i className="lnr-download btn-icon-wrapper"> </i>
&nbsp;
                        DOWNLOAD
                                </Button>
            </CardFooter>
        </Card>
    )
}

export default Profile
