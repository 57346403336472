import React from 'react'
import DataTable from '../../common/AttendanceTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                hadleOpenModal={(id) => props.hadleOpenModal(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns}
                data={props.data}
                tab={props.tab}
                loader={props.loader}
                handleSelectedRow={(class_id, session,batch_id) => props.handleSelectedRow(class_id, session,batch_id)}

            />
        </>
    )
}

export default Table
