import axios from 'axios'
import { development } from '../../config.json'

import Swal from 'sweetalert2'

export const listAllResellerTransactions = (token,body,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
   // let data={ticket_id:ticket_id}
    //dispatch({ type: 'TICKET_ALL_REQUEST' })
   // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
    axios.get(`${development.baseUrl}listAllResellerTransaction?start_date=${body.from_date}&end_date=${body.to_date}`,{ headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
                //     ticket_details:res.data.ticket,
                //     Chatdata:res.data.messages
                // }})
                cb(res.data.transactions)
            }
        })
        .catch(err => {
         //   dispatch({ type: 'TICKET_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        //text:'You are logged in to another System'
                        // text:err
                    })
                }
            }
        })
    }

    export const exportAllResellerTransactions = (token,body,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
   // let data={ticket_id:ticket_id}
    //dispatch({ type: 'TICKET_ALL_REQUEST' })
   // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
    axios.get(`${development.baseUrl}exportResellerTransaction?start_date=${body.from_date}&end_date=${body.to_date}`,{ headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
                //     ticket_details:res.data.ticket,
                //     Chatdata:res.data.messages
                // }})
                cb(res.data.url)
            }
        })
        .catch(err => {
         //   dispatch({ type: 'TICKET_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        //text:'You are logged in to another System'
                        // text:err
                    })
                }
            }
        })
    }

    //SCHOOL ACTIONS
export const listAllSchoolTransactions = (token,body,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
   // let data={ticket_id:ticket_id}
    //dispatch({ type: 'TICKET_ALL_REQUEST' })
   // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
    axios.get(`${development.baseUrl}listAllSchoolTransaction?start_date=${body.from_date}&end_date=${body.to_date}`,{ headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
                //     ticket_details:res.data.ticket,
                //     Chatdata:res.data.messages
                // }})
                cb(res.data.transactions)
            }
        })
        .catch(err => {
          //  dispatch({ type: 'TICKET_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        //text:'You are logged in to another System'
                        // text:err
                    })
                }
            }
        })
    }
    export const exportSchoolTransactions = (token,body,cb) => (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
       // let data={ticket_id:ticket_id}
        //dispatch({ type: 'TICKET_ALL_REQUEST' })
       // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
        axios.get(`${development.baseUrl}exportSchoolTransaction?start_date=${body.from_date}&end_date=${body.to_date}`,{ headers: head })
            .then(res => {
                if (res.data.result) {
                    // dispatch({ type: 'TICKET_ALL_SUCCESS', payload:{ 
                    //     ticket_details:res.data.ticket,
                    //     Chatdata:res.data.messages
                    // }})
                   cb(res.data.url)
                
                }
            })
            .catch(err => {
             //   dispatch({ type: 'TICKET_ALL_FAILED'})
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Token Expired',
                                    // text: 'Token Expired'
                                })
                                window.location.reload()
                            }
                        }
                    }
                    else{
                        Swal.fire({
                            icon:'error',
                            title:'Something Went Wrong',
                            //text:'You are logged in to another System'
                            // text:err
                        })
                    }
                }
            })
        }

    export const resellerDashBoard = (token,cb) => (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        }
       // let data={ticket_id:ticket_id}
        //dispatch({ type: 'TICKET_ALL_REQUEST' })
       // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
        axios.get(`${development.baseUrl}resellerDashboard`,{ headers: head })
            .then(res => {
                if (res.data.result) {
                    dispatch({ type: 'RESELLER_DASHBOARD_SUCCESS', payload:{dashboard_data:res.data} })
                    cb(res.data)
                }
            })
            .catch(err => {
              //  dispatch({ type: 'TICKET_ALL_FAILED'})
                if (err) {
                    if (err.response) {
                        if (err.response) {
                            if (err.response.status === 401) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Token Expired',
                                    // text: 'Token Expired'
                                })
                                window.location.reload()
                            }
                        }
                    }
                    else{
                        Swal.fire({
                            icon:'error',
                            title:'Something Went Wrong',
                            //text:'You are logged in to another System'
                            // text:err
                        })
                    }
                }
            })
        }