import React,{useState,useEffect} from 'react'
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    Label,
    CardTitle,
    FormFeedback,
  } from "reactstrap";
import { requiredValidator } from "../../../utilities/Validator";
// import { Progress } from "react-sweet-progress";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { DatePicker  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  convertToRaw,
} from "draft-js";
import moment from "moment";
// import { useHistory } from "react-router-dom";
import cx from "classnames";
import { getAllCurrencies } from "../../../redux/Actions/vendor_course";
import PageTitle from "../../HeaderTitle/PageTitle";
import { createSubscription } from "../../../redux/Actions/subscription-action"
import SweetAlert from 'sweetalert-react';

function CreateSubscription() {

    const [LiveClass, setLiveClass] = useState(false);
    const [OnDemand, setOnDemand] = useState(false);
  
    // const [ImageFile, setImageFile] = useState(null);
    const [start_date, setStartDate] = useState(null)
    const [end_date, setEndDate] = useState(null)
    const [open, setOpen] = useState(false)

    
    const [CourseDescription, setCourseDescription] = useState() //literal description about course


    const [inputField, setInputField] = useState({
        course_name: "",
        proposed_fee: "",
        course_start_date: "",
        course_end_date: "",
        ondemand_video_count: "",
        live_class_count: "",
        subscription_type: "",
        currency_id:''
      });

    const dispatch = useDispatch();
    
    const formData = new FormData();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const uploadProgressVideo = useSelector((state) =>
    //     state.course.uploadProgressVideo ? state.course.uploadProgressVideo : ""
    // );
    
     const create_loader = useSelector((state) =>state.Subscription.create_loader ? state.Subscription.create_loader : false);
    
     useEffect(()=>{
        dispatch(getAllCurrencies(tokenData.token,cb));
    },[])
    const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setInputField({ ...inputField, [name]: value });
    };
    const [errors, setErrors] = useState({})

    // const handleImageUpload = (e) => {
    //     setImageFile(e.target.files[0]);
    // };


    const handleChangeFormDate = (e, val) => {
        const dt = moment(e).format("YYYY-MM-DD");

    if (val === 'course_start_date') {
        setStartDate(e)
        setInputField({ ...inputField, [val]: dt });
    }
    else {
        setEndDate(e)
        setInputField({ ...inputField, [val]: dt });
    }
    }

    const onEditorStateChange = (editorState) => {
    setCourseDescription(editorState);
    };

    const [Currencies, setCurrencies] = useState()

    const cb = (data) =>{
    setCurrencies(data.currencies)
    // console.log("dattttt",data)
    }

    const submitForm = (e) => {
        e.preventDefault();
        let desc = CourseDescription ? draftToHtml(convertToRaw(CourseDescription.getCurrentContent())) : ""

        const err = {
            subscription_name: requiredValidator(inputField.subscription_name),
            currency_id: requiredValidator(inputField.currency_id),
            proposed_fee: requiredValidator(inputField.proposed_fee),
            course_start_date: requiredValidator(inputField.course_start_date),
            course_end_date: requiredValidator(inputField.course_end_date),
            subscription_type:requiredValidator(inputField.subscription_type),
          };
   
          if (
            !err.subscription_name &&
            !err.proposed_fee &&
            !err.course_start_date &&
            !err.course_end_date &&      
            !err.currency_id &&
            !err.subscription_type
      
          ) {
              setErrors(err)
            //   if(ImageFile){
            //     formData.append("image_file", ImageFile);
            //   }
              formData.append("subscription_name", inputField.subscription_name);
              formData.append("subscription_fee", inputField.proposed_fee);
              formData.append("subscription_start_date", inputField.course_start_date);
              formData.append("subscription_end_date", inputField.course_end_date);
              formData.append("ondemand_video_enabled", OnDemand ? 1 : 0);
              formData.append("live_class_enabled", LiveClass ? 1 : 0);
              formData.append("description", desc);
              formData.append("currency_id", inputField.currency_id);
              formData.append("subscription_type", inputField.subscription_type);
              dispatch(createSubscription(tokenData.token, formData, handleOpenSuccessModal,));
            }
          else {
            setErrors(err);
          }
    }
    const handleOpenSuccessModal=()=>{
        document.getElementById("class-form").reset();
        setInputField({
            subscription_name: "",
            proposed_fee: "",
            course_start_date: "",
            course_end_date: "",
            ondemand_video_count: "",
            live_class_count: "",
            subscription_type: "",
            currency_id:''
          })
          setCourseDescription('')
          setStartDate(null);
          setEndDate(null);
          setLiveClass(false);
          setOnDemand(false)
        setOpen(true)
    }
    const handleClose=()=>{
       
        setOpen(false)

    }
    return (
        <div>
        <PageTitle
        // style={{ display: "none" }}
        heading="Create Subscription"
        subheading="Create Susbcription"
        icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
        />


        <Card>
            <CardBody>
                <Form id="class-form" onSubmit={submitForm} >
                    <Card className="main-card mb-3">
                        <CardBody>
                        <CardTitle>GENERAL DETAILS</CardTitle>

                        <FormGroup row>
                            <Col sm={12}>
                            <Row>
                                <Col className="pt-3" sm={6}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Subscription Name
                                </Label>
                                <Input
                                    type="text"
                                    id='subscription_name'
                                    name="subscription_name"
                                    invalid={errors.subscription_name}
                                    onChange={handleChangeInput}
                                    value={inputField.subscription_name}
                                    autocomplete="off"
                                />
                                <FormFeedback>{errors.subscription_name}</FormFeedback>
                                </Col>

                                
                                <Col className="pt-3"  xs={12} sm={6}>
                                    <Label for="exampleNameF">
                                    <span className="text-danger" >*</span> Proposed Fee
                                    </Label>
                                    <Input
                                        name="proposed_fee"
                                        id="proposed_fee"
                                        onChange={handleChangeInput}
                                        value={inputField.proposed_fee}
                                        type="number"
                                        invalid={errors.proposed_fee}
                                        autocomplete="off"
                                    ></Input>
                                    <FormFeedback>{errors.proposed_fee}</FormFeedback>
                                </Col>
                                <Col className="pt-3"  xs={12} sm={6}>
                                    <Label for="exampleNameF">
                                    <span className="text-danger" >*</span> Currency
                                    </Label>
                                    <Input
                                        name="currency_id"
                                        onChange={handleChangeInput}
                                        value={inputField.currency_id}
                                        type="select"
                                        invalid={errors.currency_id}
                                        id='currency_id'
                                    >
                                        <option value={""}>select</option>
                                        {
                                        Currencies?.map( currency =>{
                                            return <option value={currency.currency_id}>{currency.currency_name}</option>
                                        } )
                                        }
                                    </Input>
                                    <FormFeedback>{errors.currency_id}</FormFeedback>
                                </Col>
                                
                                <Col xs={12} sm={6} className="pt-3">
                                <Label for="exampleInput" >
                                    <span className="text-danger" id='course_start_date'>*</span>
                                    Subscription Type
                                </Label>
                                    <Input
                                        name="subscription_type"
                                        id='subscription_type'
                                        onChange={handleChangeInput}
                                        type="select"
                                        invalid={errors.subscription_type}
                                    >
                                        <option value={""}>select type</option>
                                        <option value={'monthly'}>monthly</option>
                                        <option value={'quarterly'}>quarterly</option>
                                        <option value={'half-yearly'}>half-yearly</option>
                                        <option value={'yearly'}>yearly</option>
                                    </Input>
                                    <FormFeedback>{errors.subscription_type}</FormFeedback>
                                </Col>

                                <Col xs={12} sm={6} className="pt-3">
                                <Label for="exampleInput" >
                                    <span className="text-danger" id='course_start_date'>*</span>
                                    Subscription start date
                                </Label>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                    format="dd/MM/yyyy"
                                    label=""
                                    size='small'
                                    inputVariant="outlined"
                                    value={start_date}
                                    onChange={date => handleChangeFormDate(date,'course_start_date')}
                                    minDate={new Date()}
                                    fullWidth
                                    id='course_start_date'
                                    views={["year", "month", "date"]}
                                    openTo="year"
                                />
                                </MuiPickersUtilsProvider>
                                <span style={{ color: 'red' }}>{errors.course_start_date}</span>
                                </Col>
                                <Col xs={12} sm={6} className="pt-3">
                                    <Label for="exampleInput" >
                                        <span className="text-danger" id='course_end_date'>*</span>
                                        Subscription end date
                                    </Label>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                    
                                            format="dd/MM/yyyy"
                                            label=""
                                            size='small'
                                            inputVariant="outlined"
                                            value={end_date}
                                            onChange={date => handleChangeFormDate(date,'course_end_date')}
                                            minDate={start_date ? start_date : ''}
                                            fullWidth
                                            id='course_end_date'
                                            views={["year", "month", "date"]}
                                            openTo="year"
                                        />
                                    </MuiPickersUtilsProvider>

                                    <span style={{ color: 'red' }}>{errors.course_end_date}</span>
                                </Col>
                                
                                

                                <Col sm={4} className="pt-3" >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {" "}
                                        <label className="pt-2">
                                        {/* <span className="text-danger">*</span> */}
                                        On demand video
                                        </label>{" "}
                                        <div
                                        className="switch has-switch ml-1 "
                                        data-on-label="ON"
                                        data-off-label="OFF"
                                        onClick={() => {
                                            setOnDemand(!OnDemand)
                                        }}
                                        >
                                        <div
                                            className={cx("switch-animate", {
                                            "switch-on": OnDemand,
                                            "switch-off": !OnDemand,
                                            })}
                                        >
                                            <input type="checkbox" />

                                            <span className="switch-left bg-success">YES</span>
                                            <label>&nbsp;</label>
                                            <span className="switch-right bg-danger">NO</span>
                                        </div>
                                        </div>
                                    </div>
                                </Col>

                            <Col sm={4} className="pt-3" >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {" "}
                                    <label className="pt-2">
                                    {/* <span className="text-danger">*</span> */}
                                    Live Class
                                    </label>{" "}
                                    <div
                                    className="switch has-switch ml-1 "
                                    data-on-label="ON"
                                    data-off-label="OFF"
                                    onClick={() => {
                                        setLiveClass(!LiveClass)
                                    }}
                                    >
                                    <div
                                        className={cx("switch-animate", {
                                        "switch-on": LiveClass,
                                        "switch-off": !LiveClass,
                                        })}
                                    >
                                        <input type="checkbox" />

                                        <span className="switch-left bg-success">YES</span>
                                        <label>&nbsp;</label>
                                        <span className="switch-right bg-danger">NO</span>
                                    </div>
                                    </div>
                                </div>

                                </Col>
                                </Row>
                            </Col>
                        </FormGroup>
                        </CardBody>
                    </Card>

       
                    <Card className="main-card mb-3">
                        <CardBody>
                        <CardTitle>COURSE DESCRIPTION</CardTitle>
                        <Editor
                            // toolbarHidden
                            editorState={CourseDescription}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: { inDropdown: false },
                            options: ['inline', 'link','history','fontSize','fontFamily','list']
                            }}
                        />
                        </CardBody>
                    </Card>

     
                        {/* <Row>
                            <Col sm={6}>
                            <Label for="exampleInput">
                                Course Logo (200 x 200)px 
                            </Label>
                            <Input
                                id="exampleInput"
                                type="file"
                                //required
                                onChange={handleImageUpload}
                                accept="image/*"
                            ></Input>
                            </Col>

                            <Col sm={12}>
                            {(uploadProgressVideo !== "" &&  ImageFile) ? (
                                <>
                                <strong style={{ color: "rgb(19, 140, 228)" }}>
                                    Uploading Files...
                                </strong>
                                <Progress percent={uploadProgressVideo} />
                                </>
                            ) : null}
                            </Col>
                        </Row> */}
                  

                    <LaddaButton
                        className="mb-2 mr-2 btn btn-primary"
                        loading={create_loader}
                        type="submit"
                        data-style={ZOOM_IN}
                    >
                        Save Subscription Details
                    </LaddaButton>
                </Form>
            </CardBody>
        </Card>

        <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={open}
        text="Created successfully"
        type="success"
        onConfirm={() => handleClose()} />
        </div>
    )
}

export default CreateSubscription
