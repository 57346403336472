import React, { useEffect } from 'react'
import Table from '../Table'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyMaterialNotes } from '../../../redux/Actions/homeTeacher'

function Index() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(getStudyMaterialNotes(tokenData.token))
    }, [])

    return (
        <div>
            <Table
                action='study_notes_student'
            />
        </div>
    )
}

export default Index