export const TeacherNav = [
    
    {
        icon: 'pe-7s-home',
        label: 'Home',
        content: [],
        to: '#/dashboard/overview'

    },
    {
        icon: 'pe-7s-display2',
        label: 'Class',
        content: [
            {
                label: 'Create Class',
                to: '#/dashboard/createClass',
            },
            {
                label: 'Show Class',
                to: '#/dashboard/show-class',
            },
            {
                label: 'Special Class',
                to: '#/dashboard/specialClass',
            },
            {
                label: 'Video Class',
                to: '#/dashboard/videoClass',
            },

        ],
    },
    {
        icon: 'pe-7s-note2',
        label: 'Assignment',
        to: '#/dashboard/assignments',
        content: [

        ],
    },
    {
        icon: 'pe-7s-id',
        label: 'Attendance',
        content: [

        ],
        to: '#/dashboard/show-attendance',

    },
 
    {
        icon: 'pe-7s-add-user',
        label: 'Students',
        to: '#/dashboard/students',
        content: [

        ],
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Subjects',
        to: '#/dashboard/subjects',
        content: [

        ],
    },
    {
        icon: 'pe-7s-notebook',
        label: 'Recording',
        to: '#/dashboard/Recordings',
        content: [

        ],
    },
    {
        icon: 'pe-7s-video',
        label: 'Video Session',
        content: [
            {
                label: 'Playlist',
                to: '#/dashboard/playlist',
            },
            {
                label: 'Create  Session',
                to: '#/dashboard/createVideoSession',
            },

        ],
    },
    {
        icon: 'pe-7s-users',
        label: 'User',
        content: [
            {
                label: 'Create',
                to: '#/dashboard/forms',
            },
            {
                label: 'Show Teacher',
                to: '#/dashboard/show-teacher',
            },
            {
                label: 'Show Student',
                to: '#/dashboard/show-student',
            },
            {
                label: 'Show Staff',
                to: '#/dashboard/show-staff',
            },

        ],
    },


];

export const TeacherSubNav = [



    {
        icon: 'pe-7s-config',
        label: 'Settings',
        content: [],
    },
    {
        icon: 'pe-7s-angle-right-circle',
        label: 'Change Password',
        content: [],
        to: '#/dashboard/change-password'
    },
    {
        icon: 'pe-7s-edit',
        label: 'My Account',
        content: [],
        to: '#/dashboard/my-account'
    },
];

export const studentNav = [
    {
        icon: 'pe-7s-home',
        label: 'Home',
        content: [],
        to: '#/dashboard/overview'

    },
    {
        icon: 'pe-7s-display2',
        label: 'Class',

        content: [
            {
                label: 'Show Class ',
                to: '#/dashboard/show-class',
            },
            {
                label: 'Special Class',

                to: '#/dashboard/specialClass',
            },
            {
                label: 'Video Class',
                to: '#/dashboard/videoClass',
            },

        ]
    },

    {
        icon: 'pe-7s-note2',
        label: 'Assignment',
        content: [

        ],
        to: '#/dashboard/assignments'

    },
    {
        icon: 'pe-7s-id',
        label: 'Attendance',
        content: [

        ],
        to: '#/dashboard/show-attendance',

    },
   
]

export const studentSubNav = [



    {
        icon: 'pe-7s-config',
        label: 'Settings',
        content: [],
    },
    {
        icon: 'pe-7s-angle-right-circle',
        label: 'Change Password',
        content: [],
        to: '#/dashboard/change-password'
    },
    {
        icon: 'pe-7s-edit',
        label: 'My Account',
        content: [],
        to: '#/dashboard/my-account'
    },
];




export const principalNav = [

    {
        icon: 'pe-7s-config',
        label: 'Home',
        content: [],
    },
    {
        icon: 'pe-7s-angle-right-circle',
        label: 'Change Password',
        content: [],
        to: '#/dashboard/change-password'
    },
    {
        icon: 'pe-7s-edit',
        label: 'My Account',
        content: [],
        to: '#/dashboard/my-account'
    },
    {
        icon: 'pe-7s-users',
        label: 'Group',
        to: '#/dashboard/group',
        content: [

        ],
    },
];

export const principalSubNav = [



    {
        icon: 'pe-7s-config',
        label: 'Settings',
        content: [],
    },
    {
        icon: 'pe-7s-angle-right-circle',
        label: 'Change Password',
        content: [],
        to: '#/dashboard/change-password'
    },
    {
        icon: 'pe-7s-edit',
        label: 'My Account',
        content: [],
        to: '#/dashboard/my-account'
    },
];


export const SuperAdminSubNav = [



    {
        icon: 'pe-7s-config',
        label: 'Settings',
        content: [],
        to: '#/dashboard/settings'
    },

    {
        icon: 'pe-7s-edit',
        label: 'My Account',
        content: [],
        to: '#/dashboard/my-account'
    },
];

