const initialState = {
    videosListDetail: [],
    permissionDetail: [],
    role: [],
    group: [],
    teacherList: [],
    userData: '',
    studentList: [],
    staffList: [],
    userList: [],
    studentGroup: [],
    userListStaff: [],
    userSubLoader: false,
    listUser: [],
    users: '',
    singleRegLoader: false,
    editPrivilegeLoader: false,
    bulkLoader: false,
    studentGroupLoader: false
}

const videoReducer = (state = initialState, { type, payload }) => {
    // console.log("reducer..",payload);
    switch (type) {

        case 'UPDATE_STUDENT_SUCCESS': {
            let filtered = state.studentList.filter(users => users.user_id !== payload.user_id)
            filtered = [payload, ...filtered]
            return {
                ...state, userSubLoader: false, studentList: filtered

            }
        }
        case 'UPDATE_TEACHER_SUCCESS': {
            let filtered = state.teacherList.filter(users => users.user_id !== payload.user_id)
            filtered = [payload, ...filtered]
            return {
                ...state, userSubLoader: false, teacherList: filtered

            }
        }
        case 'EDIT_TEACHER_REQUEST': {
            return {
                ...state, editPrivilegeLoader: true
            }
        }
        case 'EDIT_TEACHER_SUCCESS': {
            return {
                ...state, editPrivilegeLoader: false
            }
        }





        case 'CREATE_USER_FAILED': {
            return {
                ...state, singleRegLoader: false, bulkLoader: false
            }
        }




        case 'CREATE_TEACHER_REQUEST': {
            return {
                ...state, singleRegLoader: true, bulkLoader: true

            }
        }
        case 'CREATE_TEACHER_SUCCESS': {
            return {
                ...state, singleRegLoader: false, bulkLoader: false
            }
        }
        case 'CREATE_TEACHER_FALSE': {
            return {
                ...state, singleRegLoader: false, bulkLoader: false

            }
        }
        case 'REQUEST_VIDEO_SUCCESS': {
            return {
                ...state, videosListDetail: payload
            }
        }

        case 'MODULE_SUCCESS': {
            return {
                ...state, permissionDetail: payload.privileges
            }
        }
        case 'ROLE_SUCCESS': {
            return {
                ...state, role: payload.roles
            }
        }
        case 'GROUP_SUCCESS': {
            return {
                ...state, group: payload.groups
            }
        }


        case 'STUDENT_GROUP_REQUEST': {
            return {
                ...state, studentGroupLoader: true
            }
        }
        case 'STUDENT_GROUP_SUCCESS': {
            return {
                ...state, studentGroup: payload.details, studentGroupLoader: false
            }
        }
        case 'TEACHER_REQUEST': {
            return {
                ...state, userSubLoader: true
            }
        }
        case 'TEACHER_REQUEST_SUCCESS': {
            return {
                ...state, teacherList: payload.details, userSubLoader: false
            }
        }
        // case 'TEACHER_REQUEST_SUCCESS': {
        //     return {
        //         ...state, userList: payload.details
        //     }
        // }
        case 'SUCCESS_EDIT_USER_DEAILS': {
            return {
                ...state, userData: payload.user_details
            }
        }
        case 'STUDENT_REQUEST': {
            return {
                ...state, userSubLoader: true
            }
        }

        case 'STUDENT_REQUEST_SUCCESS': {
            return {
                ...state, studentList: payload.details, userSubLoader: false
            }
        }
        case 'STAFF_REQUEST': {
            return {
                ...state, userSubLoader: true
            }
        }
        case 'STAFF_REQUEST_SUCCESS': {
            return {
                ...state, staffList: payload.details, userSubLoader: false
            }
        }
        case 'DELETE_TEACHER_SUCCESS': {
            return {
                ...state, userList: payload.details
            }
        }
        case 'UPDATE_USER_REQUEST': {
            return {
                ...state, userSubLoader: true

            }
        }
        case 'UPDATE_USER_SUCCESS': {
            let filtered = state.staffList.filter(users => users.user_id !== payload.user_id)
            filtered = [payload, ...filtered]
            return {
                ...state, userSubLoader: false, staffList: filtered

            }
        }
        case 'RESET_EDIT_PRIVILEGE': {
            return {
                ...state, userData: ''
            }
        }

        default:
            return state
    }

}

export default videoReducer;