import React, { useEffect, useState } from 'react';
import DataTable from '../../common/DataTable';
//import DataTable from '../../../Components/common/DataTable'
import { useDispatch, useSelector } from 'react-redux';
import { listAllRefundsForStudents } from '../../../redux/Actions/Student';
import Modals from '../../common/Modal';
import {Badge} from 'reactstrap';
function Index() {

    const StudentRefunds = useSelector(state => state.student.StudentRefunds)
    const tokenData = JSON.parse(localStorage.getItem('authDetails'))
    const dispatch = useDispatch();

    const coloumns = [
        { title: "Course_name", field: "course_name" },
        { title: "Course Amount", field: "course_amount" },
        { title: "Payment_date", field: "payment_date" },
        { title: "Status", field: "payment_status", render: (rowData) => {
            return rowData.payment_status === 'Refund Completed' ? (
              <Badge color="success" pill>
                {"COMPLETED"}
              </Badge>
            ) : (
              <Badge color="warning" pill>
                {"PENDING"}
              </Badge>
            );
          },  },
       
    ]

    const [Refunds, setRefunds] = useState( [  ])
    const [RefundModal, setRefundModal] = useState(false)

    const viewRefunds = (Refund) => {
        setRefunds(Refund)
        setRefundModal(true)
    }


    useEffect(() => {
        dispatch(listAllRefundsForStudents(tokenData.token))

    }, [])

    return (
        <div>
            <DataTable
                title="Refunds"
                columns={coloumns}
                data={StudentRefunds ? StudentRefunds : []}
                actions="student_refund"
                viewRefunds={viewRefunds}
            />
            <Modals

                title="Refunds"
                open={RefundModal}
                handleClose={() => setRefundModal(false)}
            >  { Refunds.length>0 ?
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Refund Date</th>
                            <th scope="col">Refund Amount</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Refunds.map((refund,n)=>{
                                return(
                                    <tr>
                                        <th scope="row">{n+1}</th>
                                        <td>{refund.refund_date}</td>
                                        <td>{refund.refund_amount}</td>
                                    </tr>
                                )
                            })
                        }
            
                    </tbody>
                </table>:
                <div>Refunds are pending </div>
                }
            </Modals>

        </div>


    )
}

export default Index
