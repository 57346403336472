import React, { Fragment,} from 'react'

import {
    FormFeedback,
    Row, Col,
    
    Label, 
    Input,
} from 'reactstrap';

const Teacher = (props) => {

    const { errors, handleChange } = props;
    return (
        <Fragment>
            
                <Row>
                    <Col md="12" xs="12">
                            <Label for="exampleEmail">First Name</Label>
                            <Input type="text" name="first_name" id="firstname"
                                placeholder="First name"
                                onChange={(e) => handleChange(e)}
                                invalid={errors.first_name}
                                autocomplete="off"
                            />
                            <FormFeedback >{errors.first_name}</FormFeedback>

                    </Col>
                    <Col md="12" xs="12">
                            <Label for="examplePassword">Last Name</Label>
                            <Input type="text" name="last_name" id="lastname"
                                placeholder="Last Name"
                                // required
                                invalid={errors.last_name}
                                onChange={(e) => handleChange(e)}
                                autocomplete="off"

                            />
                            <FormFeedback >{errors.last_name}</FormFeedback>

                    </Col>
                </Row>
                <Row>
                    <Col md="12" xs="12">
                            <Label for="examplePassword">Phone</Label>
                            <Input type="text" name="teacher_mobile" id="phone"
                                placeholder="phone"
                                // required
                                onChange={(e) => handleChange(e)}
                                autocomplete="off"
                                invalid={errors.enterprise_mobile}
                            />
                            <FormFeedback >{errors.enterprise_mobile}</FormFeedback>

                    </Col>
                    <Col md="12" xs="12">
                            <Label for="exampleEmail">Email</Label>
                            <Input type="text" name="email" id="email"
                                placeholder="Email"
                                // required
                                invalid={errors.enterprise_email}
                                onChange={(e) => handleChange(e)}
                                autocomplete="off"

                            />
                            <FormFeedback >{errors.enterprise_email}</FormFeedback>

                    </Col>
                </Row>
                <Row>
                    <Col md="12" xs="12">
                            <Label for="exampleEmail">Password</Label>
                            <Input type="password" name="password" id="password"
                                placeholder="Password"
                                // required
                                invalid={errors.password}

                                onChange={(e) => handleChange(e)}
                                autocomplete="off"
                            />
                            <FormFeedback >{errors.password}</FormFeedback>

                    </Col>
                </Row>
            {/* </Card> */}
        </Fragment>

    )
}

export default Teacher;
