import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';

import {
    faHome

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TitleComponent3 = (props) => {


    return (
        <Fragment>
            <Breadcrumb style={{width:'100%'}}>
                <BreadcrumbItem>
                    <Link to="/dashboard" ><FontAwesomeIcon icon={faHome} /></Link>

                </BreadcrumbItem>
                <BreadcrumbItem>
                    <Link to="/dashboard/assignments" >Assignments</Link>

                </BreadcrumbItem>

                <BreadcrumbItem active>Details</BreadcrumbItem>
            </Breadcrumb>
        </Fragment>
    );

}
export default TitleComponent3