import React, { useState, useEffect,useRef   } from 'react'
import { getMyAccount } from '../../../redux/Actions/homeTeacher'
import { useDispatch, useSelector } from "react-redux";
import { uploadVendorFiles } from "../../../redux/Actions/vendor_course"
import {
  Row,
  Col,
  Form,
  // FormGroup,
  Input,
  // InputGroupAddon,
  Card,
  CardBody,
  Label,
  CardTitle,
  FormFeedback,
  Button,
} from "reactstrap";
import { Progress } from "react-sweet-progress";
import LaddaButton from 'react-ladda/dist/LaddaButton';
// import Loader from 'react-spinners/ClipLoader';
import Modal from '../../common/Modal'
import { requiredValidator, } from '../../../utilities/Validator'
// import Swal from 'sweetalert2'


import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
// import htmlToDraft from 'html-to-draftjs';
//import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  ContentState,
  // convertFromHTML,
  convertToRaw,
} from "draft-js";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import htmlToDraft from 'html-to-draftjs';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
// import LoadingOverlay from 'react-loading-overlay';

const Index = () => {
  useEffect(() => {
    dispatch(getMyAccount(tokenData.token, cb))
  }, [])



  const uploadProgressDoc = useSelector((state) =>
    state.course.uploadProgressDoc ? state.course.uploadProgressDoc : ""
  );

  // console.log("progresssss", uploadProgressDoc)
  const [Logo, setLogo] = useState();
  const [BtnLoder, setLoder] = useState();
  const [SupportFiles, setSupportFiles] = useState(false);
  const [Load, setLoad] = useState(false);
  // const [chk, setChk] = useState(false);

  const formData = new FormData();
  const dispatch = useDispatch()

  const [InputField, setInputField] = useState({
    "first_name": "",
    "last_name": "",
    "desc": "",
    "pan": ""
  })



  const [Description, setDescription] = useState()

  const cb = (accountInfo) => {
    // console.log("acc", accountInfo)
    setInputField({ ...InputField, first_name: accountInfo.first_name, last_name: accountInfo.last_name, pan: accountInfo.pan, gst: accountInfo.gst_number })
    
    const html =accountInfo.enterprise_description
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setDescription(editorState)
    // setDescription(EditorState.createWithContent(
    //   ContentState.createFromBlockArray(
    //     convertFromHTML(
    //       accountInfo.enterprise_description
    //     )
    //   )
    // ))

  }
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
 // const accountInfo = useSelector(({ homeTeacher }) => homeTeacher)

  const handleInput = (e) => {
    const { name, value } = e.target
    setInputField({ ...InputField, [name]: value });
  }

  // console.log("input", InputField)
  // console.log("token dataaaa",tokenData)

  const handleFile = (e) => {
    // let arr = [];
    // for (let i = 0; i < e.target.files.length; i++) {
    //   arr.push(e.target.files[i]);
    //   // formData.append('doc_url[]', e.target.files[i])
    // }
    setSupportFiles(e.target.files[0])
  };

  const cropperRef = useRef(null);
  const [src,setSrc]=useState('')

  const [cropData, setCropData] = useState("");

  const handleLogo = (e) => {
    e.preventDefault()
    const reader = new FileReader();

    reader.onload = () => {
      setSrc(reader.result)
    };
    if(e.target.files[0]){
      reader.readAsDataURL(e.target.files[0]);
      setLogo(e.target.files[0])
    }
   
  }
  
  const [Brochure, setBrochure] = useState()
  const handleBrochure = (e) => {
    setBrochure(e.target.files[0])
  }
  const [SiteImage, setSiteImage] = useState()
  const handleSiteImage = (e) => {
    setSiteImage(e.target.files[0])
  }
  
  const [Banner, setBanner] = useState()
  const handleBanner = (e) => {
    setBanner(e.target.files[0])
  }

  const [errors, setErrors] = useState({})

  const submitForm = (e) => {
    e.preventDefault()
    // console.clear()
    // console.log("description",Description)
    let logo
    let desc = draftToHtml(convertToRaw(Description.getCurrentContent()))
    if(src && !cropData){
      logo='*Crop the selected image'
    }
    else{
      logo=''
    }
  //  let desc_length = desc.length
    // if(desc_length<68) { //+8 offset (60 letter long description)
    //   Swal.fire({
    //     icon: 'warning',
    //     text: 'Description must contains 60 charactors'
    //   })
    // }
    // else {

      const err = {
        first_name: requiredValidator(InputField.first_name),
        last_name: requiredValidator(InputField.last_name),
        short_description: (InputField.short_description?.length > 60 ) ? '*Maximum 60 characters' :'',
        logoerr:logo
        // desc: InputField.desc ? "" : '* Required'

      };

      if (
        !err.first_name &&
        !err.last_name && 
        !err.short_description &&
        !err.logoerr
        // !err.desc
      ) {
        setErrors(err);
        formData.append("first_name", InputField.first_name)
        formData.append("last_name", InputField.last_name)
      //  formData.append("description", InputField.desc)
        formData.append("description",desc)
        formData.append('pan', InputField.pan)
        formData.append('gst_number', InputField.gst)
        if(InputField.short_description){ formData.append("short_description",InputField.short_description)}   
        if (SupportFiles) {formData.append("doc_url", SupportFiles ) }
        if (Brochure) {formData.append("brochure", Brochure ) }
        if (Banner)  {formData.append("site_image", Banner ) } 
        if (SiteImage) {formData.append("header_banner", SiteImage ) };  //site image and banner image variable name are interchanged in code
        // Logo ?  formData.append("enterprise_logo_url", Logo ) : console.log("no logo"); 
        if(cropData){
          let file=b64toBlob(cropData)
          // console.log('aaaaaa',file)
          formData.append("enterprise_logo_url", file )

        } 

        
        dispatch(uploadVendorFiles(tokenData.token, formData, cb2, errormodel))

        setLoder(true)
      } else {
        setErrors(err);
        if(err.reseller_name){
          Showerror('first_name')
        }
        else if(err.last_name){
          Showerror('last_name')
        }
        else if(err.short_description){
          Showerror('short_description')
        }
      }
    // }
  }
  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

//////to convert binary64 to blob
const b64toBlob=(dataURI)=> {
  
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

  const [err, setErr] = useState({
    open_err: false,
    err_list: []
  })
  const errormodel = (val) => {
    setErr({
      open_err: true,
      err_list: val
    })
  }
  const closeErr = () => {
    setErr({
      open_err: false,
      err_list: []
    })
  }


  const cb2 = () => {
    setLoder(false)
    document.getElementById("docs").reset();
    setLogo(null)
    setBrochure(null)
    setSupportFiles(null)
    setSiteImage(null)
    setBanner(null)
    
    dispatch(getMyAccount(tokenData.token, cb))
    // setInputField({
    //     "first_name":"",
    //     "last_name":"",
    //     "desc":""
    // })
  }


  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  const CropImage=()=>{
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      // console.log(cropper.getCroppedCanvas().toDataURL());
      let data=cropper.getCroppedCanvas().toDataURL()
      // setLoad(true)
      // console.log('start')
  }

  const getCropData = (e) => {
    e.preventDefault()
    setLoad(true)
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper
    if (typeof cropper !== "undefined") {
      // console.log(cropper.getCroppedCanvas().toDataURL());
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setLoad(false)
    }
  };

  // const Start=()=>{
  //   console.log('started')
  //   setChk(true)
  //   // setLoad(true)

  // }
  // const End=()=>{
  //   setLoad(false)
  //   console.log('end')

  // }
  return (
    <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
    <div>
      <Form id={"docs"} onSubmit={submitForm}>
        <Card className="m-4" >
          <CardTitle className="pl-4 pt-4" >Upload Profile info</CardTitle>
          <CardBody>
            <Row>
              <Col sm={6}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> First Name
                       </Label>
                <Input
                  name="first_name"
                  value={InputField.first_name}
                  invalid={errors.first_name}
                  id='first_name'
                  onChange={handleInput}></Input>
                <FormFeedback>{errors.first_name}</FormFeedback>

              </Col>
              <Col sm={6}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Second Name
                       </Label>
                <Input
                  name="last_name"
                  value={InputField.last_name}
                  onChange={handleInput}
                  invalid={errors.last_name}
                  id='last_name'
                ></Input>
                <FormFeedback>{errors.last_name}</FormFeedback>

              </Col>

            </Row>
            <Row className="pt-2">
              <Col sm={12}>
                <Label for="exampleNameF">
                  {/* <span className="text-danger">*</span>  */}
                  Description
                       </Label>
                {/* <Input 
                  type='textarea' 
                  name="desc" 
                  value={InputField.desc} 
                  invalid={errors.desc}
                  onChange={handleInput}></Input>
                <FormFeedback>{errors.desc}</FormFeedback> */}
                <Editor
                  // toolbarHidden
                  editorState={Description}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: { inDropdown: false },
                    options: ['inline', 'link','history']

                  }}
                />
              </Col>
              <Col className='pt-1' sm={6}>
                <Label for="exampleNameF">
                  PAN
                       </Label>
                <Input name="pan" value={InputField.pan} onChange={handleInput}></Input>
              </Col>
              <Col className='pt-1' sm={6}>
                <Label for="exampleNameF">
                  GST no
                       </Label>
                <Input name="gst" value={InputField.gst} onChange={handleInput}></Input>
              </Col>
              <Col className='pt-1' sm={12}>
              <span className="text-danger">*</span>   <Label for="exampleNameF">
                  Short Description (Maximum 60 characters)
                       </Label>
                <Input id='short_description' name="short_description" invalid={errors.short_description} value={InputField.short_description} onChange={handleInput}></Input>
                <FormFeedback>{errors.short_description}</FormFeedback>
              </Col>
            </Row>
            <Row>


              <Col className="mt-4" xs={12}>

                <Label for="exampleNameF">
                  Logo
                  </Label>

                <Input type="file" onChange={handleLogo} accept="image/*"></Input>
                </Col>

                {/* <LoadingOverlay
                active={Load}
                spinner
                text='Loading your content...'
                > */}
                    <Col className="mt-4" xs={12} sm={6}>
                    {
                      src ? 
                      <>
                      <Cropper
                        src={src}
                        style={{ height: '400px', width: '100%' }}
                        aspectRatio={5 / 3}
                        guides={false}
                        crop={CropImage} 
                        ref={cropperRef}
                        // cropstart={Start}
                        // cropend={End}
                        />
                        <div className="divider"/>
                        <div className="text-center">   
                            <div className="text-center">
                                {/* <Button color="primary"
                                      onClick={getCropData}
                                  >
                                    Crop
                                </Button> */}
                                <LaddaButton 
                                  className="btn btn-danger" 
                                  loading={Load} 
                                  onClick={getCropData}
                                  >
                                  Crop Image
                                </LaddaButton>
                            </div> 
                          <span style={{color:'red'}}>{errors.logoerr}</span>
                        </div>            
                        </>
                        : 
                        ''
                    }
                    </Col>

                    <Col xs={12} sm={6}>
                    {
                      cropData ? 
                      <div>
                        <div>
                            <h6>Cropped Result</h6>
                        </div>
                        <img
                            className="after-img rounded"
                            src={cropData}
                            alt=""
                            width='100%'
                        />
                    </div>
                    : null
                    }
                    </Col>
                    {/* </LoadingOverlay> */}
              
              <Col className="mt-4" sm={6}>

                <Label for="exampleNameF">
                  Supporting doccuments (PDF)
                </Label>

                <Input name="vendor_file" onChange={handleFile} type="file" accept = "application/pdf"></Input>
              </Col>
              <Col className="mt-4" sm={6}>

                <Label for="exampleNameF">
                 Site image (1200 x 800)px
                       </Label>

                <Input name="vendor_file" onChange={handleBanner} type="file" accept="image/*"></Input>
              </Col>
              <Col className="mt-4" sm={6}>

                <Label for="exampleNameF">
                Brochure(PDF) 
                       </Label>

                <Input name="vendor_file" onChange={handleBrochure} type="file" accept = "application/pdf"></Input>
              </Col>
              <Col className="mt-4" sm={6}>

                <Label for="exampleNameF">
                Banner image (6250 x 1250)px
                       </Label>

                <Input name="site_image_file" onChange={handleSiteImage} type="file" accept="image/*"></Input>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col sm={10}>
                {(uploadProgressDoc !== "") && (Logo || SupportFiles || Banner || Brochure || SiteImage) ? (
                  <>
                    <strong style={{ color: "rgb(19, 140, 228)" }}>
                      Uploading Files...
                      </strong>
                    <Progress percent={uploadProgressDoc} />
                  </>
                ) : null}
              </Col>
                <Col sm={2}><LaddaButton className="btn btn-primary" loading={BtnLoder} >Submit</LaddaButton></Col>
              {/* <Col sm={2}><Button type="submit" color="primary" >Submit</Button></Col> */}
            </Row>
          </CardBody>
        </Card>
      </Form>

      {/* <Card className="m-4">
            <CardBody>
            <Editor
              // toolbarHidden
              // editorState={Answer}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image:{ inDropdown: false },
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],

              }}
            />
            </CardBody>
      </Card> */}

      <Modal
        open={err.open_err}
        handleClose={closeErr}
        title={`Errors`}
      >
        {
          <ul>

            {
              err.err_list.map((error, ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

            }
          </ul>

        }
      </Modal>

    </div>
    </CSSTransitionGroup>
  )
}

export default Index
