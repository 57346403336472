import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Badge,
  Button,
  Input, FormFeedback, Label,
  // TabContent, TabPane, Nav, NavItem, NavLink,
  Card, CardBody, CardHeader, Container
  // Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from "reactstrap";
// import ViewStudent from './viewStudent'
// import Swal from 'sweetalert2'
import Slider from "react-slick";

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import bg1 from '../../../assets/utils/images/dropdown-header/abstract1.jpg';
import bg2 from '../../../assets/utils/images/dropdown-header/abstract2.jpg';
import bg3 from '../../../assets/utils/images/dropdown-header/abstract3.jpg';
import bg4 from '../../../assets/utils/images/dropdown-header/abstract4.jpg';
import bg5 from '../../../assets/utils/images/dropdown-header/abstract5.jpg';
import bg6 from '../../../assets/utils/images/dropdown-header/abstract6.jpg';
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';

// import Modals from '../../common/Modal'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from "react-redux";
import { getAllSchool, deleteSchool, blockSchool, listSchoolsUnderReseller, payoffCommision, correctCommision } from "../../../redux/Actions/Superadmin";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import SweetAlert from 'sweetalert-react';
import { requiredValidator, } from '../../../utilities/Validator'
import Modal from "../../common/Modal/index"
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const Schoollist = () => {
  var columns;
  const [Message, setMessage] = useState(null)
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const SchoolArray = useSelector((state) =>
    state.superadmin.SchoolArray ? state.superadmin.SchoolArray : []
  );
  const schoolloader = useSelector((state) =>
    state.superadmin.schoolloader ? state.superadmin.schoolloader : ""
  );
  const payoffloader = useSelector((state) =>
    state.superadmin.payoffloader ? state.superadmin.payoffloader : false
  )
  useEffect(() => {
    if (tokenData.role === 'superAdmin') {
      dispatch(getAllSchool(tokenData.token));
    } else {
      dispatch(listSchoolsUnderReseller(tokenData.token));
    }
  }, []);

  columns = [
    { title: "Name", field: "school_name" },
    { title: "Panchayath", field: "panchayath_name" },
    { title: "District", field: "district_name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "contact_number" },
    // { title: "Balance", field: "balance" },
    // { title: "Referal_Code", field: "referral_code" },
    // { title: "Students", field: "total_students" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return rowData.status === 1 ? (
          <Badge color="danger" pill>
            {'Blocked'}
          </Badge>
        ) : (
          <Badge color="success" pill>
            {'Active'}
          </Badge>
        );
      },
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
          breakpoint: 1024,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
          }
      },
      {
          breakpoint: 600,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
          }
      },
      {
          breakpoint: 480,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
  ]
  };
  const data = SchoolArray ? SchoolArray.schools ? SchoolArray.schools : [] : [];
  const recentschools = SchoolArray ? SchoolArray.newSchools ? SchoolArray.newSchools : [] : [];

  const action = "school_table";



  const Editschool = (val) => {
    history.push(`/dashboard/editSchool/${val.school_id}`)
  }

  const Deleteschool = (val) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(deleteSchool(tokenData.token, val.school_id, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Delete action cancelled',
          'error'
        )
      }
    })
  }


  const [successModal, setSuccessModal] = useState(false);

  const handleOpenSuccessModal = (message) => {
    setSchool({ id: '', open: false })
    dispatch(getAllSchool(tokenData.token));
    setMessage(message)
    setSuccessModal(true)

  }
  const handleCloseSuccesstModal = () => {
    
    setMessage(null)
    SetInput({ id: '', amount: '', remarks: '' })
    setSuccessModal(false)

  }

  const View = (val) => {
    if (tokenData.role === 'superAdmin') {
      history.push(`/dashboard/schoollist/${val.school_id}`)
    }
    else {
      history.push(`/dashboard/schools/${val.school_id}`)
    }
  }

  const Updatestatus = (val) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(blockSchool(tokenData.token, val.school_id, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Cancelled',
          'error'
        )
      }
    })
  }


  const [school_data, setSchool] = useState({ id: '', open: false })
  const [inputField, SetInput] = useState({ id: '', amount: '', remarks: '' })
  const [errors, setErrors] = useState({})

  const CreditDebit = (val) => {
    setErrors({})
    setSchool({ id: val.school_id, open: true })
  }
  const CloseModel = () => {
    setSchool({ id: '', open: false })
  }

  const Handlechange = (event) => {
    SetInput({ ...inputField, [event.target.name]: event.target.value })
  }

  const submitPayoff = () => {
    const err = {
      id: requiredValidator(inputField.id),
      amount: requiredValidator(inputField.amount),
      remarks: requiredValidator(inputField.remarks),

    };
    if (
      !err.id &&
      !err.amount &&
      !err.remarks
    ) {
      setErrors(err);
      if (inputField.id === 'debit') {
        dispatch(payoffCommision(tokenData.token, school_data.id, inputField.amount, inputField.remarks, handleOpenSuccessModal))
      }
      else {
        dispatch(correctCommision(tokenData.token, school_data.id, inputField.amount, inputField.remarks, handleOpenSuccessModal))
      }
    }
    else {
      setErrors(err)
    }
  }

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container>

          {
            tokenData.role === 'superAdmin' ?
              <>
                {
                  recentschools.length > 2 ?
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardHeader>Recently Added Schools</CardHeader>
                          <CardBody>
                            <Container>
                              <Row>
                                <Col sm={12}>
                                  <Slider {...settings}>
                                    {recentschools.map((val, ky) => {
                                      // console.log("recent", val)
                                      return (
                                        <div style={{ boxSizing: 'border-box' }}>

                                          <div
                                            className="dropdown-menu-header"
                                            style={{ borderRadius: "5px" }}
                                          >
                                            <div
                                              className="dropdown-menu-header-inner bg-primary"
                                              style={{
                                                borderRadius: "5px",
                                                width: "90%",
                                              }}
                                            >
                                              <div
                                                className="menu-header-image"
                                                style={{
                                                  backgroundImage:
                                                    ky === 0
                                                      ? "url(" + bg1 + ")"
                                                      : ky === 1
                                                        ? "url(" + bg2 + ")"
                                                        : ky === 2
                                                          ? "url(" + bg3 + ")"
                                                          : ky === 3
                                                            ? "url(" + bg4 + ")"
                                                            : ky === 4
                                                              ? "url(" + bg5 + ")"
                                                              : ky === 5
                                                                ? "url(" + bg6 + ")"
                                                                : "",
                                                }}
                                              />
                                              <div className="menu-header-content">
                                                <h5 className="menu-header-title">
                                                  {val.name ? val.name.length > 21 ? val.name.slice(0,18)+'...':val.name:val.name}
                                                </h5>
                                                <h6 className="menu-header-subtitle">
                                                  {val.email}
                                                </h6>
                                                <h6 className="menu-header-subtitle">
                                                  {val.contact_number
                                                    ? val.contact_number
                                                    : ""}
                                                </h6>{" "}
                                                <br />
                                                <Button
                                                  solid
                                                  className="mb-2 mr-2 btn-hover-shine"
                                                  color="light"
                                                  onClick={() => {
                                                    history.push(`/dashboard/schoollist/${val.school_id}`);
                                                    console.log("valueeeee", val)
                                                  }}
                                                >
                                                  View Details
                                                </Button>
                                              </div>
                                            </div>
                                          </div>


                                        </div>
                                      );
                                    })}
                                  </Slider>
                                </Col>
                              </Row>
                            </Container>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    :
                    ''
                }
              </>
              :
              ''
          }


          <Row>
            <Col sm={12} md={12}>
              <Table
                actions={action}
                loader={schoolloader}
                data={data}
                title={"School List"}
                columns={columns}
                ViewDetails={View}
                Editschool={Editschool}
                Deleteschool={Deleteschool}
                Updatestatus={Updatestatus}
                CreditDebit={CreditDebit}
              />
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>


      <Modal
        open={school_data.open ? school_data.open : false}
        handleClose={CloseModel}
        title='New Payments'
      >
        <Row>
          <Col xs={12}>
            <Input
              type='select'
              style={{ width: "100%" }}
              onChange={Handlechange}
              name='id'
              value={inputField.id}
              invalid={errors.id}

            >
              <option value=''>Select Type</option>
              <option value='credit'>Credit</option>
              <option value='debit'>Debit</option>
            </Input>
            <FormFeedback>{errors.id}</FormFeedback>

          </Col>
          <Col xs={12} style={{ marginTop: '10px' }}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span>
                  Amount
                </Label>
            <Input
              min={0}
              type="number"
              style={{ width: "100%" }}
              name="amount"
              value={inputField.amount}
              onChange={Handlechange}
              invalid={errors.amount}
            />
            <FormFeedback>{errors.amount}</FormFeedback>
          </Col>

          <Col xs={12} style={{ marginTop: '10px' }}>
          <span className="text-danger">*</span>
            <Label for="exampleNameF">
              Remarks
                </Label>
            <Input
              type="textarea"
              rows={3}
              style={{ width: "100%" }}
              name="remarks"
              value={inputField.remarks}
              onChange={Handlechange}
              invalid={errors.remarks}
            />
            <FormFeedback>{errors.remarks}</FormFeedback>

          </Col>

          <Col xs={12} style={{ marginTop: '5px' }}>
            <LaddaButton className=" btn btn-primary"
              loading={payoffloader}
              type="button"
              onClick={() => submitPayoff()}
              data-style={ZOOM_IN}
            >
              Submit
                </LaddaButton>
          </Col>
        </Row>
      </Modal>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text={Message ? Message : "Sucessfull action"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />
    </>
  );
};

export default Schoollist;
