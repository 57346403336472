import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Row, Col,
    Card, CardBody, 
    CardHeader,FormGroup, CardFooter, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from 'react-loaders'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import serialize from 'form-serialize'
import { addStudentToClassroom, addStudentToEnterpriseClassroom } from '../../../../../redux/Actions/homeTeacher'

function GroupList(props) {
   
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const closeModal = () => {
    //     console.log("close it")
    // }

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const handleClick = (e) => {
        e.preventDefault();
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
  

            dispatch(addStudentToEnterpriseClassroom(props.class_id, body, tokenData.token, props.CloseSuccess, ""))


    };
    const handleChange = () => {
        console.log("callll")
    }
    console.log("propp",props)

    return (
        <div>
            <Form onSubmit={handleClick} >
                 <Card className="main-card mb-3">
                <CardHeader>
                    <Col md={5} sm={5}>
                        {tokenData.enterpriseUser ? "Groups" : "Student"}

                    </Col>

                    <Col md={7} sm={7}>
                        {tokenData.enterpriseUser ? null : "Phone"}

                    </Col>
                </CardHeader>
                <CardBody>
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>

                            <input type="hidden" name="class_id" value={props.class_id} />
                            {
                               // tStore.specificStudentLoader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {
                                props.loader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {props.data ? props.data.map(dt => (


                                <Row key={dt.user_id ? dt.user_id : dt.group_id}>
                                    <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                        {dt.user_id ? dt.first_name : dt.group_name} {dt.user_id ? dt.last_name : null}
                                    </Col>
                                    {
                                        !tokenData.enterpriseUser ?
                                            <Col md={5} sm={5} style={{ paddingLeft: '20px' }}>
                                                {`${dt.user_id ? dt.mobile : null}`}
                                            </Col> : null
                                    }

                                    <Col md={2} sm={2} >
                                        <FormGroup>
                                            <div>
                                                <input type="checkbox" id="exampleCustomCheckbox"
                                                    name={dt.user_id ? "registered_student[]" : "group_assign_id[]"}
                                                    defaultChecked={dt.selected}
                                                    // checked={selectedStudents.includes(dt.user_id) ? true : false}

                                                    // onChange={() => handleChange(dt.user_id)}

                                                    // value={dt.user_id ? dt.user_id : dt.group_id}



                                                    // checked={selectedStudents.includes(dt.user_id ? dt.user_id : dt.group_id) ? true : false}
                                                    onChange={() => handleChange(dt.user_id ? dt.user_id : dt.group_id)}
                                                    value={dt.user_id ? dt.user_id : dt.group_id}
                                                />

                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            )) : null}

                        </PerfectScrollbar>
                    </div>
                </CardBody>
                <CardFooter className="d-block text-right">
                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        loading={tStore.assignStudentLoader}
                        // style={{ width: '-webkit-fill-available' }}
                        // onClick={handleClick}
                       // type="button"
                        data-style={ZOOM_IN}
                        type="submit"
                    >
                        Save
                                            </LaddaButton>
                  
                </CardFooter>
            </Card>
            </Form>                   
        </div>
    )
}

export default GroupList
