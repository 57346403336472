import React, { useEffect, useState } from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { getStudyMaterialData } from '../../../redux/Actions/homeTeacher'
import { useDispatch } from 'react-redux'
import ReactHtmlParser from 'react-html-parser';
// import HeaderTitle from '../../HeaderTitle/HomeTitle'
import PageTitle from '../../HeaderTitle/PageTitle'

function Index() {

    const dispatch = useDispatch()
    const params = useParams()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [Data, setData] = useState(null)

    useEffect(() => {
        dispatch(getStudyMaterialData(tokenData.token, params.study_material_id, params.class_id, cb))
    }, [])

    const cb = (data) => {
        setData(data)
    }

    return (
        <div>
            {Data &&
                <>
                    <PageTitle
                        heading={Data.Title}
                        subheading={Data.Created_on}
                        icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                    />
                    <Card>
                        <CardHeader style={{textAlign:'center'}} >Note</CardHeader>
                        <CardBody>{
                            Data &&
                            <div style={{ maxHeight: '500px', overflowY: 'auto' }} >
                                {
                                    ReactHtmlParser(Data.Description)
                                }
                            </div>
                        }</CardBody>
                    </Card>
                </>
            }
        </div>
    )
}

export default Index
