import React, { useState, useEffect, Fragment } from 'react';
import image from "../LandingPageNew/images/signup-img.png"
import "../LandingPageNew/css/genex-custom.css"
import "../LandingPageNew/css/custome.css"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import {
    // requestOtp,
    validatingOtp,
    verifyEMAIL,
    studentSignup,
    // checkContactNumberExistSkillablers,
    // sentEmailSkillablers,
    requestOtp,
    checkContactNumberExist
} from '../../redux/Actions/signUp'
import Loader from 'react-loader'
import LaddaButton, { L, ZOOM_IN } from 'react-ladda';
import { useDispatch, useSelector } from 'react-redux';
import {
    emailValidator,
    passwordValidator,
    cpasswordValidator,
    nameValidator,
    mobileValidator,
} from '../../utilities/Validator'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getAllCountryStdcode } from "../../redux/Actions/genexhome"
var option = {
    lines: 13,
    length: 4,
    width: 2,
    radius: 5,
    scale: 1.00,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
};


function Signup() {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {
        if (tokenData) {
            history.push('/dashboard')
        }
        window.scrollTo(0, 0)
        // dispatch(getAllStates())
        dispatch(getAllCountryStdcode())
        return () => {
            // setInputField(initialState)
            dispatch({ type: 'RESET_SIGNUP_STORE' })
        }
    }, [])

    const Grades = useSelector(({ signUp }) => signUp)
    const otpvalid = useSelector((state) => state.signUp.otpvalid ? state.signUp.otpvalid : false);
    const otp_valid_message = useSelector((state) => state.signUp.otp_valid_message ? state.signUp.otp_valid_message : false);

    const email_valid = useSelector((state) => state.signUp.emailValidation ? state.signUp.emailValidation.result ? state.signUp.emailValidation.result : false : false);
    const country_codes = useSelector((state) => state.genexhome.country_code ? state.genexhome.country_code : []);


    const [errorz, setErrorz] = useState({});
    const dispatch = useDispatch();
    const history = useHistory()
    const [code, setCode] = useState('91');
    const [emaildisable, setEmaildisable] = useState(false);
    const [focus, setFocus] = useState(false);

    const [passwordVlidationErr, setPassErr] = useState('');
    const [passCheck, setPassCheck] = useState();

    const [err, setErr] = useState({
        open_err: false,
        err_list: []
    })



    const [inputField, setInputField] = useState({
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        mobile: '',
        address: '',
        pin: '',
        date: '',
        panchayath_id: '',
        genex_referral_code: ''
    });
    const [passcheckonchange, setPassCheckonchange] = useState('')

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value })
        if (name === 'email' && value) {
            dispatch(verifyEMAIL(e.target.value))
        }

        if (name === 'password' && passCheck) {
            if (value !== passCheck) {
                setPassCheckonchange('*password not matching')
            }
            else {
                setPassCheckonchange(null)
            }
        }
    }



    const confirmPass = (e) => {
        const { value } = e.target;
        setPassCheck(value)
        if (value !== inputField.password) {
            setPassCheckonchange('*password not matching')
        }
        else {
            setPassCheckonchange(null)
        }
    }

    const verifyMobile = (e) => {
        e.preventDefault()

        if (ForeignUser) {
            if (email_valid && inputField.email) {
                if (inputField.first_name) {
                    dispatch(checkContactNumberExist(inputField.mobile, 'email', sendOtp, code, inputField.first_name))
                    // dispatch(sentEmailSkillablers(inputField.email))
                }
                else {
                    Swal.fire('Warning', 'please enter your first name', 'warning')
                }
            } else {
                if (inputField.email) {
                    Swal.fire('Warning', 'e-mail already taken', 'warning')
                }
                else {
                    Swal.fire('Warning', 'please enter your e-mail', 'warning')
                }
            }
        } else {
            // dispatch(checkContactNumberExistSkillablers(inputField.mobile, sendOtp))
            dispatch(checkContactNumberExist(inputField.mobile, 'mobile', sendOtp, code, ''))

        }
    }
    const sendOtp = (type, codeval, first_name) => {
        dispatch(requestOtp(inputField.mobile, type, codeval, first_name, inputField.email))
    }

    const validateOtp = (e) => {
        // e.preventDefault()
        if (ForeignUser) {
            if (inputField.email) {
                dispatch(validatingOtp(inputField.mobile, otp, ForeignUser, inputField.email, callback2))
            }
            else {
                Swal.fire('Warning', 'please enter your e-mail', 'warning')
            }
        }
        else {
            dispatch(validatingOtp(inputField.mobile, otp, ForeignUser, inputField.email, callback2))
        }
    }
    const callback2 = () => {
        /////to disable email field when email varification become success
        setEmaildisable(true)
        setErrorz({ ...errorz, mobileValidated: true })
    }

    const [otp, setOtp] = useState('')
    const handleChangeOTP = (e) => {
        setOtp(e.target.value)
    }


    const formSubmit = (e) => {
        e.preventDefault()
        setPassErr('')
        setErrorz({})
        const errors = {
            first_name: nameValidator(inputField.first_name),
            last_name: nameValidator(inputField.last_name),
            email: emailValidator(inputField.email),
            password: passwordValidator(inputField.password),
            cCheckpass: cpasswordValidator(inputField.password, passCheck),
            // address: nameValidator(inputField.address),
            cpassword: nameValidator(passCheck),
            mobile: mobileValidator(inputField.mobile),
            mobileValidated: Grades.valid,

        }
        if (
            !errors.first_name &&
            !errors.last_name &&
            !errors.email &&
            !errors.password &&
            // !errors.address &&
            !errors.cCheckpass &&
            !errors.mobile &&
            Grades.emailValidation.result &&
            Grades.valid &&
            Grades.studentNumberAvail
        ) {
            let check = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])[A-Za-z\d@#$%^&+=]{8,}$/
            if (inputField.password.length < 8 || inputField.password.length > 20) {
                setPassErr('Password length should be minimum 8 and maximum 20')
            }
            else if (!check.test(inputField.password)) {
                setPassErr('Password should contain at least 1 uppercase, 1 lowercase, any 1 special character from @#$%^&+= and at least 1 digit')
            }
            else {
                setPassErr('')
                dispatch(studentSignup(
                    inputField.first_name,
                    inputField.last_name,
                    inputField.email,
                    inputField.password,
                    inputField.mobile,
                    code,
                    inputField.address ? inputField.address : null,
                    ForeignUser ? 'email' : 'mobile',
                    cb,
                    cb2
                ))
            }

        } else {
            setErrorz(errors)
        }
    }

    const cb = () => {
        history.push('/dashboard')
    }

    const cb2 = (val) => {
        setErr({
            open_err: true,
            err_list: val
        })
    }

    const closeErr = () => {
        setErr({
            open_err: false,
            err_list: []
        })
    }


    const [ForeignUser, setForeignUser] = useState(false)

    // $('#code-input option:selected').html($('#code-input option:selected').attr('value')); // already changed onload

    // $('#code-input').on('change mouseleave', function () {
    //     $('#code-input option').each(function () {
    //         $(this).html($(this).attr('data-text'));
    //     });
    //     $('#code-input option:selected').html($('#code-input option:selected').attr('value'));
    //     $(this).blur();
    // });
    // $('#code-input').on('focus', function () {
    //     $('#code-input option').each(function () {
    //         $(this).html($(this).attr('data-text'));
    //     });
    // });

    const handleCodeChange = (e) => {
        const { value } = e.target;
        // console.log('vvaaalluuueeee',value)
        // let val=country_codes.find((val)=>val.)
        setCode(value)
        if (value !== "91") {
            setForeignUser(true)
        } else {
            setForeignUser(false)
        }
    }

    const newstyle = { marginTop: '15px' }
    return (

        <Fragment>

            <section class="padding-top-60px padding-bottom-60px" style={{ backgroundColor: '#fff' }}>
                <div class="container">

                    <div class="row" data-wow-delay="0.2s">
                        <div class="col-md-5 col-lg-5 col-12">
                            <img src={image} alt="" class="img-fluid wow fadeInUp" data-wow-delay="0.2s" />
                        </div>
                        <div class="col-md-6 col-lg-6 col-12">
                            <div class="col-md-12 col-lg-12 col-12 pl-0">
                                <div class="margin-bottom-25px wow fadeInUp" >
                                    <h3 style={{ color: '#212529' }} class="font-weight-700 text-title-large wow fadeInUp" data-wow-delay="0.2s"><span class="fw-200">Student </span> Sign Up</h3>
                                </div>
                            </div>
                            <div class="row wow fadeInUp" data-wow-delay="0.2s" >
                                <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-user"></i></span>
                                        </div>
                                        <input type="text"
                                            class="form-control gn-form-control"
                                            aria-label="Small"
                                            placeholder="First Name"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name='first_name'
                                            value={inputField.first_name}
                                            onChange={handleChange}
                                            autocomplete="off"
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.first_name}</span>
                                </div>

                                <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-user"></i></span>
                                        </div>
                                        <input type="text"
                                            class="form-control gn-form-control"
                                            aria-label="Small"
                                            placeholder="Last Name"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name='last_name'
                                            value={inputField.last_name}
                                            onChange={handleChange}
                                            autocomplete="off"
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.last_name}</span>
                                </div>
                                {/* <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-calendar"></i></span>
                                        </div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                disableFuture
                                                openTo="year"
                                                format="dd/MM/yyyy"
                                                label="Date of birth"
                                                views={["year", "month", "date"]}
                                                value={selectedDate}
                                                onChange={date => handleChangeFormDate(date)}
                                            // minDate={new Date('2021-02-10')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.date}</span>
                                </div> */}
                                <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-envelope"></i></span>
                                        </div>
                                        <input type="email"
                                            class="form-control gn-form-control"
                                            aria-label="Small"
                                            placeholder="Email"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name='email'
                                            value={inputField.email}
                                            onChange={(e) => handleChange(e)}
                                            autocomplete="off"
                                            disabled={ForeignUser && emaildisable}
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.email}</span>
                                    {
                                        Grades.emailValidation.result === false && inputField.email ?
                                            <span style={{ color: 'black' }}>{`oops! ${Grades.emailValidation.message}`}</span>
                                            : null
                                    }
                                </div>

                                <div class="col-md-4 col-lg-4 col-4">

                                    <select
                                        onChange={handleCodeChange}
                                        type="select"
                                        name="tel-code"
                                        id="tel-code"
                                        value={code}
                                        onFocus={() => { setFocus(true) }}
                                        onBlur={() => { setFocus(false) }}
                                        // onMouseEnter={()=>{setFocus(true)}}
                                        // onMouseLeave={()=>{setFocus(false)}}
                                        style={{ width: '100%', height: '50px', marginTop: '17px', backgroundColor: 'white', border: '1px solid #dfe4ea', boxShadow: '0 2px 7px -1px rgb(0 0 0 / 9%)' }}
                                        disabled={Grades.valid ? true : false}>

                                        {
                                            country_codes.length > 0 ? country_codes.map((code, ky) => {
                                                return (
                                                    <option key={ky}
                                                        data-text={code.label}
                                                        value={code.value}>{focus ? code.label : code.value}</option>
                                                )
                                            }
                                            ) : ''
                                        }
                                    </select>

                                </div>
                                <div class="col-md-8 col-lg-8 col-8" style={{ marginTop: '15px', paddingLeft: '0px' }}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-mobile"></i></span>
                                        </div>
                                        <input
                                            type="number"
                                            class="form-control gn-form-control"
                                            style={{ height: '54px' }}
                                            aria-label="Small" placeholder="Contact Number"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name='mobile'
                                            value={inputField.mobile}
                                            onChange={handleChange}
                                            disabled={Grades.valid ? true : false}
                                            autocomplete="off"
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>
                                        {Grades.studentNumberAvail === false ?
                                            Grades.studentNumberAvailMessage : null
                                        }
                                        {errorz.mobile ? errorz.mobile : null}
                                        {!errorz.mobile ? errorz.mobileValidated === null || errorz.mobileValidated === false ? 'please validate your mobile' : null : null}
                                    </span>
                                    <span style={Grades.otp ? { color: 'green' } : { color: 'red' }}>
                                        {
                                            Grades.otp_message
                                        }
                                    </span>
                                </div>
                                <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="row mb-3">
                                        <div class="col-md-4 col-lg-4 col-4">


                                            <button
                                                class="form-input-btn btn-block"
                                                onClick={verifyMobile}
                                                disabled={Grades.valid || (inputField.mobile.length <= 9 && !ForeignUser) ? true : false || !inputField.mobile}

                                            >
                                                <span style={Grades.validateOtpLoader ? { visibility: 'hidden' } : null}>
                                                    {Grades.studentNumberAvail ? 'Resend' : `${ForeignUser ? "Verify e-mail" : "Verify Number"}`}
                                                </span>
                                                <span>
                                                    {Grades.validateOtpLoader ? <Loader options={option} /> : null}
                                                </span>
                                            </button>
                                        </div>

                                        {
                                            Grades.studentNumberAvail && Grades.otp ?
                                                <>
                                                    <div class="col-md-4 col-lg-4 col-4 pl-0">
                                                        <input type="number"
                                                            class="form-control gn-form-control"
                                                            style={{ borderLeft: '1px solid #dfe4ea !important', borderRadius: '4px', height: '50px' }}
                                                            placeholder="Enter OTP"
                                                            name="otp"
                                                            onChange={handleChangeOTP}
                                                            readOnly={Grades.valid ? true : false}
                                                            valid={Grades.valid ? true : false}
                                                        />
                                                    </div>
                                                    <div class="col-md-4 col-lg-4 col-4 pl-0">

                                                        <button
                                                            class="form-input-btn btn-block"
                                                            // style={{color:'white'}}
                                                            onClick={() => validateOtp()}
                                                            disabled={!otp || Grades.validateOtpValidateLoader || Grades.valid ? true : false}
                                                        >
                                                            <span style={Grades.validateOtpValidateLoader ? { visibility: 'hidden' } : null}>
                                                                Validate
                                                            </span>
                                                            {Grades.validateOtpValidateLoader ? <Loader options={option} /> : null}
                                                        </button>
                                                    </div>
                                                </>
                                                :
                                                ''
                                        }
                                        {
                                            Grades.studentNumberAvail ?
                                                <span style={{ color: 'red', marginLeft: '15px' }}>
                                                    {Grades.valid === false ? otp_valid_message : errorz.mobileValidated === null ? 'oops! Please validate OTP' : null}
                                                </span>
                                                : ''
                                        }
                                        {
                                            otpvalid ?
                                                <span style={{ color: 'green' }}>{otp_valid_message}</span> : ""
                                        }
                                    </div>
                                </div>
                                {/* <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="address"><i class="fa fa-map"></i></span>
                                        </div>
                                        <textarea
                                            id="address"
                                            cols="30"
                                            rows="2"
                                            placeholder="Address"
                                            class="form-control gn-form-control"
                                            name='address'
                                            onChange={handleChange}
                                            value={inputField.address}
                                            autocomplete="off"
                                        ></textarea>
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.address}</span>

                                </div> */}


                                <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-lock"></i></span>
                                        </div>
                                        <input type="password"
                                            class="form-control gn-form-control"
                                            aria-label="Small"
                                            placeholder="Password"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name="password"
                                            id="examplePassword"
                                            onChange={(e) => handleChange(e)}
                                            autocomplete="off"
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.password || passwordVlidationErr}</span>
                                </div>
                                <div class="col-md-12 col-lg-12 col-12" style={newstyle}>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend form-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-sm"><i class="fa fa-lock"></i></span>
                                        </div>
                                        <input type="text"
                                            class="form-control gn-form-control"
                                            aria-label="Small"
                                            placeholder="Confirm Password"
                                            aria-describedby="inputGroup-sizing-sm"
                                            name="cpassword"
                                            onChange={(e) => confirmPass(e)}
                                            autocomplete="off"
                                        />
                                    </div>
                                    <span style={{ color: 'red' }}>{errorz.cCheckpass}</span>
                                    {
                                        errorz.cCheckpass ?
                                            ''
                                            :
                                            <span style={{ color: 'red' }}>{passcheckonchange}</span>
                                    }
                                </div>



                                <div class="col-md-12 col-lg-12 col-12 text-md-right text-center" style={newstyle}>
                                    <LaddaButton className="form-input-btn"
                                        loading={Grades.loader}
                                        type="submit"
                                        onClick={formSubmit}
                                        data-style={ZOOM_IN}
                                        style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}
                                        data-size={L}

                                    >
                                        Sign Up
                                    </LaddaButton>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section>


            <Modal isOpen={false} >
                <ModalHeader >Modal title</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                        <div>An otp has been sent to your mobile number<span style={{ margin: '4px', fontWeight: 'bold' }}>7025514611</span> </div>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }} > OTP: <Input></Input> <Button className='ml-2'>Verify</Button></div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" >Sign Up</Button>{' '}
                </ModalFooter>
            </Modal>

            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                        {
                            err.err_list.map((error, ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)
                        }
                    </ul>

                }
            </Modal>
        </Fragment>
    )
}
export default Signup
