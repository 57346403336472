import React,{useEffect,Fragment} from 'react';
import banner from "../images/sub-banner.jpeg"
import subscriptionimage from "../images/subscription_image.png"


import { useHistory } from "react-router-dom";
import { listSubscriptionPaginated,setHomeData} from "../../../redux/Actions/genexhome"
import { useDispatch,useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';

import nodata from "../images/nodata.png"
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactHtmlParser from 'react-html-parser';

function SubscriptionList(){

    const dispatch = useDispatch();
    const history = useHistory();

	const Subscription_Details = useSelector((state) =>state.genexhome.Subscription_Details ? state.genexhome.Subscription_Details : "");
    const Subscription_Array = useSelector((state) =>state.genexhome.Subscription_Array ? state.genexhome.Subscription_Array : []);
	const first_page_url = useSelector((state) =>state.genexhome.Subscription_first_page_url ? state.genexhome.Subscription_first_page_url :'');
	const next_page_url = useSelector((state) =>state.genexhome.Subscription_next_page_url ? state.genexhome.Subscription_next_page_url : '');
	const loading = useSelector((state) =>state.genexhome.subloader ? state.genexhome.subloader : false);
	

	const page = useSelector((state) =>state.genexhome.subscription_page ? state.genexhome.subscription_page :1);
	const searchterm = useSelector((state) =>state.genexhome.search_term_subscription ? state.genexhome.search_term_subscription :'');

	useEffect(()=>{
        dispatch(listSubscriptionPaginated(searchterm,page))
	},[page])


    const NextPage=()=>{
        dispatch(setHomeData('subscription_page',page+1))

    }
    const PreviousPage=()=>{
        dispatch(setHomeData('subscription_page',page-1))

    }

    const ViewCourse=(val)=>{
        history.push(`/subscription/${val.id}`)
    }

    const HandleSearch=(e)=>{
        if(e.target.value){
            // setSearchterm(e.target.value)
            dispatch(setHomeData('search_term_subscription',e.target.value))
        }
        else{
            // setSearchterm('')
            dispatch(setHomeData('search_term_subscription',''))
            if(page===1){
                dispatch(listSubscriptionPaginated(e.target.value,1))
            }
            else{
                // setPage(1)
                dispatch(setHomeData('subscription_page',1))
            }
        }
    }

    const SearchCate=(e)=>{
        e.preventDefault();
        if(searchterm){
            if(page===1){
                dispatch(listSubscriptionPaginated(searchterm,1))
            }
            else{
                // setPage(1)
                dispatch(setHomeData('subscription_page',1))
            }
        }
    }

  
    return(
   
        
        <Fragment>
   {
        Subscription_Details ? 
                
            <>
                    <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})`}}>
                        <div class="container">
                            <h2 class="text-center font-weight-600 banner-title">Our Subscriptions</h2>
                        </div>

                        <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <form onSubmit={SearchCate} class="search-form-main">
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                <div class="form-group">
                                                    <input type="search" value={searchterm} onChange={HandleSearch} class="form-control" id="" placeholder="What are you looking for" />
                                                </div>
                                            </div>
                                            <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                <div class="form-group">
                
                                                <input 
                                                    type="button" 
                                                    class="btn btn-block search-btn" 
                                                    onClick={SearchCate}
                                                    disabled={searchterm ? false:true}
                                                    value="Search" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>


                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                                <h2 class="text-center search-col-title">Find your best subscriptions</h2>
                            </div>

                        </div>

                        </section>


                        <section class="padding-top-120px padding-bottom-60px" style={{backgroundColor:'#FAFAFA'}}>
                            <div class="container">

                            {/* <div class="row justify-content-center">
                                <div class="col-md-9 col-lg-9 col-12 ">
                                    <div class="text-center  margin-bottom-35px wow fadeInUp" style={{visibility:'visible',animationName:'fadeInUp'}}>
                                        <h3  style={{color:'#212529'}} class="font-weight-700 text-title-large wow fadeInUp" data-wow-delay="0.2s"><span class="fw-200">Browse Our </span> Subscriptions</h3>
                                        <p class="font-15 wow fadeInUp" data-wow-delay="0.2s">Skillablers Virtual Edu Park is enriched with all leading universities and training institutes across the globe. This offers a first time opportunity for people of any age group to go for lifelong learning. Now enrol for courses of your choice any time from our unmatched treasure of knowledge.</p>
                                    </div>
                                </div>
                            </div> */}

                                <LoadingOverlay
                                    active={loading}
                                    spinner
                                    text='Loading your content...'
                                >
                                    <div class="row wow fadeInUp" data-wow-delay="0.2s">

                                        {
                                            Subscription_Array.length > 0 ?
                                            <>
                                                {
                                                    Subscription_Array.map((val,ky)=>{
                                                        return(
                                                            <div class="col-md-4 col-lg-3 col-sm-4 col-12 mb-4" key={ky} style={{cursor:'pointer'}} onClick={()=>{ViewCourse(val)}}>
                                                                <div class="blog-item thum-hover featured-institute-main">
                                                                    <div class="position-relative">
                                                                        <div class="item-thumbnail "><img src={subscriptionimage} alt="" width='100%' height='200px' /></div>
                                                                        <div class="cat-div">{val.subscription_type}</div>
                                                                    </div>
                                                                    <div class="padding-20px bgclrbtm-of-course">
                                                                        <div style={{height:'50px',overflow:'hidden',wordWrap:'break-word'}}>  
                                                                            <span class="f-i-title">{val.subscription_name}</span>
                                                                        </div>
                                                                        <div style={{height:'50px',overflow:'hidden',wordWrap:'break-word'}}>      
                                                                        {ReactHtmlParser(val.description)}
                                                                        </div>    
                                                                        <p class="mb-0"><span class="font-16 font-weight-500 text-blue">{val.subscription_fee} {val.currency_code}</span></p>
                                                                        <hr class="mt-1 mb-2" />
                                                                        <p class="mb-0">
                                                                            {/* {
                                                                                    val.certified === 1 ?
                                                                                    <span class="mr-1 course-badges badge badge-success">Certified</span>
                                                                                    :null
                                                                            } */}
                                                                            {
                                                                                val.days_left ?
                                                                                <span class="mr-1 course-badges badge badge-dark">{val.days_left}</span>
                                                                                :
                                                                                null
                                                                            }
                                                                            </p>
                                                                    </div>
                                                                </div>
                                                            {/* </a> */}
                                                        </div>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                                                <img src={nodata} width='600px' height='400px' alt='' />
                                            </div>
                                        }
                                
                                    </div>
                                </LoadingOverlay>

                                {
                                    Subscription_Array.length > 0 && (first_page_url || next_page_url) ? 
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                                            <ul class="pagination-list">
                                                {
                                                    first_page_url ? 
                                                    <li style={{cursor:'pointer'}}><a onClick={PreviousPage}>Prev</a></li>
                                                    :
                                                    <li><a style={{color:'#dddddd'}}>Prev</a></li>

                                                }
                                                {
                                                    next_page_url ? 
                                                    <li style={{cursor:'pointer'}}><a onClick={NextPage}>Next</a></li>
                                                    :
                                                    <li><a style={{color:'#dddddd'}}>Next</a></li>

                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    :''
                                }
                                

                            </div>
                            </section>


            </>  
            :
            <>
            <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                minHeight: "100vh",
            }}
            >
                <CircularProgress />
            </div>
                        
        </>
            }
                         
    </Fragment>
    )
}

export default SubscriptionList

 
