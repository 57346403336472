import React, { useEffect, Fragment } from 'react';
import banner from "../../images/inner-banner.jpg"
import coursedummy from "../../images/coursedummy.png"

import CircularProgress from "@material-ui/core/CircularProgress";

import { useHistory, Link } from "react-router-dom";

import { getCourseUnderTag, setHomeData } from "../../../../redux/Actions/genexhome"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay';
import nodata from "../../images/nodata.png"
import WarningIcon from '@material-ui/icons/Warning';
import Swal from 'sweetalert2'
import Tooltip from '@material-ui/core/Tooltip';

function SingleTag() {

    const dispatch = useDispatch();
    const params = useParams()
    const history = useHistory();

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const vendorname_label = { fontFamily: 'revert', color: 'rgb(0 0 0)' }
    const vendorname = { fontFamily: 'revert', color: 'rgb(20 73 144)', fontWeight: '600' }


    const Course_Details = useSelector((state) => state.genexhome.Single_Tag_Details ? state.genexhome.Single_Tag_Details : "");
    const Course_Array = useSelector((state) => state.genexhome.Single_Tag_Course_Array ? state.genexhome.Single_Tag_Course_Array : []);
    const first_page_url = useSelector((state) => state.genexhome.Single_Tag__course_first_page_url ? state.genexhome.Single_Tag__course_first_page_url : '');
    const next_page_url = useSelector((state) => state.genexhome.Single_Tag_course_next_page_url ? state.genexhome.Single_Tag_course_next_page_url : '');
    const loading = useSelector((state) => state.genexhome.tagcourseloading ? state.genexhome.tagcourseloading : false);


    const page = useSelector((state) => state.genexhome.tag_course_page ? state.genexhome.tag_course_page : 1);
    const serach = useSelector((state) => state.genexhome.tag_course_search ? state.genexhome.tag_course_search : '');
    const tag_name_in_course = useSelector((state) => state.genexhome.tag_name_in_course ? state.genexhome.tag_name_in_course : '');


    useEffect(() => {
        // dispatch(setHomeData('tag_course_search',''))
        if (page !== 1) {
            dispatch(setHomeData('tag_course_page', 1))
        }
        else {
            dispatch(getCourseUnderTag(params.category_id, 1, serach))
        }
    }, [params.category_id])

    useEffect(() => {
        dispatch(getCourseUnderTag(params.category_id, page, serach))
    }, [page])


    const NextPage = () => {
        dispatch(setHomeData('tag_course_page', page + 1))
    }
    const PreviousPage = () => {
        dispatch(setHomeData('tag_course_page', page - 1))
    }

    const ViewCourse = (val) => {
        if (val.edit_status === 1) {
            Swal.fire({
                icon: 'warning',
                title: 'Rescheduling!',
                text: 'Will be available soon',
            })
        } else {
            // history.push(`/categories/${params.category_id}/${val.course_id}`)
            history.push(`/view-course/${val.course_id}`)
        }
    }



    const SearchCate = (e) => {
        e.preventDefault();
        if (page === 1) {
            dispatch(getCourseUnderTag(params.category_id, page, serach))
        }
        else {
            dispatch(setHomeData('tag_course_page', 1))

        }
    }


    const HandleChange = (e) => {
        if (e.target.value) {
            dispatch(setHomeData('tag_course_search', e.target.value))
        }
        else {
            dispatch(setHomeData('tag_course_search', e.target.value))
            if (page === 1) {
                dispatch(getCourseUnderTag(params.category_id, 1, ''))
            }
            else {
                dispatch(setHomeData('tag_course_page', 1))
            }
        }
    }

    const reschedule = { backgroundColor: 'rgb(49 49 49 / 55%)', borderColor: 'white', position: 'absolute', top: '10px', left: '10px', color: 'white', padding: '0px 2px', borderRadius: '5px', boxSizing: 'border-box' }

    return (
        <Fragment>

            {
                Course_Details ?
                    <>
                        <LoadingOverlay
                            active={loading}
                            spinner
                            text='Loading your content...'
                        >
                            <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})` }}>
                                <div class="container">
                                    <h2 class="text-center font-weight-600 banner-title">{tag_name_in_course}</h2>
                                </div>

                                <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-12 col-12">
                                            <form onSubmit={SearchCate} class="search-form-main">
                                                <div class="row justify-content-center">
                                                    <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                        <div class="form-group">
                                                            <input type="search" value={serach} onChange={HandleChange} class="form-control" id="" placeholder="What are you looking for" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                        <div class="form-group">
                                                            <input
                                                                type="button"
                                                                class="btn btn-block search-btn"
                                                                onClick={SearchCate}
                                                                disabled={serach ? false : true}
                                                                value="Search" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>


                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                                        <h2 class="text-center search-col-title">Find your best courses</h2>
                                    </div>

                                </div>

                            </section>


                            <section class="padding-top-120px padding-bottom-60px" style={{ backgroundColor: '#fff' }}>
                                <div class="container">

                                    <div class="row wow fadeInUp" data-wow-delay="0.2s">

                                        {
                                            Course_Array.length > 0 ?
                                                <>
                                                    {
                                                        Course_Array.map((val, ky) => {
                                                            let vendor = val.vendor_name ? val.vendor_name : ''
                                                            let v_name
                                                            if (vendor.length > 15) {
                                                                let apend = vendor.slice(0, 14)
                                                                v_name = apend + '...'
                                                            }
                                                            else {
                                                                v_name = vendor
                                                            }
                                                            return (
                                                                <div class="col-md-4 col-lg-3 col-sm-6 col-12 mb-4" key={ky} style={{ cursor: 'pointer' }} >
                                                                    <div> </div>
                                                                    <div class="blog-item thum-hover featured-institute-main">
                                                                        <div onClick={() => { ViewCourse(val) }} class="position-relative">
                                                                            <div class="item-thumbnail "><img src={val.banner_url ? val.banner_url : coursedummy} alt="" width='100%' height='200px' /></div>
                                                                            <div class="cat-div">{val.course_category}</div>
                                                                            {
                                                                                val.edit_status === 1 ?
                                                                                    <div style={reschedule}><WarningIcon style={{ color: '#d8d81c', fontSize: '15px' }} /> Rescheduling</div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                        <div class="padding-20px bgclrbtm-of-course">
                                                                            <div onClick={() => { ViewCourse(val) }} >
                                                                                <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                                                                    <span class="f-i-title">{val.course_name}</span>
                                                                                </div>
                                                                                <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                                                                    <p class="font-14 mb-1">{val.course_subject_name}</p>
                                                                                </div>
                                                                                <p class="mb-0"><span class="font-16 font-weight-500 text-blue">{val.course_amount}</span>
                                                                                </p>
                                                                            </div>
                                                                            <hr class="mt-1 mb-1" />

                                                                            <p class="mb-0 ">
                                                                                {
                                                                                    val.certified === 1 ?
                                                                                        <span class="mr-1 course-badges badge badge-success">Certified</span>
                                                                                        : null
                                                                                }
                                                                                {
                                                                                    val.days_left ?
                                                                                        <span class="mr-1 course-badges badge badge-dark">{val.days_left}</span>
                                                                                        : <span style={{ visibility: 'hidden' }} class="mr-1 course-badges badge badge-dark">0</span>


                                                                                }
                                                                            </p>
                                                                            {/* <Tooltip arrow title={val.vendor_name}>
                                                                                <span>
                                                                                    <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                                                                </span>
                                                                            </Tooltip> */}
                                                                            <Tooltip arrow title={val.vendor_name}>
                                                                                <Link to={{ pathname: `/vendor/${val.vendor_id}/${val.skl_user_id}` }} >
                                                                                    <span>
                                                                                        <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                                                                    </span>
                                                                                </Link>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <img src={nodata} width='600px' height='400px' alt='' />
                                                </div>
                                        }

                                    </div>

                                    {
                                        Course_Array.length > 0 && (first_page_url || next_page_url) ?
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                                                    <ul class="pagination-list">
                                                        {
                                                            first_page_url ?
                                                                <li style={{ cursor: 'pointer' }}><a onClick={PreviousPage}>Prev</a></li>
                                                                :
                                                                <li><a style={{ color: '#dddddd' }}>Prev</a></li>

                                                        }
                                                        {
                                                            next_page_url ?
                                                                <li style={{ cursor: 'pointer' }}><a onClick={NextPage}>Next</a></li>
                                                                :
                                                                <li><a style={{ color: '#dddddd' }}>Next</a></li>

                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            : ''
                                    }


                                </div>
                            </section>
                        </LoadingOverlay>

                    </>
                    :
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </div>


            }
        </Fragment>
    )
}

export default SingleTag