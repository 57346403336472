import React from 'react'
import MaterialTable from 'material-table'
// import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOverTwoTone';
// import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import DownloadIcon from '@material-ui/icons/GetApp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import CommentIcon from '@material-ui/icons/CommentTwoTone';
import BorderColorIcon from '@material-ui/icons/BorderColor';
// import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
// import PublishIcon from '@material-ui/icons/Publish';
// import OpenInNewIcon from '@material-ui/icons/OpenInNewTwoTone';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const dataTable = (props) => {


    return (
        // 


        <MaterialTable
            // components={{
            //     Toolbar: props => (
            //         <div style={{ backgroundColor: '#e8eaf5' }}>
            //             'dfdfd'
            //         </div>
            //     )
            // }}
            isLoading={props.loader}
            title="Assignments"
            columns={props.columns}
            data={props.data}
            options={{
                search: true,
                sorting: true,
                headerStyle: {
                    backgroundColor: '#01579b',
                    color: '#FFF'
                },
                actionsColumnIndex: -1,
                // fixedColumns: {
                //     left: 2,
                //     right: 2
                // }
                rowStyle: rowData =>
                    (
                        {
                            backgroundColor: (rowData.id % 2) ? '#e1e2e2' : '#f2f3f3'
                        }
                    )

            }}

            // editable={{
            //     onRowUpdate: (newData) =>
            //         new Promise((resolve) => {
            //             props.onEdit(newData)
            //             resolve()
            //         }
            //         )
            // }}
            actions={
                props.actions ?
                    props.actions === "student" ? [

                        {
                            icon: () => <VisibilityOutlinedIcon style={{ color: '#00458B' }} />,

                            tooltip: 'View Details',
                            onClick: (event, rowData) => props.handleClick(rowData)
                        },
                        {
                            icon: () => <DownloadIcon style={{ color: 'rgb(41, 183, 155)' }} />,

                            tooltip: 'Download Assignment',
                            onClick: (event, rowData) => props.downloadTeacherAssignment(rowData)
                            // onClick: (event, rowData) => console.log("Row Data *** "+rowData.assignment_id)
                        },
                        // {
                        //     icon: AssignmentReturnedIcon,
                        //     tooltip: 'Download My Assignment',
                        //     onClick: (event, rowData) => props.downloadMyAssignment(rowData)
                        // },
                        // {
                        //     icon: PublishIcon,
                        //     tooltip: 'Submit Assignment',
                        //     onClick: (event, rowData) => props.respondToAssignment(rowData)
                        // },

                        // {
                        //     icon: RecordVoiceOverIcon,
                        //     tooltip: 'Teacher Remarks',
                        //     onClick: (event, rowData) => props.getTeacherRemark(rowData)
                        // },
                        // {
                        //     icon: 'notepad',
                        //     tooltip: 'View Description ',
                        //     onClick: (event, rowData) => props.getDescription(rowData)
                        // }
                    ]

                        :
                        props.actions === "student2" ? [

                            {
                                icon: 'replay',
                                tooltip: props.role ? props.role === 'student' ? 'Submit Assignment' : props.role === 'student' ? 'Submit Assignment' : null : null,
                                onClick: (event, rowData) => props.respondToAssignment(rowData.assignment_id, rowData.title, rowData.description)
                            },

                        ]
                            :
                            props.actions === "dashboard" ? [
                                {
                                    icon: CloudDownloadIcon,
                                    tooltip: 'Download',

                                    onClick: (event, rowData) => props.downloadStudentAssignment(rowData)
                                },

                                {
                                    icon: BorderColorIcon,
                                    tooltip: 'Add Marks',
                                    onClick: (event, rowData) => props.addMarks(rowData)
                                },
                                {
                                    icon: CommentIcon,
                                    tooltip: 'Student Remarks',
                                    onClick: (event, rowData) => props.viewDiscription(rowData)
                                },
                            ]
                                :

                                props.actions === "teacher" ? [
                                    {
                                        icon: () => <VisibilityOutlinedIcon style={{ color: '#00458B' }} />,
                                        tooltip: 'View Details ',
                                        // iconProps: { style: { fontSize: "14px", color: "green" } },
                                        onClick: (event, rowData) => props.populateStudents(rowData)
                                    },
                                    {
                                        icon: () => <DownloadIcon style={{ color: 'rgb(41, 183, 155)' }} />,
                                        tooltip: 'Download your attachments',
                                        // onClick: (event, rowData) => console.log("Row Data *** "+rowData.class_id)
                                        onClick: (event, rowData) => props.downloadMyAssignment(rowData)
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit file ',
                                        onClick: (event, rowData) => props.editAssignment(rowData)
                                    },
                                    {
                                        icon: 'delete',
                                        tooltip: 'Delete',
                                        // onClick: (event, rowData) => console.log("Row Data *** "+rowData.class_id)
                                        onClick: (event, rowData) => props.deleteAssignment(rowData.assignment_id)
                                    }

                                ] :


                                    null
                    : null
            }
            onRowClick={(event, rowData) => props.handleClick(rowData)}

        />
    )
}

export default dataTable