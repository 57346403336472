import React, { useEffect,useState } from 'react';
// import img from "../../images/home_72.gif"
import bann from "../../../Components/LandingPageNew/images/banner-4.png"
import vendordummy from '../../../Components/LandingPageNew/images/vendordummy.png'

// import recent1 from "../../images/recent-course1.gif"
// import { useParams } from 'react-router-dom'
// import { useHistory } from "react-router-dom";

import { useDispatch,useSelector } from "react-redux";
import {getVendorPreviewDetails,getVendorPreviewDetailsForVendor} from "../../../redux/Actions/genexhome"
import CircularProgress from "@material-ui/core/CircularProgress";
// import { validate } from 'email-validator';
// import LoadingOverlay from 'react-loading-overlay';
import ReactHtmlParser from 'react-html-parser';
// import {Badge} from 'reactstrap'
// import {
//     Breadcrumb, BreadcrumbItem,Col,Row
// } from 'reactstrap';
// import {
//     faHome
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from 'react-router-dom'
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {useParams} from 'react-router-dom'


function VendorPreview(){

    const dispatch = useDispatch();
    const params = useParams();
    // const [page,setPage]=useState(1)

   

    const Single_Vendor_Details = useSelector((state) =>state.genexhome.Single_Vendor_Details_Preview ? state.genexhome.Single_Vendor_Details_Preview : "");
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const brochure_url = useSelector((state) =>state.genexhome.Single_Vendor_Details_Preview ? state.genexhome.Single_Vendor_Details_Preview.brochure_url : "#");

    const header_baner=useSelector((state) =>state.genexhome.Single_Vendor_Details_Preview ? state.genexhome.Single_Vendor_Details_Preview ? state.genexhome.Single_Vendor_Details_Preview.header_banner_url:bann:bann);


    useEffect(()=>{
       params.user_id ?
       dispatch(getVendorPreviewDetails(tokenData.token,params.user_id))
       : dispatch(getVendorPreviewDetailsForVendor(tokenData.token))
    },[])


    const Dwnload_Brochure=()=>{
        window.open(brochure_url,"_blank")
    }

    const [show,setShow]=useState(true)

    const ShowImage=()=>{
        setShow((prev)=>!prev)
    }

    const imgstyle={display:'none',marginBottom:'10px'}
    return(
        // <>
    <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <section class="home-page">
            {
                Single_Vendor_Details ? 
                <>
              
              <section class="course-banner position-relative" style={{backgroundImage:`url(${header_baner})`}}>
                <div class="container">
                    <div class="banner-institute-logo">
                        <img src={Single_Vendor_Details.vendor_logo_url ? Single_Vendor_Details.vendor_logo_url:vendordummy} width='100%' height='100%' alt="" />
                    </div>
                </div>
            </section>
            
            <section class="padding-top-120px padding-bottom-60px" style={{backgroundColor:'white'}}>
                <div class="container">

                    <div class="row">
                        <div class="col-md-9 col-lg-9 col-12 ">
                            <div class="wow fadeInUp" style={{visibility:'visible',animationName:'fadeInUp'}}>
                                <h3 style={{color:'#212529'}} class="font-weight-700 text-title-medium mb-0 wow fadeInUp" data-wow-delay="0.2s">{Single_Vendor_Details.vendor_name}</h3>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3 col-12 text-lg-right text-md-right text-left">
                        {
                            brochure_url==='#' ? 
                            '':
                            <span style={{color:'white',cursor:'pointer'}} onClick={Dwnload_Brochure} class="btn btn-download">Download E-Brochure <i class="fa fa-download ml-1"></i></span>

                        }
                        </div>
                        <div class="col-md-12 col-lg-12 col-12">
                            <hr /></div>

                        <div class="col-md-12 col-lg-12 col-12">
                            <h4 style={{color:'#212529'}}>WHO WE ARE</h4>
                            <p class="font-15">{ReactHtmlParser(Single_Vendor_Details.vendor_description)}</p>
                            {
                                Single_Vendor_Details.site_image_url ? 
                                <div class="row mt-3">
                                    <div id="show-content" class="col-md-12 col-lg-12 col-12" style={show ? imgstyle:{}}>
                                        <img src={Single_Vendor_Details.site_image_url} alt="" class="img-fluid" />
                                    </div>
                                    <div class="col-md-12 col-lg-12 col-12" id="expander">
                                        <a class="btn btn-gn border-radius-30 wow fadeInUp" data-wow-delay="0.2s" style={{marginTop:'10px',cursor:'pointer'}} onClick={ShowImage}>{show ? 'Show More' :'Hide'}</a>
                                    </div>
                                </div>
                                :''
                            }
                        
                        </div>
                    </div>
                    </div>
                    </section>
                </>
                :
                <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "100vh",
                }}
            >
					<CircularProgress />
				</div>
            }
            
        </section>
        
</CSSTransitionGroup>
    )
}
export default VendorPreview

