
import Swal from "sweetalert2";
import axios from "axios";
import { development} from "../../config.json"

export const exportSalesUnderSchool = (token,from_date,to_date,school_id,student_id,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data={from_date:from_date,to_date:to_date,school_id:school_id,student_id:student_id}
    dispatch({ type: 'SALES_ALL_REQUEST', payload: { saleinvoicesloader: true } })
    axios.post(`${development.baseUrl}exportSalesUnderSchool`,data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SALES_ALL_SUCCESS', payload: { saleinvoicesloader: false } })
                cb(res.data.url)
            }
        })
        .catch(err => {
            dispatch({ type: 'SALES_ALL_FAILED', payload: { saleinvoicesloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: 'You are logged in to another System'
                })
            }
        })
}

export const listSalesUnderSchool = (token,from_date,to_date,school_id,student_id,cb) => (dispatch) => {
  const head = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
  }
  let data={from_date:from_date,to_date:to_date}
  dispatch({ type: 'SALES_ALL_REQUEST', payload: { salesloader: true } })
  axios.post(`${development.baseUrl}listSalesUnderSchool`,data, { headers: head })
      .then(res => {
          if (res.data.result) {
              dispatch({ type: 'SALES_ALL_SUCCESS', payload: { sales: res.data, salesloader: false } })
              cb(res.data.sales)
          }
      })
      .catch(err => {
          dispatch({ type: 'SALES_ALL_FAILED', payload: { salesloader: false } })
          if (err) {
              if (err.response) {
                  if (err.response) {
                      if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Token Expired',
                            // text: 'Token Expired'
                        })
                        window.location.reload()
                      }
                  }
              }
          }
          else {
              Swal.fire({
                  icon: 'error',
                  title: 'Something Went Wrong',
                  text: 'You are logged in to another System'
              })
          }
      })
}

export const schoolDashboard = (token,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
   // let data={ticket_id:ticket_id}
    //dispatch({ type: 'TICKET_ALL_REQUEST' })
   // axios.get(`${development.baseUrl}listAllResellerTransaction`,{ headers: head })
    axios.get(`${development.baseUrl}schoolDashboard`,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'SCHOOL_DASHBOARD_SUCCESS', payload:{dashboard_data:res.data} })
                cb(res.data)
            }
        })
        .catch(err => {
          //  dispatch({ type: 'TICKET_ALL_FAILED'})
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else{
                    Swal.fire({
                        icon:'error',
                        title:'Something Went Wrong',
                        //text:'You are logged in to another System'
                        text:err
                    })
                }
            }
        })
    }


