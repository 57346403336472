import React from 'react'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                loader={props.loader}
                // hadleOpenModal={(id) => props.hadleOpenModal(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns} handleEdit
                Ticketdetails={(id)=>{props.Ticketdetails(id)}}
                Changestatus={(id)=>{props.Statuschange(id)}}
                data={props.data}
                title={props.title}

            />
        </>
    )
}

export default Table
