import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  
  Badge,
 
} from "reactstrap";
import { useParams } from 'react-router-dom'
// import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TimeShedule from './viewTime'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import AssignStudents from './addStudentToClass'
// import Modals from '../../common/Modal'
import Table from "../table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { getAllResellerStudent } from '../../../../redux/Actions/Superadmin'
import { useHistory } from "react-router-dom";
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import {
    faHome

} from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

const Viewstudent = () => {
  var columns;

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams()
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  useEffect(() => {


    dispatch(getAllResellerStudent(tokenData.token, params.std_id))
   
}, [])

  columns = [
    { title: "Student", field: "student" },
    { title: "Course", field: "course" },
    { title: "Partner", field: "vendor" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return rowData.status === "Active" ? (
          <Badge color="success" pill>
            {rowData.status}
          </Badge>
        ) : (
          <Badge color="danger" pill>
            {rowData.status}
          </Badge>
        );
      },
    },
    { title: "Payment_Status", field: "feestatus",
    render: (rowData) => {
        return rowData.feestatus === "Completed" ? (
          <Badge color="success" pill>
            {rowData.feestatus}
          </Badge>
        ) : (
          <Badge color="danger" pill>
            {rowData.feestatus}
          </Badge>
        );
      }, 
    },

  ];
  const data = [
    {
      student:'student1',
      course: "course1",
      vendor: "vendor1",
      status: "Active",
      feestatus:'Completed'
    },
    {
        student:'student2',
        course: "course1",
        vendor: "vendor1",
        status: "Active",
        feestatus:'Completed'
      },
      {
        student:'student3',
        course: "course1",
        vendor: "vendor1",
        status: "Active",
        feestatus:'Due'
      },
  ];

  //   const action = "student_table"

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="/dashboard/reseller">Reseller List</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Student Details</BreadcrumbItem>
      </Breadcrumb>
      <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white",display:'flex',alignItems:'center' }}>
       
        <div
            className={cx("page-title-icon")}>
            <i className='pe-7s-study icon-gradient bg-sunny-morning' />
        </div>
        <div>
            <span style={{ textTransform: 'uppercase' }}>Reseller Name</span> <br/>
            <span style={{}}>Active</span>
        </div>
    </div>
      <Row>
        <Col sm={12} md={12}>
          <Table
            // actions={action}
            data={data}
            title={"Student List"}
            columns={columns}
            // DetailsofStudent={Studentview}
          />
        </Col>
      </Row>
    </>
  );
};

export default Viewstudent;
