import React, { useState, useEffect } from 'react'
import { updateRenewSubscription, viewSubscriptionDetails, setSubscriptionData, renewSubscription } from "../../../redux/Actions/subscription-action"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import subscriptionimage from "../../LandingPageNew/images/subscription_image.png"
import { Modal } from 'reactstrap'
import {
    Row,
    Col,
    // Label,Input,
    Card,
    // CardHeader,
    Button,
    // ListGroup,
    Alert,

} from "reactstrap";
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';
import Index from "./SubscriptionClassDetails"
import WarningIcon from '@material-ui/icons/Warning';
import LaddaButton, { ZOOM_IN } from "react-ladda";
import Terms from '../../LandingPageNew/SkillablersPayment/index'
import Swal from 'sweetalert2'
import { authCheck, logout } from '../../../redux/Actions/auth'
import axios from 'axios'
import logo from "../../LandingPageNew/images/logo.png"
import { development, razorpaykey } from '../../../config.json'

import animationData from '../../Dashboard/animation/check-circle.json'
import Lottie from 'react-lottie';
import errorAnimation from '../../Dashboard/animation/error.json'
import Modals from '../../common/Modal'
import LoadingOverlay from 'react-loading-overlay';
import PageTitle from "../../HeaderTitle/PageTitle";
import RenewalHistory from "./RenewalHistory"
import expired_sub from '../../../assets/images/expired.png'
import { LogoutSession } from "../../../redux/Actions/genexhome";

function SubscriptionDetails() {

    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const role = tokenData.role ? tokenData.role : ''
    const [load_renewal, setLoader] = useState(false)
    const [successModal, setSuccessModal] = useState({ open: false })
    const [failModal, setFailModal] = useState({ fail: false, msg: '' })

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successModal.open ? animationData : failModal ? errorAnimation : null,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const loader = useSelector((state) => state.Subscription.subscription_details_loader ? state.Subscription.subscription_details_loader : false);
    const Subscription_Data = useSelector((state) => state.Subscription.Subscription_Data ? state.Subscription.Subscription_Data : []);
    const Subscription_Live = useSelector((state) => state.Subscription.Subscription_Live ? state.Subscription.Subscription_Live : []);
    const Subscription_Video = useSelector((state) => state.Subscription.Subscription_Video ? state.Subscription.Subscription_Video : []);
    const pay_loader = useSelector((state) => state.Subscription.pay_loader ? state.Subscription.pay_loader : false);

    useEffect(() => {
        dispatch(authCheck(tokenData.token, cb, authSuccess))

        return () => {
            dispatch(setSubscriptionData('Subscription_Data', []))
            dispatch(setSubscriptionData('subscription_details_loader', false))
            dispatch(setSubscriptionData('renewalList_Data', []))
        }
    }, [])
    const handleCloseModal = () => {
        setOrderData(null)
        // setTermsAndConditions(false)
    }
    const authSuccess = () => {
        dispatch(viewSubscriptionDetails(tokenData.token, params.subscription_id))
    }
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }
console.log("subscription data",Subscription_Data)
    const Renew = (id) => {
        console.log("logging console", id)
        setLoader(true)
        let data = {
            order_id: id
        }
        dispatch(renewSubscription(tokenData.token, data, success))
    }

    const [OrderData, setOrderData] = useState()

    const success = (data) => {
        setOrderData(data)
        console.log("data log", data)
        //  setTermsAndConditions(true)
        setLoader(false)
    }

    const cb = () => {

        dispatch(LogoutSession(tokenData.token))
        localStorage.removeItem("authDetails");
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
        history.push('/login')
    }

    const paymentStatus = (res) => {

        if (res.result) {
            dispatch(viewSubscriptionDetails(tokenData.token, params.subscription_id))
            setSuccessModal({ open: true, res })
        } else {
            setFailModal({ fail: true, msg: res.message })
        }
    }

    const closeSuccessModal = () => {
        dispatch({ type: 'PAYMENT_SUCCESSFULLY_COMPLETED' })
        setSuccessModal({ ...successModal, open: false })
    }

    const leftmargin = { marginTop: '15px' }
    const valstyle = { fontSize: '16px', marginTop: '15px' }
    return (
        <div>
            {
                loader ?
                    <>
                        <Card style={{ padding: '30px' }}>
                            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                <Skeleton height={30} count={5} />
                            </SkeletonTheme>
                        </Card>
                        <Card style={{ padding: '30px' }}>
                            <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                <Skeleton height={30} count={5} />
                            </SkeletonTheme>
                        </Card>
                    </>
                    :
                    <>
                        <LoadingOverlay
                            active={pay_loader}
                            spinner
                            text='Loading...'
                        >
                            <PageTitle
                                // style={{ display: "none" }}
                                heading="Subscription Details"
                                subheading="Detail page of an subscription"
                                icon="lnr-license icon-gradient bg-happy-itmeo"
                            />
                            {
                                role === 'principal' ?

                                    <Row>
                                        <Col xs={12}>
                                            <Breadcrumb style={{ width: '100%' }}>
                                                <BreadcrumbItem>
                                                    <Link to="/dashboard">
                                                        <FontAwesomeIcon icon={faHome} />
                                                    </Link>
                                                </BreadcrumbItem>
                                                <BreadcrumbItem>
                                                    <Link to="/dashboard/subscriptions">Subscription List</Link>
                                                </BreadcrumbItem>
                                                <BreadcrumbItem active>Subscription Details</BreadcrumbItem>
                                            </Breadcrumb>
                                        </Col>
                                    </Row>

                                    : null
                            }


                            <Row>
                                <Col xs={12}>
                                    <Card>
                                        <CardHeader>
                                            {Subscription_Data.subscription_name}
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                {
                                                    role === 'student' && Subscription_Data.renewal ?
                                                        <>
                                                            <Col xs={8}>
                                                                <Alert color="warning">
                                                                    <span style={{ marginRight: '6px' }}><WarningIcon /></span>
                                                                    Please Renew This Subscription
                                                                </Alert>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <LaddaButton
                                                                    className="mb-2 mr-2 btn btn-primary"
                                                                    loading={load_renewal}
                                                                    onClick={() => { Renew(Subscription_Data.order_id) }}
                                                                    data-style={ZOOM_IN}
                                                                    style={{ marginTop: '10px' }}
                                                                >
                                                                    Renew Subscription
                                                                </LaddaButton>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <hr />
                                                            </Col>
                                                        </>
                                                        :
                                                        ''
                                                }

                                                <Col md={5} className="assign-details-card-div-head" >
                                                    <span style={leftmargin}>Subscription Name</span>
                                                </Col>
                                                <Col md={7} className="assign-details-card-div">
                                                    <span style={valstyle}>{Subscription_Data.subscription_name}</span>
                                                </Col>
                                                <Col md={5} className="assign-details-card-div-head" >
                                                    <span style={leftmargin}>Subscription Fee</span>
                                                </Col>
                                                <Col md={7} className="assign-details-card-div">
                                                    <span style={valstyle}>{Subscription_Data.subscription_fee} {Subscription_Data.currency_code}</span>
                                                </Col>
                                                <Col md={5} className="assign-details-card-div-head" >
                                                    <span style={leftmargin}>Subscription Type</span>
                                                </Col>
                                                <Col md={7} className="assign-details-card-div">
                                                    <span style={valstyle}>{Subscription_Data.subscription_type}</span>
                                                </Col>

                                                <Col md={5} className="assign-details-card-div-head">
                                                    <span style={leftmargin}>Subscription Start Date</span>
                                                </Col>
                                                <Col md={7} className="assign-details-card-div">
                                                    <span style={valstyle}>{Subscription_Data.subscription_start_date}</span>
                                                </Col>

                                                <Col md={5} className="assign-details-card-div-head">
                                                    <span style={leftmargin}>Subscription End date</span>
                                                </Col>
                                                <Col md={7} className="assign-details-card-div">
                                                    <span style={valstyle}>{Subscription_Data.subscription_end_date}</span>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="pt-4">
                                <Col sm={12}>
                                    <Card>
                                        <CardHeader>Subscription Description</CardHeader>
                                        <CardBody>
                                            <div
                                                style={{
                                                    minHeight: "250px",
                                                    maxHeight: "500px",
                                                    overflowY: "scroll",
                                                    padding: "10px",
                                                }}
                                                dangerouslySetInnerHTML={{ __html: Subscription_Data.description }}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            {
                                Subscription_Data.expiry_status ?
                                    <Row>
                                        <Col xs={12} style={{ marginTop: '15px' }}>
                                            <Card>
                                                <CardHeader>
                                                    Subscription Classes
                                                </CardHeader>
                                                <CardBody style={{ textAlign: 'center' }}>
                                                    <img src={expired_sub} alt='' width='450px' height='250px' />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col sm={12}>
                                            <Index data_live={Subscription_Live} data_video={Subscription_Video} />
                                        </Col>
                                    </Row>
                            }

                            {
                                role === 'student' ?
                                    <Row>
                                        <Col sm={12}>
                                            <RenewalHistory order_id={Subscription_Data.order_id} />
                                        </Col>
                                    </Row>
                                    :
                                    ''
                            }


                        </LoadingOverlay>

                        <Modals
                            open={successModal.open}
                            // open={true}
                            handleClose={closeSuccessModal}
                            // title={`Time shedule`}
                            size="sm"
                        >

                            <Row>
                                <Col md={12}>
                                    <Lottie options={defaultOptions}
                                        height={145}
                                        width={145}
                                    //isStopped={this.state.isStopped}
                                    //isPaused={this.state.isPaused}
                                    />
                                </Col>
                            </Row>
                            <Row >
                                <Col md={12} style={{ fontSize: '20px', color: 'green', textAlign: 'center' }}>
                                    Success
                                </Col>

                            </Row>
                            <Row>
                                <Col md={12} style={{ textAlign: 'center', color: '#5e8e5f' }}>
                                    <strong style={{ fontWeight: 400 }}>Payment completed successfully</strong>
                                </Col>
                            </Row>
                            <Row>

                                <Col md={12} style={{ textAlign: 'center' }}>
                                    <Button type="button" onClick={closeSuccessModal} color="success">Okay</Button>
                                </Col>
                            </Row>

                        </Modals>


                        <Modals
                            open={failModal.fail}
                            handleClose={() => setFailModal({ fail: false, msg: '' })}
                            size="sm"
                        >
                            <Row>
                                <Col md={12}>
                                    <Lottie options={defaultOptions}
                                        height={145}
                                        width={145}
                                    //isStopped={this.state.isStopped}
                                    //isPaused={this.state.isPaused}
                                    />
                                </Col>
                            </Row>
                            <Row >
                                <Col md={12} style={{ fontSize: '20px', color: '#c0392b', textAlign: 'center' }}>
                                    Failed
                                </Col>

                            </Row>
                            <Row>
                                <Col md={12} style={{ textAlign: 'center', color: '#ab2d20' }}>
                                    <strong style={{ fontWeight: 400 }}>{failModal.msg}</strong>
                                </Col>
                            </Row>
                            <Col md={12} style={{ textAlign: 'center', paddingTop: 25 }}>
                                <Button type="button" onClick={() => setFailModal(false)} color="danger">Okay</Button>
                            </Col>
                        </Modals>

                        <Modal
                            isOpen={OrderData}
                            // handleClose={handleCloseModal}
                            toggle={handleCloseModal}
                            title={"Terms&Conditions"}
                        // size='lg'
                        >
                            <div class='class="modal-content"'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                                    <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Order</h5>
                                    <button type="button" class="close" onClick={() => { handleCloseModal(false) }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-md-12 col-lg-12 col-12">
                                        <div style={{ display: 'flex', justifyContent: 'center' }} class="row">
                                            <div style={{ textAlign: 'center' }} class="col-md-12 col-lg-12 col-12">
                                                <img style={{ borderRadius: '4px' }} src={subscriptionimage} alt="" width='200px' height='100px' class="img-fluid" />
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: '10px' }} class="col-md-12 col-lg-12 col-12 ">
                                                {/* <div style={{ fontSize: '15pt', fontWeight: 'bold', color: '#000000' }} >{Subscription_details.subscription_name}</div>
                                    <div style={{ fontWeight: 'bold', color: '#003366' }}>{Subscription_details.subscription_fee}</div> */}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-12">
                                                <hr />
                                            </div>
                                            {OrderData &&
                                                <Terms
                                                    course_id={OrderData.data.subscription_id} // subscription_id
                                                    name={OrderData.name}
                                                    email={OrderData.email}
                                                    phone={OrderData.phone}
                                                    reg_id={OrderData.reg_id}
                                                    service_type={OrderData.service_type}
                                                    url_page={OrderData.url_page}
                                                    course_amount={OrderData.data.amount}
                                                    mots_order_id={OrderData.order_id}
                                                />
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </Modal>

                    </>
            }
        </div>
    )
}

export default SubscriptionDetails
