import React,{useState,useEffect} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    // Badge,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { requiredValidator } from "../../../utilities/Validator";
import { setReqData,updateGallery,editGallery } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { Progress } from "react-sweet-progress"
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";

function EditGallery() {


    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const formData = new FormData();
    const params = useParams()
    const history = useHistory();

    const galleryloaderupdate = useSelector((state) =>state.superadmin.galleryloaderupdate ? state.superadmin.galleryloaderupdate : false);
    const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

    
    const [InputField,setInputfield]=useState('')
    const [err,setErr]=useState({})
    const [VideoFile, setVideoFile] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [opt, setOpt] = useState('image');


    useEffect(()=>{
        dispatch(editGallery(tokenData.token,params.gallery_id,setvalues))
        dispatch(setReqData('percetage',''))

    },[])

    const setvalues=(res)=>{
        setInputfield(res)
        // setOpt(res.type)
    }
    const handleChangeInput=(e)=>{
        setInputfield({...InputField,[e.target.name]:e.target.value})
    }

    const Submitform=(e)=>{
        e.preventDefault();


        const error={
            title: requiredValidator(InputField.title),
            // discription: requiredValidator(InputField.description),

        }
        if( !error.title ){
            setErr(error)
            formData.append("title", InputField.title);
            formData.append("description", null);
            formData.append("image_file", ImageFile);
            formData.append("video_file",VideoFile );
            formData.append("gallery_id",params.gallery_id);
            dispatch(updateGallery(tokenData.token,formData,cb))
        }
        else{
            setErr(error)
        }
    }

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleVideoUpload = (e) => {
        setVideoFile(e.target.files[0]);
    };
      
    const cb=()=>{

        dispatch(setReqData('percetage',''))

        setOpen(true)
    } 

    const Closesuccess=()=>{
        setOpen(false)
        history.push('/dashboard/gallery')
    }

    const ChangeOpt=(e)=>{
        setOpt(e.target.value)
    }

    return (
        <div>
            {
                InputField ? 
                <>
                <PageTitle
                    heading="Edit Gallery"
                    subheading="Update Your Gallery Here"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />

                    <Card className="main-card mb-3 ">
                        <Form onSubmit={Submitform} >
                        <CardHeader>
                            Edit Gallery
                        </CardHeader>

                        <CardBody>
                            <Row>
                                <Col className="pt-1" xs={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                            Title
                                        </Label>

                                        <Input
                                            type="text"
                                            name="title"
                                            value={InputField.title}
                                            invalid={err.title}
                                            onChange={handleChangeInput}
                                            autocomplete="off"
                                        />
                                        <FormFeedback>{err.title}</FormFeedback>

                                    </Col>


                                {/* <Col className="pt-1" xs={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                            Description
                                        </Label>

                                        <Input
                                            type="textarea"
                                            rows={5}
                                            name="description"
                                            value={InputField.description}
                                            invalid={err.discription}
                                            onChange={handleChangeInput}
                                        />
                                        <FormFeedback>{err.discription}</FormFeedback>
                                    </Col> */}
{/*                                 
                                    <Col xs={12} sm={6} className="pt-1 mt-4">
                                        <Label for="exampleNameF">
                                                Choose Image/Video
                                            </Label>
                                        <Input 
                                            type='select'
                                            name='opt'
                                            onChange={ChangeOpt}
                                            value={opt}
                                        >
                                            <option value='image'>Image</option>
                                            <option value='video'>Video</option>
                                        </Input>
                                    </Col> */}
                            
                                    <Col className="pt-1 mt-4" xs={12} sm={6}>
                                        {
                                            opt==='image' ? 
                                            <>
                                                <Label for="exampleNameF">
                                                        Image
                                                </Label>
                                                <Input 
                                                    type='file'
                                                    name='image'
                                                    accept="image/*"
                                                    onChange={handleImageUpload}
                                                />
                                            </>
                                            :
                                            <>
                                                <Label for="exampleNameF">
                                                        Video
                                                    </Label>
                                                <Input 
                                                    type='file'
                                                    name='video'
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={handleVideoUpload}
                                                />
                                            </>
                                        }
                                    </Col>

                                
                                <Col sm={12}>
                                {(percetage !== ""  &&  (ImageFile || VideoFile) ) ? (
                                    <>
                                        <strong style={{ color: "rgb(19, 140, 228)" }}>
                                        Uploading Files...
                                        </strong>
                                        <Progress percent={percetage} />
                                    </>
                                    ) : null}
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                        
                            <Col xs={12} sm={6}>
                                <LaddaButton
                                    className="btn btn-primary"
                                    loading={galleryloaderupdate}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                >
                                    Update
                                </LaddaButton>
                            </Col>
                        </CardFooter>
                                
                        </Form>
                    </Card>

                    <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={open}
                    text={"Updated successfully"}
                    type="success"
                    onConfirm={Closesuccess} />
                </>
                :''
            }
        </div>
    )
}

export default EditGallery
