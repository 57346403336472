import React, { useState, useEffect } from 'react'
import { assignTeachersToGroup } from '../../../../redux/Actions/grouping'
import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import Loader from 'react-loaders'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

import {
    Row, Col,
    Card, CardBody, CardTitle,
    CardHeader, CardFooter, Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    CustomInput,
    FormGroup, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AddStudentToClass = (props) => {
    useEffect(() => {

        return () => {
            dispatch({ type: 'RESET_ASSIGN_STUDENT_VIDEO' })
        }
    }, [])
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();


    const pStore = useSelector(({ playlist }) => playlist)

    const handleChange = () => {
        dispatch({ type: 'CHANGE_ASSIGN_STUDENT_TO_CLASS' })
    }
    const handleClick = (e) => {
        e.preventDefault();
        //  console.log("props.timeData", props.timeData)


        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(assignTeachersToGroup(tokenData.token, body, props.handleCloseStudentModal))

    };
    return (
        <Form onSubmit={handleClick}>
            <input name="group_id" type="hidden" value={props.groupID} />
            <Card className="main-card mb-3">
                <CardHeader>
                    <Col md={5} sm={5}>
                        Teachers
                    </Col>
                    <Col md={7} sm={7}>
                        Phone
                    </Col>
                </CardHeader>
                <CardBody>
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>


                            {
                                props.loader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {props.data ? props.data.map(dt => (
                                <Row key={dt.user_id}>
                                    <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                        {`${dt.first_name} ${dt.last_name}`}
                                    </Col>
                                    <Col md={5} sm={5} style={{ paddingLeft: '20px' }}>
                                        {`${dt.enterprise_mobile}     `}
                                    </Col>
                                    <Col md={2} sm={2} >
                                        <FormGroup>
                                            <div>
                                                <input type="checkbox" id="exampleCustomCheckbox"
                                                    name="teacher[]"
                                                    defaultChecked={dt.selected}
                                                    //checked
                                                    onChange={handleChange}
                                                    value={dt.user_id}
                                                />

                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            )) : null}

                        </PerfectScrollbar>
                    </div>
                </CardBody>
                <CardFooter className="d-block text-right">

                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        loading={pStore.assignStudentLoader}
                        // style={{ width: '-webkit-fill-available' }}

                        type="button"
                        data-style={ZOOM_IN}
                        type="submit"
                    >
                        Save
                                            </LaddaButton>
                    {/* <Button  size="lg" color="success">Save</Button> */}
                </CardFooter>
            </Card>
        </Form>
    )
}

export default AddStudentToClass
