import React,{useState,useEffect} from 'react'
import {
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Card,
    CardBody,
    Label,
    CardTitle,
    FormFeedback,TabContent, TabPane, Nav, NavItem, NavLink, CardHeader,
  } from "reactstrap";
import { requiredValidator } from "../../../utilities/Validator";
// import { Progress } from "react-sweet-progress";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { DatePicker  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { getAllCurrencies } from "../../../redux/Actions/vendor_course";
import PageTitle from "../../HeaderTitle/PageTitle";
import { editSubscription,updateSubscription } from "../../../redux/Actions/subscription-action"
import { useParams } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SweetAlert from 'sweetalert-react';
import classnames from 'classnames';

// import classnames from 'classnames';
import {
    EditorState,
    ContentState,
    // convertFromHTML,
    convertToRaw,
  } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { setReqData } from "../../../redux/Actions/Superadmin"
import ShowClass from "./LiveClass/ListLiveClass"
import VideoClass from "./CreateVideoSession/VideoClass/index";

function EditSubscription() {

    const [LiveClass, setLiveClass] = useState(false);
    const [OnDemand, setOnDemand] = useState(false);
  
    // const [ImageFile, setImageFile] = useState(null);
    const [start_date, setStartDate] = useState(null)
    const [end_date, setEndDate] = useState(null)
    const [successModal, setSuccessModal] = useState(false)

    const [CourseDescription, setCourseDescription] = useState() //literal description about course


    const [inputField, setInputField] = useState({
        course_name: "",
        subscription_fee: "",
        subscription_end_date: "",
        subscription_start_date: "",
        ondemand_video_enabled: "",
        live_class_enabled: "",
        subscription_type: "",
        currency_id:''
      });

    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const params = useParams()
    const formData = new FormData();

    const populate_sub = useSelector((state) =>state.Subscription.populate_sub ? state.Subscription.populate_sub : false);
    const create_loader = useSelector((state) =>state.Subscription.create_loader ? state.Subscription.create_loader : false);

    const tab = useSelector((state) => state.superadmin.tab_sub ? state.superadmin.tab_sub : "1");

    useEffect(()=>{
        dispatch(getAllCurrencies(tokenData.token,cb));
        dispatch(editSubscription(tokenData.token,params.subscription_id,cb_data));
    },[])
     
    const cb_data=(res)=>{
        setInputField(res)
        res.live_class_enabled ? setLiveClass(true) : setLiveClass(false)
        res.ondemand_video_enabled ? setOnDemand(true) : setOnDemand(false)
        setStartDate(new Date(res.subscription_start_date))
        setEndDate(new Date(res.subscription_end_date))

        const html = res.description
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
    
        setCourseDescription(editorState)

    }
    const handleChangeInput = (e) => {
        const { name, value } = e.target;
    
        setInputField({ ...inputField, [name]: value });
        };
        const [errors, setErrors] = useState({})
    
        // const handleImageUpload = (e) => {
        //     setImageFile(e.target.files[0]);
        // };
    
    
        const handleChangeFormDate = (e, val) => {
            const dt = moment(e).format("YYYY-MM-DD");
    
        if (val === 'subscription_start_date') {
            setStartDate(e)
            setInputField({ ...inputField, [val]: dt });
        }
        else {
            setEndDate(e)
            setInputField({ ...inputField, [val]: dt });
        }
        }
    
        const onEditorStateChange = (editorState) => {
        setCourseDescription(editorState);
        };
    
        const [Currencies, setCurrencies] = useState()
    
        const cb = (data) =>{
        setCurrencies(data.currencies)
        // console.log("dattttt",data)
        }
    
        const submitForm = (e) => {
            e.preventDefault();
            let desc = CourseDescription ? draftToHtml(convertToRaw(CourseDescription.getCurrentContent())) : ""
    
            const err = {
                subscription_name: requiredValidator(inputField.subscription_name),
                currency_id: requiredValidator(inputField.currency_id),
                proposed_fee: requiredValidator(inputField.subscription_fee),
                subscription_start_date: requiredValidator(inputField.subscription_start_date),
                subscription_end_date: requiredValidator(inputField.subscription_end_date),
                subscription_type:requiredValidator(inputField.subscription_type),
              };
       
              if (
                !err.subscription_name &&
                !err.proposed_fee &&
                !err.subscription_start_date &&
                !err.subscription_end_date &&      
                !err.currency_id &&
                !err.subscription_type
          
              ) {
                  setErrors(err)
                //   if(ImageFile){
                //     formData.append("image_file", ImageFile);
                //   }
                  formData.append("id", params.subscription_id);
                  formData.append("subscription_name", inputField.subscription_name);
                  formData.append("subscription_fee", inputField.subscription_fee);
                  formData.append("subscription_start_date", inputField.subscription_start_date);
                  formData.append("subscription_end_date", inputField.subscription_end_date);
                  formData.append("ondemand_video_enabled", OnDemand ? 1 : 0);
                  formData.append("live_class_enabled", LiveClass ? 1 : 0);
                  formData.append("description", desc);
                  formData.append("currency_id", inputField.currency_id);
                  formData.append("subscription_type", inputField.subscription_type);
                  formData.append("group_id", inputField.group_id);
                  dispatch(updateSubscription(tokenData.token, formData, handleOpenSuccessModal,));
                }
              else {
                setErrors(err);
              }
        }
        const handleOpenSuccessModal=()=>{
            setSuccessModal(true)
        }

        const CloseSuccess=()=>{
            setSuccessModal(false)
            history.push('/dashboard/subscriptions')
        }

        const toggle = (val) => {

            if (tab !== val) {
              // setTab(val)
              dispatch(setReqData('tab_sub', val))
            }
          }

    return (
        <div>
            {
                populate_sub ? 
                <>
                    <Card style={{ padding: '30px' }}>
                    <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                        <Skeleton height={30} count={5} />
                    </SkeletonTheme>
                    </Card>
                    <Card style={{ padding: '30px', marginTop: '25px' }}>
                    <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
        
                        <Skeleton height={30} count={4} />
                    </SkeletonTheme>
                    </Card>
                </>
                :
                <>
                    <PageTitle
                        heading="Edit Subscription"
                        subheading="Edit Susbcription"
                        icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
                    />
                

                    <Card>
                    <CardHeader>
                      <Nav justified>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({ active: tab === "1" })}
                            onClick={() => {
                              toggle("1");
                            }}
                          >
                            Edit Subscription
                          </NavLink>
                        </NavItem>
                        {
                            inputField.live_class_enabled===1 ? 
                            <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({ active: tab === "2" })}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            Edit Live Class
                          </NavLink>
                        </NavItem>
                        :''

                        }
                        
                        {
                            inputField.ondemand_video_enabled===1 ?
                            <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({ active: tab === "3" })}
                            onClick={() => {
                              toggle("3");
                            }}
                          >
                            Edit Ondemand Class
                          </NavLink>
                        </NavItem>
                        :''
                        }
                        
                      </Nav>
                    </CardHeader>

                    <CardBody>
                    <TabContent activeTab={tab}>
                        <TabPane tabId="1">
                        <Form id="class-form" onSubmit={submitForm} >
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>GENERAL DETAILS</CardTitle>

                                    <FormGroup row>
                                        <Col sm={12}>
                                        <Row>
                                            <Col className="pt-3" sm={6}>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span> Subscription Name
                                            </Label>
                                            <Input
                                                type="text"
                                                id='subscription_name'
                                                name="subscription_name"
                                                invalid={errors.subscription_name}
                                                onChange={handleChangeInput}
                                                value={inputField.subscription_name}
                                            />
                                            <FormFeedback>{errors.subscription_name}</FormFeedback>
                                            </Col>

                                            
                                            <Col className="pt-3"  xs={12} sm={6}>
                                                <Label for="exampleNameF">
                                                <span className="text-danger" >*</span> Proposed Fee
                                                </Label>
                                                <Input
                                                    name="subscription_fee"
                                                    id="proposed_fee"
                                                    onChange={handleChangeInput}
                                                    value={inputField.subscription_fee}
                                                    type="number"
                                                    invalid={errors.proposed_fee}
                                                ></Input>
                                                <FormFeedback>{errors.proposed_fee}</FormFeedback>
                                            </Col>
                                            <Col className="pt-3"  xs={12} sm={6}>
                                                <Label for="exampleNameF">
                                                <span className="text-danger" >*</span> Currency
                                                </Label>
                                                <Input
                                                    name="currency_id"
                                                    onChange={handleChangeInput}
                                                    value={inputField.currency_id}
                                                    type="select"
                                                    invalid={errors.currency_id}
                                                    id='currency_id'
                                                >
                                                    <option value={""}>select</option>
                                                    {
                                                    Currencies?.map( currency =>{
                                                        return <option value={currency.currency_id}>{currency.currency_name}</option>
                                                    } )
                                                    }
                                                </Input>
                                                <FormFeedback>{errors.currency_id}</FormFeedback>
                                            </Col>
                                            
                                            <Col xs={12} sm={6} className="pt-3">
                                            <Label for="exampleInput" >
                                                <span className="text-danger" id='course_start_date'>*</span>
                                                Subscription Type
                                            </Label>
                                                <Input
                                                    name="subscription_type"
                                                    id='subscription_type'
                                                    onChange={handleChangeInput}
                                                    type="select"
                                                    invalid={errors.subscription_type}
                                                    value={inputField.subscription_type}
                                                >
                                                    <option value={""}>select type</option>
                                                    <option value={'monthly'}>monthly</option>
                                                    <option value={'quarterly'}>quarterly</option>
                                                    <option value={'half-yearly'}>half-yearly</option>
                                                    <option value={'yearly'}>yearly</option>
                                                </Input>
                                                <FormFeedback>{errors.subscription_type}</FormFeedback>
                                            </Col>

                                            <Col xs={12} sm={6} className="pt-3">
                                            <Label for="exampleInput" >
                                                <span className="text-danger" id='course_start_date'>*</span>
                                                Subscription start date
                                            </Label>

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                format="dd/MM/yyyy"
                                                label=""
                                                size='small'
                                                inputVariant="outlined"
                                                value={start_date}
                                                onChange={date => handleChangeFormDate(date,'subscription_start_date')}
                                                // minDate={new Date()}
                                                fullWidth
                                                id='course_start_date'
                                                views={["year", "month", "date"]}
                                                openTo="year"
                                            />
                                            </MuiPickersUtilsProvider>
                                            <span style={{ color: 'red' }}>{errors.course_start_date}</span>
                                            </Col>
                                            <Col xs={12} sm={6} className="pt-3">
                                                <Label for="exampleInput" >
                                                    <span className="text-danger" id='course_end_date'>*</span>
                                                    Subscription end date
                                                </Label>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <DatePicker
                                                
                                                        format="dd/MM/yyyy"
                                                        label=""
                                                        size='small'
                                                        inputVariant="outlined"
                                                        value={end_date}
                                                        onChange={date => handleChangeFormDate(date,'subscription_end_date')}
                                                        minDate={start_date ? start_date : ''}
                                                        fullWidth
                                                        id='course_end_date'
                                                        views={["year", "month", "date"]}
                                                        openTo="year"
                                                    />
                                                </MuiPickersUtilsProvider>

                                                <span style={{ color: 'red' }}>{errors.course_end_date}</span>
                                            </Col>
                                            
                                            

                                            <Col sm={4} className="pt-3" >
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {" "}
                                                    <label className="pt-2">
                                                    {/* <span className="text-danger">*</span> */}
                                                    On demand video
                                                    </label>{" "}
                                                    <div
                                                    className="switch has-switch ml-1 "
                                                    data-on-label="ON"
                                                    data-off-label="OFF"
                                                    onClick={() => {
                                                        setOnDemand(!OnDemand)
                                                    }}
                                                    >
                                                    <div
                                                        className={cx("switch-animate", {
                                                        "switch-on": OnDemand,
                                                        "switch-off": !OnDemand,
                                                        })}
                                                    >
                                                        <input type="checkbox" />

                                                        <span className="switch-left bg-success">YES</span>
                                                        <label>&nbsp;</label>
                                                        <span className="switch-right bg-danger">NO</span>
                                                    </div>
                                                    </div>
                                                </div>
                                            </Col>

                                        <Col sm={4} className="pt-3" >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {" "}
                                                <label className="pt-2">
                                                {/* <span className="text-danger">*</span> */}
                                                Live Class
                                                </label>{" "}
                                                <div
                                                className="switch has-switch ml-1 "
                                                data-on-label="ON"
                                                data-off-label="OFF"
                                                onClick={() => {
                                                    setLiveClass(!LiveClass)
                                                }}
                                                >
                                                <div
                                                    className={cx("switch-animate", {
                                                    "switch-on": LiveClass,
                                                    "switch-off": !LiveClass,
                                                    })}
                                                >
                                                    <input type="checkbox" />

                                                    <span className="switch-left bg-success">YES</span>
                                                    <label>&nbsp;</label>
                                                    <span className="switch-right bg-danger">NO</span>
                                                </div>
                                                </div>
                                            </div>

                                            </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                    </CardBody>
                                </Card>

                
                                <Card className="main-card mb-3">
                                    <CardBody>
                                    <CardTitle>COURSE DESCRIPTION</CardTitle>
                                    <Editor
                                        // toolbarHidden
                                        editorState={CourseDescription}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                        image: { inDropdown: false },
                                        options: ['inline', 'link','history','fontSize','fontFamily','list']
                                        }}
                                    />
                                    </CardBody>
                                </Card>

                
                                    {/* <Row>
                                        <Col sm={6}>
                                        <Label for="exampleInput">
                                            Course Logo (200 x 200)px 
                                        </Label>
                                        <Input
                                            id="exampleInput"
                                            type="file"
                                            //required
                                            onChange={handleImageUpload}
                                            accept="image/*"
                                        ></Input>
                                        </Col>

                                        <Col sm={12}>
                                        {(uploadProgressVideo !== "" &&  ImageFile) ? (
                                            <>
                                            <strong style={{ color: "rgb(19, 140, 228)" }}>
                                                Uploading Files...
                                            </strong>
                                            <Progress percent={uploadProgressVideo} />
                                            </>
                                        ) : null}
                                        </Col>
                                    </Row> */}
                            

                                <LaddaButton
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={create_loader}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                >
                                    Update Subscription Details
                                </LaddaButton>
                            </Form>
                            </TabPane>
                            {
                            
                             inputField.live_class_enabled===1 ? 
                             <TabPane tabId="2">
                                    <Card>
                                        <CardBody>
                                            <ShowClass type="subscription" id={params.subscription_id} />
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                :''
                            }
                        
                            {
                                inputField.ondemand_video_enabled===1 ?
                                <TabPane tabId="3">
                                    <VideoClass subscription_id={params.subscription_id} />
                                </TabPane>
                                :''
                            }
                                
                            </TabContent>
                        </CardBody>
                    </Card>
                </>
            }
         <SweetAlert
          title="Success"
          confirmButtonColor=""
          show={successModal}
          text="Updated successfully"
          type="success"
          onConfirm={CloseSuccess} />
        </div>
    )
}

export default EditSubscription
