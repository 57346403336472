import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import classnames from "classnames";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "black",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const playable = { color: "black", cursor: "pointer" };
const notplayable = { color: "red", cursor: "pointer" };

function Index(props) {
  const live = props.data_live ? props.data_live : [];
  const demand = props.data_video ? props.data_video : [];

  const [tab, setTab] = useState(live.length > 0 ? "1" : "2");
  const [expandedlive, setExpandedLive] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  // const role=tokenData.role

  // useEffect(()=>{
  //     dispatch(setReqData('selected_demand',''))
  //     toggle(props.tabval)
  // },[props.tabval])

  const toggle = (val) => {
    if (tab !== val) {
      setTab(val);
    }
  };

  const handleChangeLive = (panel) => (event, isExpanded) => {
    setExpandedLive(isExpanded ? panel : false);
  };

  const VideoList = (val) => {
    if (tokenData.role === "student") {
      if (val.playable) {
        history.push(`/dashboard/subscription/${val.ondemand_class_id}/videos`);
      } else {
        Swal.fire({
          icon: "warning",
          title: "can't access",
          text: "complete above classes to access this class",
        });
      }
    } else {
      history.push(`/dashboard/subscription/${val.ondemand_class_id}/videos`);
    }
  };

  return (
    <Card tabs="true" className="mb-3" style={{ marginTop: "15px" }}>
      {live.length === 0 && demand.length === 0 ? 
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <img src={expired_sub} alt="" width="500px" height="300px" /> */}
        <span>No Data Yet.</span>
      </div>  
      :
      <>
       <CardHeader>
          <Nav justified>
            {live.length > 0 ? (
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: tab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Live Class
                </NavLink>
              </NavItem>
            ) : null}

            {demand.length > 0 ? (
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: tab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  On Demand Class
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>
        </CardHeader>
        <CardBody>
        <TabContent activeTab={tab}>
          {live.length > 0 ? (
            <TabPane tabId="1">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: "70px" }}>
                      Class Name
                    </TableCell>
                    <TableCell align="left">Subject</TableCell>
                    <TableCell>Timing</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
                  {live.map((val, ky) => {
                    let s = val.live_class_start_date
                      ? val.live_class_start_date
                      : "";
                    let e = val.live_class_end_date
                      ? val.live_class_end_date
                      : "";
                    let start = s ? s.slice(0, 10) : s;
                    let end = e ? e.slice(0, 10) : e;
                    return (
                      <Accordion
                        key={ky}
                        expanded={expandedlive === ky}
                        onChange={handleChangeLive(ky)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>
                            {val.live_class_name}
                          </Typography>
                          <Typography className={classes.heading}>
                            {val.subject_name}
                          </Typography>
                          <Typography
                            className={classes.secondaryHeading}
                            style={{ marginRight: "5px" }}
                          >
                            From
                          </Typography>
                          <Typography>{start}</Typography>
                          <Typography
                            className={classes.secondaryHeading}
                            style={{ margin: "0px 5px" }}
                          >
                            To
                          </Typography>
                          <Typography>{end}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Batch Name</TableCell>
                                <TableCell>Day</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {val.schedule.map((i, k) => {
                                return (
                                  <TableRow key={k}>
                                    <TableCell>{i.batch_name}</TableCell>
                                    <TableCell>{i.day}</TableCell>
                                    <TableCell>{i.class_from_time}</TableCell>
                                    <TableCell>{i.class_to_time}</TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              
            </TabPane>
          ) : null}

          {demand.length > 0 ? (
            <TabPane tabId="2">
              {demand.length > 0 ? (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Class Name</TableCell>
                      <TableCell>Videos</TableCell>
                      {
                          tokenData.role==='student' ? 
                            <TableCell>Viewed Percentage</TableCell>
                          :''
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {demand.map((val, k) => {
                      return (
                        <TableRow key={k}>
                          <TableCell>{val.ondemand_class_name}</TableCell>
                          {val.videos.length > 0 ? (
                            <TableCell>
                              <Tooltip title="View Video List">
                                <span
                                  onClick={() => {
                                    VideoList(val);
                                  }}
                                >
                                  <PlayCircleFilledIcon
                                    style={
                                      val.playable ? playable : notplayable
                                    }
                                  />
                                </span>
                              </Tooltip>
                            </TableCell>
                            
                          ) : (
                            ""
                          )}
                          {
                            tokenData.role==='student' ? 
                              <TableCell>{val.percentage ? val.percentage:0}%</TableCell>
                            :''
                          }
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                "No Demand Classes"
              )}
            </TabPane>
          ) : (
            null
          )}
          
        </TabContent>
      </CardBody>
      </>
    }
       
      

     
    </Card>
  );
}

export default React.memo(Index);
