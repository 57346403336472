import React from 'react';
import Header from "../LandingPageNew/header"
import Footer from "../LandingPageNew/HomePage/Footer/Footer"
import Login from "./Login"
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

function LoginIndex(){


    const Menu_section = useSelector((state) =>state.genexhome.Menu_section ? state.genexhome.Menu_section : "");
   

    return(
        <div>
           

            <Header/>
            {
                Menu_section ?
                <>
                    <Login/>
                    <Footer/>
                </>
                :
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress />
                </div>
                    
            }
    
            
        </div>
    )
}
export default LoginIndex