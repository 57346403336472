import React,{ useState} from 'react';
import {
    Row, Col, Container,Label,Input,FormFeedback
  } from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import { useDispatch, useSelector } from "react-redux";
import { setReqStudentData,createTicketStudent,getAllStudentTickets } from "../../../redux/Actions/Student"
import SweetAlert from 'sweetalert-react';
import {  requiredValidator,} from '../../../utilities/Validator'

function Createticket(){


    const dispatch = useDispatch();
    const [show,SetShow]=useState(false)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [successModal, setSuccessModal] = useState(false);
    const [errors, setErrors] = useState({})

    const ReasonArray = useSelector((state) =>state.student.ReasonArray ? state.student.ReasonArray : []);
    const orders=ReasonArray ? ReasonArray.orders ? ReasonArray.orders :[]:[]
    const reasons=ReasonArray ? ReasonArray.reasons ? ReasonArray.reasons :[]:[]
    const frstreasons=reasons[0] ? reasons[0].reason ? reasons[0].reason:'':''
    const frstorder=orders[0] ? orders[0].order_name ? orders[0].order_name:'':''
    const ticket_create_loader = useSelector((state) =>state.student.ticket_create_loader ? state.student.ticket_create_loader : '');

    
    const [input,SetInput]=useState({
        ticketreason:'',
        order:'',
        title:'',
        message:''
    })

    const Handlechange=(event)=>{
        
        SetInput({ ...input, [event.target.name]: event.target.value });
        let search =reasons.find(i => i.reason === event.target.value);
        if(search.type==="Order"){
            SetShow(true)
        }
        else{
            SetShow(false)
        }
    }

    const handleChangeInput = (event) => {
        SetInput({ ...input, [event.target.name]: event.target.value });
      };

    const Submit=()=>{

        const err = {
            title: requiredValidator(input.title),
            message: requiredValidator(input.message),
        }
        if (!err.title &&!err.message){
            setErrors(err);
            let submitreason;
            let submitorder;
            if(input.ticketreason===''){
                submitreason =reasons.find(i => i.reason === frstreasons);
            }
            else{
                submitreason =reasons.find(i => i.reason === input.ticketreason);
            }

            if(input.order===''){
                submitorder =orders.find(i => i.order_name === frstorder);
            }
            else{
                submitorder =orders.find(i => i.order_name === input.order);
            }
        // console.log('reason_idreason_idreason_idreason_id',submitreason)
        // console.log('order_idorder_idorder_idorder_idorder_id',submitorder)

            dispatch(createTicketStudent(
                tokenData.token,
                submitreason ? submitreason.id ? submitreason.id:'':'',
                submitorder ? submitorder.order_id ? submitorder.order_id:'':'',
                input.title,
                input.message,
                handleOpenSuccessModal
                ))
            }   
        else{
            setErrors(err);
        }

        
    }  


    const handleOpenSuccessModal = () => {
        setSuccessModal(true)
    
    }
    const handleCloseSuccesstModal = () => {
  
        setSuccessModal(false)
        dispatch(getAllStudentTickets(tokenData.token))
        dispatch(setReqStudentData('Openticket',false))
    }
    return(
        <Container>
            <Row>
                <Col xs={12} style={{marginTop:'4px'}}>
                 
                    <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Select Reason
                    </Label>
                    <Input type="select" name="ticketreason" value={input.reason} defaultValue='select' onChange={Handlechange}>
                        {
                            reasons.map((val,ky)=>{
                                return(
                                    <option key={ky} value={val.reason}>
                                        {val.reason}
                                    </option>
                                )
                            })
                        }
                     
                    </Input>
                </Col>
            </Row>

            {
                show ?
            <Row>
                
                <Col xs={12} style={{marginTop:'4px'}}>
                <Label for="exampleNameF">
                    <span className="text-danger">*</span>
                    Select Order Id
                </Label>
                <Input type="select" name="order" value={input.order} onChange={handleChangeInput} id="exampleSelect">
                    { 
                        orders.map((val,ky)=>{
                            return(
                                <option key={ky} value={val.order_name}>
                                    {
                                        val.order_name
                                    }
                                </option>
                            )
                        })
                    }
                </Input>
            </Col>
                
            </Row>
            :
            ''
            }
            
            <Row>
                <Col xs={12} style={{marginTop:'4px'}}>
                    <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Title
                    </Label>
                    <Input type="text" id="title" name='title' onChange={handleChangeInput} value={input.title} invalid={errors.title} />
                    <FormFeedback>{errors.title}</FormFeedback>
                </Col>
            </Row>

            <Row>
                <Col xs={12} style={{marginTop:'4px'}}>
                    <Label for="exampleNameF" >
                        <span className="text-danger">*</span>
                        Message
                    </Label>
                    <Input type="textarea" rows='2' name="message" id="message" onChange={handleChangeInput} value={input.message} invalid={errors.message} />
                    <FormFeedback>{errors.message}</FormFeedback>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{marginTop:'10px'}}>
                <LaddaButton
                    className="mb-2 mr-2 btn btn-primary"
                    loading={ticket_create_loader}
                    type="submit"
                    data-style={ZOOM_IN}
                    onClick={Submit}
                >
                    Create
                </LaddaButton>
                </Col>
            </Row>

            <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Created successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />
        </Container>
    )
}
export default Createticket