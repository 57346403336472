import React from 'react'
//import DataTable from '../../common/AttendanceTable'
import DataTable from '../../common/DataTable'
const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                title={props.title}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns}
                data={props.data}
                loader={props.loader}

            />
        </>
    )
}

export default Table