import React,{useState,useEffect} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    // Badge,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { requiredValidator } from "../../../utilities/Validator";
import { setReqData,updateTestimonial,editTestimonial } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { Progress } from "react-sweet-progress"
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";


function EditTestimonial(){

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const formData = new FormData();
    const params = useParams()
    const history = useHistory();

    const testimony = useSelector((state) =>state.superadmin.testimony ? state.superadmin.testimony : false);
    const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

    
    const [InputField,setInputfield]=useState('')
    const [err,setErr]=useState({})
    // const [VideoFile, setVideoFile] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);


    useEffect(()=>{
        dispatch(setReqData('percetage',''))
        dispatch(editTestimonial(tokenData.token,params.tesimony_id,setvalues))
    },[])

    const setvalues=(res)=>{
        setInputfield(res)
    }
    const handleChangeInput=(e)=>{
        setInputfield({...InputField,[e.target.name]:e.target.value})
    }

    const Submitform=(e)=>{
        e.preventDefault();
        const error={
            title: requiredValidator(InputField.title),
            discription: requiredValidator(InputField.description),
            designation: requiredValidator(InputField.designation),

        }
        if( !error.title && !error.discription && !error.designation){
            setErr(error)
            formData.append("title", InputField.title);
            formData.append("description", InputField.description);
            formData.append("image_file", ImageFile);
            // formData.append("video_file",VideoFile );
            formData.append("testimonial_id",params.tesimony_id);
            formData.append("designation",InputField.designation );
            dispatch(updateTestimonial(tokenData.token,formData,cb))
        }
        else{
            setErr(error)
        }
    }

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };

    // const handleVideoUpload = (e) => {
    //     setVideoFile(e.target.files[0]);
    // };
      
    const cb=()=>{

        dispatch(setReqData('percetage',''))

        setOpen(true)
    } 

    const Closesuccess=()=>{
        setOpen(false)
        history.push('/dashboard/testimonial')
    }
    return(
        <div>
            {
                InputField ? 
                <>
                <PageTitle
                    heading="Edit Testimony"
                    subheading="Update Your Testimony Here"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />

                    <Card className="main-card mb-3 ">
                        <Form onSubmit={Submitform} >
                        <CardHeader>
                            Edit Testimonial
                        </CardHeader>

                        <CardBody>
                            <Row>
                                <Col className="pt-1" xs={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                            Title
                                        </Label>

                                        <Input
                                            type="text"
                                            name="title"
                                            value={InputField.title}
                                            invalid={err.title}
                                            onChange={handleChangeInput}
                                            autocomplete="off"
                                        />
                                        <FormFeedback>{err.title}</FormFeedback>

                                </Col>


                                <Col className="pt-1" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                    Designation
                                    </Label>

                                    <Input
                                        type="text"
                                        name="designation"
                                        value={InputField.designation}
                                        invalid={err.designation}
                                        onChange={handleChangeInput}
                                        autocomplete="off"
                                    />
                                    <FormFeedback>{err.designation}</FormFeedback>

                            </Col>

                                <Col className="pt-1" xs={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                            Description
                                        </Label>

                                        <Input
                                            type="textarea"
                                            rows={5}
                                            name="description"
                                            value={InputField.description}
                                            invalid={err.discription}
                                            onChange={handleChangeInput}
                                        />
                                        <FormFeedback>{err.discription}</FormFeedback>

                                </Col>
                                
                                <Col className="pt-1" xs={12} sm={6}>
                                    <Label for="exampleNameF">
                                        {/* <span className="text-danger">*</span> */}
                                            Image
                                        </Label>
                                    <Input 
                                        type='file'
                                        name='image'
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                    />
                                </Col>

                                {/* <Col className="pt-1" xs={12} sm={6}>
                                    <Label for="exampleNameF">
                                            Video
                                        </Label>
                                    <Input 
                                        type='file'
                                        name='video'
                                        accept="video/mp4,video/x-m4v,video/*"
                                        onChange={handleVideoUpload}
                                    />
                                </Col> */}
                                
                                <Col sm={12}>
                                {(percetage !== ""  &&  ImageFile ) ? (
                                    <>
                                        <strong style={{ color: "rgb(19, 140, 228)" }}>
                                        Uploading Files...
                                        </strong>
                                        <Progress percent={percetage} />
                                    </>
                                    ) : null}
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                        
                            <Col xs={12} sm={6}>
                                <LaddaButton
                                    className="btn btn-primary"
                                    loading={testimony}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                >
                                    Update
                                </LaddaButton>
                            </Col>
                        </CardFooter>
                                
                        </Form>
                    </Card>

                    <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={open}
                    text={"Updated successfully"}
                    type="success"
                    onConfirm={Closesuccess} />
                </>
                :''
            }
            

        </div>
    )
}

export default EditTestimonial