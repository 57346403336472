import React, { useEffect, useState } from 'react';
import banner from "../../LandingPageNew/images/banner-6.png"
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Badge } from 'reactstrap'


import { showCourseDetails, LoginedCoursedetails, setHomeData,LogoutSession } from "../../../redux/Actions/genexhome"

import CircularProgress from "@material-ui/core/CircularProgress";

import VideoCard from '../../SuperadminComponents/Vendor/Vendordetails/Videocard'
import ReactHtmlParser from 'react-html-parser';
import spinner from '../../../assets/animated/Spinner.gif'
import Swal from 'sweetalert2'
import { authCheck, logout } from '../../../redux/Actions/auth'
import LaddaButton, { ZOOM_IN } from "react-ladda";

import { Modal } from 'reactstrap';


import coursedummy from '../../LandingPageNew/images/coursedummy.png'
import previewdummy from "../../LandingPageNew/images/previewdummy.png"
import {

    Card,
    CardHeader,
    Collapse,
    CardBody
} from 'reactstrap';
import Chip from '@material-ui/core/Chip';
import ReactPlayer from 'react-player'


import '../../LandingPageNew/css/animate.css';
// import "./css/bootstrap.min.css"
import '../../LandingPageNew/css/elegant_icon.css';
// import './css/flag-icon.min.css';
import "../../LandingPageNew/css/genex-custom.css"
import '../../LandingPageNew/css/hover-min.css';
import "../../LandingPageNew/css/style.css"
import "../../LandingPageNew/css/style.css.map"
import "../../LandingPageNew/css/swiper.css"


function SingleCoursePreview() {

    const dispatch = useDispatch();
    const params = useParams()
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const [openlive, setOpenlive] = useState(false)
    const [openvideo, setOpenVideo] = useState(false)

    const Single_Course_Details = useSelector((state) => state.genexhome.Single_Course_Details ? state.genexhome.Single_Course_Details : '');

    const video = Single_Course_Details ? Single_Course_Details.preview_video ? Single_Course_Details.preview_video : [] : []
    const frstvideo = video ? video[0] : ''
    const thumb = frstvideo ? frstvideo.thumbnails : []
    // const thumblink=thumb[1] ? thumb[1].link:previewdummy
    const thumblink = Single_Course_Details.camera_tag_thumbnail_path ? Single_Course_Details.camera_tag_thumbnail_path : thumb[1] ? thumb[1].link : previewdummy

    const dur = frstvideo ? frstvideo.video_duration : 'no'
    const purchased = Single_Course_Details ? Single_Course_Details.purchased ? Single_Course_Details.purchased : false : false

    const role = tokenData ? tokenData.role : ''


    let lerning_length = Single_Course_Details.learning_contents ? Single_Course_Details.learning_contents.length : 0
    const half_lerning = (lerning_length / 2).toFixed(0)

    let bannerurl = Single_Course_Details ? Single_Course_Details.header_banner_url ? Single_Course_Details.header_banner_url : banner : banner
    useEffect(() => {
        dispatch(setHomeData('login_from_course', false))
        dispatch(setHomeData('Single_Course_Details', ''))
        dispatch(setHomeData('message', ''))

        if (tokenData) {
            dispatch(authCheck(tokenData.token, cb, authSuccess))
        }
        else {
            dispatch(showCourseDetails(params.course_id))
        }
    }, [])



    const [toggleQuestion, setToggequestion] = useState(1)
    const [togglevideo, setToggevideo] = useState(1)

    const authSuccess = () => {
        dispatch(LoginedCoursedetails(tokenData.token, params.course_id, call_dummy))
    }

    const call_dummy = () => {
        console.log('')
    }
    const cb = () => {

        dispatch(LogoutSession(tokenData.token))
        localStorage.removeItem("authDetails");
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
        history.push('/login')
    }




    const [nowPlaying, setNowPlaying] = useState({ open: false, iframe: "<iframe></iframe>" })

    const openVideo = async (player, thumbnile,processing, camera_tag_url) => {
        if (processing) {
            Swal.fire({
                icon: 'warning',
                title: 'Processing',
                // text: "Video on process"

            })
        } else {
            setNowPlaying({ open: true, iframe: player,camera_tag_url: camera_tag_url })

        }
    }



    const CloseLive = () => {
        setOpenlive(false)
    }



    const applied = false


    return (
        <>


            {
                Single_Course_Details ?
                    <>
                        <section class="course-banner position-relative" style={{ backgroundImage: `url(${bannerurl})` }}>
                            <div class="container"></div>
                        </section>

                        <section class="padding-top-60px padding-bottom-80px" style={{ backgroundColor: '#ffffff' }}>
                            <div class="container">

                                <div class="row">
                                    <div class="col-md-8 col-lg-8 col-12 ">
                                        <div class="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }} >
                                            <h3 class="font-weight-700 text-title-medium mb-0 wow fadeInUp" data-wow-delay="0.2s">{Single_Course_Details.course_name}</h3>
                                            <p class="mb-3">
                                                {
                                                    role ? role === 'student' ?
                                                        <>
                                                            {
                                                                purchased ?
                                                                    <Badge color='success' style={{ marginTop: '3px' }}>Purchased</Badge>
                                                                    :
                                                                    <>
                                                                        {
                                                                            Single_Course_Details.certified === 1 ?
                                                                                <span class="mr-1 course-badges badge badge-success">Certified</span>
                                                                                : null
                                                                        }
                                                                        {
                                                                            Single_Course_Details.days_left ?
                                                                                <span class="mr-1 course-badges badge badge-dark">{Single_Course_Details.days_left}</span>
                                                                                : null
                                                                        }
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                Single_Course_Details.certified === 1 ?
                                                                    <span class="mr-1 course-badges badge badge-success">Certified</span>
                                                                    : null
                                                            }
                                                            {
                                                                Single_Course_Details.days_left ?
                                                                    <span class="mr-1 course-badges badge badge-dark">{Single_Course_Details.days_left}</span>
                                                                    : null
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                Single_Course_Details.certified === 1 ?
                                                                    <span class="mr-1 course-badges badge badge-success">Certified</span>
                                                                    : null
                                                            }
                                                            {
                                                                Single_Course_Details.days_left ?
                                                                    <span class="mr-1 course-badges badge badge-dark">{Single_Course_Details.days_left}</span>
                                                                    : null
                                                            }
                                                        </>

                                                }

                                            </p>

                                            <div class="row">
                                                <div class="col-md-12 mt-1">
                                                    {
                                                        Single_Course_Details ? Single_Course_Details.tags ? Single_Course_Details.tags.length > 0 ?  Single_Course_Details.tags.map((val, ky) => {
                                                            return (
                                                                <Chip
                                                                    key={ky}
                                                                    // size="small"
                                                                    label={val.tag_name}
                                                                    color="primary"
                                                                    variant='outlined'
                                                                    style={{ margin: '3px 0px 0px 3px' }}
                                                                    clickable
                                                                />
                                                            )
                                                        }) : '':'':''
                                                    }

                                                </div>
                                            </div>
                                            <hr class="opacity-4 mt-2 mb-3" />


                                            <h3 class="font-weight-600 mt-4 mb-3 light-bg-title">What you'll learn</h3>
                                            <div class="row">
                                                <div class="col-md-6 col-lg-6 col-12">
                                                    <ul class="course-learn-ul">

                                                        {
                                                            Single_Course_Details.learning_contents.map((val, ky) => {
                                                                if (ky < half_lerning) {
                                                                    return (
                                                                        <li key={ky}>{val}</li>
                                                                    )
                                                                }
                                                                else {
                                                                    return (null)
                                                                }

                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-12">
                                                    <ul class="course-learn-ul">

                                                        {
                                                            Single_Course_Details.learning_contents.map((val, ky) => {
                                                                if (half_lerning <= ky) {
                                                                    return (
                                                                        <li key={ky}>{val}</li>
                                                                    )
                                                                }
                                                                else {
                                                                    return (null)
                                                                }

                                                            })
                                                        }
                                                    </ul>
                                                </div>


                                            </div>

                                            <h3 class="font-weight-600 light-bg-title">Description</h3>
                                            <p class="font-15">
                                                {ReactHtmlParser(Single_Course_Details.description)}
                                            </p>


                                        </div>
                                    </div>

                                    <div class="col-md-4 col-lg-4 col-12">
                                        <div class="col-md-12 col-lg-12 col-12 course-fee-col text-center mb-4">
                                            {
                                                applied ?
                                                    <>
                                                        <h2 style={{ textDecoration: 'line-through' }} class="text-white font-weight-700">{Single_Course_Details.course_fee}</h2>
                                                    </>
                                                    :
                                                    <h2 class="text-white font-weight-700">{Single_Course_Details.course_fee}</h2>

                                            }
                                            {
                                                Single_Course_Details.live_classes.length > 0 ?
                                                    <p class="font-weight-500 font-15 text-white">
                                                        <a onClick={() => { setOpenlive(true) }} class="black-small-link floating" data-toggle="modal" data-target="#class_batch_selection">
                                                            Choose Live Batch Time
                                                        </a>
                                                    </p>
                                                    : null
                                            }




                                            {
                                                Single_Course_Details.ondemand_classes.length > 0 ?
                                                    <p>
                                                        <a onClick={() => { setOpenVideo(true) }} data-toggle="modal" data-target="#ondemand" class="border-link">
                                                            On Demand
                                                        </a>
                                                    </p>
                                                    : ''
                                            }


                                            <LaddaButton
                                                className="btn btn-block btn-gn"
                                                // loading={loader}
                                                type="submit"
                                                // onClick={BuyCourse}
                                                data-style={ZOOM_IN}
                                            >
                                                join Now
                                            </LaddaButton>

                                            <p style={{ cursor: 'pointer' }}><a data-toggle="modal" class="white-link">Have a Coupon?</a></p>

                                        </div>

                                        <div class="col-md-12 col-lg-12 col-12 course-right-col">
                                            <h4 class="font-weight-600 mb-1">Preview Video</h4>
                                            <hr class="mt-0 mb-2" />
                                            <div class="video-col">
                                                <div class="video-container mb-3">
                                                    <div style={{ cursor: 'pointer', backgroundSize: 'cover', borderRadius: '5px', height: '250px', width: '100%', position: 'relative' }} >

                                                        {
                                                            nowPlaying.open ?
                                                                <>
                                                                    {
                                                                        nowPlaying.iframe || nowPlaying.camera_tag_url ?
                                                                            <div style={{ background: `url(${spinner})`, height: '100%', width: '100%', backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', marginBottom: '10px' }}>
                                                                                {
                                                                                    nowPlaying.camera_tag_url ?
                                                                                        <div className='player-wrapper-course'>
                                                                                            <ReactPlayer
                                                                                                url={nowPlaying.camera_tag_url}
                                                                                                controls={true}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        ReactHtmlParser(nowPlaying.iframe)
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <img src={previewdummy} alt='no video' width='100%' height='100%' />

                                                                    }
                                                                </>
                                                                :
                                                                <VideoCard
                                                                    type="playlist"
                                                                    title={'Preview Video'}
                                                                    thumbnile={thumblink}
                                                                    duration={dur}
                                                                    openVideo={openVideo}
                                                                    embed={frstvideo.embed}
                                                                    processing={Single_Course_Details.processing}
                                                                    camera_tag_url={Single_Course_Details.camera_tag_url}
                                                                />

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <img src={Single_Course_Details.banner_url ? Single_Course_Details.banner_url : coursedummy} width='100%' height='250px' alt="" class="img-fluid" style={{ borderRadius: '4px', }} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>


                        <Modal
                            isOpen={openlive}
                            toggle={CloseLive}
                            size={'xl'}
                        >
                            <div class='class="modal-content"'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                                    <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Live Class</h5>
                                    <button type="button" class="close" onClick={CloseLive}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                {
                                    Single_Course_Details.batches.map((val, ky) => {
                                        return (
                                            <Card style={{ margin: '15px' }}>
                                                <CardHeader onClick={() => setToggequestion(ky)}>
                                                    <div class="col-md-12 col-lg-12 col-12">
                                                        <div class="row">
                                                            <div class="col-md-6 col-lg-6 col-12">
                                                                <div class="form-check mb-0">
                                                                    <h4 style={{ fontWeight: '500', color: '#1d1f21' }}>{val.batch_name}</h4>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 col-lg-6 col-12"><h4 class="mb-0" style={{ color: '#1d1f21' }}>Total Classes '{val.live_classes ? val.live_classes.length : 0}'
                                                            </h4></div>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <Collapse isOpen={toggleQuestion === ky ? true : false}>
                                                    <CardBody>
                                                        <div class="col-md-12 col-lg-12 col-12">
                                                            <div class="row">
                                                                {
                                                                    val.live_classes.length > 0 ?
                                                                        val.live_classes.map((val2, ky2) => {
                                                                            let a = val2.live_class_start_date
                                                                            let b = val2.live_class_end_date
                                                                            const s = a.slice(0, 10)
                                                                            const e = b.slice(0, 10)
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        val2.schedule ? val2.schedule.map((val3, ky3) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div key={ky3} class="col-md-3 col-lg-3 col-12">{val2.live_class_subject}</div>
                                                                                                    <div class="col-md-2 col-lg-2 col-12">{val2.live_class_name}</div>
                                                                                                    <div class="col-md-4 col-lg-4 col-12">{val3.day} {val3.from_time} To {val3.to_time}</div>
                                                                                                    <div class="col-md-3 col-lg-3 col-12">{s} To {e}</div>

                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                            :
                                                                                            ''
                                                                                    }
                                                                                </>
                                                                            )
                                                                        })
                                                                        :
                                                                        ''
                                                                }

                                                            </div>

                                                        </div>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        )
                                    })
                                }

                            </div>
                        </Modal>


                        <Modal
                            isOpen={openvideo}
                            toggle={() => { setOpenVideo(false) }}
                            size={'lg'}

                        >
                            <div class='class="modal-content"'>
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                                    <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">On Demand</h5>
                                    <button type="button" class="close" onClick={() => { setOpenVideo(false) }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                {
                                    Single_Course_Details.ondemand_classes.map((val, ky) => {
                                        return (
                                            <Card style={{ margin: '15px' }}>
                                                <CardHeader onClick={() => setToggevideo(ky)}>
                                                    <div class="col-md-12 col-lg-12 col-12">
                                                        <div class="row">
                                                            <div class="col-md-6 col-lg-6 col-12">
                                                                <h4 class="mb-0" style={{ color: '#1d1f21' }}>{val.ondemand_class_name}</h4>
                                                            </div>
                                                            <div class="col-md-6 col-lg-6 col-12">
                                                                <h4 class="mb-0" style={{ color: '#1d1f21' }}>{val.videos ? val.videos.length : 0} Videos</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <Collapse isOpen={togglevideo === ky ? true : false}>
                                                    <CardBody>
                                                        <div class="col-md-12 col-lg-12 col-12">
                                                            <div class="row">
                                                                {
                                                                    val.videos.length > 0 ?
                                                                        val.videos.map((v, ky2) => {

                                                                            return (
                                                                                <>
                                                                                    <div class="col-md-6 col-lg-6 col-12" key={ky2}>
                                                                                        <div class="row">
                                                                                            <div class="col-md-3 col-lg-3 col-12">
                                                                                                <img src={v ? v.thumbnails ? v.thumbnails[0].link : '' : ''} alt="" class="img-fluid" />
                                                                                            </div>
                                                                                            <div class="col-md-9 col-lg-9 col-12 my-auto">
                                                                                                <h5>{v.video_title}</h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-md-6 col-lg-6 col-12 my-auto">
                                                                                        <p class="mb-2">{v.video_duration}</p>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                        :
                                                                        ''
                                                                }

                                                            </div>

                                                        </div>
                                                    </CardBody>
                                                </Collapse>
                                            </Card>
                                        )
                                    })
                                }

                            </div>
                        </Modal>

                    </>

                    :
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </div>
            }

        </>

    )
}

export default SingleCoursePreview