import React, { useState, useEffect, Fragment } from 'react';
import Timer from '../common/Timer'
// import TimerCopy from '../common/Timer/copy'
// import TestTimer from '../common/Timer/testTimer'
import Modals from '../common/Modal'
import TimeShedule from './timingModel'
import { useHistory, Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { development } from '../../config.json'
import { Loader } from 'react-loaders';
// import TimerExam from './examTimer'
// import moment from 'moment';
import BrowserNotSupport from '../Class/Utility/browserNotSupport'
// import {  validateStartExam } from '../../redux/Actions/exam'


import { useDispatch, useSelector } from "react-redux";
import RecentActivity from './recentActivity'
import {
    isFirefox,
    isIE,
    isEdge,
} from "react-device-detect";
import { 
    // getClassTiming, 
    getActivities, validateStartClass } from '../../redux/Actions/homeTeacher'
import { getStudentAssignments } from '../../redux/Actions/assignment'
import {
    Row, Col,
    Button,
    CardHeader,
    Container,
    Card,
    ListGroup,
    ListGroupItem, CardFooter,
} from 'reactstrap';

// import { IoIosAnalytics } from "react-icons/io";
import Slider from "react-slick";

// import noClass from '../../assets/utils/images/myicon/noClass.png'
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { faCalendarAlt, faCloudSunRain, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, } from 'reactstrap';
import noFile from '../../assets/images/noassignment.png'
import {  faCog } from '@fortawesome/free-solid-svg-icons';
import Echo from 'laravel-echo';
// import Pusher from "pusher-js";
import CardBody from 'reactstrap/lib/CardBody';

import {
    AreaChart, Area, LineChart, Line,
    ResponsiveContainer,
} from 'recharts';

import { getMyCourseDetails,listAllExpiredSubscription,getMySubscriptionDetails,setReqStudentData } from "../../redux/Actions/Student"
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CourseCard from "./CourseCard"
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
// import moment from 'moment-timezone'
import Tooltip from '@material-ui/core/Tooltip';


const assignColor = ['bg-focus', 'bg-warning', 'bg-primary', 'bg-danger', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-danger', 'bg-warning', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-warnnig', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-danger', 'bg-warnnig', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-danger']

const options = {
    broadcaster: 'pusher',
    key: "key",
    wsHost: development.socketUrl,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss']

};

const data123 = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    {name: 'Page C', uv: 2000, pv: 6800, amt: 2290},
    {name: 'Page D', uv: 4780, pv: 7908, amt: 2000},
    {name: 'Page E', uv: 2890, pv: 9800, amt: 2181},
    {name: 'Page F', uv: 1390, pv: 3800, amt: 1500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];


const data2 = [
    {name: 'Page A', uv: 5400, pv: 5240, amt: 1240},
    {name: 'Page B', uv: 7300, pv: 4139, amt: 3221},
    {name: 'Page C', uv: 8200, pv: 7980, amt: 5229},
    {name: 'Page D', uv: 6278, pv: 4390, amt: 3200},
    {name: 'Page E', uv: 3189, pv: 7480, amt: 6218},
    {name: 'Page D', uv: 9478, pv: 6790, amt: 2200},
    {name: 'Page E', uv: 1289, pv: 1980, amt: 7218},
    {name: 'Page F', uv: 3139, pv: 2380, amt: 5150},
    {name: 'Page G', uv: 5349, pv: 3430, amt: 3210},
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2
};

const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    // const examStore = useSelector(({ exam }) => exam)
    const [classStatus, setClassStatus] = useState(false)


    const StudentCourseArray = useSelector((state) =>state.student.HomeStudentCourseArray ? state.student.HomeStudentCourseArray : []);
    
    const StudentSubscriptionsArray = useSelector((state) =>state.student.SubscriptionArray ? state.student.SubscriptionArray : []);
    
    const counter = useSelector((state) =>state.student.Homecounter ? state.student.Homecounter : '');
    const purchase_details = useSelector((state) =>state.student.Homepurchase_details ? state.student.Homepurchase_details : []);
    const Expired_Array = useSelector((state) =>state.student.Expired_Array ? state.student.Expired_Array :[]);

    useEffect(()=>{
        dispatch(getMyCourseDetails(tokenData.token))
        dispatch(getMySubscriptionDetails(tokenData.token))
        dispatch(listAllExpiredSubscription(tokenData.token))

    },[])
    
    useEffect(() => {
        // dispatch(getNextClass(tokenData.token))
        // const a = window.addEventListener("resize", resize);

        if (tStore.startClassStatus === false) {

            // dispatch(getTodaysClass(tokenData.token))
            dispatch(getStudentAssignments(tokenData.token))
            dispatch(getActivities(tokenData.token))

        }


        //  dispatch(getAssignments(tokenData.token))


        if (tStore.nextClass !== 'null' && tStore.nextClass !== []) {

            const echo = new Echo(options);
            echo.channel(`classInprogress${tStore.nextClass.class_id}`).listen('classStarted', (e) => {
               

                if (e.message.class_id == tStore.nextClass.class_id) {
                    setClassStatus(true)
                }

            });
        }

        return () => {
            dispatch({ type: 'TIMER_RESET' })
            dispatch(setReqStudentData('HomeStudentCourseArray',[]))
            dispatch(setReqStudentData('SubscriptionArray',[]))
            
        }

    }, [tStore.startClassStatus])

   
    const resize = () => {
        // console.log("resized")
    }


    const history = useHistory()

    const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)
    const assignment = useSelector(({ assignments }) => assignments)
    // console.log("homeDetail", assignment)

    const joinClass = (weekId, classId,subId) => {
        if (isFirefox || isIE || isEdge) {
            browserNotSupport()
        } else {
            dispatch(validateStartClass(cb, tokenData.token, weekId, classId,subId))

        }
        // dispatch(validateStartClass(cb, tokenData.token, weekId, classId))


    }
    // const joinExam = (exam_id) => {
    //     dispatch(validateStartExam(startExam, tokenData.token, exam_id, "home"))

    // }
    const startExam = (examId) => {
       // history.push(`/dashboard/examHall/${examId}`)
        history.push(`/dashboard/instructions/${examId}`)

    }



    const [unSupportBrowser, setUnSupportBrowser] = useState(false)
    const browserNotSupport = () => {
        setUnSupportBrowser(true)
    }
    const closeUnsupportPopup = () => {
        setUnSupportBrowser(false)
        // history.push('/dashboard/overview')

    }
    const cb = (weekId, classId,subId) => {
        //props.history.push('/dashboard/')
        //alert("teacher")
        // const weekId = homeDetail.nextClass.weekly_class_id
        // const classId = homeDetail.nextClass.class_id
        // if (res.result) {
        if(subId){
            history.push(`/dashboard/join/${weekId}/${classId}/${subId}`)
        }
        else{
            history.push(`/dashboard/join/${weekId}/${classId}`)
        }
        // } else if (!res.result) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: '',
        //         text: res.message,

        //     })
        // }
    }
    const [openModal, setOpenModal] = useState(false)
    // const getClassTimes = (id) => {
    //     dispatch(getClassTiming(tokenData.token, id))
    //     setOpenModal(true)
    // }
    const closeTimingModal = () => {
        setOpenModal(false)
    }
    // const cb = (res) => {
    //     //props.history.push('/dashboard/')
    //     alert(res)

    // }

    // setState({
    //     selectedOption: null,
    // });

    // const handleChange = (selectedOption) => {
    //     setState({ selectedOption });
    // };
    // const ExamTimer = () => {

        
    //     return (
    //         examStore.upcomingExams?.length > 0 &&
           
    //         <TimerExam
    //             classname={examStore.nextExam.exam_name}
    //             date={examStore.nextExam.start_timer_till}
    //             type="exam"
    //             label={'Your next exam'}
    //             loader={examStore.nextExamValidatorLoader}
    //             // weekly_class_id={tStore.nextExam.weekly_class_id}
    //             // class_id={tStore.nextExam.class_id}
    //             // classStatus2={tStore.nextExam.status}
    //             exam_id={examStore.nextExam.exam_id}
    //             joinExam={joinExam}
    //         // active={true}
    //         // loader={tStore.timerLoader}
    //         // disablity={tStore.nextExam.length <= 0 ? true : false}
    //         // classStatus={classStatus}
    //         // role={'student'}

    //         />
           


    //     )
    // }
    // const toggle = () => {
        // setState(prevState => ({
        //     dropdownOpen: !prevState.dropdownOpen
        // }));
    // }
    // const UpcomingExams = () => {
    //     return (
    //         <Card className="main-card mb-3">
    //             <CardHeader>
    //                 <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
    //                     Upcoming Exams
    //                         </div>
    //                 <div className="btn-actions-pane-right">

    //                 </div>
    //             </CardHeader>
    //             <Table responsive borderless hover className="align-middle text-truncate mb-0">
    //                 <thead>
    //                     <tr>
    //                         <th className="text-center">#</th>
    //                         <th className="text-center">Exam Title</th>

    //                         <th className="text-center">Class</th>

    //                         <th className="text-center">Subject</th>
    //                         <th className="text-center">Date</th>
    //                         <th className="text-center">
    //                              Time 
    //                         </th>

    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {
    //                         examStore.upcomingExams?.length > 0 ? examStore.upcomingExams.map((exam, index) => {

    //                             return (

    //                                 < tr >
    //                                     <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
    //                                     <td className="text-center" style={{ width: '80px' }}>
    //                                         {/* <img width={40} className="rounded-circle"
    //                                            src={avatar1}
    //                                               alt=""/> */}
    //                                         {exam.exam_titile}
    //                                     </td>
    //                                     <td className="text-center" style={{ width: '80px' }}>
    //                                         {/* <img width={40} className="rounded-circle"
    //                                            src={avatar1}
    //                                               alt=""/> */}
    //                                         {exam.class_name}
    //                                     </td>

    //                                     <td className="text-center">
    //                                         {exam.subject_name}
    //                                     </td>
    //                                     <td className="text-center">
    //                                         <span className="pr-2 opacity-6">
    //                                             <FontAwesomeIcon icon={faCalendarAlt} />
    //                                         </span>
    //                                         {exam.date ? exam.date : "null"}
    //                                     </td>
    //                                     <td className="text-center">
    //                                         <span className="pr-2 opacity-6">
    //                                             <FontAwesomeIcon icon={faCloudSunRain} />
    //                                         </span>
    //                                         {exam.time ? exam.time : "null"}
    //                                     </td>

    //                                 </tr>
    //                             )
    //                         }) : null
    //                     }

    //                 </tbody>

    //             </Table>
    //             {
    //                 examStore.upcomingExams?.length === 0 ?
    //                     <p style={{ textAlign: 'center' }}> <img src={noClass} width="200px" height="150px" /><p style={{ color: 'gray', textAlign: 'center' }}>There is no exams</p></p>
    //                     : null
    //             }

    //             <CardFooter className="d-block p-4 text-center">
    //                 <Link to="/dashboard/show-class">
    //                     <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
    //                         <span className="mr-2 opacity-7">
    //                             <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
    //                         </span>
    //                         <span className="mr-1">
    //                             View Full TimeTable
    //                                 </span>
    //                     </Button>
    //                 </Link>
    //             </CardFooter>
    //         </Card>
    //     )
    // }

    // const { selectedOption } = state;
    // const { data } = state;

    // const settings = {
    //     className: "",
    //     centerMode: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     speed: 500,
    //     dots: true,
    // };
    // const dateInFuture = '2029-12-31 23:59:59'   
    // const [sampleTime, setSampleTime] = useState({ date: "05 15 2020,10:00 pm", name: "Old" })

    // const fullScreen = () => {
    //     alert("key pressed")
    // }

    // const JoinClass=(val)=>{
    //     history.push(`/dashboard/join/${val.weekly_class_id}/${val.class_id}`)
    // }


    return (
        <Fragment>
       
               <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
            <Container fluid>
                <Row>
                    <Col xs={12}>
                    <Row>
                        <Col sm="12" md="6" xl="4">
                            <div className="card mb-3 widget-chart" onClick={()=>{history.push('/dashboard/myCourse')}}>
                                <div className="widget-chart-content" >
                                    <div className="icon-wrapper rounded">
                                        <div className="icon-wrapper-bg bg-warning"/>
                                        <i className="lnr-sync text-warning"/>
                                    </div>
                                    <div className="widget-numbers">                
                                        {
                                            counter ? counter.nof_active_course ? counter.nof_active_course:'-':'-'
                                        }
                                    </div>
                                    <div
                                        className="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                                        No.of Active Courses
                                    </div>
                                   
                                </div>
                                <div className="widget-chart-wrapper" >
                                    <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                        <LineChart data={data123}
                                                   margin={{top: 0, right: 25, left: 25, bottom: 0}}>
                                            <Line type='monotone' dataKey='pv' stroke='var(--warning)' strokeWidth={3}/>
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="6" xl="4">
                            <div className="card mb-3 widget-chart" onClick={()=>{history.push('/dashboard/myCourse')}}>
                                <div className="widget-chart-content">
                                    <div className="icon-wrapper rounded">
                                        <div className="icon-wrapper-bg bg-danger"/>
                                        <i className="lnr-graduation-hat text-danger"/>
                                    </div>
                                    <div className="widget-numbers">
                                        {
                                            counter ? counter.nof_total_course ? counter.nof_total_course:'-':'-'
                                        }
                                    </div>
                                    <div
                                        className="widget-subheading fsize-1 pt-2 opacity-10 text-danger font-weight-bold">
                                        Total Course
                                    </div>
                                   
                                </div>
                                <div className="widget-chart-wrapper">
                                    <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                        <AreaChart data={data2}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Area type='monotoneX' dataKey='uv' strokeWidth='2' stroke='var(--danger)'
                                                  fill='var(--danger)' fillOpacity='.3'/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="12" xl="4">
                            <div className="card mb-3 widget-chart" onClick={()=>{history.push('/dashboard/tickets')}}>
                                <div className="widget-chart-content">
                                    <div className="icon-wrapper rounded">
                                        <div className="icon-wrapper-bg bg-info"/>
                                        <i className="lnr-tag text-info"/>
                                    </div>
                                    <div className="widget-numbers text-danger">
                                        {
                                            counter ? counter.nof_active_ticket ? counter.nof_active_ticket:'-':'-'
                                        }
                                    </div>
                                    <div
                                        className="widget-subheading fsize-1 pt-2 opacity-10 text-info font-weight-bold">
                                        No.of Active Tickets
                                    </div>
                                    
                                </div>
                                <div className="widget-chart-wrapper">
                                    <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                        <AreaChart data={data123}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Area type='stepAfter' dataKey='uv' strokeWidth='2' stroke='var(--info)'
                                                  fill='var(--info)' fillOpacity='.4'/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </Col>
                </Row>
               
                
                        {/* <Timer
                            classname={homeDetail.nextClass.class_name}
                            date={'03 26 2021,02:45 pm'}
                            weekly_class_id={homeDetail.nextClass.weekly_class_id}
                            class_id={homeDetail.nextClass.class_id}
                            classStatus2={homeDetail.nextClass.status}
                            joinClass={joinClass}
                            active={true}
                            loader={tStore.timerLoader}
                            disablity={homeDetail.nextClass.length <= 0 ? true : false}
                            classStatus={classStatus}
                            role={'student'}
                            label={'Your next class'}
                        /> */}

                

                <Card className="main-card mb-3">
                    <CardHeader>
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            Today's Courses
                            </div>
                        <div className="btn-actions-pane-right">

                        </div>
                    </CardHeader>
                  
                        <div className='smooth-scroll' style={{maxHeight:'800px',overflow:'auto'}} >
                    <Table responsive borderless hover className="align-middle text-truncate mb-0">
                        <thead >
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Category</th>
                                <th className="text-center">Course Name</th>
                                <th className="text-center">Class Name</th>
                                <th className="text-center">Next Class</th>
                                <th className="text-center">Timing</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">
                                    Timer<AccessTimeIcon style={{color:'red',fontSize:'16px'}}/></th>
                                {/* <th className="text-center">Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                                {
                                    StudentCourseArray.map((val,ky)=>{
                                        
                                        let dat=val.date
                                        let day=dat.slice(0,2)
                                        return(
                                            <tr key={ky}>
                                                <td className="text-center text-muted" style={{ width: '80px' }}>{ky+1}</td>
                                                <td className="text-center" style={{ width: '80px' }}>{val.course_category}</td>

                                                <td className="text-center">{val.course_name}</td>

                                                <td className="text-center">{val.class_name}</td>
                                                <td className="text-center"> {day}th {val.day}</td>
                                                <td className="text-center"> {val.timing}</td>
                                                <td className="text-center">
                                                    <div className={val.status === 'inprogress' ? 'badge badge-pill badge-warning'
                                                        : val.status === 'completed' ? 'badge badge-pill badge-danger'
                                                            : val.status === 'ongoing' ? 'badge badge-pill badge-success'
                                                                : val.status === 'pending' ? 'badge badge-pill badge-info' : null}>
                                                        {val.status}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                {
                                                    val.edit_status===1 ?
                                                    <Tooltip arrow title='Course is being rescheduled'>
                                                        <span style={{color:'red',cursor:'pointer'}}>Rescheduling</span>
                                                    </Tooltip>
                                                    :
                                                    <Timer
                                                        classname={val.class_name}
                                                        date={val.timer} 
                                                        // date={new_timer}
                                                        status={val.status}
                                                        weekly_class_id={val.weekly_class_id}
                                                        class_id={val.class_id}
                                                        joinClass={joinClass}
                                                        loader={tStore.timerLoader}
                                                        disablity={homeDetail.nextClass.length <= 0 ? true : false}
                                                    />
                                                }
                                                   
              
                                                </td>
                                                
                                        </tr>
                                        )
                                    })
                                    
                                }
                            
                        </tbody>
                    </Table>
                       </div>
                   
                    {/* <CardFooter className="d-block p-4 text-center">
                        <Link to="/dashboard/show-class">
                            <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                                <span className="mr-2 opacity-7">
                                    <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                                </span>
                                <span className="mr-1">
                                    View Full TimeTable
                                    </span>
                            </Button>
                        </Link>
                    </CardFooter> */}
                </Card>
             
             {/* subscriptions */}
             
             {
                 StudentSubscriptionsArray.length > 0 ? 
                 <Card className="main-card mb-3">
                    <CardHeader>
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            Today's Subscription classes
                            </div>
                        <div className="btn-actions-pane-right">

                        </div>
                    </CardHeader>
                  
                        <div className='smooth-scroll' style={{maxHeight:'800px',overflow:'auto'}} >
                    <Table responsive borderless hover className="align-middle text-truncate mb-0">
                        <thead >
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Category</th>
                                <th className="text-center">Subscription Name</th>
                                <th className="text-center">Class Name</th>
                                <th className="text-center">Next Class</th>
                                <th className="text-center">Timing</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">
                                    Timer<AccessTimeIcon style={{color:'red',fontSize:'16px'}}/></th>
                                {/* <th className="text-center">Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                                {
                                    StudentSubscriptionsArray.map((val,ky)=>{
                                        
                                        let dat=val.date
                                        let day=dat.slice(0,2)
                                        return(
                                            <tr key={ky}>
                                                <td className="text-center text-muted" style={{ width: '80px' }}>{ky+1}</td>
                                                <td className="text-center" style={{ width: '80px' }}>{val.course_category}</td>

                                                <td className="text-center">{val.subscription_name}</td>

                                                <td className="text-center">{val.class_name}</td>
                                                <td className="text-center"> {day}th {val.day}</td>
                                                <td className="text-center"> {val.timing}</td>
                                                <td className="text-center">
                                                    <div className={val.status === 'inprogress' ? 'badge badge-pill badge-warning'
                                                        : val.status === 'completed' ? 'badge badge-pill badge-danger'
                                                            : val.status === 'ongoing' ? 'badge badge-pill badge-success'
                                                                : val.status === 'pending' ? 'badge badge-pill badge-info' : null}>
                                                        {val.status}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        val.expiry_status ? 
                                                        <Tooltip arrow title='Renew subscription to join class'>
                                                            <span style={{color:'red',cursor:'pointer'}}>Expired</span>
                                                        </Tooltip>
                                                        :
                                                        <Timer
                                                            classname={val.class_name}
                                                            date={val.timer} 
                                                            // date={new_timer}
                                                            status={val.status}
                                                            weekly_class_id={val.weekly_class_id}
                                                            class_id={val.class_id}
                                                            joinClass={joinClass}
                                                            loader={tStore.timerLoader}
                                                            disablity={homeDetail.nextClass.length <= 0 ? true : false}
                                                            sub_id={val.subscription_id}
                                                        /> 
                                                    }
                                                                                                      
              
                                                </td>
                                                
                                        </tr>
                                        )
                                    })
                                    
                                }
                            
                        </tbody>
                    </Table>
                       </div>
                
                </Card>
                :''

             }
                

                {
                    Expired_Array.length > 0 ?
                    <Card className="main-card mb-3">
                        <CardHeader>
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                Expired Subscriptions
                                </div>
                            <div className="btn-actions-pane-right">

                            </div>
                        </CardHeader>
                  
                        <div className='smooth-scroll' style={{maxHeight:'800px',overflow:'auto'}} >
                            <Table responsive borderless hover className="align-middle text-truncate mb-0">
                                <thead >
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Order ID</th>
                                        <th className="text-center">Subscription Name</th>
                                        <th className="text-center">Subscription Type</th>
                                        <th className="text-center">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {
                                            Expired_Array.map((val,ky)=>{
                
            
                                                return(
                                                    <tr key={ky}>
                                                        <td className="text-center text-muted" style={{ width: '80px' }}>{ky+1}</td>
                                                        <td className="text-center">#{val.order_id}</td>
                                                        <td className="text-center" style={{ width: '80px' }}>{val.subscription_name}</td>
                                                        <td className="text-center">{val.subscription_type}</td>
                                                        <td className="text-center"> {val.course_amount} {val.currency_code}</td>
                                                </tr>
                                                )
                                            })
                                            
                                        }
                                    
                                </tbody>
                            </Table>
                        </div>
                        <CardFooter style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                            <Button 
                                className='btn-pill btn-shadow btn-wide' color="primary" style={{paddingRight:'10px',paddingLeft:'10px'}}
                                onClick={()=>{history.push('/dashboard/subscriptions/expired')}}
                            >
                                <span className="mr-2 opacity-7">
                                    <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                                </span>
                                    View All
                            </Button>   
                        </CardFooter>
                       </Card>
                     :
                    ''
                } 
                
                <Row>
                    <Col xs={12}>
                        {
                            purchase_details.length > 0 ?
                            <Card>
                                <CardHeader className="card-header-tab z-index-6">
                                    <div
                                        className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                                        Recently Purchased Courses
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Slider {...settings}>
                                        {
                                            purchase_details.map((val,ky)=>{
                                                return(
                                                <div key={ky} style={{backgroundColor:'red'}}>
                                                    <div className="slider-item" style={{textAlign:'left'}}>
                                                        <CourseCard 
                                                            url={val.banner_url} 
                                                            name={val.course_name}
                                                            category={val.course_category}
                                                        />
                                                        {/* <span>{val.course_name}</span> */}
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }
                                                            
                                    </Slider>
                                </CardBody>
                            </Card>
                            :''
                        }
                        
                    </Col>
                </Row>
        
                
        
                <Row style={{marginTop:'20px'}}>
                    <Col sm="12" lg="6">
                        <LoadingOverlay tag="div" active={assignment.loader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <Card className="card-hover-shadow-2x mb-3">
                                <CardHeader className="card-header-tab">
                                    <div
                                        className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-database icon-gradient bg-malibu-beach"> </i>
                                       Assignment
                                    </div>


                                </CardHeader>
                                <div className="scroll-area-lg">
                                    <PerfectScrollbar>
                                        <div className="p-4">
                                            <ListGroup className="todo-list-wrapper" flush>

                                                {Array.isArray(assignment.assignmentss) ? assignment.assignmentss.length === 0 ? ' ' : null : null}
                                                {assignment.assignmentss ?
                                                    assignment.assignmentss.map((assignments, index) => {
                                                        return (
                                                        

                                                            <Link style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }} to={`/dashboard/assignments/${assignments.assignment_id}/${assignments.class_id}`}>


                                                                <ListGroupItem>
                                                                    <div className={`todo-indicator ${assignColor[index]}`} />


                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left mr-2">

                                                                            </div>
                                                                            <div className="widget-content-left flex2">
                                                                                <div className="widget-heading">
                                                                                    {assignments.title}
                                                                                </div>
                                                                                <div className="widget-subheading">
                                                                                    By {assignments.teacher}

                                                                                </div>
                                                                                <p style={{ fontSize: '11px', color: 'rgb(9, 93, 168)', opacity: 0.8, marginBottom: '2px' }}>Deadline : {assignments.deadline}</p>

                                                                            </div>

                                                                            <div className="widget-content-right ml-3">
                                                                                <div className="badge badge-pill badge-warning">{assignments.subject_name} </div>

                                                                            </div>
                                                                            {/* <br />
                                                                            <div className="widget-content-right ml-3">

                                                                                <div className="badge badge-pill badge-danger">Deadline : {assignments.deadline} </div>

                                                                            </div> */}

                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>




                                                            </Link>


                                                        )
                                                    }) : []
                                                }
                                                {assignment.assignmentss.length === 0 ?
                                                    <p style={{ textAlign: 'center', paddingTop: '40px' }}><img src={noFile} width="100%" height="100%" alt='' /></p>
                                                    : null}

                                            </ListGroup>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                                <CardFooter className="d-block text-right">
                                    {/* <Button size="sm" className="mr-2" color="link">Cancel</Button> */}
                                    <a href="#/dashboard/assignments"> <Button color="primary">View All Assignments</Button></a>
                                </CardFooter>
                            </Card>
                        </LoadingOverlay>
                    </Col>

                    <Col sm="12" lg="6">
                        <LoadingOverlay tag="div" active={tStore.activityLoader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <RecentActivity
                                activities={tStore.activities}
                            />
                        </LoadingOverlay>
                    </Col>


                </Row>
            </Container>
            <Modals
                open={openModal}
                handleClose={closeTimingModal}

            >
                <TimeShedule
                    data={tStore.specificClassTime}
                />
            </Modals>
            <Modals
                open={unSupportBrowser}
                handleClose={closeUnsupportPopup}
                title={`Warning`}
            >
                <BrowserNotSupport />

            </Modals>
            </CSSTransitionGroup>
           
        </Fragment >
    )

}
export default AnalyticsDashboard1