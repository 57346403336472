const initialState = {

    createGroupLoader: false,
    groupListLoader: false,
    groupList: [],
    assignTeahersLoader: false,
    assignTeahersList: [],
    sourceGroupStudentsLoader: false,
    sourceGroupStudents: [],
    destinationGroupStudentsLoader: false,
    destinationGroupStudents: [],
    copyButtonLoader: false,
    moveButtonLoader: false,


}

const groupReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'MIGRATE_GROUP_STUDENT_MOVE_REQUEST': {
            return {
                ...state, moveButtonLoader: true,

            }
        }
        case 'MIGRATE_GROUP_STUDENT_MOVE_SUCCESS': {

            //const sourceList=state.sourceGroupStudents.filter
            return {
                ...state, moveButtonLoader: false,
                sourceGroupStudents: payload.source_group,
                destinationGroupStudents: payload.destination_group,

            }
        }
        case 'MIGRATE_GROUP_STUDENT_COPY_REQUEST': {
            return {
                ...state, copyButtonLoader: true
            }
        }
        case 'MIGRATE_GROUP_STUDENT_COPY_SUCCESS': {
            return {
                ...state,
                destinationGroupStudents: payload.destination_group,
                copyButtonLoader: false,
                sourceGroupStudents: payload.source_group
            }
        }
        case 'POPULATE_SOURCE_GROUP_STUDENT_REQUEST': {
            return {
                ...state, sourceGroupStudentsLoader: true
            }
        }
        case 'POPULATE_SOURCE_GROUP_STUDENT_SUCCESS': {
            return {
                ...state, sourceGroupStudentsLoader: false, sourceGroupStudents: payload
            }
        }
        case 'POPULATE_DESTINATION_GROUP_STUDENT_REQUEST': {
            return {
                ...state, destinationGroupStudentsLoader: true
            }
        }
        case 'POPULATE_DESTINATION_GROUP_STUDENT_SUCCESS': {
            return {
                ...state, destinationGroupStudentsLoader: false, destinationGroupStudents: payload
            }
        }

        case 'POPULATE_REGISTERED_TEACHER_REQUEST': {
            return {
                ...state, assignTeahersLoader: true
            }
        }
        case 'POPULATE_REGISTERED_TEACHER_SUCCESS': {
            return {
                ...state, assignTeahersLoader: false, assignTeahersList: payload
            }
        }
        case 'EDIT_GROUP_REQUEST': {

            return {
                ...state, groupListLoader: true
            }
        }
        case 'EDIT_GROUP_SUCCESS': {
            let filtered = state.groupList.filter(group => group.group_id !== payload.group_id)
            filtered = [payload, ...filtered]
            return {
                ...state, groupListLoader: false, groupList: filtered
            }
        }
        case 'DETELE_GROUP_REQUEST': {

            return {
                ...state, groupListLoader: true
            }
        }
        case 'DETELE_GROUP_SUCCESS': {
            const filtered = state.groupList.filter(row => row.group_id !== payload)
            return {
                ...state, groupList: filtered, groupListLoader: false
            }
        }
        case 'CREATE_GROUP_REQUEST': {
            return {
                ...state, createGroupLoader: true
            }
        }
        case 'CREATE_GROUP_SUCCESS': {
            return {
                ...state, createGroupLoader: false, groupList: [payload, ...state.groupList]
            }
        }
        case 'POPULATE_GROUP_REQUEST': {
            return {
                ...state, groupListLoader: true
            }
        }
        case 'POPULATE_GROUP_SUCCESS': {
            return {
                ...state, groupListLoader: false, groupList: payload
            }
        }
        case 'RESET_SOURCE_STUDENT_LIST': {
            return {
                ...state, sourceGroupStudents: []
            }
        }
        case 'RESET_DESTINATION_STUDENT_LIST': {
            return {
                ...state, destinationGroupStudents: []
            }
        }
        case 'RESET_ASSIGN_TEACHERS_LIST': {
            return {
                ...state, assignTeahersList: []

            }
        }
        case 'SET_GROUPDATA_DATA':{
            return {
                ...state, ...payload
            }
        }
        default:
            return state
    }

}

export default groupReducer