import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

// import { Row, Col, Card, Button } from "reactstrap";
// import { Loader, Types } from 'react-loaders';
// import LoadingOverlay from 'react-loading-overlay'
// import TitleComponent1 from './PageTitleExamples/Variation1'
// import TitleComponent2 from './PageTitleExamples/Variation2'
// import TitleComponent3 from './PageTitleExamples/Variation3'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

const PageTitle = (props) => {


    const {
        enablePageTitleIcon,
        enablePageTitleSubheading,
        assignmentStore,
        heading,
        icon,
        subject,
        deadline,
        buttonAction,
        background,
        color,
        button,
        lastDataColor,
        loader
    } = props;

    // var arr = [<TitleComponent1 />, <TitleComponent2 />, <TitleComponent3 />]
    // var arr = [<TitleComponent3 />]


    return (



        <div className="app-page-title" style={{ margin: '0px', borderRadius: '6px', backgroundColor: background, color: "white" }}>
            <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div
                        className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}>
                        <i className={icon} />
                    </div>
                    <div className="jms-opacity-overwrite" style={{ fontWeight: 600 }}>
                        <span style={{ textTransform: 'uppercase' }}> {heading}</span>
                        <div style={{ color: color, fontWeight: 600, textTransform: 'capitalize' }} className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}>
                            {subject}&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; <span style={{ color: lastDataColor, fontSize: 12 }}>{"Last date of submission "} <strong>{deadline}</strong></span>
                        </div>
                    </div>
                </div>
                <div className="page-title-actions">
                    <LaddaButton className="mb-2 mr-2 btn btn-dark"
                        loading={loader}
                        type="button"
                        onClick={() => buttonAction()}
                        data-style={ZOOM_IN}
                        style={{ color: '#09315d', backgroundColor: '#99DDFF', borderColor: '#99DDFF' }}
                    >
                        {
                            button === 'ADD MARK' ?
                                <i className="lnr-plus-circle btn-icon-wrapper"> </i>
                                :
                                <i className="lnr-download btn-icon-wrapper"> </i>
                        }
                        &nbsp;{button}

                    </LaddaButton>
                </div>
            </div>
        </div>



    );

}
const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});



export default connect(mapStateToProps)(PageTitle);