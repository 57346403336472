import React, { useEffect,useState } from 'react';
import PageTitle from "../../HeaderTitle/PageTitle";
import nodata from '../../LandingPageNew/images/nodata.png'
import subscriptionimage from "../../LandingPageNew/images/subscription_image.png"

import dateicon from "../Viewcourse/icons/coursedate.png"
import video from "../Viewcourse/icons/totalvideoclass.png"
import live from "../Viewcourse/icons/totalliveclass .png"
// import grade from "../Viewcourse/icons/grade.png"
// import subject from "../Viewcourse/icons/subject.png"
// import seats from "../Viewcourse/icons/seats.png"
// import enroll from "../Viewcourse/icons/enrolldate.png"
// import status from "../Viewcourse/icons/coursestatus.png"
import fees from "../Viewcourse/icons/fees.png"
import type from "../Viewcourse/icons/type.png"
// import classtype from "../Viewcourse/icons/classtype.png"
import {
    Input, InputGroup, 
    Row, Col,
    // Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    CardHeader,
    CardFooter,
    // ListGroup,
    // ListGroupItem,
    Card, CardBody,
    DropdownItem,
    Form

} from 'reactstrap';

//import TabbedContent from './Tabbed';

import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
// import EmailIcon from '@material-ui/icons/Email';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
// import PersonIcon from '@material-ui/icons/Person';
// import PerfectScrollbar from 'react-perfect-scrollbar';

import { faArrowRight} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import CountUp from 'react-countup';
import { listAllSubscriptions,deleteSubscription,setSubscriptionData } from "../../../redux/Actions/subscription-action"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import LoadingOverlay from 'react-loading-overlay';
import SweetAlert from 'sweetalert-react';
import "../Viewcourse/card.css"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Badge from 'reactstrap/lib/Badge';



function SubscriptionList() {


    const dispatch = useDispatch();
    const history = useHistory();

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const [pagestd,setPage]=useState(1)
    // const [clear,setClear]=useState(false)
    // const [open,setOpen]=useState(false)
    // const [course,setCoure]=useState('')

    const Subscription_Details = useSelector((state) => state.Subscription.Subscription_Details ? state.Subscription.Subscription_Details : '');
    const Subscription_Array = useSelector((state) => state.Subscription.Subscription_Array ? state.Subscription.Subscription_Array : []);

    const vendor_nextpage = useSelector((state) => state.Subscription.Subscription_next_page_url ? state.Subscription.Subscription_next_page_url : '');
    const vendor_prevpage = useSelector((state) => state.Subscription.Subscription_first_page_url ? state.Subscription.Subscription_first_page_url : '');
    const subloader = useSelector((state) => state.Subscription.subloader ? state.Subscription.subloader : false);
   
    // const purchased_student_loader = useSelector((state) => state.superadmin.purchased_student_loader ? state.superadmin.purchased_student_loader : false);
    // const purchased_list = useSelector((state) => state.superadmin.purchased_list ? state.superadmin.purchased_list : []);

    const page = useSelector((state) => state.Subscription.subscription_page ? state.Subscription.subscription_page : 1);
    const search = useSelector((state) => state.Subscription.sub_search_term ? state.Subscription.sub_search_term : '');

    const StudentList=(val)=>{
       // history.push(`/dashboard/course/${val.course_id}/student-list`)
        history.push(`/dashboard/subscriptions/student-list/${val.id}`)
        // setCoure(val.course_id)
        // dispatch(getCoursePurchasedStudent(tokenData.token,val.course_id,pagestd))
        // setOpen(true)
    }

    useEffect(() => {
        window.scrollTo({top:0,behavior:'smooth'});
        dispatch(listAllSubscriptions(tokenData.token,page,search,"",cb))
      }, [page])


    const NextPage=()=>{
        dispatch(setSubscriptionData('subscription_page',page+1))
    }
    const PreviousPage=()=>{
        dispatch(setSubscriptionData('subscription_page',page-1))
    }


    const viewcoursedetail = (val) => {
        history.push('/dashboard/subscription/' + val.id)

      }

    const editcourse = (val) => {
    history.push('/dashboard/editSubscription/' + val.id)
    }
    

    const DeleteCourse = (val) => {

        Swal.fire({
          icon: 'warning',
          title: 'Are you sure !',
    
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "CONFIRM",
          cancelButtonText: "CANCEL",
    
        }).then((result) => {
          if (result.value) {
    
            dispatch(deleteSubscription(tokenData.token, val.id, cb))
    
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Delete action cancelled',
              'error'
            )
          }
        })
      }

const [successModal, setSuccessModal] = useState(false);


const cb = () => {

    dispatch(setSubscriptionData('vendor_searched',false))
    dispatch(setSubscriptionData('vendor_search_term',''))

    if(page===1){
        dispatch(listAllSubscriptions(tokenData.token,1,'',''))
    }
    else{
        dispatch(setSubscriptionData('subscription_page',1))
    }
    setSuccessModal(true)
}

const handleCloseSuccesstModal = () => {
    
    setSuccessModal(false)
}

const CreateVideoSession = (val) => {
    history.push('/dashboard/subscription/createVideoSession/' + val.id)
}
  const CreateLiveClass = (val) => {
    history.push('/dashboard/subscription/createLiveClass/' + val.id)
}


const HandleChange=(e)=>{
    e.preventDefault()
    if(e.target.value){
        dispatch(setSubscriptionData('sub_search_term',e.target.value))
    }
    else{
        dispatch(setSubscriptionData('sub_search_term',''))
        if(page===1){
            dispatch(listAllSubscriptions(tokenData.token,1,'',''))
        }
        else{
            dispatch(setSubscriptionData('subscription_page',1))
        }
    }

}

const Searchcourse=(e)=>{
   e.preventDefault()
    if(page===1){
        dispatch(listAllSubscriptions(tokenData.token,1,search,''))
    }
    else{
        dispatch(setSubscriptionData('subscription_page',1))
    }
}



// const Preview=(val)=>{  
//     history.push(`/dashboard/showCourse/Preview/${val.course_id}`)
// }

const demo = () => {
    return (
        <div>
            <div className="pane-right">
                <Form onSubmit={Searchcourse} >
                <InputGroup>
                    <Input type='search' placeholder="Search..." onChange={HandleChange} value={search ? search:''} style={{marginRight:'5px'}}/>
                    <Button color='primary' variant='contained' onClick={Searchcourse} size='small' disabled={search ? false:true} style={{marginRight:'5px'}}>
                            apply
                    </Button>
     
                    
  
                </InputGroup>
                </Form>
            </div>
        </div>
    )
}



    return (
        <div>
        {
            Subscription_Details ? 
            <>
            <PageTitle
                // style={{ display: "none" }}
                heading="Show Subscriptions"
                subheading="List of all Subscriptions "
                icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
                actions={demo}
            />
            <LoadingOverlay
                active={subloader}
                spinner
                text='Loading your content...'
            >
                {
                    Subscription_Array.length===0 ?
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={nodata} width='600px' height='400px' alt=''/>
                    </div>
                    :
                 
            <Row style={{ margin: "10px" }} lg="6" xl="4">
                    {
                        Subscription_Array.map((val,ky)=>{
                            return(
                                <Card style={{ marginRight:"20px", width: "300px" }} className="mb-3 " key={ky}>
                                        <CardHeader className="card-header-tab">
                                            <div style={{ width: "250px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "clip" }}
                                                className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> </i>
                                                    {val.subscription_name}
                                                </div>
   
                                                    <div className="btn-actions-pane-right actions-icon-btn">
                                                        <UncontrolledButtonDropdown direction='left'>
                                                            <DropdownToggle className="btn-icon btn-icon-only" color="link">
                                                                <i className="pe-7s-config btn-icon-wrapper" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                                                                <DropdownItem header>Actions</DropdownItem>

                                                                    {/* <DropdownItem onClick={()=>{Preview(val)}}>
                                                                        <i className="dropdown-icon lnr-frame-expand"> </i>
                                                                        <span>Preview Subscription</span>
                                                                    </DropdownItem> */}
                                                                {
                                                                     (val.live_class_enabled)  ?
                                                                    
                                                                    <DropdownItem onClick={()=>{CreateLiveClass(val)}}>
                                                                        <i className="dropdown-icon lnr-inbox"> </i>
                                                                        <span>Add Live Class</span>
                                                                    </DropdownItem>

                                                                    : ''
                                                                }
                                                                
                                                                {
                                                                     (val.ondemand_video_enabled)  ?
                                                                    
                                                                    <DropdownItem onClick={()=>{CreateVideoSession(val)}}>
                                                                        <i className="dropdown-icon lnr-file-empty"> </i>
                                                                        <span>Add Video Class</span>
                                                                    </DropdownItem>
                                                                    
                                                                    :
                                                                    ''
                                                                }
                                                                 <DropdownItem onClick={()=>{StudentList(val)}}>
                                                                        <i className="dropdown-icon lnr-user"> </i>
                                                                        <span>Student List</span>
                                                                    </DropdownItem>
                                                                
                                                                <div className="p-1 text-right">
                                                                    {/* {
                                                                        (val.request_status === "requested" || (val.request_status === "approved" && val.edit_status===0) ) ?
                                                                        ''
                                                                        :
                                                                        <> */}
                                                                            <DropdownItem divider />
                                                                            <Button color='primary' size='small' variant='contained' style={{marginRight:'5px'}} onClick={()=>{editcourse(val)}}>Edit</Button>
                                                                            <Button color='secondary' size='small' variant='contained' onClick={()=>{DeleteCourse(val)}}>Delete</Button>
                                                                        {/* </>
                                                                    }
                                                                     */}
                                                                </div>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </div>
                                                
                                            
                                        </CardHeader>
                                        <div style={{position:'relative'}} className="image-clean recntigcr">
                                            <img  alt="banner" width="100%" src={subscriptionimage} height='260px' ></img>    
                                        </div>
                                        <CardBody className="pt-2 pb-0">
                                        <Row>
                                            
                                            <Col xs={12} sm={6}>
                                                <Badge color='warning'>{val.product_code}</Badge>
                                            </Col>
                                            
                                        </Row>
                                            <div style={{height:'350px'}}>
                                                <Row>
                                                <Col xs={12}>
                                                    <span style={{fontSize:'16px',fontWeight:'700',color:'#333'}}>
                                                        {val.subscription_name} 
                                                    </span>
                                                </Col>                                             
                                                 <Col xs={7} style={{marginTop:'5px'}}>
                                                        <img src={fees} width='20px' height='20px' alt=''/>   
                                                        <span className='headcourse'>Subscription Fee</span>
                                                    </Col>
                                                    <Col xs={5}  style={{marginTop:'5px',fontSize:'15px'}}>{val.subscription_fee} {val.currency_code}</Col>
                                                    
                                                    <Col xs={7} style={{marginTop:'5px'}}>
                                                        <img src={type} width='20px' height='20px' alt=''/>   
                                                        <span className='headcourse'>Subscription Type</span>
                                                    </Col>
                                                    <Col xs={5}  style={{marginTop:'5px',fontSize:'15px'}}>{val.subscription_type}</Col>


                                                    {/* <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={subject} width='20px' height='20px' />   

                                                            <span className='headcourse'>Subject</span>
                                                        </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.course_subject_name}</Col> */}

                                                    {
                                                        val.live_class_enabled ? 
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={live} width='20px' height='20px' alt=''/>   
                                                            <span className='headcourse'>Total live Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.live_class_count}</Col>

                                                        {/* <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={classtype} width='20px' height='20px' />   
                                                            <span className='headcourse'>Class Type</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.class_type ? val.class_type:'-'}</Col> */}

                                                        </>
                                                        :
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={live} width='20px' height='20px' alt=''/>   
                                                            <span className='headcourse'>Total live Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>-</Col>

                                                        {/* <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={classtype} width='20px' height='20px' />   
                                                            <span className='headcourse'>Class Type</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>-</Col> */}
                                                        </>
                                                    }
                                                    
                                                    {
                                                        val.ondemand_video_enabled ?
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={video} width='19px' height='19px' alt=''/>   

                                                        <span className='headcourse'>Total video Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.ondemand_video_count}</Col>
                                                        </>
                                                        :
                                                        <>
                                                        <Col xs={7} style={{marginTop:'8px'}}>
                                                        <img src={video} width='19px' height='19px' alt=''/>   

                                                        <span className='headcourse'>Total video Class</span>
                                                        </Col>
                                                        <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>-</Col>
                                                        </>
                                                    }

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={dateicon} width='20px' height='20px' alt=''/>   
                                                        <span className='headcourse'>Start Date</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.subscription_start_date}</Col>

                                                    <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={dateicon} width='20px' height='20px' alt=''/>   
                                                        <span className='headcourse'>End Date</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px',fontSize:'15px'}}>{val.subscription_end_date}</Col>

                                                    {/* <Col xs={7} style={{marginTop:'8px'}}>
                                                    <img src={status} width='20px' height='20px' />   
                                                        <span className='headcourse'>Course Status</span>
                                                    </Col>
                                                    <Col xs={5} style={{marginTop:'8px'}}>
                                                    {
                                                        val.course_status===1 ?
                                                        <span style={{color:'green',fontSize:'15px',fontWeight:'bold'}}>ACTIVE</span>
                                                        :
                                                        <span style={{color:'red',fontSize:'15px',fontWeight:'bold'}}>IN ACTIVE</span>

                                                    }
                                                    </Col> */}
                                                </Row>
                                                  
                                            </div>
                                        </CardBody>
                        <CardFooter className="d-block text-center rm-border">
                            <Button color="secondary" variant='contained'  size='medium' onClick={()=>{viewcoursedetail(val)}}>
                                View Full Details
                                            <span className="text-white pl-2 opacity-3">
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                            </Button>
                        </CardFooter>
                    </Card>   
                            )
                        })
                    }
                    
            </Row>
            }
            {
                Subscription_Array.length > 0 && (vendor_prevpage || vendor_nextpage) ? 
                <Row>
                <Col xs={12}>
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div style={{width:'25%',display:'flex',justifyContent:'space-evenly'}}>

                        <Button 
                            color="primary" 
                            onClick={PreviousPage}
                            disabled={vendor_prevpage ? false :true}
                            variant='contained'
                            // size='small'
                            >
                                Prev
                            </Button>


                            <Button 
                            color="primary" 
                            onClick={NextPage}
                            disabled={vendor_nextpage ? false :true}
                            variant='contained'
                            // size='small'
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            :''

            }
            
            </LoadingOverlay>


            <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Deleted successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />

        {/* <Model
          open={open}
          handleClose={CloseModel}
          title={'Student List'}
      >
          {
            purchased_student_loader ? 
            <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

            </div>
            :
            <>
            {
                purchased_list.length > 0 ?
                <>
                        <Table  aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Student <span><PersonIcon style={{fontSize:'15px'}} /> </span></TableCell>
                                    <TableCell>Email <span><EmailIcon  style={{fontSize:'15px'}} /> </span></TableCell>
                                    <TableCell>Phone <span><PhoneAndroidIcon  style={{fontSize:'15px'}}/> </span></TableCell>
                                </TableRow>
                            </TableHead>
                
                            <TableBody>
                            {
                            purchased_list.map((val,ky)=>{
                                return(
                                <TableRow key={ky}>
                                    <TableCell>{val.first_name}</TableCell>
                                    <TableCell>{val.email}</TableCell>
                                    <TableCell>{val.parent_mobile}</TableCell>
                                </TableRow>
                                )
                            })
                            }
                        </TableBody>
                        
                    </Table>
                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                        <div style={{width:'50%',display:'flex',height:'50px',justifyContent:'flex-end'}}>

                            <span style={{marginTop:'13px'}}>{from}-{to}</span>
                            <Button
                                onClick={PreviousPageStudent}
                                disabled={prev_page_student ? false :true}
                            >
                                <NavigateBeforeIcon />
                            </Button>
                            <Button
                                onClick={NextPageStudent}
                                disabled={next_page_student ? false :true}
                            >
                                <NavigateNextIcon />
                            </Button>
                        </div>
                    </div>
                </>
                :
                <img src={nodata} width='100%' height='250px' />
            }
        </>
                    
        } 
                
      </Model> */}
        </>
        :
        <>
        <Card style={{ padding: '30px' }}>
          <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
          </SkeletonTheme>
        </Card>
        <Card style={{ padding: '30px' }}>
          <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
          </SkeletonTheme>
        </Card>
        </>
        }
        </div>
        
    )
}

export default SubscriptionList
