import React,{useEffect,useState} from 'react';
import { useDispatch,useSelector } from "react-redux";
import Table from "./table";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { useHistory } from "react-router-dom";
import { Modal,ModalHeader,ModalBody } from 'reactstrap';

import Swal from 'sweetalert2'
import { listAllNews,deleteNews} from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';


function NewsList() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const history = useHistory();


    const newsloader = useSelector((state) =>state.superadmin.newsloader ? state.superadmin.newsloader : false);
    const Newsdata = useSelector((state) =>state.superadmin.Newsdata ? state.superadmin.Newsdata : []);

    const [image,setImage]=useState({show:false,url:''})

    useEffect(()=>{
        dispatch(listAllNews(tokenData.token))
    },[])

    const EditNews=(rowdata)=>{
        history.push(`/dashboard/updateNews/${rowdata.news_id}`)
    }

   
    const ViewImage=(rowdata)=>{
        setImage({show:true,url:rowdata.image})
    }

    const CloseImage=()=>{
        setImage({show:false,url:''})
    }
    let col=[
        { title: 'Title', field: 'title' },
        { title: 'Description', field: 'description' },
        { title: 'Date', field: 'date_format' },
    ]

    const Delete=(rowdata)=>{
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',
    
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
    
        }).then((result) => {
            if (result.value) {
                dispatch(deleteNews(tokenData.token,rowdata.news_id,handleOpenSuccessModal))
    
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }


    const [successModal, setSuccessModal] = useState(false);
    const handleOpenSuccessModal = () => {
        dispatch(listAllNews(tokenData.token))
        setSuccessModal(true)
    }


    return (
        <div>
            <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
        >
            <PageTitle
                heading="News List"
                subheading="List of Your Newses"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />

            <Table
                loader={newsloader}
                actions={'news'}
                data={Newsdata}
                title={'News List'}
                columns={col}
                EditNews={EditNews}
                ViewImage={ViewImage}
                Delete={Delete}
            />

        </CSSTransitionGroup>

        <Modal
          isOpen={image.show}
          toggle={CloseImage}
          size={'md'}
        >   
            <ModalHeader toggle={CloseImage}>
                Preview News Image
            </ModalHeader>
            <ModalBody>
                {
                    image.url ?
                    <img src={image.url} alt='' width='100%' height='350px' />
                    :
                    <div style={{width:'100%',height:'100%',justifyContent:'center',display:'flex',alignItems:'flex'}}>
                        <span>Please Upload An Image</span>
                    </div>
                }
            </ModalBody>
            
        </Modal>


        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text= {"Deleted Successfully"}
            type="success"
            onConfirm={() => setSuccessModal(false)} />


        </div>
    )
}

export default NewsList
