const initialState = {
  uploadProgressVideo: "",
  classDetails: [],
  courses:[],
  my_courses:[],
  classesOfCourse:[],
  uploadProgressDoc:"",
  UploadCourseLoader:false,
  subscriptionClassDetails:[]
};

const vendorCourseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPLOAD_PROGRESS_IMAGE": {
      return {
        ...state,
        uploadProgressVideo: payload, //both video and banner image are included
      };
    }
    case "UPLOAD_PROGRESS_DOC": {
      return {
        ...state,
        uploadProgressDoc: payload,
      };
    }
    case "UPLOAD_ASSIGNMENT_STUDENT_REQUEST": {
      return {
        ...state,
        uploadAssignButton: true,
      };
    }
    case "UPLOAD_ASSIGNMENT_SUCCESS": {
      return {
        ...state,
        uploadProgressVideo: "",
      };
    }
    case "UPLOAD_ASSIGNMENT_FAILED": {
      return {
        ...state,
        uploadProgressVideo: "",
      };
    }
    case "UPLOAD_COURSE_FILES_REQUEST": {
      return {
        ...state,
       UploadCourseLoader: true,
      };
    }
    case "UPLOAD_COURSE_FILES_SUCCESS": {
      return {
        ...state,
       UploadCourseLoader: false,
      };
    }
    case "UPLOAD_COURSE_FILES_FAILED": {
      return {
        ...state,
       UploadCourseLoader: false,
      };
    }
    case "POPULATE_CLASS_DETAILS_SUCCESS": {
      return {
        ...state,
        classDetails: payload,
      };
    }
    case "POPULATE_COURSES_SUCCESS": {
      return {
        ...state,
        courses: payload,
      };
    }
    case "POPULATE_MY_COURSES_SUCCESS": {
      return {
        ...state,
        my_courses: payload,
      };
    }
    case "POPULATE_CLASS_FOR_COURSES_REQUEST":{
      return{
        ...state,loadclass:true
      }
    }
    case "POPULATE_CLASS_FOR_COURSES_RESET": {
      return {
        ...state,
        classesOfCourse: '',
       
      };
    }
    case "POPULATE_CLASS_FOR_COURSES_SUCCESS": {
      return {
        ...state,
        classesOfCourse: payload,
        loadclass:false
      };
    }
    case "POPULATE_CLASS_FOR_COURSES_FAILED":{
      return{
        ...state,loadclass:false
      }
    }

    case "COURSEEDIT_DETAILS_REQUEST": {
      return {
        ...state,
        ...payload,
      };
    }
    case "POPULATE_CLASS_FOR_COURSES_RESET": {
      return {
        ...state,
        classesOfCourse: '',
       
      };
    }

    case "COURSEEDIT_DETAILS_SUCCESS": {
      let blist=[]
      let data={}
      data.batch_name=''
      data.total_seats=''
      blist.push(data)
   
      let slist=[]
      let data2={}
      data2.subject_name=''
      slist.push(data2)
      
      return {
        ...state,
        EditCourseDetails: payload,
        EditBatchList:payload.batch_details ? payload.batch_details.length > 0 ?  payload.batch_details:blist :blist,
        ...payload,
        EditSubjectList:payload.subjects ? payload.subjects.length > 0 ?  payload.subjects:slist :slist,
        ...payload
      };
    }
    case "COURSEEDIT_DETAILS_FAILED": {
      return {
        ...state,
        ...payload,
      };
    }




    case "EDIT_COURSE_FILES_REQUEST": {
      return {
        ...state,
        editloader: true,
      };
    }
    case "EDIT_COURSE_FILES_SUCCESS": {
      return {
        ...state,
        editloader: false,
      };
    }
    case "EDIT_COURSE_FILES_FAILED": {
      return {
        ...state,
        editloader: false,
      };
    }


    case "DELETE_GROUP_REQUEST": {
      return {
        ...state,
        batchloader: true,
      };
    }
    case "DELETE_GROUP_SUCCESS": {
      return {
        ...state,
        batchloader: false,
      };
    }
    case "DELETE_GROUP_FAILED": {
      return {
        ...state,
        batchloader: false,
      };
    }
    case "POPULATE_SUBSCRIPTION_CLASS_DETAILS_SUCCESS" : {
      return {
        ...state, subscriptionClassDetails:payload
      }
    }

//////////////SINGLE/////////
case "SET_VENDOR_DATA": {
  return { ...state, ...payload };
}


    default:
      return state;
  }
};

export default vendorCourseReducer;
