import React from "react";
import DataTable from "material-table";
import "./styleee.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import GroupIcon from "@material-ui/icons/Group";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import BookIcon from "@material-ui/icons/Book";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import InsertCommentIcon from "@material-ui/icons/InsertComment";
import VisibilityIcon from '@material-ui/icons/Visibility';
// import SchoolIcon from '@material-ui/icons/School';
// import PublishIcon from '@material-ui/icons/Publish';
// import BlockIcon from '@material-ui/icons/Block';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Back from '@material-ui/icons/RotateLeft';
import ImageIcon from '@material-ui/icons/Image';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';

const Table = (props) => {
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  // console.log("dtattavle  ", props);

  return (
    <DataTable
      isLoading={props.loader}
      title={props.title ? props.title : "Class"}
      columns={props.columns}
      data={props.data}
      options={{
        search: true,
        sorting: true,
        // rowStyle: {
        //     backgroundColor: '#EEE',
        // },
        actionsColumnIndex: -1,
      }}
      // onRowClick={
      //     props.actions === 'create_group' ?
      //         ((evt, rowData) => props.handleSelectedRow(rowData.group_id))
      //         : null

      // }
      editable={
        props.actions === "add_subject"
          ? {
            onRowUpdate: (newData) =>
              new Promise((resolve) => {
                props.onEdit(newData);
                resolve();
              }),
          }
          : props.actions === "create_group"
            ? {
              onRowUpdate: (newData) =>
                new Promise((resolve) => {
                  props.onEdit(newData);
                  resolve();
                }),
            }
            : props.actions === "edit_users"
              ? {
                onRowUpdate: (newData) =>
                  new Promise((resolve) => {
                    props.onEdit(newData);
                    resolve();
                  }),
              }
              : null
      }
      actions={
        props.actions ? props.actions === "dashboard"
          ? [
            {
              icon: "queue_play_next",
              tooltip: props.role
                ? props.role === "student"
                  ? "Join to Class"
                  : props.role === "teacher"
                    ? "Start Class"
                    : null
                : null,
              onClick: (event, rowData) =>
                props.joinClass(rowData.weekly_class_id, rowData.class_id),
            },
          ]
          : props.actions === "list_question_paper"
            ? [
              {
                icon: () => <AddIcon style={{ color: "#3b6ac3" }} />,
                tooltip: "Add Questions",
                onClick: (event, rowData) =>
                  props.addQuestion(rowData.question_paper_id),
              },
              {
                icon: () => <BookIcon style={{ color: "#3b6ac3" }} />,
                tooltip: "Show Questions",
                onClick: (event, rowData) =>
                  props.listQuestions(rowData.question_paper_id),
              },
              // {

              //     icon: () => <EditIcon style={{ color: '#3b6ac3' }} />,
              //     tooltip: 'Edit',
              //     onClick: (event, rowData) => props.editQuestionPaper(rowData.question_paper_id)

              // },
              {
                icon: () => <DeleteIcon style={{ color: "#3b6ac3" }} />,
                tooltip: "Delete Question Paper",
                onClick: (event, rowData) =>
                  props.deleteQuestionPaper(rowData.question_paper_id),
              },
            ]
            : props.actions === "list_exam"
              ? [
                ...(tokenData.role === "student"
                  ? [
                    {
                      icon: () => (
                        <BookIcon style={{ color: "rgb(0, 69, 139)" }} />
                      ),
                      tooltip: "Answer Sheet",
                      onClick: (event, rowData) =>
                        props.hadleAnswerSheet(rowData),
                    },
                  ]
                  : tokenData.role === "teacher" ||
                    tokenData.role === "principal"
                    ? [
                      {
                        icon: () => <AddIcon style={{ color: "red" }} />,
                        tooltip: "Assign to exam",
                        onClick: (event, rowData) =>
                          props.assignToExam(rowData.grade_id, rowData.exam_id),
                      },
                      {
                        icon: () => (
                          <InsertCommentIcon
                            style={{ color: "rgb(0, 69, 139)" }}
                          />
                        ),
                        tooltip: "Add exam instructions",
                        onClick: (event, rowData) =>
                          props.addInstruction(rowData.exam_id),
                      },
                      {
                        icon: () => (
                          <BookIcon style={{ color: "rgb(0, 69, 139)" }} />
                        ),
                        tooltip: "Exam details",
                        onClick: (event, rowData) =>
                          props.doToExamDetails(rowData),
                      },

                      {
                        icon: () => <EditIcon style={{ color: "blue" }} />,
                        tooltip: "Update exam timings",
                        onClick: (event, rowData) => props.updateExam(rowData),
                      },
                    ]
                    : []),
                (rowData) =>
                  tokenData.role === "principal" && {
                    icon: () =>
                      rowData.approval ? (
                        <CancelIcon style={{ color: "red" }} />
                      ) : (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ),
                    tooltip: rowData.approval ? "Reject exam" : "Approve exam",
                    onClick: (event, rowData) => props.toggleApproval(rowData),
                  },
              ]
              : props.actions === "assign_student"
                ? [
                  {
                    icon: "schedule",
                    tooltip: "Time Shedule",
                    onClick: (event, rowData) =>
                      props.hadleOpenModal(rowData.class_id),
                  },
                  {
                    icon: "supervisor_account",
                    tooltip: "View Students",
                    onClick: (event, rowData) =>
                      props.handleOpenStudentListModal(
                        rowData.class_id,
                        rowData.grade_id
                      ),
                  },
                  // {
                  //   icon: "add",
                  //   tooltip: `${
                  //     tokenData.enterpriseUser
                  //       ? "Assign Group"
                  //       : "Assign Students"
                  //   }`,
                  //   onClick: (event, rowData) =>
                  //     props.hadleOpenStudentModal(
                  //       rowData.class_id,
                  //       rowData.grade_id,
                  //       rowData.teacher_id,
                  //       rowData.timing
                  //     ),
                  // },
                  {
                    icon: "edit",
                    tooltip: "Edit Class",
                    onClick: (event, rowData) =>
                      props.handleEdit(rowData.course_id, rowData.class_id)
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete Class",
                    onClick: (event, rowData) =>
                      props.handleDelete(rowData.class_id)
                  },
                ]
                : props.actions === "assign_student_subscription"
                  ? [
                    // {
                    //   icon: "schedule",
                    //   tooltip: "Time Shedule",
                    //   onClick: (event, rowData) =>
                    //    // props.hadleOpenModal(rowData.class_id),
                    //    console.log(rowData)
                    // },
                    // {
                    //   icon: "add",
                    //   tooltip: "Assign Group",
                    //   onClick: (event, rowData) =>
                    //     props.handleAssign(
                    //       rowData.class_id,

                    //     ),
                    // },
                    // {
                    //   icon: "add",
                    //   tooltip: `${
                    //     tokenData.enterpriseUser
                    //       ? "Assign Group"
                    //       : "Assign Students"
                    //   }`,
                    //   onClick: (event, rowData) =>
                    //     props.hadleOpenStudentModal(
                    //       rowData.class_id,
                    //       rowData.grade_id,
                    //       rowData.teacher_id,
                    //       rowData.timing
                    //     ),
                    // },
                    {
                      icon: "edit",
                      tooltip: "Edit Class",
                      onClick: (event, rowData) =>
                        props.handleEdit(rowData.course_id, rowData.class_id)
                    },
                    {
                      icon: "delete",
                      tooltip: "Delete Class",
                      onClick: (event, rowData) =>
                        props.handleDelete(rowData.class_id)
                    },
                  ]
                  : props.actions === "show_class"
                    ? [
                      {
                        icon: "schedule",
                        tooltip: "Time Shedule",
                        onClick: (event, rowData) =>
                          props.hadleOpenModal(rowData.class_id),
                      },
                      {
                        icon: "supervisor_account",
                        tooltip: "View Students",
                        onClick: (event, rowData) =>
                          props.handleOpenStudentListModal(
                            rowData.class_id,
                            rowData.grade_id
                          ),
                      },
                    ]
                    : props.actions === "student_class"
                      ? [
                        {
                          icon: () => (
                            <AccessAlarmIcon style={{ color: "rgb(0, 69, 139)" }} />
                          ),
                          tooltip: "Time Shedule",
                          onClick: (event, rowData) =>
                            props.hadleOpenModal(rowData.class_id),
                        },
                      ]
                      : props.actions === "recording_list"
                        ? [
                          {
                            icon: () => (
                              <DownloadIcon style={{ color: "rgb(41, 183, 155)" }} />
                            ),
                            tooltip: "Download",
                            onClick: (event, rowData) =>
                              props.onDownload(rowData),
                          },
                          {
                            icon: () => <FileCopyIcon style={{ color: "#648dae" }} />,
                            tooltip: "Copy",
                            onClick: (event, rowData) =>
                              props.onCopy(rowData.download_link),
                          },
                        ]
                        : props.actions === "add_subject"
                          ? [
                            // {
                            //     icon: 'edit',
                            //     tooltip: 'Edit',
                            //     onClick: (event, rowData) => props.hadleOpenModal(rowData)
                            // },
                            {
                              icon: "delete",
                              tooltip: "Delete",
                              onClick: (event, rowData) =>
                                props.onDelete(rowData.subject_id),
                            },
                          ]
                          : props.actions === "create_group"
                            ? [
                              // {
                              //     icon: () => <AddIcon style={{ color: '#3b6ac3' }} />,
                              //     tooltip: 'Assign Teacher',
                              //     onClick: (event, rowData) => props.handleAssignTeacher(rowData.group_id)
                              // },
                              {
                                icon: "delete",
                                tooltip: "Delete",
                                onClick: (event, rowData) => props.onDelete(rowData.group_id),
                              },
                              {
                                icon: () => <GroupIcon />,
                                tooltip: "Student List",
                                onClick: (event, rowData) =>
                                  props.handleAssignStudent(rowData.group_id),
                              },
                            ]

                            : props.actions === "course_purchased_students" ?
                              [
                                {
                                  icon: () => <OndemandVideoIcon />,
                                  tooltip: "Ondemand class Progress",
                                  onClick: (event, rowData) =>
                                    props.ViewStudents(rowData.student_id),
                                }
                              ]
                              ////////////////////////super admin start here by midhlaj//////////////
                              : props.actions === "vendor_table"
                                ? [
                                  {
                                    icon: "edit",
                                    tooltip: "Edit Partner",
                                    onClick: (event, rowData) =>
                                      props.Editvendor(rowData)
                                  },
                                  // {
                                  //     icon: () => <GroupIcon style={{ color: "black" }} />,
                                  //     tooltip: "Subscription",
                                  //     onClick: (event, rowData) =>
                                  //     props.subscriptionActive(rowData),
                                  // },
                                  {
                                    icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                    tooltip: "View Details",
                                    onClick: (event, rowData) => props.DetailsofVendor(rowData),
                                  },
                                  {
                                    icon: () => <AddIcon style={{ color: "black" }} />,
                                    tooltip: "Credit/Debit",
                                    onClick: (event, rowData) =>
                                      props.CreditDebit(
                                        rowData
                                      ),
                                  },
                                  //   {
                                  //     icon: () => <MenuBookIcon style={{ color: "black" }} />,
                                  //     tooltip: "View Course Details",
                                  //     onClick: (event, rowData) =>
                                  //     props.DetailsofCourse(rowData),
                                  // },
                                  {
                                    icon: "delete",
                                    tooltip: "Delete",
                                    onClick: (event, rowData) => props.Deletevendor(rowData)
                                  },

                                  rowData => ({
                                    icon: () => <PowerSettingsNewIcon

                                      style={rowData?.status === 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }}
                                    />,
                                    tooltip: rowData?.status === 1 ? 'UnBlock' : 'Block',
                                    onClick: (event, rowData) => props.Updatestatus(rowData),

                                  }),

                                  rowData => ({
                                    icon: () => <SubscriptionsIcon

                                      style={rowData?.subscription_enabled === 0 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }}
                                    />,
                                    tooltip: rowData?.subscription_enabled === 0 ? 'Enable subscription feature' : 'Disable subscription feature',
                                    onClick: (event, rowData) => props.UpdateSubscriptionStatus(rowData),

                                  }),
                                ]
                                : props.actions === "rseller_table"
                                  ? [
                                    {
                                      icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                      tooltip: "View Reseller",
                                      onClick: (event, rowData) =>
                                        props.ViewReseller(rowData)
                                    },
                                    {
                                      icon: () => <AddIcon style={{ color: "black" }} />,
                                      tooltip: "Credit/Debit",
                                      onClick: (event, rowData) =>
                                        props.CreditDebit(
                                          rowData
                                        ),
                                    },
                                    {
                                      icon: "edit",
                                      tooltip: "Edit Reseller",
                                      onClick: (event, rowData) =>
                                        props.EditReseller(rowData)
                                    },

                                    //   {
                                    //     icon: () => <SchoolIcon style={{ color: "black" }} />,
                                    //     tooltip: "View Schools",
                                    //     onClick: (event, rowData) =>
                                    //     props.DetialsofVendor(

                                    //     ),
                                    // },
                                    {
                                      icon: "delete",
                                      tooltip: "Delete",
                                      onClick: (event, rowData) => props.DeleteReseller(rowData)
                                    },

                                    rowData => ({
                                      icon: () => <PowerSettingsNewIcon

                                        style={rowData?.status === 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }}
                                      />,
                                      tooltip: 'Block/Unblock Reseller',
                                      onClick: (event, rowData) => props.Updatestatus(rowData),

                                    }),

                                    // rseller_table
                                  ]
                                  : props.actions === "school_table" && tokenData.role === 'superAdmin' ?
                                    [

                                      {
                                        icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                        tooltip: "View School",
                                        onClick: (event, rowData) =>
                                          props.ViewDetails(
                                            rowData
                                          ),
                                      },
                                      {
                                        icon: "edit",
                                        tooltip: "Edit School",
                                        onClick: (event, rowData) =>
                                          props.Editschool(rowData)
                                      },
                                      {
                                        icon: () => <AddIcon style={{ color: "black" }} />,
                                        tooltip: "Creadit/Debit",
                                        onClick: (event, rowData) =>
                                          props.CreditDebit(rowData)
                                      },
                                      {
                                        icon: "delete",
                                        tooltip: "Delete Schhol",
                                        onClick: (event, rowData) => props.Deleteschool(rowData)
                                      },
                                      rowData => ({
                                        icon: () => <PowerSettingsNewIcon

                                          style={rowData?.status === 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }}
                                        />,
                                        tooltip: 'Block/Unblock School',
                                        onClick: (event, rowData) => props.Updatestatus(rowData),

                                      }),
                                    ]
                                    :
                                    props.actions === "school_table" && tokenData.role === 'reseller' ?
                                      [
                                        {
                                          icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                          tooltip: "View School",
                                          onClick: (event, rowData) =>
                                            props.ViewDetails(
                                              rowData
                                            ),
                                        },
                                      ]


                                      : props.actions === "student_table" && tokenData.role === 'superAdmin' ?

                                        [
                                          {
                                            icon: "edit",
                                            tooltip: "Edit Student",
                                            onClick: (event, rowData) =>
                                              props.Editstudent(rowData),
                                          },
                                          {
                                            icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                            tooltip: "View Details",
                                            onClick: (event, rowData) =>
                                              props.DetialsofStudent(rowData),
                                          },
                                          {
                                            icon: "delete",
                                            tooltip: "Delete",
                                            onClick: (event, rowData) => props.Deletestudent(rowData)
                                          },

                                          rowData => ({
                                            icon: () => <PowerSettingsNewIcon

                                              style={rowData?.status === 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }}
                                            />,
                                            tooltip: 'Block/Unblock Student',
                                            onClick: (event, rowData) => props.Updatestatus(rowData),

                                          })
                                        ]

                                        : props.actions === "student_table" && tokenData.role === 'reseller' ?
                                          [
                                            {
                                              icon: "edit",
                                              tooltip: "Edit Student",
                                              onClick: (event, rowData) =>
                                                props.Editstudent(rowData),
                                            },
                                            {
                                              icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                              tooltip: "View Details",
                                              onClick: (event, rowData) =>
                                                props.DetialsofStudent(rowData),
                                            },
                                          ]
                                          : props.actions === "payment_table"
                                            ? [


                                              {
                                                icon: () => (
                                                  <Back style={{ color: "#0275d8" }} />
                                                ),
                                                tooltip: "Initiate Refund",
                                                onClick: (event, rowData) =>
                                                  props.initiateRefund(
                                                    rowData
                                                  ),
                                              },

                                            ]

                                            :
                                            props.actions === "vendorcourse_details"
                                              ? [
                                                // {
                                                //   icon: "edit",
                                                //   tooltip: "Edit Commission",
                                                //   onClick: (event, rowData) =>
                                                //     props.Editcommission(rowData)
                                                // },
                                                {
                                                  icon: () => (
                                                    <VisibilityIcon style={{ color: "black" }} />
                                                  ),
                                                  tooltip: "View Details",
                                                  onClick: (event, rowData) =>
                                                    props.DetialsofVendorCourse(rowData),
                                                },

                                              ]
                                              : props.actions === "vendorstudent_details"
                                                ? [

                                                  {
                                                    icon: () => (
                                                      <MenuBookIcon style={{ color: "black" }} />
                                                    ),
                                                    tooltip: "View Course Details",
                                                    onClick: (event, rowData) =>
                                                      props.Vendorstudentdetails(rowData),
                                                  },

                                                ]
                                                : props.actions === "Ticket_table"
                                                  ? [
                                                    {
                                                      icon: () => (
                                                        <EditIcon style={{ color: "black" }} />
                                                      ),
                                                      tooltip: "Chnage Status",
                                                      onClick: (event, rowData) =>
                                                        props.Changestatus(rowData),
                                                    },
                                                    {
                                                      icon: () => (
                                                        <VisibilityIcon style={{ color: "black" }} />
                                                      ),
                                                      tooltip: "View Details",
                                                      onClick: (event, rowData) =>
                                                        props.Ticketdetails(rowData),
                                                    },

                                                  ]

                                                  /////////////////student by midhlaj
                                                  : props.actions === "student_ticket"
                                                    ? [
                                                      {
                                                        icon: () => (
                                                          <VisibilityIcon style={{ color: "black" }} />
                                                        ),
                                                        tooltip: "View Detail",
                                                        onClick: (event, rowData) =>
                                                          props.Ticketdetails(rowData)
                                                      },
                                                    ]
                                                    ///////////////////testimonial/////////////
                                                    : props.actions === "testimonial"
                                                      ? [
                                                        {
                                                          icon: () => (
                                                            <EditIcon style={{ color: "black" }} />
                                                          ),
                                                          tooltip: "Edit",
                                                          onClick: (event, rowData) =>
                                                            props.EditTestimony(rowData)
                                                        },
                                                        {
                                                          icon: () => (
                                                            <ImageIcon style={{ color: "black" }} />
                                                          ),
                                                          tooltip: "View image",
                                                          onClick: (event, rowData) =>
                                                            props.ViewImage(rowData)
                                                        },
                                                        {
                                                          icon: 'delete',
                                                          tooltip: "Delete",
                                                          onClick: (event, rowData) =>
                                                            props.Delete(rowData)
                                                        },
                                                      ]
                                                      /////////////gallery//////////
                                                      : props.actions === "gallery"
                                                        ? [
                                                          {
                                                            icon: () => (
                                                              <EditIcon style={{ color: "black" }} />
                                                            ),
                                                            tooltip: "Edit",
                                                            onClick: (event, rowData) =>
                                                              props.EditGallery(rowData)
                                                          },
                                                          {
                                                            icon: () => (
                                                              <VisibilityIcon style={{ color: "black" }} />
                                                            ),
                                                            tooltip: "Manage Media",
                                                            onClick: (event, rowData) =>
                                                              props.AddMedia(rowData)
                                                          },
                                                          // {
                                                          //   icon: () => (
                                                          //     <ImageIcon style={{ color: "black" }} />
                                                          //   ),
                                                          //   tooltip: "View image",
                                                          //   onClick: (event, rowData) =>
                                                          //     props.ViewImage(rowData)
                                                          // },
                                                          // {

                                                          //   rowData => ({

                                                          //     icon: () => rowData.type==='image' ? <ImageIcon style={{ color: "black" }} />:<PlayCircleFilledIcon style={{ color: "black" }} />,
                                                          //     tooltip: rowData.type==='image' ? 'View image':'Play Video',
                                                          //     onClick:rowData.type==='image' ? 
                                                          //       (event, rowData) =>
                                                          //       props.ViewImage(rowData)
                                                          //       :
                                                          //       (event, rowData) =>
                                                          //       props.Play(rowData)

                                                          // }),

                                                          {
                                                            icon: 'delete',
                                                            tooltip: "Delete",
                                                            onClick: (event, rowData) =>
                                                              props.Delete(rowData)
                                                          },
                                                        ]
                                                        ////////////////news////////////////
                                                        : props.actions === "news"
                                                          ? [
                                                            {
                                                              icon: () => (
                                                                <EditIcon style={{ color: "black" }} />
                                                              ),
                                                              tooltip: "Edit",
                                                              onClick: (event, rowData) =>
                                                                props.EditNews(rowData)
                                                            },
                                                            {
                                                              icon: () => <ImageIcon style={{ color: "black" }} />,
                                                              tooltip: 'View image',
                                                              onClick:
                                                                (event, rowData) =>
                                                                  props.ViewImage(rowData)
                                                            },
                                                            {
                                                              icon: 'delete',
                                                              tooltip: 'Delete',
                                                              onClick:
                                                                (event, rowData) =>
                                                                  props.Delete(rowData)
                                                            },
                                                          ]

                                                          ////////////////vendor new /////////////////
                                                          : props.actions === "vendorviewcourses"
                                                            ? [
                                                              {
                                                                icon: () => (
                                                                  <VisibilityIcon style={{ color: "black" }} />
                                                                ),
                                                                tooltip: "View Detail",
                                                                //   onClick: (event, rowData) =>
                                                                //     props.Ticketdetails()
                                                              },
                                                              {
                                                                icon: "edit",
                                                                tooltip: "Edit Course",
                                                                onClick: (event, rowData) =>
                                                                  alert('Edit Course')
                                                              },
                                                            ]
                                                            :
                                                            props.actions === "single_course_class_details"
                                                              ? [
                                                                {
                                                                  icon: "schedule",
                                                                  tooltip: "Time Shedule",
                                                                  onClick: (event, rowData) =>
                                                                    props.TimeSchedule(rowData)
                                                                },
                                                                {
                                                                  icon: "edit",
                                                                  tooltip: "Edit Live Class",
                                                                  onClick: (event, rowData) =>
                                                                    props.Editclass(rowData)
                                                                },
                                                                {
                                                                  icon: "delete",
                                                                  tooltip: "Delete Live Class",
                                                                  onClick: (event, rowData) =>
                                                                    props.Deleteclass(rowData)
                                                                },
                                                              ]
                                                              : props.actions === "single_course_ondemand_class_details"
                                                                ? [
                                                                  // {
                                                                  //   icon: () => (
                                                                  //     <AddIcon style={{ color: "black" }} />
                                                                  //   ),
                                                                  //   tooltip: "Assign Students",
                                                                  //   onClick: (event, rowData) =>
                                                                  //     props.hadleOpenModal(rowData.video_class_id)
                                                                  // },
                                                                  {
                                                                    icon: () => (
                                                                      <PlayCircleFilledIcon style={{ color: "black" }} />
                                                                    ),
                                                                    tooltip: "View Video Class",
                                                                    onClick: (event, rowData) =>
                                                                      props.ViewvideoClass(rowData)
                                                                  },
                                                                  {
                                                                    icon: "edit",
                                                                    tooltip: "Edit Video Class",
                                                                    onClick: (event, rowData) =>
                                                                      props.EditVideoclass(rowData)
                                                                  },
                                                                  {
                                                                    icon: "delete",
                                                                    tooltip: "Delete Video Class",
                                                                    onClick: (event, rowData) =>
                                                                      props.DeleteVideoClass(rowData)
                                                                  },
                                                                ]
                                                                : props.actions === "single_vendor_list"
                                                                  ? [

                                                                    {
                                                                      icon: () => (
                                                                        <VisibilityIcon style={{ color: "black" }} />
                                                                      ),
                                                                      tooltip: "View Course",
                                                                      onClick: (event, rowData) =>
                                                                        props.Viewcourse(rowData)
                                                                    },
                                                                    rowData => ({
                                                                      disabled: (rowData.request_status === "requested") || (rowData.live_class_limit) || (rowData.request_status === "approved") || !(rowData.live_class_enabled),
                                                                      // disabled:(rowData)=> rowData.request_status==="( console.log("statas",rowData.status)"? false: console.log("roeeeee",rowData),
                                                                      // icon: () => (
                                                                      //   <AddIcon style={{ color: "black" }} />
                                                                      // ),
                                                                      icon: "add",
                                                                      // tooltip: rowData.request_status !== "unrequested"? rowData.live_class_limit? "Limit reached":"Requested approval" : rowData.live_class_enabled? "Add live class":"this course is only video class" ,
                                                                      tooltip: (rowData.request_status === "requested" || rowData.request_status === "approved") ? "Disabled" : "Add live class",
                                                                      onClick: (event, rowData) =>
                                                                        props.CreateLiveclass(rowData)
                                                                    }),
                                                                    rowData => ({
                                                                      disabled: (rowData.request_status === "requested") || (rowData.ondemand_video_limit) || (rowData.request_status === "approved") || !(rowData.ondemand_video_enabled),
                                                                      icon: () => (
                                                                        <VideoCallIcon style={((rowData.request_status === "requested") || (rowData.request_status === "approved") || (rowData.ondemand_video_limit)) ? { color: "grey" } : { color: "black" }} />
                                                                      ),
                                                                      // icon:"edit",
                                                                      //tooltip: rowData.request_status !== "unrequested"? rowData.live_class_limit? "Limit reached":"Requested approval" :rowData.ondemand_video_enabled?"Add video class":"this course is only live class" ,
                                                                      tooltip: (rowData.request_status === "requested" || rowData.request_status === "approved") ? "Disabled" : "Add video class",
                                                                      onClick: (event, rowData) =>
                                                                        props.CreateVideoClass(rowData)
                                                                    }),

                                                                    rowData => ({
                                                                      //  disabled:(rowData)=> rowData.request_status==="unrequested"? false: true,
                                                                      disabled: (rowData.request_status === "requested" || rowData.request_status === "approved"),
                                                                      icon: "edit",
                                                                      tooltip: rowData.request_status === "unrequested" || rowData.request_status === "rejected" ? "Edit Course" : rowData.request_status === "requested" ? "requested course can't edit" : "approved course can't edit",
                                                                      onClick: (event, rowData) =>
                                                                        props.Editcourse(rowData)
                                                                    }),
                                                                    rowData => ({
                                                                      disabled: (rowData.request_status === "requested" || rowData.request_status === "approved"),
                                                                      //  disabled:(rowData)=> rowData.request_status==="unrequested"? false: true,
                                                                      icon: "delete",
                                                                      tooltip: rowData.request_status === "unrequested" || rowData.request_status === "rejected" ? "Delete Course" : rowData.request_status === "requested" ? "requested course can't delete" : "approved course can't delete",
                                                                      onClick: (event, rowData) =>
                                                                        props.DeleteCourse(rowData)
                                                                    })
                                                                  ] : props.actions === "active_courses" ? [
                                                                    {
                                                                      icon: () => (
                                                                        <VisibilityIcon style={{ color: "black" }} />
                                                                      ),
                                                                      tooltip: "View Course",
                                                                      onClick: (event, rowData) =>
                                                                        props.Viewcourse(rowData.course_id)
                                                                    },
                                                                  ]
                                                                    : props.actions === "pending_courses" ? [
                                                                      {
                                                                        icon: () => (
                                                                          <VisibilityIcon style={{ color: "black" }} />
                                                                        ),
                                                                        tooltip: "View Course",
                                                                        onClick: (event, rowData) =>
                                                                          props.Viewcourse(rowData.course_id)
                                                                      },
                                                                    ]
                                                                      : props.actions === "rejected_courses" ? [
                                                                        {
                                                                          icon: () => (
                                                                            <VisibilityIcon style={{ color: "black" }} />
                                                                          ),
                                                                          tooltip: "View Course",
                                                                          onClick: (event, rowData) =>
                                                                            props.Viewcourse(rowData.course_id)
                                                                        },
                                                                      ]
                                                                        : props.actions === "pin_filter" ? [
                                                                          {
                                                                            icon: () => (
                                                                              <VisibilityIcon style={{ color: "black" }} />
                                                                            ),
                                                                            tooltip: "View/Update Panchayath",
                                                                            onClick: (event, rowData) =>
                                                                              props.ViewPincodes(rowData)
                                                                          },]
                                                                          : props.actions === "course_categories" ? [
                                                                            {
                                                                              icon: () => (
                                                                                <VisibilityIcon style={{ color: "black" }} />
                                                                              ),
                                                                              tooltip: "View Category",
                                                                              onClick: (event, rowData) =>
                                                                                props.ViewCategory(rowData)
                                                                            },
                                                                            {
                                                                              icon: () => (
                                                                                <EditIcon style={{ color: "black" }} />
                                                                              ),
                                                                              tooltip: "Edit Category",
                                                                              onClick: (event, rowData) =>
                                                                                props.EditCategory(rowData)
                                                                            },
                                                                            {
                                                                              icon: () => <DeleteIcon style={{ color: "#3b6ac3" }} />,
                                                                              tooltip: "Delete Category",
                                                                              onClick: (event, rowData) =>
                                                                                props.deleteCategory(rowData),
                                                                            },
                                                                          ]
                                                                            : props.actions === "course_tags" ? [

                                                                              // {
                                                                              //   icon: () => <VisibilityIcon style={{ color: "black" }} />,
                                                                              //   tooltip: "View Logo",
                                                                              //   onClick: (event, rowData) =>
                                                                              //     props.viewTag(rowData),
                                                                              // },
                                                                              {
                                                                                icon: () => <EditIcon style={{ color: "black" }} />,
                                                                                tooltip: "Update Tag",
                                                                                onClick: (event, rowData) =>
                                                                                  props.editTag(rowData),
                                                                              },
                                                                              {
                                                                                icon: () => <DeleteIcon style={{ color: "#3b6ac3" }} />,
                                                                                tooltip: "Delete Tag",
                                                                                onClick: (event, rowData) =>
                                                                                  props.deleteTag(rowData),
                                                                              },
                                                                            ]
                                                                              : props.actions === "download_invoice"
                                                                                ? [
                                                                                  // {
                                                                                  //   icon: () => (
                                                                                  //     <DownloadIcon style={{ color: "rgb(41, 183, 155)" }} />
                                                                                  //   ),
                                                                                  //   tooltip: "Download Invoice",
                                                                                  //   onClick: (event, rowData) =>
                                                                                  //   props.downloadInvoice(rowData.invoice_path),
                                                                                  // },

                                                                                ] : props.actions === "student_refund"
                                                                                  ? [


                                                                                    {
                                                                                      icon: () => (
                                                                                        <Back style={{ color: "#0275d8" }} />
                                                                                      ),
                                                                                      tooltip: "View Refunds",
                                                                                      onClick: (event, rowData) =>
                                                                                        props.viewRefunds(
                                                                                          rowData.refunds
                                                                                        ),
                                                                                    },

                                                                                  ] :
                                                                                  props.actions === "study_material_teacher" ?
                                                                                    [
                                                                                      {
                                                                                        icon: () => (
                                                                                          <DownloadIcon style={{ color: "#0275d8" }} />
                                                                                        ),
                                                                                        tooltip: "Download ZIP",
                                                                                        onClick: (event, rowData) =>
                                                                                          props.handleDownload(
                                                                                            rowData
                                                                                          ),
                                                                                      },
                                                                                      {
                                                                                        icon: () => (
                                                                                          <VisibilityIcon />
                                                                                        ),
                                                                                        tooltip: "View/Update",
                                                                                        onClick: (event, rowData) =>
                                                                                          props.handleView(
                                                                                            rowData
                                                                                          ),
                                                                                      },
                                                                                      {
                                                                                        icon: () => (
                                                                                          <DeleteIcon />
                                                                                        ),
                                                                                        tooltip: "Delete",
                                                                                        onClick: (event, rowData) =>
                                                                                          props.handleDelete(
                                                                                            rowData
                                                                                          ),
                                                                                      },
                                                                                    ] :
                                                                                    props.actions === "study_material_student" ?
                                                                                      [
                                                                                        {
                                                                                          icon: () => (
                                                                                            <DownloadIcon style={{ color: "#0275d8" }} />
                                                                                          ),
                                                                                          tooltip: "Download ZIP",
                                                                                          onClick: (event, rowData) =>
                                                                                            props.handleDownload(
                                                                                              rowData
                                                                                            ),
                                                                                        },
                                                                                        // {
                                                                                        //   icon: () => (
                                                                                        //     <VisibilityIcon />
                                                                                        //   ),
                                                                                        //   tooltip: "View",
                                                                                        //   onClick: (event, rowData) =>
                                                                                        //     props.handleView(
                                                                                        //       rowData
                                                                                        //     ),
                                                                                        // },
                                                                                      ] :
                                                                                      props.actions === "study_notes_teacher" ?
                                                                                        [
                                                                                          {
                                                                                            icon: () => (
                                                                                              <EditIcon style={{ color: "black" }} />
                                                                                            ),
                                                                                            tooltip: "Edit",
                                                                                            onClick: (event, rowData) =>
                                                                                              props.handleEdit(
                                                                                                rowData
                                                                                              ),
                                                                                          },
                                                                                          {
                                                                                            icon: () => (
                                                                                              <VisibilityIcon />
                                                                                            ),
                                                                                            tooltip: "View",
                                                                                            onClick: (event, rowData) =>
                                                                                              props.handleView(
                                                                                                rowData
                                                                                              ),
                                                                                          },
                                                                                          {
                                                                                            icon: () => (
                                                                                              <DeleteIcon />
                                                                                            ),
                                                                                            tooltip: "Delete",
                                                                                            onClick: (event, rowData) =>
                                                                                              props.handleDelete(
                                                                                                rowData
                                                                                              ),
                                                                                          },
                                                                                        ] : props.actions === "study_notes_student" ?
                                                                                          [

                                                                                            {
                                                                                              icon: () => (
                                                                                                <VisibilityIcon />
                                                                                              ),
                                                                                              tooltip: "View",
                                                                                              onClick: (event, rowData) =>
                                                                                                props.handleView(
                                                                                                  rowData
                                                                                                ),
                                                                                            },
                                                                                          ] : null
          : null
      }
    />
  );
};

////



export default Table;
