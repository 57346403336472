import React,{useState,useEffect,Fragment} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    // Badge,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { requiredValidator } from "../../../utilities/Validator";
import { setReqData,createGallery,listAllGalleries } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { Progress } from "react-sweet-progress";
import GalleryList from "./galleryList"
function CreateGallery() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const formData = new FormData();

    const gallery = useSelector((state) =>state.superadmin.gallery ? state.superadmin.gallery : false);
    const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

    
    const [InputField,setInputfield]=useState({
        title:'',
        discription:'',
        designation:''
    })
    const [err,setErr]=useState({})
    const [VideoFile, setVideoFile] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [opt, setOpt] = useState('image');

    useEffect(()=>{
        dispatch(setReqData('percetage',''))
    },[])
    const handleChangeInput=(e)=>{
        setInputfield({...InputField,[e.target.name]:e.target.value})
    }

    const Submitform=(e)=>{
        e.preventDefault();
        let meadia
        if(opt==='image'){
            meadia=ImageFile===null ? "*Required":''
        }else{
            meadia=VideoFile===null ? "*Required":''
        }

        const error={
            title: requiredValidator(InputField.title),
            // discription: requiredValidator(InputField.discription),
            meadiaerr:meadia
        }
        if( !error.title && !error.meadiaerr){
            setErr(error)
            formData.append("title", InputField.title);
            formData.append("description", null);
            formData.append("image_file", ImageFile);
            formData.append("video_file",VideoFile );
            dispatch(createGallery(tokenData.token,formData,cb))
        }
        else{
            setErr(error)
        }
    }

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleVideoUpload = (e) => {
        setVideoFile(e.target.files[0]);
    };
      
    const cb=()=>{
        dispatch(listAllGalleries(tokenData.token))
        document.getElementById('form_element').reset()
        setInputfield({
            title:'',
            discription:'',
        })
        dispatch(setReqData('percetage',''))
        setVideoFile(null)
        setImageFile(null)
        setOpen(true)
    } 

    const ChangeOpt=(e)=>{
        setOpt(e.target.value)
    }
    return (
        <Fragment>

            <PageTitle
                heading="Create Gallery"
                subheading="Create Your Gallery"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />

                <Card className="main-card mb-3">
                    <Form onSubmit={Submitform} id='form_element'>
                    <CardHeader>
                        Create Gallery
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col className="pt-1  mt-2" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Title
                                    </Label>

                                    <Input
                                        type="text"
                                        name="title"
                                        value={InputField.title}
                                        invalid={err.title}
                                        onChange={handleChangeInput}
                                        autocomplete="off"
                                    />
                                    <FormFeedback>{err.title}</FormFeedback>

                            </Col>
                            
                            {/* <Col className="pt-1 mt-2" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Description
                                    </Label>

                                    <Input
                                        type="textarea"
                                        rows={2}
                                        name="discription"
                                        value={InputField.discription}
                                        invalid={err.discription}
                                        onChange={handleChangeInput}
                                    />
                                    <FormFeedback>{err.discription}</FormFeedback>

                            </Col> */}
                            
                            {/* <Col xs={12} sm={6} className="pt-1 mt-4">
                                <Label for="exampleNameF">
                                        Choose Image
                                    </Label>
                                <Input 
                                    type='select'
                                    name='opt'
                                    onChange={ChangeOpt}
                                    value={opt}
                                    invalid={err.meadiaerr}
                                >
                                    <option value='image'>Image</option>
                                    <option value='video'>Video</option>
                                </Input>
                            </Col> */}
                            
                            <Col className="pt-1 mt-4" xs={12} sm={6}>
                                {
                                    opt==='image' ? 
                                    <>
                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                                Image
                                        </Label>
                                        <Input 
                                            type='file'
                                            name='image'
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                    </>
                                    :
                                    <>
                                        <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                                Video
                                            </Label>
                                        <Input 
                                            type='file'
                                            name='video'
                                            accept="video/mp4,video/x-m4v,video/*"
                                            onChange={handleVideoUpload}
                                        />
                                    </>
                                }
                                    <span style={{color:'red'}}>{err.meadiaerr}</span>
                            </Col>

           
                            
                            <Col sm={12} className="pt-1 mp-2">
                            {(percetage !== ""  && (ImageFile || VideoFile )) ? (
                                <>
                                    <strong style={{ color: "rgb(19, 140, 228)" }}>
                                    Uploading Files...
                                    </strong>
                                    <Progress percent={percetage} />
                                </>
                                ) : null}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                    
                        <Col xs={12} sm={6}>
                            <LaddaButton
                                className="btn btn-primary"
                                loading={gallery}
                                type="submit"
                                data-style={ZOOM_IN}
                            >
                                Create
                            </LaddaButton>
                        </Col>
                    </CardFooter>
                            
                    </Form>
                </Card>

                <GalleryList/>

                <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={open}
                    text={"Created successfully"}
                    type="success"
                    onConfirm={() => setOpen(false)} />

        </Fragment>
    )
}

export default CreateGallery
