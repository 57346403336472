import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardTitle, CardBody, Input, Label } from 'reactstrap'
import Creatable from "react-select/lib/Creatable";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { 
    // createResellerPincode, 
    getAllStates, fillterDistricts, fillterPincodes, filterPincodeAssignment } from '../../../redux/Actions/Superadmin'
import { useDispatch, useSelector } from 'react-redux'
import DataTable from '../../common/DataTable'
import PageTitle from '../../common/HeaderTitle/PageTitle'
import PinAssignment from '../Reseller/PinAssignment'
import Model from "../../common/Modal";
function Index() {

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const [PinModel, setPinModel] = useState(false)

    const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
    const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
    const pincodes = useSelector((state) => state.superadmin.pincodes ? state.superadmin.pincodes : []);
    const resellersFiltered = useSelector((state) => state.superadmin.resellersFiltered ? state.superadmin.resellersFiltered : []);
   // const resellersFilteredLoader = useSelector((state) => state.superadmin.resellersFiltered ? state.superadmin.resellersFilteredLoader : false);

    const [PinCodes, setPinCodes] = useState([])

    const [Filter, setFilter] = useState({state_id:"",district_id:"",pincode_ids:"" })

    
    const columns = [
        { title: "Reseller Name", field: "reseller_name" },
        { title: "State", field: "state_name" },
        // { title: "District", field: "district_name" }
        
    ]
    console.log("")
    useEffect(() => {
        
        dispatch(getAllStates(tokenData.token))
        
    }, [])

    const [ResellerId, setResellerId] = useState(null)
    
    const ViewPincodes = (rowData) => {
        console.log("pincodesss", rowData)
        setResellerDetails(rowData)
        setResellerId(rowData.reseller_id)
        setPinModel(true);
    }

    const [Loader, setLoader] = useState(false) 
    const [ResellerDetails, setResellerDetails] = useState(null)

    const filter = () => {
        setLoader(true)
        let arr = []
        
        PinCodes.map((area) => {
            arr.push(area.pincode_id)
            return arr
        })
        
        
        dispatch(filterPincodeAssignment(tokenData.token,Filter,arr,cb))
    } 

    const cb = () => {
        setLoader(false)
    }
    
    // console.log("pincode pincode", resellersFiltered)
    const handleStateChange = (e) => {
        
        const {value} = e.target

         dispatch(fillterDistricts(tokenData.token, value));
         dispatch({ type: "RESET_PINCODES" }) 
        setFilter({...Filter, state_id:value})
    }
  
    const handleDistrictChange = (e) => {

        const {value} = e.target

        dispatch(fillterPincodes(tokenData.token, value));
        setFilter({...Filter, district_id:value})
    }

    return (
        <div>
            <PageTitle 
                heading="Filter"
                subheading="Filter Out Resellers By Area"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            
            />

            <Card>
                <CardTitle className="pl-4 pt-4" >Filter</CardTitle>
                <CardBody className="pt-0">
                    <Row>

                        <Col sm={3}>
                            <Label>State</Label>
                            <Input type="select" onChange={(e) => handleStateChange(e)} >
                                <option value={null} >Select</option>
                                {states.map((s) => {
                                    return (
                                        <option value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>
                        </Col>

                        <Col sm={3}>
                            <Label>District</Label>
                            <Input type="select" onChange={(e) => handleDistrictChange(e)} >
                                <option value={null} >Select</option>
                                {districts.map((s) => {
                                    return (
                                        <option value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>
                        </Col>
                        {pincodes &&
                            <Col sm={6}>
                                <Label>Panchayath</Label>
                                <Creatable
                                    styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                    isMulti
                                    getOptionLabel={option =>
                                        `${option.pin}${option.place}`
                                    }
                                    getOptionValue={option =>
                                        `${option.pincode_id}`
                                    }
                                    options={pincodes}
                                    name="list"
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    onChange={(e) => setPinCodes(e)}
                                />
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col xs={2} sm={10} ></Col>
                        <Col xs={10} sm={2} style={{textAlign:'right'}} >
                            <LaddaButton

                                className="mt-2 btn btn-primary"
                                loading={Loader}
                                type="submit"
                                data-style={ZOOM_IN}
                                onClick={filter}
                            >
                                Filter
                            </LaddaButton>
                            {/* <LaddaButton

                                className="mt-2 mr-2 btn btn-success"
                                loading={false}
                                type="submit"
                                data-style={ZOOM_IN}
                                onClick={""}
                            >
                                Export
                            </LaddaButton> */}


                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <div className="mt-4">
                <DataTable

                    // hadleOpenModal={(id) => props.hadleOpenModal(id)}
                    actions={'pin_filter'}

                    columns={columns}
                    // handleEdit={(class_id, teacher_id) => props.handleEdit(class_id, teacher_id)}
                    data= {resellersFiltered}
                    title={"Resellers"}
                    loader={Loader}
                    ViewPincodes={ViewPincodes}
                />

            </div>
            {ResellerDetails &&
            <Model
              size='xl'
              open={PinModel}
              handleClose={() => { setPinModel(false) }}
              title='Update Panchayath'

            >
              <PinAssignment 
            
                reseller_data={ResellerDetails}
                reseller_id={ResellerDetails.reseller_id}
               setPinModel={setPinModel}
              />
            </Model>
            }
        </div>
    )
}

export default Index
