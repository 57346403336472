import React, {  useEffect } from 'react'
import { assignTeachersToGroup } from '../../../../redux/Actions/grouping'
import { useDispatch } from 'react-redux'
import serialize from 'form-serialize'
import Loader from 'react-loaders'


import {
    Row, Col,
    Card, CardBody, 
    CardHeader, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const ListStudent = (props) => {
    useEffect(() => {

        return () => {
            dispatch({ type: 'RESET_ASSIGN_STUDENT_VIDEO' })
        }
    }, [])
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();


   // const pStore = useSelector(({ playlist }) => playlist)


    const handleClick = (e) => {
        e.preventDefault();
        //  console.log("props.timeData", props.timeData)


        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(assignTeachersToGroup(tokenData.token, body, props.handleCloseStudentModal))

    };
    return (
        <Form onSubmit={handleClick}>
            <input name="group_id" type="hidden" value={props.groupID} />
            <Card className="main-card mb-3">
                <CardHeader>
                    <Col md={6} sm={6}>
                        Student
                    </Col>
                    <Col md={6} sm={6}>
                        Phone
                    </Col>
                </CardHeader>
                <CardBody>
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>


                            {
                                props.loader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {props.data ? props.data.map(dt => (
                                <Row key={dt.user_id}>
                                    <Col md={6} sm={6} style={{ paddingLeft: '30px' }}>
                                        {/* {`${dt.first_name} ${dt.last_name}`} */}
                                        {dt.full_name}
                                    </Col>
                                    <Col md={6} sm={6} style={{ paddingLeft: '20px' }}>
                                        {`${dt.enterprise_mobile}     `}
                                    </Col>

                                </Row>

                            )) : null}

                        </PerfectScrollbar>
                    </div>
                </CardBody>

            </Card>
        </Form>
    )
}

export default ListStudent