import React, { Fragment, useState, useEffect } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
// import { Vimeo } from './vimeo'
import ReactPlayer from 'react-player'
import {
    Card, Row, Col, CardHeader,

} from 'reactstrap';
import VideoCard from '../../common/VideoCard'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import ReactHtmlParser from 'react-html-parser';
// import Player from '@vimeo/player';

import Modals from '../../common/Modal';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import { getVideoList, validateVideoClass } from '../../../redux/Actions/videolist'
import RequestVideo from './RequestVideo'
// import video1 from '../../../assets/images/video1.mp4'
// import video2 from '../../../assets/images/video2.mp4'

import './ListVideo.css'

const ListVideo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tStore = useSelector(({ videolist }) => videolist)
    var videoDetail = tStore.videosListDetail;
    // const [videoDetail, setVideoDetail] = useState(null)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const param = useParams()

    useEffect(() => {
        //  debugger
        dispatch(getVideoList(tokenData.token, param.class_id,'',callIframe, empty))

        // const script = document.createElement("script");

        // script.src = "https://player.vimeo.com/api/player.js";
        // // script.async = true;

        // document.body.appendChild(script);

    }, []);
    const [allVideos, setAllVideos] = useState([])
    const empty = () => {
        // history.push('/dashboard/videoClass')
        history.goBack()
    }
    const callIframe = (video_id, data) => {
        setVideoId(data.videos[0].video_class_id)
        //  debugger
        const videoUrls = data.videos.map(video => {
            return video.video_url
        })
        setAllVideos(videoUrls)
        // setTimeout(function () { iFrame(video_id, 0) }, 3000);

        // iFrame(video_id, "start", data)
    }


    const [count, setCount] = useState(0);
    const [videoId, setVideoId] = useState()
    const switchData = (index, video_id) => {
        setCount(index)
        setVideoId(video_id)
    }
    const handleCloseStudentModal = () => {
        setOpenModal(false)
    }




    const [openModal, setOpenModal] = useState(false)
    // const reviewModel = () => {
    //     setOpenModal(true)
    // }

    // const [co, setCo] = useState(0)
    //const [vid, setVid] = useState(["https://player.vimeo.com/video/450016827", "https://player.vimeo.com/video/450016827", "https://player.vimeo.com/video/450462855", "https://player.vimeo.com/video/450016827"])
    // var player;
    // if (player) {
    //     player.on('play', function () {
    //         // player.addEvent('finish', alert("Finished"));
    //         player.pause();
    //         console.log("played")
    //         dispatch(validateVideoClass(tokenData.token, videoId))
    //         // player.play();

    //     });
    // }
    // if (videoDetail.videos && videoDetail.videos.length > 0) {
    //     setVideoId(videoDetail.videos[0].video_id)

    // }
    // const [players, setPlayers] = useState()
    // const [url, setUrl] = useState('https://vimeo.com/453942051')
    const [show, setShow] = useState({ playing: false })
    const [validatedVideo, setValidatedVideo] = useState('')
    const ply = (video_class_id) => {
        // alert(video_class_id)
        setShow({ playing: true })

        if (validatedVideo !== video_class_id) {
            setShow({ playing: false })
            dispatch(validateVideoClass(tokenData.token, videoId, param.class_id, videoApproved))

        }

    }
    const videoApproved = (video_class_id) => {
        setShow({ playing: true })
        setValidatedVideo(video_class_id)
        setTimeout(function () { setValidatedVideo('') }, 300000);

    }
    // const iFrame = (video_id, index = "start", iframeData = null) => {
    //     // debugger

    //     var iframe = document.querySelector("iframe");
    //     console.log("iframe++++++", iframe)

    //     var elementSatus = isElement(iframe)

    //     if (elementSatus === true) {
    //         console.log("elementSatus", iframe.src)
    //         // if (iframe.src.includes("vimeo")) {
    //         var player
    //         if (index === "start") {
    //             // let ele = new DOMParser().parseFromString(iframeData.videos[0].embed, "text/html");

    //             player = new Player(iframe);

    //         } else {
    //             // let ele = new DOMParser().parseFromString((videoDetail.videos[index].embed), "text/html");

    //             player = new Player(iframe);
    //         }
    //         player.on('play', function () {
    //             // player.addEvent('finish', alert("Finished"));
    //             // player.pause();
    //             console.log("played")
    //             // dispatch(validateVideoClass(tokenData.token, video_id, playNow))
    //             // const playNow = () => player.play();

    //         });
    //         // } else {
    //         //     var player = new Player(ReactHtmlParser(videoDetail.videos && videoDetail.videos[index].embed));
    //         //     player.on('play', function () {
    //         //         // player.addEvent('finish', alert("Finished"));
    //         //         // player.pause();
    //         //         console.log("played")
    //         //         // dispatch(validateVideoClass(tokenData.token, video_id, playNow))
    //         //         // const playNow = () => player.play();

    //         //     });
    //         // }

    //     }
    //     setPlayers(player)

    // }


    // var iframe = document.querySelector('iframe');
    // var player = new Vimeo.Player(iframe);

    // player.on('play', function () {
    //     console.log('Played the video');
    // });

    // player.getVideoTitle().then(function (title) {
    //     console.log('title:', title);
    // });


    // const renderIframe = (index = 0) => {
    //     //alert(index)

    //     return ReactHtmlParser(videoDetail.videos && videoDetail.videos[index].embed)


    // }
    // const renderPlayer = () => {

    //     return (
    //         <div>
    //             {FILES.map(url => {
    //                 const playing = this.state.index === FILES.indexOf(url)
    //                 return (
    //                     <ReactPlayer
    //                         url={url}
    //                         className={playing ? null : 'hidden'}
    //                         playing={playing}
    //                         controls
    //                         width='100%'
    //                         height='100%'
    //                         onEnded={this.onEnded}
    //                     />
    //                 )
    //             })}
    //         </div>
    //     )
    // }


    const videolisting = (videoDetail) => {

        var videos = videoDetail.videos && videoDetail.videos.length > 0 ? videoDetail.videos : '';
        return videos && videos.map((item, index) => {
            return (
                <Row onClick={() => switchData(index, item.video_class_id)}>
                    <Col lg={12} md={12} sm={12} xs={12} style={{ paddingRight: 22, marginBottom: 10 }}>
                        <VideoCard
                            thumbnile={item.thumbnails[2].link}
                            title={item.video_title}
                            duration={item.video_duration}
                            type="player"
                            data={item}

                        />
                    </Col>
                </Row>


                // <div className="video-sidelist">
                //     <Row>
                //         <Col lg="6" md="6" xs="6">
                //             <div class="vjs-playlist-thumbnail vjs-playlist-now-playing" onClick={() => switchData(index)}>
                //                 <source srcSet="//vjs.zencdn.net/v/oceans.png" type="image/png" media="(min-width: 100px;)" />
                //                 <img width="150px" height="120px" alt="" src={item.poster_url ? item.poster_url : '//vjs.zencdn.net/v/oceans.png'} />
                //             </div>
                //         </Col>
                //         <Col lg="6" md="6" xs="6">
                //             <div class="vjs-playlist-title-container" onClick={() => switchData(index)}>
                //                 <cite class="vjs-playlist-name" >{item.video_title}</cite><br />
                //                 {/* <time class="vjs-playlist-duration" datetime="PT0H0M888S">14:48</time><br/> */}

                //             </div>
                //             {/* <button type="button" title="Re-view Video" className="btn-primary review-button" onClick={() => reviewModel()}>Re-view the video</button> */}
                //         </Col>
                //     </Row>
                //     {/* <span class="vjs-playlist-now-playing-text" title="Now Playing">Now Playing</span> */}
                // </div>
            )
        })
    }

    if (videoDetail) {
        return (
            <Fragment>
                <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div>
                        <PageTitle
                            heading={videoDetail && videoDetail.class_name}
                            subheading={`by ${videoDetail && videoDetail.teacher_name}`}
                            icon="lnr-film-play text-info"
                        />


                        <Row>
                            <Col md={8} lg={8} xs={12} sm={12} >
                                <Card className="main-card mb-3" style={{ background: '#ffffff', color: 'black' }}>

                                    <div className='player-wrapper'>


                                        {/* {renderPlayer()} */}


                                        {
                                            allVideos.map((url, index) =>
                                                count === index ?


                                                    (
                                                        <ReactPlayer
                                                            url={url}
                                                            // style={playing ? { display: 'none' } : ''}
                                                            onPlay={tokenData.role === "student" ? () => ply(videoDetail.videos[count].video_class_id) : null}
                                                            onPause={() => setShow({ playing: false })}
                                                            playing={show.playing}
                                                            controls={true}
                                                        // config={{
                                                        //     file: {
                                                        //         attributes: {
                                                        //             src: 'file.mp4'
                                                        //         }
                                                        //     }
                                                        // }}
                                                        />
                                                    ) :
                                                    (<></>)

                                            )
                                        }
                                    </div>
                                    {/* <p>iframe</p> */}
                                    {/* {ReactHtmlParser(videoDetail.videos && videoDetail.videos[count].embed)} */}
                                    {/* <hr /> */}

                                    <div class="vjs-playlist-title-container" style={{ padding: '20px 20px 5px 20px' }}>
                                        <h4 style={{ textTransform: 'capitalize' }} >{videoDetail.videos && videoDetail.videos[count].video_title}</h4>

                                        <h5 style={{ fontWeight: 500, color: 'aquamarine' }} class="vjs-playlist-name" title="Disney's Oceans">{videoDetail && videoDetail.subject_name}</h5>
                                        {/* <span class="jms-span-video-details" >{videoDetail && videoDetail.teacher_name}</span><br /> */}
                                        {/* <p>{videoDetail.videos && videoDetail.videos[count].from_date} - {videoDetail.videos && videoDetail.videos[count].to_date}</p> */}
                                        {/* <p>{videoDetail.videos && videoDetail.videos[count].from_time} - {videoDetail.videos && videoDetail.videos[count].to_time}</p> */}
                                        {/* <p style={{ fontSize: 11 }}>This video you should watch before {`${videoDetail.videos && videoDetail.videos[count].to_date}-${videoDetail.videos && videoDetail.videos[count].to_time}`}</p> */}
                                    </div>

                                    <Row style={{ padding: '0px 23px 14px 26px' }}>
                                        <Col md={12}>
                                            <p style={{ fontSize: 12, margin: 0 }}>
                                                {/* {`Video available from ${videoDetail.videos && videoDetail.videos[count].from_time} to ${videoDetail.videos && videoDetail.videos[count].to_time} between date ${videoDetail.videos && videoDetail.videos[count].from_date} to ${videoDetail.videos && videoDetail.videos[count].to_date}`} */}
                                            </p>

                                        </Col>


                                        {/* <Col md={6}>
                                            <p style={{ fontSize: 8, margin: 0 }}> From</p>
                                            <p style={{ fontSize: 10, margin: 0 }}> {`${videoDetail.videos && videoDetail.videos[count].from_date}`}</p>
                                            <p style={{ fontSize: 9, margin: 0 }}>{videoDetail.videos && videoDetail.videos[count].from_time}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p style={{ fontSize: 8, margin: 0 }}>To</p>
                                            <p style={{ fontSize: 10, margin: 0 }}> {`${videoDetail.videos && videoDetail.videos[count].to_date}`}</p>
                                            <p style={{ fontSize: 9, margin: 0 }}>{videoDetail.videos && videoDetail.videos[count].to_time}</p>
                                        </Col> */}
                                    </Row>

                                </Card>
                            </Col>
                            <Col md={4} lg={4} xs={12} sm={12}>
                                <Card className="main-card mb-3">
                                    <CardHeader>Videos({videoDetail && videoDetail.videos_count})</CardHeader>

                                    {videolisting(videoDetail)}



                                </Card>
                            </Col>
                        </Row>

                    </div>
                    <Modals
                        open={openModal}
                        handleClose={handleCloseStudentModal}
                        title={`Request Form`}
                    >
                        <RequestVideo
                            class_id={param.class_id}
                        />
                    </Modals>
                </CSSTransitionGroup>
            </Fragment >
        );
    }
    else {
        return (
            <div></div>
        )
    }
}

// function isElement(obj) {
//     try {
//         //Using W3 DOM2 (works for FF, Opera and Chrome)
//         return obj instanceof HTMLElement;
//     }
//     catch (e) {
//         //Browsers not supporting W3 DOM2 don't have HTMLElement and
//         //an exception is thrown and we end up here. Testing some
//         //properties that all elements have (works on IE7)
//         return (typeof obj === "object") &&
//             (obj.nodeType === 1) && (typeof obj.style === "object") &&
//             (typeof obj.ownerDocument === "object");
//     }
// }
export default ListVideo;