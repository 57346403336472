import React, { useState } from "react";
import {
  Col,
  Row,
  Input
} from "reactstrap";

import "./chooseFromPlaylist.scss";
import NumericInput from "react-numeric-input";
import simpleNumberLocalizer from "react-widgets-simple-number";

import {
  EditorState,
  ContentState,
  // convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';



simpleNumberLocalizer();

const UploadVideos = (props) => {
  const {
    fromDate,
    toDate,
    playlistsVideoId,
    index,
    fromTime,
    toTime,
    order,
    selectedIdOrigin,
    watch,
    type,
    title,
    duration,
    playlist_video_id,
    format,
    id,
    url,
    syncVideo,
    multiSelectVideo,
    videoId,
    mutliSelect,
    thumbnile,
    handleTime,
    handleClickSync,
    handleChange,
    check,
    limit,
    description,
    class_video_id
  } = props;
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [perval, setPerVal] = useState(limit ? limit :100)
  const [desc, setDesc] = useState(description)
  const [orderVal, setOrderVal] = useState(order ? order : 1)

  const html = description ? description : ''
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);

  const [videoDescription, setvideoDescription] = useState(editorState) //literal description about course
  const [videoDescriptionArr, setvideoDescriptionArr] = useState([])
  // const editorState = EditorState.createWithContent(contentState);

  const onChangeOrder = (val) => {
    setOrderVal(val)
  }
  const onChangePercentage=(val)=>{
    setPerVal(val)
  }

  const ChangeDesc=(e)=>{
    setDesc(e.target.value)
  }

  const onEditorStateChange = (editorState) => {
    setvideoDescription(editorState);
    // VideoDescFunc(editorState,videoId)
    
    };

  return (
    <>
      {type == "upload_video" ? (
        <>
          <Col
            md={4}
            lg={4}
            sm={4}
            xs={4}
            style={{ marginBottom: 25 }}
            className="jms-choose-from-playlist-thumb-video-col-3"
          >
            <input
              type="hidden"
              disabled={!mutliSelect.includes(videoId)}
              value={videoId}
              name="videos[video_id][]"
            />

            <div
              className="jms-choose-from-playlist-thumb-card"
              style={{ backgroundImage: `url(${thumbnile})` }}
            >
              <input
                style={{ marginLeft: 6 }}
                type="checkbox"
                id={`id-${id}`}
                checked={mutliSelect.includes(videoId)}
                value={videoId}
                className="jms-choose-from-playlist-thumb-checkbox"
                onChange={() => multiSelectVideo(videoId)}
                name="muliselect"
              />
              <div className="jms-choose-from-playlist-thumb-title">
                {" "}
                {title}
              </div>
              <div className="jms-choose-from-playlist-thumb-size">
                {duration}
              </div>
              <div className="jms-choose-from-playlist-thumb-format-choose">
                <span style={{ float: "right", fontSize: 11 }}>
                  Sync &nbsp;{` `}
                  <input
                    // style={{ float: 'right' }}
                    onChange={() => handleClickSync(videoId)}
                    value={isToggleOn}
                    type="checkbox"
                    id={`sync${videoId}`}
                    name={`sync-${videoId}`}
                    disabled={!mutliSelect.includes(videoId)}
                    checked={syncVideo.includes(videoId)}
                  />
                </span>
              </div>
            </div>
          </Col>
        </>
      ) : (
          <Row
            style={
              playlistsVideoId.includes(playlist_video_id)
                ? {
                  padding: "6px 0px 6px 10px",
                  borderBottom: "1px solid #dedada",
                  background: "aliceblue",
                }
                : {
                  background: "#e9ecef",
                  padding: "6px 0px 6px 10px",
                  borderBottom: "1px solid #dedada",
                }
            }
          >
            <Col
              md={4}
              lg={4}
              sm={12}
              xs={12}
              className="jms-choose-from-playlist-thumb-video-col-3"
            >
      
              <input
                type="hidden"
                disabled={!playlistsVideoId.includes(playlist_video_id)}
                value={videoId}
                name="videos[video_id][]"
              />
              <input
                type="hidden"
                disabled={!playlistsVideoId.includes(playlist_video_id)}
                value={playlist_video_id}
                name="videos[playlist_video_id][]"
              />

              <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'2021-03-20'} name="videos[video_from_date][]" />
              <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'2021-03-24'} name="videos[video_to_date][]" />

              <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'10:26 am'} name="videos[video_from_time][]" />
              <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'10:30 am'} name="videos[video_to_time][]" />

              <Row>
                <Col md={12}>
                  <div
                    className="jms-choose-from-playlist-thumb-card"
                    style={{ backgroundImage: `url(${thumbnile})`,backgroundSize:'cover',backgroundRepeat:'no-repeat' }}
                  >
                    <input
                      type="checkbox"
                      checked={playlistsVideoId.includes(playlist_video_id)}
                      value={videoId}
                      className="jms-choose-from-playlist-thumb-checkbox"
                      onClick={() =>
                        multiSelectVideo(videoId, playlist_video_id, index,class_video_id)
                      }
                    />

                    <div className="jms-choose-from-playlist-thumb-title">
                      {" "}
                      {title}
                    </div>
                    <div className="jms-choose-from-playlist-thumb-size">
                      {duration}
                    </div>
                    <div className="jms-choose-from-playlist-thumb-format">
                      {format}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md={8} lg={8} sm={12} xs={12}>
              <Row style={{ paddingTop: 5 }}>
                <Col md={4}>
                    Order
                  <div>
                    <NumericInput
                      // required
                      min={1}
                      mobile
                      className="jms-number-height"
                      name="videos[order_of_video][]"
                      disabled={!playlistsVideoId.includes(playlist_video_id)}
                      value={orderVal}
                      onChange={onChangeOrder}

                    />
                  </div>

                </Col>
                {
                    check.percentage ?  
                    <Col md={4}>
                        % limit
                    <div>
                        <NumericInput
                            // required
                            min={1} max={100} mobile className="jms-number-height"
                            onChange={onChangePercentage}
                            value={perval}
                            // id={`order-${index}`}
                            name="videos[percentage][]" disabled={!playlistsVideoId.includes(playlist_video_id)} />

                    </div>

                </Col>
                :''
                }
                {
                  type === "create_class" && playlistsVideoId.includes(playlist_video_id) && check.description ?
                  
                    <Col xs={12}>
                        Description
                    <div className="vidDescEditor">
                        {/* <Input
                            type='textarea'
                            name="videos[description][]" disabled={!playlistsVideoId.includes(playlist_video_id)} 
                            value={desc}
                            onChange={ChangeDesc}
                          /> */}
                          {/* {console.log("videoDescription",videoDescription)} */}
                          <input
                            type="hidden"
                            disabled={!playlistsVideoId.includes(playlist_video_id)}
                            value={draftToHtml(convertToRaw(videoDescription.getCurrentContent()))}
                            name="videos[description][]"
                          />
                          <Editor
                            // toolbarHidden
                            name="videos[description][]"
                            editorState={videoDescription }
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                            inline: { inDropdown: true },
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                            link: { inDropdown: true },
                            history: { inDropdown: true },
                            image: { inDropdown: false },
                            options: ['inline', 'link','history','fontSize','fontFamily','list']
                            }}
                        />
                    </div>
                    </Col>
                  :''
              }

                <Col md={6}>
                  {type == "upload_video" ? (
                    <>
 
                     
                      <span style={{ float: "right", fontSize: 11 }}>
                        Sync this &nbsp;{` `}
                        <input
                          // style={{ float: 'right' }}
                          onClick={() => handleClickSync(videoId)}
                          value={isToggleOn}
                          type="checkbox"
                          disabled={!playlistsVideoId.includes(playlist_video_id)}
                          checked={syncVideo.includes(videoId)}
                        />
                      </span>
                    </>
                  ) : type == "create_class" ? (
                    <>
              
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
    </>
  );
};
UploadVideos.defaultProps = {
  syncVideo: [],
  mutliSelect: [],
};
export default UploadVideos;

