import React, { Fragment, } from 'react';
// import ScriptTag from 'react-script-tag';


import Header from '../../header';
import SingleCourse from './SingleCourse'
import Footer from "../../HomePage/Footer/Footer"



import '../../css/animate.css';
// import "./css/bootstrap.min.css"
import '../../css/elegant_icon.css';
// import './css/flag-icon.min.css';
import "../../css/genex-custom.css"
import '../../css/hover-min.css';
import "../../css/style.css"
import "../../css/style.css.map"
import "../../css/swiper.css"

import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";

const SingleCourseIndex = () => {

    const Menu_section = useSelector((state) =>state.genexhome.Menu_section ? state.genexhome.Menu_section : "");


	return (
		<Fragment>
           
            <Header/>
            {
                Menu_section ?
                <>
                <SingleCourse/>
                <Footer/>
                </>
                :
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress />
                </div>
                    
            }

		</Fragment>
	);
}
export default SingleCourseIndex;