import React,{useEffect} from "react";
import Container from "reactstrap/lib/Container";

import { listAllSubscriptions,setSubscriptionData } from "../../../../redux/Actions/subscription-action"

import { useDispatch, useSelector } from 'react-redux'
import { useHistory,useParams } from "react-router-dom";

import Tooltip from '@material-ui/core/Tooltip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
import nodata from '../../../LandingPageNew/images/nodata.png'

import {
  Input, InputGroup, 
  Row, Col,Table,Card,CardHeader,Form
 
} from 'reactstrap';



function VendorSubscriptiondetails(props) {

const dispatch = useDispatch();
const tokenData = JSON.parse(localStorage.getItem("authDetails"));
const history = useHistory();
const params=useParams()

const Subscription_Array = useSelector((state) => state.Subscription.Subscription_Array ? state.Subscription.Subscription_Array : []);

const nextpage = useSelector((state) => state.Subscription.Subscription_next_page_url ? state.Subscription.Subscription_next_page_url : '');
const prevpage = useSelector((state) => state.Subscription.Subscription_first_page_url ? state.Subscription.Subscription_first_page_url : '');
const subloader = useSelector((state) => state.Subscription.subloader ? state.Subscription.subloader : false);

const page = useSelector((state) => state.Subscription.subscription_page ? state.Subscription.subscription_page : 1);
const search = useSelector((state) => state.Subscription.sub_search_term ? state.Subscription.sub_search_term : '');

const from = useSelector((state) => state.Subscription.from_sub ? state.Subscription.from_sub : '');
const to = useSelector((state) => state.Subscription.to_sub ? state.Subscription.to_sub : '');

useEffect(() => {
    dispatch(listAllSubscriptions(tokenData.token,page,search,props.user_id))
}, [page])


const NextPage=()=>{
    dispatch(setSubscriptionData('subscription_page',page+1))
}
const PreviousPage=()=>{
    dispatch(setSubscriptionData('subscription_page',page-1))
}


const HandleChange=(e)=>{
    e.preventDefault()
    if(e.target.value){
        dispatch(setSubscriptionData('sub_search_term',e.target.value))
    }
    else{
        dispatch(setSubscriptionData('sub_search_term',''))
        if(page===1){
            dispatch(listAllSubscriptions(tokenData.token,1,'',props.user_id))
        }
        else{
            dispatch(setSubscriptionData('subscription_page',1))
        }
    }

}

const Searchcourse=(e)=>{
   e.preventDefault()
    if(page===1){
        dispatch(listAllSubscriptions(tokenData.token,1,search,props.user_id))
    }
    else{
        dispatch(setSubscriptionData('subscription_page',1))
    }
}

const Vendorcourse=(val)=>{
    history.push(`/dashboard/vendor/${params.enterprise_id}/subscription/${val.id}`)
}
  return (
    <>
    {
      Subscription_Array.length > 0 ? 
      <Container>
        
        <Row>
          
          <Col sm={12} md={12}>
                <LoadingOverlay
                  active={subloader}
                  spinner
                  text='Loading...'
                >
                  <Card className="main-card mb-3">
                    <CardHeader>
                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal" style={{width:'50%'}}>
                        Subscription List
                      </div>
                      <div style={{width:'50%',display:'flex',justifyContent:'flex-end'}}>
                      <Form onSubmit={Searchcourse} >
                        <InputGroup>
                          <Input type='search' placeholder="Search..." onChange={HandleChange} value={search ? search:''}/>
                                <Button color='primary' 
                                    onClick={Searchcourse} 
                                    size='small' 
                                    variant='contained'
                                    disabled={search ? false:true || subloader} >
                                      search
                                  </Button>
                        </InputGroup>
                      </Form>
                      </div>
                    </CardHeader>
                    {/* {
                      Subscription_Array.length > 0 ? */}

                      <Table responsive borderless hover className="align-middle text-truncate mb-0">
                      <thead>
                          <tr>
                              <th>Subscription Name</th>
                              <th>Subscription fee</th>
                              <th>Subscription Type</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                    
                      <tbody>
                        {
                          Subscription_Array.map((val,ky)=>{
                            return(
                              <tr key={ky}>
                                  <td>{val.subscription_name}</td>
                                  <td>{val.subscription_fee} {val.currency_code}</td>
                                  <td>{val.subscription_type}</td>
                                  <td>{val.subscription_start_date}</td>
                                  <td>{val.subscription_end_date}</td>
                                  <td>
                                  
                                      <Tooltip title='View Details'>
                                        <Button  color="secondary" variant='contained' size='small'
                                         onClick={()=>{Vendorcourse(val)}}
                                          >
                                        <span>View</span>
                                        </Button>
                                      </Tooltip>
                                  </td>
                              </tr>
                            )
                          })
                        }
                     
                    </tbody>
    

                </Table>
                {/* :
                <Table responsive borderless hover className="align-middle text-truncate mb-0">
                      <thead>
  
                      </thead>
                      
                      <tbody>
                        <tr>
                          <td style={{width:'100%',textAlign:'center'}}>
                          <img src={nodata} width='500px' height='300px' alt='' />
                          </td>
                        </tr>
                      </tbody>
                      </Table>
                    } */}
                
                <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                      <div style={{width:'30%',display:'flex',height:'50px',justifyContent:'flex-end'}}>

                        <span style={{marginTop:'13px'}}>{from}-{to}</span>
                          <Button
                            onClick={PreviousPage}
                            disabled={prevpage ? false :true}
                          >
                            <NavigateBeforeIcon />
                          </Button>
                          <Button
                            onClick={NextPage}
                            disabled={nextpage ? false :true}
                          >
                            <NavigateNextIcon />
                          </Button>
                      </div>
              </div>
                </Card>
                </LoadingOverlay>

          </Col>
        </Row>

        
      </Container>
      :null
    }
      
    </>
  );
}

export default React.memo(VendorSubscriptiondetails);
