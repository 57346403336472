import React,{useState,useEffect,Fragment} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    // Badge,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { requiredValidator } from "../../../utilities/Validator";
import { createTestimonial,setReqData } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { Progress } from "react-sweet-progress";


function CreateTestimonial(){

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const formData = new FormData();

    const testimony = useSelector((state) =>state.superadmin.testimony ? state.superadmin.testimony : false);
    const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

    
    const [InputField,setInputfield]=useState({
        title:'',
        discription:'',
        designation:''
    })
    const [err,setErr]=useState({})
    // const [VideoFile, setVideoFile] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        dispatch(setReqData('percetage',''))
    },[])
    const handleChangeInput=(e)=>{
        setInputfield({...InputField,[e.target.name]:e.target.value})
    }

    const Submitform=(e)=>{
        e.preventDefault();
        let meadia=ImageFile===null ? "*Required":''

        const error={
            title: requiredValidator(InputField.title),
            discription: requiredValidator(InputField.discription),
            designation: requiredValidator(InputField.designation),
            meadiaerr:meadia
        }
        if( !error.title && !error.discription && !error.designation && !error.meadiaerr){
            setErr(error)
            formData.append("title", InputField.title);
            formData.append("description", InputField.discription);
            formData.append("image_file", ImageFile);
            formData.append("designation",InputField.designation );
            dispatch(createTestimonial(tokenData.token,formData,cb))
        }
        else{
            setErr(error)
        }
    }

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };

    // const handleVideoUpload = (e) => {
    //     setVideoFile(e.target.files[0]);
    // };
      
    const cb=()=>{
        document.getElementById('form_id').reset()
        setInputfield({
            title:'',
            discription:'',
            designation:''
        })
        dispatch(setReqData('percetage',''))
        // setVideoFile(null)
        setImageFile(null)
        setOpen(true)
    } 
    return(
        <Fragment>

            <PageTitle
                heading="Create Testimony"
                subheading="Create Your Testimony Here"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />

                <Card className="main-card mb-3 ">
                    <Form onSubmit={Submitform} id='form_id'>
                    <CardHeader>
                        Create Testimonial
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col className="pt-1" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Title
                                    </Label>

                                    <Input
                                        type="text"
                                        name="title"
                                        value={InputField.title}
                                        invalid={err.title}
                                        onChange={handleChangeInput}
                                        autocomplete="off"
                                    />
                                    <FormFeedback>{err.title}</FormFeedback>

                            </Col>


                            <Col className="pt-1" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                    Designation
                                    </Label>

                                    <Input
                                        type="text"
                                        name="designation"
                                        value={InputField.designation}
                                        invalid={err.designation}
                                        onChange={handleChangeInput}
                                        autocomplete="off"
                                    />
                                    <FormFeedback>{err.designation}</FormFeedback>

                            </Col>

                            
                            <Col className="pt-1" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Description
                                    </Label>

                                    <Input
                                        type="textarea"
                                        rows={2}
                                        name="discription"
                                        value={InputField.discription}
                                        invalid={err.discription}
                                        onChange={handleChangeInput}
                                    />
                                    <FormFeedback>{err.discription}</FormFeedback>

                            </Col>
                            
                            <Col className="pt-1" xs={12} sm={6}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Image
                                    </Label>
                                <Input 
                                    type='file'
                                    name='image'
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                <span style={{color:'red'}}>{err.meadiaerr}</span>
                            </Col>

                            {/* <Col className="pt-1" xs={12} sm={6}>
                                <Label for="exampleNameF">
                                        Video
                                    </Label>
                                <Input 
                                    type='file'
                                    name='video'
                                    accept="video/mp4,video/x-m4v,video/*"
                                    onChange={handleVideoUpload}
                                />
                            </Col> */}
                            
                            <Col sm={12}>
                            {(percetage !== ""  && ImageFile ) ? (
                                <>
                                    <strong style={{ color: "rgb(19, 140, 228)" }}>
                                    Uploading Files...
                                    </strong>
                                    <Progress percent={percetage} />
                                </>
                                ) : null}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                    
                        <Col xs={12} sm={6}>
                            <LaddaButton
                                className="btn btn-primary"
                                loading={testimony}
                                type="submit"
                                data-style={ZOOM_IN}
                            >
                                Create
                            </LaddaButton>
                        </Col>
                    </CardFooter>
                            
                    </Form>
                </Card>



                <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={open}
                    text={"Created successfully"}
                    type="success"
                    onConfirm={() => setOpen(false)} />

        </Fragment>
    )
}

export default CreateTestimonial