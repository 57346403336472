import React, { Fragment,useEffect,useState } from 'react';

import Header from '../header';
import SingleCourse from './CourseDetails'
import Footer from "../HomePage/Footer/Footer"

import '../css/animate.css';
import '../css/elegant_icon.css';
import "../css/genex-custom.css"
import '../css/hover-min.css';
import "../css/style.css"
import "../css/style.css.map"
import "../css/swiper.css"

import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector,useDispatch } from "react-redux";
import { useHistory,useParams } from "react-router-dom";

import { getSingleCourseDetails,setHomeData } from "../../../redux/Actions/genexhome"
// import { logout } from '../../../redux/Actions/auth'


const Index = () => {

    const params = useParams()
    const dispatch = useDispatch()
    const history = useHistory()

    const [check,setCheck]=useState(false)
    const Menu_section = useSelector((state) =>state.genexhome.Menu_section ? state.genexhome.Menu_section : "");

    useEffect(()=>{
        dispatch(getSingleCourseDetails(params.token,params.course_id,cb))

        return()=>{
            dispatch(setHomeData('Single_Course_Details',[]))
        }
    },[])

    const cb=(val)=>{
        if(val){
            setCheck(true)
        }
        else{
            // dispatch(LogoutSession(params.token))
            localStorage.removeItem("authDetails");
            history.push('/')
        }
    }
   
	return (
		<Fragment>
           
            <Header/>
            {
                Menu_section && check ?
                <>
                    <SingleCourse />
                    <Footer />
                </>
                :
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        minHeight: "100vh",
                    }}
                >
                    <CircularProgress />
                </div>
                    
            }

		</Fragment>
	);
}
export default Index;