import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'reactstrap';
import Table from '../common/DataTable'
import { getMyStudents, getAllTeacherStudents } from '../../redux/Actions/homeTeacher'

var columns
// var studentData

const ListStudents = () => {
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem('authDetails'));
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    if (tokenData.role === 'teacher') {
        columns = [
            { title: 'Student ', field: 'name', defaultSort: 'asc', headerStyle: { fontWeight: '600' } },
            { title: ' Course', field: 'grade', headerStyle: { fontWeight: '600' } },
            { title: ' Email', field: 'email', headerStyle: { fontWeight: '600' } }

        ]
    } else {
        columns = [
            { title: ' Enrollment No', field: 'enrollment_no', headerStyle: { fontWeight: '600' } },
            { title: 'Student ', field: 'full_name', defaultSort: 'asc', headerStyle: { fontWeight: '600' } },
            { title: ' Course', field: 'group_name', headerStyle: { fontWeight: '600' } },
            { title: ' Email', field: 'enterprise_email', headerStyle: { fontWeight: '600' } }
        ]
    }

    useEffect(() => {
        if (tokenData.enterpriseUser) {
            dispatch(getAllTeacherStudents(tokenData.token))
        }
        else {
            dispatch(getMyStudents(tokenData.token))
        }
    }, [])

    return (
        <Card>
            <Table
                data={tokenData.enterpriseUser ? tStore.myEnterpriseStudent : tStore.myStudent}
                loader={tStore.myStudentLoader}
                title={'Students'}
                columns={columns}
            />
        </Card>
    )
}

export default ListStudents
