import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  // Form,
  FormFeedback,
  Input,
  Label,
  Container,
} from "reactstrap";

import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { emailValidator, requiredValidator,} from '../../../utilities/Validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { populateSchoolDetail,UpdateSchool ,getAllStates, fillterDistricts, fillterPanchayat} from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Modal from '../../common/Modal'

const EditSchool = () => {
    

  const [inputField,SetInput]=useState(null)
  const [errors, setErrors] = useState({})
  const [successModal, setSuccessModal] = useState(false);

  const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
  const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
  //const pincodes = useSelector((state) => state.superadmin.pincodes ? state.superadmin.pincodes : []);
  const panchayath = useSelector((state) => state.superadmin.panchayath ? state.superadmin.panchayath : []);

  const dispatch=useDispatch()
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const params = useParams()
  const history = useHistory();

  const schooledit_loader = useSelector((state) =>state.superadmin.schooledit_loader ? state.superadmin.schooledit_loader : false);

  useEffect(()=>{
    dispatch(populateSchoolDetail(tokenData.token,params.school_id,cb))
    dispatch(getAllStates(tokenData.token))
  },[])

  const cb=(formData)=>{
    dispatch(fillterDistricts(tokenData.token, formData.state_id))
    dispatch(fillterPanchayat(tokenData.token, formData.district_id))
    SetInput(formData)
  } 
  
  const handleChangeInput=(event)=>{
    SetInput({...inputField,[event.target.name]:event.target.value})
    // console.log('ccchhaanngeee',inputField)
  }

  const Submit = () => {
    const err = {
      email: emailValidator(inputField.email),
      school_name: requiredValidator(inputField.school_name),
      number: requiredValidator(inputField.contact_number),
      area: requiredValidator(inputField.area),
      pin: requiredValidator(inputField.pin),
      panchayath_id: requiredValidator(inputField.panchayath_id),
      address: requiredValidator(inputField.address),
      person: requiredValidator(inputField.contact_person),
      commision: requiredValidator(inputField.commision),

    };
    if (
      !err.email &&
      !err.number &&
      !err.school_name &&
      !err.area &&
      !err.pin &&
      !err.address&&
      !err.panchayath_id &&
      !err.person&&
      !err.commision
    ) {
      setErrors(err);
      dispatch(UpdateSchool(
          tokenData.token,
          params.school_id,
          inputField.school_name,
          inputField.contact_number,
          inputField.contact_person,
          inputField.email,
          inputField.password ? inputField.password :'',
          inputField.address,
          inputField.area,
          inputField.pin,
          inputField.panchayath_id,
          Number(inputField.commision),
          handleOpenSuccessModal,
          errormodel
      ))
    } else {
      setErrors(err);
      if(err.school_name){
        Showerror('school_name')
      }
    }
  };

  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

  const [err, setErr] = useState({
    open_err:false,
    err_list:[]
  })
  const errormodel=(val)=>{
    setErr({
      open_err:true,
      err_list:val
    })  
  }
  const closeErr = () => {
    setErr({
      open_err:false,
      err_list:[]
    })
  }
  
  const handleOpenSuccessModal = () => {
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
    history.push('/dashboard/schoollist')
}


  const Handlechangephone=(e)=>{
    SetInput({...inputField,contact_number:e})
  }

  return (
    <div>
      {
          inputField ? 
          <>
          <PageTitle
          style={{ display: "none" }}
          heading="Edit School"
          subheading="Edit School Details"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardBody>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>BASIC DETAILS</CardTitle>
                    <Container>
                      <Row>
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            School Name
                          </Label>
                          <Input
                            type="text"
                            name="school_name"
                            invalid={errors.school_name}
                            onChange={handleChangeInput}
                            value={inputField.school_name}
                            id='school_name'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.school_name}</FormFeedback>
                        </Col>
                        
                        {/* <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Referal Code
                          </Label>
                          <Input
                            type="text"
                            name="referelcode"
                            invalid={errors.referelcode}
                            onChange={handleChangeInput}
                            value={inputField.referelcode}
                          />
                          <FormFeedback>{errors.referelcode}</FormFeedback>
                        </Col> */}

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span> Email ID
                          </Label>
                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            style={{ width: "100%" }}
                            name="email"
                            value={inputField.email}
                            invalid={errors.email}
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.email}</FormFeedback>
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            {/* <span className="text-danger">*</span> */}
                            Password
                          </Label>
                          <Input
                            type="password"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            value={inputField.password ? inputField.password :''}
                            // invalid={errors.password}
                            name="password"
                            autocomplete="off"
                          />
                          {/* <FormFeedback>{errors.password}</FormFeedback> */}
                        </Col>

                        <Col xs={6}  className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Number
                          </Label>

                          <PhoneInput
                            value={inputField.contact_number}
                            name="contact_number"
                            country={'in'}
                            onChange={(e) => Handlechangephone(e)}
                            invalid={errors.number}
                            
                            />
                            {
                              errors.number ? 
                              <span style={{color:'red'}}>{errors.number}</span>:''
                            }
                          {/* <Input
                            type="number"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="number"
                            value={inputField.number}
                            invalid={errors.number}
                          />
                          <FormFeedback>{errors.number}</FormFeedback> */}
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Person
                          </Label>

                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            name="contact_person"
                            invalid={errors.person}
                            value={inputField.contact_person}
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.person}</FormFeedback>
                        </Col>

                        
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            rows={4}
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="address"
                            value={inputField.address}
                            invalid={errors.address}
                          />
                          <FormFeedback>{errors.address}</FormFeedback>
                        </Col>                      

                        <Col xs={12} sm={4} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Area
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="area"
                            value={inputField.area}
                            invalid={errors.area}
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.area}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={4} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            PIN
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="pin"
                            value={inputField.pin}
                            invalid={errors.pin}
                            min={0}
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.pin}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={4} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Commision
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            name="commision"
                            value={inputField.commision}
                            invalid={errors.commision}
                            onChange={handleChangeInput}
                            min={0}
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.commision}</FormFeedback>
                        </Col>

                        <Col md={4}>
                            <label>State</label>
                            <span className="text-danger">*</span>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_DISTRICT_PANCHAYATH"})}}>
                                <option  value={null} >Select</option>
                                {states.map((s) => {
                                    return (
                                        <option selected={inputField.state_id == s.state_id} value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>

                    </Col>
                    <Col sm={4}>
                            <label>District</label>
                            <span className="text-danger">*</span>
                            <Input type='select' onChange={(e) => {dispatch(fillterPanchayat(tokenData.token, e.target.value)); dispatch({ type: "RESET_PANCHAYATH" })   }} >
                                <option value={null} >Select</option>
                                {districts.map((s) => {
                                    return (
                                        <option selected={inputField.district_id == s.district_id} value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>                         
                            
                        </Col>
                       <Col sm={4}>
                            <label>Panchayat</label>
                            <span className="text-danger">*</span>
                            <Input invalid={errors.panchayath_id} name='panchayath_id' id='panchayath_id' type='select'  onChange={handleChangeInput} >
                                <option value={null} >Select</option>
                                {panchayath.map((p) => {
                                                return (
                                                    <option selected={inputField.panchayath_id == p.panchayath_id}  value={p.panchayath_id}>{p.panchayath}</option>
                                                )
                                            }

                                            )}
                            </Input>                         
                            <FormFeedback>{errors.panchayath_id}</FormFeedback>
                        </Col>

                      </Row>
                    </Container>
                </CardBody>
              </Card>
              <LaddaButton
                className="mb-2 mr-2 btn btn-primary"
                loading={schooledit_loader}
                type="submit"
                onClick={Submit}
                data-style={ZOOM_IN}
              >
                UPDATE SCHOOL
              </LaddaButton>
          </CardBody>
        </Card>
          </>
          :''
      }
        

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error,ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>
    </div>
  );
};

export default EditSchool;
