import React, { useEffect, } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getMyAccount } from '../../redux/Actions/homeTeacher'
import bg1 from '../../assets/utils/images/dropdown-header/abstract1.jpg';
import copy from 'copy-to-clipboard';
import { useHistory } from 'react-router-dom'
import {
     Col,
    Button,
    Card,
    Progress,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Swal from 'sweetalert2';


const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    console.error(tokenData.role)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyAccount(tokenData.token,cb))
    }, [])
    const cb=()=>{
        console.log('')
    }
    const history = useHistory()

    const handleEdit = () => {
        history.push('/dashboard/my-account/editAccount')
    }
    
    const handleDownload = () =>{
        if(accountInfo.myAccountInfo.user_manual_url)
        {window.open(accountInfo.myAccountInfo.user_manual_url,'_blank');}
        else {
            Swal.fire('Not Avilable',"Support Documents aren't avilable",'error')
        }
    }

    const accountInfo = useSelector(({ homeTeacher }) => homeTeacher)

    const joinClass = (weekId, classId) => {

        if (tokenData.role === 'student') {
            history.push(`/dashboard/join/${weekId}/${classId}`)
        } else if (tokenData.role === 'teacher') {
            history.push(`/dashboard/startclass/${weekId}/${classId}`)
        }
        else {
            alert("invalid")
        }
    }
 
    const myfunction = () => {
        toast.configure();
        copy('https://mymots.com/#/teacher-register?referal=' + accountInfo.myAccountInfo.referral_code);
        toast.error("Copied", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }
    const upgradePlan = () => {
        dispatch({ type: 'PAYMENT_UPGRADE_POPUP_REQUEST' })

    }
 
    return (

        <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
        >
            <Col md="12" lg="6" xl={{ span: 6, offset: 3 }}    >
                <Card className="card-shadow-primary profile-responsive card-border mb-3">
                    <div className="dropdown-menu-header">
                        <div className="dropdown-menu-header-inner bg-danger">
                            <div className="menu-header-image"
                                style={{
                                    backgroundImage: 'url(' + bg1 + ')'
                                }}
                            />
                            <div className="menu-header-content btn-pane-right">
                                <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                                    <div className="avatar-icon">
                                        <img src={accountInfo.myAccountInfo ? accountInfo.myAccountInfo.enterprise_logo_url : ""} alt="Avatar 5" />
                                    </div>
                                </div>

                                <div>
                                    <h5 className="menu-header-title">{accountInfo.myAccountInfo ? accountInfo.myAccountInfo.first_name + " " + accountInfo.myAccountInfo.last_name : " "} </h5>
                                    
                                    <h6 className="menu-header-subtitle">
                                        {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.role ? accountInfo.myAccountInfo.role === 'Principal' ? 'Partner' : accountInfo.myAccountInfo.role === 'Teacher' ? 'Facilitator' : accountInfo.myAccountInfo.role : " " : ''}</h6>
                                    {
                                        tokenData.role === 'principal' ?
                                            <>
                                               <h6 className='mt-2' >{accountInfo.myAccountInfo?.enterprise_name}</h6>
                                            </>
                                            : ""
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <ListGroup flush>
                        {tokenData.role === 'student' && !tokenData.enterpriseUser ?

                            <ListGroupItem>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-3">
                                            <div className="icon-wrapper m-0">
                                                <div className="progress-circle-wrapper">
                                                    <Progress
                                                        type="circle"
                                                        percent={43}
                                                        width="100%"
                                                        theme={
                                                            {
                                                                active: {
                                                                    trailColor: '#e1e1e1',
                                                                    color: '#28b0cd'
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {(accountInfo.myAccountInfo && accountInfo.myAccountInfo.grade) ?
                                            <>
                                                <div className="widget-content-left">
                                                    <div className="widget-heading">
                                                        Grade
                                                     </div>
                                                    <div className="widget-subheading">
                                                        {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.grade : " "}
                                                    </div>
                                                </div>

                                            </> : " "}
                                    </div>
                                </div>
                            </ListGroupItem> : null
                        }
                        <ListGroupItem>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <div className="icon-wrapper m-0">
                                            <div className="progress-circle-wrapper">
                                                <Progress
                                                    type="circle"
                                                    percent={82}
                                                    width="100%"
                                                    theme={
                                                        {
                                                            active: {
                                                                trailColor: '#e1e1e1',
                                                                color: '#3ac47d'
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Email
                                </div>
                                        <div className="widget-subheading">
                                            {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.email : " "}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ListGroupItem>
                        <ListGroupItem>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <div className="icon-wrapper m-0">
                                            <div className="progress-circle-wrapper">
                                                <Progress
                                                    type="circle"
                                                    percent={47}
                                                    width="100%"
                                                    theme={
                                                        {
                                                            active: {
                                                                trailColor: '#e1e1e1',
                                                                color: '#f7b924'
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Mobile
                                </div>
                                        <div className="widget-subheading">
                                            {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.mobile : " "}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ListGroupItem>
                       {/* {
                          tokenData.role === 'student' || tokenData.role === 'school' ?
                           <ListGroupItem>
                           <div className="widget-content p-0">
                               <div className="widget-content-wrapper">
                                   <div className="widget-content-left mr-3">
                                       <div className="icon-wrapper m-0">
                                           <div className="progress-circle-wrapper">
                                               <Progress
                                                   type="circle"
                                                   percent={47}
                                                   width="100%"
                                                   theme={
                                                       {
                                                           active: {
                                                               trailColor: '#e1e1e1',
                                                               color: '#f7b924'
                                                           }
                                                       }
                                                   }
                                               />
                                           </div>
                                       </div>
                                   </div>
                                   <div className="widget-content-left">
                                       <div className="widget-heading">
                                           Support Document
                               </div>
                                       <div >
                                           <Button className="btn btn-danger" onClick={handleDownload}>Download</Button>
                                       </div>
                                   </div>

                               </div>
                           </div>
                       </ListGroupItem> : ''
                       } */}

                        {tokenData.role === 'teacher' && !tokenData.enterpriseUser ?

                            <ListGroupItem>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-3">
                                            <div className="icon-wrapper m-0">
                                                <div className="progress-circle-wrapper">
                                                    <Progress
                                                        type="circle"
                                                        percent={43}
                                                        width="100%"
                                                        theme={
                                                            {
                                                                active: {
                                                                    trailColor: '#e1e1e1',
                                                                    color: '#28b0cd'
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="widget-content-left">
                                            <div className="widget-heading">
                                                Referral Code
                                </div>
                                            <div className="widget-subheading">
                                                {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.referral_code : " "}

                                            </div>

                                        </div>
                                        <div className="widget-content-right">
                                            <button onClick={myfunction}>copy</button>
                                        </div>
                                    </div>
                                </div>

                            </ListGroupItem> : null
                        }
                        {
                            tokenData.role === "teacher" && !tokenData.enterpriseUser ?
                                <ListGroupItem>
                                    <div className="widget-content p-0">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left mr-3">
                                                <div className="icon-wrapper m-0">
                                                    <div className="progress-circle-wrapper">
                                                        <Progress
                                                            type="circle"
                                                            percent={82}
                                                            width="100%"
                                                            theme={
                                                                {
                                                                    active: {
                                                                        trailColor: '#e1e1e1',
                                                                        color: '#3ac47d'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    Plan
                                </div>
                                                <div className="widget-subheading">

                                                    {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.plan_name : " "}
                                                </div>
                                            </div>
                                            <div className="widget-content-right">
                                                <Button onClick={upgradePlan} color="warning">Choose Plan</Button>
                                            </div>
                                        </div>
                                    </div>
                                </ListGroupItem>
                                : null
                        }
                    </ListGroup>
                </Card>

            </Col>
        </CSSTransitionGroup>
    )

}
export default AnalyticsDashboard1