import React, { useState, useEffect } from 'react'
import PageTitle from '../../common/HeaderTitle/PageTitle'
import { Card, CardHeader, Row, Col, Input, FormFeedback } from 'reactstrap'
// import Dropzone from 'react-dropzone'
import { Attachment, Clear } from '@material-ui/icons'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { populateMyCourse, uploadStudyMaterial, getStudyMaterials, downloadStudyMaterial } from '../../../redux/Actions/homeTeacher'
import { populateClassForCourse } from '../../../redux/Actions/vendor_course'
import { useDispatch, useSelector } from 'react-redux'
import { requiredValidator } from "../../../utilities/Validator";
import Swal from 'sweetalert2'
import Table from '../Table'
import { Progress } from 'react-sweet-progress';
import Tooltip from '@material-ui/core/Tooltip';
import { filterBatchByClass } from '../../../redux/Actions/assignment'

function StudyMaterials() {
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const homeTeacher = useSelector(({ homeTeacher }) => homeTeacher)
    const course = useSelector(({ course }) => course)

    const [InputField, setInputField] = useState({});
    const [Files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    const assignment = useSelector(({ assignments }) => assignments)


    useEffect(() => {

        dispatch(populateMyCourse(tokenData.token))
        dispatch(getStudyMaterials(tokenData.token))
        return () => {
            dispatch({ type: 'FILTER_BATCH_BY_CLASS_RESET' })
        }
    }, [])

    const onDrop = (e) => {
        // console.log("log files", e.currentTarget.files )
        let arr = Array.from(e.currentTarget.files)
        setFiles([...Files, ...arr])
    }
    const formData = new FormData();
    const handleDelete = (i) => {
        let arr = [...Files]
        arr.splice(i, 1)
        setFiles(arr)
    }


    const [Errors, setErrors] = useState({})

    const formSubmit = () => {

        if (Files.length > 0) {
            let err = {}

            err = {
                class_id: requiredValidator(InputField.class_id),
                title: requiredValidator(InputField.title),
                description: requiredValidator(InputField.description),
                course_id: requiredValidator(InputField.course_id),
                group_id: requiredValidator(InputField.group_id),

            };

            for (let i = 0; i < Files.length; i++) {

                formData.append('study_material[]', Files[i])
            }
            formData.append('class_id', InputField.class_id)
            formData.append('title', InputField.title)
            formData.append('description', InputField.description)
            formData.append('type', 'study_material')
            formData.append('batch_id', InputField.group_id)

            if (!err.class_id && !err.title && !err.description && !err.group_id) {

                dispatch(uploadStudyMaterial(tokenData.token, formData, reset))
            } else {
                setErrors(err)
            }
        } else {
            Swal.fire('Warning', 'Attach File', 'warning')
        }

    }

    const refresh = () => {
        dispatch(getStudyMaterials(tokenData.token))
        setLoading(false)
    }

    const reset = () => {
        dispatch({ type: 'UPLOAD_STUDY_MATERIAL_PROGRESS', payload: '' })
        dispatch(getStudyMaterials(tokenData.token))
        setErrors({})
        setFiles([])
        setInputField({
            title: '',
            course_id: '',
            class_id: '',
            description: ''
        })
        Swal.fire({
            icon: 'success',
            text: 'Study Material Created',
            // title: 'Token Expired'
        })

    }

    const handleChangeCourse = e => {
        const { value } = e.target;
        setInputField({ ...InputField, 'course_id': value, group_id: '', class_id: '' })
        // setInputfield({ ...inputField, [name]: value })

        dispatch(populateClassForCourse(tokenData.token, value))

    };

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        if (name === 'class_id') {
            dispatch(filterBatchByClass(tokenData.token, value))
            setInputField({ ...InputField, [name]: value, group_id: '' })
        } else {
            setInputField({ ...InputField, [name]: value })
        }
    }



    return (
        <div>
            <PageTitle
                heading="Study Materials"
                subheading="Upload Study Materials"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />
            <Card className='mb-3' >
                <CardHeader>Upload study materials</CardHeader>
                <Row className='p-3' >
                    <Col sm={6} >
                        <span className='text-danger' >*</span>
                        <label>Title</label>
                        <Input value={InputField.title} invalid={Errors.title} name='title' onChange={handleChangeInput} ></Input>
                        <FormFeedback>{Errors.title}</FormFeedback>
                    </Col>
                    <Col sm={6} >
                        <span className='text-danger' >*</span>
                        <label>Course</label>
                        <Input value={InputField.course_id} invalid={Errors.course_id} name='course_id' onChange={handleChangeCourse} type='select' >
                            <option>Select</option>
                            {
                                homeTeacher.TeachersCourse.map(
                                    (course) => <option value={course.course_id} > {course.course_name} </option>)
                            }
                        </Input>
                        <FormFeedback>{Errors.course_id}</FormFeedback>
                    </Col>
                    <Col sm={6} >
                        <span className='text-danger' >*</span>
                        <label>Class</label>
                        <Input value={InputField.class_id} invalid={Errors.class_id} name='class_id' onChange={handleChangeInput} type='select' >
                            <option>Select</option>
                            {
                                course.classesOfCourse ? course.classesOfCourse.map(
                                    (cl) => <option value={cl.class_id} > {cl.class_name} </option>)
                                    :
                                    ''
                            }
                        </Input>
                        <FormFeedback>{Errors.class_id}</FormFeedback>
                    </Col>

                    <Col sm={6} className="assign-form-grid">
                        <label for="exampleNameF">
                            Group
                        </label>
                        <div class="form-group">
                            <div class="input-group">
                                <Input type="select"
                                    native
                                    labelId="demo-simple-select-outlined-label"
                                    onChange={handleChangeInput}
                                    id="group_id"
                                    name='group_id'
                                    label="Grade"
                                    invalid={Errors.group_id}
                                    value={InputField.group_id}
                                >

                                    <option value={''}>{'Select Batch'}</option>
                                    {assignment.batchesinClass ?
                                        assignment.batchesinClass.map((b, k) => {
                                            return (
                                                <option key={k} value={b.group_id}>{b.group_name}</option>
                                            )
                                        }) : null
                                    }

                                </Input>

                                {
                                    assignment.batchesinClassLoader ?
                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </span>
                                        :
                                        null
                                }
                            </div>
                            <FormFeedback>{Errors.group_id}</FormFeedback>

                        </div>
                    </Col>



                    <Col className='mt-2' sm={12} >
                        <span className='text-danger' >*</span>
                        <label>Description</label>
                        <Input value={InputField.description} invalid={Errors.description} name='description' onChange={handleChangeInput} type='textarea' >
                        </Input>
                        <FormFeedback>{Errors.description}</FormFeedback>
                    </Col>
                    {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}  > */}
                    <Col sm={12} >
                        {/* <span className='text-danger' >*</span> */}
                        {/* <label                 
                        >Files
                            
                        </label> */}
                        <label style={{ border: 'black dashed 1px', padding: '5px', marginLeft: '10px', marginTop: '15px' }} for='file-input' >Choose Files</label>
                        <Input
                            style={{ display: 'none' }}
                            id='file-input'
                            type='file' multiple onChange={onDrop} />
                    </Col>

                    {/* 
                        <div className="dropzone-wrapper dropzone-wrapper-sm">
                            <Dropzone
                                onDrop={onDrop}
                                onFileDialogCancel={onCancel}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content">
                                            <p>Try dropping some files here, or click to select files to upload.</p>
                                        </div>
                                    </div>
                                )}
                            </Dropzone> 
                        </div>
                        */}
                    {/* </div> */}
                    <Col sm={12} >
                        {Files.length > 0 ?
                            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '20px 10px' }} >
                                {
                                    Files.map((file, i) =>
                                        <div style={{ backgroundColor: 'lavender', padding: '4px', display: 'flex', margin: '10px', borderRadius: '4px' }} >
                                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px' }} >
                                                <Attachment />
                                                {file.name}
                                                {/* - {file.size} */}
                                            </div>
                                            <Tooltip title='Remove'>
                                                <span onClick={() => handleDelete(i)} style={{ backgroundColor: 'lightcoral', padding: '2px', margin: '3px 5px' }} > <Clear style={{ cursor: 'pointer', fontSize: '20px' }} /> </span>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            </div> : ''

                        }
                    </Col>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} >
                        {

                            Files.length > 0 && homeTeacher.uploadStudyMaterialProgress !== '' ?
                                <>
                                    {/* <strong style={{ color: 'rgb(19, 140, 228)' }}>Uploading Files...</strong> */}
                                    <Progress
                                        percent={homeTeacher.uploadStudyMaterialProgress}
                                    />
                                </> : null

                        }
                        <LaddaButton className="mt-2 mr-4 btn btn-danger pr-5 pl-5"
                            loading={homeTeacher.studyMaterialUploadLoader}
                            onClick={formSubmit}
                            type="submit"
                            data-style={ZOOM_IN}
                        >
                            Upload
                        </LaddaButton>
                    </div>

                </Row>
            </Card>

            <Table
                downloadcb={refresh}
                action='study_material_teacher'
                setLoading={() => { setLoading(true) }}
                loading={loading}
            />

        </div>
    )
}

export default StudyMaterials
