import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {getRejectedCourses} from "../../../redux/Actions/Superadmin"
import {
    Row,
    Col,
     Badge,
    // Card,
    // CardBody,
    // Button,
    // CardHeader,
    Container,
  } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Table from "./table";
import {useHistory} from 'react-router-dom'


function RejectedCourselist() {

  const RejectedCourseArray = useSelector(state => state.superadmin.RejectedCourseArray ? state.superadmin.RejectedCourseArray:[])
  const rejectloader = useSelector(state => state.superadmin.rejectloader ? state.superadmin.rejectloader:false)

  const history = useHistory()

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const dispatch = useDispatch()

  const Viewcourse = (id) =>{
    history.push('/dashboard/rejectedcourses/course/'+id)
  }
  
  useEffect(() => {
      dispatch(getRejectedCourses(tokenData.token))  
  
  }, [])

    const columns = [
        { title: "Course", field: "course_name" },
        { title: "Product_Code", field: "product_code" },
        { title: "Partner", field: "vendor_name" },
        { title: "Course_Date", field: "course_date", 
        render: (rowData)=>{
          return < Badge>{rowData.course_date}</Badge>
        }
      },
        { title: "Enrollment_Date", field: "enrollment_date", 
        render: (rowData)=>{
          return < Badge>{rowData.enrollment_date}</Badge>
        }
      },
      { title: "Type", field: "course_type" },
      { title: "Live_Class", field: "live_class_count" },
      { title: "Video_Class", field: "ondemand_video_count" },

        { title: "Seats", field: "seats" },
        { title: "Proposed_fee", field: "proposed_fee" },
        { title: "Total_fee", field: "total_fee"
         },
    ]
  
    const action='pending_courses'
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container>
          <Row>
            <Col sm={12}>
              <Table
                loader={rejectloader}
                actions={action}
                data={RejectedCourseArray}
                title={"Rejected Courses"}
                columns={columns}
                Viewcourse={Viewcourse}
             />
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>
    </>
  );
}
export default RejectedCourselist;


