import React, { useState, useEffect, Fragment } from 'react';
import banner from "../images/inner-banner.jpg"
import coursedummy from "../images/coursedummy.png"


import { useHistory, Link } from "react-router-dom";

import { CommonsearchCourse, setHomeData } from "../../../redux/Actions/genexhome"
import { useDispatch, useSelector } from "react-redux";
import LoadingOverlay from 'react-loading-overlay';

import nodata from "../images/nodata.png"
// import about from "../images/about-01.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { Link } from 'react-router-dom'
import WarningIcon from '@material-ui/icons/Warning';
import Swal from 'sweetalert2'
import Tooltip from '@material-ui/core/Tooltip';

function SearchCourses() {

    const dispatch = useDispatch();
    const history = useHistory();
    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const vendorname_label = { fontFamily: 'revert', color: 'rgb(0 0 0)' }
    const vendorname = { fontFamily: 'revert', color: 'rgb(20 73 144)', fontWeight: '600' }

    const [serach, setSearch] = useState('')
    // const [searching,setSeraching]=useState(false)
    const [change, setChange] = useState(false)
    const Course_Details = useSelector((state) => state.genexhome.Common_Search_Course_Details ? state.genexhome.Common_Search_Course_Details : "");
    const Course_Array = useSelector((state) => state.genexhome.Common_Search_Course_Array ? state.genexhome.Common_Search_Course_Array : []);
    const first_page_url = useSelector((state) => state.genexhome.Common_Search_first_page_url ? state.genexhome.Common_Search_first_page_url : '');
    const next_page_url = useSelector((state) => state.genexhome.Common_Search_next_page_url ? state.genexhome.Common_Search_next_page_url : '');
    const loading = useSelector((state) => state.genexhome.Common_Search_loading ? state.genexhome.Common_Search_loading : false);

    const searchterm = useSelector((state) => state.genexhome.search_term ? state.genexhome.search_term : "");

    const page = useSelector((state) => state.genexhome.search_page ? state.genexhome.search_page : 1);

    const certification = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#202A60', padding: '3px 20px', background: 'white', width: '96px', boxSizing: 'border-box', display: 'inline-block' }
    const timeleft = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }

    const timelefthidden = { visibility: 'hidden', border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }

    useEffect(() => {
        if (change) {
            dispatch(CommonsearchCourse(searchterm, page, cb2))
        }

    }, [page])

    const NextPage = () => {
        setChange(true)
        dispatch(setHomeData('search_page', page + 1))

    }
    const PreviousPage = () => {
        setChange(true)
        dispatch(setHomeData('search_page', page - 1))

    }

    const ViewCourse = (val) => {
        if (val.edit_status === 1) {
            Swal.fire({
                icon: 'warning',
                title: 'Rescheduling!',
                text: 'Will be available soon',
            })
        } else {
            history.push(`/view-course/${val.course_id}`)
        }
    }

    const HandleSearch = (e) => {
        dispatch(setHomeData('search_term', e.target.value))
    }

    const SearchCate = (e) => {
        e.preventDefault();
        if (searchterm) {
            setSearch(true)
            if (page === 1) {
                dispatch(CommonsearchCourse(searchterm, 1, cb2))
            }
            else {
                setSearch(false)
                // setPage(1)
                dispatch(setHomeData('search_page', 1))

            }
        }
    }

    // const cb=()=>{
    //     setPage(1)
    //     setSearch(false)
    // }

    const cb2 = () => {
        // setSeraching(true)
        setSearch(false)
    }

    const reschedule = { backgroundColor: 'rgb(49 49 49 / 55%)', borderColor: 'white', position: 'absolute', top: '10px', left: '10px', color: 'white', padding: '0px 2px', borderRadius: '5px', boxSizing: 'border-box' }

    return (


        <Fragment>
            {
                serach ?
                    <>

                        <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})` }}>
                            <div class="container">
                                <h2 class="text-center font-weight-600 banner-title">BROWSE OUR ONLINE COURSES</h2>
                            </div>

                            <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12 col-12">
                                        <div class="search-form-main">
                                            <div class="row justify-content-center">
                                                <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                    <div class="form-group">
                                                        <input type="text" value={searchterm} class="form-control" id="" placeholder="What are you looking for" />
                                                    </div>
                                                </div>
                                                <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                    <div class="form-group">

                                                        <input
                                                            type="button"
                                                            class="btn btn-block search-btn"
                                                            // onClick={SearchCate}
                                                            value="Loading..."
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                                    <h2 class="text-center search-col-title">Find your best courses</h2>
                                </div>

                            </div>

                        </section>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                minHeight: "80vh",
                            }}
                        >
                            <CircularProgress />
                        </div>

                    </>
                    :
                    <>
                        {
                            Course_Details ?
                                <>
                                    <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})` }}>
                                        <div class="container">
                                            <h2 class="text-center font-weight-600 banner-title">BROWSE OUR ONLINE COURSES</h2>
                                        </div>

                                        <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 col-12">
                                                    <form onSubmit={SearchCate} class="search-form-main">
                                                        <div class="row justify-content-center">
                                                            <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                                <div class="form-group">
                                                                    <input type="text" value={searchterm} onChange={HandleSearch} class="form-control" id="" placeholder="What are you looking for" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                                <div class="form-group">

                                                                    <input
                                                                        type="button"
                                                                        class="btn btn-block search-btn"
                                                                        onClick={SearchCate}
                                                                        disabled={searchterm ? false : true}
                                                                        value="Search" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>


                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                                                <h2 class="text-center search-col-title">Find your best courses</h2>
                                            </div>

                                        </div>

                                    </section>


                                    <section class="padding-top-120px padding-bottom-60px" style={{ backgroundColor: '#FAFAFA' }}>
                                        <div class="container">

                                            {/* <div class="row justify-content-center">
                                <div class="col-md-9 col-lg-9 col-12 ">
                                    <div class="text-center  margin-bottom-35px wow fadeInUp" style={{visibility:'visible',animationName:'fadeInUp'}}>
                                        <h3  style={{color:'#212529'}} class="font-weight-700 text-title-large wow fadeInUp" data-wow-delay="0.2s"><span class="fw-200">Browse Our </span> Online Courses</h3>
                                        <p class="font-15 wow fadeInUp" data-wow-delay="0.2s">Skillablers Virtual Edu Park is enriched with all leading universities and training institutes across the globe. This offers a first time opportunity for people of any age group to go for lifelong learning. Now enrol for courses of your choice any time from our unmatched treasure of knowledge.</p>
                                    </div>
                                </div>
                            </div> */}

                                            <LoadingOverlay
                                                active={loading}
                                                spinner
                                                text='Loading your content...'
                                            >
                                                <div class="row wow fadeInUp" data-wow-delay="0.2s">

                                                    {
                                                        Course_Array.length > 0 ?
                                                            <>
                                                                {
                                                                    Course_Array.map((val, ky) => {
                                                                        let vendor = val.vendor_name ? val.vendor_name : ''
                                                                        let v_name
                                                                        if (vendor.length > 15) {
                                                                            let apend = vendor.slice(0, 14)
                                                                            v_name = apend + '...'
                                                                        }
                                                                        else {
                                                                            v_name = vendor
                                                                        }
                                                                        return (
                                                                            <div class="col-md-4 col-lg-3 col-sm-6 col-12 mb-4" key={ky} style={{ cursor: 'pointer' }} >
                                                                                <div class="blog-item thum-hover featured-institute-main">
                                                                                    <div class="position-relative">
                                                                                        <div onClick={() => { ViewCourse(val) }} class="item-thumbnail  "><img src={val.banner_url ? val.banner_url : coursedummy} alt="" width='100%' height='200px'  /></div>
                                                                                        <div class="cat-div">
                                                                                            {val.tags ? val.tags[0] ? val.tags[0].tag_name ? val.tags[0].tag_name : '' : '' : ''}
                                                                                        </div>
                                                                                        {
                                                                                            val.edit_status === 1 ?
                                                                                                <div style={reschedule}><WarningIcon style={{ color: '#d8d81c', fontSize: '15px' }} /> Rescheduling</div>
                                                                                                : ''
                                                                                        }
                                                                                    </div>
                                                                                    <div class="padding-20px bgclrbtm-of-course">
                                                                                        <div onClick={() => { ViewCourse(val) }} >
                                                                                            <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                                                                                <span class="f-i-title">{val.course_name}</span>
                                                                                            </div>
                                                                                            <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                                                                                <p class="font-14 mb-1">{val.course_subject_name}</p>
                                                                                            </div>
                                                                                            <p class="mb-0"><span class="font-16 font-weight-500 text-blue">{val.course_amount}</span></p>
                                                                                        </div>
                                                                                        <hr class="mt-1 mb-1" />
                                                                                        <p class="mb-0">
                                                                                            {
                                                                                                val.certified === 1 ?
                                                                                                    <div style={certification}>Certified</div>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                val.days_left ?
                                                                                                    <div style={timeleft}>{val.days_left}</div>
                                                                                                    :
                                                                                                    <span style={timelefthidden} class="mr-1 course-badges badge badge-dark">{0}</span>
                                                                                            }
                                                                                        </p>
                                                                                        <Tooltip arrow title={val.vendor_name}>
                                                                                            <Link to={{ pathname: `/vendor/${val.vendor_id}/${val.skl_user_id}` }} >
                                                                                                <span>
                                                                                                    <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                                                                                </span>
                                                                                            </Link>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                                {/* </a> */}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                <img src={nodata} width='600px' height='400px' />
                                                            </div>
                                                    }

                                                </div>
                                            </LoadingOverlay>

                                            {
                                                Course_Array.length > 0 ?
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                                                            <ul class="pagination-list">
                                                                {
                                                                    first_page_url ?
                                                                        <li style={{ cursor: 'pointer' }}><a onClick={PreviousPage}>Prev</a></li>
                                                                        :
                                                                        <li><a style={{ color: '#dddddd' }}>Prev</a></li>

                                                                }
                                                                {
                                                                    next_page_url ?
                                                                        <li style={{ cursor: 'pointer' }}><a onClick={NextPage}>Next</a></li>
                                                                        :
                                                                        <li><a style={{ color: '#dddddd' }}>Next</a></li>

                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }


                                        </div>
                                    </section>


                                </>
                                :

                                <>

                                    <section class="top-banner position-relative" style={{ backgroundImage: `url(${banner})` }}>
                                        <div class="container">
                                            <h2 class="text-center font-weight-600 banner-title">BROWSE OUR ONLINE COURSES</h2>
                                        </div>

                                        <div class="inner-search wow fadeInUp" data-wow-delay="0.2s">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-12 col-12">
                                                    <form onSubmit={SearchCate} class="search-form-main">
                                                        <div class="row justify-content-center">
                                                            <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                                <div class="form-group">
                                                                    <input type="text" value={searchterm} onChange={HandleSearch} class="form-control" id="" placeholder="What are you looking for" />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                                                                <div class="form-group">

                                                                    <input
                                                                        type="button"
                                                                        class="btn btn-block search-btn"
                                                                        onClick={SearchCate}
                                                                        value="Search"
                                                                        disabled={searchterm ? false : true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>


                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                                                <h2 class="text-center search-col-title">Find your best courses</h2>
                                            </div>

                                        </div>

                                    </section>

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <img src={nodata} width='600px' height='400px' />
                                    </div>

                                </>
                        }
                    </>
            }

        </Fragment>
    )
}

export default SearchCourses