import React,{Fragment,useState} from 'react'
import {
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    EmailShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookShareButton,
    WhatsappIcon,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
  } from "react-share";
  import Grid from '@material-ui/core/Grid';

const style={
    padding: '4px 6px',
    background: '#3A436F',
    width: '100px',
    margin: '6px auto auto',
    borderRadius: '21px',
    fontSize: '12px',
    fontWeight: '400',
    color: '#fff',
    cursor:'pointer'
}
export default function ShareOptions({location}) {
    const [copied, setCopied] = useState(false);

    const CopyValue=()=>{
        navigator.clipboard.writeText(location)
        setCopied('Copied')
        }

    const shareUrl=location
    return (
        <Fragment>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <div  style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
                        <EmailShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button"
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>

                        <WhatsappShareButton
                            url={shareUrl}
                            title={'Skillablers'}
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <LinkedinShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button"
                        >
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>

                        <TwitterShareButton
                            url={shareUrl}
                            title={'Skillablers'}
                            className="Demo__some-network__share-button"
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        
                        <FacebookShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>

                        <FacebookMessengerShareButton
                            url={shareUrl}
                            appId="521270401588372"
                            className="Demo__some-network__share-button"
                        >
                            <FacebookMessengerIcon size={32} round />
                        </FacebookMessengerShareButton>
                    </div>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center'}}>
                    OR
                </Grid>
                <Grid item xs={12} style={{textAlign:'center',marginBottom:'10px'}}>
                    <div style={style} onClick={CopyValue} >
                        COPY LINK
                    </div>
                </Grid>
                <Grid item xs={12} style={{textAlign:'center',marginBottom:'5px'}}>
                    <span style={{color:'green'}}>{copied}</span>
                </Grid>
            </Grid>
        </Fragment>
    )
}
