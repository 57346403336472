const initialState = {
    
  };
  
  const resellerReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case "UPLOAD_PROGRESS_IMAGE": {
        return {
          ...state,
          uploadProgressVideo: payload, //both video and banner image are included
        };
      }
      case "RESELLER_DASHBOARD_SUCCESS": {
        return {
          ...state,
          resellerDashboard: payload.dashboard_data, //both video and banner image are included
        };
      }
    
  
  
      default:
        return state;
    }
  };
  
  export default resellerReducer;
  