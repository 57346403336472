import React, { useState, useEffect, } from 'react'
import { addDays } from 'date-fns';
// import toDate from 'date-fns/toDate'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Swal from 'sweetalert2'
import { populateMyCourse, populateClassForCourse, setCourseData } from '../../../redux/Actions/vendor_course'
import { populateMyCourseStudent, exportTeacherAttendance, setReqData } from '../../../redux/Actions/Superadmin'

import { getAllStudentCourse } from "../../../redux/Actions/Student"
// import Tooltip from '@material-ui/core/Tooltip';
import { filterBatchByClass } from '../../../redux/Actions/assignment'

import {
    Row, Col, Input,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody, Label,
    CardHeader,
    FormFeedback,
} from 'reactstrap';
import PageTitle from '../../common/HeaderTitle/PageTitle'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { requiredValidator } from '../../../utilities/Validator'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import {
    populateTeacherCourses, filterSubscriptionByTeacher,
    getAttendance, populateAllUserClass, getAttendanceOfTeacher,
    filterAttendance, populateRegisteredStudents,
    // populateTeacherClasses,populateAllEnterpriseUserClass,populateStudentClasses,
    populateRegisteredTeachers, setTeacherData
}
    from '../../../redux/Actions/homeTeacher'
import { useHistory } from "react-router-dom";
import { filterClassBySubscription } from '../../../redux/Actions/vendor_course'

import classnames from 'classnames';
import { populateMySubscription } from '../../../redux/Actions/homeTeacher'
import { filterStudentBatchByClass } from "../../../redux/Actions/Student"
const Index = () => {


    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const StudentCourseArray = useSelector((state) => state.student.StudentCourseArray ? state.student.StudentCourseArray : []);
    const assignment = useSelector(({ assignments }) => assignments)

    const courses = useSelector((state) =>
        state.homeTeacher.classes ? state.homeTeacher.classes : ""
    );

    const classes = useSelector((state) =>
        state.course.classesOfCourse ? state.course.classesOfCourse : ""
    );


    // const teacherSubscriptions = useSelector((state) =>
    //     state.homeTeacher.teacherSubscriptions ? state.homeTeacher.teacherSubscriptions : []
    // );
    const teacherCourses = useSelector((state) =>
        state.homeTeacher.teacherCourses ? state.homeTeacher.teacherCourses : []
    );
    // console.log("teachwe", teacherCourses)
    const subscriptions = useSelector((state) =>
        state.homeTeacher.teacherSubscriptions ? state.homeTeacher.teacherSubscriptions : ""
    );

    const teachersSubscriptions = useSelector((state) =>
        state.homeTeacher.SpecificteacherSubscriptions ? state.homeTeacher.SpecificteacherSubscriptions : ""
    )
    const loadclass = useSelector((state) =>
        state.course.loadclass ? state.course.loadclass : false
    );

    const student_course = useSelector((state) =>
        state.superadmin.student_course ? state.superadmin.student_course : []
    );
    const student_batches = useSelector((state) =>
        state.student.student_batches ? state.student.student_batches : []
    );
    const batch_loader = useSelector((state) =>
        state.student.batch_loader ? state.student.batch_loader : false
    );

    const [Type, setType] = useState()
    const [subscription_value, setSubscriptionValue] = useState('')
    const [batch_id, setBatchId] = useState('')
    const [student_batch_id, setStudentBatchId] = useState('')


    useEffect(() => {
        dispatch({ type: 'FILTER_BATCH_BY_CLASS_RESET' })
        dispatch({ type: "POPULATE_CLASS_FOR_COURSES_RESET" })
        dispatch({ type: "RESET_ATTENDANCE" })
        setTeacher({ teacher_id: "", class_id: "", student_id: "" })
        setError({})
        dispatch(setReqData('teacher_selected_batch', ''))
        if (history.location.pathname === '/dashboard/subscription/show-attendance') {
            setType('subscription')
            dispatch(populateMySubscription(tokenData.token))
        } else {
            setType('course')
        }
    }, [history.location.pathname])

    useEffect(() => {

        if (tokenData.role !== 'principal' && tokenData.role !== 'student') {
            dispatch(populateAllUserClass(tokenData.token))
        }
        if (!localStorage.getItem('authDetails')) {
            history.push('/')
        }


        if (tokenData.role === 'principal' || tokenData.role === 'staff') {
            if (history.location.pathname === '/dashboard/show-attendance') {
                dispatch(populateMyCourse(tokenData.token))
            }
            dispatch(populateRegisteredTeachers(tokenData.token))

        }
        if (tokenData.role === 'student') {
            dispatch(getAllStudentCourse(tokenData.token))
        }

        return () => {
            setError({})
            setTeacher({ teacher_id: "", class_id: "", student_id: "" })
            dispatch(setTeacherData('attendanceInfo', []))
            dispatch(setTeacherData('studentAttandance', {}))

        }


    }, [dispatch]);

    const [state2, setState2] = useState({
        activeTab: '1',
        showMore: true,
        transform: true,
        showInkBar: true,

        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        //dispatch({ type: 'RESET_SUBJECT' })
        setError({})
        setSubscriptionValue('')
        setStudentBatchId('')
        setTeacher({ teacher_id: "", class_id: "", student_id: "" })
        dispatch({ type: 'RESET_STUDENT_ATTANDANCE' })
        dispatch({ type: 'RESET_ATTANDANCE' })
        dispatch(setCourseData('classesOfCourse', []))
        if (state2.activeTab !== tab) {
            setState2({
                activeTab: tab
            });
            setTeachercourse('')
        }
        if (tab === '2') {
            if (history.location.pathname === '/dashboard/show-attendance') {
                dispatch(populateMyCourseStudent(tokenData.token))
            }
        }
    }
    let columns = ''
    if (tokenData.enterpriseUser && tokenData.role != 'student' && tokenData.role != 'teacher' && state2.activeTab != 2) {
        columns = [
            {
                title: '#', field: 'tableData.id', render: (rowData) => {
                    return rowData.tableData.id + 1
                }
            },
            { title: 'Facilitator_Name', field: 'teacher' },
            { title: 'Date', field: 'date' },
            { title: 'From_Time', field: 'class_from_time' },
            { title: 'To Time', field: 'class_to_time' },
            { title: 'Class_name', field: 'class_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Status', field: 'status' },
            // { title: 'Group', field: 'standard' },

        ]
    } else if (tokenData.role == 'student' || state2.activeTab == 2) {
        columns = [
            {
                title: '#', field: 'tableData.id', render: (rowData) => {
                    return rowData.tableData.id + 1
                }
            },
            { title: 'Date', field: 'date' },
            { title: 'Class_Name', field: 'class_name' },
            { title: 'Time Slot', field: 'time_slot' },
            { title: 'Status', field: 'status' },
            { title: 'Student_Duration ', field: 'student_duration' },
            // { title: 'Teacher Duration', field: 'teacher_duration' },
            // { title: 'Standerd', field: 'grade' },
        ]
    } else if (tokenData.role == 'teacher') {
        columns = [
            {
                title: '#', field: 'tableData.id', render: (rowData) => {
                    return rowData.tableData.id + 1
                }
            },
            { title: 'Class_name', field: 'class_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Group', field: 'standard' },
            { title: 'Date', field: 'created_at' },
            { title: 'From Time', field: 'class_from_time' },
            { title: 'To Time', field: 'class_to_time' },

        ]
    } else {
        columns = [
            {
                title: '#', field: 'tableData.id', render: (rowData) => {
                    return rowData.tableData.id + 1
                }
            },
            { title: 'Date', field: 'created_at' },
            { title: 'From Time', field: 'class_from_time' },
            { title: 'To Time', field: 'class_to_time' },
            { title: 'Class name', field: 'class_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Grade', field: 'grade' },

        ]
    }



    const handleSelectedRow = (class_id, session, batch_id) => {
        if (session.length > 0) {
            dispatch(setReqData("teacher_selected_batch", batch_id ? batch_id : undefined))
            history.push({
                pathname: `/dashboard/show-attendance/${class_id}`,
                data: session // your data array of objects
            })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Empty',
                text: "Empty session",
            })
        }
    }




    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -32),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [teacher, setTeacher] = useState({ teacher_id: "", class_id: "", student_id: "" })

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setTeacher({ ...teacher, [name]: value })
        setBatchId('')
        if (name === 'teacher_id' && Type === 'course') {
            setTeachercourse('')
            setTeacher({ ...teacher, class_id: '', [name]: value })
            dispatch(setTeacherData('classesOfCourse', ''))
        }
        else if ((tokenData.role === 'principal' || tokenData.role === 'staff') && state2.activeTab === '2' && Type === 'course') {
            setTeacher({ ...teacher, student_id: '', [name]: value })
            setStudentBatchId('')
        } else if (name === 'teacher_id' && Type !== 'course' && state2.activeTab === '1') {
            setTeacher({ ...teacher, [name]: value, class_id: '' })
        }
        else if (value === "class_id" && tokenData.role === 'student') {
            setStudentBatchId('')
        }
        else {
            setTeacher({ ...teacher, [name]: value })
        }

        if ((tokenData.role === 'principal' || tokenData.role === 'staff') && name === 'teacher_id' && Type === 'course') {
            dispatch(populateTeacherCourses(tokenData.token, value))
        }

        if ((tokenData.role === 'principal' || tokenData.role === 'staff') && name === 'student_id' && Type === 'course' &&value) {
            dispatch(filterStudentBatchByClass(tokenData.token,teacher.class_id,value))
        }

        if ((tokenData.role === 'principal' || tokenData.role === 'staff') && name === 'teacher_id' && Type === 'subscription' && value) {
            setSubscriptionValue('')
            dispatch(filterSubscriptionByTeacher(tokenData.token, value))

        }

        if ((tokenData.role === 'principal' || tokenData.role === 'staff') && name !== 'student_id' && value) {
            dispatch(populateRegisteredStudents(tokenData.token, null, value))
        }

        if (tokenData.role === 'teacher' && name === 'class_id' && value) {
            dispatch(filterBatchByClass(tokenData.token, value))
        }

        if ((tokenData.role === 'principal' || tokenData.role === 'staff') && name === 'class_id' && value && state2.activeTab === '1' && Type === 'course') {
            dispatch(filterBatchByClass(tokenData.token, value))
        }

        if (tokenData.role === 'student' && name === 'class_id') {
            dispatch(filterStudentBatchByClass(tokenData.token, value))
        }

    
    }

    const [teachercourse, setTeachercourse] = useState('')
    const handleChangeCourse = (e) => {
        const { name, value } = e.target;
        setTeachercourse(value)
        setBatchId('')
        setStudentBatchId('')
        //formData.set('course_id',value)
        if (value) {
            dispatch(populateClassForCourse(tokenData.token, value, teacher.teacher_id ? teacher.teacher_id : ''))
        }
        if (tokenData.role === 'teacher' || tokenData.role === 'student') {
            setTeacher({ ...teacher, class_id: '' })
        }
        if (tokenData.role === 'principal') {
            setTeacher({ ...teacher, class_id: '' })
        }
        if ((tokenData.role === 'principal' || tokenData.role === 'staff') && state2.activeTab === '2') {
            setTeacher({ ...teacher, class_id: '', student_id: '' })
        }
    }

    const handleChangeSubscription = (e) => {

        const { value } = e.target;
        //setInputfield({ ...inputField, [name]: value })
        // formData.set('subscription_id', value)
        setSubscriptionValue(value)
        if (value) {
            dispatch(filterClassBySubscription(tokenData.token, value))
        }
        if (tokenData.token === 'teacher' || tokenData.token === 'student') {
            setTeacher({ ...teacher, class_id: '' })
        }
        if (Type === 'subscription') {
            setTeacher({ ...teacher, class_id: '', student_id: '' })
        }
    }


    // const handleChangeClass = (e) => {
    //     // alert(e.target.value)
    //     dispatch(populateRegisteredStudents(tokenData.token, null, e.target.value))

    // }

    const [error, setError] = useState({})
    const filter = () => {
        //  debugger
        let err;
        let invalid;

        const body = {
            from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
            to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
            teacher_id: teacher.teacher_id,

            class_id: teacher.class_id,
            student_id: teacher.student_id,
            type: Type
        }
        if (tokenData.role === 'principal' || tokenData.role === 'staff') {
            if (state2.activeTab === '1' && Type === 'course') {
                err = {
                    class_id: requiredValidator(teacher.class_id),
                    teacher_id: requiredValidator(teacher.teacher_id),
                    course_id: requiredValidator(teachercourse),
                    batch_id: requiredValidator(batch_id),

                }
                invalid = err.class_id || err.teacher_id || err.course_id || err.batch_id
            }

            if (state2.activeTab === '1' && Type !== 'course') {

                err = {
                    class_id: requiredValidator(teacher.class_id),
                    teacher_id: requiredValidator(teacher.teacher_id),
                    sub_id: requiredValidator(subscription_value),
                    // batch_id: requiredValidator(student_batch_id),
                }

                invalid = err.class_id || err.teacher_id || err.sub_id
            }
            if (state2.activeTab === '2' && Type === 'course') {

                err = {
                    class_id: requiredValidator(teacher.class_id),
                    student_id: requiredValidator(teacher.student_id),
                    course_id: requiredValidator(teachercourse ? teachercourse : ''),
                    batch_id: requiredValidator(student_batch_id),
                }
                invalid = err.class_id || err.student_id || err.course_id || err.batch_id
            }
            if (state2.activeTab === '2' && Type !== 'course') {
                err = {
                    class_id: requiredValidator(teacher.class_id),
                    student_id: requiredValidator(teacher.student_id),
                    sub_id: requiredValidator(subscription_value),
                    
                }
                invalid = err.class_id || err.student_id || err.sub_id 
            }

        }
        if (tokenData.role == 'teacher') {
            err = {
                class_id: requiredValidator(teacher.class_id),
                batch_id: requiredValidator(batch_id),
            }
            invalid = err.class_id || err.batch_id
        }
        if (tokenData.role == 'student') {
            err = {
                class_id: requiredValidator(teacher.class_id),
                batch_id: requiredValidator(student_batch_id),
            }
            invalid = err.class_id || err.batch_id
        }
        if (!invalid) {
            setError(err)


            if ((tokenData.role === 'principal' || tokenData.role === 'staff') && teacher.teacher_id === "" && state2.activeTab == 1) {

                Swal.fire({
                    icon: 'warning',
                    title: 'Required',
                    text: "Select teacher",

                })

            }
            else if (tokenData.role === 'student' && teacher.class_id === "") {
                Swal.fire({
                    icon: 'warning',
                    title: 'Required',
                    text: 'Select class',

                })

            } else {
                setFilterLoader(true)

                if (tokenData.role === 'student' || state2.activeTab == 2) {
                    if (student_batch_id) body.batch_id = student_batch_id
                    dispatch(filterAttendance(tokenData.token, body, cb))

                } else if (tokenData.role === "principal" || tokenData.role === "staff") {
                    if (batch_id) body.batch_id = batch_id
                    if (student_batch_id) body.batch_id = student_batch_id
                    dispatch(getAttendanceOfTeacher(tokenData.token, body, cb))

                } else {
                    if (batch_id) body.batch_id = batch_id
                    dispatch(getAttendance(tokenData.token, body, cb))

                }

            }
        } else {
            setError(err)
        }

    }
    const [filterLoader, setFilterLoader] = useState(false)
    const cb = () => {
        setFilterLoader(false)
    }

    // const Download = () => {
    //     dispatch(exportTeacherAttendance(tokenData.token, teacher.class_id, DwnloadCallback))
    // }
    // const DwnloadCallback = (val) => {
    //     if (val) {
    //         window.open(val, "_blank")
    //     }
    // }
    return (<>
        <PageTitle

            heading="Attendance Details"
            subheading="Attandance details"
            icon="pe-7s-users icon-gradient bg-happy-itmeo"
        />




        {tokenData.role === 'principal' || tokenData.role === 'staff' ?


            <Card tabs={true} className="mb-3">
                <CardHeader>
                    <Nav justified>
                        <NavItem>
                            <NavLink href="#"
                                className={classnames({ active: state2.activeTab === '1' })}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Facilitator
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                className={classnames({ active: state2.activeTab === '2' })}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Student
                            </NavLink>
                        </NavItem>


                    </Nav>
                </CardHeader>
                <CardBody>

                    <TabContent activeTab={state2.activeTab}>
                        <TabPane tabId="1">
                            <Card style={{ marginBottom: 20, padding: 20 }}>

                                <DateRangePicker
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    maxDate={new Date()}
                                    direction="horizontal"
                                    color='#fed858'
                                />
                                <hr />
                                <Row>
                                    <Col xs={12} md={6}>


                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Facilitator
                                        </Label>

                                        <Input type="select"
                                            onChange={(e) => handleChange(e)}
                                            name='teacher_id'
                                            required
                                            // value={teacher.teacher_id}
                                            invalid={error.teacher_id}
                                        >
                                            <option value={''}>{'select'}</option>
                                            {tStore.registeredTeachers ?
                                                tStore.registeredTeachers.map((registeredTeachers, ky) => {
                                                    return (
                                                        <option key={ky} value={registeredTeachers.user_id}>{registeredTeachers.first_name + " " + registeredTeachers.last_name}</option>
                                                        //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                                    )
                                                }) : []
                                            }

                                        </Input>
                                        <FormFeedback >{error.teacher_id}</FormFeedback>



                                    </Col>
                                    {Type === 'course' ?
                                        <Col xs={12} md={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Course
                                            </Label>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <Input type="select"
                                                        onChange={handleChangeCourse}
                                                        name='course_id'
                                                        value={teachercourse}
                                                        // required
                                                        // value={this.state.loaderType}
                                                        invalid={error.course_id}
                                                    >

                                                        <option value={''}>{'select'}</option>
                                                        {
                                                            teacherCourses ? teacherCourses.map((course) => {
                                                                return (
                                                                    <option value={course.course_id} key={course.course_id}>{course.course_name}</option>
                                                                )
                                                            })
                                                                : []
                                                        }

                                                    </Input>
                                                    {/* <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback> */}
                                                    {
                                                        tStore.loadteacher ?
                                                            <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </span>

                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                        :
                                        <Col xs={12} md={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Subscription
                                            </Label>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <Input type="select"
                                                        onChange={handleChangeSubscription}
                                                        name='subscription_id'
                                                        value={subscription_value}
                                                        // required
                                                        // value={this.state.loaderType}
                                                        invalid={error.sub_id}
                                                    >

                                                        <option value={''}>{'select'}</option>
                                                        {
                                                            teachersSubscriptions ? teachersSubscriptions.map((subscription) => {
                                                                return (
                                                                    <option value={subscription.subscription_id} key={subscription.subscription_id}>{subscription.subscription_name}</option>
                                                                )
                                                            })
                                                                : []
                                                        }

                                                    </Input>
                                                    {/* <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback> */}
                                                    {/* {
                                                tStore.loadteacher ?
                                                    <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                        <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </span>

                                                    :
                                                    null
                                            } */}
                                                </div>
                                            </div>
                                        </Col>

                                    }
                                    <Col xs={12} md={6}>

                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Class
                                        </Label>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <Input type="select"
                                                    onChange={(e) => handleChange(e)}
                                                    name='class_id'
                                                    required
                                                    value={teacher.class_id}
                                                    invalid={error.class_id}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        classes ? classes.map((c, ky) => {
                                                            return (
                                                                <option key={ky} value={c.class_id} key={c.class_id}>{c.class_name}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>
                                                {
                                                    loadclass ?
                                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>

                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <FormFeedback >{error.class_id}</FormFeedback>


                                    </Col>

                                    {
                                        Type === "course" &&
                                        <Col xs={12} md={6}>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Batch
                                            </Label>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <Input type="select"
                                                        onChange={(e) => setBatchId(e.target.value)}
                                                        name='batch_id'
                                                        required
                                                        value={batch_id}
                                                        invalid={error.batch_id}
                                                    >

                                                        <option value={''}>{'select'}</option>
                                                        {assignment.batchesinClass ?
                                                            assignment.batchesinClass.map((b, k) => {
                                                                return (
                                                                    <option key={k} value={b.group_id}>{b.group_name}</option>
                                                                )
                                                            }) : null
                                                        }

                                                    </Input>

                                                </div>
                                            </div>

                                        </Col>
                                    }

                                    <Col style={{ textAlign: 'right' }}>

                                        <LaddaButton className=" mr-2 btn btn-primary"
                                            style={{ marginTop: '30px' }}
                                            loading={filterLoader}
                                            type="button"
                                            onClick={filter}
                                            data-style={ZOOM_IN}  >
                                            Filter
                                        </LaddaButton>
                                    </Col>
                                </Row>

                            </Card>
                        </TabPane>
                        <TabPane tabId="2">
                            <Card style={{ marginBottom: 20, padding: 20 }}>

                                <DateRangePicker
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    maxDate={new Date()}
                                    direction="horizontal"
                                    color='#fed858'
                                />
                                <hr />
                                <Row>
                                    {Type === 'course' ?

                                        <Col xs={12} md={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Course
                                            </Label>
                                            {
                                                <Input type="select"
                                                    onChange={handleChangeCourse}
                                                    name='course_id'
                                                    required
                                                    value={teachercourse}
                                                    // value={this.state.loaderType}
                                                    invalid={tokenData.role !== "teacher" ? error.course_id : null}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        student_course ? student_course.map((course) => {
                                                            return (
                                                                <option value={course.course_id} key={course.course_id}>{course.course_name}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>
                                            }
                                            <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback>

                                        </Col> :

                                        <Col xs={12} md={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Subscription
                                            </Label>
                                            {
                                                <Input type="select"
                                                    onChange={handleChangeSubscription}
                                                    name='subscription_id'
                                                    value={subscription_value}
                                                    required
                                                    // value={this.state.loaderType}
                                                    invalid={tokenData.role !== "teacher" ? error.sub_id : null}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        subscriptions ? subscriptions.map((subscription) => {
                                                            return (
                                                                <option value={subscription.subscription_id} key={subscription.subscription_id}>{subscription.subscription_name}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>
                                            }
                                            <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback>

                                        </Col>
                                    }
                                    <Col xs={12} md={6}>

                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Class
                                        </Label>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <Input type="select"
                                                    onChange={(e) => handleChange(e)}
                                                    name='class_id'
                                                    required
                                                    value={teacher.class_id}
                                                    invalid={error.class_id}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        classes ? classes.map((data) => {
                                                            return (
                                                                <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>
                                                {
                                                    loadclass ?
                                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>

                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <FormFeedback >{error.class_id}</FormFeedback>



                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Student
                                        </Label>

                                        <div class="form-group">
                                            <div class="input-group">
                                                <Input type="select"
                                                    onChange={(e) => handleChange(e)}
                                                    name='student_id'
                                                    required
                                                    value={teacher.student_id}
                                                    invalid={error.student_id}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        tStore.specificStudent ? tStore.specificStudent.map((data) => {
                                                            return (
                                                                <option value={data.user_id} key={data.user_id}>  {`${data.first_name} ${data.last_name}`}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>
                                                {
                                                    tStore.specificStudentLoader ?
                                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>

                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <FormFeedback >{error.student_id}</FormFeedback>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Batch
                                        </Label>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <Input type="select"
                                                    onChange={(e) => setStudentBatchId(e.target.value)}
                                                    name='batch_id'
                                                    required
                                                    value={student_batch_id}
                                                    invalid={error.batch_id}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {student_batches ?
                                                        student_batches.map((b, k) => {
                                                            return (
                                                                <option key={k} value={b.group_id}>{b.group_name}</option>
                                                            )
                                                        }) : null
                                                    }

                                                </Input>
                                                {
                                                    batch_loader ?
                                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>

                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={12} style={{ textAlign: 'right' }}>
                                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                            <span className="text-danger">*</span>
                                            {' '}
                                        </Label>

                                        <LaddaButton className=" mr-2 btn btn-primary"
                                            loading={filterLoader}
                                            type="button"
                                            onClick={filter}
                                            data-style={ZOOM_IN}  >
                                            Filter
                                        </LaddaButton>
                                    </Col>
                                </Row>

                            </Card>
                        </TabPane>


                    </TabContent>

                </CardBody>
            </Card>
            :

            <Card style={{ marginBottom: 20, padding: 20 }}>

                <DateRangePicker
                    onChange={item => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    maxDate={new Date()}
                    direction="horizontal"
                    color='#fed858'
                />
                <hr />
                <Row>
                    {Type === 'course' ?
                        <Col xs={12} md={3}>

                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Select Course
                            </Label>

                            <Input type="select"
                                onChange={handleChangeCourse}
                                name='course_id'
                                required
                                // value={this.state.loaderType}
                                value={teachercourse}
                            // invalid={tokenData.role !== "teacher" ? error.class_id : null}
                            >
                                {
                                    tokenData.role === 'student' ?
                                        <>
                                            <option value={''}>{'select'}</option>
                                            {
                                                StudentCourseArray ? StudentCourseArray.map((course) => {
                                                    return (
                                                        <option value={course.course_id} key={course.course_id}>{course.course_name}</option>
                                                    )
                                                })
                                                    : []
                                            }
                                        </>
                                        :
                                        <>
                                            <option value={''}>{'select'}</option>
                                            {
                                                courses ? courses.map((course) => {
                                                    return (
                                                        <option value={course.course_id} key={course.course_id}>{course.course_name}</option>
                                                    )
                                                })
                                                    : []
                                            }
                                        </>
                                }


                            </Input>

                            <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback>

                        </Col>
                        : <>
                            <Col xs={12} md={3}>

                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                    {' '}Select Subscription
                                </Label>

                                <Input type="select"
                                    onChange={handleChangeSubscription}
                                    name='subscription_id'
                                    required
                                    // value={this.state.loaderType}
                                    invalid={tokenData.role !== "teacher" ? error.class_id : null}
                                >
                                    {
                                        tokenData.role === 'student' ?
                                            <>
                                                <option value={''}>{'select'}</option>
                                                {
                                                    subscriptions ? subscriptions.map((sub) => {
                                                        return (
                                                            <option value={sub.subscription_id} key={sub.subscription_id}>{sub.subscription_name}</option>
                                                        )
                                                    })
                                                        : []
                                                }
                                            </>
                                            :
                                            <>
                                                <option value={''}>{'select'}</option>
                                                {
                                                    subscriptions ? subscriptions.map((subscription) => {
                                                        return (
                                                            <option value={subscription.subscription_id} key={subscription.subscription_id}>{subscription.subscription_name}</option>
                                                        )
                                                    })
                                                        : []
                                                }
                                            </>
                                    }


                                </Input>

                                <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback>

                            </Col>
                        </>}
                    <Col xs={12} md={3}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}Select Class
                        </Label>
                        <div class="form-group">
                            <div class="input-group">
                                <Input type="select"
                                    onChange={(e) => handleChange(e)}
                                    name='class_id'
                                    required
                                    // invalid={tokenData.role !== "teacher" ? error.class_id : null}
                                    invalid={error.class_id}
                                    value={teacher.class_id}
                                >

                                    <option value={''}>{'select'}</option>
                                    {
                                        classes ? classes.map((data) => {
                                            return (
                                                <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                            )
                                        })
                                            : []
                                    }

                                </Input>
                                {
                                    loadclass ?
                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </span>

                                        :
                                        null
                                }
                            </div>
                        </div>

                    </Col>

                    {
                        tokenData.role === 'teacher' && Type === "course" &&
                        <Col xs={12} md={3}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Select Batch
                            </Label>
                            <div class="form-group">
                                <div class="input-group">
                                    <Input type="select"
                                        onChange={(e) => setBatchId(e.target.value)}
                                        name='batch_id'
                                        required
                                        value={batch_id}
                                        invalid={error.batch_id}
                                    >

                                        <option value={''}>{'select'}</option>
                                        {assignment.batchesinClass ?
                                            assignment.batchesinClass.map((b, k) => {
                                                return (
                                                    <option key={k} value={b.group_id}>{b.group_name}</option>
                                                )
                                            }) : null
                                        }

                                    </Input>
                                    {
                                        assignment.batchesinClassLoader ?
                                            <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>

                                            :
                                            null
                                    }
                                </div>
                            </div>

                        </Col>
                    }


                    {
                        tokenData.role === "student" && Type === "course" &&
                        <Col xs={12} md={3}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Select Batch
                            </Label>
                            <div class="form-group">
                                <div class="input-group">
                                    <Input type="select"
                                        onChange={(e) => setStudentBatchId(e.target.value)}
                                        name='batch_id'
                                        required
                                        value={student_batch_id}
                                        invalid={error.batch_id}
                                    >

                                        <option value={''}>{'select'}</option>
                                        {student_batches ?
                                            student_batches.map((b, k) => {
                                                return (
                                                    <option key={k} value={b.group_id}>{b.group_name}</option>
                                                )
                                            }) : null
                                        }

                                    </Input>
                                    {
                                        batch_loader ?
                                            <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>

                                            :
                                            null
                                    }
                                </div>
                            </div>

                        </Col>
                    }


                    <Col xs={6} md={1}>
                        <LaddaButton className="btn btn-primary"
                            style={{ marginTop: '30px' }}
                            loading={filterLoader}
                            type="button"
                            onClick={filter}
                            data-style={ZOOM_IN}  >
                            Filter
                        </LaddaButton>

                    </Col>
                    {/* {
                        tokenData.role === "teacher" ? 
                        <Col xs={6} md={1}>
                             <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                <span className="text-danger">*</span>
                                    {' '}
                                </Label>
                            <Tooltip title='Download Invoice'>
                                <LaddaButton className="btn btn-danger"
                                    loading={teacherinvoiceloader}
                                    type="button"
                                    onClick={Download}
                                    data-style={ZOOM_IN}  >
                                    Invoice
                                </LaddaButton>
                            </Tooltip>
                        </Col>
                        :
                        ''
                    } */}
                </Row>

            </Card>

        }


        {/* <form onSubmit={filter}>
            <Card style={{ marginBottom: 20, padding: 20 }}>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}From
                                                    </Label>

                        <Input type="date" name="from_date" />
                    </Col>
                    <Col md={3}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}To
                                                    </Label>

                        <Input type="date" name="to_date" />

                    </Col>
                    <Col md={3} >
                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                            <span className="text-danger">*</span>
                            {' '}To
                                                    </Label>
                        <LaddaButton className=" mr-2 btn btn-primary"
                            style={{ width: '-webkit-fill-available' }}
                            //loading={groupStore.createGroupLoader}
                            type="submit"
                            data-style={ZOOM_IN}  >
                            Filter
                        </LaddaButton>
                    </Col>
                </Row>
            </Card>
        </form>
 */}

        <Row>
            <Col sm={12} md={12}>
                <Table
                    loader={tStore.attendanceLoader}
                    data={tokenData.role == 'student' || state2.activeTab == 2 ? tStore.studentAttandance.details : tStore.attendanceInfo}
                    //  data={dummyData}
                    role={tokenData.role}
                    columns={columns}
                    tab={state2.activeTab}
                    actions='attandance'
                    handleSelectedRow={handleSelectedRow}
                />
            </Col>
        </Row >
        {/* <Modals
            open={openStdModal}
            handleClose={handleCloseStudentModal}
            title={`Assign student`}
        >
            <AssignStudents
                classId={classId}
                data={tStore.specificStudent}
                handleCloseStudentModal={handleCloseStudentModal2}
            />
        </Modals>


        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Completed successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} /> */}
    </>
    )
}

export default Index
