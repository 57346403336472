import React, { useEffect, useState } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
// import CreateClass from '../Class/CreateClass/Home'
import ShowClass from '../Class/ShowClass'
import ShowSubscriptionClass from '../Class/SubscriptionClass/SubscriptionClass'

import Students from '../Students'
// import SpecialClass from '../Class/SpecialClass/'
import ShowAttendance from '../Attendance/ShowAttendance'
import ShowAttendanceDetails from '../Attendance/Details'
import AuthCheck from '../AuthCheck'
// import EditClass from '../Class/EditClass'
import { useDispatch, useSelector } from "react-redux";
import Modals from '../common/Modal'
import Loader from 'react-loader'
import MyAccount from '../MyAccount'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Updates from './updates'
// import Exam from '../Exam'
// import Instructions from '../Exam/Instructions/instruction'
import Plans from './plans'
// import showGroup from '../Group/showGroup'
import {
    faCheckCircle, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import ChangePassword from '../ChangePassword'
// import Grouping from '../Group'
// import PageTitle from '../../Layout/AppMain/PageTitle';
import ClassTeacher from '../Class/ClassRoom/teacher'
import ClassStudent from '../Class/ClassRoom/student'
// import { development } from '../../config.json'
import Subject from '../Subjects'

import Payment from '../Payment'
// Layout
import AppHeader from '../../Layout/AppHeader/';
import AppSidebar from '../../Layout/AppSidebar/';
import StudyMaterialUpload from '../StudyMaterials/Teacher'
import HomeTeacher from '../Home/teacher'
import EditVideoClass from '../VideoSession/VideoClass/editVideoClass'
import HomeStudent from '../Home/student'
import HomePrincipal from '../Home/principal'
import Assignments from '../Assignment'
import Assignmentss from '../Assignment/Details'
import StudentAssignments from '../Assignment/StudentAssigment'
import SpecificAssignment from '../Assignment/Details/specificStudentAssignment'
// import CreateVideoSession from '../VideoSession/CreateVideoSession'
import Recordings from '../Recordings'
// import VideoClass from '../VideoSession/VideoClass'
import ListVideo from '../VideoSession/ListVideos'
import FormRegister from '../FormRegister'
import ShowTeacher from '../FormRegister/Teacher/ShowTeacher'
import StudyNotesWrite from '../StudyNotes/Teacher'
// import ShowStudent from '../FormRegister/Student/ShowStudent'
// import ShowStaff from '../FormRegister/Staff/ShowStaff'
// Theme Options
//import Playlist from "../VideoSession/Playlist";
import Playlist from "../VideoSession/Playlist/CreatePlaylist/school";
import PlaylistDetails from "../VideoSession/Playlist/UploadVideos";
// import ThemeOptions from "../../Layout/ThemeOptions";
import { Switch, Route, Redirect } from "react-router-dom";
//import ManageGroup from "../Group/ManageGroup";
import StudyNoteView from '../StudyNotes/ViewNote'
//vendor
//import MyProfile from "../Vendor/MyAccount/MyAccount"
import UploadVendorDoc from "../Vendor/MyAccount"
import createCourse from "../Vendor/CreateCourse";
import classCreate from "../Vendor/CreateClass";
// import ViewCourselist from "../Vendor/Viewcourse/Viewcourselist"
import ViewCourselistCards from "../Vendor/Viewcourse/Cards"
import Editcourse from "../Vendor/Editcourse/EditCourse"
import EditCourseClass from '../Vendor/EditClass/index'
import BlockedScreen from '../../Components/Blocked/Index'
import VendorPreview from "../../Components/Vendor/MyAccount/vendorpreview"
import SingleCoursePreview from "../../Components/Vendor/Viewcourse/PreviewCourse"
import AccountPreview from '../../Components/Vendor/ProfilePreview'
import VideoSessionTabs from '../../Components/VideoSession/VideoSessionTabs'
import Group from '../Vendor/Subscription/Groups'

import CreateSubscription from "../../Components/Vendor/Subscription/CreateSubscription"
import EditSubscription from "../Vendor/Subscription/EditSubscription"
import SubscriptionList from "../Vendor/Subscription/SubscriptionList"
import SubscriptionStudentList from "../Vendor/Subscription/StudentList"
//reseller
import TranasctionsList from '../../Components/Reseller/Transactions'
import ResellerHome from '../../Components/Reseller/Home'
import SalesList from "../../Components/Reseller/Sales/Sales"
import ViewPincodes from '../../Components/SuperadminComponents/Reseller/PinAssignMent/index'
import {
    Row, Col,
    Button,

} from 'reactstrap';
import { emailSendForVerification, getNotificationAlert } from '../../redux/Actions/homeTeacher'
// import { logout } from '../../redux/Actions/auth'
import { useHistory, Link } from 'react-router-dom'


///////super admin import part
import Superadminhome from "../Home/Superadmin"
import Index from "../SuperadminComponents/Vendor/index"
import Createvendor from "../SuperadminComponents/Vendor/Createvendor"
import Editvendor from "../SuperadminComponents/Vendor/Editvendor"
// import Vendordetails from "../SuperadminComponents/Vendor/Vendordetails/Studentdetails"
// import VendorCousedetails from "../SuperadminComponents/Vendor/Vendordetails/Coursedetails"
import StudentCourseDetails from "../SuperadminComponents/Vendor/Vendordetails/StudentCoursedetails"
import Vendordetails from "../SuperadminComponents/Vendor/Vendordetails/Viewdetails"
import CouseFulldetails from "../SuperadminComponents/Vendor/Vendordetails/Coursefulldetails"
import videolist from "../SuperadminComponents/Vendor/Vendordetails/Coursefull/Videolist"

import CreateReseller from "../SuperadminComponents/Reseller/CreateReseller"
import Resellerlist from "../SuperadminComponents/Reseller/Resellerlist"
import EditReseller from "../SuperadminComponents/Reseller/EditReseller"
import ResellerDetails from "../SuperadminComponents/Reseller/ResellerDetails"
import FilterAssignedPincodes from "../SuperadminComponents/AssignedPinsFilter"

import Viewstudent from "../SuperadminComponents/Reseller/Resellerviews/Viewstudent"
import CreateSchool from "../SuperadminComponents/school/Createschool"
import Schoollist from "../SuperadminComponents/school/Schoollist"
import EditSchool from "../SuperadminComponents/school/EditSchool"
import SchoolDetails from "../SuperadminComponents/school/SchoolDetails"

import Studentlist from "../SuperadminComponents/student/Listsudent"
import Singlestudent from "../SuperadminComponents/student/Singlestudent"
import CreateStudent from "../SuperadminComponents/student/Createstudent"
import EditStudent from "../SuperadminComponents/student/EditStudent"

import Paymentlist from "../SuperadminComponents/payments/Paymentlist"

import Courselist from "../SuperadminComponents/Courses/Courseslist"
import PendingCourselist from "../SuperadminComponents/Courses/Pendingcourselist"
import RejectedCourselist from "../SuperadminComponents/Courses/Rejectedcourselist"

import TicketList from "../SuperadminComponents/Tickets/Ticketlist"
import TicketDetails from "../SuperadminComponents/Tickets/Ticketdetails"

import CustomHomePage from '../SuperadminComponents/SetHomepage'

import TagIndex from "../SuperadminComponents/Tags/index"
// import EditAccount from '../SuperadminComponents/EditAccount'
import SalesListSchool from '../School/Sales/Sales'
import TranasctionsListVendor from "../Vendor/Transactions"
import SalesListVendor from "../Vendor/Sales/Sales"
import CreateCourseCategory from '../SuperadminComponents/CourseCategory'
import viewCategory from '../SuperadminComponents/CourseCategory/EditCategory'

import Testimonial from "../SuperadminComponents/Testimonial/Testimonial"
import CreateTestimonial from "../SuperadminComponents/Testimonial/Createtestimony"
import EditTestimonial from "../SuperadminComponents/Testimonial/Edittestimony"

import Refunds from '../SuperadminComponents/Refund'

import CreateGallery from "../SuperadminComponents/Gallery/CreateGallery"
import EditGallery from "../SuperadminComponents/Gallery/EditGallery"
import GalleryList from "../SuperadminComponents/Gallery/galleryList"
import CreateGalleryPost from "../SuperadminComponents/Gallery/CreateGalleryPost"

import PendingEditCourselist from "../../Components/SuperadminComponents/Courses/EditRequest/Pendingedit"
import ApprovedEditCourselist from "../../Components/SuperadminComponents/Courses/EditRequest/ApprovedEdit"

import CreateNewsComponent from "../../Components/SuperadminComponents/News/CreateNews"
import EditNews from "../../Components/SuperadminComponents/News/EditNews"
import NewsList from "../../Components/SuperadminComponents/News/NewsList"
////////////////////////
///////////////////student new routes//////////////////
import StudentTicketlist from "../Studentcomponents/Tickets/Ticketlist"
import StudentTicketDetails from "../Studentcomponents/Tickets/Ticketdetails"
import StudentCourseList from "../Studentcomponents/Courses/CourseList"
import StudentRefunds from '../Studentcomponents/Refund'
import SchoolHome from '../School/Home'
import TranasctionsListSchool from "../School/TransactionList/Transactions"

import PageNotFound from '../../Components/PageNotFound'
import SubscriptionAll from "../Studentcomponents/Subscription/SubscriptionAll"
import ExpiredSubscriptions from "../Studentcomponents/Subscription/Expires"
import RenewelSubscriptions from "../Studentcomponents/Subscription/Renewel"
import SubscriptionDetails from "../Vendor/Subscription/SubscriptionDetails"
import CreateLiveClassSubscription from '../../Components/Vendor/Subscription/LiveClass/Create'
import EditLiveClassSubscription from '../../Components/Vendor/Subscription/LiveClass/Edit'
import SubscriptionVideoSessionTabs from '../../Components/Vendor/Subscription/CreateVideoSession/VideoSessionTabs/index'
import EditSubscriptionVideoClass from '../../Components/Vendor/Subscription/CreateVideoSession/VideoClass/editVideoClass'
import CouseFulldetailsByLink from "../Studentcomponents/CourseDetailsByLink/CourseDetails"
import StudyMaterialsStudent from '../StudyMaterials/Student'
import StudyMaterialsView from '../StudyMaterials/ViewStudyMaterials'
import StudyNoteEdit from '../StudyNotes/Teacher/Edit'
import StudentList from "../Vendor/Viewcourse/StudentList"
import { setHomeData,LogoutSession } from "../../redux/Actions/genexhome"
import StudyNotesStudent from '../StudyNotes/Student'

const AnalyticsDashboard = () => {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const whatsnewStore = JSON.parse(localStorage.getItem("whatsnew"));

    const storeData = useSelector(({ homeTeacher }) => homeTeacher)
    const authData = useSelector(({ auth }) => auth)

    const history = useHistory()
    const dispatch = useDispatch();
    const [newUpdates, setNewUpdates] = useState(false)


    useEffect(() => {
        if (tokenData) {
            dispatch(setHomeData('HomeArray', ''))

            dispatch({ type: 'SET_PLIVILLAGES', payload: tokenData.privilege })
            if (tokenData.role === "teacher") {
                if (whatsnewStore) {
                    if (whatsnewStore.count <= 1 && storeData.whatsnew) {
                        if (whatsnewStore.date != storeData.whatsnew.date) {
                            dispatch(getNotificationAlert(tokenData.token, openUpdate))
                        }
                    } else {
                        dispatch(getNotificationAlert(tokenData.token, openUpdate))

                    }

                } else {
                    dispatch(getNotificationAlert(tokenData.token, openUpdate))

                }

            }

        }

        if (!tokenData) {
            history.push('/')
        }

    }, [])
    const [updateDate, setUpdateDate] = useState()
    const openUpdate = (date) => {

        setUpdateDate(date)
        setNewUpdates(true)
    }
    const closeNewUpdates = () => {
        setNewUpdates(false)
        const localstore = {
            date: updateDate,
            count: whatsnewStore.count + 1
        }

        localStorage.setItem('whatsnew', JSON.stringify(localstore));

    }


    const logOut = () => {
        dispatch(LogoutSession(tokenData.token))
        localStorage.removeItem("authDetails");
        history.push('/')
    }
    const resentMail = () => {
        dispatch(emailSendForVerification(tokenData.token))
    }

    const getComponent = (typ) => {
        switch (typ) {
            case true:
                return <><p style={{ color: 'blue' }}>Sending...</p><Loader options={options} /></>
            case 'sent':
                return <><p style={{ color: "green" }}>Sent{`  `}<FontAwesomeIcon color="green" icon={faCheckCircle} /></p></>
            case 'failed':
                return <><p style={{ color: "red" }}>Failed{`  `}<FontAwesomeIcon color="red" icon={faExclamationTriangle} /></p></>
            case '':
                return <Link onClick={() => resentMail()}><Button color='primary' btn-shadow btn-hover-shine style={{ fontSize: '12px', padding: '5px 18px' }} className="btn-shadow" >Verify your Email</Button></Link>
        }
    }
    var options = {
        lines: 13,
        length: 4,
        width: 2,
        radius: 5,
        scale: 1.00,
        corners: 1,
        color: '#000',
        opacity: 0.25,
        rotate: 0,
        direction: 1,
        speed: 1,
        trail: 60,
        fps: 20,
        zIndex: 2e9,
        top: '36%',
        left: '100%',
        shadow: false,
        hwaccel: false,
        position: 'absolute'
    };
    const changePlan = (plan) => {
        dispatch({ type: 'CHANGE_PLAN', payload: plan })
    }
    return (
        <AuthCheck>
            {
                tokenData ? <>

                    <AppHeader
                        user={tokenData ? tokenData.name : null}
                        role={tokenData ? tokenData.role : null}
                    />
                    <div className="app-main">
                        <AppSidebar
                            role={tokenData ? tokenData.role : null}
                            enterprise_user={tokenData ? tokenData.enterpriseUser : null}
                            privillage={authData.privillages}

                        />
                        <div className="app-main__outer">
                            <div className="app-main__inner">
                                <CSSTransitionGroup
                                    component="div"
                                    transitionName="TabsAnimation"
                                    transitionAppear={true}
                                    transitionAppearTimeout={0}
                                    transitionEnter={false}
                                    transitionLeave={false}>

                                    {tokenData.role === 'teacher' ?

                                        <Switch>
                                            {tokenData.enterpriseUser ?
                                                <Redirect exact from="/dashboard" to={
                                                    tokenData.privilege[0].content.length > 0 ?
                                                        tokenData.privilege[0].content[0].to.replace('#', '')
                                                        : tokenData.privilege[0].to.replace('#', '')

                                                } /> :
                                                <Redirect exact from="/dashboard" to="/dashboard/overview" />


                                            }

                                            {!tokenData.enterpriseUser &&
                                                <Route exact path="/dashboard/payment" component={Payment} />

                                            }
                                            <Route exact path="/dashboard/videoClass/edit/:class_id/:subject_id" component={EditVideoClass} />
                                            <Route exact path="/dashboard/overview" component={HomeTeacher} />

                                            <Route exact path="/dashboard/study-notes-write" component={StudyNotesWrite} />
                                            <Route exact path="/dashboard/study-notes-edit/:class_id/:study_material_id" component={StudyNoteEdit} />
                                            <Route exact path="/dashboard/study-notes-view/:class_id/:study_material_id" component={StudyNoteView} />

                                            <Route exact path="/dashboard/study-materials-upload" component={StudyMaterialUpload} />
                                            <Route exact path="/dashboard/study-materials-view/:class_id/:study_material_id" component={StudyMaterialsView} />

                                          
                                            <Route exact path="/dashboard/assignments/students/:assignment_id/:class_id" component={StudentAssignments} />
                                            <Route exact path="/dashboard/subscription/show-attendance" component={ShowAttendance} />
                                            <Route path="/dashboard/show-class" component={ShowClass} />
                                            <Route path="/dashboard/subscription/show-class" component={ShowSubscriptionClass} />
                                            <Route path="/dashboard/Recordings" component={Recordings} />
                                            <Route path="/dashboard/change-password" component={ChangePassword} />
                                            <Route exact path="/dashboard/my-account" component={MyAccount} />

                                            {/* <Route exact path="/dashboard/my-account" component={MyProfile} /> */}
                                            <Route exact path="/dashboard/startclass/:week_id/:class_id/:type" component={ClassTeacher} />
                                            <Route path="/dashboard/Recordings" component={Recordings} />

                                            {tokenData.plan_id !== 1 && <>
                                                <Route exact path="/dashboard/show-attendance" component={ShowAttendance} />
                                                <Route exact path="/dashboard/show-attendance/:class_id" component={ShowAttendanceDetails} />
                                                <Route exact path="/dashboard/assignments" component={Assignments} />
                                                <Route exact path="/dashboard/subscriptions/assignments" component={Assignments} />
                                                <Route exact path="/dashboard/assignments/:assig_id/:class_id" component={Assignmentss} />
                                                <Route exact path="/dashboard/assignments/:assig_id/:class_id/:student_id/:assesment_id" component={SpecificAssignment} />
                                            </>
                                            }

                                            <Route component={PageNotFound} />
                                        </Switch>
                                        :
                                        tokenData.role === 'student' ?
                                            <Switch>
                                                {tokenData.enterpriseUser ?
                                                    <Redirect exact from="/dashboard" to={
                                                        tokenData.privilege[0].content.length > 0 ?
                                                            tokenData.privilege[0].content[0].to.replace('#', '')
                                                            : tokenData.privilege[0].to.replace('#', '')

                                                    } /> :
                                                    <Redirect exact from="/dashboard" to="/dashboard/overview" />

                                                }

                                                <Route path="/dashboard/overview" component={HomeStudent} />
                                                {/* <Route path="/dashboard/show-class" component={ShowClass} /> */}
                                                <Route path="/dashboard/join/:week_id/:class_id" component={ClassStudent} />
                                                <Route path="/dashboard/join/:week_id/:class_id/:subscription_id" component={ClassStudent} />
                                                <Route exact path="/dashboard/show-attendance" component={ShowAttendance} />
                                                <Route exact path="/dashboard/subscription/show-attendance" component={ShowAttendance} />
                                                <Route exact path="/dashboard/show-attendance/:class_id/:session_id" component={ShowAttendanceDetails} />
                                                <Route exact path="/dashboard/assignments" component={Assignments} />
                                                <Route exact path="/dashboard/assignments/:assig_id/:class_id" component={Assignmentss} />
                                                <Route exact path="/dashboard/subscriptions/assignments" component={Assignments} />
                                                <Route path="/dashboard/change-password" component={ChangePassword} />
                                                <Route path="/dashboard/my-account" component={MyAccount} />
                                                {/* <Route path="/dashboard/feedback" component={Feedback} /> */}
                                                <Route path='/dashboard/tickets' component={StudentTicketlist}></Route>
                                                <Route path='/dashboard/ticketdetails/:ticket_id' component={StudentTicketDetails}></Route>
                                                <Route path='/dashboard/myCourse' component={StudentCourseList}></Route>
                                                <Route exact path='/dashboard/viewCourse/:course_id/:order_id' component={CouseFulldetails}></Route>
                                                <Route exact path="/dashboard/viewCourse/:course_id/:order_id/videos" component={videolist} />
                                                <Route path='/dashboard/payments/refund' component={StudentRefunds}></Route>

                                                <Route exact path='/dashboard/subscriptions/expired' component={ExpiredSubscriptions}></Route>
                                                <Route exact path='/dashboard/subscriptions/renewal' component={RenewelSubscriptions}></Route>
                                                <Route path='/dashboard/subscriptions' component={SubscriptionAll}></Route>
                                                <Route exact path='/dashboard/subscription/:subscription_id' component={SubscriptionDetails}></Route>
                                                <Route exact path='/dashboard/subscription/:video_id/videos' component={videolist}></Route>
                                              
                                                <Route exact path='/dashboard/study-materials' component={StudyMaterialsStudent}></Route>
                                                <Route exact path='/dashboard/study-notes' component={StudyNotesStudent}></Route>
                                                <Route exact path="/dashboard/study-notes-view/:class_id/:study_material_id" component={StudyNoteView} />

                                                {/* route for course page when comes through link */}
                                                <Route exact path='/dashboard/course/:course_id/details/:token' component={CouseFulldetailsByLink}></Route>

                                                <Route component={PageNotFound} />

                                            </Switch>
                                            : tokenData.role === 'principal' && tokenData.blocked_status === 1 ?
                                                <Switch>
                                                    <Redirect exact from="/dashboard" to={"/dashboard/blockScreen"} />
                                                    <Route exact path="/dashboard/blockScreen" component={BlockedScreen} />
                                                    <Route exact path="/dashboard/my-account" component={MyAccount} />

                                                    <Route path="/dashboard/my-account/editAccount" component={UploadVendorDoc} />
                                                    <Route path="/dashboard/change-password" component={ChangePassword} />
                                                    <Route path='/dashboard/my-account/Preview' component={VendorPreview}></Route>
                                                    <Route component={BlockedScreen} />
                                                </Switch>

                                                : tokenData.role === 'principal' && tokenData.blocked_status === 0 ?
                                                    <Switch>


                                                        <Redirect exact from="/dashboard" to={
                                                            tokenData.privilege[0].content.length > 0 ?
                                                                tokenData.privilege[0].content[0].to.replace('#', '')
                                                                : tokenData.privilege[0].to.replace('#', '')
                                                        } />
                                                        <Route exact path="/dashboard/overview" component={HomePrincipal} />
                                                        <Route exact path="/dashboard/transactions" component={TranasctionsListVendor} />
                                                        <Route exact path='/dashboard/sales' component={SalesListVendor}></Route>

                                                        <Route path="/dashboard/join/:week_id/:class_id" component={ClassStudent} />

                                                        <Route exact path="/dashboard/assignments/students/:assignment_id/:class_id" component={StudentAssignments} />
                                                        <Route path="/dashboard/show-class" component={ShowClass} />
                                                        <Route exact path="/dashboard/show-attendance" component={ShowAttendance} />

                                                        <Route exact path="/dashboard/show-attendance/:class_id" component={ShowAttendanceDetails} />
                                                        <Route path="/dashboard/students" component={Students} />
                                                        <Route path="/dashboard/subjects" component={Subject} />
                                                        <Route exact path="/dashboard/playlist" component={Playlist} />
                                                        <Route exact path="/dashboard/playlist/:playlist_id/" component={PlaylistDetails} />
                                                        <Route path="/dashboard/Recordings" component={Recordings} />
                                                        <Route exact path="/dashboard/assignments" component={Assignments} />
                                                        <Route exact path="/dashboard/assignments/:assig_id/:class_id" component={Assignmentss} />
                                                        <Route exact path="/dashboard/assignments/:assig_id/:class_id/:student_id/:assesment_id" component={SpecificAssignment} />
                                                        <Route path="/dashboard/videolist/:course_id" component={videolist} />
                                                        <Route exact path="/dashboard/startclass/:week_id/:class_id/:type" component={ClassTeacher} />
                                                        <Route path="/dashboard/change-password" component={ChangePassword} />
                                                        <Route exact path="/dashboard/my-account" component={MyAccount} />
                                                        <Route path="/dashboard/Recordings" component={Recordings} />
                                                        <Route path="/dashboard/group" component={Group} />
                                                        <Route path="/dashboard/forms" component={FormRegister} />
                                                        <Route path="/dashboard/show-teacher" component={ShowTeacher} />

                                                        <Route
                                                            path="/dashboard/createCourse"
                                                            component={createCourse}
                                                        />

                                                        <Route exact
                                                            path="/dashboard/createClass/:course_id"
                                                            component={classCreate}
                                                        />
                                                        <Route exact path="/dashboard/showCourse" component={ViewCourselistCards} />
                                                        <Route exact path="/dashboard/editCourse/:course_id" component={Editcourse} />
                                                        <Route exact path="/dashboard/editCourse/:course_id/editVideoClass/:video_class_id" component={EditVideoClass} />
                                                        <Route exact path="/dashboard/editCourse/:class_id/videoList/:video_class_id" component={ListVideo} />
                                                        <Route path="/dashboard/createVideoSession/:course_id" component={VideoSessionTabs} />
                                                        <Route exact path="/dashboard/showCourse/:course_id" component={CouseFulldetails} />
                                                        <Route exact path="/dashboard/showCourse/:course_id/videos" component={videolist} />
                                                        <Route exact path="/dashboard/showCourse/Preview/:course_id" component={SingleCoursePreview} />

                                                        {/* <Route path="/dashboard/createVideoSession/:course_id" component={CreateVideoSession} /> */}

                                                        <Route exact path="/dashboard/editCourse/:course_id/editClass/:class_id" component={EditCourseClass} />

                                                        <Route path="/dashboard/my-account/editAccount" component={AccountPreview} />
                                                        {/* <Route path="/dashboard/my-account/editAccount" component={UploadVendorDoc} /> */}
                                                        <Route path='/dashboard/my-account/Preview' component={VendorPreview}></Route>

                                                        <Route path='/dashboard/course/:course_id/student-list' component={StudentList}></Route>

                                                        {
                                                            tokenData.subscrition_status === true ?
                                                                <Switch>
                                                                    <Route path='/dashboard/createSubscription' component={CreateSubscription}></Route>
                                                                    <Route exact path='/dashboard/editSubscription/:subscription_id' component={EditSubscription}></Route>
                                                                    <Route exact path="/dashboard/subscription/show-attendance" component={ShowAttendance} />
                                                                    <Route exact path='/dashboard/subscription/createLiveClass/:subscription_id' component={CreateLiveClassSubscription}></Route>
                                                                    <Route exact path='/dashboard/subscription/:subscription_id' component={SubscriptionDetails}></Route>
                                                                    <Route exact path="/dashboard/subscription/createVideoSession/:subscription_id" component={SubscriptionVideoSessionTabs} />
                                                                    <Route exact path='/dashboard/subscription/:video_id/videos' component={videolist}></Route>
                                                                    <Route exact path="/dashboard/editSubscription/:subscription_id/editVideoClass/:video_class_id" component={EditSubscriptionVideoClass} />
                                                                    <Route exact path="/dashboard/editSubscription/:subscription_id/editClass/:class_id" component={EditLiveClassSubscription} />
                                                                    <Route exact path='/dashboard/subscriptions' component={SubscriptionList}></Route>
                                                                    <Route path='/dashboard/subscriptions/student-list/:subscription_id' component={SubscriptionStudentList}></Route>
                                                                    <Route component={PageNotFound} />
                                                                </Switch>
                                                                : null
                                                        }

                                                        <Route component={PageNotFound} />
                                                    </Switch>
                                                    :
                                                    tokenData.role === 'superAdmin' ?
                                                        <Switch>

                                                            {/* <Redirect exact from="/dashboard" to={
                                                            tokenData.privilege[0].content.length > 0 ?
                                                                tokenData.privilege[0].content[0].to.replace('#', '')
                                                                : tokenData.privilege[0].to.replace('#', '')

                                                        } /> */}
                                                            <Redirect exact from="/dashboard" to="/dashboard/overview" />
                                                            <Route exact path="/dashboard/overview" component={Superadminhome} />
                                                            <Route path="/dashboard/vendors" component={Index} />
                                                            <Route path="/dashboard/createvendor" component={Createvendor} />
                                                            <Route path="/dashboard/editVendor/:enterprise_id" component={Editvendor} />


                                                            <Route exact path="/dashboard/vendor/:enterprise_id/:user_id" component={Vendordetails} />
                                                            <Route exact path="/dashboard/vendor/:enterprise_id/course/:course_id" component={CouseFulldetails} />
                                                            {/* <Route exact path="/dashboard/vendor/course/:course_id" component={CouseFulldetails} /> */}

                                                            <Route exact path="/dashboard/vendor/:enterprise_id/student/:student_id" component={StudentCourseDetails} />
                                                            <Route exact path="/dashboard/vendor/course/:course_id/videos" component={videolist} />
                                                            <Route exact path='/dashboard/preview/:user_id' component={VendorPreview}></Route>
                                                            <Route exact path="/dashboard/vendor/:enterprise_id/subscription/:subscription_id" component={SubscriptionDetails} />

                                                            <Route path="/dashboard/createreseller" component={CreateReseller} />
                                                            <Route path="/dashboard/reseller" component={Resellerlist} />
                                                            <Route exact path="/dashboard/resllerstudent/:std_id" component={Viewstudent} />
                                                            <Route path="/dashboard/editReseller/:reseller_id" component={EditReseller} />
                                                            <Route path="/dashboard/viewReseller/:reseller_id" component={ResellerDetails} />
                                                            <Route path="/dashboard/filterPincodes" component={FilterAssignedPincodes} />
                                                            <Route path="/dashboard/ViewPincodes" component={ViewPincodes} />


                                                            <Route path='/dashboard/manageCategoryTag' component={TagIndex}></Route>
                                                            <Route path='/dashboard/viewCategory/:grade_id' component={viewCategory}></Route>
                                                            <Route path='/dashboard/createschool' component={CreateSchool}></Route>
                                                            <Route exact path='/dashboard/schoollist' component={Schoollist}></Route>
                                                            <Route exact path='/dashboard/schoollist/:school_id' component={SchoolDetails}></Route>
                                                            <Route path='/dashboard/editSchool/:school_id' component={EditSchool}></Route>


                                                            <Route path='/dashboard/student' component={Studentlist}></Route>
                                                            <Route path='/dashboard/payment' component={Paymentlist}></Route>

                                                            <Route path='/dashboard/studentdetails/:student_id' component={Singlestudent}></Route>
                                                            <Route path='/dashboard/createstudent' component={CreateStudent}></Route>
                                                            <Route path='/dashboard/editStudent/:student_id' component={EditStudent}></Route>


                                                            <Route exact path='/dashboard/activecourses' component={Courselist}></Route>
                                                            <Route exact path='/dashboard/pendingcourses' component={PendingCourselist}></Route>
                                                            <Route exact path='/dashboard/rejectedcourses' component={RejectedCourselist}></Route>
                                                            <Route exact path='/dashboard/reschulingcourses' component={Courselist}></Route>
                                                            <Route exact path='/dashboard/activecourses/course/:course_id' component={CouseFulldetails}></Route>
                                                            <Route exact path='/dashboard/pendingcourses/course/:course_id' component={CouseFulldetails}></Route>
                                                            <Route exact path='/dashboard/rejectedcourses/course/:course_id' component={CouseFulldetails}></Route>
                                                            <Route exact path='/dashboard/reschulingcourses/course/:course_id' component={CouseFulldetails}></Route>

                                                            <Route path='/dashboard/tickets' component={TicketList}></Route>
                                                            <Route path='/dashboard/ticketdetails/:ticket_id' component={TicketDetails}></Route>
                                                            <Route exact path="/dashboard/my-account" component={MyAccount} />
                                                            <Route path="/dashboard/settings" component={CustomHomePage} />
                                                            <Route path="/dashboard/change-password" component={ChangePassword} />

                                                            <Route path="/dashboard/testimonial" component={Testimonial} />
                                                            <Route path="/dashboard/createTestimonial" component={CreateTestimonial} />
                                                            <Route path="/dashboard/editTestimonial/:tesimony_id" component={EditTestimonial} />

                                                            <Route exact path='/dashboard/Refunds' component={Refunds}></Route>

                                                            <Route path="/dashboard/createGallery" component={CreateGallery} />
                                                            <Route exact path="/dashboard/gallery" component={GalleryList} />
                                                            <Route path="/dashboard/editGalleryPost/:gallery_id" component={EditGallery} />
                                                            <Route path="/dashboard/gallery/add-media/:gallery_id" component={CreateGalleryPost} />

                                                            <Route path="/dashboard/editRequest" component={PendingEditCourselist} />
                                                            <Route path="/dashboard/editedCourse" component={ApprovedEditCourselist} />

                                                            <Route path="/dashboard/createNews" component={CreateNewsComponent} />
                                                            <Route path="/dashboard/updateNews/:news_id" component={EditNews} />
                                                            <Route path="/dashboard/news" component={NewsList} />
                                                            <Route exact path='/dashboard/subscription/:video_id/videos' component={videolist}></Route>

                                                            {/* <Route path="/dashboard/tags" component={TagIndex} /> */}


                                                            <Route component={PageNotFound} />
                                                        </Switch>
                                                        :
                                                        tokenData.role === 'reseller' ?
                                                            <Switch>
                                                                <Redirect exact from="/dashboard" to="/dashboard/overview" />
                                                                <Route exact path="/dashboard/overview" component={ResellerHome} />
                                                                <Route exact path="/dashboard/transactions" component={TranasctionsList} />

                                                                <Route exact path="/dashboard/createStudent" component={CreateStudent} />
                                                                <Route exact path="/dashboard/studentList" component={Studentlist} />
                                                                <Route path='/dashboard/studentdetails/:student_id' component={Singlestudent}></Route>
                                                                <Route path='/dashboard/editStudent/:student_id' component={EditStudent}></Route>
                                                                <Route path='/dashboard/createschool' component={CreateSchool}></Route>
                                                                <Route exact path='/dashboard/schools' component={Schoollist}></Route>
                                                                <Route exact path='/dashboard/schools/:school_id' component={SchoolDetails}></Route>

                                                                <Route exact path='/dashboard/sales' component={SalesList}></Route>
                                                                <Route exact path="/dashboard/my-account" component={MyAccount} />

                                                                <Route component={PageNotFound} />

                                                            </Switch>
                                                            : tokenData.role === 'school' ?
                                                                <Switch>
                                                                    <Redirect exact from="/dashboard" to="/dashboard/overview" />
                                                                    <Route exact path="/dashboard/overview" component={SchoolHome} />
                                                                    <Route exact path="/dashboard/transactions" component={TranasctionsListSchool} />
                                                                    <Route exact path='/dashboard/sales' component={SalesListSchool}></Route>
                                                                    <Route exact path="/dashboard/my-account" component={MyAccount} />
                                                                    <Route component={PageNotFound} />

                                                                </Switch> : null
                                    }


                                </CSSTransitionGroup>


                            </div>
                        </div>
                    </div>
                    {tokenData.emailVerified === false ?
                        <Modals
                            open={true}
                            title={`Email verification`}
                        >
                            <Row>
                                <Col md={12}>
                                    <p style={{ color: '#777', fontSize: '16px', fontWeight: '400', lineHeight: '22px' }}>
                                        Thanks for getting started with Us! <br /><br />

                                        We need a little more information to complete your <br />
                                        registration, including a confirmation of your email address. <br /><br />

                                        Click below to verify your email address
                                    </p>
                                </Col>
                                <Col md={4}></Col>
                                <Col md={5} style={{ textAlign: 'right' }}>
                                    {getComponent(storeData.resentMailLoader)}
                                </Col>
                                <Col md={3}>
                                    <Button variant="danger" style={{ fontSize: '12px', padding: '5px 18px' }} className="btn-shadow" onClick={() => logOut()}>{storeData.resentMailLoader === 'sent' ? 'Done' : 'Cancel'}</Button>
                                </Col>
                                <Col md="12">{storeData.resentMailLoader === 'sent' ? <p style={{ color: "green" }}><FontAwesomeIcon color="green" icon={faCheckCircle} />{`  `}A verification link has been sent to your email account,Please Verify.   </p> : null}</Col>
                            </Row>
                        </Modals> : null
                    }</>
                    : null
            }
            <Modals
                open={newUpdates}
                handleClose={closeNewUpdates}

            >
                <Updates
                    data={storeData.whatsnew}
                />
            </Modals>
            <Modals
                open={authData.payment ? authData.payment.payment_alert : false}
                size="xl"
            >
                <Plans
                    data={authData.payment}
                    changePlan={changePlan}
                />
            </Modals>

        </AuthCheck>
    )

}
export default AnalyticsDashboard
