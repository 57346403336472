import React, { useState, useEffect } from 'react'
import { addStudentToClassroom, addStudentToEnterpriseClassroom } from '../../../redux/Actions/homeTeacher'
import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import Swal from 'sweetalert2';
import NoTimeSlot from './notimeslot.png'
import {
    Row, Col,
    Card, CardBody,
    CardHeader, CardFooter, 
    
    FormGroup, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Loader from 'react-loaders'

const AddStudentToClass = (props) => {
    // const { selectedStudents, setSelectedStudents, data } = props;
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    
    useEffect(() => {

        return () => {
            dispatch({ type: 'RESET_ASSIGN_STUDENT_CLASS' })
            dispatch({ type: 'RESET_GROUP_LIST' })

        }
    }, [])
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();



    
    
    const handleChange = (user_id) => {
        // alert(selectedStudents.length)
        dispatch({ type: 'CHANGE_ASSIGN_STUDENT_TO_CLASS' })
        
        // if (selectedStudents.includes(user_id)) {
        //     const filtered = selectedStudents.filter(userId => userId != user_id)
        //     console.log("after removed", filtered)
        //     setSelectedStudents(filtered)
        // }
        // // else if (selectedStudents.length < tokenData.number_of_student) {

        // //     setSelectedStudents([...selectedStudents, user_id])

        // //     dispatch({ type: 'CHANGE_ASSIGN_STUDENT_TO_CLASS' })
        // // } 
        // else {
        //     setSelectedStudents([...selectedStudents, user_id])

        //     dispatch({ type: 'CHANGE_ASSIGN_STUDENT_TO_CLASS' })

        //     // Swal.fire({
        //     //     icon: 'error',
        //     //     title: 'Limit exceeded',
        //     //     text: `Maximum number of student is ${tokenData.number_of_student}`,

        //     // })

        // }

    }
    console.log("props data",props.data)
    console.log("props data true",props.data.includes( props.data.selected))

    // tStore.specificGroup.map( (group) => {
    //     if(group.selected){
    //         console.log("selected true")
    //     }
    // } )
const [GroupsSelected, setGroupsSelected] = useState(true);

    // if(tStore.specificGroup){
    //     const result = tStore.specificGroup.filter(group => group.selected);
    //     if(result){
    //         setGroupsSelected(true)
    //     }
    // }

    const handleClick = (e) => {
        e.preventDefault();
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
  
        if (tokenData.enterpriseUser) {

            dispatch(addStudentToEnterpriseClassroom(props.classId, body, tokenData.token, props.handleCloseStudentModal, props.timeData))


        } else {
            dispatch(addStudentToClassroom(props.classId, body, tokenData.token, props.handleCloseStudentModal, props.timeData))

        }
    };
    return (
        <Form onSubmit={handleClick}>
            <input name="class_id" type="hidden" value={props.classId} />
            {/* <input name="teacher_id" type="hidden" value={props.data? props.data[0]? props.data[0].teacher_} /> */}
            <input name="teacher_id" type="hidden" value={props.data[0] ? props.data[0].teacher_id : null} />
            { GroupsSelected ?
            <Card className="main-card mb-3">
                <CardHeader>
                    <Col md={5} sm={5}>
                        {tokenData.enterpriseUser ? "Groups" : "Student"}

                    </Col>

                    <Col md={7} sm={7}>
                        {tokenData.enterpriseUser ? null : "Phone"}

                    </Col>
                </CardHeader>
                <CardBody>
              
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>

                            {/* <input type="hidden" name="timing" value={props.timing} /> */}
                            {
                                tStore.specificStudentLoader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {
                                props.loader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }
                            {props.data ? props.data.map(dt => (


                                <Row key={dt.user_id ? dt.user_id : dt.group_id}>
                                    <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                        {dt.user_id ? dt.first_name : dt.group_name} {dt.user_id ? dt.last_name : null}
                                    </Col>
                                    {
                                        !tokenData.enterpriseUser ?
                                            <Col md={5} sm={5} style={{ paddingLeft: '20px' }}>
                                                {`${dt.user_id ? dt.mobile : null}`}
                                            </Col> : null
                                    }

                                    <Col md={2} sm={2} >
                                        <FormGroup>
                                            <div>
                                                <input type="checkbox" id="exampleCustomCheckbox"
                                                    name={dt.user_id ? "registered_student[]" : "group_assign_id[]"}
                                                    defaultChecked={dt.selected}
                                                    // checked={selectedStudents.includes(dt.user_id) ? true : false}

                                                    // onChange={() => handleChange(dt.user_id)}

                                                    // value={dt.user_id ? dt.user_id : dt.group_id}



                                                    // checked={selectedStudents.includes(dt.user_id ? dt.user_id : dt.group_id) ? true : false}
                                                    onChange={() => handleChange(dt.user_id ? dt.user_id : dt.group_id)}
                                                    value={dt.user_id ? dt.user_id : dt.group_id}
                                                />

                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            )) : null}

                        </PerfectScrollbar>
                    </div>
                
              </CardBody>
                <CardFooter className="d-block text-right">
                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        loading={tStore.assignStudentLoader}
                        // style={{ width: '-webkit-fill-available' }}

                        type="button"
                        data-style={ZOOM_IN}
                        type="submit"
                    >
                        Save
                                            </LaddaButton>
                    {/* <Button type="submit" size="lg" color="success">Save</Button> */}
                </CardFooter>
            </Card>
            : <>
                 <img src={NoTimeSlot} alt='no time slot'></img>  
             </>
            }
        </Form>
    )
}

export default AddStudentToClass
