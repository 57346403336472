import React,{useEffect} from 'react';
import PageTitle from '../../common/HeaderTitle/PageTitle'
import {
    Row, Col, Container,Badge,Button
  } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Table from "./table"

import { useHistory } from "react-router-dom";

import Tooltip from '@material-ui/core/Tooltip';
import Model from "../../common/Modal/index"

import { setReqStudentData,getTicketReason,getAllStudentTickets } from "../../../redux/Actions/Student"
import { useDispatch, useSelector } from "react-redux";
import Createticket from "./Createticket"



function StudentTicketlist(){



    const dispatch = useDispatch();
    const history=useHistory()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const Openticket = useSelector((state) =>state.student.Openticket ? state.student.Openticket : false);
    const ticketarray = useSelector((state) =>state.student.ticketarray ? state.student.ticketarray : []);
    const ticket_student_loader = useSelector((state) =>state.student.ticket_student_loader ? state.student.ticket_student_loader : '');

    
    useEffect(()=>{
        dispatch(getAllStudentTickets(tokenData.token))
    },[])
    const columns=[
        { title: 'Ticket Id', field: 'ticket_id' },
        { title: 'Ttile', field: 'title' },
        { title: 'Issued_Date', field: 'issued_date' },
        { title: 'Status', field: 'status',
        render: (rowData) => {
            return rowData.status === 'Pending' ? (
              <Badge color="danger" pill>
                {"Pending"}
              </Badge>
            ) : rowData.status === 'Inprogress' ? (
              <Badge color="warning" pill>
                {"Inprogress"}
              </Badge>
            ) : (
              <Badge color="success" pill>
                {"Resolved"}
              </Badge>
            );
          },
        }
        
    ]    
        
  

    const action='student_ticket'

    const Ticketview=(val)=>{
        history.push('/dashboard/ticketdetails/' + val.ticket_id)
    }
    const Addticket=()=>{
        dispatch(getTicketReason(tokenData.token))
        dispatch(setReqStudentData('Openticket',true))
    }
    const Closemodel=()=>{
        dispatch(setReqStudentData('Openticket',false))
    }
    return(
        <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
            >
                <Container>
                    <Row>
                        <Col xs={9} sm={8}>
                        <PageTitle
                            heading="Tickets"
                            subheading=""
                            icon="pe-7s-ticket icon-gradient bg-happy-itmeo"
                        />
                        </Col>
                        <Col xs={3} sm={4} style={{textAlign:'right',}}>
                            <Tooltip title="Add New Ticket" aria-label="add">
                                <Button className="mb-2 mr-2 btn-icon" color="primary"  onClick={()=>{Addticket()}}>
                                    <i className="pe-7s-ticket btn-icon-wrapper"> </i>
                                        Add Ticket
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Table
                                loader={ticket_student_loader}
                                actions={action}
                                data={ticketarray}
                                title={'Tickets'}
                                columns={columns}
                                Ticketdetails={Ticketview}
                            /> 
                        </Col>
                    </Row>
                </Container>
            
                <Model
                    open={Openticket}
                    handleClose={Closemodel}
                    title='Create a Ticket'
                >
                    <Createticket />
                </Model>

        </CSSTransitionGroup>
    )
}

export default StudentTicketlist