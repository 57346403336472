import React,{useEffect} from 'react';
import {
    Col,
    Card,Input,
    Row,Container,CardFooter
} from 'reactstrap';
// import Tooltip from '@material-ui/core/Tooltip';
// import cx from 'classnames';
// import { Elastic } from 'react-burgers';
import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
// import avatar2 from '../../../assets/utils/images/avatars/2.jpg';
import {
    // faCog,
    faCalendarAlt,
    // faSearch,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import {
    faHome
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import cx from 'classnames';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';

import PerfectScrollbar from 'react-perfect-scrollbar';
import SendIcon from '@material-ui/icons/Send';

import { getSingleTicket,sendTicketMessage,setReqStudentData } from "../../../redux/Actions/Student"
import { useDispatch,useSelector } from "react-redux";
import { useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


function StudentTicketDetails(){

    const dispatch=useDispatch()
    const params=useParams()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const [message,setMessage]=useState('')
    const ticket_details = useSelector((state) =>state.student.ticket_details ? state.student.ticket_details : '');
    const message = useSelector((state) =>state.student.message ? state.student.message : '');
    const Chatdata = useSelector((state) =>state.student.Chatdata ? state.student.Chatdata : []);

    useEffect(()=>{
        dispatch(getSingleTicket(tokenData.token,params.ticket_id))
        return(()=>{
            dispatch(setReqStudentData('ticket_details',''))
            dispatch(setReqStudentData('message',''))
            dispatch(setReqStudentData('Chatdata',''))

        })
    },[])

    const Handlemessage=(e)=>{
        dispatch(setReqStudentData('message',e.target.value))
    }
    const SendMessage=()=>{
        dispatch(sendTicketMessage(tokenData.token,params.ticket_id,message,reset))
    }

    const reset=()=>{
        dispatch(setReqStudentData('message',''))
        dispatch(getSingleTicket(tokenData.token,params.ticket_id))

    }
    return(
        <Container>
            <Row>
                <Col xs='12'>
                <Breadcrumb style={{width:'100%'}}>
                    <BreadcrumbItem>
                    <Link to="/dashboard">
                        <FontAwesomeIcon icon={faHome} />
                    </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                    <Link to="/dashboard/tickets">Ticket List</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Details</BreadcrumbItem>
                </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white",display:'flex',alignItems:'center' }}>
       
                    <div
                        className={cx("page-title-icon")}>
                        <i className='pe-7s-ticket icon-gradient bg-sunny-morning' />
                    </div>
                    <div>
                        <span style={{ textTransform: 'uppercase' }}>{ticket_details.order_id}</span> 
                        <br />
                    <div
                    style={{
                        color: "#99DDFF",
                        fontWeight: 600,
                        textTransform: "capitalize",
                    }}
                    className={cx("page-title-subheading")}
                    >
                    {ticket_details.status}
                    </div>
                    </div>

                </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12} style={{marginTop:'5px'}}>
                    <Card>
                        <CardHeader>
                            Details
                        </CardHeader>
                        <CardBody>
                            <Row>
                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Order Id"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.order_id}
                                                     
                            </Col>

                        
                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Issued Date"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.issued_date}
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Status"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.status}
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Title"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.title}
                            </Col>
                            {
                                ticket_details.course_name ? 
                                <>
                                <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Course Name"}
                                </Col>
                                <Col md={7} className="assign-details-card-div">
                                    {" "}{ticket_details.course_name}
                                </Col>
                                </>:''
                            }
                            

                            </Row>
                        </CardBody>
                    </Card>
                </Col>

            </Row>

        {
                Chatdata.length > 0 ? 
                <Row style={{marginTop:'10px'}}>
                <Col xs={12}>
                    <Card className="main-card mb-3">
                        <CardHeader>Chat Box</CardHeader>
                        
                        <div className="scroll-area-sm" style={{minHeight:'400px'}}>
                            <PerfectScrollbar>

                            
                                    <div className="chat-wrapper p-1">
                                    <Grid container>

                                    {
                                        Chatdata.map((val,ky)=>{
                                            if(val.type==='right'){
                                                return(
                                                    <Grid item xs={12}>
                                                    <div className="float-right">
                                                        <div className="chat-box-wrapper chat-box-wrapper-right">
                                                            <div style={{display:'block'}}>
                                                                <div className="chat-box" style={{display:'flex',justifyContent:'flex-end'}}>
                                                                    <div>
                                                                        {val.message}
                                                                    </div>
                                                                </div>
                                                                <small className="opacity-6">
                                                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                                    {val.created_at}
                                                                </small>
                                                            </div>
                                                            <div>
                                                                <div className="avatar-icon-wrapper ml-1">
    
                                                                    <div className="avatar-icon avatar-icon-lg rounded">
                                                                        <img src={avatar1} alt=""/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                )
                                            }
                                            else{
                                                return(
                                                    <div className="chat-box-wrapper">
                                                    <div>
                                                        <div className="avatar-icon-wrapper mr-1">
                                                
                                                            <div className="avatar-icon avatar-icon-lg rounded">
                                                                <img src={avatar1} alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="chat-box">
                                                            {val.message} 
                                                        </div>
                                                        <small className="opacity-6">
                                                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                            {val.created_at}
                                                        </small>
                                                    </div>
                                                </div>
                                                )
                                            }
                                            
                                        })
                                    }
                                    
                                    </Grid>
                                </div>
                            </PerfectScrollbar>
                        </div>
                        <CardFooter>
                            <Input type="text" placeholder="Type a message..." onChange={Handlemessage}  value={message ? message:''}/>
                            <Button  onClick={SendMessage} disabled={message ? false:true}><SendIcon fontSize='large' style={{color:'#C0C0C0',cursor:'pointer'}}/></Button>

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            :
            ''
        }
            

        </Container>
    )
}
export default StudentTicketDetails




                                    // <div className="chat-box-wrapper">
                                    //     <div>
                                    //         <div className="avatar-icon-wrapper mr-1">
                                    
                                    //             <div className="avatar-icon avatar-icon-lg rounded">
                                    //                 <img src={avatar1} alt=""/>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    //     <div>
                                    //         <div className="chat-box">
                                    //             But I must explain to you how all this mistaken idea of
                                    //             denouncing pleasure and praising pain was born and I will
                                    //             give you a complete account of the system.
                                    //         </div>
                                    //         <small className="opacity-6">
                                    //             <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                    //             11:01 AM | Yesterday
                                    //         </small>
                                    //     </div>
                                    // </div>
                                    // <div className="float-right">
                                    //     <div className="chat-box-wrapper chat-box-wrapper-right">
                                    //         <div>
                                    //             <div className="chat-box">
                                    //                 Expound the actual teachings.
                                    //             </div>
                                    //             <small className="opacity-6">
                                    //                 <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                    //                 11:01 AM | Yesterday
                                    //             </small>
                                    //         </div>
                                    //         <div>
                                    //             <div className="avatar-icon-wrapper ml-1">

                                    //                 <div className="avatar-icon avatar-icon-lg rounded">
                                    //                     <img src={avatar1} alt=""/>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                    //     </div>
                                    // </div>