import React, { useState, useEffect } from 'react'
import PageTitle from '../../common/HeaderTitle/PageTitle'
import { Card, CardHeader, Row, Col, Input, FormFeedback } from 'reactstrap'
// import Dropzone from 'react-dropzone'
// import { FileCopy, Attachment, Clear } from '@material-ui/icons'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { populateMyCourse, uploadStudyMaterial, getStudyMaterialNotes, downloadStudyMaterial } from '../../../redux/Actions/homeTeacher'
import { populateClassForCourse } from '../../../redux/Actions/vendor_course'
import { useDispatch, useSelector } from 'react-redux'
// import DataTable from '../../common/DataTable'
// import { useHistory } from 'react-router-dom'
import { requiredValidator } from "../../../utilities/Validator";
import Swal from 'sweetalert2'
import { EditorState, convertToRaw, } from "draft-js";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Table from '../Table'
import { filterBatchByClass } from '../../../redux/Actions/assignment'

function StudyMaterials() {

    // const history = useHistory()

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const homeTeacher = useSelector(({ homeTeacher }) => homeTeacher)
    const course = useSelector(({ course }) => course)



    const [InputField, setInputField] = useState({
        class_id: '',
        title: '',
        course_id: ''
    });
    const [Files, setFiles] = useState([]);
    const [loader, setLoader] = useState(false);

    const assignment = useSelector(({ assignments }) => assignments)


    useEffect(() => {
       
        dispatch(populateMyCourse(tokenData.token))
        dispatch(getStudyMaterialNotes(tokenData.token))
        return()=>{
            dispatch({type:'FILTER_BATCH_BY_CLASS_RESET'})
        }
    }, [])

    const deletecb = () => {
        dispatch(getStudyMaterialNotes(tokenData.token))
        setLoader(false)
    }

    // const onDrop = (e) => {
    //     console.log("log files", e)
    //     //  setFiles(e.target.files)
    // }
    const formData = new FormData();
    const handleDelete = (i) => {
        let arr = [...Files]
        arr.splice(i, 1)
        setFiles(arr)
    }


    const handleDownload = (rowData) => {
        // var link = document.createElement("a");
        //     link.href = url;
        //     link.target = "_blank"
        //     link.click();
        //     link.remove();
        // console.log("row data",rowData)
        dispatch(downloadStudyMaterial(tokenData.token, rowData.study_material_id, rowData.class_id))
    }

    const [Errors, setErrors] = useState({})
    const [EState, setEState] = useState(EditorState.createEmpty())

    const formSubmit = () => {
        let err = {}

        err = {
            class_id: requiredValidator(InputField.class_id),
            title: requiredValidator(InputField.title),
            //  description: requiredValidator(InputField.description),
            course_id: requiredValidator(InputField.course_id),
            group_id:requiredValidator(InputField.group_id),

        };
        let qn = draftToHtml(convertToRaw(EState.getCurrentContent()))

        formData.append('class_id', InputField.class_id)
        formData.append('title', InputField.title)
        formData.append('description', qn)
        formData.append('type', 'study_notes')
        formData.append('batch_id', InputField.group_id)

        if (!err.class_id && !err.title && !err.description && !err.group_id) {
            setNotesLoader(true)
            dispatch(uploadStudyMaterial(tokenData.token, formData, reset))
        } else {
            setErrors(err)
        }

    }

    const reset = () => {
        setNotesLoader(false)
        setErrors({})
        // setFiles([])
        setInputField({
            title: '',
            course_id: '',
            class_id: '',
            // description: null
        })
        dispatch(getStudyMaterialNotes(tokenData.token))
        setEState(EditorState.createEmpty())
        Swal.fire({
            icon: 'success',
            text: 'Study Note Created',
            // title: 'Token Expired'
        })
    }

    const handleChangeCourse = e => {
        const { name, value } = e.target;
        setInputField({ ...InputField, 'course_id': value,group_id:'',class_id:'' })
        // setInputfield({ ...inputField, [name]: value })

        dispatch(populateClassForCourse(tokenData.token, value))

    };

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        
        if(name==='class_id'){
            setInputField({ ...InputField, [name]: value,group_id:''  })
            dispatch(filterBatchByClass(tokenData.token, value))
        }else{
            setInputField({ ...InputField, [name]: value })
        }
    }



    const onEditorStateChange = (e) => {
        setEState(e)
    }

    const [NotesLoader, setNotesLoader] = useState(false)

    return (
        <div>
            <PageTitle
                heading="Study Notes"
                subheading="Write Study Notes"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />
            <Card className='mb-3' >
                <CardHeader>Write Notes</CardHeader>
                <Row className='p-3' >
                    <Col sm={6} >
                        <label>Title</label>
                        <Input value={InputField.title} invalid={Errors.title} name='title' onChange={handleChangeInput} ></Input>
                        <FormFeedback>{Errors.title}</FormFeedback>
                    </Col>
                    <Col sm={6} >
                        <label>Course</label>
                        <Input value={InputField.course_id} invalid={Errors.course_id} name='course_id' onChange={handleChangeCourse} type='select' >
                            <option>Select</option>
                            {
                                homeTeacher.TeachersCourse.map(
                                    (course) => <option value={course.course_id} > {course.course_name} </option>)
                            }
                        </Input>
                        <FormFeedback>{Errors.course_id}</FormFeedback>
                    </Col>
                    <Col sm={6} >
                        <label>Class</label>
                        <Input value={InputField.class_id} invalid={Errors.class_id} name='class_id' onChange={handleChangeInput} type='select' >
                            <option>Select</option>
                            {
                                course.classesOfCourse ? course.classesOfCourse.map(
                                    (cl) => <option value={cl.class_id} > {cl.class_name} </option>)
                                    :''
                            }
                        </Input>
                        <FormFeedback>{Errors.class_id}</FormFeedback>
                    </Col>

                    <Col sm={6} className="assign-form-grid">
                      <label for="exampleNameF">
                        Group
                      </label>
                      <div class="form-group">
                        <div class="input-group">
                          <Input type="select"
                            native
                            labelId="demo-simple-select-outlined-label"
                            onChange={handleChangeInput}
                            id="group_id"
                            name='group_id'
                            label="Grade"
                            invalid={Errors.group_id}
                            value={InputField.group_id}
                          >

                            <option value={''}>{'Select Batch'}</option>
                            {assignment.batchesinClass ?
                             assignment.batchesinClass.map((b,k) => {
                                return (
                                  <option key={k} value={b.group_id}>{b.group_name}</option>
                                )
                              }) : null
                            }

                          </Input>

                          {
                            assignment.batchesinClassLoader ?
                            <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>
                              :
                              null
                          }
                        </div>
                        <FormFeedback>{Errors.group_id}</FormFeedback>

                      </div>
                    </Col>

                    {/* <Col sm={12} >
                        <label>Description</label>
                        <Input value={InputField.description} invalid={Errors.description} name='description' onChange={handleChangeInput} type='textarea' >
                        </Input>
                        <FormFeedback>{Errors.description}</FormFeedback>
                    </Col> */}

                    <Col style={{ marginTop: '10px' }} sm={12} >
                        <Editor
                            stripPastedStyles={true}
                            handlePastedText={() => false}
                            editorStyle={{ border: "1px solid #ced4da", borderRadius: '0.25rem', margin: '10px', padding: '5px', height: '500px' }}
                            editorState={EState}
                            onEditorStateChange={(e) => { onEditorStateChange(e) }}
                            // toolbar={{
                            //     inline: { inDropdown: true },
                            //     list: { inDropdown: true },
                            //     textAlign: { inDropdown: true },
                            //     link: { inDropdown: true },
                            //     history: { inDropdown: true },
                            //     image: { inDropdown: false }
                            //     // image: {
                            //     //      uploadCallback: uploadImageCallBack,
                            //     //     alt: { present: false, mandatory: false }, previewImage: true
                            //     // },
                            // }}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                fontFamily: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: { inDropdown: false },
                                options: ['inline', 'link', 'history', 'fontSize', 'fontFamily', 'list']
                            }}
                        />
                    </Col>


                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} >
                        <LaddaButton className="mt-2 mr-4 pr-5 pl-5 btn btn-danger"
                            loading={NotesLoader}
                            onClick={formSubmit}
                            type="submit"
                            data-style={ZOOM_IN}
                            

                        >
                            Create
                        </LaddaButton>
                    </div>

                </Row>
            </Card>

            <Table
                action='study_notes_teacher'
                deletecb={deletecb}
                setLoader={() => { setLoader(true) }}
                loading={loader}
            />

        </div>
    )
}

export default StudyMaterials
