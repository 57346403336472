import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";

import CreateSchoolClass from "./school";
import CreateCollegeClass from "./college";
import CreateLifeLongClass from "./lifelong";
import classnames from "classnames";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import {setReqData,listAllTagsByFilter} from "../../../redux/Actions/Superadmin"

// import VendorCouselist from "../Viewcourse/Viewcourselist"

const Index = () => {
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const [state, setState] = useState({
    activeTab: "3",
    showMore: true,
    transform: true,
    showInkBar: true,

    selectedTabKey: 0,
    transformWidth: 400,
  });

  useEffect(()=>{
    dispatch(listAllTagsByFilter(tokenData.token,3))
  },[])

  const toggle = (tab) => {
    dispatch({ type: "RESET_SUBJECT" });
    if (state.activeTab !== tab) {
      let blist=[]
      let data={}
      data.batch_name=''
      data.total_seat=''
      blist.push(data)
      dispatch(setReqData('BatchList',blist))

      let slist = []
      let data2 = {}
      data2.subject_name = ''
      slist.push(data2)
      dispatch(setReqData('SubList', slist))

      if(tab==='1'){
        dispatch(listAllTagsByFilter(tokenData.token,1))
      }
      else if(tab==='2'){
        dispatch(listAllTagsByFilter(tokenData.token,2))
      }
      else{
        dispatch(listAllTagsByFilter(tokenData.token,3))
      }
      setState({
        activeTab: tab,
      });
    }
  };
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          style={{ display: "none" }}
          heading="Create Course"
          subheading="Create course"
          icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardHeader>
            {/* <Nav justified>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  School
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  College/University
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Institute
                </NavLink>
              </NavItem>
            </Nav> */}
            Create Course
          </CardHeader>
          <CardBody>
            {/* <TabContent activeTab={state.activeTab}>
              <TabPane tabId="1">
                <CreateSchoolClass /> 
              </TabPane>
              <TabPane tabId="2">
                <CreateCollegeClass />
              </TabPane>
              <TabPane tabId="3">
                <CreateLifeLongClass />
              </TabPane>
            </TabContent> */}
            <CreateLifeLongClass />
          </CardBody>
        </Card>

      </CSSTransitionGroup>
    </>
  );
};

export default Index;
