import React, { Fragment, useState, Suspense } from "react";

import styles from "./vendor.module.css"
import Grid from "@material-ui/core/Grid";
import ShareIcon from "@material-ui/icons/Share";
import LanguageIcon from "@material-ui/icons/Language";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";


import OwlCarousel from "react-owl-carousel";
import ReactHtmlParser from "react-html-parser";
import PersonIcon from "@material-ui/icons/Person";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import ReactPlayer from "react-player";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Divider } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

import ShareOptions from "./ShareOptions";
import SocialmediaComponent from "./Socialmedia"
import Slider from "react-slick";
import noAbout from "../../images/no-about.png"
import noAchievements from "../../images/no-achievements.png"


const SliderComponent = React.lazy(() => import('./slider'));

const options = {
    responsiveClass: true,
    nav: true,
    margin: 10,
    dots: false,
    autoplay: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,
        },
    },
};

const options1 = {
    responsiveClass: true,
    nav: false,
    margin: 10,
    dots: true,
    autoplay: false,
};

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
}
export default function NewSIngleVendor(props) {
    const {
        InstituteName,
        LogoPath,
        SocialMedia,
        FullDetails,
        ImageGallery,
        Achievement,
        Faculty
    } = props;

    const location = window.location.href;

    const [tab, setTab] = useState(1);
    const [showimage, setImage] = useState(1);
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState({ open: false, data: [] });
    const [video, setVideo] = useState({ open: false, url: '' });

    const ShowGallery = (val) => {
        setImages({ open: true, data: val })
    }

    const OpenVideo = (val) => {
        setVideo({ open: true, url: val.AchievementUploadPath })
    }

    return (
        <Fragment>
            <section
                className={styles.section_vendor_wrap}
                style={{ backgroundColor: "#ffffff" }}
            >
                <div class="container">
                    <div class="row mt-1">
                        <Grid container spacing={1}>
                            <Grid item md={3} sm={12} xs={12}>
                                <div className={styles.vendor_logo}>
                                    {
                                        showimage === 1 ?
                                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                                                <img src={LogoPath} height='100%' width='90%' alt='' />
                                            </div>
                                            :
                                            <div className='player-wrapper'>
                                                <ReactPlayer
                                                    className='react-player'
                                                    url={FullDetails.ProfileVideoPath}
                                                    width='100%'
                                                    height='100%'
                                                    controls={true}
                                                />
                                            </div>
                                    }
                                </div>
                                <div className={styles.vendornew_details_sectionone}>
                                    <div style={{ borderRadius: '11px', width: '100%', height: '33px', display: 'flex' }}>
                                        <div className={showimage === 1 ? styles.sectionone_one : styles.sectionone_one_2} onClick={() => { setImage(1) }}>
                                            <PersonIcon fontSize='small' />
                                        </div>
                                        <div className={showimage === 2 ? styles.sectionone_one : styles.sectionone_one_2} onClick={() => { setImage(2) }}>
                                            <PlayArrowIcon fontSize='small' />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={9} sm={12} xs={12}>
                                <div className={styles.vendor_headname_warper}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span className={styles.newvendor_name}>{InstituteName}</span>
                                        <span>
                                            <ShareIcon style={{ cursor: 'pointer' }} onClick={() => { setOpen(true) }} />
                                        </span>

                                    </div>
                                    <div className={styles.newvendor_social}>
                                        <div style={{ marginLeft: '5px' }}>
                                            <span style={{ marginRight: '3px' }}><LanguageIcon style={{ color: '#5DBBC0' }} fontSize='small' /></span>
                                            <span className={styles.socila_links}><a target='_blank' href={FullDetails.Website} >{FullDetails.Website} </a></span>
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            <span style={{ marginRight: '3px' }}><LocationOnIcon style={{ color: '#5DBBC0' }} fontSize='small' /></span>
                                            <span className={styles.socila_links}>{FullDetails.Address}</span>
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            <span style={{ marginRight: '3px' }}><EmailIcon style={{ color: '#5DBBC0' }} fontSize='small' /></span>
                                            <span className={styles.socila_links}>{FullDetails.EmailId}</span>
                                        </div>
                                        <div style={{ marginLeft: '5px' }}>
                                            <span style={{ marginRight: '3px' }}><PhoneIcon style={{ color: '#5DBBC0' }} fontSize='small' /></span>
                                            <span className={styles.socila_links}>{FullDetails.MobileNumber}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.sectiontwo_one}>
                                    <div
                                        className={tab === 1 ? styles.sectiontwo_one_tabs_active : styles.sectiontwo_one_tabs}
                                        onClick={() => { setTab(1) }}
                                    >
                                        ABOUT
                                    </div>
                                    <div
                                        className={tab === 2 ? styles.sectiontwo_one_tabs_active : styles.sectiontwo_one_tabs}
                                        onClick={() => { setTab(2) }}
                                    >
                                        FACULTY
                                    </div>
                                    <div
                                        className={tab === 3 ? styles.sectiontwo_one_tabs_active : styles.sectiontwo_one_tabs}
                                        onClick={() => { setTab(3) }}
                                    >
                                        ACHIEVEMENTS
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        {
                            tab === 1 ?
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={3}>
                                        {
                                            ImageGallery.length > 0 ?
                                                <div className={styles.cusrosel_respons} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <div className={styles.vendornew_details_sectiontwo}>
                                                        <OwlCarousel
                                                            id="feature-slide"
                                                            className="owl-carousel owl-theme"
                                                            {...options}
                                                        >
                                                            {
                                                                ImageGallery.map((val, ky) => {
                                                                    return (
                                                                        <div key={ky} style={{ width: '100%', height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <div key={ky} style={{ width: '90%', height: '120px' }}>
                                                                                <img src={val.GalleryImagePath} alt='' width='100%' height='100%' />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </OwlCarousel>
                                                    </div>
                                                </div>
                                                :
                                                <div></div>
                                        }

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9}>
                                        <div style={{ width: '100%', wordWrap: 'break-word', marginTop: '40px' }}>
                                            {
                                                FullDetails.ProfileDescription ?
                                                    <p className={styles.sectiontwo_two_p}>
                                                        {ReactHtmlParser(FullDetails.ProfileDescription)}
                                                    </p>
                                                    :
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <img src={noAbout} width='400px' height='250px' alt='' />
                                                    </div>
                                            }

                                        </div>
                                    </Grid>


                                    <Grid item md={3} xs={12}>
                                        <SocialmediaComponent SocialMedia={SocialMedia} />
                                    </Grid>

                                </Grid>
                                :
                                tab === 2 ?
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <SliderComponent Faculty={Faculty} />
                                            </Suspense>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container>
                                        {
                                            Achievement.length > 0 ?
                                                <Grid item xs={12}>
                                                    {
                                                        Achievement.map((val, ky) => {
                                                            return (
                                                                <div className={styles.achievements} key={ky}>
                                                                    <Grid container>
                                                                        <Grid item xs={12} sm={12} md={3}>
                                                                            <div className={styles.achievements_curosel}>
                                                                                <div style={{ width: '165px', height: '110px' }}>
                                                                                    {
                                                                                        val.AchievementVideos ? val.AchievementVideos.length > 0 ?
                                                                                            <Slider {...settings}>
                                                                                                {
                                                                                                    val.AchievementVideos.map((value, ky2) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div key={ky2} style={{ width: '165px', height: '100px', }} onClick={() => { OpenVideo(value) }}>
                                                                                                                    <img src={value.AchievementThmbnailPath} alt='' width='100%' height='100%' style={{ cursor: 'pointer' }} />
                                                                                                                </div>
                                                                                                                <div style={{ textAlign: 'center' }}>
                                                                                                                    {value.AchievementUploadName}
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )

                                                                                                    })
                                                                                                }
                                                                                            </Slider>
                                                                                            :
                                                                                            <div style={{ width: '165px', height: '100px', textAlign: 'center' }}>No videos</div>
                                                                                            :
                                                                                            <div style={{ width: '165px', height: '100px', textAlign: 'center' }}>No videos</div>

                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={9}>
                                                                            <div className={styles.achievements_details}>
                                                                                <span className={styles.achievements_head}>
                                                                                    {val.AchievementTitle}
                                                                                </span>

                                                                                <p className={styles.achievements_p}>
                                                                                    {val.AchievementDescription}
                                                                                </p>

                                                                                <div style={{ width: '100%', textAlign: 'right' }}>
                                                                                    {
                                                                                        val.AchievementImages ? val.AchievementImages.length > 0 ?
                                                                                            <span className={styles.achievements_galll} onClick={() => { ShowGallery(val.AchievementImages) }}>
                                                                                                View Gallery
                                                                                            </span>
                                                                                            :
                                                                                            ''
                                                                                            :
                                                                                            ''
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </Grid>
                                                :
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    <img src={noAchievements} width='600px' height='400px' alt='' />
                                                </div>
                                        }

                                    </Grid>
                        }


                    </div>
                </div>
            </section>

            {/* ///////////url/////////// */}
            <Dialog
                fullWidth={false}
                maxWidth={"sm"}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ padding: "9px 0px" }}>
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} style={{ textAlign: "center" }}>
                            <span>Share Url</span>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                            <CloseIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <ShareOptions location={location} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            {/* ///////////image//////////// */}
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={images.open}
                onClose={() => {
                    setImages({ open: false, data: [] });
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ padding: "9px 0px" }}>
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} style={{ textAlign: "center" }}>
                            <span>Image Gallery</span>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                            <CloseIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setImages({ open: false, data: [] });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: '6px' }}>
                            <Divider />
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent>
                    <div style={{ width: '100%', height: '240px' }}>
                        <Grid container>
                            <Grid item xs={1} sm={4}></Grid>
                            <Grid item xs={10} sm={4}>
                                <OwlCarousel
                                    id="feature-slide"
                                    className="owl-carousel owl-theme"
                                    {...options1}
                                >
                                    {
                                        images.data.map((val, ky) => {
                                            return (
                                                <div key={ky} style={{ height: 'auto', width: '220px' }}>
                                                    <img src={val.AchievementUploadPath} alt='' height='210px' width='100%' />
                                                </div>
                                            )
                                        })
                                    }

                                </OwlCarousel>
                            </Grid>
                            <Grid item xs={1} sm={4}></Grid>
                        </Grid>
                    </div>
                </DialogContent>

            </Dialog>


            {/* /////////video/////////////// */}
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={video.open}
                onClose={() => {
                    setVideo({ open: false, url: '' });
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ padding: "9px 0px", paddingBottom: '7px' }}>
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10} style={{ textAlign: "center" }}>
                            <span>Play Video</span>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                            <CloseIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setVideo({ open: false, url: '' });
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} style={{marginTop:'6px'}}>
                            <Divider />
                        </Grid> */}
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: '0px' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    url={video.url}
                                    width='100%'
                                    height='100%'
                                    controls={true}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}
