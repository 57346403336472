import axios from 'axios'
import { development } from '../../config.json'

import Swal from 'sweetalert2'


export function setHomeData(name, value) {
    return {
        type: 'SET_HOME_DATA',
        payload: { [name]: value },
    }
}


export const getAllSiteSettings = () => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: {} })
    axios.get(`${development.baseUrl}getAllSiteSettings`, { headers: head })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        HomeArray: res.data,
                        course_array: res.data.tab_section.tab_details,
                        filtered: res.data.tab_section.tab_details[0].tab_content,
                        tabarra: res.data.tab_section.tabs,
                        Menu_section: res.data.menu_section ? res.data.menu_section : [],
                        vendor_section: res.data.vendor_section
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Something Went Wrong',
                            // text: 'You are logged in to another System'
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Something Went Wrong',
                            text: 'Check Your Connection'
                        })
                    }
                }

                else {
                    // console.log('eeeerrrrrooorr',err)
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        text: 'Check Your Connection'
                    })
                }
            }
        })
}



export const getCourseUnderCategory = (category_id, page,) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { loading: true } })
    axios.get(`${development.baseUrl}getCourseUnderCategory?category_id=${category_id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Course_Details: res.data.course_details,
                        Course_Array: res.data.course_details.data,
                        first_page_url: res.data.course_details.prev_page_url,
                        next_page_url: res.data.course_details.next_page_url,
                        categories: res.data.categories,
                        loading: false,
                        category_description: res.data.category_description ? res.data.category_description : '',
                        category_name: res.data.category_name ? res.data.category_name : 'Our Courses'

                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    // console.log('aaaaaaaaaaaaaaaaaaaaa',err)
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const showCourseDetails = (course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}showCourseDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Course_Details: res.data
                    }
                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const getAllCategories = (page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { cat_loading: true } })
    axios.get(`${development.baseUrl}getAllCategories?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Catogory_Details: res.data.category_details,
                        Catogory_Array: res.data.category_details.data,
                        cat_first_page_url: res.data.category_details.prev_page_url,
                        cat_next_page_url: res.data.category_details.next_page_url,
                        cat_loading: false,
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { cat_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const getAllVendors = (page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { vendor_loading: true } })
    axios.get(`${development.baseUrl}getAllVendors?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Vendor_Details: res.data.vendor_details,
                        Vendor_Array: res.data.vendor_details.data,
                        vendor_first_page_url: res.data.vendor_details.prev_page_url,
                        vendor_next_page_url: res.data.vendor_details.next_page_url,
                        vendor_loading: false
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { vendor_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const getVendorDetails = (vendor_id, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { single_vendor_loading: true } })
    axios.get(`${development.baseUrl}getVendorDetails?vendor_id=${vendor_id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Vendor_Details: res.data.vendor_details,
                        Single_Vendor_Course_Array: res.data.vendor_details.data,
                        single_vendor_first_page_url: res.data.vendor_details.prev_page_url,
                        single_vendor_next_page_url: res.data.vendor_details.next_page_url,
                        single_vendor_loading: false
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { single_vendor_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const searchCourse = (category_id, page, search) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { loading: true } })
    axios.get(`${development.baseUrl}searchCourse?category_id=${category_id}&page=${page}&course_term=${search}`, { headers: head })
        // axios.get(`${development.baseUrl}searchCourse?page=${page}&course_term=${search}`, { headers: head })

        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Course_Details: res.data.course_details,
                        Course_Array: res.data.course_details.data,
                        first_page_url: res.data.course_details.prev_page_url,
                        next_page_url: res.data.course_details.next_page_url,
                        loading: false,
                        category_description: res.data.category_description ? res.data.category_description : '',
                        category_name: res.data.category_name ? res.data.category_name : 'Our Courses'

                    }
                })
                // cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { loading: false, } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const purchaseSubscription = (token, subscription_id,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { subscription_id: subscription_id }
   // dispatch({ type: 'HOME_ALL_REQUEST', })
    axios.post(`${development.baseUrl}purchaseSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({
                //     type: 'HOME_ALL_SUCCESS', payload: {
                //         Single_Course_Details: res.data
                //     }
                // })
                // console.log("from red",res.data)
                 cb(res.data.data)
            }
        })
        .catch(err => {
           // dispatch({ type: 'HOME_ALL_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const createOrder = (token, course_id, group_id,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id, group_id: group_id }
   // dispatch({ type: 'HOME_ALL_REQUEST', })
    axios.post(`${development.baseUrl}createOrder`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                // dispatch({
                //     type: 'HOME_ALL_SUCCESS', payload: {
                //         Single_Course_Details: res.data
                //     }
                // })
                // console.log("from red",res.data)
                 cb(res.data.data)
            }
        })
        .catch(err => {
           // dispatch({ type: 'HOME_ALL_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
export const LoginedCoursedetails = (token, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { course_id: course_id }
    dispatch({ type: 'HOME_ALL_REQUEST', })
    axios.post(`${development.baseUrl}courseDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Course_Details: res.data
                    }
                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED' })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const ApplyCoupenCode = (token, code, amount, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { coupon: code, amount: amount, course_id: course_id }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { coupenloader: true } })
    axios.post(`${development.baseUrl}validateDiscountCoupon`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        coupen_data: res.data,
                        deduction_amount: res.data.deduction_amount,
                        discounted_amount: res.data.discounted_amount,
                        coupenloader: false,
                        message: ''
                    }
                })
                cb()
            }
            else {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        message: res.data.message,
                        coupenloader: false

                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { coupenloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const searchVendors = (page, search) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { vendor_loading: true } })
    axios.get(`${development.baseUrl}searchVendors?page=${page}&search_term=${search}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Vendor_Details: res.data.vendor_details,
                        Vendor_Array: res.data.vendor_details.data,
                        vendor_first_page_url: res.data.vendor_details.prev_page_url,
                        vendor_next_page_url: res.data.vendor_details.next_page_url,
                        vendor_loading: false
                    }
                })
                // cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { vendor_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const searchCategory = (page, search,) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { cat_loading: true } })
    axios.get(`${development.baseUrl}searchCategory?page=${page}&search_term=${search}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Catogory_Details: res.data.category_details,
                        Catogory_Array: res.data.category_details.data,
                        cat_first_page_url: res.data.category_details.prev_page_url,
                        cat_next_page_url: res.data.category_details.next_page_url,
                        cat_loading: false
                    }
                })
                // cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { cat_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}



export const getVendorPreviewDetails = (token, id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { single_vendor_loading: true } })
    axios.get(`${development.baseUrl}getVendorPreviewDetails?vendor_id=${id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Vendor_Details_Preview: res.data.vendor_details,
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { single_vendor_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getVendorPreviewDetailsForVendor = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { single_vendor_loading: true } })
    axios.get(`${development.baseUrl}getVendorPreviewDetails`, { headers: head })
        .then(res => {
            if (res.data) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Vendor_Details_Preview: res.data.vendor_details,
                    }
                })

            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { single_vendor_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


/////////////general search///////////////


export const CommonsearchCourse = (course_term, page, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { Common_Search_loading: true } })
    axios.get(`${development.baseUrl}generalSearchCourse?course_term=${course_term}&page=${page}`, { headers: head })

        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Common_Search_Course_Details: res.data,
                        Common_Search_Course_Array: res.data.course_details.data,
                        Common_Search_first_page_url: res.data.course_details.prev_page_url,
                        Common_Search_next_page_url: res.data.course_details.next_page_url,
                        Common_Search_loading: false,
                        // Common_Search_category_description:res.data.category_description ? res.data.category_description :''

                    }
                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { Common_Search_loading: false, } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
/////////////////coursefull////////////
export const CommonsearchCourseFull = (course_term, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // let data={category_id:category_id}
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { Full_Search_loading: true } })
    axios.get(`${development.baseUrl}generalSearchCourse?course_term=${course_term}&page=${page}`, { headers: head })

        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Full_Course_Details: res.data,
                        Full_Course_Array: res.data.course_details.data,
                        Full_Search_first_page_url: res.data.course_details.prev_page_url,
                        Full_Search_next_page_url: res.data.course_details.next_page_url,
                        Full_Search_loading: false,
                        // Common_Search_category_description:res.data.category_description ? res.data.category_description :''

                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { Full_Search_loading: false, } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}
/////////////////collage menu click////////////////////



export const filterActiveGrade = (degree_id, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { Collage_Grade_collageloading: true } })
    axios.get(`${development.baseUrl}filterActiveGrade?degree_id=${degree_id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Collage_Grade_Details: res.data,
                        Collage_Grade_Array: res.data.grades.data,
                        Collage_Grade_first_page_url: res.data.grades.prev_page_url,
                        Collage_Grade_next_page_url: res.data.grades.next_page_url,
                        Collage_Grade_collageloading: false,
                        Grade_name: res.data.grade_name ? res.data.grade_name : 'Our Courses'
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { Collage_Grade_collageloading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

//////////////college view more////////


export const viewMoreDegreeCategory = (page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { collagemoreloading: true } })
    axios.get(`${development.baseUrl}viewMoreDegreeCategory?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Collage_More_Details: res.data,
                        Collage_More__Array: res.data.degrees.data,
                        Collage_More_first_page_url: res.data.degrees.prev_page_url,
                        Collage_More__next_page_url: res.data.degrees.next_page_url,
                        collagemoreloading: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { collagemoreloading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

////////////contact///////


export const sendContactMail = (data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'HOME_ALL_REQUEST', payload: { contactusloader: true } })
    axios.post(`${development.baseUrl}sendContactMail`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        contactusloader: false,
                        // contact_message:data.message ? data.message:'sent successfully'
                    }
                })
            }
            cb(res.data)
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { contactusloader: false, } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

//////////////////testimonial////////

export const getAllHomePageTestimonial = (page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { testimonial: true } })
    axios.get(`${development.baseUrl}getAllHomePageTestimonial?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Testimonial_Details: res.data,
                        Testimonial_Array: res.data.testimonials.data,
                        tesimonial_first_page_url: res.data.testimonials.prev_page_url,
                        tesimonial_next_page_url: res.data.testimonials.next_page_url,
                        testimonial: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { testimonial: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

///////////////////gallery/////////////
export const getAllHomePageGallery = (page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { gallery: true } })
    axios.get(`${development.baseUrl}listAllHomePageGallery?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Gallery_Details: res.data ? res.data : [],
                        Gallery_Array: res.data.galleries.data,
                        gallery_first_page_url: res.data.galleries.prev_page_url,
                        gallery_next_page_url: res.data.galleries.next_page_url,
                        gallery: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { gallery: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getGalleryDetails = (id, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    // debugger
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { gallerydetailsloader: true } })
    axios.get(`${development.baseUrl}listtGalleryDetails?gallery_id=${id}&page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Gallery_Details: res.data ? res.data : [],
                        Single_Gallery_Array_details: res.data.galleries.data,
                        Single_gallery_first_page_url: res.data.galleries.prev_page_url,
                        Single_gallery_next_page_url: res.data.galleries.next_page_url,
                        gallerydetailsloader: false,
                    }
                })
            }
            else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { gallerydetailsloader: false } })
                Swal.fire({
                    icon: 'error',
                    text: res.data.message
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { gallerydetailsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Token Expired',
                                // text: 'Token Expired'
                            })
                            window.location.reload()
                        }
                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
        })
}

///////////////////////news/////////////////
export const listAllHomePageNews = (page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { newsloader: true } })
    axios.get(`${development.baseUrl}listAllHomePageNews?page=${page}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        news_Details: res.data,
                        news_Array: res.data.news.data,
                        news_first_page_url: res.data.news.prev_page_url,
                        news_next_page_url: res.data.news.next_page_url,
                        newsloader: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { newsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


export const Single_news = (news_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { single_newsloader: true } })
    axios.get(`${development.baseUrl}news?news_id=${news_id}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        single_news_Details: res.data,
                        single_news_description: res.data.news.description,
                        single_news_day: res.data.news.day,
                        single_news_month: res.data.news.month,
                        single_news_year: res.data.news.year,
                        single_news_image: res.data.news.image,
                        single_news_title: res.data.news.title,
                        recent: res.data.recent_news,
                        single_newsloader: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { single_newsloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

///////////////sub scription list///////////////

export const listSubscriptionPaginated = (search, page) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { subloader: true } })
    axios.get(`${development.baseUrl}listSubscriptionPaginated?page=${page}&search_term=${search}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Subscription_Details: res.data,
                        subloader: false,
                        Subscription_Array: res.data.subscriptions.data,
                        Subscription_first_page_url: res.data.subscriptions.prev_page_url,
                        Subscription_next_page_url: res.data.subscriptions.next_page_url,
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { subloader: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const showSubscriptionDetails = (subscription_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    let data = { id: subscription_id }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}showSubscriptionDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Subscription_Details: res.data.subscription_details ? res.data.subscription_details[0] : []
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    text: res.data.message
                })
            }

        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getSubscriptionDetails = (token, subscription_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = { id: subscription_id }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: {} })
    axios.post(`${development.baseUrl}getSubscriptionDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Subscription_Details: res.data.subscription_details ? res.data.subscription_details[0] : []
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: {} })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

//////////////subscription payment confirm/////////////
export const updatePurchaseSubscription = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'HOME_ALL_REQUEST', payload: { pay_loader: true } })
    axios.post(`${development.baseUrl}updatePurchaseSubscription`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'HOME_ALL_SUCCESS', payload: { pay_loader: false } })
                cb(res.data)
            } else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { pay_loader: false } })
                cb(res.data)

            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'HOME_ALL_FAILED', payload: { pay_loader: false } })
            if (err) {
                if (err.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        text: 'Order Failed'
                    })
                }

            }

        }
        )
}

///////////////validate payment link///////////
export const validatePaymentLink = (expiry, referal, cb, cb2) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { valid_loader: true } })
    axios.get(`${development.baseUrl}validatePaymentLink?expires=${expiry}&signature=${referal}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'HOME_ALL_SUCCESS', payload: { valid_loader: false } })
                cb(res.data)
            } else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { valid_loader: false } })
                cb2(res.data.message)
            }

        })
        .catch(err => {
            //dispatch({ type: 'AUTH_CHECK_SUCCESS', payload: err.response.status })
            dispatch({ type: 'HOME_ALL_FAILED', payload: { valid_loader: false } })
            if (err) {
                if (err.response) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        text: 'Order Failed'
                    })
                }

            }

        }
        )
}


export const updateOrderViaPaymentLink = (data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { valid_loader: true } })
    axios.post(`${development.baseUrl}updateOrderViaPaymentLink`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'HOME_ALL_SUCCESS', payload: { valid_loader: false } })
                cb(res.data)
            } else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { valid_loader: false } })
                cb(res.data)

            }

        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { valid_loader: false } })
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        //  cb()
                    }
                }

            }

        }
        )
}



///////tags/////
export const listAllHomeTags = (page, tag_term) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { tagmoreloading: true } })
    axios.get(`${development.baseUrl}listAllHomeTags?page=${page}&tag_term=${tag_term}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Tag_More_Details: res.data ? res.data : [],
                        Tag_More__Array: res.data.tags ? res.data.tags.data : [],
                        Tag_More_first_page_url: res.data.tags.prev_page_url,
                        Tag_More_next_page_url: res.data.tags.next_page_url,
                        tagmoreloading: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { tagmoreloading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getCourseUnderTag = (tag_id, page, tag_term) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { tagcourseloading: true } })
    axios.get(`${development.baseUrl}getCourseUnderTag?tag_id=${tag_id}&page=${page}&search_term=${tag_term}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Tag_Details: res.data ? res.data : [],
                        Single_Tag_Course_Array: res.data.course_details ? res.data.course_details.data : [],
                        Single_Tag__course_first_page_url: res.data.course_details.prev_page_url,
                        Single_Tag_course_next_page_url: res.data.course_details.next_page_url,
                        tagcourseloading: false,
                        tag_name_in_course: res.data.category_name
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { tagcourseloading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}


//////category page through website////
export const listAllHomeTagsCat = (page, tag_term) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        // Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { tag_cat_loading: true } })
    axios.get(`${development.baseUrl}listAllHomeTags?page=${page}&tag_term=${tag_term}`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Tag_More_Details_Cat: res.data ? res.data : [],
                        Tag_More__Array_Cat: res.data.tags ? res.data.tags.data : [],
                        Tag_More_first_page_url_Cat: res.data.tags.prev_page_url,
                        Tag_More_next_page_url_Cat: res.data.tags.next_page_url,
                        tag_cat_loading: false
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { tag_cat_loading: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

///////////////skill abler data ////

export const getAllCountryStdcode = () => (dispatch) => {

    axios.post(`${development.baseUrl}skill/getAllCountryStdcode`)
        .then(res => {
            if (res.data.result) {
                // let data=res.data.details ? res.data.details:[]
                // data.forEach(element => {
                //     element.code_value=element.label.split(':')[1]
                // });
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        country_code: res.data.details ? res.data.details : []
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { country_code: [] } })

        })
}


///////////////skill abler data ////
export const getVendorDetailsFromSkill = (vendor_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
    }
    let data = {
        skl_user_id: vendor_id
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { skill_vendor_load: true } })
    axios.post(`${development.baseUrl}skill/getVendorDetails`, data, { headers: head })
        .then(res => {
            if (res.data.Status === 'Exists') {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Skill_vendor: res.data ? res.data : [],
                        InstituteName: res.data.InstituteName ? res.data.InstituteName : '',
                        LogoPath: res.data.LogoPath ? res.data.LogoPath : '',
                        SocialMedia: res.data.SocialMedia ? res.data.SocialMedia : [],
                        ImageGallery: res.data.ImageGallery ? res.data.ImageGallery : [],
                        Achievement: res.data.Achievement ? res.data.Achievement : [],
                        skill_vendor_load: false
                    }
                })

            }
            else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { skill_vendor_load: false } })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { skill_vendor_load: false } })
            if (err) {
                if (err.response) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Something Went Wrong',
                                // text: 'You are logged in to another System'
                            })
                        }
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something Went Wrong',
                        // text: 'You are logged in to another System'
                    })
                }
            }
        })
}

export const getClientToken = () => (dispatch) => {

    axios.post(`${development.baseUrl}skill/getClientToken`)
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'HOME_ALL_SUCCESS', payload: {} })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: {} })

        })
}

export const getSingleCourseDetails = (token, course_id, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    let data = {
        // client_token:token,
        course_id: course_id
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { course_data_loading: true } })
    axios.post(`${development.baseUrl}getSingleCourseDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        Single_Course_Details: res.data ? res.data : [],
                        course_data_loading: false,
                        course_tags: res.data ? res.data.tags ? res.data.tags : [] : []
                    }
                })
                const tok = {
                    result: res.data.result,
                    role: res.data.user_details.role,
                    token: res.data.user_details.api_token,
                    name: res.data.user_details.name,
                    emailVerified: res.data.user_details.email_verified,
                    privilege: res.data.user_details.privilege,
                    enterpriseUser: res.data.user_details.enterprise_user,
                    blocked_status: res.data.user_details.blocked_status,
                    subscrition_status: res.data.user_details.subscrition_status
                }
                localStorage.setItem('authDetails', JSON.stringify(tok));
                cb(true)
            }
            else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { course_data_loading: false } })
                Swal.fire({
                    icon: 'error',
                    text: res.data.message ? res.data.message : 'Invalid Token'
                })
                cb(false)
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { course_data_loading: false } })

        })
}

export const getUserLoginDetails = (token, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'HOME_ALL_REQUEST', payload: { course_data_loading: true } })
    axios.post(`${development.baseUrl}getUserLoginDetails`, '', { headers: head })
        .then(res => {
            if (res.data.result) {
                const tok = {
                    result: res.data.result,
                    role: res.data.user_details.role,
                    token: res.data.user_details.api_token,
                    name: res.data.user_details.name,
                    emailVerified: res.data.user_details.email_verified,
                    privilege: res.data.user_details.privilege,
                    enterpriseUser: res.data.user_details.enterprise_user,
                    blocked_status: res.data.user_details.blocked_status,
                    subscrition_status: res.data.user_details.subscrition_status
                }
                localStorage.setItem('authDetails', JSON.stringify(tok));
                cb(true)
            }
            else {
                dispatch({ type: 'HOME_ALL_FAILED', payload: { course_data_loading: false } })
                Swal.fire({
                    icon: 'error',
                    text: res.data.message ? res.data.message : 'Invalid Token'
                })
                cb(false)
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { course_data_loading: false } })

        })
}

////////////////logoutSession/////////////////
export const LogoutSession = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    dispatch({ type: 'HOME_ALL_REQUEST', payload: { } })
    axios.get(`${development.baseUrl}logoutSession`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'HOME_ALL_SUCCESS',})
            } else {
                dispatch({ type: 'HOME_ALL_FAILED',})
            }

        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED',  })
        }
        )
}



///////////////skill abler data ////

export const getAssessmentDetails = (course_id, skl_user_id) => (dispatch) => {
    // console.log("course_id, skl_user_id",course_id, skl_user_id)
    const head = {
        Accept: 'application/json',
    }
    let data = {
        course_id: course_id,
        skl_user_id: skl_user_id
    }
    axios.post(`${development.baseUrl}skill/getAssessmentDetails`, data, { headers: head })
        .then(res => {
            // console.log('getAssessmentDetails--res->',res.data.recordsets[1][0])
            if (res.data.recordsets) {
                dispatch({
                    type: 'HOME_ALL_SUCCESS', payload: {
                        CourseAssesmentDetails: res.data.recordsets[1][0] ? res.data.recordsets[1][0] : {},
                        ModuleAssesmentDetails: res.data.recordsets[0] ? res.data.recordsets[0] : []
                    }
                })
            }
        })
        .catch(err => {
            dispatch({ type: 'HOME_ALL_FAILED', payload: { CourseAssesmentDetails: {}, ModuleAssesmentDetails: [] } })
        })
}
