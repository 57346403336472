import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    // Badge,
    // Card,
    // CardBody,
    // Button,
    // CardHeader,
    Container,
  } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Table from "./table";
import {getActiveCourses,getReschedulingCourses} from "../../../redux/Actions/Superadmin"
import Badge from "reactstrap/lib/Badge";
// import { classNames } from "react-select/lib/utils";
import {useHistory,useLocation} from 'react-router-dom'

function Courselist() {
  const ActiveCourseArray = useSelector(state => state.superadmin.ActiveCourseArray ? state.superadmin.ActiveCourseArray:[])
  const activeloader = useSelector(state => state.superadmin.activeloader ? state.superadmin.activeloader:false)
  const ReschedulingCourseArray = useSelector(state => state.superadmin.ReschedulingCourseArray ? state.superadmin.ReschedulingCourseArray:[])

  
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const history = useHistory()
  const dispatch = useDispatch()
  let location = useLocation();

  useEffect(() => {
    if(location.pathname==='/dashboard/activecourses'){
      dispatch(getActiveCourses(tokenData.token))
    }
    else{
      dispatch(getReschedulingCourses(tokenData.token))

    } 
  
  }, [location.pathname])

  const Viewcourse = (id) =>{
    if(location.pathname==='/dashboard/activecourses'){
      history.push('/dashboard/activecourses/course/'+id)
    }
    else{
      history.push('/dashboard/reschulingcourses/course/'+id)

    }
  }


  const columns = [
    { title: "Course", field: "course_name" },
    { title: "Product_Code", field: "product_code" },
    { title: "Partner", field: "vendor_name" },
    { title: "Course_Date", field: "course_date", 
    render: (rowData)=>{
      return < Badge>{rowData.course_date}</Badge>
    }
  },
    { title: "Enrollment_Date", field: "enrollment_date", 
    render: (rowData)=>{
      return < Badge>{rowData.enrollment_date}</Badge>
    }
  },
  { title: "Type", field: "course_type"},
  { title: "Live_Class", field: "live_class_count" },
  { title: "Video_Class", field: "ondemand_video_count" },

    { title: "Seats", field: "seats" },
    { title: "Proposed_fee", field: "proposed_fee" },

    { title: "Total_fee", field: "total_fee"},
]
    
    const action='active_courses'
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container>
          <Row>
            <Col sm={12}>
              <Table
                loader={activeloader}
                actions={action}
                data={ location.pathname==='/dashboard/activecourses' ? ActiveCourseArray : ReschedulingCourseArray}
                title={location.pathname==='/dashboard/activecourses' ? "Active Courses" :"Rescheduling Courses"}
                columns={columns}
                Viewcourse={Viewcourse}
             />
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>
    </>
  );
}
export default Courselist;
