import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Badge,
  FormFeedback,
  Input,
  Label,
  Container,
} from "reactstrap";

import LaddaButton, { ZOOM_IN } from "react-ladda";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { emailValidator, requiredValidator,} from '../../../utilities/Validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {AddNewSchool,getAllSchool,deleteSchool,payoffCommision,correctCommision,blockSchool,getAllStates, fillterDistricts,fillterPanchayat, fillterPincodes } from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

import Table from "./table";
import Modal from '../../common/Modal'

const CreateSchool = () => {
    

  const [inputField,SetInput]=useState({
    school_name:'',
    email:'',
    password:'',
    number:'',
    area:'',
    pin:'',
    address:'',
    person:''
  })
  const [errors, setErrors] = useState({})
  const [successModal, setSuccessModal] = useState(false);

  const dispatch=useDispatch()
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const history = useHistory();
// console.log("tokendata",tokenData)
  const schoolcreate = useSelector((state) =>state.superadmin.schoolcreate ? state.superadmin.schoolcreate : false);

  const SchoolArray = useSelector((state) =>
    state.superadmin.SchoolArray ? state.superadmin.SchoolArray : []
  );
  const schoolloader = useSelector((state) =>
    state.superadmin.schoolloader ? state.superadmin.schoolloader : ""
  );

  const payoffloader = useSelector((state) =>
  state.superadmin.payoffloader ? state.superadmin.payoffloader : false
)

const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
//const pincodes = useSelector((state) => state.superadmin.pincodes ? state.superadmin.pincodes : []);
const panchayath = useSelector((state) => state.superadmin.panchayath ? state.superadmin.panchayath : []);


  useEffect(()=>{
    dispatch(getAllSchool(tokenData.token));
    dispatch(getAllStates(tokenData.token))
  },[])

  const handleChangeInput=(event)=>{
    SetInput({...inputField,[event.target.name]:event.target.value})
    // console.log('ccchhaanngeee',inputField)
  }

  const Submit = () => {
    const err = {
      email: emailValidator(inputField.email),
      school_name: requiredValidator(inputField.school_name),
      password: requiredValidator(inputField.password),
      number: requiredValidator(inputField.number),
      area: requiredValidator(inputField.area),
      pin: requiredValidator(inputField.pin),
      panchayath_id: requiredValidator(inputField.panchayath_id),
      address: requiredValidator(inputField.address),
      person: requiredValidator(inputField.person),
    };
    if (
      !err.email &&
      !err.password &&
      !err.number &&
      !err.school_name &&
      !err.area &&
      !err.pin &&
      !err.panchayath_id &&
      !err.address&&
      !err.person
    ) {
      setErrors(err);
      // console.log('insidee submit')
      dispatch(AddNewSchool(
          tokenData.token,
          inputField.school_name,
          inputField.number,
          inputField.person,
          inputField.email,
          inputField.password,
          inputField.address,
          inputField.area,
          inputField.pin,
          inputField.panchayath_id,
          handleOpenSuccessModal,
          reset,
          errormodel
      ))
    } else {
      setErrors(err);
      if(err.school_name){
        Showerror('school_name')
      }
      else if(err.panchayath_id){
        Showerror('panchayath_id')
      }
      else if(err.person){
        Showerror('person')
      }
      else if(err.email){
        Showerror('email')
      }
      else if(err.address){
        Showerror('address')
      }
      else if(err.area){
        Showerror('area')
      }
      else if(err.pin){
        Showerror('pin')
      }
      else{
        Showerror('password')
      }
    }
  };

  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

  const [err, setErr] = useState({
    open_err:false,
    err_list:[]
  })
  const errormodel=(val)=>{
    setErr({
      open_err:true,
      err_list:val
    })  
  }
  const closeErr = () => {
    setErr({
      open_err: false,
      err_list: []
    })
  }

  const [Message, setMessage] = useState(null)
  const handleOpenSuccessModal = (message) => {
    setMessage(message)
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
    dispatch(getAllSchool(tokenData.token));
    setSchool({id:'',open:false})
    SetInputmodel({id:'',amount:'',remarks:''})
    setSuccessModal(false)
}
 const reset=()=>{
  SetInput({
    school_name:'',
    email:'',
    password:'',
    number:'',
    area:'',
    pin:'',
    address:'',
    referelcode:'',
    panchayath_id:'',
    person:''
  })
  
 }

  const Handlechangephone=(e)=>{
    SetInput({...inputField,number:e})
    console.log(inputField)
  }


  const columns = [
    { title: "Name", field: "school_name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "contact_number" },
    { title: "Area", field: "area" },
    { title: "PIN", field: "pin" },
    // { title: "Referal_Code", field: "referral_code" },
    // { title: "Students", field: "total_students" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return rowData.status === 1 ? (
          <Badge color="danger" pill>
            {'Blocked'}
          </Badge>
        ) : (
          <Badge color="success" pill>
            {'Active'}
          </Badge>
        );
      },
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const data = SchoolArray? SchoolArray.schools? SchoolArray.schools: []: [];

  const action = "school_table";

  const Vendorview = () => {
    history.push("/dashboard/student");
  };

  const Editschool=(val)=>{
    history.push(`/dashboard/editSchool/${val.school_id}`)
  }


  const Deleteschool=(val)=>{
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

  }).then((result) => {
      if (result.value) {
          dispatch(deleteSchool(tokenData.token, val.school_id,DeleteOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Delete action cancelled',
              'error'
          )
      }
  })
  }

  const [deleteModel, setdeleteModel] = useState(false);

const DeleteOpenSuccessModal = () => {
  dispatch(getAllSchool(tokenData.token));
  setdeleteModel(true)

}
const DeleteCloseSuccesstModal = () => {
  setdeleteModel(false)
}

const [school_data,setSchool]=useState({id:'',open:false})
const [inputFieldmodel,SetInputmodel]=useState({id:'',amount:'',remarks:''})
const [errorsmodel, setErrorsmodel] = useState({})

const CreditDebit=(val)=>{
    setErrors({})
    setSchool({id:val.school_id,open:true})
}
const CloseModel=()=>{
  setSchool({id:'',open:false})
}

const Handlechange=(event)=>{
  SetInputmodel({...inputFieldmodel,[event.target.name]:event.target.value})
}

const submitPayoff=()=>{
  const err = {
    id: requiredValidator(inputFieldmodel.id),
    amount: requiredValidator(inputFieldmodel.amount),
    remarks: requiredValidator(inputFieldmodel.remarks),

  };
  if (
    !err.id &&
    !err.amount &&
    !err.remarks
  ) {
    setErrorsmodel(err);
    if(inputFieldmodel.id==='credit'){
      dispatch(payoffCommision(tokenData.token,school_data.id,inputFieldmodel.amount,inputFieldmodel.remarks,handleOpenSuccessModal))
    }
    else{
      dispatch(correctCommision(tokenData.token,school_data.id,inputFieldmodel.amount,inputFieldmodel.remarks,handleOpenSuccessModal))
    }
  }
  else{
    setErrorsmodel(err)
  }
}

const Updatestatus=(val)=>{
  Swal.fire({
    icon: 'warning',
    title: 'Are you sure !',
    //text: 'All the classes under the subject will be deleted',

    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: "CONFIRM",
    cancelButtonText: "CANCEL",

}).then((result) => {
    if (result.value) {
        dispatch(blockSchool(tokenData.token,val.school_id,handleOpenSuccessModal))

    } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
            'Cancelled',
            'Cancelled',
            'error'
        )
    }
})
}

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          style={{ display: "none" }}
          heading="Create School"
          subheading="Create School With Basic Details"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardBody>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>BASIC DETAILS</CardTitle>
                    <Container>
                      <Row>
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            School Name
                          </Label>
                          <Input
                            type="text"
                            name="school_name"
                            invalid={errors.school_name}
                            onChange={handleChangeInput}
                            value={inputField.school_name}
                            id='school_name'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.school_name}</FormFeedback>
                        </Col>
                        
                        {/* <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Referal Code
                          </Label>
                          <Input
                            type="text"
                            name="referelcode"
                            invalid={errors.referelcode}
                            onChange={handleChangeInput}
                            value={inputField.referelcode}
                          />
                          <FormFeedback>{errors.referelcode}</FormFeedback>
                        </Col> */}

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span> Email ID
                          </Label>
                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            style={{ width: "100%" }}
                            name="email"
                            value={inputField.email}
                            invalid={errors.email}
                            id='email'
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.email}</FormFeedback>
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Password
                          </Label>
                          <Input
                            type="password"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            value={inputField.password}
                            invalid={errors.password}
                            name="password"
                            id='password'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.password}</FormFeedback>
                        </Col>

                        <Col xs={6}  className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Number
                          </Label>

                          <PhoneInput
                            value={inputField.number}
                            name="number"
                            country={'in'}
                            onChange={(e) => Handlechangephone(e)}
                            invalid={errors.number}
                            id='number'
                            />
                            {
                              errors.number ? 
                              <span style={{color:'red'}}>{errors.number}</span>:''
                            }
                          {/* <Input
                            type="number"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="number"
                            value={inputField.number}
                            invalid={errors.number}
                          />
                          <FormFeedback>{errors.number}</FormFeedback> */}
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Person
                          </Label>

                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            name="person"
                            invalid={errors.person}
                            value={inputField.person}
                            id='person'
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.person}</FormFeedback>
                        </Col>

                        
                        <Col md={4}>
                            <label>State</label>
                            <span className="text-danger">*</span>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_DISTRICT_PANCHAYATH"})}}>
                                <option value={null} >Select</option>
                                {states.map((s) => {
                                    return (
                                        <option value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>

                    </Col>
                    <Col sm={4}>
                            <label>District</label>
                            <span className="text-danger">*</span>
                            <Input type='select' onChange={(e) => {dispatch(fillterPanchayat(tokenData.token, e.target.value)); dispatch({ type: "RESET_PANCHAYATH" })   }} >
                                <option value={null} >Select</option>
                                {districts.map((s) => {
                                    return (
                                        <option value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>                         
                            
                        </Col>
                       <Col sm={4}>
                            <label>Panchayat</label>
                            <span className="text-danger">*</span>
                            <Input invalid={errors.panchayath_id} name='panchayath_id' id='panchayath_id' type='select'  onChange={handleChangeInput} >
                                <option value={null} >Select</option>
                                {panchayath.map((p) => {
                                                return (
                                                    <option value={p.panchayath_id}>{p.panchayath}</option>
                                                )
                                            }

                                            )}
                            </Input>                         
                            <FormFeedback>{errors.panchayath_id}</FormFeedback>
                        </Col>

                        
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            rows={4}
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="address"
                            value={inputField.address}
                            invalid={errors.address}
                            id='address'
                          />
                          <FormFeedback>{errors.address}</FormFeedback>
                        </Col>                      

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Municipality
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="area"
                            value={inputField.area}
                            invalid={errors.area}
                            id='area'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.area}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            PIN
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="pin"
                            value={inputField.pin}
                            invalid={errors.pin}
                            id='pin'
                            min={0}
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.pin}</FormFeedback>
                        </Col>

                      </Row>
                    </Container>
                </CardBody>
              </Card>
              <LaddaButton
                className="mb-2 mr-2 btn btn-primary"
                loading={schoolcreate}
                type="submit"
                onClick={Submit}
                data-style={ZOOM_IN}
              >
                CREATE SCHOOL
              </LaddaButton>
          </CardBody>
        </Card>
{tokenData.role === 'superAdmin' &&    //reseller is sharing this component
        <Row>
          <Col sm={12} md={12}>
            <Table
              actions={action}
              loader={schoolloader}
              data={data}
              title={"School List"}
              columns={columns}
              DetailsofVendor={Vendorview}
              Editschool={Editschool}
              Deleteschool={Deleteschool}
              CreditDebit={CreditDebit}
              Updatestatus={Updatestatus}

            />
          </Col>
        </Row>
}
      </CSSTransitionGroup>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text={Message ? Message:"Completed successfully"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />


      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={deleteModel}
        text="Deleted successfully"
        type="success"
        onConfirm={() => DeleteCloseSuccesstModal()} />


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error,ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>



            <Modal
        open={school_data.open ? school_data.open:false}
        handleClose={CloseModel}
        title='New Payments'
        >
          <Row>
            <Col xs={12}>
              <Input 
                type='select'  
                style={{ width: "100%" }}
                onChange={Handlechange}
                name='id'
                value={inputFieldmodel.id}
                invalid={errorsmodel.id}

                >
                  <option value=''>Select Type</option>
                  <option value='credit'>Credit</option>
                  <option value='debit'>Debit</option>
              </Input>
              <FormFeedback>{errorsmodel.id}</FormFeedback>

            </Col>
            <Col xs={12} style={{marginTop:'10px'}}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span>
                  Amount
                </Label>
              <Input 
                 min={0}
                 type="number"
                 style={{ width: "100%" }}
                 name="amount"
                 value={inputFieldmodel.amount}
                 onChange={Handlechange}
                 invalid={errorsmodel.amount}
              />
              <FormFeedback>{errorsmodel.amount}</FormFeedback>
            </Col>

            <Col xs={12} style={{marginTop:'10px'}}>
                <Label for="exampleNameF">
                  Remarks
                </Label>
              <Input 
                 type="textarea"
                 rows={3}
                 style={{ width: "100%" }}
                 name="remarks"
                 value={inputFieldmodel.remarks}
                 onChange={Handlechange}
                 invalid={errorsmodel.remarks}
              />
              <FormFeedback>{errorsmodel.remarks}</FormFeedback>

            </Col>

            <Col xs={12} style={{marginTop:'5px'}}>
                <LaddaButton className=" btn btn-primary"
                  loading={payoffloader}
                  type="button"
                   onClick={() => submitPayoff()}
                  data-style={ZOOM_IN}
                >
                  Submit
                </LaddaButton>
            </Col>
          </Row>
        </Modal>

    </>
  );
};

export default CreateSchool;
