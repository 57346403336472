import React, { useState,useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  // Form,
  FormFeedback,
  Input,
  Label,
  Container,
} from "reactstrap";

import LaddaButton, { ZOOM_IN } from "react-ladda";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { emailValidator, requiredValidator,} from '../../../utilities/Validator'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { AddNewStudent,getAllStudent,listStudentsUnderReseller ,getAllStates, fillterDistricts, fillterPanchayat } from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';

import Studentlist from "./Listsudent"
import Modal from '../../common/Modal'

const CreateStudent = () => {
    
  useEffect(()=>{
    dispatch(getAllStates(tokenData.token))
  },[])
   
  const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
  const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
  //const pincodes = useSelector((state) => state.superadmin.pincodes ? state.superadmin.pincodes : []);
  const panchayath = useSelector((state) => state.superadmin.panchayath ? state.superadmin.panchayath : []);

  const [successModal, setSuccessModal] = useState(false);

  const dispatch=useDispatch()
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const studentcreate = useSelector((state) =>state.superadmin.studentcreate ? state.superadmin.studentcreate : false);
  const district_loader = useSelector((state) =>state.superadmin.district_loader ? state.superadmin.district_loader : false);
  const panchayath_loader = useSelector((state) =>state.superadmin.panchayath_loader ? state.superadmin.panchayath_loader : false);

  
  const [inputField,SetInput]=useState({
    student_name:'',
    email:'',
    password:'',
    number:'',
    area:'',
    pin:'',
    address:'',
    genex_referral_code:''
  })
  const [errors, setErrors] = useState({})

  const handleChangeInput=(event)=>{
    SetInput({...inputField,[event.target.name]:event.target.value})
  }

  const Submit = () => {
    const err = {
      email: emailValidator(inputField.email),
      name: requiredValidator(inputField.student_name),
      password: requiredValidator(inputField.password),
      number: requiredValidator(inputField.number),
      area: requiredValidator(inputField.area),
      pin: requiredValidator(inputField.pin),
      panchayath_id: requiredValidator(inputField.panchayath_id),
      address: requiredValidator(inputField.address),

    };
    if (
      !err.email &&
      !err.password &&
      !err.number &&
      !err.name &&
      !err.area &&
      !err.pin &&
      !err.panchayath_id &&
      !err.address
   
    ) {
      setErrors(err);
      dispatch(AddNewStudent(
        tokenData.token,
        inputField.student_name,
        inputField.number,
        inputField.email,
        inputField.password,
        inputField.address,
        inputField.area,
        inputField.pin,
        inputField.panchayath_id,
        inputField.genex_referral_code,
        handleOpenSuccessModal,
        reset,
        errormodel
    ))

    } else {
      setErrors(err);
      if(err.name){
        Showerror('name')
      }
      else if(err.panchayath_id){
        Showerror('panchayath_id')
      }
      else if(err.email){
        Showerror('email')
      }
      else if(err.address){
        Showerror('address')
      }
      else if(err.area){
        Showerror('area')
      }
      else if(err.pin){
        Showerror('pin')
      }
      else{
        Showerror('password')
      }

    }
  };

  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}
  const [err, setErr] = useState({
    open_err:false,
    err_list:[]
  })
  const errormodel=(val)=>{
    setErr({
      open_err:true,
      err_list:val
    })  
  }
  const closeErr = () => {
    setErr({
      open_err: false,
      err_list: []
    })
  }

  const Handlechangephone=(e)=>{
    SetInput({...inputField,number:e})
  }
  

  const handleOpenSuccessModal = () => {
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
  if(tokenData.role==='superAdmin'){
    dispatch(getAllStudent(tokenData.token))
  }
  else{
    dispatch(listStudentsUnderReseller(tokenData.token))
  }

    setSuccessModal(false)
    // window.location.reload(false);

}
 const reset=()=>{
  SetInput({
    student_name:'',
    email:'',
    password:'',
    number:'',
    area:'',
    pin:'',
    address:'',
    panchayath_id:'',
    genex_referral_code:''
  })
  
 }

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          style={{ display: "none" }}
          heading="Create Student"
          subheading="Create Student With Basic Details"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardBody>
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>BASIC DETAILS</CardTitle>
                    <Container>
                      <Row>
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Name
                          </Label>
                          <Input
                            type="text"
                            name="student_name"
                            invalid={errors.name}
                            onChange={handleChangeInput}
                            value={inputField.student_name}
                            id='name'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.name}</FormFeedback>
                        </Col>
                        

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span> Email ID
                          </Label>
                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            style={{ width: "100%" }}
                            name="email"
                            value={inputField.email}
                            invalid={errors.email}
                            id='email'
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.email}</FormFeedback>
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Password
                          </Label>
                          <Input
                            type="password"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            value={inputField.password}
                            invalid={errors.password}
                            name="password"
                            id='password'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.password}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Number
                          </Label>
                          <PhoneInput
                            value={inputField.number}
                            name="number"
                            country={'in'}
                            onChange={(e) => Handlechangephone(e)}
                            invalid={errors.number}
                            id='number'
                            />
                            {
                              errors.number ? 
                              <span style={{color:'red'}}>{errors.number}</span>:''
                            }
   
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            {/* <span className="text-danger">*</span> */}
                            Referral Code
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            value={inputField.genex_referral_code}
                            name="genex_referral_code"
                            autocomplete="off"
                          />
                        </Col>

                        <Col md={4}>
                            <label>State</label>
                            <span className="text-danger">*</span>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_DISTRICT_PANCHAYATH"})}}>
                                <option value={null} >Select</option>
                                {states.map((s,k) => {
                                    return (
                                        <option key={k} value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>

                    </Col>
                    <Col sm={4}>
                            <label>District</label>
                            <span className="text-danger">*</span>
                            <div class="form-group">
                            <div class="input-group">
                              <Input type='select' onChange={(e) => {dispatch(fillterPanchayat(tokenData.token, e.target.value)); dispatch({ type: "RESET_PANCHAYATH" })   }} >
                                  <option value={null} >Select</option>
                                  {districts.map((s,k) => {
                                      return (
                                          <option key={k} value={s.district_id}>{s.district_name}</option>
                                      )
                                  }

                                  )}
                              </Input>                         
                            {
                            district_loader ?
                              <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>
                              :
                              null
                          }
                        </div>
                      </div>
                        </Col>
                       <Col sm={4}>
                            <label>Panchayat</label>
                            <span className="text-danger">*</span>
                            <div class="form-group">
                            <div class="input-group">
                            <Input invalid={errors.panchayath_id} name='panchayath_id' id='panchayath_id' type='select' value={inputField.panchayath_id}  onChange={handleChangeInput} >
                                <option value={null} >Select</option>
                                {panchayath.map((p,k) => {
                                  return (
                                    <option key={k} value={p.panchayath_id}>{p.panchayath}</option>
                                  )
                                }

                              )}
                            </Input>  
                            {
                            panchayath_loader ?
                              <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </span>
                              :
                              null
                          }
                        </div>
                      </div>                       
                            <FormFeedback>{errors.panchayath_id}</FormFeedback>
                        </Col>

                             
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            rows={4}
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="address"
                            value={inputField.address}
                            invalid={errors.address}
                            id='address'
                            
                          />
                          <FormFeedback>{errors.address}</FormFeedback>
                        </Col>                      

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Area
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="area"
                            value={inputField.area}
                            invalid={errors.area}
                            id='area'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.area}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            PIN
                          </Label>
                          <Input
                            type="number"
                            style={{ width: "100%" }}
                            onChange={handleChangeInput}
                            name="pin"
                            value={inputField.pin}
                            invalid={errors.pin}
                            min={0}
                            id='pin'
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.pin}</FormFeedback>
                        </Col>

                      </Row>
                    </Container>
                </CardBody>
              </Card>
              <LaddaButton
                className="mb-2 mr-2 btn btn-primary"
                loading={studentcreate}
                type="submit"
                onClick={Submit}
                data-style={ZOOM_IN}
              >
                CREATE STUDENT
              </LaddaButton>
          </CardBody>
        </Card>

        <Studentlist />
      </CSSTransitionGroup>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Completed successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error) => <li  style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>

    </>

    
  );
}; 

export default CreateStudent;
