import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, Input, CardBody, CardTitle, FormFeedback } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { getStudyMaterialData, updateStudyMaterial, deleteStudyMaterial, deleteStudyMaterialFile, updateFileUpload, downloadStudyMaterialFile } from '../../../redux/Actions/homeTeacher'
import { Clear, } from '@material-ui/icons'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import PageTitle from '../../common/HeaderTitle/PageTitle'
// import Dropzone from 'react-dropzone'
import { Attachment, CloudDownload } from '@material-ui/icons'
import { requiredValidator } from "../../../utilities/Validator";
import Swal from 'sweetalert2'
import { Progress } from 'react-sweet-progress';
import Tooltip from '@material-ui/core/Tooltip';

function Index() {

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const params = useParams()
    const homeTeacher = useSelector(({ homeTeacher }) => homeTeacher)

    // const studyMaterialDetails = useSelector(state => state.homeTeacher.studyMaterialDetails ? state.homeTeacher.studyMaterialDetails : null)
    const files = useSelector(state => state.homeTeacher.studyMaterialDetails ? state.homeTeacher.studyMaterialDetails.files : [])
    // const studyMaterialDetailsLoader = useSelector(state => state.homeTeacher.studyMaterialDetailsLoader ? state.homeTeacher.studyMaterialDetailsLoader : false)


    useEffect(() => {
        dispatch(getStudyMaterialData(tokenData.token, params.study_material_id, params.class_id, cb))
    }, [])

    const [EditData, setEditData] = useState({})

    const cb = (data) => {
        setEditData(data)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setEditData({ ...EditData, [name]: value })
    }

    const [Files, setFiles] = useState([]);

    const onDrop = (e) => {
        // console.log("log files", e)
        let arr = Array.from(e.currentTarget.files)
        setFiles([...Files, ...arr])
        // setFiles(e)
    }
    const formData = new FormData();
    const handleDelete = (i) => {
        let arr = [...Files]
        arr.splice(i, 1)
        setFiles(arr)
    }


    const handleDeleteFile = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteStudyMaterialFile(tokenData.token, id, params.study_material_id, refresh))
            }
        })

    }

    const refreshupdate = () => {
        setUpdateLoader(false)
        Swal.fire('Success', 'Updated Succesfully')
        refresh()
        setError({})
    }

    const refresh = () => {
        dispatch(getStudyMaterialData(tokenData.token, params.study_material_id, params.class_id, cb))
    }

    const [UpdateLoader, setUpdateLoader] = useState(false)
    const [Error, setError] = useState({})

    const formSubmit = () => {

        let err = {};

        err = {
            // class_id: requiredValidator(InputField.class_id),
            title: requiredValidator(EditData.Title),
            description: requiredValidator(EditData.Description),
            // course_id: requiredValidator(InputField.course_id),
        };

        if (!err.title && !err.description) {
            setUpdateLoader(true)
            let data = {
                study_material_id: params.study_material_id,
                title: EditData.Title,
                description: EditData.Description
            }

            dispatch(updateStudyMaterial(tokenData.token, data, refreshupdate))
        } else {
            setError(err)
        }

    }

    const [AddMoreLoader, setAddMoreLoader] = useState(false)

    const handleFileUpload = () => {
        // if (Files.length > 0) {
        if(files.length > 0 || Files.length > 0){
            let err = {};
        err = {
            // class_id: requiredValidator(InputField.class_id),
            title: requiredValidator(EditData.Title),
            description: requiredValidator(EditData.Description),
            // course_id: requiredValidator(InputField.course_id),
        };
        setError(err)
        if (!err.title && !err.description) {
            setAddMoreLoader(true)
            const formData = new FormData();

            for (let i = 0; i < Files.length; i++) {

                formData.append('study_material[]', Files[i])
            }
            formData.append('class_id', params.class_id)
            formData.append('study_material_id', params.study_material_id)
            formData.append('title', EditData.Title)
            formData.append('description', EditData.Description)

            formData.append('type', 'study_material')

            dispatch(updateFileUpload(tokenData.token, formData, refreshfile))
        }
        }
        else {
            Swal.fire('Warning', 'Attach atleast one file', 'warning')
        }
    }

    const refreshfile = () => {
        dispatch({ type: 'UPDATE_STUDY_MATERIAL_PROGRESS', payload: '' })
        setFiles([])
        setAddMoreLoader(false)
        Swal.fire('Success', 'Updated', 'success')
        refresh()
    }

    const handleDownloadFile = (id) => {
        dispatch(downloadStudyMaterialFile(tokenData.token, id, params.class_id))
    }

    return (
        <>
            <PageTitle
                heading="Study Materials"
                subheading="Update Study Materials"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />
            <Card className='px-3' >
                {/* <CardHeader>Update study material</CardHeader> */}
                <CardBody >

                    <Row>
                        <CardTitle>General Details</CardTitle>
                        <Col sm={12} >
                            <span className='text-danger' >*</span>
                            <label>Title</label>
                            <Input name='Title' invalid={Error.title} onChange={handleChange} value={EditData.Title} ></Input>
                            <FormFeedback>{Error.title}</FormFeedback>
                        </Col>
                        <Col sm={12} >
                            <span className='text-danger' >*</span>
                            <label>Description</label>
                            <Input invalid={Error.description} name='Description' onChange={handleChange} type='textarea' value={EditData.Description} ></Input>
                            <FormFeedback>{Error.description}</FormFeedback>
                        </Col>
                        {/* <div style={{ width: '100%', textAlign: 'right' }} >
                            <LaddaButton className="mt-2 mr-2 btn btn-danger"
                                loading={UpdateLoader}
                                onClick={formSubmit}
                                type="submit"
                                data-style={ZOOM_IN}
                                style={{ whiteSpace: 'nowrap', marginRight: '0px' }}

                            >
                                Update
                            </LaddaButton>
                        </div> */}
                    </Row>

                    {/* <hr /> */}
                    <Row>
                        <div style={{ marginTop: '10px', width: '100%' }} >
                            <CardTitle>Uploaded Files</CardTitle>


                            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                                {
                                    files?.map((file, index) => {
                                        return (
                                            <div key={index} style={{ backgroundColor: 'lightblue', margin: '5px', display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', padding: '3px' }} >

                                                <div style={{ textAlign: 'right', width: '100%' }} >
                                                    <Tooltip title='Remove File'>
                                                        <Clear style={{ cursor: 'pointer' }} fontSize='small' onClick={() => handleDeleteFile(file.id)} />
                                                    </Tooltip>
                                                </div>

                                                <div style={{ textAlign: 'center', width: '100%' }} >
                                                    <Tooltip title='View image'>
                                                        <CloudDownload fontSize='large' style={{ cursor: 'pointer' }} onClick={() => handleDownloadFile(file.id)} />
                                                    </Tooltip>
                                                </div>
                                                <Tooltip title={file.file_name}>
                                                    <span>
                                                        {
                                                            file.file_name.length > 10 ? file.file_name.slice(0, 20) + '...' : file.file_name
                                                        }
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        )
                                    })

                                }

                            </div>
                            <hr />
                        </div>
                        <div style={{ marginTop: '10px' }} >
                            <CardTitle>Add More Files</CardTitle>
                            <div>
                                <label style={{ border: 'black dashed 1px', padding: '5px', marginLeft: '5px' }} for='file-input' >Browse Files</label>
                                <Input style={{ display: 'none' }} id='file-input' type='file' multiple onChange={onDrop} ></Input>
                            </div>

                            <Col sm={12} >
                                {Files.length > 0 ?
                                    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '20px 10px', }} >
                                        {
                                            Files.map((file, i) =>
                                                <div style={{ backgroundColor: 'lavender', padding: '4px', display: 'flex', margin: '10px', borderRadius: '4px' }} >
                                                    <div style={{ display: 'flex', alignItems: 'center' }} >
                                                        <Attachment />
                                                        {file.name}
                                                        {/* - {file.size} */}
                                                    </div>
                                                    <Tooltip title='Remove'>
                                                        <span onClick={() => handleDelete(i)} style={{ backgroundColor: 'lightcoral', padding: '2px', margin: '3px' }} >
                                                            <Clear fontSize='small' />
                                                        </span>
                                                    </Tooltip>

                                                </div>
                                            )
                                        }

                                    </div>

                                    : ''

                                }
                            </Col>
                        </div>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'right', width: '100%' }} >
                        {

                            Files.length > 0 && homeTeacher.updateStudyMaterialProgress !== '' ?
                                <>
                                    <Progress
                                        percent={homeTeacher.updateStudyMaterialProgress}
                                    />
                                </> : null

                        }
                        <LaddaButton className="mt-2 mr-2 btn btn-danger"
                            loading={AddMoreLoader}
                            onClick={handleFileUpload}
                            type="submit"
                            data-style={ZOOM_IN}
                            style={{ whiteSpace: 'nowrap', marginRight: '0px' }}

                        >
                            Update
                        </LaddaButton>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default Index
