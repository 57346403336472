const initialState = {
    records: [],
    downloadTeacherLoader: false,
}

const RecordingsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'LIST_RECORDINGS': {
            return {
                ...state, loader: false, records: payload
            }

        }
        case 'RECORDING_DOWNLOAD_SUCCESS': {
            return {
                ...state, downloadTeacherLoader: false
            }
        }
        default:
            return state
    }
}

export default RecordingsReducer;