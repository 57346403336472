import React from 'react'
import Image from './page_not_found.png'
function index() {
    return (
        <div>
            <img alt="page not found" src={Image}></img>
        </div>
    )
}

export default index
