import React, { useState,useEffect,useRef } from "react";
import { useDispatch,useSelector } from "react-redux";
import { Progress } from "react-sweet-progress";

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  // FormGroup,
  FormFeedback,
  Input,
  Label,
  Container,
  Button
} from "reactstrap";

import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { setReqData,populateVendorDetail,updateVendor } from "../../../redux/Actions/Superadmin";
import {
  emailValidator,
  requiredValidator,
} from "../../../utilities/Validator";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import SweetAlert from 'sweetalert-react';
import { useParams } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Modal from '../../common/Modal'


import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const Editvendor = () => {
  const dispatch = useDispatch();
  const formData = new FormData();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const params = useParams()

  const history = useHistory();

  const [input, SetInput] = useState(null);
  const [errors, setErrors] = useState({});
  const [successModal, setSuccessModal] = useState(false);

  const [File, setFile] = useState()
  const [Logo, setLogo] = useState()

  const handleChangeInput = (event) => {
    SetInput({ ...input, [event.target.name]: event.target.value });
  };

  const editvendor_loader = useSelector((state) =>state.superadmin.editvendor_loader ? state.superadmin.editvendor_loader : false);
  // const files = useSelector((state) =>state.superadmin.files ? state.superadmin.files : []);
  // const logo = useSelector((state) =>state.superadmin.logo ? state.superadmin.logo : []);

  const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

  useEffect(()=>{
    dispatch(setReqData("percetage", ''));
    dispatch(populateVendorDetail(tokenData.token,params.enterprise_id,cb))
  },[])

  const cb=(formData)=>{
    SetInput(formData)
  } 

  const Changefile = (e) => {
    // let arr = [];
    // for (let i = 0; i < e.target.files.length; i++) {
    //   arr.push(e.target.files[i]);
    // }
    // dispatch(setReqData("files", arr));
    setFile(e.target.files[0])
  };

  const cropperRef = useRef(null);
  const [src,setSrc]=useState('')

  const [cropData, setCropData] = useState("");

  const ChangefileLogo=(e)=>{
    e.preventDefault()
    const reader = new FileReader();

    reader.onload = () => {
      setSrc(reader.result)
    };
    if(e.target.files[0]){
      reader.readAsDataURL(e.target.files[0]);
      setLogo(e.target.files[0])
    }
    
  }


  const CropImage=()=>{
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    console.log(cropper.getCroppedCanvas().toDataURL());
  }

  const getCropData = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper
    if (typeof cropper !== "undefined") {
      console.log(cropper.getCroppedCanvas().toDataURL());
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };
  
  //////to convert binary64 to blob
const b64toBlob=(dataURI)=> {
  
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

  const Submit = (e) => {
    e.preventDefault();
    // let fileerr=files.length > 0 ? '' :"* required"

    const err = {
      email: emailValidator(input.email),
      vendorname: requiredValidator(input.vendor_name),
      // password: requiredValidator(input.password),
      number: requiredValidator(input.contact_number),
      person: requiredValidator(input.contact_person),
      // pan: requiredValidator(input.pan),
      // gst: requiredValidator(input.gst),
      address: requiredValidator(input.address),
      // description: requiredValidator(input.description),
      // file:fileerr
    };
    if (
      !err.email &&
      !err.vendorname &&
      !err.number &&
      !err.person &&
      // !err.pan &&
      // !err.gst &&
      !err.address 
      // !err.description 
      // !err.fileerr
    ) {
      setErrors(err);
      formData.append("vendor_name", input.vendor_name);
      formData.append("contact_number", input.contact_number);
      formData.append("contact_person", input.contact_person);
      formData.append("email", input.email);
      if(input.password){
        formData.append("password", input.password ? input.password:'');
      }
      formData.append("gst_number", input.gst_number ? input.gst_number:'');
      formData.append("pan_number", input.pan_number ? input.pan_number:'');
      formData.append("address", input.address);
      formData.append("description", input.description);
      //formData.append("doc_url", files);
      formData.append("doc_url", File);
     // formData.append("enterprise_logo_url",logo);
     if(cropData){
      // formData.append("enterprise_logo_url",Logo);
      let file=b64toBlob(cropData)
        formData.append("enterprise_logo_url", file )
     }     

      formData.append('user_id',params.enterprise_id)
      dispatch(updateVendor(tokenData.token,formData,handleOpenSuccessModal,errormodel))
    } else {
      setErrors(err);
      if(err.vendorname){
        Showerror('vendorname')
      }
      // else if(err.number){
      //   Showerror('number')
      // }
      else if(err.email){
        Showerror('email')
      }
      else if(err.person){
        Showerror('person')
      }
      else{
        Showerror('address')
      }
    }
  };
  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

  const [err, setErr] = useState({
    open_err:false,
    err_list:[]
  })
  const errormodel=(val)=>{
    setErr({
      open_err:true,
      err_list:val
    })  
  }
  const closeErr = () => {
    setErr({
      open_err:false,
      err_list:[]
    })
  }

  const handleOpenSuccessModal = () => {
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
    setSuccessModal(false)
    history.push('/dashboard/vendors')
}



 const Handlechangephone=(e)=>{
  SetInput({...input,contact_number:e})
}
  return (
    <div>
      {
        input ? 
        <>
        <PageTitle
            style={{ display: "none" }}
            heading="Edit Partner"
            subheading="Edit Partner Details"
            icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
          />

            <Card tabs={true} className="mb-3">
              <CardBody>
                {/* <Form onSubmit={submitForm} id="class-form"> */}
                <Card className="main-card mb-3">
                  <CardBody>
                    <CardTitle>BASIC DETAILS</CardTitle>
                    <Container>
                      <Row>
                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Partner Name
                          </Label>
                          <Input
                            type="text"
                            name="vendor_name"
                            id='vendorname'
                            value={input.vendor_name}
                            invalid={errors.vendorname ? true : false}
                            onChange={handleChangeInput}
                            autocomplete="off"
                          />
                          <FormFeedback>{errors.vendorname}</FormFeedback>
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span> Email ID
                          </Label>
                          <Input
                            type="text"
                            onChange={handleChangeInput}
                            style={{ width: "100%" }}
                            // id="demo-simple-select-required"
                            id='email'
                            name="email"
                            value={input.email}
                            invalid={errors.email}
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.email}</FormFeedback>
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            {/* <span className="text-danger">*</span> */}
                            Password
                          </Label>
                          <Input
                            type="password"
                            style={{ width: "100%" }}
                            id="deadline"
                            value={input.password ? input.password:''}
                            // invalid={errors.password}
                            name="password"
                            onChange={handleChangeInput}
                            autocomplete="off"
                          />
                          {/* <FormFeedback>{errors.password}</FormFeedback> */}
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Number
                          </Label>
                            <PhoneInput
                                value={input.contact_number}
                                name="contact_number"
                                // id='number'
                                country={'in'}
                                onChange={(e) => Handlechangephone(e)}
                                invalid={errors.number}
                                
                                />
                                {
                                  errors.number ? 
                                  <span style={{color:'red'}}>{errors.number}</span>:''
                                }
            
                        </Col>

                        <Col sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Contact Person
                          </Label>

                          <Input
                            type="text"
                            name="contact_person"
                            id='person'
                            invalid={errors.person}
                            onChange={handleChangeInput}
                            value={input.contact_person}
                            autocomplete="off"
                          ></Input>
                          <FormFeedback>{errors.person}</FormFeedback>
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            {/* <span className="text-danger">*</span> */}
                            GST no.
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            name="gst_number"
                            value={input.gst_number}
                            onChange={handleChangeInput}
                            autocomplete="off"
                            // invalid={errors.gst}
                          />
                          {/* <FormFeedback>{errors.gst}</FormFeedback> */}
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            {/* <span className="text-danger">*</span> */}
                            PAN no.
                          </Label>
                          <Input
                            type="text"
                            style={{ width: "100%" }}
                            name="pan_number"
                            value={input.pan_number}
                            onChange={handleChangeInput}
                            autocomplete="off"
                            // invalid={errors.pan}
                          />
                          {/* <FormFeedback>{errors.pan}</FormFeedback> */}
                        </Col>

                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            Address
                          </Label>
                          <Input
                            type="textarea"
                            rows={4}
                            style={{ width: "100%" }}
                            name="address"
                            id='address'
                            value={input.address}
                            onChange={handleChangeInput}
                            invalid={errors.address}
                          />
                          <FormFeedback>{errors.address}</FormFeedback>
                        </Col>

                        <Col sm={12} className="assign-form-grid">
                          <Label for="exampleNameF">
                            {/* <span className="text-danger">*</span> */}
                            Description
                          </Label>
                          <Input
                            type="textarea"
                            rows={2}
                            style={{ width: "100%" }}
                            value={input.description}
                            name="description"
                            onChange={handleChangeInput}
                            // invalid={errors.description}
                          />
                          {/* <FormFeedback>{errors.description}</FormFeedback> */}
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            Support Documents
                          </Label>
                          <Input
                            style={{ lineHeight: "27px" }}
                            type="file"
                            id="assignment"
                            onChange={Changefile}
                            name="support document"
                          
                            // invalid={errors.file}
                          />
                          {/* <FormFeedback >{errors.file}</FormFeedback> */}
                        </Col>

                        <Col xs={12} sm={6} className="assign-form-grid">
                          <Label for="exampleNameF">
                            Logo
                          </Label>
                          <Input
                            style={{ lineHeight: "27px" }}
                            type="file"
                            onChange={ChangefileLogo}
                            name="logo"
                            accept="image/*"
                            // invalid={errors.file}
                          />
                          {/* <FormFeedback >{errors.file}</FormFeedback> */}
                        </Col>
                        

                        <Col xs={12} sm={6}>
                    {
                          src ? 
                          <>
                            <Cropper
                              src={src}
                              style={{ height: '400px', width: '100%' }}
                              aspectRatio={5 / 3}
                              guides={false}
                              crop={CropImage} 
                              ref={cropperRef}

                              />
                                <div className="divider"/>
                                <div className="text-center">   
                                <div className="text-center">
                                    <Button color="primary"
                                            onClick={getCropData}
                                        >
                                            Crop Selection
                                        </Button>
                                    </div> 
                                  
                                </div>            
                            </>
                            : 
                            ''
                        }
                    </Col>

                    <Col xs={12} sm={6}>
                    {
                        cropData ? 
                          <div>
                            <div>
                                <h6>Cropped Result</h6>
                            </div>
                            <img
                                className="after-img rounded"
                                src={cropData}
                                alt=""
                                width='100%'
                            />
                        </div>
                      : null
                      }
                    </Col>


                        <Col sm={12}>
                        {(percetage !== ""  &&  (File || Logo)) ? (
                          <>
                            <strong style={{ color: "rgb(19, 140, 228)" }}>
                              Uploading Files...
                            </strong>
                            <Progress percent={percetage} />
                          </>
                        ) : null}
                      </Col>

                      </Row>
                    </Container>
                  </CardBody>
                </Card>
                <LaddaButton
                  className="mb-2 mr-2 btn btn-primary"
                  loading={editvendor_loader}
                  type="submit"
                  data-style={ZOOM_IN}
                  onClick={Submit}
                >
                  UPDATE PARTNER
                </LaddaButton>
                {/* </Form> */}
              </CardBody>
            </Card>
            </>
        :
        ''
      }
        

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text="Updated successfully"
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error,ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>
    </div>
  );
};

export default Editvendor;
