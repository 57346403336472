import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import {
    Row, Col,
    Card, CardBody, CardTitle,
    CardHeader, CardFooter, Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    CustomInput,
    FormGroup, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AddStudentToClass = (props) => {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    return (


        <Card className="main-card mb-3">
            <CardHeader>
                <Col md={5} sm={5}>
                    Student
                    </Col>
                <Col md={7} sm={7}>
                    Phone
                    </Col>
            </CardHeader>
            <CardBody>
                <div className="scroll-area-sm">
                    <PerfectScrollbar>
                        {
                            tokenData.enterpriseUser ?
                                props.data ? props.data.map(dt => (
                                    <Row key={dt.user_id}>
                                        <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                            {`${dt.first_name} ${dt.last_name}`}
                                        </Col>
                                        <Col md={5} sm={5} style={{ paddingLeft: '20px' }}>
                                            {`${dt.mobile}     `}
                                        </Col>

                                    </Row>

                                ))
                                    : null
                                :
                                props.data ? props.data.filter(u => u.selected !== false).map(dt => (
                                    <Row key={dt.user_id}>
                                        <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                            {`${dt.first_name} ${dt.last_name}`}
                                        </Col>
                                        <Col md={5} sm={5} style={{ paddingLeft: '20px' }}>
                                            {`${dt.mobile}     `}
                                        </Col>

                                    </Row>

                                )) : null
                        }
                    </PerfectScrollbar>
                </div>
            </CardBody>

        </Card>

    )
}

export default AddStudentToClass
