import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { resellerDashBoard } from '../../../redux/Actions/reseller'
// import {
//     AreaChart, Area, LineChart, Line,
//     ResponsiveContainer,
// } from 'recharts';
import {
    Row, Col,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Button
} from 'reactstrap';
import { useHistory } from "react-router-dom";
import CountUp from 'react-countup';
import PageTitle from '../../common/HeaderTitle/PageTitle'
import Slider from './Slider'

function Index() {

    const history = useHistory()
    const resellerStore = useSelector(({ reseller }) => reseller);
    console.log('rslll', resellerStore)
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    useEffect(() => {
        dispatch(resellerDashBoard(tokenData.token, cb))
    }, [])

    const [Counter, setCounter] = useState()
    const [SchoolSliderData, setSchoolSliderData] = useState([])
    const [StudentSliderData, setStudentSliderData] = useState([])

    const cb = (Dashboard) => {
        setCounter(Dashboard.counter)
        setSchoolSliderData(Dashboard.school_details)
        setStudentSliderData(Dashboard.student_details)
    }
    console.log("dadada", Counter)
    // const data123 = [
    //     {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    //     {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    //     {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    //     {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    //     {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    //     {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    //     {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    //     {name: 'Page C', uv: 2000, pv: 6800, amt: 2290},
    //     {name: 'Page D', uv: 4780, pv: 7908, amt: 2000},
    //     {name: 'Page E', uv: 2890, pv: 9800, amt: 2181},
    //     {name: 'Page F', uv: 1390, pv: 3800, amt: 1500},
    //     {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    // ];


    // const data2 = [
    //     {name: 'Page A', uv: 5400, pv: 5240, amt: 1240},
    //     {name: 'Page B', uv: 7300, pv: 4139, amt: 3221},
    //     {name: 'Page C', uv: 8200, pv: 7980, amt: 5229},
    //     {name: 'Page D', uv: 6278, pv: 4390, amt: 3200},
    //     {name: 'Page E', uv: 3189, pv: 7480, amt: 6218},
    //     {name: 'Page D', uv: 9478, pv: 6790, amt: 2200},
    //     {name: 'Page E', uv: 1289, pv: 1980, amt: 7218},
    //     {name: 'Page F', uv: 3139, pv: 2380, amt: 5150},
    //     {name: 'Page G', uv: 5349, pv: 3430, amt: 3210},
    // ];

    const handleStudentClick = (val) => {
        history.push(`/dashboard/studentdetails/${val}`);
    }

    const handleSchoolClick = (val) => {
        history.push(`/dashboard/schools/${val}`);
    }

    return (
        <div>
            <PageTitle

                heading="Reseller Dashboard"
                subheading="Welcome to dashboard"
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />
            {Counter &&
                <Row>
                    <Col sm="12" md="6" xl="4">
                        <div className="card mb-3 widget-chart">
                            <div className="widget-chart-content">
                                <div className="icon-wrapper rounded">
                                    <div className="icon-wrapper-bg bg-success" />
                                    <i className="lnr-apartment text-success" />
                                </div>
                                <div className="widget-numbers">
                                    <CountUp start={0}
                                        end={Counter.total_school}
                                        separator=""
                                        decimals={0}
                                        decimal="."
                                        prefix=""
                                        useEasing={false}
                                        suffix=""
                                        duration="3" />
                                </div>
                                <div
                                    className="widget-subheading fsize-1 pt-2 opacity-10 text-success font-weight-bold">
                                    Total Schools
                                    </div>
                                {/* <div className="widget-description opacity-8">
                                                <span className="text-danger pr-1">
                                                <FontAwesomeIcon icon={faAngleDown}/>
                                                <span className="pl-1">54.1%</span>
                                            </span>
                                        down last 30 days
                                    </div> */}
                            </div>
                            <div className="widget-chart-wrapper">
                                {/* <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                        <LineChart data={data123}
                                                   margin={{top: 0, right: 25, left: 25, bottom: 0}}>
                                            <Line type='monotone' dataKey='pv' stroke='var(--warning)' strokeWidth={3}/>
                                        </LineChart>
                                    </ResponsiveContainer> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="6" xl="4">
                        <div className="card mb-3 widget-chart">
                            <div className="widget-chart-content">
                                <div className="icon-wrapper rounded">
                                    <div className="icon-wrapper-bg bg-primary" />
                                    <i className="lnr-users text-primary" />
                                </div>
                                <div className="widget-numbers">
                                    <CountUp start={0}
                                        end={Counter.total_students}
                                        separator=""
                                        decimals={0}
                                        decimal=","
                                        prefix=""
                                        useEasing={false}
                                        suffix=""
                                        duration="3" />
                                </div>
                                <div
                                    className="widget-subheading fsize-1 pt-2 opacity-10 text-primary font-weight-bold">
                                    Total Students
                                    </div>
                                {/* <div className="widget-description opacity-8">
                                        Compared to YoY:
                                        <span className="text-info pl-1">
                                                <FontAwesomeIcon icon={faAngleDown}/>
                                                <span className="pl-1">14.1%</span>
                                            </span>
                                    </div> */}
                            </div>
                            <div className="widget-chart-wrapper">
                                {/* <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                        <AreaChart data={data2}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Area type='monotoneX' dataKey='uv' strokeWidth='2' stroke='var(--danger)'
                                                  fill='var(--danger)' fillOpacity='.3'/>
                                        </AreaChart>
                                    </ResponsiveContainer> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" md="12" xl="4">
                        <div className="card mb-3 widget-chart">
                            <div className="widget-chart-content">
                                <div className="icon-wrapper rounded">
                                    <div className="icon-wrapper-bg bg-info" />
                                    <i className="pe-7s-wallet text-info" />
                                </div>
                                <div className="widget-numbers text-danger">
                                    <CountUp start={0}
                                        end={Counter.wallet_balance}
                                        separator=""
                                        decimals={0}
                                        decimal=","
                                        prefix="₹"
                                        useEasing={false}
                                        suffix=""
                                        duration="10" />
                                </div>
                                <div
                                    className="widget-subheading fsize-1 pt-2 opacity-10 text-info font-weight-bold">
                                    Balance
                                    </div>
                                {/* <div className="widget-description opacity-8">
                                        Down by
                                        <span className="text-success pl-1">
                                                <FontAwesomeIcon icon={faAngleDown}/>
                                                <span className="pl-1">21.8%</span>
                                            </span>
                                    </div> */}
                            </div>
                            <div className="widget-chart-wrapper">
                                {/* <ResponsiveContainer width='100%' aspect={3.0 / 1.0}>
                                        <AreaChart data={data123}
                                                   margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <Area type='stepAfter' dataKey='uv' strokeWidth='2' stroke='var(--info)'
                                                  fill='var(--info)' fillOpacity='.4'/>
                                        </AreaChart>
                                    </ResponsiveContainer> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            <Row>
                {SchoolSliderData?.length > 2 &&
                    <Col md="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Recently Added Schools</CardTitle>
                                <Slider sdata={SchoolSliderData} school={true} handleDetailsClick={handleSchoolClick} />
                            </CardBody>
                            <CardFooter className="text-center d-block p-3">
                                <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg"
                                    onClick={() => { history.push('dashboard/schools') }}
                                >
                                    <Row>

                                        <span className="mr-1">

                                            View All Schools
                                </span>

                                    </Row>

                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>}
                <Col md="12">
                    {StudentSliderData.length > 2 &&
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle>Recently Added Students</CardTitle>
                                <Slider sdata={StudentSliderData} school={false} handleDetailsClick={handleStudentClick} />

                            </CardBody>
                            <CardFooter className="text-center d-block p-3">
                                <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg"
                                    onClick={() => { history.push('dashboard/studentlist') }}
                                >
                                    <Row>

                                        <span className="mr-1">

                                            View All Students
                                </span>

                                    </Row>

                                </Button>
                            </CardFooter>
                        </Card>}
                </Col>
            </Row>
        </div>
    )
}

export default Index
