import React from 'react'
import { Col, Card, Row } from 'reactstrap'
import user from '../../assets/utils/images/myicon/user.png'
const StudentPreviewCard = (props) => {
    const { data, remove } = props;
    return (
        <Col md={4}>
            <Card className="student-preview-card">
                <strong className="student-card-close" onClick={() => remove(data.student_id)} style={{ textAlign: 'end' }}>X</strong>
                <Row>
                    <Col md={4}><img src={user} width={50} /></Col>
                    <Col md={8}>
                        <strong>{` ${data.first_name}  ${data.last_name}`}</strong>
                        <p style={{ color: 'gray', fontSize: '13px' }}>{data.grade_name}</p>

                    </Col>
                </Row>
            </Card>
        </Col>
    )
}

export default StudentPreviewCard
