import React,{useEffect,useState} from 'react'
import Swal from 'sweetalert2'
import DataTable from '../../../../common/DataTable'
import {deleteLiveClass}from '../../../../../redux/Actions/vendor_course'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { 
    // deleteClasses, validateClassTiming, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, 
    populateAllEnterpriseUserClass, getTeacherAssignedGroups,setTeacherData
    // populateAllUserClassTeacher 
} from '../../../../../redux/Actions/homeTeacher'
import Modals from '../../../../common/Modal'
import GroupList from './GroupList'
import SweetAlert from 'sweetalert-react';
function Index(props) {
    
    const history = useHistory()
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const {id, type }= props

    const [groupAssignModal, setgroupAssignModal] = useState(false)

    useEffect(() => {
        dispatch(populateAllEnterpriseUserClass(tokenData.token,id,type))
    }, [])

    const  columns = [
        { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
        // { title: 'Teacher ', field: 'teacher_name', headerStyle: { fontWeight: '600' } },
        { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
        { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
        { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
        { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

    ]

    const handleDelete = (class_id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',
        
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
        
        }).then((result) => {
            if (result.value) {
                
                dispatch(deleteLiveClass(tokenData.token,class_id,cb))
        
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
        
    }

    const handleEdit = (id, class_id) => {
        //alert(classId)

        history.push(`/dashboard/editSubscription/${props.id}/editClass/${class_id}`)
    }
     
    const cb = () =>{
        dispatch(populateAllEnterpriseUserClass(tokenData.token,id,type))
    }
   const [ClassId, setClassId] = useState(null)
    
   const handleAssign = (class_id) => {
        dispatch(getTeacherAssignedGroups(tokenData.token, class_id,""))
        setClassId(class_id)
        setOpenStdModal(false)
        setgroupAssignModal(true)
    }

    const closeAssign=()=>{
        dispatch(setTeacherData('specificGroup',[]))
        setClassId('')
        setOpenStdModal(false)
        setgroupAssignModal(false)
    }
    const [openStdModal, setOpenStdModal] = useState(false)

    const handleCloseStudentModal2 = () => {
        dispatch(setTeacherData('specificGroup',[]))
        setClassId('')
        setgroupAssignModal(false)
        setOpenStdModal(true)
    }
    return (
        <div>
             <DataTable     
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleAssign={handleAssign}   
              columns={columns}
              actions={'assign_student_subscription'}
                // role={}
                // columns={}        
                data={tStore.enterpriseClasses}
                // loader={}
            />
        <Modals
            open={groupAssignModal}
            handleClose={()=>closeAssign()}
            title={`Assign Groups`}
        >
            <GroupList
                class_id={ClassId}
                data={tStore.specificGroup}
                loader={tStore.specificGroupLoader}
                close={closeAssign}
                CloseSuccess={handleCloseStudentModal2}
            />
          
        </Modals>
        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={openStdModal}
            text="Completed successfully"
            type="success"
            onConfirm={() => setOpenStdModal(false)} />

        </div>
    )
}

export default Index
