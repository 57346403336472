import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/store'
import * as serviceWorker from './serviceWorker';

import { HashRouter,BrowserRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Pages/Main';
import { Provider } from 'react-redux';


const rootElement = document.getElementById('root');
const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter >
                <Component />
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);



// ReactDOM.render(
//     <Provider store={store}>
//         <BrowserRouter>
//             <Main />
//         </BrowserRouter>
//     </Provider>,
//     document.getElementById('root')
//   );
serviceWorker.unregister();

