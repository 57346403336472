import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ReactHtmlParser from 'react-html-parser';
// import moment from 'moment'
import { useParams } from 'react-router-dom'
import Header from '../header'
import classnames from 'classnames';
// import UploadVideo from './uploadVideos'
// import ChooseVideos from './chooseVideos'
// import ClassList from '../Common/classDateTime'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import Switchy from "react-switch";
// import cx from 'classnames';
import Modals from '../../../common/Modal'
// import Thumbnile from '../../../common/Thumbnile/video'
import VideoCard from '../../../common/VideoCard'
import { Progress } from 'react-sweet-progress';
import serialize from 'form-serialize'
import spinner from '../../../../assets/animated/Spinner.gif'
import { requiredValidator, videoValidator } from '../../../../utilities/Validator'

import {
    Row, Col,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody,
    CardHeader, Input, Button, FormGroup, Label, FormFeedback

} from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { CameraTagKey } from '../../../../config.json';
import { uploadVideo, getPlayListDetails, addVideosFromPlaylist, deletePlaylistVideos, updatePlaylistVideos } from '../../../../redux/Actions/playlist'
import processing_thumnile from "../../../../assets/images/processing_thumnile.jpg"
import ReactPlayer from 'react-player'
import '../playlist.scss'
const formData = new FormData();
const formData2 = new FormData();

let CameraTag = undefined
const Details = () => {

    const params = useParams()
    // const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const playlist = useSelector(({ playlist }) => playlist)
    const tokenData = JSON.parse(localStorage.getItem('authDetails'))
    const dispatch = useDispatch()
    const [uuid, setUuid] = useState('')
    const [cameratag_file, setCameraTagFile] = useState('')
    const [uploadFile, setUploadFile] = useState(false)
    const [loading, setLoading] = useState(false)
    const [controll, setControll] = useState(false)

    useEffect(() => {
        dispatch(getPlayListDetails(tokenData.token, params.playlist_id))
    }, [])

    useEffect(() => {
        if (uploadFile) {
            CameraTag = window.CameraTag
            CameraTag.setup()
        }
    }, [uploadFile])

    useEffect(() => {
        if (uuid && controll) {
            setControll(false)
            // CameraTag.cameras['DemoCamera'].destroy()
            SubmitWithUuid()
        }
    }, [uuid])

    if (CameraTag && CameraTag !== undefined && !uuid) {
        CameraTag.observe('DemoCamera', 'published', function () {
            let myCam = CameraTag.cameras["DemoCamera"];
            let myVideo = myCam.getVideo();
            setUuid(myVideo.uuid)
        });
    }
    if (CameraTag && CameraTag !== undefined && !cameratag_file) {
        CameraTag.observe('DemoCamera', 'uploadFileSelected', function (file) {
            setCameraTagFile(file)
        });
    }

    const [isToggleOn, setIsToggleOn] = useState(false)
    // const handleClick = () => {
    //     if (isToggleOn) {
    //         setMultiSelect([])
    //     }
    //     setIsToggleOn(!isToggleOn)
    // }
    const [state, setState] = useState({
        activeTab: '1',
        showMore: true,
        transform: true,
        showInkBar: true,

        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }

    // const [classList, setClassList] = useState([{ class_id: 2, Title: 'Class One', subject: 'Mathametics', Grade: 9, selected: false }, { class_id: 3, Title: 'Test class', subject: 'Mathametics', Grade: 9, selected: false }, { class_id: 23, Title: 'Historical Theorm', subject: 'Mathametics', Grade: 9, selected: false }, { class_id: 32, Title: 'Class Algorithm', subject: 'Mathametics', Grade: 9, selected: false }, { class_id: 62, Title: 'Class Intro', subject: 'Mathametics', Grade: 9, selected: false }])

    const [mutliSelect, setMultiSelect] = useState([])
    // const handleMultiSelect = (class_id) => {


    //     if (mutliSelect.includes(class_id)) {
    //         // debugger
    //         const filtered = mutliSelect.filter(classId => classId != class_id)
    //         console.log("after removed", filtered)
    //         setMultiSelect(filtered)

    //     } else {
    //         setMultiSelect([...mutliSelect, class_id])
    //     }

    // }
    const [inputField, setInputField] = useState({
        title: '',
        video: ''
    })

    const handleChange = (e) => {

        const { files, name, value } = e.target
        // formData.set('playlist_id', params.playlist_id)
        if (name === "video") {
            formData.append('video', e.target.files[0])
            // formData.set('playlist_id', params.playlist_id)
            setInputField({ ...inputField, [name]: files[0], fileName: value })
        } else {
            setInputField({ ...inputField, [name]: value })

        }
        if (name === "poster") {
            formData.append('poster', e.target.files[0])

            setInputField({ ...inputField, [name]: files[0] })
        }


    }


    const [playlistsVideoId, setPlaylistsVideoId] = useState([])
    const [errors, setErrors] = useState({})

    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        formData2.delete('playlist_videos')
        formData2.delete('classes')
        formData2.delete('playlist_id')

        formData.delete('title')
        formData.delete('classes')

        formData.append('title', body.title)
        formData.append('playlist_id', params.playlist_id)

        var details = JSON.stringify(body.classes);
        formData.append('classes', details);
        var videos = JSON.stringify(body.videos);
        formData2.append('playlist_videos', videos)
        formData2.append('playlist_id', params.playlist_id)
        let error;
        if (body.isType === "UPLOAD") {
            error = {
                title: requiredValidator(inputField.title),
                // video: videoValidator(inputField.fileName),
                video: videoValidator(cameratag_file ? cameratag_file.name : ''),
            }
            if (!error.title && !error.video) {
                setErrors(error)
                // dispatch(uploadVideo(tokenData.token, formData, resetForm, deleteForm))
                setControll(true)
                setLoading(true)
                UploadFn()
            } else {
                setErrors(error)
            }
        }
        else {

            if (isToggleOn) {
                // body.classes.playlist_video = mutliSelect2
                // body.classes.playlist_video_id = playlistsVideoId
                if (!body.classes && !body.videos) {
                    //  alert("select class and video")
                    Swal.fire({
                        icon: 'warning',
                        title: 'Required',
                        text: "select class and video"

                    })
                } else if (!body.videos) {
                    // alert("select  videos")
                    Swal.fire({
                        icon: 'warning',
                        title: 'Required',
                        text: "select  videos"

                    })

                }
                else if (!body.classes) {
                    //alert("select  classes")
                    Swal.fire({
                        icon: 'warning',
                        title: 'Required',
                        text: "select  classes"

                    })
                }
                else {
                    body.classes.playlist_video_id = body.videos.playlist_video_id
                    body.classes.video_id = body.videos.playlist_video
                    body.classes.title = body.videos.title

                    let details = JSON.stringify(body.classes);
                    formData2.append('classes', details);

                    dispatch(addVideosFromPlaylist(tokenData.token, formData2, resetForm, deleteForm))

                }
            } else {
                // error = {
                //     title: requiredValidator(playlist.title),
                //     video: videoValidator(inputField.fileName),
                // }
                const filterData = playlist.playlistVideos.filter(row => row.title === '')
                const filterTitle = filterData.filter(row => {
                    return playlistsVideoId.indexOf(row.playlist_video_id) !== -1

                })

                if (!body.videos) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Required',
                        text: "select  videos"
                    })

                } else if (filterData && filterTitle.length > 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Required',
                        text: "Fill the title field"
                    })
                }
                else {
                    dispatch(addVideosFromPlaylist(tokenData.token, formData2, resetForm, deleteForm))
                }
            }
        }
    }

    const UploadFn = () => {
        let myCam = CameraTag.cameras["DemoCamera"];
        myCam.startUpload()
    }

    const SubmitWithUuid = async () => {
        await CameraTag.cameras['DemoCamera'].destroy()
        CameraTag = undefined
        setLoading(false)
        formData.append('uuid', uuid)
        dispatch(uploadVideo(tokenData.token, formData, resetForm, deleteForm))
    }

    const resetForm = () => {
        dispatch(getPlayListDetails(tokenData.token, params.playlist_id))

        setUploadFile(false)
        setCameraTagFile('')
        setUuid('')
        setMultiSelect([])
        deleteForm()
        toggle('1')
        setInputField({
            title: '',
            video: ''
        })
        document.getElementById("video-upload").reset();
        if(CameraTag){
            CameraTag.cameras['DemoCamera'].destroy()
        }
    }

    const deleteForm = () => {
        formData2.delete('classes')
        formData2.delete('videos')
        formData2.delete('playlist_videos')
        formData2.delete('playlist_id')
        formData.delete('title')
        formData.delete('classes')
        formData.delete('video')

    }


    const deleteVideo = (video_id) => {
        if (!video_id) {
            Swal.fire({
                icon: 'warning',
                title: 'Video under processing',
                text: 'please try later',

            })
            setEditMode(false)

        }
        else {
            Swal.fire({
                icon: 'warning',
                // title: "Format not supported",
                text: "Are you sure,do you want to delete ",
                confirmButtonText: 'Yes',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',


            }).then((result) => {
                if (result.value) {
                    dispatch(deletePlaylistVideos(tokenData.token, video_id, params.playlist_id))

                }

            })
        }
    }
    const [editMode, setEditMode] = useState(false)
    const [videoDetails, setVideoDetails] = useState('')
    const openEdit = (video_id, video_name) => {
        // debugger
        setVideoDetails({ video_id: video_id, video_name: video_name })
        setEditMode(true)
    }
    const closeEditModel = () => {
        setEditMode(false)

    }
    // const [videoName,setVideo]

    const handleChangeTitle = (e) => {

        setVideoDetails({ ...videoDetails, video_name: e.target.value })
    }
    const resetSelectedVideos = () => {
        setPlaylistsVideoId([])
    }
    const [updating, setUpdating] = useState([])
    const updateVideo = (video_id) => {
        if (!videoDetails.video_name) {
            Swal.fire({
                icon: 'error',
                title: 'Required',
                text: 'Video name  required',

            })
        } else {
            if (!video_id) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Video under processing',
                    text: 'please try later',

                })
                // setVideoTitle(title)
                setEditMode(false)

            } else {

                // setUpdating([...updating, video_id])
                dispatch(updatePlaylistVideos(tokenData.token, video_id, params.playlist_id, videoDetails.video_name, updated))

            }
        }

    }
    const cancelUpdate = (video_id) => {
        setEditMode(false)
        // setVideoTitle(title)
        const removeId = updating.filter(id => id !== video_id)
        setUpdating(removeId)

    }
    const updated = (title_name, video_id) => {

        // setVideoDetails({ ...videoDetails, video_name: title_name })
        setEditMode(false)
        // const removeId = updating.filter(id => id != video_id)
        // setUpdating(removeId)

    }
    const [nowPlaying, setNowPlaying] = useState({ open: false, iframe: "<iframe></iframe>" })
    const openVideo = async (player, thumbnile, processing, file_url) => {
        if (processing) {
            Swal.fire({
                icon: 'warning',
                title: 'Processing',
                // text: "Video on process"
            })
        } else {
            setNowPlaying({ open: true, iframe: player, file_url: file_url })
        }
    }

    return (
        <>
            <Header
                heading={playlist.playListDetails ? playlist.playListDetails.playlist_name ? playlist.playListDetails.playlist_name : '' : ''}
                color={'rgb(250, 202, 141)'}
                subject={playlist.playListDetails ? playlist.playListDetails.subject_name ? playlist.playListDetails.subject_name : '' : ''}
                deadline={playlist.playListDetails ? playlist.playListDetails.grade_name ? playlist.playListDetails.grade_name : '' : ''}
                icon="pe-7s-video icon-gradient bg-sunny-morning"
                // buttonAction={downloadTeacherFile}
                background={'#292961'}
                skelton={playlist.playListDetailsLoader}
                // assignmentStore={assignmentStore}
                //lastDataColor={'rgb(180, 193, 189)'}
                grade={playlist.playListDetails ? playlist.playListDetails.grade_name ? playlist.playListDetails.grade_name : '' : ''}
                button={'DELETE'}
                type="hide"
                buttonColor={'#fa6c27'}
                buttonFontColor={'white'}

            />
            {uploadFile &&

                <form onSubmit={formSubmit} id="video-upload" enctype="multipart/form-data">

                    <Card tabs={true} className="mb-3 jms-palylist-details-cards">
                        <CardHeader>
                            <Nav justified>
                                <NavItem>
                                    <NavLink href="#"
                                        className={classnames({ active: state.activeTab === '1' })}
                                        onClick={() => {
                                            toggle('1');
                                        }}
                                    >
                                        UPLOAD VIDEOS
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink href="#"
                                        className={classnames({ active: state.activeTab === '2' })}
                                        onClick={() => {
                                            toggle('2');
                                        }}
                                    >
                                        CHOOSE VIDEO FROM PLAYLIST
                                                </NavLink>
                                </NavItem> */}


                            </Nav>
                        </CardHeader>
                        <CardBody>
                            <TabContent activeTab={state.activeTab}>

                                <TabPane tabId="1">
                                    {state.activeTab === '1' ?
                                        // <UploadVideo
                                        //     handleChange={handleChange}
                                        //     errors={errors}

                                        // />
                                        <Row >
                                            <Col md={12}>
                                                <Input type="hidden" name="isType" value={"UPLOAD"} />

                                                <FormGroup>
                                                    <Label for="exampleCustomSelectDisabled">*Title</Label>
                                                    <Input type="text" name="title" onChange={handleChange}
                                                        invalid={errors.title}
                                                    />
                                                    <FormFeedback >{errors.title}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="exampleCustomSelectDisabled">*Choose videos<span class='text-danger'>{errors.video}</span></Label>
                                                    {/* <Input
                                                        invalid={errors.video}
                                                        type="file" id="fup" name="video" accept="video/mp4,video/x-m4v,video/*"
                                                        style={{ lineHeight: '32px' }} onChange={handleChange} /> */}
                                                    <div>
                                                        <camera style={{ height: 'auto', width: '100%', background: '#f8fbff', fontSize: 'unset' }} id='DemoCamera' data-app-id={CameraTagKey.camera_tag_id} data-sources='upload' data-upload-on-select='false'></camera>
                                                        <div id="DemoCamera-start-screen">
                                                            <div class="cameratag_upload button_wrapper">
                                                                <button type='button' class="cameratag_upload_button">Choose file</button>
                                                                <div style={{ width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
                                                                    {
                                                                        cameratag_file ?
                                                                            <span className="camera_tag_span">{cameratag_file.name}</span>
                                                                            :
                                                                            <span className="camera_tag_span">No file chosen</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </FormGroup>

                                            </Col>
                                            <Col md={3}></Col>
                                        </Row>
                                        : null
                                    }

                                </TabPane>
                                {/* <TabPane tabId="2">
                                    {
                                        state.activeTab == 2 ?
                                            <ChooseVideos
                                                resetSelectedVideos={resetSelectedVideos}
                                                errors={errors}
                                                syncVideo={syncVideo}
                                                handleClickSync={handleClickSync}
                                                mutliSelect={mutliSelect2}
                                                setMultiSelect2={setMultiSelect2}
                                                multiSelectVideo={multiSelectVideo}
                                                playlistsVideoId={playlistsVideoId}
                                                subject_id={params.subject_id !== 'undefined' ? params.subject_id : null}
                                                course_id={params.course_id}
                                            /> : null
                                    }

                                </TabPane> */}
                                <hr />

                                {/* {playlist.playListDetails ? playlist.playListDetails.classes.length > 0 ?

                                    <Row>
                                        <Col md={12}>

                                            <Row>
                                                <Col md={10}>
                                                    <p style={{ color: "#fa6b27", paddingTop: 2 }}>{` This playlist already attached with some classes, Do you want to sync new videos to the classes?`}

                                                    </p>
                                                </Col>
                                                <Col md={2} style={{ textAlign: 'end' }}>
                                                    <div className="switch has-switch mr-2 mb-2 mr-2" data-on-label="Yes"
                                                        data-off-label="No"
                                                        onClick={handleClick}>
                                                        <div className={cx("switch-animate", {
                                                            'switch-on': isToggleOn,
                                                            'switch-off': !isToggleOn
                                                        })}>
                                                            <input type="checkbox" /><span
                                                                className="switch-left bg-warning">Yes</span><label>&nbsp;</label><span
                                                                    className="switch-right bg-warning">No</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                    : null : null} */}

                            </TabContent>
                        </CardBody>
                    </Card>

                    {/* {isToggleOn ?
                        <ClassList
                            classList={playlist.playListDetails.classes}
                            handleMultiSelect={handleMultiSelect}
                            mutliSelect={mutliSelect}
                            handleDate={handleDate}

                        /> : null} */}
                    <hr />

                    <Row style={{ paddingTop: '20px' }}>
                        <Col md={12} style={{ paddingBottom: 20 }}>
                            {

                                playlist.uploadProgress != '' ?
                                    <>
                                        <strong style={{ color: 'rgb(19, 140, 228)' }}>Uploading Files...</strong>
                                        <Progress
                                            percent={playlist.uploadProgress}
                                        />

                                    </> : null

                            }
                        </Col>
                        <Col md={3}>
                            <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                loading={playlist.uploadVideoButtonLoader || loading}
                                // onClick={formSubmit}
                                id="submitButton"
                                type="submit"
                                data-style={ZOOM_IN}
                                style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available' }}

                            >
                                UPLOAD
                            </LaddaButton>
                        </Col>

                    </Row>
                </form>
            }

            <h6 style={{ paddingTop: 60, fontWeight: 700 }}>VIDEOS</h6>
            <hr />
            <Row style={{ background: 'rgb(213, 216, 220)', padding: '24px 9px' }}>
                {
                    playlist.playListDetails ? playlist.playListDetails.vidoes.map((video, index) => {
                        return (
                            <Col xs={12} sm={6} md={3} key={index}>
                                {/* <Thumbnile
                                    title={video.title}
                                    size={'11 MB'}
                                    format={'Mp4'}
                                /> */}
                                <VideoCard
                                    type="playlist"
                                    title={video.title}
                                    thumbnile={video.processing ? processing_thumnile : video.camera_tag_thumbnail_path ? video.camera_tag_thumbnail_path : video.thumbnails[2].link}
                                    duration={video.video_duration}
                                    video_id={video.video_id}
                                    deleteVideo={deleteVideo}
                                    openEdit={openEdit}
                                    openVideo={openVideo}
                                    embed={video.embed}
                                    processing={video.processing}
                                    file_url={video.file_url}
                                />
                            </Col>
                        )
                    }) : null
                }
                {
                    playlist.playListDetails ?
                        playlist.playListDetails.vidoes.length == 0 ?
                            <div className=" font-icon-lg" style={{ margin: 'auto' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <i style={{ fontSize: 40, paddingLeft: 15 }} className="lnr-warning icon-gradient bg-sunny-morning"> </i>
                                </div>
                                <div>
                                    <p>Videos not uploaded</p>
                                </div>

                            </div>
                            : null
                        : null
                }


                <Col xs={12} sm={6} md={3} >
                    <div className="jms-choose-from-playlist-thumb-card" style={{ background: `gray`, display: 'flex', justifyContent: 'center' }}

                    >
                        <Button style={{ position: 'relative', height: '34px', margin: ' -20px -50px', top: '50%' }} onClick={() => setUploadFile(true)}>Add video +</Button>
                    </div>
                </Col>

            </Row>
            <Modals
                open={editMode}
                handleClose={closeEditModel}
                size='sm'
                title="Edit "
            >
                <div style={{ padding: 15 }}>
                    <p style={{ marginBottom: 5 }}> Title</p>
                    {/* <hr /> */}
                    <Input style={{ height: 32 }} type="text" onChange={handleChangeTitle} name="title" value={videoDetails.video_name} />
                    <Row style={{ paddingTop: 10 }}>

                        <Col md={6}>
                            <Button onClick={() => cancelUpdate()} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="danger" >Cancel</Button>

                        </Col>
                        <Col md={6}>
                            <Button disabled={updating.includes(videoDetails.video_id)} onClick={() => updateVideo(videoDetails.video_id)} style={{ height: '19px', padding: '0px', width: '-webkit-fill-available', fontSize: '10px' }} type="button" color="success">{"Save"}</Button>
                        </Col>
                    </Row>
                </div>

            </Modals>

            <Modals
                open={nowPlaying.open}
                handleClose={() => setNowPlaying({ open: false, iframe: '', file_url: '' })}
                size='md'
            >
                <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    {
                        nowPlaying.file_url ?
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    url={nowPlaying.file_url}
                                    controls={true}
                                />
                            </div>
                            :
                            ReactHtmlParser(nowPlaying.iframe)
                    }
                </div>



            </Modals>
        </>

    )
}

export default Details
