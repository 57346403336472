import React,{useState} from 'react'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    CardHeader,
} from "reactstrap";import 

classnames from "classnames";
import{useDispatch,}from 'react-redux';
import PlayList from './PlayList';
import VideoSession from './VideoSession';
import { populatePlayList, } from '../../../../../redux/Actions/playlist'
import { useParams } from 'react-router-dom'

function Index() {

    const dispatch = useDispatch();
    const params = useParams()

    const [activeTab, setactiveTab] = useState("1")
    const tokenData = JSON.parse(localStorage.getItem('authDetails'))
   

    const toggle = (tab) => {
        dispatch(populatePlayList(tokenData.token,"",params.subscription_id))
        if (activeTab !== tab) {
            if(tab === "2")
            {
                // dispatch(populatePlayList(tokenData.token, params.course_id))
                setactiveTab(tab)
                // dispatch(getVendorPreviewDetailsForVendor(tokenData.token))
            }else{
                setactiveTab(tab)
            }

        }
    }

    return (
        <div>
            <Card tabs={true} className="mb-3">
                <CardHeader>
                    <Nav justified>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => {
                                    toggle("1");
                                }}
                            >
                               Playlist
                </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                    toggle("2");
                                }}
                            >
                                Create Video Session
                </NavLink>
                        </NavItem>

                    </Nav>
                </CardHeader>
               
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                           
                           <PlayList />
                        </TabPane>
                        <TabPane tabId="2">
                           <VideoSession />
                        </TabPane>
                    </TabContent>
               
            </Card>
        </div>
    )
}

export default Index
