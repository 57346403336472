import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authCheck, } from '../../redux/Actions/auth'
import { LogoutSession } from "../../redux/Actions/genexhome"

import Swal from 'sweetalert2'
const AuthWrapper = ({ children }) => {
    const dispatch = useDispatch();

    const history = useHistory()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [authebticated, setAuthenticated] = useState(false)
    const role=tokenData ? tokenData.role ? tokenData.role:'':''
    const token=tokenData ? tokenData.token ? tokenData.token:'':''
    useEffect(() => {

        if (!tokenData) {
            history.push('/login')
        } else {
            dispatch(authCheck(tokenData.token, cb, authSuccess))

        }
    }, []);

    const authSuccess = () => {
        setAuthenticated(true)
    }


    const cb = () => {

        dispatch(LogoutSession(token))
        localStorage.removeItem("authDetails");
        if(role==='superAdmin'){
            history.push('/admin')
        }
        else{
            history.push('/login')
        }
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
    }
    const checkAuth = () => {
        if (!tokenData) {
            history.push('/login')
        } else {
            return children

        }

    }
    return (
        <>
            {/* {checkAuth()} */}
            {authebticated && checkAuth()}
        </>
    )
}
export default AuthWrapper