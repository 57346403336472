import React, { useEffect,useState,Fragment,useRef } from 'react';
import { useHistory,useParams } from "react-router-dom";
import { useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import {
    Card, Row, Col,CardHeader,

} from 'reactstrap';
import Videocard from '../../../../common/VideoCard'
import PageTitle from "../../../../HeaderTitle/PageTitle";
import './videolist.css'

import { getVideoList, validateVideoClass,getVideoListForPercentage,getVideoList2 } from '../../../../../redux/Actions/videolist'
import { useDispatch, } from 'react-redux'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { setReqData,addOrUpdateAnalatics,addOrUpdateAnalatics2 } from '../../../../../redux/Actions/Superadmin'
import { Progress } from 'reactstrap';
import Swal from "sweetalert2";
import ReactHtmlParser from 'react-html-parser';
import { getAllStudentCourse } from "../../../../../redux/Actions/Student"

// import { Button } from 'reactstrap/lib';

const stylebutton={padding:' 6px 35px',border:'1px solid #908484',background:'#04134c',color:'white',borderRadius:'5px'}
function Videolist(){

    const history=useHistory();
    const [dur, setDur] = useState(0);
  
    const [viewed, setViewed] = useState(0);
    const [seekval, setSeek] = useState(0);

    const params = useParams()
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  
    const ref = useRef(null);
    const val = useSelector((state) =>state.superadmin && state.superadmin);
    const percent = useSelector((state) =>state.superadmin.percentvideo ? state.superadmin.percentvideo : 0);
    const videos_of_class = useSelector((state) =>state.superadmin.videos_of_class ? state.superadmin.videos_of_class : []);
    const watched_duration = useSelector((state) =>state.superadmin.watched_duration ? state.superadmin.watched_duration : 0);
    // const current_video__id = useSelector((state) =>state.superadmin.current_video__id ? state.superadmin.current_video__id : '');
    
    const StudentCourseArray = useSelector((state) =>state.student.StudentCourseArray ? state.student.StudentCourseArray : '');
    
    
    const tStore = useSelector(({ videolist }) => videolist)
    var videoDetail = tStore.videosListDetail;

    const [count, setCount] = useState(0);
    const [videoId, setVideoId] = useState()

    const [openmodel, setOpenmodel] = useState(false)

    useEffect(()=>{
        dispatch(getAllStudentCourse(tokenData.token))
    },[])


    useEffect(()=>{
        dispatch(setReqData('percentvideo',0))
        // dispatch(setReqData('current_video__id',''))
        if(params.video_id){
            dispatch(getVideoList(tokenData.token, params.video_id,'subscription',callIframe, empty))
        }
        else{
            //////////params.course_id for course////////
            dispatch(getVideoList(tokenData.token, params.course_id,'',callIframe, empty))
        }
        return()=>{
            // dispatch(addOrUpdateAnalatics2(tokenData.token,current_video__id,percent,watched_duration))
            dispatch(setReqData('prevent_idle_timeout',false))
            
        }    
    },[])



    const [allVideos, setAllVideos] = useState([])
    const empty = () => {
        history.goBack()
    }
    const callIframe = (video_id, data) => {
        if(!data.playable){
            Swal.fire({
                icon: "warning",
                title: "can't access",
                text: "complete above classes to access this class",
            })
            history.goBack()
        }
            setVideoId(data.videos[0].video_class_id)
            // dispatch(setReqData('current_video__id',data.videos[0].video_class_id))
            dispatch(setReqData('percentvideo',data.videos[0] ? data.videos[0].percentage:0))
            setSeek(data.videos[0] ? data.videos[0].video_stopped_at ?  parseInt(data.videos[0].video_stopped_at):0:0)

            setViewed(data.videos[0] ? data.videos[0].percentage!==null ? data.videos[0].percentage:0:0)
            const videoUrls = data.videos.map(video => {
                if(video.file_url){
                    return video.file_url
                }else{
                    return video.video_url
                }
        })
        setAllVideos(videoUrls)
        dispatch(setReqData('videos_of_class',data.videos))

    }


    const switchData = (index, id,per,playable,stopedat) => {
        if(videoId!==id){
            if(tokenData.role==='student'){
            
                if(playable){

                    let prev_video=videos_of_class.find((val)=>val.video_class_id===videoId)
                    if(prev_video.video_stopped_at < watched_duration){
                        dispatch(addOrUpdateAnalatics2(tokenData.token,videoId,parseInt(percent) > 100 ? 100:parseInt(percent),parseInt(watched_duration)))
                    }
                    if(params.video_id){
                        dispatch(getVideoList2(tokenData.token, params.video_id,'subscription'))
                    }
                    else{
                        dispatch(getVideoList2(tokenData.token, params.course_id,''))
                    }
                    setCount(index)
                    setVideoId(id)
                    // dispatch(setReqData('current_video__id',id))
    
                    if(per){
                        setViewed(per)
                        dispatch(setReqData('percentvideo',per))
                        
                    }
                    else{
                        setViewed(0)
                        dispatch(setReqData('percentvideo',0))
                    }
                    if(stopedat){
                        setSeek(stopedat)
                        dispatch(setReqData('watched_duration',stopedat))
                    }
                    else{
                        setSeek(0)
                        dispatch(setReqData('watched_duration',0))
                    }
                }
                else{
                    Swal.fire({
                        icon: "warning",
                        title: "can't play",
                        text: "complete above videos to access this",
                    })
                }
            }
            else{
                setCount(index)
                setVideoId(id)
                // dispatch(setReqData('current_video__id',id))
    
                if(per===null){
                    setViewed(0)
                    dispatch(setReqData('percentvideo',0))
                }
                else{
                    setViewed(per)
                    dispatch(setReqData('percentvideo',per))
                }
            }
        }
    }
    

    const [show, setShow] = useState({ playing: false })
    const [validatedVideo, setValidatedVideo] = useState('')
    const ply = (video_class_id,item) => {
        setShow({ playing: true })
        if (validatedVideo !== video_class_id) {
                setShow({ playing: false })
                dispatch(validateVideoClass(tokenData.token, videoId, params.course_id ? params.course_id:params.video_id, videoApproved))
        }

    }
    const PlayVideo=()=>{
        setShow({ playing: true })
    }
    const videoApproved = (video_class_id) => {
        dispatch(setReqData('prevent_idle_timeout',true))
        // if(params.video_id){
        //     dispatch(getVideoListForPercentage(tokenData.token, params.video_id,'subscription'))
        // }
        // else{
        //     //////////for course////////
        //     dispatch(getVideoListForPercentage(tokenData.token, params.course_id,'',))
        // }
        if(seekval!==0){
            setOpenmodel(true)
        }
        // setShow({ playing: true })
        setValidatedVideo(video_class_id)
        setTimeout(function () { setValidatedVideo('') }, 300000);

    }
    
    const Reset=()=>{
        setOpenmodel(false)
        setSeek(0)
        dispatch(setReqData('watched_duration',0))
        ref.current.seekTo(0)
        setShow({ playing: true })
    }
   
    const Continue=()=>{
        setOpenmodel(false)
        setShow({ playing: true })
    }

    // const handleResume = () => {
    //     ref.current.seekTo(parseInt(seekval))  // give seconds or fraction as parameter to resume from left off time
    //   }

    const videolisting = (videoDetail) => {
        var videos = videoDetail.videos && videoDetail.videos.length > 0 ? videoDetail.videos : '';
        return videos && videos.map((item, index) => {
            return (
                
                <Row key={index} onClick={() => switchData(index,item.video_class_id,item.percentage,item.playable,item.video_stopped_at)}>
                    <Col lg={12} md={12} sm={12} xs={12}>

                        <Videocard
                            thumbnile={item.camera_tag_thumbnail_path ? item.camera_tag_thumbnail_path:item.thumbnails[2].link}
                            title={item.video_title}
                            duration={item.video_duration}
                            type="player"
                            data={item}
                            percent={item.percentage ? item.percentage:0}
                            desc={item.video_class_description ? item.video_class_description:'' }
                            check_progress={tokenData.role==='student' ? true :false}
                            playing={count===index ? true :false}
                        />
                    </Col>
                </Row>
                )
            })
        }
    

        const Duration=(duration)=>{
            setDur(duration)
            ref.current.seekTo(parseInt(seekval))
        }

        const handleProgress = (state) => {
            let per=(state.playedSeconds/dur)*100 ? (state.playedSeconds/dur)*100:0
            if(tokenData.role==='student'){
                if(seekval < state.playedSeconds){
                    dispatch(setReqData('watched_duration',state.playedSeconds))
                }

                if(per > percent){
                    let check_percentage=(state.playedSeconds/dur)*100 ? (state.playedSeconds/dur)*100:0
                    dispatch(setReqData('percentvideo',check_percentage))

                    if (check_percentage >= 10 && check_percentage <=13) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,10,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if (check_percentage >= 20 && check_percentage <=23) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,20,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    } 
                    if(check_percentage >= 30 && check_percentage <=33) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,30,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if(check_percentage >= 40 && check_percentage <=43) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,40,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if(check_percentage >= 50 && check_percentage <=53) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,50,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if(check_percentage >= 60 && check_percentage <=63) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,60,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if(check_percentage >= 70 && check_percentage <=73) {
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,70,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if(check_percentage >= 80 && check_percentage <=83){
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,80,cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                    if(check_percentage >= 90 && check_percentage <=95){
                        dispatch(addOrUpdateAnalatics(tokenData.token,videoId,parseInt(check_percentage),cb,seekval < state.playedSeconds ? parseInt(state.playedSeconds) : seekval))
                    }
                }
            }
            else{
                return null
            }
                        
        }
    
    const handleEnded=()=>{
        if(tokenData.role==='student'){
            dispatch(addOrUpdateAnalatics(tokenData.token,videoId,100,cb,dur))
            dispatch(setReqData('percentvideo',100))
            setSeek(dur)
            if(params.video_id){
                dispatch(getVideoList(tokenData.token, params.video_id,'subscription',cbdummy,empty))
            }
            else{
                dispatch(getVideoList(tokenData.token, params.course_id,'',cbdummy,empty))
            }
        }
        
    }
      
    const cbdummy=()=>{
        dispatch(setReqData('prevent_idle_timeout',false))
    }
    const cb=(val)=>{
        setViewed(parseInt(val))
    }

  const StopPlay=()=>{
    setShow({ playing: false })
    dispatch(setReqData('prevent_idle_timeout',false))
    // if(watched_duration > seekval){
    //     setSeek(watched_duration)
    //     dispatch(addOrUpdateAnalatics(tokenData.token,videoId,viewed,cb,watched_duration))
    // }
  }

//   const Seek=(e)=>{
//     console.log('seeeek')
//   }

if (videoDetail) {
    return (
        <Fragment>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <div>
                    <PageTitle
                        heading = {videoDetail.class_name}
                        subheading={videoDetail.course_name}
                        icon="lnr-film-play text-info"
                        link= {tokenData.role === "student" ?`/dashboard/viewCourse/${videoDetail.course_id}/${params.order_id }`: tokenData.role === "superAdmin" ? `/dashboard/activecourses/course/${videoDetail.course_id}`:`/dashboard/showCourse/${videoDetail.course_id}`}
                        course_id = {videoDetail.course_id}
                        // link= {`/dashboard/showCourse/${videoDetail.course_id}`}
                    />

                    <Row>
                        <Col md={8} lg={8} xs={12} sm={12} >
                            <Card className="main-card mb-3" style={{ background: '#ffffff', color: 'black' }}>
                                <div className={'player-wrapper'}>
                                    {
                                        allVideos.map((url, index) =>
                                            count === index ?
                                                (
                                                    <ReactPlayer
                                                        className={"new-width"}
                                                        key={index}
                                                        url={url}
                                                        onPlay={tokenData.role === "student" ? () => ply(videoDetail.videos[count].video_class_id,videoDetail.videos[count]) : PlayVideo}
                                                        onPause={StopPlay}
                                                        playing={show.playing}
                                                        controls={true}
                                                        ref={ref}
                                                        onDuration={Duration}
                                                        onProgress={handleProgress}
                                                        onEnded={handleEnded}
                                                        // onReady={handleResume}
                                                        config={{ file: { 
                                                            attributes: {
                                                              controlsList: 'nodownload'  //<- this is the important bit
                                                            }
                                                        }}}
                                                    />
                                                   
                                                ) :
                                                (<></>)

                                        )
                                    }
                                </div>
                                <div className={openmodel ?'confirm-seek show' :'confirm-seek'}>
                                    <div style={{color:'white',fontSize:'18px'}}>Continue where you left off?</div>
                                    <div style={{display:'flex',justifyContent:'space-between',width:'40%'}}>
                                        <button style={stylebutton} onClick={Continue}>Yes</button>
                                        <button style={stylebutton} onClick={Reset}>No</button>
                                    </div>
                                </div>


                                <div class="vjs-playlist-title-container" style={{ padding: '5px 20px 5px 5px' }}>
                                    <h4 style={{ textTransform: 'capitalize' }} >{videoDetail.videos && videoDetail.videos[count].video_title}</h4>
                                    { tokenData.role==='student'?
                                    <div className='d-flex justify-content-between row mx-0 px-0'>
                                        <div className=' col-lg-6 col-xl-6 col-md-6 col-12' >
                                        {/* {console.log("viewed",viewed,'videoDetail',videoDetail)} */}
                                        <span>Topic Progress : </span><span>{parseInt(viewed)}%</span>
                                        <Progress className="progress-bar-sm mb-3" value={parseInt(viewed)} />
                                        </div>
                                        <div className='col-lg-6 col-xl-6 col-md-6 col-12'>
                                        <span>Module Progress : </span>
                                        <span>{videoDetail.overAllPercentage}%</span>
                                        <Progress className="progress-bar-sm mb-3" value={videoDetail.overAllPercentage} />
                                        </div>
                                    </div>
                                        :''
                                    }
                                    {/* <h4 style={{ textTransform: 'capitalize' }} >{videoDetail.videos && videoDetail.videos[count].video_title}</h4> */}
                                    <h5 style={{ fontWeight: 500, color: 'aquamarine' }} class="vjs-playlist-name" title="Disney's Oceans">{videoDetail && videoDetail.subject_name}</h5>

                                    <span>{videoDetail.videos &&  ReactHtmlParser(videoDetail.videos[count].video_class_description)}</span>
                                </div>

                            </Card>
                        </Col>
                        <Col md={4} lg={4} xs={12} sm={6}>
                            <Card className="main-card mb-3">
                                <CardHeader>Videos({videoDetail && videoDetail.videos_count})</CardHeader>
                                <div className='videolist_div' style={{width:'100%',height:'90vh',overflowY:'scroll',overflowX:'hidden'}}>
                                    {videolisting(videoDetail)}
                                </div>

                            </Card>
                        </Col>
                    </Row>

                </div>
  
            </CSSTransitionGroup>
        </Fragment >
    );
}
else {
    return (
        <div></div>
    )
}
}
export default Videolist