import { combineReducers } from "redux";
import auth from "./Reducers/auth";
import signUp from "./Reducers/signUp";
import homeStudent from "./Reducers/homeStudent";
import homeTeacher from "./Reducers/homeTeacher";
import Reducers from "../reducers/index";
import assignments from "./Reducers/assignments";
import playlist from "./Reducers/playlist";
import Recordings from "./Reducers/Recordings";
import grouping from "./Reducers/grouping";
import videolist from "./Reducers/videolist";
import feedback from "./Reducers/feedback";
import exam from "./Reducers/exam";
import answers from "./Reducers/answers";
import course from "./Reducers/vendor_course";
import genexhome from "./Reducers/genexhomeReducer"

import superadmin from "./Reducers/SuperadminReducer"
import student from "./Reducers/Studentreducer"
import reseller from "./Reducers/reseller"
import school from "./Reducers/school"
import Subscription from "./Reducers/subscriptions"

const rootReducer = combineReducers({ answers, auth, signUp, homeStudent, homeTeacher, playlist, videolist, assignments, Recordings, grouping, exam, feedback, course, superadmin,student,genexhome,reseller,school,Subscription, ...Reducers })

export default rootReducer;
