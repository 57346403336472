import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
// import Ionicon from 'react-ionicons';
import { IoIosGrid, IoIosNotificationsOutline, IoIosAnalytics } from "react-icons/io";
import { notificationMarkedAsRead, getNotification } from '../../../redux/Actions/homeTeacher'
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';

import ChatExample from './TabsContent/ChatExample';


const HeaderDots = (props) => {
    const storeData = useSelector(({ homeTeacher }) => homeTeacher)
    const [state, setstate] = useState({ active: false })
    const [show, setShow] = useState(0)
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));



    const getNotif = () => {
        setShow(storeData.notifications.length > 0 ? show + 1 : null)
        dispatch(getNotification(tokenData.token, cb))


    }
    const cb = () => {
        dispatch(notificationMarkedAsRead(tokenData.token))
    }
    return (
        <Fragment>
            <div className="header-dots">
                
                <UncontrolledDropdown>
                    <DropdownToggle className="p-0 mr-2" color="link">

                        <div className="icon-wrapper icon-wrapper-alt rounded-circle"
                            onClick={() => getNotif()} >
                            <div className="icon-wrapper-bg bg-danger" />
                            <IoIosNotificationsOutline color="#d92550" fontSize="23px" />
                            <div className="badge badge-dot badge-dot-sm badge-danger">Notifications</div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-xl rm-pointers">

                        <div className="dropdown-menu-header mb-0">
                            <div className="dropdown-menu-header-inner bg-deep-blue">
                                <div className="menu-header-image opacity-1"
                                    style={{
                                        backgroundImage: 'url(' + city3 + ')'
                                    }}
                                />
                                <div className="menu-header-content text-dark">
                                    <h5 className="menu-header-title">Notifications</h5>
                                    <h6 className="menu-header-subtitle">You have <b>{storeData.notifications.length}</b> unread messages</h6>
                                </div>
                            </div>
                        </div>
                        <ChatExample
                            data={storeData.notifications}
                        />


                    </DropdownMenu>
                </UncontrolledDropdown>
                
            </div>
        </Fragment >
    )

}

export default HeaderDots;
