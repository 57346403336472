
import React, { useState } from 'react'
import { Col, Row, Input,  } from 'reactstrap'

import './chooseFromPlaylist.scss'
import NumericInput from 'react-numeric-input';
import simpleNumberLocalizer from 'react-widgets-simple-number';


simpleNumberLocalizer()

const UploadVideos = (props) => {
    const { type, title, selectedIdOrigin, index, textInputRef, handleChange, handleTime, handleOrder, handleDate, duration, playlistsVideoId, playlist_video_id, format, id, url, syncVideo, multiSelectVideo, videoId, mutliSelect, thumbnile, handleClickSync,AddDescription,check,dateVideo,palylistDetails,handlePercentage,processing}= props
    const [isToggleOn, setIsToggleOn] = useState(false)

    const [editTitle, setEditTitle] = useState(title)
    const [orderVal, setOrderVal] = useState(1)
    const [perval, setPerVal] = useState(100)

    const onChangeOrder = (val) => {
        handleOrder(`order-${index}`)
        setOrderVal(val)
    }
    const onChangePercentage=(val)=>{
        handlePercentage(`order-${index}`)
        setPerVal(val)
    }
    return (
        <>
            {
                type == "upload_video" ?
                    <>
                        <Col md={4} lg={4} sm={4} xs={4} style={{ marginBottom: 25 }} className="jms-choose-from-playlist-thumb-video-col-3" >
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={playlist_video_id} name="videos[playlist_video_id][]" />
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={videoId} name="videos[playlist_video][]" />


                            <div className="jms-choose-from-playlist-thumb-card" style={{ backgroundImage: `url(${thumbnile})`,backgroundSize:'cover',backgroundRepeat:'no-repeat' }}>
                                <input
                                    style={{ marginLeft: 6 }}
                                    type="checkbox"
                                    id={`id-${id}`}
                                    checked={playlistsVideoId.includes(playlist_video_id)}
                                    value={videoId}
                                    className="jms-choose-from-playlist-thumb-checkbox"
                                    onChange={() => multiSelectVideo(videoId, playlist_video_id)}
                                    name="muliselect"
                                />
                                <div className="jms-choose-from-playlist-thumb-title" > {editTitle}</div>
                                <div className="jms-choose-from-playlist-thumb-size">{duration}</div>
                                
                            </div>
                            <Input type="text" name="videos[title][]"
                                value={title}
                                // onChange={(e) => setEditTitle(e.target.value)}
                                disabled={!playlistsVideoId.includes(playlist_video_id)}
                                placeholder="New title here"
                                onChange={(e) => handleChange(e, id)}
                                style={{ width: '-webkit-fill-available', marginLeft: 8, marginTop: 10 }}
                                invalid={!title}
                            />



                        </Col>
                    </>
                    :
                    <Row style={playlistsVideoId.includes(playlist_video_id) ? { padding: '6px 0px 6px 10px', borderBottom: '1px solid #dedada', background: 'white' } : { background: '#e9ecef', padding: '6px 0px 6px 10px', borderBottom: '1px solid #dedada' }}>

                        <Col md={4} lg={4} sm={12} xs={12} className="jms-choose-from-playlist-thumb-video-col-3" >
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={videoId} name="videos[video_id][]" />
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={playlist_video_id} name="videos[playlist_video_id][]" />
                            <Input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'2021-03-20'} name="videos[video_from_date][]" />
                            <Input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'2021-03-24'} name="videos[video_to_date][]" />

                            <Input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'10:26 am'} name="videos[video_from_time][]" />
                            <Input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={'10:30 am'} name="videos[video_to_time][]" />
                            <Row>
                                <Col md={12} >
                                    <div className="jms-choose-from-playlist-thumb-card" style={{ backgroundImage: `url(${thumbnile})`,backgroundSize:'cover',backgroundRepeat:'no-repeat' }}>
                                        <input type="checkbox" checked={playlistsVideoId.includes(playlist_video_id)} value={videoId}
                                            className="jms-choose-from-playlist-thumb-checkbox"
                                            onClick={() => multiSelectVideo(videoId, playlist_video_id, thumbnile, index,processing)} />
                                        <div className="jms-choose-from-playlist-thumb-title" > {title}</div>
                                        <div className="jms-choose-from-playlist-thumb-size">{duration}</div>
                                        <div className="jms-choose-from-playlist-thumb-format" >{format}</div>
                                    </div>

                                </Col>


                            </Row>
                        </Col>

                        <Col md={8} lg={8} sm={12} xs={12}>
                            
                            
                            <Row style={{ paddingTop: 5 }}>
                                <Col md={4}>
                                        Order
                                    <div>
                                        <NumericInput
                                            // required
                                            min={1} mobile className="jms-number-height"
                                            onChange={onChangeOrder}
                                            value={orderVal}
                                            // id={`order-${index}`}
                                            name="videos[order_of_video][]" disabled={!playlistsVideoId.includes(playlist_video_id)} />

                                    </div>

                                </Col>
                                {
                                    check.percentage ?  
                                    <Col md={4}>
                                        % limit
                                    <div>
                                        <NumericInput
                                            // required
                                            min={1} max={100} mobile className="jms-number-height"
                                            onChange={onChangePercentage}
                                            value={perval}
                                            // id={`order-${index}`}
                                            name="videos[percentage][]" disabled={!playlistsVideoId.includes(playlist_video_id)} />

                                    </div>

                                </Col>
                                :''
                                }
                                {
                                check.description ? 
                                    <Col xs={12}>
                                        Description
                                    <div>
                                        <Input
                                            type='textarea'
                                            name="videos[description][]" disabled={!playlistsVideoId.includes(playlist_video_id)} />

                                    </div>

                                </Col>
                                :''
                                }

                                <Col md={4}  >
                                    {
                                        type == "upload_video" ?
                                            <>
                                               
                                                <span style={{ float: 'right', fontSize: 11, }}>
                                                    Sync this &nbsp;{` `}
                                                    <input
                                                        // style={{ float: 'right' }}
                                                        onClick={() => handleClickSync(videoId)}
                                                        value={isToggleOn}
                                                        type="checkbox"
                                                        disabled={!mutliSelect.includes(videoId)}
                                                        checked={syncVideo.includes(videoId)}

                                                    />
                                                </span>

                                            </>
                                            : type == "create_class" ?
                                                   
                                                    <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} name="videos[percentage][]" value={25} />
                                              : null

                                    }

                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }

        </>
    )
}
UploadVideos.defaultProps = {
    syncVideo: [],
    mutliSelect: [],

};
export default UploadVideos
