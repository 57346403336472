import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Row, Col,  Input } from 'reactstrap'
// import { Multiselect } from 'react-widgets'
import { createResellerPincode, getAllStates, fillterDistricts, getPincodes ,getPincodesUnderReseller, unAssignPincode} from '../../../redux/Actions/Superadmin'
import { useDispatch, useSelector } from 'react-redux'
// import { components } from 'react-select'
import Creatable from "react-select/lib/Creatable";
// import { useParams } from 'react-router-dom'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Swal from 'sweetalert2'
function PinAssignment(props) {

    const {   reseller_id} = props
    // const params = useParams()

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const states = useSelector(state => state.super)
    const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
    const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
    const pincodes = useSelector((state) => state.superadmin.UnusedPincodes ? state.superadmin.UnusedPincodes : []);
    //   const SingleReseller = useSelector((state) => state.superadmin.SingleReseller ? state.superadmin.SingleReseller : []);
   // const Reseller_data = useSelector((state) => state.superadmin.Reseller_data ? state.superadmin.Reseller_data : []);

    const [PinCodesSelected, setPinCodesSelected] = useState([])
    const [LaddaLoader, setLaddaLoader] = useState(false)
    // const [PinCodes, setPinCodes] = useState()

    const handleSubmit = () => {
        setLaddaLoader(true)
        let arr = []
        PinCodesSelected.map((area) => {
            arr.push(area.pincode_id)
        })
        // console.clear()
        // console.log(arr)
        dispatch(createResellerPincode(tokenData.token,reseller_id, arr, cb))
    }
    const cb = () => {
       // setPinModel(false);
        setLaddaLoader(false);
        dispatch(getPincodesUnderReseller(tokenData.token,reseller_id,cb2))
    }

    // console.log("states", SingleReseller)

    useEffect(() => {
        dispatch(getAllStates(tokenData.token))      
        dispatch(getPincodesUnderReseller(tokenData.token,reseller_id,cb2))
      

    }, [])

    const [Reseller_data, setReseller_data] = useState()
// console.log("redata",Reseller_data)
   const cb2 = (data) => {
      
      // setPinCodes(data)
      // setPinCodesSelected(data)
      setReseller_data(data[0])
   }

   //console.log("reseller dataaa",reseller_data)

   const handleRemove = (id) => {
       let arr = [id] //  push elemets into array to remove multiple 
       dispatch(unAssignPincode(tokenData.token,arr, removecb))
     
   }

   const removecb = () => {
       Swal.fire('Unassigned','Reseller Unassigned from the Panchayath','success')
    dispatch(getPincodesUnderReseller(tokenData.token,reseller_id,cb2))
   }
   

   console.log("check this out",Reseller_data)

    return (
        <div>
            <Card >
                <CardBody>
                    <Row form>
                        <Col md={3}>
                            <label>State</label>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_PINCODES"})}}>
                                <option value={null} >Select</option>
                                {states?.map((s) => {
                                    return (
                                        <option value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>
                          </Col>       
                          <Col md={3}>    
                            <label>District</label>
                            <Input type='select' onChange={(e) => dispatch(getPincodes(tokenData.token, e.target.value))} >
                                <option value={null} >Select</option>
                                {districts?.map((s) => {
                                    return (
                                        <option value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>
                            </Col>  
                          <Col md={5}>
                            <label>Panchayath</label>
                        

                                <Creatable
                                  //  defaultValue={PinCodes}
                                    // defaultValue={[{grade_id: 75, grade_name: "React", selected: true}]}
                                    //  components={{ Menu }}
                                    // value={SelectedOptions}
                                    isMulti
                                    getOptionLabel={option =>
                                        `${option.place}`
                                    }
                                    getOptionValue={option =>
                                        `${option.pincode_id}`
                                    }

                                    name="list"
                                    options={pincodes}
                                    // options={[{grade_id: 734, grade_name: "Angular", selected: true},{grade_id: 754, grade_name: "Android", selected: false},{grade_id: 754, grade_name: "Laravel", selected: false}]}
                                    //  isValidNewOption={isValidNewOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => setPinCodesSelected(e)}
                                /> 
                        </Col>
                        
                        {/* <Col className="pt-3 mr-3" sm={1}><Button disable={LaddaLoader} onClick={handleSubmit} color='primary'>Submit</Button></Col> */}
                        <Col className="mt-4" sm={1}><LaddaButton
                            className="mt-2 mr-2 btn btn-primary"
                            loading={LaddaLoader}
                            type="submit"
                            data-style={ZOOM_IN}
                            onClick={handleSubmit}
                            disabled={PinCodesSelected.length > 0 ? false:true}
                        >
                           Assign
                             </LaddaButton>
                        </Col>

                    </Row>
                </CardBody>
            </Card>
           { 
           Reseller_data?.states.map(s => { return (
           <Card className="mt-1">
                <CardTitle className="m-3">{s.state_name}</CardTitle>
                {console.log("reseller data",Reseller_data)}
                <CardBody>
                     <Row>
                        { s.districts?.map( d =>{ return (
                        <Col md="6" xl="3">
                            <div style={{height:"182px" }}
                                className="card mb-3  text-left card-border card-shadow-success border-success">
                             <div className="menu-header-title pl-2 text-left">
                                {d.district_name}
                             </div>
                                <div className="mt-1" style={{height:'600px',overflowY:'scroll'}}>
                                    <ul>
                                    {d.panchayaths?.map( p => { return (
                                        <>
                                         <li>{p.panchayath}<span onClick={ () => handleRemove(p.panchayath_id) } style={{paddingLeft:'10px', textDecoration:'underline', color:'blue' , cursor:'pointer'}}>remove</span></li> 
                                         
                                         </>
                                    )})}
                                    </ul>
                                </div>
                            </div>
                        </Col> ) })
                         }                   
                    </Row>
                </CardBody>
            </Card>
            ) })
            }
        </div>
    )
}

export default PinAssignment
