import React,{useEffect,useState} from 'react';
import { listAllTestimonial,deleteTestimonial } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import Table from "./table";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { useHistory } from "react-router-dom";
import { Modal,ModalHeader,ModalBody } from 'reactstrap';
import SweetAlert from 'sweetalert-react';
import Swal from 'sweetalert2'


function Testimonial(){
    
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const history = useHistory();


    const testimony = useSelector((state) =>state.superadmin.testimonyloader ? state.superadmin.testimonyloader : false);
    const TestimonyData = useSelector((state) =>state.superadmin.TestimonyData ? state.superadmin.TestimonyData : []);

    const [image,setImage]=useState({show:false,url:''})
    // const [video,setVideo]=useState({show:false,url:''})

    useEffect(()=>{
        dispatch(listAllTestimonial(tokenData.token))
    },[])

    const EditTestimony=(rowdata)=>{
        history.push(`/dashboard/editTestimonial/${rowdata.testimonial_id}`)
    }
    // const Playvideo=(rowdata)=>{
    //     if(rowdata.preview_video){
    //         let video=rowdata ? rowdata.preview_video ? rowdata.preview_video:[]:[]
    //         let frstvideo=video ? video[0]:''
    //         let thumb=frstvideo ? frstvideo.thumbnails:[]
    //         let thumblink=thumb[1] ? thumb[1].link:''
    //         if (thumblink.includes('default')) {
    //             Swal.fire({
    //                 icon: 'warning',
    //                 title: 'Processing',
    //                 // text: "Video on process"

    //             })
    //         } 
    //         else {
    //         setVideo({show:true,url:frstvideo.embed})
    //         }
    //     }
    //     else{
    //         Swal.fire({
    //             icon: 'warning',
    //             title: 'No Video',
    //             text: "There is no video"

    //     })
    //     }

    // }
    // const CloseVideo=()=>{
    //     setVideo({show:false,url:''})
    // }
    const ViewImage=(rowdata)=>{
        setImage({show:true,url:rowdata.image})
    }

    const CloseImage=()=>{
        setImage({show:false,url:''})
    }
    let col=[
        { title: 'Title', field: 'title' },
        { title: 'Description', field: 'description' },
        { title: 'Created At', field: 'created_at' },
        { title: 'Updated At', field: 'updated_at' },

        // { title: 'Phone', field: 'phone' },
    ]

    const Delete=(rowdata)=>{
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',
    
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
    
        }).then((result) => {
            if (result.value) {
                dispatch(deleteTestimonial(tokenData.token,rowdata.testimonial_id,handleOpenSuccessModal))
    
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }

    const [successModal, setSuccessModal] = useState(false);
    const handleOpenSuccessModal = () => {
        dispatch(listAllTestimonial(tokenData.token))
        setSuccessModal(true)
    }

    return(
        <div>
        <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
        >
            <PageTitle
                heading="Testimony List"
                subheading="List of Your Tesimonials"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />

            <Table
                loader={testimony}
                actions={'testimonial'}
                data={TestimonyData}
                title={'Testimonial List'}
                columns={col}
                EditTestimony={EditTestimony}
                // Play={Playvideo}
                ViewImage={ViewImage}
                Delete={Delete}
            />

        </CSSTransitionGroup>


        <Modal
          isOpen={image.show}
          toggle={CloseImage}
          size={'md'}
        >   
            <ModalHeader toggle={CloseImage}>
                Preview Image
            </ModalHeader>
            <ModalBody>
                {
                    image.url ?
                    <img src={image.url} alt='' width='100%' height='350px' />
                    :
                    <div style={{width:'100%',height:'100%',justifyContent:'center',display:'flex',alignItems:'flex'}}>
                        <span>Please Upload An Image</span>
                    </div>
                }
            </ModalBody>
            
        </Modal>


        {/* <Modal
            isOpen={video.show}
            toggle={CloseVideo}
            size={'md'}
        >
            <ModalHeader toggle={CloseVideo}>
                Preview Video
            </ModalHeader>
            <ModalBody>
            {
                video.url ? 
                <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                  {ReactHtmlParser(video.url)}
                </div>
                :
                <div style={{width:'100%',height:'100%',justifyContent:'center',display:'flex',alignItems:'flex'}}>
                        <span>Please Upload Video</span>
                    </div>
              }
            </ModalBody>
        </Modal> */}

        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text= {"Deleted Successfully"}
            type="success"
            onConfirm={() => setSuccessModal(false)} />

        </div>
    )
}

export default Testimonial