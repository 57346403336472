import React,{useEffect,useState} from 'react';
import Grid from '@material-ui/core/Grid';

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import Card from '@material-ui/core/Card';
import { listAllExpiredSubscription,setReqStudentData } from "../../../redux/Actions/Student"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import WarningIcon from '@material-ui/icons/Warning';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import nodata from '../../LandingPageNew/images/nodata.png'
import PageTitle from "../../HeaderTitle/PageTitle";
import LoadingOverlay from 'react-loading-overlay';
import subscriptionimage from "../../LandingPageNew/images/subscription_image.png"
import LaddaButton, { ZOOM_IN } from "react-ladda";
import Terms from '../../LandingPageNew/SkillablersPayment/index'
import Swal from 'sweetalert2'
import {  logout } from '../../../redux/Actions/auth'
import axios from 'axios'
import logo from "../../LandingPageNew/images/logo.png"
import { development,razorpaykey } from '../../../config.json'
import { Modal } from 'reactstrap';
import animationData from '../../Dashboard/animation/check-circle.json'
import Lottie from 'react-lottie';
import errorAnimation from '../../Dashboard/animation/error.json'
import Modals from '../../common/Modal'
import {
    Row,
    Col, 
    Button,
  } from "reactstrap";
  import { updateRenewSubscription, renewSubscription } from "../../../redux/Actions/subscription-action"


  
  const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

function ExpiredSubscriptions(){
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const role=tokenData.role ?tokenData.role:''
    const [load_renewal,setLoader]=useState(false)
    const [successModal, setSuccessModal] = useState({open:false})
    const [failModal, setFailModal] = useState({fail:false,msg:''})
    const [idcheck,setIdcheck]=useState(false)
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successModal.open ? animationData :failModal? errorAnimation:null,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const Expired_Data = useSelector((state) =>state.student.Expired_Data ? state.student.Expired_Data : '');
    const Expired_Array = useSelector((state) =>state.student.Expired_Array ? state.student.Expired_Array :[]);
    const next_page = useSelector((state) =>state.student.next_page ? state.student.next_page : '');
    const prev_page = useSelector((state) =>state.student.prev_page ? state.student.prev_page : '');
	const page = useSelector((state) =>state.student.exp_page ? state.student.exp_page :1);
	const loading = useSelector((state) =>state.student.exp_load ? state.student.exp_load :false);
	const loading_payment = useSelector((state) =>state.Subscription.exp_load ? state.Subscription.exp_load :false);

    
    useEffect(()=>{
        dispatch(listAllExpiredSubscription(tokenData.token,page))
    },[page])

    const Viewcourse=(val)=>{
        history.push(`/dashboard/subscription/${val.subscription_id}`)
    }
    const handleCloseModal = () => {
        setOrderData(null)
        // setTermsAndConditions(false)
    }
    const NextPage=()=>{
        dispatch(setReqStudentData('exp_page',page+1))

    }
    const PreviousPage=()=>{
        dispatch(setReqStudentData('exp_page',page-1))
    }


    const Renew =  (id) => {
        setLoader(true)
        let data = {
            order_id:id
        }
        dispatch(renewSubscription(tokenData.token,data,success))
    }
    const [OrderData, setOrderData] = useState()
    const success = (data) => {
        setOrderData(data)
        console.log("data log",data)
        //  setTermsAndConditions(true)
        setLoader(false)
    }


    const cb = () => {

        dispatch(logout())
        localStorage.removeItem("authDetails");
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
        history.push('/login')
    }

    const paymentStatus = (res) => {

        if (res.result) {
            if(page===1){
                dispatch(listAllExpiredSubscription(tokenData.token,page))
            }
            else{
                dispatch(setReqStudentData('exp_page',1))
            }
            setSuccessModal({open:true,res})
        } else {
            setFailModal({fail:true,msg:res.message})
        }
    }

    const closeSuccessModal=()=>{
        dispatch({type:'PAYMENT_SUCCESSFULLY_COMPLETED'})
        setSuccessModal({...successModal,open:false})
    }
    
    return(
        
        <div>
            {
                Expired_Data ? 
                <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                <PageTitle
                    // style={{ display: "none" }}
                    heading="Subscriptions"
                    subheading="Expired Susbcriptions"
                    icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
                    />
                <LoadingOverlay
                    active={loading ||loading_payment}
                    spinner
                    text='Loading your content...'
                >
                <Grid container>
               
                {
                    Expired_Array.length > 0 ?
                    <>
                        {
                            Expired_Array.map((val,ky)=>{
                                return(
                                    <Grid item xs={12} sm={4} md={3} key={ky} style={{marginTop:'10px',padding:'10px',boxSizing:'border-box'}}>
                                       <div class="blog-item thum-hover featured-institute-main" style={{cursor:'pointer'}}>
                                            <div class="position-relative"  onClick={()=>{Viewcourse(val)}}>
                                                <div class="item-thumbnail "><img src={subscriptionimage} alt="" width='100%' height='200px' /></div>
                                                <div class="cat-div">{val.subscription_type}</div>
                                            </div>
                                            <div class="padding-20px bgclrbtm-of-course">
                                                <div style={{height:'50px',overflow:'hidden',wordWrap:'break-word'}}  onClick={()=>{Viewcourse(val)}}>
                                                    <span class="f-i-title">{val.subscription_name}</span>
                                                </div>
                                                <p class="font-14 mb-2"  onClick={()=>{Viewcourse(val)}}>{val.course_subject_name}</p>
                                                <p class="mb-0"  onClick={()=>{Viewcourse(val)}}><span style={{color:'#6a6f73'}}>Order Id </span><span class="font-16 font-weight-500 text-blue">#{val.order_id}</span>
                                                </p>
                                                <hr class="mt-1 mb-2" />
                                                <p class="mb-0"  onClick={()=>{Viewcourse(val)}}>
                                                    <span class="mr-1 course-badges badge badge-success">{val.payment_date}</span>
                                                    <span class="mr-1 course-badges badge badge-dark">{val.course_amount} {val.currency_code}</span>
                                                </p>
                                                <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                                                    <LaddaButton
                                                        className="mr-2 btn btn-primary"
                                                        loading={load_renewal && idcheck===val.order_id}
                                                        onClick={()=>{Renew(val.order_id)}}
                                                        data-style={ZOOM_IN}
                                                        style={{marginTop:'10px'}}
                                                    >
                                                        Renew
                                                    </LaddaButton>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </>
                    :
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={nodata} width='600px' height='400px' alt='' />
                    </div>
                }
            </Grid>
            {
                    Expired_Array.length > 0 && (next_page || prev_page)  ?
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                            <ul class="pagination-list">
                                {
                                    prev_page ? 
                                    <li style={{cursor:'pointer'}}><a onClick={PreviousPage}>Prev</a></li>
                                    :
                                    <li><a style={{color:'#dddddd'}}>Prev</a></li>

                                }
                                {
                                    next_page ? 
                                    <li style={{cursor:'pointer'}}><a onClick={NextPage}>Next</a></li>
                                    :
                                    <li><a style={{color:'#dddddd'}}>Next</a></li>

                                }
                            </ul>
                        </div>
                    </div>

                    :
                    ''
                    
                }
            </LoadingOverlay>   

            </CSSTransitionGroup>

            :
            <>
                <Card style={{ padding: '30px' }}>
                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={30} count={5} />
                </SkeletonTheme>
                </Card>
                <Card style={{ padding: '30px' }}>
                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={30} count={5} />
                </SkeletonTheme>
                </Card>
            </>
            }
            <Modal
                isOpen={OrderData}
                // handleClose={handleCloseModal}
                toggle={handleCloseModal}
                title={"Terms&Conditions"}
            // size='lg'
            >
                <div class='class="modal-content"'>
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-betwenn', padding: '15px', borderBottom: '1px solid #e9ecef', borderTopLeftRadius: '.3rem', borderTopRightRadius: '.3rem' }}>
                        <h5 class="modal-title text-blue text-center w-100" id="exampleModalLongTitle">Order</h5>
                        <button type="button" class="close" onClick={() => { handleCloseModal(false) }}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12 col-lg-12 col-12">
                            <div style={{ display: 'flex', justifyContent: 'center' }} class="row">
                                <div style={{ textAlign: 'center' }} class="col-md-12 col-lg-12 col-12">
                                    <img style={{ borderRadius: '4px' }} src={subscriptionimage} alt="" width='200px' height='100px' class="img-fluid" />
                                </div>
                                <div style={{ textAlign: 'center', marginTop: '10px' }} class="col-md-12 col-lg-12 col-12 ">
                                    {/* <div style={{ fontSize: '15pt', fontWeight: 'bold', color: '#000000' }} >{Subscription_details.subscription_name}</div>
                                    <div style={{ fontWeight: 'bold', color: '#003366' }}>{Subscription_details.subscription_fee}</div> */}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12 col-12">
                                    <hr />
                                </div>
                            {OrderData &&
                                <Terms
                                    course_id={OrderData.data.subscription_id} // subscription_id
                                    name={OrderData.name}
                                    email={OrderData.email}
                                    phone={OrderData.phone}
                                    reg_id={OrderData.reg_id}
                                    service_type={OrderData.service_type}
                                    url_page={OrderData.url_page}
                                    course_amount={OrderData.data.amount}
                                    mots_order_id={OrderData.order_id}
                                />
                            }
                            </div>


                        </div>
                    </div>
                </div>

            </Modal>
            
        </div>
    )
}

export default ExpiredSubscriptions