import React, { useState,useEffect,useRef } from "react";
import { useDispatch,useSelector } from "react-redux";
import { Progress } from "react-sweet-progress";
import { deleteVendor,blockVendor } from "../../../redux/Actions/Superadmin";
import Swal from 'sweetalert2'

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Badge,
  FormFeedback,
  Input,
  Label,
  Container,
  Button
} from "reactstrap";

import LaddaButton, { ZOOM_IN } from "react-ladda";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { setReqData } from "../../../redux/Actions/Superadmin";
import {
  emailValidator,
  requiredValidator,
  // descriptionValidator
} from "../../../utilities/Validator";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import {AddNewVendor,getAllvendors} from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';
import { getAllStates, 
  // createResellerPincode, fillterDistricts, fillterPincodes ,getPincodesUnderReseller
} from '../../../redux/Actions/Superadmin'

import Table from "./table";
import { useHistory } from "react-router-dom";
import Modal from '../../common/Modal'

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const Createvendor = () => {
  const dispatch = useDispatch();
  const formData = new FormData();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const history=useHistory()

  // const states = useSelector((state) => state.superadmin.states ? state.superadmin.states : []);
  // const districts = useSelector((state) => state.superadmin.districts ? state.superadmin.districts : []);
  // const pincodes = useSelector((state) => state.superadmin.pincodes ? state.superadmin.pincodes : []);

  
  const [File, setFile] = useState()
  const [LogoFile, setLogoFile] = useState('')

  const [input, SetInput] = useState({
    vendorname: "",
    email: "",
    password: "",
    number: "",
    person: "",
    pan: "",
    gst: "",
    address: "",
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [successModal, setSuccessModal] = useState(false);

  const Deletevendor=(val)=>{
    // console.log(val)
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',
  
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",
  
  }).then((result) => {
      if (result.value) {
          dispatch(deleteVendor(tokenData.token,val.user_id,handleOpenSuccessModal))
  
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Delete action cancelled',
              'error'
          )
      }
  })
  }

  const handleChangeInput = (event) => {
    SetInput({ ...input, [event.target.name]: event.target.value });
  };

  const vendorcreate = useSelector((state) =>state.superadmin.vendorcreate ? state.superadmin.vendorcreate : false);
  // const files = useSelector((state) =>state.superadmin.files ? state.superadmin.files : []);
  // const logo = useSelector((state) =>state.superadmin.logo ? state.superadmin.logo : []);

  const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');
  const VendorArray = useSelector((state) =>
  state.superadmin.VendorArray ? state.superadmin.VendorArray : []
);
const vendorloader = useSelector((state) =>
  state.superadmin.vendorloader ? state.superadmin.vendorloader : ""
);

  useEffect(()=>{
    dispatch(setReqData("percetage", ''));
    dispatch(getAllvendors(tokenData.token));
    dispatch(getAllStates(tokenData.token))

  },[])
  // console.log("vendorrr array",VendorArray)
  const Changefile = (e) => {
  setFile(e.target.files[0])
    // let arr = [];
    // for (let i = 0; i < e.target.files.length; i++) {
    //   arr.push(e.target.files[i]);
    //   // formData.append('doc_url[]', e.target.files[i])

    // }
   // dispatch(setReqData("files", arr));
  };


  const cropperRef = useRef(null);
  const [src,setSrc]=useState('')

  const [cropData, setCropData] = useState("");

  const ChangefileLogo=(e)=>{
    e.preventDefault()
    const reader = new FileReader();

    reader.onload = () => {
      setSrc(reader.result)
    };
    if(e.target.files[0]){
      reader.readAsDataURL(e.target.files[0]);
      setLogoFile(e.target.files[0])
    }

  }

    const CropImage=()=>{
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      // console.log(cropper.getCroppedCanvas().toDataURL());
    }

    const getCropData = () => {
      const imageElement = cropperRef?.current;
      const cropper = imageElement?.cropper
      if (typeof cropper !== "undefined") {
        // console.log(cropper.getCroppedCanvas().toDataURL());
        setCropData(cropper.getCroppedCanvas().toDataURL());
      }
    };
//////to convert binary64 to blob
const b64toBlob=(dataURI)=> {
  
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}


  const Submit = (e) => {
    e.preventDefault();
    // let fileerr=files.length > 0 ? '' :"* required"
    let logo
    if(src && !cropData){
      logo='*Crop the selected image'
    }
    else{
      logo=''
    }

    const err = {
      email: emailValidator(input.email),
      vendorname: requiredValidator(input.vendorname),
      password: requiredValidator(input.password),
      number: requiredValidator(input.number),
      person: requiredValidator(input.person),
      pin: requiredValidator(input.pin),
      // gst: requiredValidator(input.gst),
      address: requiredValidator(input.address),
      // description: descriptionValidator(input.description),
      // file:fileerr
      logoerr:logo

    };
    if (
      !err.email &&
      !err.vendorname &&
      !err.number &&
      !err.password &&
      !err.person &&
       !err.pin &&
      // !err.gst &&
      !err.address &&
     //  !err.description 
      // !err.fileerr
      !err.logoerr
    ) {
      setErrors(err);
      formData.append("vendor_name", input.vendorname);
      formData.append("contact_number", input.number);
      formData.append("contact_person", input.person);
      formData.append("email", input.email);
      formData.append("password", input.password);
      formData.append("gst_number", input.gst);
      formData.append("pan_number", input.pan);
      formData.append("pin", input.pin);
      formData.append("address", input.address);
      formData.append("description", input.description);
      formData.append("doc_url", File);
      if(cropData){
        // formData.append("enterprise_logo_url",LogoFile);
        let file=b64toBlob(cropData)
        formData.append("enterprise_logo_url", file )
      }
      // formData.append('enterprise_number',1234)
      dispatch(AddNewVendor(tokenData.token,formData,handleOpenSuccessModal,reset,errormodel))
    } else {
      setErrors(err);
      if(err.vendorname){
        Showerror('vendorname')
      }
      // else if(err.number){
      //   Showerror('number')
      // }
      else if(err.email){
        Showerror('email')
      }
      else if(err.person){
        Showerror('person')
      }
      else if(err.pin){
        Showerror('pin')
      }
      else if(err.address){
        Showerror('address')
      }
      // else if(err.description){
      //   Showerror('description')
      // }
      else{
        Showerror('password')
      }
    }
  };

  const Showerror=(val)=>{
    var elmnt = document.getElementById(val);
    elmnt.scrollIntoView();
}

  const Updatestatus=(val)=>{
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',
  
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",
  
  }).then((result) => {
      if (result.value) {
          dispatch(blockVendor(tokenData.token,val.user_id,handleOpenSuccessModal))
  
      } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
              'Cancelled',
              'Cancelled',
              'error'
          )
      }
  })
  }

  const [Message, setMessage] = useState(null)
  const handleOpenSuccessModal = (message) => {
    setMessage(message)
    setSuccessModal(true)

}
const handleCloseSuccesstModal = () => {
    dispatch(getAllvendors(tokenData.token));
    setSuccessModal(false)
    // window.location.reload(false);
}

const reset=()=>{
  dispatch(setReqData('files',[]))
  dispatch(setReqData("logo", []));
  dispatch(setReqData("percetage", ''));
  setSrc('')
  setCropData('')
  setLogoFile('')
  setFile('')
  SetInput({
    vendorname: "",
    email: "",
    password: "",
    number: "",
    person: "",
    pan: "",
    gst: "",
    address: "",
    description: "",
  })

  
 }

 const Handlechangephone=(e)=>{
  SetInput({...input,number:e})
}


const [err, setErr] = useState({
  open_err:false,
  err_list:[]
})
const errormodel=(val)=>{
  setErr({
    open_err:true,
    err_list:val
  })  
}
const closeErr = () => {
  setErr({
    open_err: false,
    err_list: []
  })
}

const columns = [
  { title: "Name", field: "enterprise_name" },
  { title: "Email", field: "email" },
  { title: "Phone", field: "contact_number" },
  { title: "PIN", field: "pin" },
  { title: "Signup", field: "signup_status",
  render: (rowData) => {
      return rowData.signup_status === 1 ? (
        <Badge color="success" pill>
          {"COMPLETED"}
        </Badge>
      ) : (
        <Badge color="warning" pill>
          {"PARTIAL"}
        </Badge>
      );
    }, },
  // { title: "Courses", field: "total_courses" },
  { title: 'Status', field: 'status',
  render: (rowData) => {
      return(
          rowData.status===1 ?
          <Badge color="danger" pill>{'Blocked'}</Badge>
          :
          <Badge color="success" pill>{'Active'}</Badge>
      )
  }
  },
 
];

const data = VendorArray ? VendorArray.vendors ? VendorArray.vendors: []: [];

const action = "vendor_table";
const Vendordetails=(val)=>{
  dispatch(setReqData('selectedvendor',val))
  // history.push(`/dashboard/vendor/${val.enterprise_id}`)
  history.push(`/dashboard/vendor/${val.enterprise_id}/${val.user_id}`)

}

const EditVendor=(val)=>{
history.push(`/dashboard/editVendor/${val.user_id}`)
}

  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          style={{ display: "none" }}
          heading="Create Partner"
          subheading="Create Partner With Basic Details"
          icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
        />

        <Card tabs={true} className="mb-3">
          <CardBody>
            {/* <Form onSubmit={submitForm} id="class-form"> */}
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>BASIC DETAILS</CardTitle>
                <Container>
                  <Row>
                    <Col sm={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Partner Name
                      </Label>
                      <Input
                        type="text"
                        id='vendorname'
                        name="vendorname"
                        value={input.vendorname}
                        invalid={errors.vendorname ? true : false}
                        onChange={handleChangeInput}
                        autocomplete="off"
                      />
                      <FormFeedback>{errors.vendorname}</FormFeedback>
                    </Col>

                    <Col sm={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span> Email ID
                      </Label>
                      <Input
                        type="text"
                        onChange={handleChangeInput}
                        style={{ width: "100%" }}
                        name="email"
                        id='email'
                        value={input.email}
                        invalid={errors.email}
                        autocomplete="off"
                      ></Input>
                      <FormFeedback>{errors.email}</FormFeedback>
                    </Col>

                    <Col sm={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Password
                      </Label>
                      <Input
                        type="password"
                        style={{ width: "100%" }}
                        id="password"
                        value={input.password}
                        invalid={errors.password}
                        name="password"
                        onChange={handleChangeInput}
                        autocomplete="off"
                      />
                      <FormFeedback>{errors.password}</FormFeedback>
                    </Col>

                    <Col xs={12} sm={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Contact Number
                      </Label>
                        <PhoneInput
                            value={input.number}
                            name="number"
                            // id='number'
                            country={'in'}
                            onChange={(e) => Handlechangephone(e)}
                            invalid={errors.number}
                            
                            />
                            {
                              errors.number ? 
                              <span style={{color:'red'}}>{errors.number}</span>:''
                            }
        
                    </Col>

                    <Col sm={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Contact Person
                      </Label>

                      <Input
                        type="text"
                        name="person"
                        id='person'
                        invalid={errors.person}
                        onChange={handleChangeInput}
                        value={input.person}
                        autocomplete="off"
                      ></Input>
                      <FormFeedback>{errors.person}</FormFeedback>
                    </Col>

                    {/* <Col md={4}>
                            <label>State</label>
                            <Input  type='select' onChange={(e) =>{ dispatch(fillterDistricts(tokenData.token, e.target.value)); dispatch({type:"RESET_PINCODES"})}}>
                                <option value={null} >Select</option>
                                {states.map((s) => {
                                    return (
                                        <option value={s.state_id}>{s.state_name}</option>
                                    )
                                }

                                )}
                            </Input>

                    </Col>
                    <Col sm={4}>
                            <label>District</label>
                            <Input type='select' onChange={(e) => dispatch(fillterPincodes(tokenData.token, e.target.value))} >
                                <option value={null} >Select</option>
                                {districts.map((s) => {
                                    return (
                                        <option value={s.district_id}>{s.district_name}</option>
                                    )
                                }

                                )}
                            </Input>                         
                            
                        </Col>
                       <Col sm={4}>
                            <label>Panchayat</label>
                            <Input type='select'  >
                                <option value={null} >Select</option>
                                {pincodes.map((p) => {
                                    return (
                                        <option value={p.pincode_id}>{p.place}</option>
                                    )
                                }

                                )}
                            </Input>                         
                            
                        </Col> */}

                    <Col xs={12} sm={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        PIN
                      </Label>
                      <Input
                        type="text"
                        id='pin'
                        style={{ width: "100%" }}
                        name="pin"
                        value={input.pin}
                        onChange={handleChangeInput}
                        invalid={errors.pin}
                        autocomplete="off"
                      />
                      <FormFeedback>{errors.pin}</FormFeedback>
                    </Col>
                    <Col xs={12} sm={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        {/* <span className="text-danger">*</span> */}
                        GST no.
                      </Label>
                      <Input
                        type="text"
                        style={{ width: "100%" }}
                        name="gst"
                        value={input.gst}
                        onChange={handleChangeInput}
                        autocomplete="off"
                        // invalid={errors.gst}
                      />
                      {/* <FormFeedback>{errors.gst}</FormFeedback> */}
                    </Col>

                    <Col xs={12} sm={4} className="assign-form-grid">
                      <Label for="exampleNameF">
                        {/* <span className="text-danger">*</span> */}
                        PAN no.
                      </Label>
                      <Input
                        type="text"
                        style={{ width: "100%" }}
                        name="pan"
                        value={input.pan}
                        onChange={handleChangeInput}
                        // invalid={errors.pan}
                        autocomplete="off"
                      />
                      {/* <FormFeedback>{errors.pan}</FormFeedback> */}
                    </Col>

                    <Col sm={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        Address
                      </Label>
                      <Input
                        type="textarea"
                        id='address'
                        rows={4}
                        style={{ width: "100%" }}
                        name="address"
                        value={input.address}
                        onChange={handleChangeInput}
                        invalid={errors.address}
                      />
                      <FormFeedback>{errors.address}</FormFeedback>
                    </Col>

                    <Col sm={12} className="assign-form-grid">
                      <Label for="exampleNameF">
                        Description
                      </Label>
                      <Input
                        type="textarea"
                        rows={4}
                        style={{ width: "100%" }}
                        value={input.description}
                        name="description"
                        onChange={handleChangeInput}
                       // invalid={errors.description}
                        id='description'
                      />
                      {/* <FormFeedback>{errors.description}</FormFeedback> */}
                    </Col>

                    <Col xs={12} sm={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        Support Documents
                      </Label>
                      <Input
                        style={{ lineHeight: "27px" }}
                        type="file"
                        id="assignment"
                        onChange={Changefile}
                        name="support document"
                        
                        // invalid={errors.file}
                      />
                      {/* <FormFeedback >{errors.file}</FormFeedback> */}
                    </Col>

                    <Col xs={12} sm={6} className="assign-form-grid">
                      <Label for="exampleNameF">
                        Logo
                      </Label>
                      <Input
                        style={{ lineHeight: "27px" }}
                        type="file"
                        onChange={ChangefileLogo}
                        name="logo"
                        accept="image/*"
                        // invalid={errors.file}
                      />
                    </Col>
                    
                    <Col xs={12} sm={6}>
                    {
                          src ? 
                          <>
                            <Cropper
                              src={src}
                              style={{ height: '400px', width: '100%' }}
                              aspectRatio={5 / 3}
                              guides={false}
                              crop={CropImage} 
                              ref={cropperRef}

                              />
                                <div className="divider"/>
                                <div className="text-center">   
                                <div className="text-center">
                                    <Button color="danger"
                                            onClick={getCropData}
                                        >
                                            Crop Image
                                        </Button>
                                    </div> 
                                  <span style={{color:'red'}}>{errors.logoerr}</span>
                                </div>            
                            </>
                            : 
                            ''
                        }
                    </Col>

                    <Col xs={12} sm={6}>
                    {
                        cropData ? 
                          <div>
                            <div>
                                <h6>Cropped Result</h6>
                            </div>
                            <img
                                className="after-img rounded"
                                src={cropData}
                                alt=""
                                width='100%'
                            />
                        </div>
                      : null
                      }
                    </Col>
                    <Col sm={12}>
                    {(percetage !== ""  && (File || LogoFile)) ? (
                      <>
                        <strong style={{ color: "rgb(19, 140, 228)" }}>
                          Uploading Files...
                        </strong>
                        <Progress percent={percetage} />
                      </>
                    ) : null}
                  </Col>

                  </Row>
                </Container>
              </CardBody>
            </Card>
            <LaddaButton
              className="mb-2 mr-2 btn btn-primary"
              loading={vendorcreate}
              type="submit"
              data-style={ZOOM_IN}
              onClick={Submit}
            >
              CREATE PARTNER
            </LaddaButton>
            {/* </Form> */}
          </CardBody>
        </Card>

        <Row>
            <Col sm={12}  md={12}>
              <Table
                loader={vendorloader}
                actions={action}
                data={data}
                // handleEdit={handleEdit}
                title={"Partner List"}
                columns={columns}
                Deletevendor={Deletevendor}
                // DetailsofVendor={VendorviewStudents}
                // DetailsofCourse={ViewCourse}
                Vendordetails={Vendordetails}
                Editvendor={EditVendor}
                Updatestatus={Updatestatus}
  
              />
            </Col>
          </Row>

      </CSSTransitionGroup>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text={Message? Message:"Completed successfully"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />


            <Modal
                open={err.open_err}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                      
                        {
                            err.err_list.map((error,ky) => <li key={ky} style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>

    </>
  );
};

export default Createvendor;
