import React,{useState,useEffect,Fragment} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    // Badge,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { requiredValidator } from "../../../utilities/Validator";
import { setReqData,createNews } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { Progress } from "react-sweet-progress";
import { DatePicker  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from "moment";

function CreateNewsComponent() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const formData = new FormData();

    const news = useSelector((state) =>state.superadmin.news ? state.superadmin.news : false);
    const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

    
    const [InputField,setInputfield]=useState({
        title:'',
        description:'',
        date:null
    })
    const [err,setErr]=useState({})
    const [ImageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [dateval, setDate] = useState(null);

    useEffect(()=>{
        dispatch(setReqData('percetage',''))
    },[])
    const handleChangeInput=(e)=>{
        setInputfield({...InputField,[e.target.name]:e.target.value})
    }

    const Submitform=(e)=>{
        e.preventDefault();
        let meadia=ImageFile===null ? "*Required":''

        const error={
            title: requiredValidator(InputField.title),
            description: requiredValidator(InputField.description),
            meadiaerr:meadia,
            date: requiredValidator(InputField.date),

        }
        if( !error.title && !error.description && !error.meadiaerr && !error.date){
            setErr(error)
            formData.append("title", InputField.title);
            formData.append("date", InputField.date);
            formData.append("image_file", ImageFile);
            formData.append("description",InputField.description );
            dispatch(createNews(tokenData.token,formData,cb))
        }
        else{
            setErr(error)
        }
    }

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };


      
    const cb=()=>{
        document.getElementById('form_id').reset()
        setInputfield({
            title:'',
            description:'',
            date:null
        })
        dispatch(setReqData('percetage',''))
        setDate(null)
        setImageFile('')
        setOpen(true)
    } 

   const handleChangeFormDate=(e)=>{
        const dt = moment(e).format("YYYY-MM-DD");
        setDate(e)
        setInputfield({ ...InputField, date: dt });

   }
    return (
        <Fragment>

            <PageTitle
                heading="Add News"
                subheading="Create New News Here"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />

                <Card className="main-card mb-3">
                    <Form onSubmit={Submitform} id='form_id'>
                    <CardHeader>
                        Add News
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col className="pt-1  mt-2" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Title
                                    </Label>

                                    <Input
                                        type="text"
                                        name="title"
                                        value={InputField.title}
                                        invalid={err.title}
                                        onChange={handleChangeInput}
                                        autocomplete="off"
                                    />
                                    <FormFeedback>{err.title}</FormFeedback>

                            </Col>
                            
                            <Col className="pt-1 mt-2" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Description
                                    </Label>

                                    <Input
                                        type="textarea"
                                        rows={4}
                                        name="description"
                                        value={InputField.description}
                                        invalid={err.description}
                                        onChange={handleChangeInput}
                                    />
                                    <FormFeedback>{err.description}</FormFeedback>

                            </Col>
                            
                            <Col className="pt-1 mt-4" xs={12} sm={6}>
                            <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Date
                                </Label>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        format="dd/MM/yyyy"
                                        label=""
                                        size='small'
                                        inputVariant="outlined"
                                        value={dateval}
                                        onChange={date => handleChangeFormDate(date)}
                                        // minDate={new Date()}
                                        fullWidth
                                        views={["year", "month", "date"]}
                                        openTo="year"
                                    />
                                </MuiPickersUtilsProvider>
                                <span style={{color:'red'}}>{err.date}</span>

                            </Col>
                            
                            <Col className="pt-1 mt-4" xs={12} sm={6}>
     
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Image
                                </Label>
                                <Input 
                                    type='file'
                                    name='image'
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                />
                                <span style={{color:'red'}}>{err.meadiaerr}</span>

                            </Col>

           
                            
                            <Col sm={12} className="pt-1 mp-2">
                            {(percetage !== ""  && ImageFile ) ? (
                                <>
                                    <strong style={{ color: "rgb(19, 140, 228)" }}>
                                    Uploading Files...
                                    </strong>
                                    <Progress percent={percetage} />
                                </>
                                ) : null}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                    
                        <Col xs={12} sm={6}>
                            <LaddaButton
                                className="btn btn-primary"
                                loading={news}
                                type="submit"
                                data-style={ZOOM_IN}
                            >
                                Create
                            </LaddaButton>
                        </Col>
                    </CardFooter>
                            
                    </Form>
                </Card>



                <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={open}
                    text={"Created successfully"}
                    type="success"
                    onConfirm={() => setOpen(false)} />

        </Fragment>
    )
}

export default CreateNewsComponent
