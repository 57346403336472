import React,{useState,useEffect} from 'react';
import {
    Col, FormGroup,
    Card,Input,
    Row,Container,Label,CardFooter
} from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import './ticket.css'
// import cx from 'classnames';
// import { Elastic } from 'react-burgers';
import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
// import avatar2 from '../../../assets/utils/images/avatars/2.jpg';
import {
    // faCog,
    faCalendarAlt,
    // faSearch,

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import {
    faHome
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import cx from 'classnames';
import CardHeader from 'reactstrap/lib/CardHeader';
import CardBody from 'reactstrap/lib/CardBody';
import Model from "../Vendor/Model"
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import InfoIcon from '@material-ui/icons/Info';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SendIcon from '@material-ui/icons/Send';

import { UpdateTicketStatus,getSingleTicket,sendTicketMessage,setReqData } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

function TicketDetails(){

    const [Open,setOpen]=useState(false)

    const [ticket_id,setTicketId]=useState()
    const [ticket_status,setTicketStatus]=useState()
    const [successModal, setSuccessModal] = useState(false);
    const dispatch=useDispatch()
    const params=useParams()

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const status_update_loader = useSelector((state) =>state.superadmin.status_update_loader ? state.superadmin.status_update_loader : '');

    const ticket_details = useSelector((state) =>state.superadmin.ticket_details ? state.superadmin.ticket_details : '');
    // const status_update_loader = useSelector((state) =>state.superadmin.status_update_loader ? state.superadmin.status_update_loader : '');
    const message = useSelector((state) =>state.superadmin.message ? state.superadmin.message : '');
    const Chatdata = useSelector((state) =>state.superadmin.Chatdata ? state.superadmin.Chatdata : []);

    

    useEffect(()=>{
        dispatch(getSingleTicket(tokenData.token,params.ticket_id))

        return(()=>{
            dispatch(setReqData('ticket_details',''))
            dispatch(setReqData('message',''))
            dispatch(setReqData('Chatdata',''))

        })
    },[])

    const Changestatus=()=>{
        setTicketId(params.ticket_id)
        setTicketStatus(ticket_details.status)
        setOpen(true)
    }
    
    const CloseTicket=()=>{
        setOpen(false)
    }
    
    const Submitstatus=(e)=>{
        if(e.target.value !=='select'){
            setTicketStatus(e.target.value)
        }
    }
    
    const Submit=()=>{
        dispatch(UpdateTicketStatus(tokenData.token,params.ticket_id,ticket_status,handleOpenSuccessModal)) 
    }
    
    const handleOpenSuccessModal = () => {
        setOpen(false)
        dispatch(getSingleTicket(tokenData.token,params.ticket_id))
        setSuccessModal(true)
    }


    const handleCloseSuccesstModal = () => {

        setSuccessModal(false)
        // setOpen(false)

    }


    const Handlemessage=(e)=>{
        dispatch(setReqData('message',e.target.value))
    }
    const SendMessage=()=>{
        dispatch(sendTicketMessage(tokenData.token,params.ticket_id,message,reset))
    }

    const reset=()=>{
        dispatch(setReqData('message',''))
        dispatch(getSingleTicket(tokenData.token,params.ticket_id))

    }
    return(
        <Container>
            <Row>
                <Col xs={12}>
                <Breadcrumb style={{width:'100%'}}>
                    <BreadcrumbItem>
                    <Link to="/dashboard">
                        <FontAwesomeIcon icon={faHome} />
                    </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                    <Link to="/dashboard/tickets">Ticket List</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Details</BreadcrumbItem>
                </Breadcrumb>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                <div className="app-page-title" style={{ margin: '0px 0px 15px 0px', borderRadius: '6px', backgroundColor: 'rgb(0, 69, 139)', color: "white",display:'flex',alignItems:'center' }}>
       
                    <div
                        className={cx("page-title-icon")}>
                        <i className='pe-7s-ticket icon-gradient bg-sunny-morning' />
                    </div>
                    <div>
                        <span style={{ textTransform: 'uppercase' }}>{ticket_details.order_id}</span> 
                        <br />
                    <div
                    style={{
                        color: "#99DDFF",
                        fontWeight: 600,
                        textTransform: "capitalize",
                    }}
                    className={cx("page-title-subheading")}
                    >
                    {ticket_details.status}
                    </div>
                    </div>

                    <div className='page-title-actions'>
                        <Button size='small' color='secondary' variant='contained' onClick={()=>{Changestatus()}}>Update Status</Button>
                    </div>
                </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={12} style={{marginTop:'5px'}}>
                    <Card>
                        <CardHeader>
                            Details
                        </CardHeader>
                        <CardBody>
                            <Row>
                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Name"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}
                                    <span className='courseview'>{ticket_details.student_name}</span>
                                {/* <Tooltip arrow title='View Student'>
                                    <span><InfoIcon fontSize='small' style={{cursor:'pointer',marginLeft:'5px'}}/></span>
                                </Tooltip> */}
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Order ID"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.order_id}
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Issued Date"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.issued_date}
                            </Col>

                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Title"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.title}
                            </Col>

                            
                            {
                                ticket_details.course_name ? 
                                <>
                                <Col md={5} className="assign-details-card-div-head">
                                    <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                    {"Course"}
                                </Col>
                                <Col md={7} className="assign-details-card-div">
                                    {" "}
                                        <span className='courseview'>Course1</span>
                                    <Tooltip arrow title='View Course'>
                                        <span><InfoIcon fontSize='small' style={{cursor:'pointer',marginLeft:'5px'}}/></span>
                                    </Tooltip>
                                </Col>
                                </>
                                :''

                            }
                            

                            <Col md={5} className="assign-details-card-div-head">
                                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>{" "}</span>
                                {"Status"}
                            </Col>
                            <Col md={7} className="assign-details-card-div">
                                {" "}{ticket_details.status}
                            </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>

                {/* <Col sm={12} md={6} style={{marginTop:'5px'}}>
                    <Card>
                        <CardHeader>
                            Description
                        </CardHeader>
                        <CardBody>
                            asdds
                        </CardBody>
                    </Card>
                </Col> */}
            </Row>

            {
                Chatdata.length > 0 ?
<Row style={{marginTop:'10px'}}>
                <Col xs={12}>
                    <Card className="main-card mb-3">
                        <CardHeader>Chat Box</CardHeader>
                        <div className="scroll-area-sm" style={{minHeight:'400px'}}>
                            <PerfectScrollbar>
                                <div className="chat-wrapper p-1">
                                    <Grid container>
                                {
                                        Chatdata.map((val,ky)=>{
                                            if(val.type==='right'){
                                                return(
                                                    <Grid item xs={12} style={{textAlign:'right'}}>
                                                        <div className="float-right">
                                                            <div className="chat-box-wrapper chat-box-wrapper-right">
                                                                <div style={{display:'block'}}>
                                                                    <div className="chat-box" style={{display:'flex',justifyContent:'flex-end'}}>
                                                                        <div>
                                                                            {val.message}
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <small className="opacity-6">
                                                                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                                        {val.created_at}
                                                                    </small>
                                                                </div>
                                                                <div>
                                                                    <div className="avatar-icon-wrapper ml-1">
        
                                                                        <div className="avatar-icon avatar-icon-lg rounded">
                                                                            <img src={avatar1} alt=""/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                )
                                            }
                                            else{
                                                return(
                                                    <Grid item xs={12}>
                                                        <div className="chat-box-wrapper">
                                                        <div>
                                                            <div className="avatar-icon-wrapper mr-1">
                                                    
                                                                <div className="avatar-icon avatar-icon-lg rounded">
                                                                    <img src={avatar1} alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="chat-box">
                                                                {val.message} 
                                                            </div>
                                                            <small className="opacity-6">
                                                                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                                {val.created_at}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </Grid>

                                                )
                                            }
                                            
                                        })
                                    }
                                    {/* <div className="chat-box-wrapper">
                                        <div>
                                            <div className="avatar-icon-wrapper mr-1">

                                                <div className="avatar-icon avatar-icon-lg rounded">
                                                    <img src={avatar1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="chat-box">
                                                But I must explain to you how all this mistaken idea of
                                                denouncing pleasure and praising pain was born and I will
                                                give you a complete account of the system.
                                            </div>
                                            <small className="opacity-6">
                                                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                11:01 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        <div className="chat-box-wrapper chat-box-wrapper-right">
                                            <div>
                                                <div className="chat-box">
                                                    Expound the actual teachings of the great explorer of
                                                    the truth, the master-builder of human happiness.
                                                </div>
                                                <small className="opacity-6">
                                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                    11:01 AM | Yesterday
                                                </small>
                                            </div>
                                            <div>
                                                <div className="avatar-icon-wrapper ml-1">

                                                    <div className="avatar-icon avatar-icon-lg rounded">
                                                        <img src={avatar1} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-box-wrapper">
                                        <div>
                                            <div className="avatar-icon-wrapper mr-1">
                                    
                                                <div className="avatar-icon avatar-icon-lg rounded">
                                                    <img src={avatar1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="chat-box">
                                                But I must explain to you how all this mistaken idea of
                                                denouncing pleasure and praising pain was born and I will
                                                give you a complete account of the system.
                                            </div>
                                            <small className="opacity-6">
                                                <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                11:01 AM | Yesterday
                                            </small>
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        <div className="chat-box-wrapper chat-box-wrapper-right">
                                            <div>
                                                <div className="chat-box">
                                                    Expound the actual teachings.
                                                </div>
                                                <small className="opacity-6">
                                                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-1"/>
                                                    11:01 AM | Yesterday
                                                </small>
                                            </div>
                                            <div>
                                                <div className="avatar-icon-wrapper ml-1">

                                                    <div className="avatar-icon avatar-icon-lg rounded">
                                                        <img src={avatar1} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    </Grid>
                                </div>
                            </PerfectScrollbar>
                        </div>
                        <CardFooter>
                            <Input type="text" placeholder="Type a message..." onChange={Handlemessage}  value={message ? message:''}/>
                            {/* <span><SendIcon fontSize='large' style={{color:'#C0C0C0',cursor:'pointer'}} onClick={SendMessage}/></span> */}
                            <Button  onClick={SendMessage} disabled={message ? false:true}><SendIcon fontSize='large' style={{color:'#C0C0C0',cursor:'pointer'}}/></Button>

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            :''
            }
            

            <Model
                open={Open}
                handleClose={CloseTicket}
                title={'Ticket Status'}
            >   
                <Container>
                    <Row>
                        <Col sm={12}>
                        <FormGroup>
                            <Label for="exampleSelect">Change Status</Label>
                            <Input type="select" name="select" id="exampleSelect" onChange={Submitstatus}>
                                <option value='select'>Select</option>
                                <option value='Inprogress'>In Progress</option>
                                <option value='Resolved'>Resolved</option>
                            </Input>
                        </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                    <Col xs={12}>
                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                            loading={status_update_loader}
                            type="button"
                            onClick={() => Submit()}
                            data-style={ZOOM_IN}
                        >
                            Submit
                        </LaddaButton>
                    </Col>
                    </Row>
                </Container>
            </Model>


            <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Status Updated successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />
        </Container>
    )
}
export default TicketDetails