import React, { useState,useEffect } from 'react'
// import swal from 'sweetalert'
import {
    Row, Col, Form, FormGroup, Input,
    FormFeedback,
    Card, CardBody, Label,
    CardTitle
} from 'reactstrap';
// import Modal from 'react-modal';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import SweetAlert from 'sweetalert-react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {  requiredValidator } from '../../../utilities/Validator'
import "react-datepicker/dist/react-datepicker.css";


// import Tabl from '../../common/DataTable'
import moment from 'moment'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { validateClassTiming, editClass, getEditFormDetails } from '../../../redux/Actions/homeTeacher'
import { useHistory, useParams } from "react-router-dom";
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
// import DatePicker from 'react-datepicker';
import CreateTimeSlot from '../EditClass/CreateTimeSlot'
// import TimeSlot from './timeSlotCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock, faWindowClose,

} from '@fortawesome/free-solid-svg-icons';
// import { set } from 'date-fns';
// import ShowClass from '../../Class/ShowClass'
import { DatePicker  } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const Index = () => {


    // alert(dd)
    const dispatch = useDispatch();
    const history = useHistory();
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    // const inputData = tStore.editFormDetails;
//     const Details = useSelector((state) =>
//     state.homeTeacher.editClassDetails ? state.homeTeacher.editClassDetails : ""
//   );
    const [editForm, setEditForm] = useState(null)
    const [OnedayClass, setOnedayClass] = useState(false)

    // const fromdateRef = useRef();
    const [slotError, setslotError] = useState({})
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [CourseStartDate, setCourseStartDate] = useState()
    const [CourseEndDate, setCourseEndDate] = useState()
    const [ClassStart, setClassStart] = useState()
    const [ClassEnd, setClassEnd] = useState()
    //const st = useSelector(state => state)
    const param = useParams()
    //console.log("tStore-------", st)
    // console.log("tStore.editFormDetails", tStore.editFormDetails)
    
    // console.log("class start dateee", ClassStart)
    // const [reset, setReset] = useState()
    useEffect(() => {
        //alert(moment("19:10", 'hh:mm A').isValid())
        if (param.course_id == 'null') {
            history.goBack()
        }
        dispatch(getEditFormDetails(tokenData.token, param.class_id, param.course_id, cb))
    }, []);
    const [validateData, setValidayeData] = useState({
        class_from_date: null,
        class_to_date: null,
        day: null
    })

    // const months = [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December"
    //   ];



    //special class toggle fuctionality here......
    const [specialClass, setSpecialClass] = useState(false)
    // const changeSpecialClass = () => {
    //     // debugger
    //     // console.log({ validateData });

    //     // setValidayeData({
    //     //     // class_from_date: null,
    //     //     // class_to_date: null,
    //     //     // day: null
    //     // })
    //     setSpecialClassDay('')
    //     const reffffff = fromdateRef.current.props.selected
    //     const dt = moment(reffffff).format('dddd');
    //     setSpecialClassDay(dt)
    //     if (specialClass === false) {
    //         setSpecialClass(!specialClass)
    //         setEditForm({ ...editForm, timing: [] })
    //     } else {
    //         setSpecialClass(!specialClass)
    //         setEditForm(baseState)
    //     }

    // }
    //end here.....
    const [tempCart, setTempCard] = useState({
        class_from_date: null,
        class_to_date: null,
        day: null,
        class_from_time:'',
        teacher_name:'',
        group_name:'',
        class_to_time: '',
        class_type: specialClass ? 'special' : 'sheduled'
    })
    const [previousTime, setPreviousTime] = useState([])
    const [baseState, setBaseState] = useState()
    const [originalData, setOriginalData] = useState()
    const [initialTodate, setInitialTodate] = useState()
    const [DateRange, setDateRange] = useState([])

    const cb = (fromData,courseData) => {
        // console.log("fromData@@@@", fromData)
        fromData.class_from_date=fromData.class_from_date.split(" ")[0]
        fromData.class_to_date=fromData.class_to_date.split(" ")[0]
        setEditForm(fromData)
        setOriginalData(fromData)
        setInitialTodate(moment(fromData.class_to_date).format('YYYY-MM-DD'))
        setValidayeData({ ...validateData, class_from_date: fromData.class_from_date, class_to_date: fromData.class_to_date })
        setBaseState(fromData)
        // console.log("crs date",courseData)
        var str = moment(fromData.class_from_date).format('YYYY/MM/DD')
        var end = moment(fromData.class_to_date).format('YYYY/MM/DD')
        var str2 = new Date(str);
        var end2 = new Date(end)
        // console.log("detuio",str,end,str2,end2)
        setTempCard({...tempCart, class_from_date : str2, class_to_date:end2})
        setCourseStartDate(courseData.course_start_date)
        setCourseEndDate(courseData.course_end_date)
        setClassStart(str2)
        setClassEnd(end2) 
        getDatesBetweenDates(str2,end2) 
        courseData.live_class_type==="oneday"?setOnedayClass(true):setOnedayClass(false);
    }


// console.log("course strrrrrr",CourseStartDate)
// console.log("course strrrrrr",CourseStartDate)
    const getDatesBetweenDates = (startDate, endDate) => {
        // console.log("dates",startDate,endDate)
        let dates = [];
        const theDate = new Date(startDate);
        while (theDate <= endDate) {
          dates = [...dates, new Date(theDate)];
          theDate.setDate(theDate.getDate() + 1);
        }
        setDateRange(dates);
        return
      };

    // console.log("previousTime", previousTime)
    // setTimeout(function () {
    //     setEditForm(tStore.editFormDetails)
    // }, 1000)


    // const editData = (data) => {
    //     setEditForm(data)
    //     setRealCard(data.timing)
    // }
    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setEditForm({ ...editForm, [name]: value })
    }

    // const [removedTimeSlot, setRemovedTimeSlot] = useState([])
    const [specialClassDay, setSpecialClassDay] = useState()
    //console.log({ specialClassDay });
   
    const setStartTime = (e) => {
      
        let a = String(e)
        let  b = a.slice(16,21)
          
        setTempCard({ ...tempCart, key: uuid(), class_from_time :b })
    
          //setinputField({...inputField, class_start_date:b})
      }
      const setEndTime = (e) => {
      
        let a = String(e)
        let  b = a.slice(16,21)
          
        setTempCard({ ...tempCart, key: uuid(), class_to_time :b })
    
          //setinputField({...inputField, class_start_date:b})
      }

    // const handleStartDate = (e,name) => {

    //    // const {  value } = e;
    //     const value = e;
        
    //     if(editForm.timing.length>0){
    //       swal({
    //         title: "Are you sure?",
    //         text: "If you change date, Slots will be cleared!",
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //       })
    //       .then((willDelete) => {
    //         if (willDelete) {
    //           editForm.timing=[]
    //           var d = new Date(value);
    //          // setclassStart(d);
            
    //           setTempCard({ ...tempCart, class_from_date: value })
    //           setEditForm({ ...editForm, [name]: e, class_to_date: '' })
    //           if(OnedayClass){
    //             getDatesBetweenDates(d, d);
    //             setTempCard({ ...tempCart, class_from_date: value, class_to_date: value })

    //           //  setinputField({ ...inputField, class_start_date:value, class_to_date: value });
    //           }
    //         } else {
    //         //   console.log("not cleared")
    //         }
    //       })
    //     }
        
    //     else{
    //       var d = new Date(e);
    //      // setclassStart(d);
    //       setTempCard({ ...tempCart, class_from_date: value })
    //      // setinputField({ ...inputField, [name]: value });
      
    //       if(OnedayClass){
    //         getDatesBetweenDates(d, d);
    //         setTempCard({ ...tempCart, class_from_date: value, class_to_date: value })
    //       //  setinputField({ ...inputField, class_start_date:value, class_to_date: value });
    //       }
    //     }
    // }
    
    // const handleEndDate = (e,name) => {
     
    //  let value=e   
    //   if(editForm.timing.length>0){
    //     swal({
    //       title: "Are you sure?",
    //       text: "If you change date, Slots will be cleared!",
    //       icon: "warning",
    //       buttons: true,
    //       dangerMode: true,
    //     })
    //     .then((willDelete) => {
    //       if (willDelete) {
    //         editForm.timing=[]
    //         //const { name, value } = e.target;
    //         var dt = new Date(e);
            // console.log("as",dt)
    //         setclassEnd(dt);
    //         setTempCard({ ...tempCart, class_to_date: value })
    //         setEditForm({ ...editForm, class_to_date: e })
    //         // setinputField({ ...inputField, [name]: value });
    //         getDatesBetweenDates(classStart, dt);
    //       } else {
            // console.log("not cleared")
    //       }
    //     })
    //   }  
    //   else{
    //    // const { name, value } = e.target;
    //     var dt = new Date(e);
    //     setclassEnd(dt);
    //     setTempCard({ ...tempCart, class_to_date: value })
    //     // setinputField({ ...inputField, [name]: value });
    //     getDatesBetweenDates(classStart, dt);
    //   }  
    // }

 
// console.log("Editform again",editForm )

    const handleChangeFormDate = (e, name) => {
        // console.log(`eee${e}-${name}`)
        const dt = moment(e).format('YYYY-MM-DD');
        //debugger
        
        setEditForm({ ...editForm, [name]: dt })

        // console.log('eeeeeeeeeeeeeeeeeeee',e)
        if (specialClass) {
            const dt = moment(e).format('dddd');
            setSpecialClassDay(dt)
        }
        if (editForm.timing.length > 0) {

            let confirmBox = window.confirm("If you change the date,time slots will delete ")


            // Swal.fire({
            //     icon: 'warning',
            //     title: 'Are you sure !',
            //     text: 'All the existing slots for the class will be deleted',
            
            //     showCancelButton: true,
            //     confirmButtonColor: "#DD6B55",
            //     confirmButtonText: "CONFIRM",
            //     cancelButtonText: "CANCEL",
            
            // }).then((result) => {
            //     if (result.value) {
                    
            //         editForm.timing = []
            //     if (name === 'class_from_date') {
            //         setClassStart(e)
            //         setEditForm({ ...editForm, [name]: dt })
            //         setTempCard({...tempCart, class_from_date : e, class_to_date:ClassEnd})
            //         setValidayeData({ ...validateData, [name]: dt })
            //         getDatesBetweenDates(e,ClassEnd)
                    // console.log("validateData", validateData.class_to_date)
            //     } else {
            //         setClassEnd(e)
            //         setEditForm({ ...editForm, [name]: dt })
            //         setValidayeData({ ...validateData, [name]: dt })
            //         setTempCard({...tempCart, class_from_date : ClassStart, class_to_date:e})
            //         getDatesBetweenDates(ClassStart,e)
            //     }
                    
            
            //     } else if (result.dismiss === Swal.DismissReason.cancel) {
                   
            //         if (name === 'class_from_date') {
            //             setClassStart(e)
            //             setEditForm({ ...editForm, [name]: e, class_to_date: '' })
            //             setValidayeData({ ...validateData, [name]: e })
            //             getDatesBetweenDates(e,ClassEnd)
            //             setTempCard({...tempCart, class_from_date : e, class_to_date:ClassEnd})
                        // console.log("validateData", validateData.class_to_date)
            //         } else {
            //             setClassEnd(e)
            //             setEditForm({ ...editForm, [name]: e })
            //             setValidayeData({ ...validateData, [name]: e })
            //             setTempCard({...tempCart, class_from_date : ClassStart, class_to_date:e})
            //             getDatesBetweenDates(ClassStart,e)
            //         }
            //     }
            // })


            if (confirmBox) {
                //setRemovedTimeSlot(editForm.timing)
                // debugger
               // const ids = tStore.editFormDetails.timing.map(id => id.key)

               // setPreviousTime(ids)
                editForm.timing = []
                if (name === 'class_from_date') {
                    setClassStart(e)
                    setEditForm({ ...editForm, [name]: dt })
                    setTempCard({...tempCart, class_from_date : e, class_to_date:ClassEnd})
                    setValidayeData({ ...validateData, [name]: dt })
                    getDatesBetweenDates(e,ClassEnd)
                    if(OnedayClass){
                        setClassEnd(e)
                        setTempCard({...tempCart, class_to_date:e})
                        setValidayeData({ ...validateData,class_to_date: dt })
                        getDatesBetweenDates(e,e)
                    }
                } else {
                    setClassEnd(e)
                    setEditForm({ ...editForm, [name]: dt })
                    setValidayeData({ ...validateData, [name]: dt })
                    setTempCard({...tempCart, class_from_date : ClassStart, class_to_date:e})
                    getDatesBetweenDates(ClassStart,e)
                }
               // getDatesBetweenDates(editForm.class_from_date,editForm.class_end_date );
            }

        } else {
            if (name === 'class_from_date') {
                setClassStart(e)
                setEditForm({ ...editForm, [name]: e, class_to_date: '' })
                setValidayeData({ ...validateData, [name]: e })
                setTempCard({...tempCart, class_from_date : e, class_to_date:ClassEnd})
                if(OnedayClass){
                    setClassEnd(e)
                    setTempCard({...tempCart, class_to_date:e})
                    setValidayeData({ ...validateData,class_to_date: dt })
                    getDatesBetweenDates(e,e)
                }
                // console.log("validateData", validateData.class_to_date)
                getDatesBetweenDates(e,ClassEnd)
            } else {
                setClassEnd(e)
                setEditForm({ ...editForm, [name]: e })
                setValidayeData({ ...validateData, [name]: e })
                setTempCard({...tempCart, class_from_date : ClassStart, class_to_date:e})
               
                getDatesBetweenDates(ClassStart,e)
        
        }
    
    }}
    // console.log("validateData", validateData)
    // console.log("won day", OnedayClass)



    const handleChadeCard = (e) => {
        // debugger

        const { id, value } = e.target;
        // alert(value)

        if (id==="teacher_id") {
            var teacher_name = e.target[e.target.selectedIndex].getAttribute("t-name")
        //    console.log("teacher naaam",teacher_name)
            setTempCard({ ...tempCart, key: uuid(), teacher_name : teacher_name,[id] : value})
          }
         else if (id==="group_id") {
            var group_name = e.target[e.target.selectedIndex].getAttribute("g-name")
            setTempCard({ ...tempCart, key: uuid(), group_name : group_name,[id] : value})
          }

       else setTempCard({ ...tempCart, key: uuid(), [id]: value, class_type: specialClass ? 'special' : 'sheduled' })


        setValidayeData({ ...validateData, [id]: value })

    }
//   console.log("temp card",tempCart)
    const removeCardFromEdit = (id) => {
        // Swal.fire({
        //     icon: 'error',
        //     title: '',
        //     text: id,

        // })
        const updatedCard = editForm.timing.filter(card => card.key !== id)
        // console.log("updatedCard-------------------------------------", updatedCard)
        setEditForm({ ...editForm, timing: updatedCard })
        // console.log("editForm---", editForm)
        setPreviousTime([...previousTime, id])

    }
    // console.log("tempCartooooo", tempCart)
    // const [createTimeSlot, setTimeSlot] = useState([
    //     {
    //         key: uuid(),
    //         days: [
    //             { day: null, val: null },
    //             { day: 'Sunday', val: 'sun' },
    //             { day: 'Monday', val: 'mon' },
    //             { day: 'Tuesday', val: 'tue' },
    //             { day: 'Wednesday', val: 'wed' },
    //             { day: 'Thursday', val: 'thu' },
    //             { day: 'Friday', val: 'fri' },
    //             { day: 'Saturday', val: 'sat' }]
    //     }

    // ])
    const [slotLoader, setSlotLoader] = useState(false)
    // const loaderFalse = (res) => {
    //     setSlotLoader(false)
    //     Swal.fire({
    //         icon: 'error',
    //         title: '',
    //         text: res,

    //     })

    // }

    const validateCard = (cid) => {
        let err = null
        // console.log("is it class start",ClassStart)
        // console.log("is it class start",ClassEnd)
        if(OnedayClass){
            setTempCard({...tempCart, class_from_date : ClassStart,class_end_date:ClassStart})
        }
        setTempCard({...tempCart, class_from_date : ClassStart,class_end_date:ClassEnd})
       // setTempCard({...tempCart, class_to_date : ClassEnd})
        //console.log("temp",tempCart)
        // var startTime = moment(validateData.class_from_time, 'hh:mm');
        // var endTime = moment(validateData.class_to_time, 'hh:mm');
        // var duration = endTime.diff(startTime, 'minutes');
        // console.log(`diff:${duration}`)

        err ={
            day: requiredValidator(tempCart.day),
            teacher: requiredValidator(tempCart.teacher_id),
            group: requiredValidator(tempCart.group_id),
            class_from_time: requiredValidator(tempCart.class_from_time),
            class_to_time: requiredValidator(tempCart.class_to_time),
          }

        //   console.log("temp carttttt",tempCart)
          if(!err.day && !err.teacher && !err.group && !err.class_from_time && !err.class_to_time){
            setSlotLoader(true)
            //var startTime = moment(classStart, "hh:mm");
            //var endTime = moment(classEnd, "hh:mm");
            //var duration = endTime.diff(startTime, "minutes");
            dispatch(validateClassTiming(tokenData.token,tempCart,createCard))
          }
        
          else{
              setslotError(err);
          }
        

    }
    // const validateCard = () => {

    //     var startTime = moment(validateData.class_from_time, 'hh:mm');
    //     var endTime = moment(validateData.class_to_time, 'hh:mm');
    //     var duration = endTime.diff(startTime, 'hours');
        // console.log(`diff--${duration}`)

    //     //         if (tempCart.class_from_time > tempCart.class_to_time || tempCart.class_from_time == tempCart.class_to_time) {
    //     //             alert(`Start time should be less than End time
    //     // or 
    //     // should not be same`)


    //     //         }
    //     // else {

        // console.log(`valu--------${validateData.class_from_date} ---${validateData.class_to_date}---${tempCart.class_from_time}---${tempCart.class_to_time}---${tempCart.day}`)

    //     if (validateData.class_from_date &&
    //         validateData.class_to_date &&
    //         tempCart.class_from_time &&
    //         tempCart.class_to_time &&
    //         tempCart.day !== null || '') {
    //         // if (!(duration > 0)) {
    //         //     Swal.fire({
    //         //         icon: 'error',
    //         //         title: '',
    //         //         text: 'You have to start min one hour class',

    //         //     })
    //         // }
    //         // else {
    //         dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse, previousTime, removedTimeSlot))
    //         setSlotLoader(true)
    //         // }



    //     }
    //     else {
    //         Swal.fire({
    //             icon: 'error',
    //             title: '',
    //             text: 'Fill the required fields to create slot',

    //         })
    //     }
    //     // }



    // }
    const createCard = () => {
        //setDateDisabliyt(true)
        // console.log("valid")
        let err;
        //setEditForm({ ...editForm, timing: [...editForm.timing, tempCart] })
        // console.log("after create-----------", editForm.timing)
        //setTempCard()
        //debugger
        // console.log("tempCart", tempCart)
        if (editForm.timing.length === 0) {
            //setEditForm({...editForm,[editForm.timing],tempCart})
            editForm.timing.push(tempCart)
            // console.log(editForm)
            setTempCard({...tempCart, day:'',class_from_time:'',class_to_time:'',teacher_name:'',teacher_id:'',group_id:'',group_name:''})
            setSlotLoader(false)
            setslotError({})
            document.getElementById("edit_form").reset();
        }
        else {
            //let err;
            editForm.timing.forEach(item => {
                if (tempCart.day === item.day && ((tempCart.teacher_id === item.teacher_id) || (tempCart.group_id === item.group_id))) {
                    // console.log("hii")
                    if (tempCart.class_from_time !== item.class_from_time || tempCart.class_to_time !== item.class_to_time) {
                        const format1 = moment(item.class_from_time, 'hh:mm A').format("HH:mm")
                        // const format2 = moment(item.class_from_time, 'h:mm A').format("HH:mm")
                        // const format11 = moment(item.class_to_time, 'h:mm A').format("HH:mm")

                        const format2 = moment(item.class_to_time, 'hh:mm A').format("HH:mm")
                        // const test = moment('11:10', 'HH:mm').isBetween(moment(format1, 'HH:mm'), moment(format2, 'HH:mm'));

                        const fr = moment(tempCart.class_from_time, 'hh:mm').isBetween(moment(format1, 'HH:mm'), moment(format2, 'HH:mm'));
                        const to = moment(tempCart.class_to_time, 'hh:mm').isBetween(moment(format1, 'HH:mm'), moment(format2, 'HH:mm'));
                        // debugger
                        const format3 = moment(item.class_from_time, 'h:mm A').format("hh:mm")
                        const format4 = moment(item.class_to_time, 'h:mm A').format("hh:mm")

                        const rfr = moment(format3, 'HH:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));
                        const rto = moment(format4, 'HH:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));

                        if (fr || to || rfr || rto) {
                            // console.log(`rrr${fr}-${to}-${rfr}-${rto}`)
                            err = "FROM or TO time slot already taken";
                        }
                    }
                    // else if (tempCart.from == item.from || tempCart.to == item.to) {
                    //   err = "from time t0 time same so notpossible";
                    // }
                    else {
                        err = "FROM or TO time slot already taken !";
                    }
                }
            })
            if (!err) {
                setEditForm({ ...editForm, timing: [...editForm.timing, tempCart] })
                setTempCard({...tempCart, day:'',class_from_time:'',class_to_time:'',teacher_name:'',teacher_id:'',group_id:'',group_name:''})
                 document.getElementById("edit_form").reset();
                setSlotLoader(false)
                setslotError({})
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Not avilable',
                    text: 'This slot is not possible',

                })
                setSlotLoader(false)
            }
        }

        // console.log("tmp=============", tempCart)

        // console.log("editForm=============", editForm)
    }
    const [successModal, setSuccessModal] = useState(false)
    const success = () => {
        setSuccessModal(true)
    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
       // history.push(`/dashboard/editCourse/${param.course_id}`)
    }
    const [errors, setErrors] = useState({})

   // const [classStart, setclassStart] = useState()
    //const [classEnd, setclassEnd] = useState()
    const submitForm = (e) => {
        e.preventDefault();
        const err = {
            class_name: requiredValidator(editForm.class_name),
        }
        // console.log("err", err);

        if (!err.class_name) {
            // console.log("err--", err);

            setErrors(err)

            if (editForm.timing.length > 0) {
                const form = e.currentTarget
                let body = serialize(form, { hash: true, empty: true })
                // console.log("bodddddyyyyyy", body);
                dispatch(editClass(tokenData.token, body, success))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: 'You have to create minimum time slot',

                })
            }
        } else {
            setErrors(err)
            Showerror('class_name')
        }
    }

    const Showerror=(val)=>{
        var elmnt = document.getElementById(val);
        elmnt.scrollIntoView();
    }

    // console.log("editForm---", editForm)
    const resetForm = () => {
        // console.log("reset data===", baseState)
        setSpecialClass(!setSpecialClass)
        setEditForm(baseState)

    }
    return (<>


        <Form onSubmit={submitForm} id="class-form" >

            {editForm ?
                <>
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle>
                                EDIT CLASS
            </CardTitle>

                            <FormGroup row>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Class Name
                                            </Label>
                                            <Input
                                                type="text"
                                                name="class_name"
                                                id='class_name'
                                                value={editForm.class_name}
                                                onChange={handleChangeForm}
                                                disabled={specialClass}
                                                invalid={errors.class_name}
                                                autocomplete="off"
                                            />
                                            <FormFeedback >{errors.class_name}</FormFeedback>

                                            <input
                                                name='class_type'
                                                type="hidden"
                                                value={specialClass ? "special" : "sheduled"}
                                            />
                                            <input
                                                name='class_id'
                                                type="hidden"
                                                value={editForm.class_id}
                                            />
                                        </Col>
                                    </Row>


                                </Col>
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={6} >
                                        
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}From Date
                                            </Label>
                                            {/* <DatePicker
                                             //   selected={moment(editForm.class_from_date).toDate()}
                                                selected={ClassStart?ClassStart:""}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                //onChange={(e) => handleChange(e, 'class_from_date')}
                                                withPortal
                                                 minDate={moment(CourseStartDate)?.toDate()}
                                                 maxDate={moment(CourseEndDate)?.toDate()}
                                                required
                                                onChange={(e) => handleChangeFormDate(e, 'class_from_date')}
                                              // onChange={(e)=>handleStartDate(e,'class_from_date')}
                                                ref={fromdateRef}


                                            /> */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                format="dd/MM/yyyy"
                                                label=""
                                                size='small'
                                                inputVariant="outlined"
                                                value={ClassStart ? ClassStart:null}
                                                onChange={(e) => handleChangeFormDate(e, 'class_from_date')}
                                                minDate={moment(CourseStartDate)?.toDate()}
                                                maxDate={moment(CourseEndDate)?.toDate()}
                                                fullWidth
                                                required
                                                views={["year", "month", "date"]}
                                                openTo="year"
                                            />
                                            </MuiPickersUtilsProvider>

                                            {/* <DatePicker
                                             selected={ClassStart?ClassStart:""}
                                             dateFormat="dd/MM/yyyy"
                                             className="form-control"
                                             //onChange={(e) => handleChange(e, 'class_from_date')}
                                             withPortal
                                              minDate={moment(CourseStartDate)?.toDate()}
                                              maxDate={moment(CourseEndDate)?.toDate()}
                                             required
                                             onChange={(e) => handleChangeFormDate(e, 'class_from_date')}
                                           // onChange={(e)=>handleStartDate(e,'class_from_date')}
                                             ref={fromdateRef}
                                              renderCustomHeader={({
                                                date,
                                                decreaseMonth,
                                                increaseMonth,
                                                increaseYear,
                                                decreaseYear,

                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center"
                                                  }}
                                                >
                                                  <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                                    {"<<"}
                                                  </Button>
                                                  <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {"<"}
                                                  </Button>

                                                  <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                                                  <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                    {">"}
                                                  </Button>
                                                  <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                                    {">>"}
                                                  </Button>
                                                </div>
                                              )}

                                            /> */}
                                            
                                            <Input
                                                value={moment(editForm.class_from_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_from_date"
                                            />
                                            
                                            {/* <Input
                                value={validateData.class_from_date}
                                required
                                type="date"
                                name="class_from_date"
                                onChange={handleChange}
                                id="exampleDate"
                                placeholder="date placeholder"
                                min={moment().format("YYYY-MM-DD")}
                            /> 
                                            */}      </Col>
                                        <Col sm={6}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}To Date
                                            </Label>
                                            {/* <DatePicker

                                                className="form-control"
                                                selected={ClassEnd?ClassEnd:""}
                                               // selected={OnedayClass?moment(editForm.class_from_date).toDate():moment(editForm.class_to_date).toDate()}
                                                onChange={OnedayClass? "":(e) => handleChangeFormDate(e, 'class_to_date')}
                                              // onChange={(e)=>handleEndDate(e,'class_to_date')}
                                                disabled={OnedayClass}
                                                withPortal
                                                dateFormat="dd/MM/yyyy"
                                               minDate={ClassStart?ClassStart:""}
                                               // minDate={moment(validateData.class_from_date).add(7, 'd')._d}
                                             //  minDate={moment(CourseStartDate)?.toDate()}
                                             //  minDate={moment(ClassStart)?.toDate()} 
                                               maxDate={moment(CourseEndDate)?.toDate()} 
                                               required
                                            /> */}

                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    format="dd/MM/yyyy"
                                                    label=""
                                                    size='small'
                                                    inputVariant="outlined"
                                                    value={ClassEnd?ClassEnd:null}
                                                    onChange={OnedayClass? "":(e) => handleChangeFormDate(e, 'class_to_date')}
                                                    minDate={ClassStart?ClassStart:""}
                                                    maxDate={moment(CourseEndDate)?.toDate()} 
                                                    fullWidth
                                                    required
                                                    disabled={OnedayClass}
                                                    views={["year", "month", "date"]}
                                                    openTo="year"
                                                />
                                            </MuiPickersUtilsProvider>
                                    {/* <DatePicker
                                             className="form-control"
                                             selected={ClassEnd?ClassEnd:""}
                                            // selected={OnedayClass?moment(editForm.class_from_date).toDate():moment(editForm.class_to_date).toDate()}
                                             onChange={OnedayClass? "":(e) => handleChangeFormDate(e, 'class_to_date')}
                                           // onChange={(e)=>handleEndDate(e,'class_to_date')}
                                             disabled={OnedayClass}
                                             withPortal
                                             dateFormat="dd/MM/yyyy"
                                            minDate={ClassStart?ClassStart:""}
                                            // minDate={moment(validateData.class_from_date).add(7, 'd')._d}
                                          //  minDate={moment(CourseStartDate)?.toDate()}
                                          //  minDate={moment(ClassStart)?.toDate()} 
                                            maxDate={moment(CourseEndDate)?.toDate()} 
                                            required
                                              renderCustomHeader={({
                                                date,
                                                decreaseMonth,
                                                increaseMonth,
                                                increaseYear,
                                                decreaseYear,

                                                prevMonthButtonDisabled,
                                                nextMonthButtonDisabled
                                              }) => (
                                                <div
                                                  style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center"
                                                  }}
                                                >
                                                  <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                                    {"<<"}
                                                  </Button>
                                                  <Button size="sm" className="mr-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {"<"}
                                                  </Button>

                                                  <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                                                  <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                    {">"}
                                                  </Button>
                                                  <Button size="sm" className="ml-2" color='light' style={{fontWeight:'bold',fontSize:'18px'}} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                                    {">>"}
                                                  </Button>
                                                </div>
                                              )}

                                            /> */}


                                            <Input
                                                value={OnedayClass? moment(editForm.class_from_date).format('YYYY-MM-DD') : moment(editForm.class_to_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_to_date"
                                            />
                                            {/* <Input
                                required
                                type="date"
                                name="class_to_date"
                                onChange={handleChange}
                                id="exampleDate"
                                placeholder="date placeholder"
                                min={moment().format("YYYY-MM-DD")}

                            />  */}

                                        </Col>
                                    </Row>
                                </Col> 

                       
                       
                      
                        
                            </FormGroup>

                        </CardBody>
                    </Card>

                  {/*  handleChadeCard={handleChadeCard}
                    createTimeSlot={createTimeSlot}
                    createCard={validateCard}
                    tempCart={tempCart}
                    loader={slotLoader}
                    specialClass={specialClass}
                    changeSpecialClass={() => changeSpecialClass()}
                    mode={'edit'}
                        specialClassDay={specialClassDay} */}
                    <CreateTimeSlot

                        Errors={slotError}
                        tempCard={tempCart}
                        settempCard={setTempCard}
                        handleChangeInput={handleChadeCard}
                        dateRange={DateRange.slice(0,7)}
                        createCard={validateCard}
                        SlotLoader={slotLoader}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                    />


                    <Row>
                        {/* {console.log("editForm.timing", editForm.timing)} */}
                        {editForm.timing ?
                            editForm.timing.map(days => {
                                // console.log("formdata",editForm)
                                return (
                                    <Col md="6" lg="3">
                                        <Card className={`widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary ${days.class_type === "special" ? `border-danger` : `border-primary`} `}>
                                            <div className="widget-chat-wrapper-outer">

                                                <div className="widget-chart-content">
                                                    {days.starts_at !== null ?
                                                        <Row style={{ paddingBottom: '16px' }}>
                                                            <Col md={10} style={{ paddingLeft: '10px' }} ><strong>{days.starts_at}</strong></Col>
                                                            <Col md={2} ><FontAwesomeIcon 
                                                                onClick={() => removeCardFromEdit(days.key)}
                                                                icon={faWindowClose} /></Col>

                                                        </Row> :
                                                        <p style={{ textAlign: 'right' }}>
                                                            <FontAwesomeIcon style={{cursor:'pointer'}}
                                                                onClick={() => removeCardFromEdit(days.key)}
                                                                icon={faWindowClose} />
                                                        </p>

                                                    }


                                                    <div className="widget-title opacity-5 text-uppercase">
                                                        <h6>
                                                            {/* {`${days.class_from_time}-${days.class_to_time}`} */}
                                                            {`${days ?

                                                                (days.class_from_time.includes("AM") || days.class_from_time.includes("PM"))
                                                                    ?
                                                                    days.class_from_time
                                                                    : moment(days.class_from_time, 'hh:mm ').format("h:mm A")
                                                                //moment(days.class_from_time, 'hh:mm ').format("h:mm A")
                                                                : null} 
                                                                - 
                                                                ${days ?
                                                                    (days.class_to_time.includes("AM") || days.class_to_time.includes("PM")) ?
                                                                        days.class_to_time
                                                                        : moment(days.class_to_time, 'hh:mm ').format("h:mm A")
                                                                    //  moment(days.class_to_time, 'hh:mm ').format("h:mm A")
                                                                    : null}`}
                                                        </h6>
                                                        
                                                        <p>{days.teacher_name}</p>
                                                        <p>{days.group_name}</p>
                                                        
                                                        <input type="hidden"
                                                            name={days.day === 'sun' ? "week[sun][class_category][]" :
                                                                days.day === 'mon' ? "week[mon][class_category][]" :
                                                                    days.day === 'tue' ? "week[tue][class_category][]" :
                                                                        days.day === 'wed' ? "week[wed][class_category][]" :
                                                                            days.day === 'thu' ? "week[thu][class_category][]" :
                                                                                days.day === 'fri' ? "week[fri][class_category][]" :
                                                                                    days.day === 'sat' ? "week[sat][class_category][]" :
                                                                                        "name from"
                                                            }
                                                            value={days ? days.class_type : "sheduled"}

                                                        />
                                                        <input
                                                            // var dt= moment("12:15 AM", ["h:mm A"]).format("HH:mm");
                                                            value={days ?
                                                                // (days.class_from_time.includes("AM") || days.class_from_time.includes("PM"))
                                                                //     ?
                                                                //     days.class_from_time.replace(/AM|PM/g, '') :
                                                                //     days.class_from_time 
                                                                moment(days.class_from_time, ["h:mm A"]).format("HH:mm")
                                                                : null}
                                                            type="hidden"
                                                            name={

                                                                days.day === 'sun' ? "week[sun][class_from_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_from_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_from_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_from_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_from_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_from_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_from_time][]" :
                                                                                            "name from"
                                                            }
                                                            id="from"
                                                        />
                                                        <input type="hidden"
                                                            value={days ?
                                                                // (days.class_to_time.includes("AM") || days.class_to_time.includes("PM"))
                                                                //     ?
                                                                //     days.class_to_time.replace(/AM|PM/g, '') :
                                                                //     days.class_to_time 
                                                                moment(days.class_to_time, ["h:mm A"]).format("HH:mm")

                                                                : null}
                                                            // value={days ? days.class_to_time : null}
                                                            id='to'
                                                            name={
                                                                days.day === 'sun' ? "week[sun][class_to_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_to_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_to_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_to_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_to_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_to_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_to_time][]" :
                                                                                            "name to"
                                                            }
                                                        />
                                                        <input type="hidden"
                                                            value={days ? days.holiday_at : null}

                                                            name={
                                                                days.day === 'sun' ? "week[sun][holiday_at][]" :
                                                                    days.day === 'mon' ? "week[mon][holiday_at][]" :
                                                                        days.day === 'tue' ? "week[tue][holiday_at][]" :
                                                                            days.day === 'wed' ? "week[wed][holiday_at][]" :
                                                                                days.day === 'thu' ? "week[thu][holiday_at][]" :
                                                                                    days.day === 'fri' ? "week[fri][holiday_at][]" :
                                                                                        days.day === 'sat' ? "week[sat][holiday_at][]" :
                                                                                            null
                                                            }
                                                        />
                                                         <input
                                type="hidden"
                                value={days ? days.teacher_id : null}
                                id="to"
                                name={
                                  days.day === "sun"
                                    ? "week[sun][teacher_id][]"
                                    : days.day === "mon"
                                      ? "week[mon][teacher_id][]"
                                      : days.day === "tue"
                                        ? "week[tue][teacher_id][]"
                                        : days.day === "wed"
                                          ? "week[wed][teacher_id][]"
                                          : days.day === "thu"
                                            ? "week[thu][teacher_id][]"
                                            : days.day === "fri"
                                              ? "week[fri][teacher_id][]"
                                              : days.day === "sat"
                                                ? "week[sat][teacher_id][]"
                                                : "name to"
                                }
                              />
                              <input
                                type="hidden"
                                value={days ? days.group_id : null}
                                id="to"
                                name={
                                  days.day === "sun"
                                    ? "week[sun][group_id][]"
                                    : days.day === "mon"
                                      ? "week[mon][group_id][]"
                                      : days.day === "tue"
                                        ? "week[tue][group_id][]"
                                        : days.day === "wed"
                                          ? "week[wed][group_id][]"
                                          : days.day === "thu"
                                            ? "week[thu][group_id][]"
                                            : days.day === "fri"
                                              ? "week[fri][group_id][]"
                                              : days.day === "sat"
                                                ? "week[sat][group_id][]"
                                                : "name to"
                                }
                              />
                                                        <input type="hidden"
                                                            value={days ? days.starts_at : null}
                                                            name={
                                                                days.day === 'sun' ? "week[sun][start_at][]" :
                                                                    days.day === 'mon' ? "week[mon][start_at][]" :
                                                                        days.day === 'tue' ? "week[tue][start_at][]" :
                                                                            days.day === 'wed' ? "week[wed][start_at][]" :
                                                                                days.day === 'thu' ? "week[thu][start_at][]" :
                                                                                    days.day === 'fri' ? "week[fri][start_at][]" :
                                                                                        days.day === 'sat' ? "week[sat][start_at][]" :
                                                                                            null}
                                                        />
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="widget-chart-flex align-items-center">

                                                            <div>
                                                                <h4>
                                                                    {/* {days.day} */}
                                                                    {days ? days.day === 'sun' ? 'Sunday'
                                                                        : days.day === 'mon' ? 'Monday'
                                                                            : days.day === 'tue' ? 'Tuesday'
                                                                                : days.day === 'wed' ? 'Wednesday'
                                                                                    : days.day === 'thu' ? 'Thursday'
                                                                                        : days.day === 'fri' ? 'Friday'
                                                                                            : days.day === 'sat' ? 'Saturday' : null
                                                                        : null}
                                                                </h4>

                                                            </div>

                                                            <div style={{ display: 'none' }}>

                                                                <select
                                                                    id='day'
                                                                    name='day'
                                                                >
                                                                    <option value={days ? days.day : null}>
                                                                        {days ? days.day === 'sun' ? 'Sunday'
                                                                            : days.day === 'mon' ? 'Monday'
                                                                                : days.day === 'tue' ? 'Tuesday'
                                                                                    : days.day === 'wed' ? 'Wednesday'
                                                                                        : days.day === 'thu' ? 'Thursday'
                                                                                            : days.day === 'fri' ? 'Friday'
                                                                                                : days.day === 'sat' ? 'Saturday' : null
                                                                            : null}</option>
                                                                </select>

                                                            </div>
                                                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                                                <div className="ml-auto">
                                                                    <FontAwesomeIcon style={{ fontSize: '40px' }} color={days.class_type === "special" ? 'red' : "#545bd8"} icon={faClock} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            }) : []
                        }

                    </Row>

                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={tStore.ccLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                        UPDATE CLASS
     </LaddaButton>
                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        //loading={tStore.ccLoader}
                        onClick={() => resetForm()}
                        type="button"
                        data-style={ZOOM_IN}  >
                        RESET
     </LaddaButton>
                </>
                :
                <>

                    <Card style={{ padding: '30px' }}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                            <Skeleton height={30} count={5} />
                        </SkeletonTheme>
                    </Card>
                    <Card style={{ padding: '30px', marginTop: '25px' }}>
                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

                            <Skeleton height={30} count={4} />
                        </SkeletonTheme>
                    </Card>


                </>
            }

            <Input type='hidden' name='course_id' value={param.course_id}></Input>
        </Form>

        

        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Updated Successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />


    </>
    )

}

export default Index
