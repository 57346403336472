import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CustomInput, Form, FormGroup, Label, FormFeedback, Input } from 'reactstrap'
// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
// import PageTitle from '../HeaderTitle/PageTitle'
import './subject.scss'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeListLifeLong, } from '../../redux/Actions/signUp'
import { createSubjectLifeLong,  populateAllLifelongSubjects, updateSubjectLifeLond, deleteSubjectLifeLong,populateAllEnterpriseLifelongSubjects } from '../../redux/Actions/homeTeacher'
import serialize from 'form-serialize'
import Table from '../common/DataTable'
import Swal from 'sweetalert2'


// const columns = [
//     { title: 'teacher', field: 'first_name', editable: 'never' },
//     { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
//     { title: 'Course', field: 'grade_name', editable: 'never' },



// ]

const Index = () => {
    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    var columns
    if(tokenData.enterpriseUser){
        columns = [
            // { title: 'teacher', field: 'first_name', editable: 'never' },
            { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
            { title: 'Course', field: 'grade_name', editable: 'never' },    
        
        ]
    }else{
        columns = [
            { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
            { title: 'Course', field: 'grade_name', editable: 'never' },
        
        
        
        ]
        
    }

    useEffect(() => {
        dispatch(getGradeListLifeLong(null, 3))
        if(tokenData.enterpriseUser){
            
            dispatch(populateAllEnterpriseLifelongSubjects(tokenData.token))

        }else{
            dispatch(populateAllLifelongSubjects(tokenData.token))

        }
        // dispatch(populateRegisteredTeachers(tokenData.token))




        return () => {

        }
    }, [])

    const onEdit = (rowData) => {
        // console.log("row-", rowData)
        dispatch(updateSubjectLifeLond(tokenData.token, rowData))

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubjectLifeLong(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }

    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(createSubjectLifeLong(tokenData.token, body, reset))

    }
    const reset = () => {
        document.getElementById("create-form-lifelong").reset();
    }
    return (
        <>




            <Form onSubmit={formSubmit} id="create-form-lifelong">


                <Card>
                    <Row className="jms-subject-row-pad">
                    {/* {tokenData.enterpriseUser ? <>

<Col sm={3}>
        <Label for="exampleNameF">
            <span className="text-danger">*</span>
            {' '}Select Teacher
         </Label>

        <Input type="select"
            // onChange={(e) => handleChangeGrade(e)}
            name='teacher_id'
            required
        // value={this.state.loaderType}
        >

                    <option value={''}>{'select'}</option>
                                    {tStore.registeredTeachers ?
                                        tStore.registeredTeachers.map(registeredTeachers => {
                                            return (
                                                <option value={registeredTeachers.user_id}>{registeredTeachers.first_name+" "+registeredTeachers.last_name}</option>
                                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                            )
                                        }) : []
                                    }

        </Input>
    </Col>
</> :null

} */}
                        <Col md={4}>
                            <input type="hidden" value={3} name="category_id" />

                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Course</Label>
                                <CustomInput type="select" name="grade_id"
                                    required
                                >
                                    <option value="">Select</option>

                                    {
                                        Grades.gradeLifeLong.grades ? Grades.gradeLifeLong.grades.map((data) => {
                                            return (
                                                <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                            )
                                        })
                                            : []
                                    }


                                </CustomInput>
                                <FormFeedback >oops! Type is required</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">Subject</Label>
                                <Input required type="text" name="subject_name" />
                                <FormFeedback >oops! Type is required</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label>

                                <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                    loading={tStore.createSubLoaderLifeLong}
                                    // onClick={formSubmit}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                    style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available' }}

                                >
                                    Add Subject
                                            </LaddaButton>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <hr />
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Subjects'}
                        columns={columns}
                        actions={"add_subject"}
                        data={tStore.subjectListLifeLong}
                        loader={tStore.lifeLongSubLoader}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </Col>

            </Row>



        </>

    )
}

export default Index
