import React, { Fragment,useState,useEffect,useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import Echo from 'laravel-echo';
import { useDispatch } from 'react-redux'
import ResizeDetector from 'react-resize-detector';
import { development } from '../../config.json';

import AppMain from '../../Layout/AppMain';
import IdleTimer from 'react-idle-timer'

import { logout } from '../../redux/Actions/auth'
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert-react';
import {logoutSession, setReqData} from "../../redux/Actions/Superadmin"
// import Swal from 'sweetalert2'
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

import { 
    Row, Col,
} from 'reactstrap';
import Button from '@material-ui/core/Button';
const options = {

    broadcaster: 'pusher',
    key: "key",
    //  wsHost: window.location.hostname,
    wsHost: development.socketUrl,
    // wsHost: "backend.mymots.com",

    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss']

};

const Main = (props) => {

    const dispatch = useDispatch()
    var mute = "mute";
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const role =tokenData ? tokenData.role ? tokenData.role:'':'' 
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const IdleTimerref=useRef(null)
    const history = useHistory();
    const [successModal, setSuccessModal] = useState(false);
    const [confirmmodel, setConfirmModel] = useState(false);
    // const [seconds, setSeconds] = useState(10);
    const prevent_idle_timeout = useSelector((state) =>state.superadmin.prevent_idle_timeout ? state.superadmin.prevent_idle_timeout : false);
    const seconds = useSelector((state) =>state.superadmin.seconds_session ? state.superadmin.seconds_session : 10);

    useEffect(() => {
        /////to hide initial loader////
        const loader = document.querySelector('.loaderinitialfront');
        loader.classList.add('loaderinitialfront--hide')
        dispatch(setReqData('seconds_session',10))

        const echo = new Echo(options);
        
        echo.channel("audioSettings").listen('toggleStudentMike', (e) => {
            if (e.message.mute_status !== mute) {
                dispatch({ type: 'BROADCAST_INDEX_PAGE', payload: e.message.student_id })
                mute = e.message.mute_status
            }

        });


        echo.channel(`classInprogress`).listen('classStarted', (e) => {
            ai(e.message.class_id)
        });

    }, [])

    useEffect(() => {
        if (seconds > 0 && confirmmodel) {
        //   setTimeout(() => setSeconds(seconds - 1), 1000);
          setTimeout(() =>  dispatch(setReqData('seconds_session',seconds-1)), 1000);
        } 
        if((seconds===1 ||seconds===0)&& confirmmodel){
            handleClose()
        }
      });
   


    const ai = (id) => {
        if (tStore.startClass_id === id) {
            alert("Same")
        }
        alert(id)
        alert(tStore.startClass_id)
    }

    let {
        colorScheme,
        enableFixedHeader,
        enableFixedSidebar,
        enableFixedFooter,
        enableClosedSidebar,
        closedSmallerSidebar,
        enableMobileMenu,
        enablePageTabsAlt,
    } = props;

    const onIdel=()=>{
        dispatch(setReqData('seconds_session',10))
        if(prevent_idle_timeout===false){
            if(tokenData){
                setConfirmModel(true)

                // setSuccessModal(true)
                // dispatch(logoutSession(tokenData.token))
                // dispatch(logout())
                // localStorage.removeItem("authDetails");
                // history.push('/login')
            }
            else{
                setConfirmModel(false)
            }
        }
        else{
            setConfirmModel(false)
        }
        
        
    }
   
    const handleCloseSuccesstModal=()=>{
        setSuccessModal(false)
        
    }
    const handleClose=()=>{
        setConfirmModel(false)
        setSuccessModal(true)
        dispatch(logoutSession(tokenData.token))
        dispatch(logout())
        localStorage.removeItem("authDetails");
        history.push('/admin')
    }
    const ContinueSesion=()=>{
        dispatch(setReqData('seconds_session',10))
        setConfirmModel(false)
    }

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <div className={cx(
                        "app-container app-theme-" + colorScheme,
                        { 'fixed-header': enableFixedHeader },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'fixed-footer': enableFixedFooter },
                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                        { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                        { 'sidebar-mobile-open': enableMobileMenu },
                        { 'body-tabs-shadow-btn': enablePageTabsAlt },
                    )}>
                        <AppMain />
                    </div>
                    {/* {
                        role==='student'?
                        <IdleTimer ref={IdleTimerref} timeout={120*1000} onIdle={onIdel}></IdleTimer>
                        :
                        role==='teacher' ? 
                        <IdleTimer ref={IdleTimerref} timeout={300*1000} onIdle={onIdel}></IdleTimer>
                        :
                        <IdleTimer ref={IdleTimerref} timeout={60*1000} onIdle={onIdel}></IdleTimer>

                    } */}
                    {
                        role==='superAdmin' ?
                        <IdleTimer ref={IdleTimerref} timeout={300*1000} onIdle={onIdel}></IdleTimer>
                        :''
                    }
                    <SweetAlert
                        title="Session Expired"
                        confirmButtonColor=""
                        show={successModal}
                        text="Your Session has expired. Please login again"
                        type='warning'
                        onConfirm={() => handleCloseSuccesstModal()} />

                        <Dialog
                            fullWidth={false}
                            maxWidth={'sm'}
                            open={confirmmodel}
                            onClose={handleClose}
                            aria-labelledby="max-width-dialog-title"
                        >
                            <DialogTitle style={{textAlign:'center'}} id="max-width-dialog-title">Session Time out!!!</DialogTitle>
                            <Divider/>
                            <DialogContent>
                                <DialogContentText>
                                    <Row>
                                        <Col xs={12} style={{padding:'20px'}}>
                                            You have been idle for a while! Do you want to continue?
                                        </Col>
                                        <Col xs={12} style={{textAlign:'center'}}> 
                                            <span style={{fontSize:'15px',fontWeight:'bold'}}>{seconds}</span>
                                        </Col>

                                        <Col xs={12} style={{textAlign:'center',padding:'20px'}}>
                                            <Button variant='contained' color='secondary' size='small' onClick={ContinueSesion} >CONTINUE</Button>
                                        </Col>
                                    </Row>
                                    
                                </DialogContentText>
                            
                            </DialogContent>
                        </Dialog>
                </Fragment>
            )}
        />
    )

}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));

