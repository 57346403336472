import React from 'react'
import DataTable from 'material-table'
//import './../../common/DataTable/styleee.scss'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
const Table = (props) => {
    // console.log("propss", props);
    return (

        <DataTable

            isLoading={props.loader}
            title={props.title ? props.title : 'Class'}
            columns={props.columns}
            handleOpenStudentGroupModal={(user_id,rowData) => props.handleOpenStudentGroupModal(user_id, rowData)}
            // BlockUnblock={(rowData)=>{BlockUnblock(rowData)}}
            data={props.data}
            options={{
                search: true,
                sorting: true,
                // rowStyle: {
                //     backgroundColor: '#EEE',
                // },
                actionsColumnIndex: -1
            }}
            editable={
                {
                    onRowUpdate: (newData) =>
                        new Promise((resolve) => {
                            props.onEdit(newData)
                            resolve()
                        }
                        )
                }}

            actions={
                props.actions ?

                    props.actions === "show_user" ?
                        [
                            {
                                icon: 'add',
                                tooltip: 'Permission',
                                onClick: (event, rowData) => props.onPermission(rowData.user_id)
                            },
                        
                            rowData => ({
                                icon: () => <PowerSettingsNewIcon
              
                                  style={rowData?.block === 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }} 
                                  />,
                                  tooltip: rowData?.block === 1 ? 'UnBlock' : 'Block',
                                  onClick: (event, rowData) => props.BlockUnblock(rowData),
              
                            }),
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => props.onDelete(rowData.user_id)
                            }

                        ]
                        : [
                            {
                                icon: 'supervisor_account',
                                tooltip: 'View Groups',
                                onClick: (event, rowData) => props.handleOpenStudentGroupModal(rowData.user_id)
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete',
                                onClick: (event, rowData) => props.onDelete(rowData.user_id)
                            }

                        ]
                    : null
            }

        />
    )
}
export default Table