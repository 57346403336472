import React, { useState,useEffect } from 'react'
// import PageTitle from '../../../common/HeaderTitle/PageTitle'
import { Card, Col, Row, Input, Label, FormFeedback } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
//import Table from '../../common/DataTable'
import { populateGroupStudents, migrateGroupStudents } from '../../../../redux/Actions/grouping'
import serialize from 'form-serialize'
import Swal from 'sweetalert2'
import { requiredValidator } from '../../../../utilities/Validator'
import { useDispatch, useSelector } from 'react-redux'
//import AssignTeacher from './assignTeacher'
//import Modals from '../common/Modal'
const Index = () => {


    // const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const groupStore = useSelector(({ grouping }) => grouping)
    useEffect(() => {

        return()=>{
            setInputField({
                source_group_id: '',
                destination_group_id: ''
            })
        }
    }, [])

    const [mutliSelect, setMultiSelect] = useState([])
    const [inputField, setInputField] = useState({
        source_group_id: '',
        destination_group_id: ''
    })
    const handleChangeGroup = (e, type) => {
        const { value, name } = e.target;
        setInputField({ ...inputField, [name]: value })
        if (type === 'source') {
            setMultiSelect([])

            dispatch({ type: 'RESET_SOURCE_STUDENT_LIST' })
        } else {
            dispatch({ type: 'RESET_DESTINATION_STUDENT_LIST' })

        }
        if (value !== '') {
            dispatch(populateGroupStudents(tokenData.token, value, type))

        }

    }

    const multiSelectStudent = (student_id) => {


        if (mutliSelect.includes(student_id)) {
            // debugger
            const filtered = mutliSelect.filter(videoId => videoId !== student_id)
            setMultiSelect(filtered)

        } else {
            setMultiSelect([...mutliSelect, student_id])
        }

    }
    const [migrationType, setMigrationType] = useState()
    const copyHere = (type) => {
        setMigrationType(type)
        //document.getElementById("myForm").submit();
        //e.preventDefault()
        //alert('copy')

    }
    const [errors, setErrors] = useState({})
    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        body.students = mutliSelect
        body.type = migrationType
        const err = {
            destination_group_id: requiredValidator(inputField.destination_group_id),
            source_group_id: requiredValidator(inputField.source_group_id)
        }
        if (!err.destination_group_id && !err.source_group_id) {
            setErrors(err)
            if (body.students.length === 0) {

                Swal.fire(
                    'Required',
                    'You have to select minimum student',
                    'warning'
                )
            } else {
                dispatch(migrateGroupStudents(tokenData.token, body, resetForm))

            }
        } else {
            setErrors(err)
        }


    }

    const resetForm = () => {
        setMultiSelect([])
        //document.getElementById("group-form").reset();
    }
    return (
        <>

            <Card style={{ padding: 20 }}>
                <h6>Group Students</h6>
                <hr />
                <form onSubmit={formSubmit} id="group-form">
                    <Row >
                        <Col md={6} >
                            <Row>

                                <Col md={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                        {' '}Source Group
                                    </Label>
                                    <Input type="select"
                                        onChange={(e) => handleChangeGroup(e, 'source')}
                                        name='source_group_id'
                                        // required
                                        // value={this.state.loaderType}
                                        invalid={errors.source_group_id}
                                    >

                                        <option value={''}>{'select'}</option>
                                        {groupStore.groupList.map(listGroup => {
                                            return (
                                                <option value={listGroup.group_id}>{listGroup.group_name}</option>

                                            )
                                        })
                                        }


                                    </Input>
                                    <FormFeedback >{errors.source_group_id}</FormFeedback>

                                </Col>
                                <Col md={12} style={{ padding: '15px 15px 20px 35px' }}>
                                    {groupStore.sourceGroupStudentsLoader ? "Fetching....." : null}
                                    {groupStore.sourceGroupStudents.length > 0 ? groupStore.sourceGroupStudents.map(student => {
                                        return (
                                            <Row style={{ paddingTop: 10 }}>
                                                <Col md={8}> <Input type="checkbox" onChange={() => multiSelectStudent(student.user_id)} value={student.user_id} checked={mutliSelect.includes(student.user_id) ? true : null} /> {student.full_name}</Col>
                                                <Col md={4}> {student.user_id}</Col>
                                            </Row>
                                        )
                                    }) : null
                                    }
                                </Col>
                            </Row>

                        </Col>

                        <Col md={6} style={{ borderLeft: '1px solid #a0a4aa' }}>
                            <Row>
                                <Col md={12}>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                        {' '}Destination Group
                                    </Label>
                                    <Input type="select"
                                        onChange={(e) => handleChangeGroup(e, 'destination')}
                                        name='destination_group_id'
                                        // required
                                        // value={this.state.loaderType}
                                        invalid={errors.destination_group_id}
                                    >

                                        <option value={''}>{'select'}</option>
                                        {groupStore.groupList.map(listGroup => {
                                            return (
                                                <option value={listGroup.group_id}>{listGroup.group_name}</option>

                                            )
                                        })
                                        }

                                    </Input>
                                    <FormFeedback >{errors.destination_group_id}</FormFeedback>

                                </Col>
                                <Col md={12} style={{ padding: 20 }}>
                                    {groupStore.destinationGroupStudentsLoader ? 'Fetching....' : null}
                                    {groupStore.destinationGroupStudents.length > 0 ? groupStore.destinationGroupStudents.map(student => {
                                        return (
                                            <Row style={{ paddingTop: 10 }}>
                                                <Col md={8}>
                                                    {student.full_name}</Col>
                                                <Col md={4}> {student.user_id}</Col>
                                            </Row>
                                        )
                                    }) : null
                                    }
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                    <hr />
                    <Row style={{ paddingTop: 15 }}>

                        <Col xs={4} md={9}></Col>
                        <Col xs={8} md={3}>

                            <LaddaButton className="mb-2 btn btn-success"
                                loading={groupStore.copyButtonLoader}
                                type="submit"
                                name="copy"
                                onClick={() => copyHere("copy")}
                                data-style={ZOOM_IN}  >
                                Copy here
                            </LaddaButton>

                            <LaddaButton className="mb-2 ml-2 btn btn-danger"
                                loading={groupStore.moveButtonLoader}
                                type="submit"
                                name="move"
                                onClick={(e) => copyHere("move")}

                                data-style={ZOOM_IN}  >
                                Move here
                            </LaddaButton>
                        </Col>
      
                    </Row>
                </form>
            </Card>



        </>
    )
}

export default Index
