import React,{useEffect} from "react";
import Container from "reactstrap/lib/Container";

import { useParams } from 'react-router-dom'
import { setReqData,getAllvendorCourses,getAllvendorCoursesSearchAdmin } from '../../../../redux/Actions/Superadmin'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

import Tooltip from '@material-ui/core/Tooltip';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
import nodata from '../../../LandingPageNew/images/nodata.png'

import {
  Input, InputGroup, 
  Row, Col,Table,Card,CardHeader,Form
 
} from 'reactstrap';



function VendorCousedetails() {

const dispatch = useDispatch();
const params = useParams()
const tokenData = JSON.parse(localStorage.getItem("authDetails"));
const history = useHistory();
// const [page,setPage]=useState(1)
// const [search,setSearch]=useState('')
// const [searched,setSearched]=useState(false)

// const vendorcourseloader = useSelector((state) =>state.superadmin.vendorcourseloader ? state.superadmin.vendorcourseloader : "");

const VendorCourseArrayData = useSelector((state) =>state.superadmin.VendorCourseArrayData ? state.superadmin.VendorCourseArrayData : []);
const vendor_nextpage = useSelector((state) => state.superadmin.vendor_nextpage ? state.superadmin.vendor_nextpage : '');
const vendor_prevpage = useSelector((state) => state.superadmin.vendor_prevpage ? state.superadmin.vendor_prevpage : '');
const vendorcourseloader = useSelector((state) => state.superadmin.vendorcourseloader ? state.superadmin.vendorcourseloader : false);
// const data=VendorCourseArray.courses ? VendorCourseArray.courses :[]
// const data= props.data
// const action='vendorcourse_details'
const from = useSelector((state) => state.superadmin.from ? state.superadmin.from : '');
const to = useSelector((state) => state.superadmin.to ? state.superadmin.to : '');

const page = useSelector((state) => state.superadmin.page_admin_side ? state.superadmin.page_admin_side : 1);
const search = useSelector((state) => state.superadmin.search_admin_side ? state.superadmin.search_admin_side : '');
const searched = useSelector((state) => state.superadmin.searched_admin ? state.superadmin.searched_admin : false);

useEffect(() => {
    if(searched){
      dispatch(getAllvendorCoursesSearchAdmin(tokenData.token, params.enterprise_id,page,search))
    }
    else{
      dispatch(getAllvendorCourses(tokenData.token, params.enterprise_id,page))
    }
      dispatch(setReqData('selectedcourse',false))

}, [page])

const NextPage=()=>{
  dispatch(setReqData('page_admin_side',page+1))
}
const PreviousPage=()=>{
  dispatch(setReqData('page_admin_side',page-1))
}

const Vendorcourse=(id)=>{
  history.push(`/dashboard/vendor/${params.enterprise_id}/course/${id.course_id}`)
}

const HandleChange=(e)=>{
  e.preventDefault()
  if(!e.target.value){
    // setSearched(false)
    dispatch(setReqData('searched_admin',false))
    if(page===1)
    {
      dispatch(getAllvendorCourses(tokenData.token, params.enterprise_id,1))
    }
    else{
      dispatch(setReqData('page_admin_side',1))

    }
    dispatch(setReqData('search_admin_side',e.target.value))
  }
  else{
    dispatch(setReqData('search_admin_side',e.target.value))
  }
}


const Searchcourse=(e)=>{
  /////////to be change
  e.preventDefault()
  // setSearched(true)
  dispatch(setReqData('searched_admin',true))
  if(page===1){
    dispatch(getAllvendorCoursesSearchAdmin(tokenData.token, params.enterprise_id,1,search))
  }
  else{
    // setPage(1)
    dispatch(setReqData('page_admin_side',1))
  }
}


  return (
    <>
      <Container>
        
        <Row>
         
          <Col sm={12} md={12}>


                <LoadingOverlay
                  active={vendorcourseloader}
                  spinner
                  text='Loading...'
                >
                  <Card className="main-card mb-3">
                    <CardHeader>
                      <div className="card-header-title font-size-lg text-capitalize font-weight-normal" style={{width:'50%'}}>
                        Course List
                      </div>
                      <div style={{width:'50%',display:'flex',justifyContent:'flex-end'}}>
                      <Form onSubmit={Searchcourse} >
                        <InputGroup>
                          <Input type='search' placeholder="Search..." onChange={HandleChange} value={search ? search:''}/>
                                <Button color='primary' 
                                    onClick={Searchcourse} 
                                    size='small' 
                                    variant='contained'
                                    disabled={search ? false:true || vendorcourseloader} >
                                      search
                                  </Button>
                        </InputGroup>
                      </Form>
                      </div>
                    </CardHeader>
                    {
                      VendorCourseArrayData.length > 0 ?

                      <Table responsive borderless hover className="align-middle text-truncate mb-0">
                      <thead>
                          <tr>
                              <th>Course Name</th>
                              <th>Proposed fee</th>
                              <th>Seats</th>
                              <th>Total Live Class</th>
                              <th>Total Video Class</th>
                              <th>Status</th>
                              <th>Action</th>

                          </tr>
                      </thead>
                    
                      <tbody>
                        {
                          VendorCourseArrayData.map((val,ky)=>{
                            return(
                              <tr key={ky}>
                                  <td>{val.course_name}</td>
                                  <td>{val.proposed_fee}</td>
                                  <td>{val.seats}</td>
                                  <td>{val.live_class_count}</td>
                                  <td>{val.ondemand_video_count}</td>
                                  <td>
                                  <div className={val.course_status === 1 ? 'badge badge-pill badge-success'
                                      :'badge badge-pill badge-danger'}>
                                      {val.course_status===1 ? 'Active' : 'Inactive'}
                                  </div>
                                  </td>

                                  <td>
                                  
                                      <Tooltip title='View Details'>
                                        {/* <VisibilityIcon  style={{cursor:'pointer'}} onClick={()=>{Vendorcourse(val)}}/> */}
                                        <Button  color="primary" variant='contained' size='small'
                                         onClick={()=>{Vendorcourse(val)}}
                                          >
                                        <span>View</span>
                                        </Button>
                                      </Tooltip>
                                  </td>
                              </tr>
                            )
                          })
                        }
                     
                    </tbody>
    

                </Table>
                :
                <Table responsive borderless hover className="align-middle text-truncate mb-0">
                      <thead>
                          {/* <tr>
                              <th>Course Name</th>
                              <th>Proposed fee</th>
                              <th>Seats</th>
                              <th>Total Live Class</th>
                              <th>Total Video Class</th>
                              <th>Status</th>
                              <th>Action</th>

                          </tr> */}
                      </thead>
                      
                      <tbody>
                        <tr>
                          <td style={{width:'100%',textAlign:'center'}}>
                          <img src={nodata} width='500px' height='300px' alt='' />
                          </td>
                        </tr>
                      </tbody>
                      </Table>
                    }
                
                <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                      <div style={{width:'30%',display:'flex',height:'50px',justifyContent:'flex-end'}}>

                        <span style={{marginTop:'13px'}}>{from}-{to}</span>
                          <Button
                            onClick={PreviousPage}
                            disabled={vendor_prevpage ? false :true}
                          >
                            <NavigateBeforeIcon />
                          </Button>
                          <Button
                            onClick={NextPage}
                            disabled={vendor_nextpage ? false :true}
                          >
                            <NavigateNextIcon />
                          </Button>
                      </div>
              </div>
                </Card>
                </LoadingOverlay>

          </Col>
        </Row>

        
      </Container>
    </>
  );
}

export default React.memo(VendorCousedetails);
