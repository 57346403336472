import React, { Fragment, useState } from 'react';

import {
     ListGroupItem, ListGroup,
    Button, 

} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux'
import { broadcastMikeSetting, toggleChange } from '../../../redux/Actions/homeTeacher'

import {
    faUser,
    
} from '@fortawesome/free-solid-svg-icons';
import Switchy from "react-switch";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function boxMullerRandom() {
    let phase = true,
        x1 = 0.0, x2 = 0.0, w = 0.0;

    return (function () {

        if (phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
}

const sampleData = randomData(30);

const DrawerBigExample = (props) => {



    const dispatch = useDispatch();

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));



    // const handleChange = (e) => {
    //     const { value } = e.target;

    // }

    // console.log("object", tStore.students)

    const [checkedState, setcheckedState] = useState(false)

    // console.log("checkedState", checkedState)
    const handleChangeTo = (student_id, muteState) => {
        dispatch(toggleChange(student_id))
        // debugger;
        //     setcheckedState(!checkedState)
        //     muteDi(student_id);
        // }
        // const muteDi = (student_id) => {
        // setcheckedState(!checkedState)

        if (!muteState) {
            setcheckedState(!checkedState)
            // console.log("checked trueeee")
            dispatch(broadcastMikeSetting(tokenData.token, "mute", student_id, tStore.muteStatus))
        }
        else {
            setcheckedState(!checkedState)

            // console.log("checked false")
            dispatch(broadcastMikeSetting(tokenData.token, "unmute", student_id, tStore.muteStatus))

        }

    }

    return (
        <Fragment>
            <h3 className="drawer-heading">
                <p style={{ visibility: 'hidden' }}>Head</p>
            </h3>

            <h3 className="drawer-heading">
                Students Joined
                </h3>
            <div className="drawer-section p-0">
                <div className="files-box">
                    <ListGroup flush>

                        {tStore.students ?
                            tStore.students.map((student, index) => {
                                return (
                                    <ListGroupItem className="pt-2 pb-2 pr-2">
                                        <div className="widget-content p-0">
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left opacity-6 fsize-2 mr-3 text-primary center-elem">
                                                    <FontAwesomeIcon icon={faUser} />
                                                </div>
                                                <div className="widget-content-left">
                                                    <div className="widget-heading font-weight-normal">
                                                        {student.name}
                                                    </div>
                                                </div>
                                                <div className="widget-content-right widget-content-actions">
                                                    <Button size="sm" className="btn-icon btn-icon-only" color="link">
                                                        {/* <FontAwesomeIcon icon={faUser} /> */}
                                                        <Switchy
                                                            onChange={(e) => handleChangeTo(student.id, !checkedState)}
                                                            // checked={
                                                            //     tStore.muteCheckedStatus.map(ck => {
                                                            //         console.log("student.id", student.id)
                                                            //         return (

                                                            //             ck === student.id ? true : false
                                                            //         )
                                                            //     })
                                                            // }
                                                            checked={student.mute}
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </ListGroupItem>)
                            }) :
                            null}

                    </ListGroup>
                </div>
            </div>


        </Fragment>
    )

}

export default DrawerBigExample;





