import React from 'react'
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import Tooltip from "@material-ui/core/Tooltip";

export default function Socialmedia({SocialMedia}) {
    // let unique = [...new Map(SocialMedia.map((m) => [m.SocialMediaName, m])).values()];     // added by dhansekar to remove duplicate social media
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap',  marginTop: '30px',width:'80%' }}>
            {
             SocialMedia.map((val, ky) => {  
           
                    return (
                        <>
                            {
                                val.SocialMediaName === 'LinkedIn' ?
                                    <>
                                        {
                                            val.InstituteSocialMediaPath ?
                                                <a href={val.InstituteSocialMediaPath} target='_blank'>
                                                    <Tooltip arrow title='LinkedIn'>
                                                        <span key={ky}>
                                                            <LinkedInIcon style={{ color: '#C4C4C4' }} />
                                                        </span>
                                                    </Tooltip>
                                                </a>
                                                :
                                                <Tooltip arrow title='No link available'>
                                                    <span key={ky}>
                                                        <LinkedInIcon style={{ color: '#C4C4C4' }} />
                                                    </span>
                                                </Tooltip>

                                        }
                                    </>

                                    :
                                    val.SocialMediaName === 'Twitter' ?
                                        <>
                                            {
                                                val.InstituteSocialMediaPath ?
                                                    <a href={val.InstituteSocialMediaPath} target='_blank'>
                                                        <Tooltip arrow title='Twitter'>
                                                            <span key={ky}>
                                                                <TwitterIcon style={{ color: '#C4C4C4' }} />
                                                            </span>
                                                        </Tooltip>
                                                    </a>
                                                    :
                                                    <Tooltip arrow title='No link available'>
                                                        <span >
                                                            <TwitterIcon style={{ color: '#C4C4C4' }} />
                                                        </span>
                                                    </Tooltip>

                                            }
                                        </>

                                        :
                                        val.SocialMediaName === 'Facebook' ?
                                            <>
                                                {
                                                    val.InstituteSocialMediaPath ?
                                                        <a href={val.InstituteSocialMediaPath} target='_blank'>
                                                            <Tooltip arrow title='Facebook'>
                                                                <span key={ky}>
                                                                    <FacebookIcon style={{ color: '#C4C4C4' }} />
                                                                </span>
                                                            </Tooltip>
                                                        </a>
                                                        :
                                                        <Tooltip arrow title='No link available'>
                                                            <span>
                                                                <FacebookIcon style={{ color: '#C4C4C4' }} />
                                                            </span>
                                                        </Tooltip>

                                                }
                                            </>

                                            :
                                            val.SocialMediaName === 'YouTube' ?
                                                <>
                                                    {
                                                        val.InstituteSocialMediaPath ?
                                                            <a href={val.InstituteSocialMediaPath} target='_blank'>
                                                                <Tooltip arrow title='YouTube'>
                                                                    <span key={ky}>
                                                                        <YouTubeIcon style={{ color: '#C4C4C4' }} />
                                                                    </span>
                                                                </Tooltip>
                                                            </a>
                                                            :
                                                            <Tooltip arrow title='No link available'>
                                                                <span>
                                                                    <YouTubeIcon style={{ color: '#C4C4C4' }} />
                                                                </span>
                                                            </Tooltip>
                                                    }
                                                </>

                                                : val.SocialMediaName === 'Skype' ?
                                                    <>
                                                        {
                                                            val.InstituteSocialMediaPath ?
                                                                <a href={val.InstituteSocialMediaPath} target='_blank'>
                                                                    <Tooltip arrow title='Skype'>
                                                                        <span style={{ color: '#C4C4C4' }}>
                                                                            <i class="fab fa-skype fa-2x"></i>
                                                                        </span>
                                                                    </Tooltip>
                                                                </a>
                                                                :
                                                                <Tooltip arrow title='No link available'>
                                                                    <span style={{ color: '#C4C4C4' }}>
                                                                        <i class="fab fa-skype fa-2x"></i>
                                                                    </span>
                                                                </Tooltip>
                                                        }
                                                    </>

                                                    :
                                                    ''

                            }
                        </>
                    )
                })
            }

        </div>
    )
}
