import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem,Badge } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import { Link } from "react-router-dom";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {downloadVendorDoc,getVendorDetails,
  // activateUser,
  rejectUser,listAllSchoolTransaction,updateProfileStatus,setReqData}from "../../../../redux/Actions/Superadmin"
import { useParams,useHistory } from 'react-router-dom'
// import { getAllvendorStudents } from '../../../../redux/Actions/Superadmin'
import VendorCousedetails from "./Coursedetails";
import VendorStudentdetails from "./Studentdetails";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { useDispatch,useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { Row, Col, Container, Card,CardHeader,Button  } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faCalendarAlt,
  faFile
} from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
// import { render } from "react-dom";
import SweetAlert from 'sweetalert-react';
import swal from 'sweetalert'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';
import moment from 'moment'
import Table from "../table"
// import { VendorPreview } from "../../../../redux/Actions/Superadmin";
// import Snackbar from '@material-ui/core/Snackbar';
import VendorSubscriptiondetails from "./SubscriptionList"
function Index() {
//  const history = useHistory();
 const dispatch = useDispatch();
 const params = useParams();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const user_id = params.user_id

  const history = useHistory()

const [open,setOpen]=useState(false)
const [Message,setMessage]=useState('')

 
  const cb2 = (message) => {
    swal({
      title: "Sucess",
      text: message,
      icon: 'success',
      
    })
    history.goBack()
  }

  const cb = (message) => {
    dispatch(getVendorDetails(tokenData.token,user_id, vendorcb))
    setMessage(message)
    setOpen(true)
  }
  const handleCloseSuccesstModal=()=>{
    // dispatch(getVendorDetails(tokenData.token,user_id, vendorcb))
    setOpen(false)
    setSignupmodel(false)
  }
  
  useEffect(() => {
    dispatch(getVendorDetails(tokenData.token,user_id, vendorcb))

    return()=>{
      dispatch(setReqData('transactions_school',[]))
    } 
  }, []);

  const [Courses, setCourses] = useState()
  const [Student, setStudent] = useState()
  const [Vendor, setVendor] = useState()
  const [signup, setSignup] = useState();

  const vendorcb = (v) => {
    setVendor(v);
    setStudent(v.student_list);
    setCourses(v.course_list);
    setSignup(v.vendor.signup_status === 0 ? false :true)
    // console.log('ssssttttaaaatusss',v.vendor.signup_status === 0 ? false :true)

  }

  // const columns = [
  //   { title: "Name", field: "name" },
  //   { title: "Email", field: "email" },
  //   { title: "Phone", field: "phone" },
  //   { title: "Total_Courses", field: "no_of_courses" },
  // ];

  // const Vendordetails = (val) => {
  //   history.push(`/dashboard/vendor/${val.enterprise_id}`);
  // };

  // const handleAccept = () => {
    
  //   dispatch(activateUser(tokenData.token,user_id,cb))
  // }
  
  const  handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Once rejected, This account will be deleted !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch(rejectUser(tokenData.token,user_id,cb2))
      } else {
        swal("Rejection aborted");
      }
    });
    
  }

  const handleChange = () => {
    dispatch(updateProfileStatus(tokenData.token,user_id,signupcb))
  };

  const [openssignup, setSignupmodel] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const signupcb=(ms)=>{
    setMsg(ms)
    setSignupmodel(true)
    dispatch(getVendorDetails(tokenData.token,user_id, vendorcb))
  }
// console.log("vendor details", Vendor)

const transactions_loader_school = useSelector((state) =>state.superadmin.transactions_loader_school ? state.superadmin.transactions_loader_school : false);
const transactions_school = useSelector((state) =>state.superadmin.transactions_school ? state.superadmin.transactions_school : []);


const columns = [
  { title: '#', field: 'tableData.id' },
  {title:'Name',field:"full_name"},
  { title: 'Transaction_Id', field: 'transaction_id',
  render: (rowData) => {
      return '#'+rowData.transaction_id
      },
  },
      { title: 'Order_Id', field: 'order_id',
      render: (rowData) => {
        if(rowData.order_id)
        return '#' + rowData.order_id
        else return " " 
      },
      },
  
 
  { title: "Credit", field: "type",
    render: (rowData) => {
      return rowData.type === 'Credit' ? (
        'Rs. '+ rowData.amount
      ) : (
        ' '
      );
    }, 
  },
  { title: "Debit", field: "type",
  render: (rowData) => {
    return rowData.type === 'Debit' ? (
      'Rs. '+ rowData.amount
    ) : (
      ' '
    );
  }, 
},
{ title: "Balance", field: "balance",
render: (rowData) => {
    return 'Rs. '+rowData.balance
    } },
 
];


const [state, setState] = useState([
  {
      startDate: addDays(new Date(), -32),
      endDate: new Date(),
      key: 'selection'
  }
]);
const filter=()=>{
  let from_date= moment(state[0].startDate).format("YYYY-MM-DD")
  let to_date=moment(state[0].endDate).format("YYYY-MM-DD")
  
  //////same api of school transaction list
  dispatch(listAllSchoolTransaction(tokenData.token,user_id,from_date,to_date))
}

  return (
    <>
    {
      Vendor ? 
      <>
      <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
    <Container>
      <Row>
      <Breadcrumb style={{width:'100%'}}>
            <BreadcrumbItem>
              <Link to="/dashboard">
                <FontAwesomeIcon icon={faHome} />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/dashboard/vendors">Partner List</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Partner Details</BreadcrumbItem>
          </Breadcrumb>
      </Row>
      <Row>
        <Col sm={12} md={12}>
          
          <div
            className="app-page-title"
            style={{
              margin: "0px 0px 15px 0px",
              borderRadius: "6px",
              backgroundColor: "rgb(0, 69, 139)",
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{marginRight:'20px'}} >
            {/* <div className={cx("page-title-icon")}> */}
              {/* <i className="fas fa-university icon-gradient bg-sunny-morning" /> */}
              <img style={{border:"3px white solid",borderRadius:"4px"}} width="164px" height="112px" src={Vendor?.vendor.skill_logo_url} alt="vendor logo" ></img>
            </div>
            <div>
              <span style={{ textTransform: "uppercase",fontWeight:600,fontSize:'15pt' }}>
                {Vendor?.vendor.vendor_name}
              </span>{" "}
              <br />
              <div
                style={{
                  color: "#99DDFF",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
                className={cx("page-title-subheading")}
              >
                {
                  Vendor?.vendor.status===0?
                      <span>Active</span> :
                      <span>Block</span>
                }
              </div>
            </div>
            <div className="page-title-actions">
              {/* {Vendor?.application_status === 1 ? (
                <>
                  {Vendor?.status === 1 ? (
                    <LaddaButton
                      className="mb-2 mr-2 btn btn-danger"
                      // loading={loader}
                      type="button"
                      // onClick={() => buttonAction()}
                      data-style={ZOOM_IN}
                    >
                      Block
                    </LaddaButton>
                  ) : (
                    <LaddaButton
                      className="mb-2 mr-2 btn btn-success"
                      // loading={loader}
                      type="button"
                      // onClick={() => buttonAction()}
                      data-style={ZOOM_IN}
                    >
                      Unblock
                    </LaddaButton>
                  )}
                </>
              ) : ""} */}

              {
                  Vendor?.vendor.approval_status === 0 ?
                  <>
                  {/* <LaddaButton
                      className="mb-2 mr-2 btn btn-success"
                      // loading={loader}
                      type="button"
                       onClick={() => handleAccept()}
                      data-style={ZOOM_IN}
                    >
                      Approve
                    </LaddaButton> */}
                    <LaddaButton
                      className="mb-2 mr-2 btn btn-danger"
                      // loading={loader}
                      type="button"
                       onClick={() => handleReject()}
                      data-style={ZOOM_IN}
                    >
                      Reject
                    </LaddaButton>
                  </>:''
              }
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm="12" md="6">
          <Card
            style={{ height: "350px" }}
            className="assign-details-card assign-details-card-descrip"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h6>
                <strong>Details</strong>
              </h6>

              <div>
              <Button onClick={ ()=> { history.push(`/dashboard/preview/${user_id}`) } } className=" mr-2 btn btn-info">Account Preview</Button>

                {/* <span>Signup : </span> */}
                <BootstrapSwitchButton
                  checked={signup}
                  onlabel="Completed"
                  offlabel="Partial"
                  width={100}
                  onChange={handleChange}
                />
              </div>
            </div>
            <hr />
              { Vendor &&
            <Row>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faUser} />
                </span>
                {"Name"}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.vendor_name}
               
              </Col>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                {"Email"}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.email}
              </Col>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                {"Email Status"}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.email_verified_at ? <Badge color="success" >Verified</Badge> : <Badge color="danger">Not Verified</Badge>}
              </Col>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                {"Phone"}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.contact_number}
              </Col>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                {"PIN"}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.pin}
              </Col>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                {"GST No."}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.gst_number ? Vendor.vendor.gst_number : "-"}
              </Col>
              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </span>
                {"PAN no."}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                {Vendor?.vendor.pan_number ? Vendor?.vendor.pan_number : "-"}
              </Col>

              <Col md={5} className="assign-details-card-div-head">
                <span className="pr-2 opacity-6" style={{ color: "#3FD2C7" }}>
                  {" "}
                  <FontAwesomeIcon icon={faFile} />
                </span>
                {"Documents"}
              </Col>
              <Col md={7} className="assign-details-card-div">
                {" "}
                <Tooltip onClick={()=>dispatch(downloadVendorDoc(tokenData.token,Vendor?.vendor.user_id,cb))} title='Download Document' arrow>
                  <GetAppIcon style={{cursor:'pointer'}}/>
                </Tooltip>
              </Col>
              {/* <Col md={5} className="assign-details-card-div-head">
                <Button onClick={ ()=> { history.push(`/dashboard/preview/${user_id}`) } } className="btn btn-info">Preview</Button>
              </Col> */}

            </Row>
              }
          </Card>
        </Col>
        <Col sm="12" md="6" style={{ marginTop: "15px" }}>
          <Row>

          <Col md="12">
              <div
                style={{ color: "white", backgroundColor: "rgb(157 116 202)" }}
                className="card mb-3 widget-chart card-hover-shadow-2x text-left"
              >
                <div className="icon-wrapper border-light rounded">
                  <div className="icon-wrapper-bg bg-light" />
                  <i className="lnr-briefcase icon-gradient bg-heavy-rain" />
                </div>
                <div className="widget-chart-content">
                  <div className="widget-numbers">{Vendor?.balance}</div>
                  <div className="widget-subheading">Wallet Balance</div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div
                style={{ color: "white", backgroundColor: "#d92550" }}
                className="card mb-3 widget-chart card-hover-shadow-2x text-left"
              >
                <div className="icon-wrapper border-light rounded">
                  <div className="icon-wrapper-bg bg-light" />
                  <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
                </div>
                <div className="widget-chart-content">
                  <div className="widget-numbers">{Vendor?.total_courses}</div>
                  <div className="widget-subheading">Total Courses</div>
                </div>
              </div>
            </Col>

            <Col md="12">
              <div
                style={{ color: "white", backgroundColor: "#3FD2C7 " }}
                className="card mb-3 widget-chart card-hover-shadow-2x text-left"
              >
                <div className="icon-wrapper border-light rounded">
                  <div className="icon-wrapper-bg bg-light" />
                  <i className="lnr-users icon-gradient bg-heavy-rain" />
                </div>
                <div className="widget-chart-content jms-opacity-overwrite">
                  <div className="widget-numbers">{Vendor?.total_students}</div>
                  <div className="widget-subheading">Total Students</div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <Col md="6">
          <div
            style={{ color: "white", backgroundColor: "#d92550" }}
            className="card mb-3 widget-chart card-hover-shadow-2x text-left"
          >
            <div className="icon-wrapper border-light rounded">
              <div className="icon-wrapper-bg bg-light" />
              <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
            </div>
            <div className="widget-chart-content">
              <div className="widget-numbers">{"3"}</div>
              <div className="widget-subheading">Total Courses</div>
            </div>
          </div>
        </Col>
        
        <Col md="6" >
          <div
            style={{ color: "white", backgroundColor: "#3FD2C7 " }}
            className="card mb-3 widget-chart card-hover-shadow-2x text-left"
          >
            <div className="icon-wrapper border-light rounded">
              <div className="icon-wrapper-bg bg-light" />
              <i className="lnr-enter icon-gradient bg-heavy-rain" />
            </div>
            <div className="widget-chart-content jms-opacity-overwrite">
              <div className="widget-numbers">{"2"}</div>
              <div className="widget-subheading">Total Students</div>
            </div>
          </div>
        </Col>
      </Row> */}

      <Row style={{ marginTop: "15px" }}>
        <VendorCousedetails data={Courses}  />
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <VendorSubscriptiondetails user_id={params.enterprise_id} />
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <VendorStudentdetails data={Student} />
      </Row>

      <Row style={{marginTop:'15px'}}>
      <Col xs={12}>
        <Card style={{ marginBottom: 20, padding: 20 }}>
          <CardHeader>Transaction Details</CardHeader>
            <DateRangePicker
                onChange={item => setState([item.selection])}
                // onChange={handleSelect}

                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                maxDate={new Date()}
                direction="horizontal"
                color='#fed858'
                // direction="vertical"
                // scroll={{ enabled: true }}
            />
          <hr />
          <Row>
            <Col xs={6} sm={9}></Col>
            <Col xs={6} sm={3} style={{textAlign:'right'}}>
              <LaddaButton className=" mr-2 btn btn-primary"
                style={{ width: '-webkit-fill-available' }}
                loading={transactions_loader_school}
                type="button"
                onClick={filter}
                data-style={ZOOM_IN}  >
                Filter
              </LaddaButton>
            </Col>
          </Row>
        </Card>
    </Col>

    <Col xs={12}>
      <Table
        loader={transactions_loader_school}
        data={transactions_school}
        title={"Transactions"}
        columns={columns}
      />
    </Col>
  </Row>

    </Container>
    </CSSTransitionGroup>


    <SweetAlert
      title="Success"
      confirmButtonColor=""
      show={open}
      text={Message? Message:"Completed successfully"}
      type="success"
      onConfirm={() => handleCloseSuccesstModal()} />


    <SweetAlert
      title="Success"
      confirmButtonColor=""
      show={openssignup}
      text={msg? msg:"Completed successfully"}
      type="success"
      onConfirm={() => handleCloseSuccesstModal()} />
      </>
      :
      <>
      <Card style={{ padding: '30px' }}>
        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
            <Skeleton height={30} count={5} />
        </SkeletonTheme>
    </Card>
    <Card style={{ padding: '30px', marginTop: '25px' }}>
        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">

            <Skeleton height={30} count={4} />
        </SkeletonTheme>
    </Card>
    </>
    }
    
    </>
  );
}

export default Index;
