import React, { useState, useEffect, Fragment } from 'react';


// import { useHistory,  } from 'react-router-dom'
// import { development } from '../../config.json'
import Slider from './Slider'
import CountUp from 'react-countup';
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch, } from "react-redux";
import { superAdminDashboard } from '../../redux/Actions/Superadmin'

import {
    Row, Col,
    // Button,
    // CardHeader,
    Container,
    Card,
    CardBody,
    CardTitle,
    CardHeader,
    // ListGroup,
    // ListGroupItem, CardFooter,
} from 'reactstrap';


import PageTitle from '../common/HeaderTitle/PageTitle'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';




const Superadminhome = () => {

    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(superAdminDashboard(tokenData.token, cb))

    }, [])


    const [Counters, setCounters] = useState(null)
    const [Recent, setRecent] = useState(null)

    const cb = (dashboard) => {
        // console.clear()
        // console.log(dashboard)

        setCounters({
            "total_courses": dashboard.total_courses,
            "total_students": dashboard.total_students,
            "total_resellers": dashboard.total_resellers,
            "total_vendors": dashboard.total_vendors,
            "total_schools": dashboard.total_schools,
            "total_sales": dashboard.total_sales,
        })

        setRecent({
            "recent_courses": dashboard.recent_courses,
            "recent_students": dashboard.recent_students,
            "recent_resellers": dashboard.recent_resellers,
            "recent_vendors": dashboard.recent_vendors,
            "recent_schools": dashboard.recent_schools,
            "recent_sales": dashboard.recent_sales,
        })


    }


    // const history = useHistory()
    const VendorClick=()=>{
        console.log('')
    }

    // const ResellerClick=()=>{
    //     console.log('')
    // }

    return (
        <Fragment>
            <PageTitle

                heading="Super Admin Dashboard"
                subheading="Monitor your account from here"
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />
            { Counters ?
                <Row>
                    <Col sm="6" xl="3">
                        <div className="card mb-3 widget-content bg-plum-plate">
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Courses
                                            </div>
                                    <div className="widget-subheading">
                                        Total Courses
                                            </div>
                                </div>

                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">
                                        <CountUp end={Counters.total_courses} duration="10" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="6" xl="3">
                        <div className="card mb-3 widget-content bg-plum-plate">
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                    Partners
                                            </div>
                                    <div className="widget-subheading">
                                        Total Partners
                                            </div>
                                </div>
                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">
                                        <CountUp
                                            //start={75}
                                            end={Counters.total_vendors}
                                            //separator=""
                                            //decimals={0}
                                            //decimal=","
                                            //prefix="$ "
                                            duration="10" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm="6" xl="3">
                        <div className="card mb-3 widget-content bg-plum-plate">
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Students
                                            </div>
                                    <div className="widget-subheading">
                                        Total Students
                                            </div>
                                </div>
                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">
                                        <CountUp
                                            start={0}
                                            end={Counters.total_students}
                                            // separator=""
                                            // decimals={0}
                                            // decimal=","
                                            // prefix="$"
                                            // suffix="M"
                                            duration="10"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* <Col lg="6" xl="4">
                        <div className="card mb-3 widget-content bg-plum-plate">
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Resellers
                                            </div>
                                    <div className="widget-subheading">
                                        Total Resellers
                                            </div>
                                </div>
                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">
                                        <CountUp start={0}
                                            end={Counters.total_resellers}
                                            // separator=""
                                            // decimals={0}
                                            // decimal=","
                                            // prefix=""
                                            // suffix="%"
                                            duration="15" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col lg="6" xl="4">
                        <div className="card mb-3 widget-content bg-plum-plate">
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Schools
                                            </div>
                                    <div className="widget-subheading">
                                        Total Schools
                                            </div>
                                </div>
                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">
                                        <CountUp end={Counters.total_schools} duration="10" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col> */}
                    <Col sm="6" xl="3">
                        <div className="card mb-3 widget-content bg-plum-plate">
                            <div className="widget-content-wrapper text-white">
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Sales
                                            </div>
                                    <div className="widget-subheading">
                                        Total Sales
                                            </div>
                                </div>
                                <div className="widget-content-right">
                                    <div className="widget-numbers text-white">
                                        <CountUp
                                            //start={75}
                                            end={5}
                                            //separator=""
                                            //decimals={0}
                                            //decimal=","
                                            //prefix="$ "
                                            duration="10" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
                :
                <>
                    <Card style={{ padding: '30px' }}>
                    <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                        <Skeleton height={30} count={5} />
                    </SkeletonTheme>
                    </Card>
                    <Card style={{ padding: '30px' }}>
                    <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                        <Skeleton height={30} count={5} />
                    </SkeletonTheme>
                    </Card>
                    </>
                }

                { Recent &&
            <Container fluid>

                
                    {/* <Card>
                        <CardTitle>Recently Added Courses</CardTitle>
                        <CardBody>
                            <Slider sdata={Recent.recent_courses} school={true} handleDetailsClick={""} />
                        </CardBody>
                    </Card> */}
                   {Recent.recent_vendors.length >0 &&
                    <Card className="mt-4" >
                        <CardHeader>Recently Added Partners</CardHeader>
                        <CardBody>
                            <Slider sdata={Recent.recent_vendors}  handleDetailsClick={VendorClick} />
                        </CardBody>
                    </Card>
                    }
                   {/* {Recent.recent_resellers.length >0 &&
                    <Card className="mt-4" >
                        <CardHeader>Recently Added Resellers</CardHeader>
                        <CardBody>
                            <Slider sdata={Recent.recent_resellers}  handleDetailsClick={ResellerClick} />
                        </CardBody>
                    </Card>
                    } */}
                    {/* <Card className="mt-4" >
                        <CardTitle>Recently Added Schools</CardTitle>
                        <CardBody>
                            <Slider sdata={Recent.recent_schools}  handleDetailsClick={""} />
                        </CardBody>
                    </Card> */}
                

            </Container>
            }

        </Fragment >
    )

}
export default Superadminhome