import React, { useEffect, Fragment, useState } from 'react';
// import bann from "../../images/banner-4.png"

import coursedummy from "../../images/coursedummy.png"
import nodata from "../../images/nodata.png"
import { useHistory, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVendorDetails, setHomeData, getVendorDetailsFromSkill, getClientToken } from "../../../../redux/Actions/genexhome"
import CircularProgress from "@material-ui/core/CircularProgress";
import LoadingOverlay from 'react-loading-overlay';
// import ReactHtmlParser from 'react-html-parser';
// import vendordummy from '../../images/vendordummy.png'

import WarningIcon from '@material-ui/icons/Warning';
import Swal from 'sweetalert2'
import Tooltip from '@material-ui/core/Tooltip';
import NewSIngleVendor from "./NewSIngleVendor"

function SingleVendor() {

    const dispatch = useDispatch();
    const params = useParams()
    const history = useHistory();
    const [show, setShow] = useState(true)

    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    const Single_Vendor_Details = useSelector((state) => state.genexhome.Single_Vendor_Details ? state.genexhome.Single_Vendor_Details : "");
    const Single_Vendor_Course_Array = useSelector((state) => state.genexhome.Single_Vendor_Course_Array ? state.genexhome.Single_Vendor_Course_Array : []);
    const single_vendor_first_page_url = useSelector((state) => state.genexhome.single_vendor_first_page_url ? state.genexhome.single_vendor_first_page_url : '');
    const single_vendor_next_page_url = useSelector((state) => state.genexhome.single_vendor_next_page_url ? state.genexhome.single_vendor_next_page_url : '');
    const single_vendor_loading = useSelector((state) => state.genexhome.single_vendor_loading ? state.genexhome.single_vendor_loading : false);

    const skill_vendor_load = useSelector((state) => state.genexhome.skill_vendor_load ? state.genexhome.skill_vendor_load : false);


    // const brochure_url=useSelector((state) =>state.genexhome.Single_Vendor_Details ? state.genexhome.Single_Vendor_Details.brochure_url : "#");
    // const header_baner=useSelector((state) =>state.genexhome.Single_Vendor_Details ? state.genexhome.Single_Vendor_Details.header_banner_url ? state.genexhome.Single_Vendor_Details.header_banner_url:bann : bann);

    const page = useSelector((state) => state.genexhome.Single_Vendor_page ? state.genexhome.Single_Vendor_page : 1);

    const InstituteName = useSelector((state) => state.genexhome.InstituteName ? state.genexhome.InstituteName : '');
    const LogoPath = useSelector((state) => state.genexhome.LogoPath ? state.genexhome.LogoPath : '');
    const SocialMedia = useSelector((state) => state.genexhome.SocialMedia ? state.genexhome.SocialMedia : []);
    const Skill_vendor = useSelector((state) => state.genexhome.Skill_vendor ? state.genexhome.Skill_vendor : '');
    const ImageGallery = useSelector((state) => state.genexhome.ImageGallery ? state.genexhome.ImageGallery : []);
    const Achievement = useSelector((state) => state.genexhome.Achievement ? state.genexhome.Achievement : []);

    const certification = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#202A60', padding: '3px 20px', background: 'white', width: '96px', boxSizing: 'border-box', display: 'inline-block' }
    const timeleft = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }
    const timelefthidden = { visibility: 'hidden', border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }

    useEffect(() => {
        // dispatch(getClientToken())
        dispatch(getVendorDetailsFromSkill(params.skl_user_id, cb))
        return () => {
            dispatch(setHomeData('Single_Vendor_Details', ''))
        }
    }, [])


    useEffect(() => {
        dispatch(getVendorDetails(params.vendor_id, page))
    }, [page])

    const NextPage = () => {
        dispatch(setHomeData('Single_Vendor_page', page + 1))
    }
    const PreviousPage = () => {
        dispatch(setHomeData('Single_Vendor_page', page - 1))
    }

    const ViewCourse = (val) => {
        if (val.edit_status === 1) {
            Swal.fire({
                icon: 'warning',
                title: 'Rescheduling!',
                text: 'Will be available soon',
            })
        } else {
            history.push(`/view-course/${val.course_id}`)

        }
    }

    const cb = () => {
        setShow(false)
    }
    // const Dwnload_Brochure=()=>{
    //     window.open(brochure_url,"_blank")
    // }

    // const ShowImage=()=>{
    //     setShow((prev)=>!prev)
    // }


    // const imgstyle={display:'none',marginBottom:'10px'}
    const reschedule = { backgroundColor: 'rgb(49 49 49 / 55%)', borderColor: 'white', position: 'absolute', top: '10px', left: '10px', color: 'white', padding: '0px 2px', borderRadius: '5px', boxSizing: 'border-box' }

    const vendorname_label = { fontFamily: 'revert', color: 'rgb(0 0 0)' }
    const vendorname = { fontFamily: 'revert', color: 'rgb(20 73 144)', fontWeight: '600' }

    return (

        <Fragment>
            {
                Single_Vendor_Details && !skill_vendor_load ?
                    <>
                        {
                            show ?
                                <div>
                                    <NewSIngleVendor
                                        InstituteName={InstituteName}
                                        LogoPath={LogoPath}
                                        SocialMedia={SocialMedia}
                                        FullDetails={Skill_vendor}
                                        ImageGallery={ImageGallery}
                                        Achievement={Achievement}
                                        Faculty={Single_Vendor_Details.faculty_list ? Single_Vendor_Details.faculty_list : []}
                                    />
                                    <section style={{ backgroundColor: 'white' }}>
                                        <div class="container">

                                            <LoadingOverlay
                                                active={single_vendor_loading}
                                                spinner
                                                text='Loading your content...'
                                            >
                                                <div class="row wow fadeInUp padding-top-80px" data-wow-delay="0.2s">

                                                    <div class="col-md-12 col-lg-12 col-12">
                                                        <div class="row justify-content-center">
                                                            <div class="col-md-9 col-lg-9 col-12 ">
                                                                <div class="text-center  margin-bottom-35px wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                                                                    <h3 style={{ color: '#212529' }} class="font-weight-700 text-title-large wow fadeInUp" data-wow-delay="0.2s"><span class="fw-200">BROWSE OUR  </span> ONLINE COURSES</h3>
                                                                    <p class="font-15 wow fadeInUp" data-wow-delay="0.2s"><span style={{ fontWeight: 'bold' }}>Skillablers Virtual Edu Park- </span>A first time in India experience of a
                                                                        next generation technology- now brings a parallel and comprehensive educational solution for learners
                                                                        from KG to PhD and beyond. It promotes life long learning for all age groups and acrossthe world.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    {
                                                        Single_Vendor_Course_Array.length > 0 ?
                                                            <>
                                                                {
                                                                    Single_Vendor_Course_Array.map((val, ky) => {
                                                                        let vendor = val.vendor_name ? val.vendor_name : ''
                                                                        let v_name
                                                                        if (vendor.length > 15) {
                                                                            let apend = vendor.slice(0, 14)
                                                                            v_name = apend + '...'
                                                                        }
                                                                        else {
                                                                            v_name = vendor
                                                                        }
                                                                        return (
                                                                            <div class="col-md-4 col-lg-3 col-sm-6 col-12 mb-4" key={ky} style={{ cursor: 'pointer' }} >
                                                                                <div class="blog-item thum-hover featured-institute-main recntigcr">
                                                                                    <div class="position-relative">
                                                                                        <div onClick={() => { ViewCourse(val) }} class="item-thumbnail "><img src={val.banner_url ? val.banner_url : coursedummy} height='200px' width='100%' alt="" /></div>
                                                                                        <div class="cat-div">
                                                                                            {val.tags ? val.tags[0] ? val.tags[0].tag_name ? val.tags[0].tag_name : '' : '' : ''}
                                                                                        </div>
                                                                                        {
                                                                                            val.edit_status === 1 ?
                                                                                                <div style={reschedule}><WarningIcon style={{ color: '#d8d81c', fontSize: '15px' }} /> Rescheduling</div>
                                                                                                : ''
                                                                                        }
                                                                                    </div>
                                                                                    <div class="padding-20px ">
                                                                                        <div onClick={() => { ViewCourse(val) }}>
                                                                                            <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                                                                                <span class="f-i-title">{val.course_name}</span>
                                                                                            </div>
                                                                                            <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                                                                                <p class="font-14 mb-1">{val.course_subject_name}</p>
                                                                                            </div>
                                                                                            <p class="mb-0"><span class="font-16 font-weight-500 text-blue">{val.course_amount}</span></p>
                                                                                        </div>
                                                                                        <hr class="mt-1 mb-1" />
                                                                                        <p class="mb-0">
                                                                                            {
                                                                                                val.certified === 1 ?
                                                                                                    <div style={certification}>Certified</div>
                                                                                                    : null
                                                                                            }
                                                                                            {
                                                                                                val.days_left ?
                                                                                                    <div style={timeleft}>{val.days_left}</div>
                                                                                                    : <span style={timelefthidden} class="mr-1 course-badges badge badge-dark">{0}</span>
                                                                                            }
                                                                                        </p>
                                                                                        <Tooltip arrow title={val.vendor_name}>
                                                                                            <Link to={{ pathname: `/vendor/${val.vendor_id}/${val.skl_user_id}` }} >
                                                                                                <span>
                                                                                                    <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                                                                                </span>
                                                                                            </Link>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                <img src={nodata} width='600px' height='400px' alt='' />
                                                            </div>
                                                    }
                                                </div>
                                            </LoadingOverlay>


                                            {
                                                Single_Vendor_Course_Array.length > 0 && (single_vendor_first_page_url || single_vendor_next_page_url) ?
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-12 text-center mt-4">
                                                            <ul class="pagination-list">
                                                                {
                                                                    single_vendor_first_page_url ?
                                                                        <li style={{ cursor: 'pointer' }}><a onClick={PreviousPage}>Prev</a></li>
                                                                        :
                                                                        <li><a style={{ color: '#dddddd' }}>Prev</a></li>

                                                                }
                                                                {
                                                                    single_vendor_next_page_url ?
                                                                        <li style={{ cursor: 'pointer' }}><a onClick={NextPage}>Next</a></li>
                                                                        :
                                                                        <li><a style={{ color: '#dddddd' }}>Next</a></li>

                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    : ''

                                            }

                                        </div>

                                    </section>
                                </div>
                                :
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <img src={nodata} width='600px' height='400px' alt='' />
                                </div>

                        }
                    </>
                    :
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "100vh",
                        }}
                    >
                        <CircularProgress />
                    </div>
            }
        </Fragment>
    )
}
export default SingleVendor