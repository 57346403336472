import React from 'react'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                // hadleOpenModal={(id) => props.hadleOpenModal(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns} 
                handleEdit
                EditTestimony={(id)=>{props.EditTestimony(id)}}
                Delete={(id)=>{props.Delete(id)}}
                ViewImage={(id)=>{props.ViewImage(id)}}
                data={props.data}
                title={props.title}
                loader={props.loader}
            />
        </>
    )
}

export default Table
