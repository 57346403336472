import React, { useState } from 'react'
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Card,
    // CardBody,
    CardHeader,
} from "reactstrap";
import classnames from "classnames";
import Edit from './edit';
import Preview from './vendorpreview'
import {useDispatch} from 'react-redux'
import {getVendorPreviewDetailsForVendor} from "../../../redux/Actions/genexhome"
function Index() {

    const dispatch = useDispatch();
    const [activeTab, setactiveTab] = useState("1")
    const tokenData = JSON.parse(localStorage.getItem('authDetails'))

    const toggle = (tab) => {

        if (activeTab !== tab) {
            if(tab === "2")
            {
                setactiveTab(tab)
                dispatch(getVendorPreviewDetailsForVendor(tokenData.token))
            }else{
                setactiveTab(tab)
            }

        }
    };


    return (
        <div>
            <Card tabs={true} className="mb-3">
                <CardHeader>
                    <Nav justified>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "1" })}
                                onClick={() => {
                                    toggle("1");
                                }}
                            >
                               Edit Profile
                </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href="#"
                                className={classnames({ active: activeTab === "2" })}
                                onClick={() => {
                                    toggle("2");
                                }}
                            >
                                Preview
                </NavLink>
                        </NavItem>

                    </Nav>
                </CardHeader>
               
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Edit />
                            {/*create course*/}
                        </TabPane>
                        <TabPane tabId="2">
                            <Preview />
                        </TabPane>
                    </TabContent>
               
            </Card>
        </div>
    )
}

export default Index
