import React from 'react'
import Tabs, { TabPane } from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import ListStudents from './listStudents'
import AssignStudents from './assignStudents'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PageTitle from '../HeaderTitle/PageTitle'
const Index = () => {
    return (
        <>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <PageTitle
                    style={{ display: 'none' }}
                    heading="Students Details"
                    subheading="List of students under teacher"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />

                <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab='View Students' key="1"><ListStudents /></TabPane>
                    <TabPane tab='Add Students' key="2"><AssignStudents /></TabPane>
                </Tabs>
            </CSSTransitionGroup>
        </>


    )
}

export default Index
