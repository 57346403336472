import React, { useState } from 'react'
//import Modal from '../../common/Modal'
import { ccavenue } from '../../../config.json'
import { Button, Input, Label } from 'reactstrap'
import './style.scss'
function Index(props) {
    const { course_id, name, email, reg_id, phone, service_type, url_page, course_amount, mots_order_id } = props

    const [Read, setRead] = useState(false)

    console.log("console logging event",course_id, name, email, reg_id, phone, service_type, url_page, course_amount, mots_order_id )
    const handleChangeRead = (e) => {
        if (course_id && name && email && reg_id && phone && service_type && url_page && course_amount && mots_order_id) {
            setRead(!Read)
        }
    }

    // const logOnSubmit = (e) => {
    //     console.log("submit event", e)
    // }

    return (
        <div>
            {/* <form method="post" name="redirect" onSubmit={logOnSubmit}> */}
            <form method="post" name="redirect" action={`${ccavenue.url}`}>
                <div style={{ textAlign: 'center', width: '100%' }}><h4>Terms&Conditions</h4></div>
                <div class='wrap-chat-main' style={{ height: '300px', overflowY: 'scroll' }} >
                    <ul>
                        <li>Payment(s) through this Service may only be made with a Credit Card, Debit card, UPI or Net Banking.</li>
                        <li>The credit card/ Debit Card information supplied at the time of using the service is processed by the payment gateway of the service provider and is not directly related to Skillablers.</li>
                        <li>It is the sole responsibility of the User of the service to ensure that the information entered in the relevant fields are correct. It is recommended that you take and retain a copy of the transaction for record keeping purposes, which might assist you later in resolution of any disputes that may arise out of usage of the service.</li>
                        <li>The Applicant agrees, understands and confirms that his/ her personal data, including without limitation, details relating to debit card/ credit card/net banking transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that Skillablers or the Payment Service Provider(s) have no control over such matters.</li>
                        <li>If payment is made by means of a card that you do not personally own, the permission of the card owner must always be obtained to make payments using the card. In using Skillablers, you confirm that you have such permission and Skillablers is in no way bound by your mode of transaction.</li>
                        <li>Information related to payments using debit or credit card is not accessed or stored by Skillablers.</li>
                        <li>Limitation of Liability: In no event, Skillablers will be liable for any damage – direct or indirect losses or expenses arising in connection with site.</li>
                        <li>Security: All payment details which are entered through this payment gateway are encrypted when an Individual or third party makes a payment and enters into an annual subscription or partnership with Skillablers.</li>
                        <li>Skillablers may also make additions/deletions/alteration to the services offered, at its sole discretion. Skillablers reserve the right to withdraw/change the service or the payment gateway providers at any time and/or at its own discretion.</li>
                        <li>Skillablers also retain the right to change the terms and conditions for Online Fees.</li>
                        <li>The CCAvenue payment page has a Timeout set at 15 minutes per session. If any user is on the payment page and clicks on "Pay Now" after 15 minutes, the CCAvenue payment page used to show the time out message to the user. No response was sent to the merchant for timeouts, and the transaction status remained as “awaited” / "in process" /”initiated” etc. at the merchant end.</li>
                    </ul>
                </div>
                <input type="hidden" id="encb_name" name="encb_name" value={name} />
                <input type="hidden" id="encb_email" name="encb_email" value={email} />
                <input type="hidden" id="encb_phone" name="encb_phone" value={phone} />
                <input type="hidden" id="enc_RegId" name="enc_RegId" value={reg_id} />
                <input type="hidden" id="enc_servicetype" name="enc_servicetype" value={service_type} />
                <input type="hidden" id="enc_entityId" name="enc_entityId" value={course_id} />
                <input type="hidden" id="enc_amount" name="encAmount" value={course_amount} />
                <input type="hidden" id="enc_mots_order_id" name="enc_mots_order_id" value={mots_order_id} />
                <input type="hidden" id="enc_urlpage" name="enc_urlpage" value={url_page} />


                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '10px' }} ><input style={{ marginTop: '5px' }} type='checkbox' onChange={handleChangeRead} checked={Read} ></input>  <label style={{ marginLeft: '5px' }}>I agree to the Terms and Conditions</label></div>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} >
                    <Button className='btn-wide btn-pill' style={{ backgroundColor: '#36BDAC', border: 'none' }} disabled={!Read} size='lg' type='submit' >Proceed</Button>
                </div>



            </form>
        </div>
    )
}

export default Index
