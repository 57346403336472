import React, { Fragment } from "react";
import CourseList from "./Tabsec/Courselist";
import Category from "./Coursecategory/CourseCategory";
import Vendor from "./Vendors/Vendors";
// import Analyse from "./Analyse/Analyse"
import Benefit from "./Courses/Benefit";
// import News from "./News/News"
import Footer from "./Footer/Footer";

import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

function HomePage() {


  const HomeArray = useSelector((state) =>
    state.genexhome.HomeArray ? state.genexhome.HomeArray : ""
  );
  const vendor_section = useSelector((state) =>
  state.genexhome.vendor_section ? state.genexhome.vendor_section : []
);
  

  return (
    <Fragment>
    
      {HomeArray ? (
        <>
          <CourseList 
            course_array={HomeArray ? HomeArray.tag_tab_section ? HomeArray.tag_tab_section.tab_details ? HomeArray.tag_tab_section.tab_details:[]:[]:[]} 
            tab_head={HomeArray ? HomeArray.tag_tab_section ? HomeArray.tag_tab_section.tabs ? HomeArray.tag_tab_section.tabs:[]:[]:[]}
            slide_section={HomeArray ? HomeArray.slide_section ? HomeArray.slide_section:[]:[]}
            // slide_length={HomeArray ? HomeArray.slide_section ? HomeArray.slide_section.length:0:0}
            />

          <Category
            cat_section={
              HomeArray
                ? HomeArray.tag_section
                  ? HomeArray.tag_section
                  : []
                : []
            }
          />
          <Vendor vendor_array={vendor_section} />
          {/* <Analyse /> */}

          <Benefit course_array={HomeArray ? HomeArray.recent_courses_section ? HomeArray.recent_courses_section:[]:[]} />
          {/* <News newses={HomeArray ? HomeArray.recent_news_section ? HomeArray.recent_news_section:[]:[]}/> */}
          <Footer  />
        </>
        ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )} 


    </Fragment>
  );
}

export default HomePage;


