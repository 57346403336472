import React, { useEffect, useState } from 'react'
import PageTitle from "../../HeaderTitle/PageTitle";
import { useDispatch, useSelector } from "react-redux";
// import { getGradeListLifeLong } from '../../../redux/Actions/signUp'
import {
    addUserManual, showUserManual, getSiteSettings, deleteUserManual,
    addSiteSettings, getDefaultCommisions, updateDefaultCommision, updateExchangeRate,
    ListCurrency, AddSliderImages, setReqData, getSlideImages, deleteSlideImage, addSocialMediaLinks
} from '../../../redux/Actions/Superadmin'
import { getAllCurrencies } from '../../../redux/Actions/vendor_course'
import { Progress } from 'react-sweet-progress';

import LaddaButton, { ZOOM_IN } from "react-ladda";
import {
    Row,
    Col,
    // Form,
    FormGroup,
    Input,
    // InputGroupAddon,
    Card,
    CardBody,
    Label,
    CardHeader,
    Button,
    FormFeedback,
} from "reactstrap";

//import Select from 'react-select'
import { components } from 'react-select'
import Creatable from "react-select/lib/Creatable";
import Swal from 'sweetalert2'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
// import { Table, } from 'reactstrap';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SweetAlert from 'sweetalert-react';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Modals from '../../common/Modal';
import spinner from '../../../assets/animated/Spinner.gif'

import validator from 'validator';

function Index() {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    // const tabsArray = useSelector(state => state.superadmin.tabsArray ? state.superadmin.tabsArray : null)


    // const selected  = useSelector(state => state.superadmin.selectedCourse?state.superadmin.selectedCourse:null)
    const currency_list = useSelector(state => state.superadmin.currency_list ? state.superadmin.currency_list : [])
    const percetage_manual = useSelector(state => state.superadmin.percetage_manual ? state.superadmin.percetage_manual : null)
    const sliderprogress = useSelector(state => state.superadmin.sliderprogress ? state.superadmin.sliderprogress : null)
    const sliderloader = useSelector(state => state.superadmin.sliderloader ? state.superadmin.sliderloader : null)
    const slider_images = useSelector(state => state.superadmin.slider_images ? state.superadmin.slider_images : [])
    const slidedelete = useSelector(state => state.superadmin.slidedelete ? state.superadmin.slidedelete : false)

    const social_loader = useSelector(state => state.superadmin.social_loader ? state.superadmin.social_loader : false)




    const dispatch = useDispatch();
    //LIST ITEMS
    const [AvilableOptions, setAvailableOptions] = useState()
    const [SelectedOptions, setSelectedOptions] = useState(null)

    //LIST COURSES
    const [CourseCategories, setCourseCategories] = useState()
    const [SelectedCategories, setSelectedCategories] = useState()


    /////menu
    const [selected_menu, setSelectedMenu] = useState([])
    const [available_menu, setAvailbaleMenu] = useState([])
    const [menu_loader, setMenuLoader] = useState(false)

    //////commision
    const [Commisions, setCommisions] = useState({ reseller_commision: "", school_commision: "" })
    const [CommisionLoader, setCommisionLoader] = useState(false)

    //Currency
    const [currency, setCurrency] = useState([])
    const [val, setVal] = useState({ type: '', rate: '', err: '', load: false })
    const [success, setSuccess] = useState(false)

    const [userId, setuserId] = useState(null)
    const [File, setFile] = useState()

    /////slider images
    const [sliderimages, setSlider] = useState('')
    const [openerr, setOpenerr] = useState({ open: false, messages: [] })


    ////social links//
    const [media, setMedia] = useState([])
    const [social_err, setSocialerr] = useState({ id: '', err: '' })

    useEffect(() => {
        dispatch(getSiteSettings(tokenData.token, cb));
        dispatch(getDefaultCommisions(tokenData.token, setCommision))
        dispatch(getAllCurrencies(tokenData.token, callback_currenct))
        dispatch(ListCurrency(tokenData.token))
        dispatch(showUserManual(tokenData.token, setManual))
        // dispatch(getGradeListLifeLong(null, 3));
        dispatch(getSlideImages(tokenData.token))

    }, [])




    const [ListLoader, setListLoader] = useState(false)
    const [Browse, setBrowse] = useState(false)

    const cb = (avilable_courses, selected_courses, categories, selected_categories, all, menu, menu_available,social_media_section) => {
        // setAvailableOptions(life_courses)
        //let courses = [{grade_name:"coding", selected:true,grade_id:8},{grade_name:"java coding",grade_id:1, selected:false}, {grade_name:"js coding",grade_id:2, selected:true},{grade_name:"python coding",grade_id:3, selected:true}]
        // const selected_courses = tabsArray.filter(course =>course.selected)
        //const avilable_courses = courses.filter(course =>!course.selected)
        // console.log("selected course",all)
        // setAll(all)
        setAvailableOptions(avilable_courses)
        setSelectedOptions(selected_courses)
        setAvailableOptions(avilable_courses)
        // setSelectedOptions(selected_courses)
        setSelectedMenu(menu)
        setAvailbaleMenu(menu_available)
        setMedia(social_media_section)
        setCourseCategories(categories)
        setSelectedCategories(selected_categories)

    }

    const callback_currenct = (res) => {
        setCurrency(res.currencies ? res.currencies : [])
    }
    const setCommision = (Commisions) => {
        setCommisions({ reseller_commision: Commisions.reseller_commision, school_commision: Commisions.school_commision })
    }

    const handleCommisionChange = (e) => {

        const { name, value } = e.target
        setCommisions({ ...Commisions, [name]: value })

    }

    const handleDownload = (url) => {
        // dispatch(downloadUserManual(tokenData.token, id, downloadcb))
        window.open(url, '_blank');
        //    var link = document.createElement("a");
        //     link.href = url;

        //     link.click();
        //     link.remove();
    }

    const handleDelete = (id) => {
        dispatch(deleteUserManual(tokenData.token, id, deletecb))
    }
    const deletecb = () => {
        dispatch(showUserManual(tokenData.token, setManual))
    }

    const [CommisionErrors, setCommisionErrors] = useState({ reseller_commision: false, school_commision: false })

    const handleSubmitCommision = () => {
        let err = false;
        if (Commisions.reseller_commision > 99) {
            setCommisionErrors({ ...CommisionErrors, reseller_commision: true })
            err = true;
        }
        if (Commisions.school_commision > 99) {
            setCommisionErrors({ ...CommisionErrors, school_commision: true })
            err = true;
        }
        if (!err) {
            setCommisionLoader(true);
            dispatch(updateDefaultCommision(tokenData.token, Commisions, updateCommisioncb))
        }
    }

    const updateCommisioncb = (message) => {
        setCommisionLoader(false)
    }
    // console.log("options",AvilableOptions)
    // console.log("options selected",SelectedOptions)

    const handleChangeSelectList = (e) => {
        setSelectedOptions(e)
        // console.log("array of selected", e)
    }

    const handleChangeSelectCourse = (e) => {
        setSelectedCategories(e)
    }

    const handleChangeSelectMenu = (e) => {
        setSelectedMenu(e)
    }
    const handleSubmitItems = () => {
        dispatch(addSiteSettings(tokenData.token, SelectedOptions, "tag_tab_section", cb2))
    }

    const handleSubmitCategories = () => {
        dispatch(addSiteSettings(tokenData.token, SelectedCategories, "tag_browse_section", cb3))
        //    if (SelectedCategories.length !== 12) {
        //         dispatch(addSiteSettings(tokenData.token, SelectedCategories, "tag_browse_section", cb3))
        //     }
        //     else {
        //         Swal.fire('Warning', '12 Course Categories must be selected', 'warning')
        //     }
    }

    const handleSubmitMenu = () => {
        dispatch(addSiteSettings(tokenData.token, selected_menu, "menu_tag_section", cb4))
    }

    const cb3 = (load) => {
        setBrowse(load)
    }
    const cb2 = (load) => {
        setListLoader(load)
    }
    const cb4 = (load) => {
        setMenuLoader(load)
    }
    const [manualList, setmanualList] = useState()
    const setManual = (da) => {
        setmanualList(da)
    }

    const [Loader, setLoader] = useState(false)

    const handleSubmitManual = () => {

        const formData = new FormData();
        if (File) {
            setLoader(true);
            formData.append("role_id", userId);
            formData.append("document", File);
            dispatch(addUserManual(tokenData.token, formData, addcb))
        } else {
            Swal.fire('Required', 'Fill The Fields', 'warning')
        }

    }

    const addcb = () => {
        setLoader(false);
        Swal.fire('Success', 'Upload Completed', 'success')
        dispatch({ type: 'RESET_MANUAL_PROGRESS' })
        dispatch(showUserManual(tokenData.token, setManual))
        setuserId("")
        document.getElementById("ipfile").value = "";
    }


    const Menu = props => {
        const optionSelectedLength = props.getValue().length || 0;

        let limit = props.selectProps.name === 'list1' ? 6 : 30
        return (
            <components.Menu {...props}>
                {optionSelectedLength < limit ? (
                    props.children
                ) : (
                    <div style={{ margin: 15 }}>Max limit reached</div>
                )}
            </components.Menu>
        );
    };

    const isValidNewOption = (inputValue, selectValue) =>
        inputValue.length > 0 && selectValue.length < 6;


    // create course categories functions

    const Ratechnage = (e) => {
        setVal({ ...val, [e.target.name]: e.target.value })
    }

    const handleSubmitCurrency = () => {
        if (val.type && val.rate) {
            /////submt
            setVal({ ...val, load: true })
            dispatch(updateExchangeRate(tokenData.token, val.type, val.rate, submit_curr))
        }
        else {
            setVal({ ...val, err: 'please fill all field' })
        }
    }
    const submit_curr = () => {
        setSuccess(true)
        setVal({ type: '', rate: '', err: '', load: false })
        dispatch(ListCurrency(tokenData.token))

    }


    const HandleSlideImages = (e) => {
        // let a=e.target.files
        // if(a.length>1){
        //     let arr=[]
        //     for(let i=0;i<3;i++){
        //         arr.push(a[i])
        //     }
        //     setSlider(arr)
        // }
        // else{
        setSlider(e.target.files[0])
        // }

    }

    const handleSubmiSlider = () => {
        // let data={slider_image:sliderimages}

        let formData = new FormData();
        formData.append("slide_image", sliderimages);
        dispatch(AddSliderImages(tokenData.token, formData, slidercallback, slidercallback2))
    }

    const slidercallback = () => {
        dispatch(setReqData('sliderprogress', null))
        document.getElementById('sliderimage').value = null
        dispatch(getSlideImages(tokenData.token))
        setSlider([])
        setSuccess(true)
    }
    const slidercallback2 = (res) => {
        setOpenerr({ open: true, messages: res })
    }

    const RemoveImage = (val) => {
        dispatch(deleteSlideImage(tokenData.token, val.id, cbdelete))
    }

    const cbdelete = () => {
        dispatch(getSlideImages(tokenData.token))
    }

    const HandleChangeLink = (e, i) => {
        const { value } = e.target;
        let list = [...media]
        list[i].link = value
        setMedia(list)
    }


    const submitSocialLinks = () => {
        let valid = true
        let i
        for (i = 0; i < media.length; i++) {
            if (media[i].link) {
                if (!validator.isURL(media[i].link)) {
                    valid = false
                    break
                }
            }
        }
        if (valid) {
            setSocialerr({ id: '', err: '' })
            dispatch(addSocialMediaLinks(tokenData.token, media, cb_social))
        }
        else {
            setSocialerr({ id: i, err: 'enter a valid url' })
        }
    }

    const cb_social = () => {
        setSocialerr({ id: '', err: '' })
        setMedia([
            {
                type: "facebook",
                link: '',
                icon: "fab fa-facebook-f"
            },
            {
                type: "youtube",
                link: '',
                icon: "fab fa-youtube"
            },
            {
                type: "linkedin",
                link: '',
                icon: "fab fa-linkedin"
            },
            {
                type: "twitter",
                link: '',
                icon: "fab fa-twitter"
            },
            {
                type: "instagram",
                link: '',
                icon: "fab fa-instagram"
            },
        ])
        setSuccess(true)
    }


    return (
        <div>
            <PageTitle
                style={{ display: "none" }}
                heading="Customize Homepage"
                subheading="Set list of categories to be displayed in homepage"
                icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
            />
            <Card className="main-card">

                <Card className="m-4" >
                    <CardHeader className="pl-2 pt-2" >Set Menu items</CardHeader>
                    <CardBody>

                        <Row form>

                            <Col className="pl-2" md={10}>
                                <FormGroup>
                                    {selected_menu && available_menu ?
                                        <Creatable
                                            defaultValue={selected_menu}
                                            components={{ Menu }}
                                            isMulti
                                            getOptionLabel={option =>
                                                `${option.category_name}`
                                            }
                                            getOptionValue={option =>
                                                `${option.category_id}`
                                            }
                                            name='list3'
                                            options={available_menu}
                                            isValidNewOption={isValidNewOption}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleChangeSelectMenu}
                                        /> :


                                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                            <Skeleton height={30} count={1} />
                                        </SkeletonTheme>}

                                </FormGroup>
                            </Col>
                            <Col className="pl-4">
                                <LaddaButton
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={menu_loader}
                                    onClick={handleSubmitMenu}
                                    data-style={ZOOM_IN}
                                >
                                    Submit
                                </LaddaButton>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>

                <Card className="m-4" >
                    <CardHeader className="pl-2 pt-2" >Set Tab items</CardHeader>
                    <CardBody>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span> Select items to display (Items will be shown in home page in the same order of selection here)
                        </Label>
                        <Row form>

                            <Col className="pl-2" md={10}>
                                <FormGroup>
                                    {SelectedOptions && AvilableOptions ?
                                        <Creatable
                                            defaultValue={SelectedOptions}
                                            // defaultValue={[{grade_id: 75, grade_name: "React", selected: true}]}
                                            components={{ Menu }}
                                            // value={SelectedOptions}
                                            isMulti
                                            getOptionLabel={option =>
                                                `${option.category_name}`
                                            }
                                            getOptionValue={option =>
                                                `${option.category_id}`
                                            }

                                            name="list1"
                                            options={AvilableOptions}
                                            // options={[{grade_id: 734, grade_name: "Angular", selected: true},{grade_id: 754, grade_name: "Android", selected: false},{grade_id: 754, grade_name: "Laravel", selected: false}]}
                                            isValidNewOption={isValidNewOption}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleChangeSelectList}
                                        /> :


                                        <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                            <Skeleton height={30} count={1} />
                                        </SkeletonTheme>}

                                </FormGroup>
                            </Col>
                            <Col className="pl-4">
                                {/* <Button onClick={handleSubmitItems} color="primary">Submit</Button> */}
                                <LaddaButton
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={ListLoader}
                                    onClick={handleSubmitItems}
                                    data-style={ZOOM_IN}
                                >
                                    Submit
                                </LaddaButton>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>

                <Card className="m-4" >
                    <CardHeader className="pl-2 pt-2" >Set Course Category Tags</CardHeader>
                    <CardBody>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span> Select items to display (Items will be shown in home page in the same order of selection here)
                        </Label>
                        <Row form>

                            <Col className="pl-2" md={10}>
                                {SelectedCategories && CourseCategories ?
                                    <FormGroup>
                                        <Creatable
                                            defaultValue={SelectedCategories}
                                            components={{ Menu }}
                                            // value={SelectedOptions}
                                            isMulti
                                            getOptionLabel={option =>
                                                `${option.category_name}`
                                            }
                                            getOptionValue={option =>
                                                `${option.category_id}`
                                            }

                                            name="list2"
                                            options={CourseCategories}
                                            isValidNewOption={isValidNewOption}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleChangeSelectCourse}
                                        />

                                        {/* <span style={{color:"red"}} > { SelectedOptions? SelectedOptions.length !== 8 ? "*Eight options must be selected" : "":""}</span> */}
                                    </FormGroup>
                                    : <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                        <Skeleton height={30} count={1} />
                                    </SkeletonTheme>}
                            </Col>
                            <Col className="pl-4">
                                {/* <Button onClick={handleSubmitCategories} color="primary">Submit</Button> */}
                                <LaddaButton
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={Browse}
                                    onClick={handleSubmitCategories}
                                    data-style={ZOOM_IN}
                                >
                                    Submit
                                </LaddaButton>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>

                <Card className="m-4" >

                    <CardHeader className="pl-2 pt-2" >Set Default Commisions</CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={5}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Commision in percentage for resellers
                                </Label>
                                <Input name="reseller_commision" value={Commisions?.reseller_commision} onChange={handleCommisionChange} type="number" max={100} ></Input>
                                {CommisionErrors.reseller_commision ? <span style={{ color: 'red' }}>Maximum is 99%</span> : ""}
                            </Col>
                            <Col sm={5}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Commision in percentage for schools
                                </Label>
                                <Input name="school_commision" value={Commisions?.school_commision} onChange={handleCommisionChange} type="number" max={100}></Input>
                                {CommisionErrors.school_commision ? <span style={{ color: 'red' }}>Maximum is 99%</span> : ""}
                            </Col>
                            <Col className="pt-4" sm={2}>
                                <Label for="exampleNameF">
                                    {"  "}
                                </Label>
                                <Label for="exampleNameF">
                                </Label>
                                <LaddaButton
                                    type="submit"
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={CommisionLoader}
                                    onClick={handleSubmitCommision}
                                    data-style={ZOOM_IN}
                                >
                                    Submit
                                </LaddaButton>
                            </Col>
                        </Row>
                    </CardBody>

                </Card>


                <Card className="m-4" >

                    <CardHeader className="pl-2 pt-2" >Set Default Currency</CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={5}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Select Currency
                                </Label>
                                <Input name="type" value={val.type} onChange={Ratechnage} type="select" >
                                    <option value=''>Select</option>
                                    {
                                        currency.map((val, ky) => {
                                            return (
                                                <option key={ky} value={val.currency_id}>{val.currency_name}</option>
                                            )
                                        })
                                    }
                                </Input>
                                {/* {CommisionErrors.reseller_commision ? <span style={{ color: 'red' }}>Maximum is 99%</span> : ""} */}
                            </Col>
                            <Col sm={5}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Rate
                                </Label>
                                <Input name="rate" value={val.rate} onChange={Ratechnage} type="number" min={0} ></Input>

                            </Col>
                            <Col className="pt-4" sm={2}>
                                <Label for="exampleNameF">
                                    {"  "}
                                </Label>
                                <Label for="exampleNameF">
                                </Label>
                                <LaddaButton
                                    type="submit"
                                    className="mb-2 mr-2 btn btn-primary"
                                    loading={val.load}
                                    onClick={handleSubmitCurrency}
                                    data-style={ZOOM_IN}
                                >
                                    Submit
                                </LaddaButton>
                            </Col>
                            <Col sm={10} style={{ textAlign: 'center' }}>
                                <span style={{ color: 'red' }}>{val.err}</span>
                            </Col>

                        </Row>
                    </CardBody>

                </Card>

                <Card className="m-4">
                    <CardHeader className="pl-2 pt-2">
                        Currency List
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sl No.</TableCell>
                                                <TableCell>Currency Name</TableCell>
                                                <TableCell>Currency code</TableCell>
                                                <TableCell>Symbol</TableCell>
                                                <TableCell>Exchange Rate</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {
                                                currency_list.map((val, ky) => {
                                                    return (
                                                        <TableRow key={ky}>
                                                            <TableCell>{ky + 1}</TableCell>
                                                            <TableCell>{val.currency_name}</TableCell>
                                                            <TableCell>{val.currency_code}</TableCell>
                                                            <TableCell>{val.symbol}</TableCell>
                                                            <TableCell>{val.exchange_rate ? val.exchange_rate : '-'}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="m-4">
                    <CardHeader className="pl-2 pt-2">
                        Upload User Manual
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={4}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Select User
                                </Label>
                                <Input id='selectip' onChange={(e) => setuserId(e.target.value)} value={userId} type="select" min={0} >
                                    <option value={null}>Select</option>
                                    <option value={4} >Student</option>
                                    <option value={9}>School</option>
                                    <option value={6}>Partner</option>
                                </Input>
                            </Col>
                            <Col style={{ marginTop: '25px' }} xs={4}>

                                <Input id='ipfile' onChange={(e) => setFile(e.target.files[0])} accept=".pdf" type="file" min={0} ></Input>
                            </Col>
                            <Col xs={2}>

                                <LaddaButton type="submit"
                                    style={{ marginTop: '25px' }}
                                    className="btn btn-primary"
                                    loading={Loader}
                                    onClick={handleSubmitManual}
                                    data-style={ZOOM_IN}>Upload</LaddaButton>
                            </Col>
                            <Col xs={12}>
                                {percetage_manual &&
                                    <Progress
                                        percent={percetage_manual}
                                    />}
                            </Col>
                            <Col className="mt-4" xs={12}>
                                {manualList ?
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Sl No.</TableCell>
                                                    <TableCell>User</TableCell>
                                                    <TableCell>Actions</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {

                                                    manualList?.map((val, ky) => {
                                                        return (
                                                            <TableRow key={ky}>
                                                                <TableCell>{ky + 1}</TableCell>
                                                                <TableCell>{val.user}</TableCell>
                                                                <TableCell> <Button onClick={() => handleDownload(val.document_path)} className="btn-primary" size='sm' ><i className="lnr-download  btn-icon-wrapper"></i></Button> <Button onClick={() => handleDelete(val.id)} size='sm' className="btn-danger"><i className="lnr-trash  btn-icon-wrapper"></i></Button>  </TableCell>

                                                            </TableRow>
                                                        )
                                                    })

                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    : <h1>No data</h1>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="m-4">
                    <CardHeader className="pl-2 pt-2">
                        Upload Slider Images
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={8}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span> Select Images
                                </Label>
                                <Input
                                    type='file'
                                    name='images'
                                    onChange={HandleSlideImages}
                                    // multiple
                                    id='sliderimage'
                                />
                            </Col>
                            <Col xs={4}>
                                <LaddaButton type="submit"
                                    style={{ marginTop: '35px' }}
                                    className="btn btn-primary"
                                    loading={sliderloader}
                                    onClick={handleSubmiSlider}
                                    data-style={ZOOM_IN}
                                    disabled={!sliderimages}
                                >
                                    Upload
                                </LaddaButton>
                            </Col>
                            <Col xs={12}>
                                <Col xs={12}>
                                    {sliderprogress &&
                                        <Progress
                                            percent={sliderprogress}
                                        />}
                                </Col>
                            </Col>
                            <Col xs={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h3>Current Images</h3>
                            </Col>
                            {
                                slidedelete ?
                                    <Col xs={12}>
                                        <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>

                                        </div>
                                    </Col>
                                    :
                                    <>
                                        {
                                            slider_images.length > 0 ?
                                                slider_images.map((val, ky) => {
                                                    return (
                                                        <>
                                                            <Col xs={10} sm={6} key={ky} style={{ marginTop: '10px' }}>
                                                                <div style={{ height: '120px', width: '100%' }}>
                                                                    <img alt='' src={val.slide_image} height='100%' width='100%' />
                                                                </div>
                                                            </Col>
                                                            <Col xs={2} sm={6} style={{ marginTop: '10px', }}>

                                                                <Tooltip title='remove'>
                                                                    <CloseIcon style={{ color: 'red', cursor: 'pointer', marginTop: '45px' }} onClick={() => { RemoveImage(val) }} />
                                                                </Tooltip>
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                                :
                                                <span style={{ marginLeft: '15px' }}>No Images</span>
                                        }
                                    </>
                            }


                        </Row>
                    </CardBody>
                </Card>

                <Card className="m-4">
                    <CardHeader className="pl-2 pt-2">
                        Set Social Media Links
                    </CardHeader>
                    <CardBody>
                        {
                            media.map((val, ky) => {

                                return (
                                    <Row key={ky} className='mt-2'>
                                        <Col xs={2}>
                                            <span>
                                                <i class={val.icon}></i>
                                            </span>
                                        </Col>
                                        <Col xs={8}>
                                            <Input
                                                type='text'
                                                name='images'
                                                onChange={(e) => { HandleChangeLink(e, ky) }}
                                                value={val.link}
                                                invalid={social_err.err && ky === social_err.id}
                                            />
                                            <FormFeedback>{social_err.err}</FormFeedback>
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                        <Col xs={10} style={{ textAlign: 'right' }}>
                            <LaddaButton type="submit"
                                style={{ marginTop: '35px' }}
                                className="btn btn-primary"
                                loading={social_loader}
                                onClick={submitSocialLinks}
                                data-style={ZOOM_IN}
                            >
                                Submit
                            </LaddaButton>
                        </Col>
                    </CardBody>
                </Card>

            </Card>

            <SweetAlert
                title="Success"
                confirmButtonColor=""
                show={success}
                text="Successfully Updated"
                type="success"
                onConfirm={() => setSuccess(false)} />

            <Modals
                open={openerr.open}
                handleClose={() => { setOpenerr({ open: false, messages: [] }) }}
                title={'Error'}
            >
                <Row>
                    <Col xs={12}>
                        {
                            openerr.messages.map((val, ky) => {
                                return (
                                    <span key={ky} style={{ color: 'red' }}>{val}</span>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Modals>
        </div>
    )
}
export default Index
