import React, { Fragment,useState, useEffect } from 'react';
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import serialize from 'form-serialize'
import {
   
    Row, Col,
    Card,  CardFooter,
    CardTitle, 
    FormGroup, Label, Form, 
    Input,
} from 'reactstrap';
import { getTeacherData, setEditData } from '../../../redux/Actions/videolist'

const EditTeacher = (props) => {
    const dispatch = useDispatch();
    // const history = useHistory();
    const tStore = useSelector(({ videolist }) => videolist)
    const userDetail = tStore.userData;
    // console.log("tssss",tStore);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const param = useParams()
    const [userData, setUserData] = useState([userDetail])
    useEffect(() => {       
        dispatch(getTeacherData(tokenData.token,props.user_id))
    }, []);

    const changeHandler = (e) => {
        // console.log("change",e.target.value);
    }
    const editData = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(setEditData(tokenData.token, body))
    }

    return (
        <Fragment>
            <Form onSubmit={editData} id="form-user">  
                <Card className="main-card m-20">
                    <CardTitle>Facilitator Form</CardTitle>
                    <Row>
                        <Col md="6" xs="6">
                            <input type="hidden" name="user_id" value={userData.user_id}/>
                            <FormGroup className="p-10">
                                <Label for="exampleEmail">First Name</Label>
                                <Input type="text" name="first_name" id="firstname"
                                    value={userData.first_name}
                                    placeholder="First name" 
                                    onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="6">
                            <FormGroup className="p-10">
                                <Label for="examplePassword">Last Name</Label>
                                <Input type="text" name="last_name" id="lastname"
                                    placeholder="Last Name" 
                                    value={userData.last_name}
                                    onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" xs="6">
                            <FormGroup className="p-10">
                                <Label for="examplePassword">Phone</Label>
                                <Input type="text" name="enterprise_mobile" id="phone"
                                    placeholder="phone" 
                                    value={userData.enterprise_mobile}
                                    onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6" xs="6">
                            <FormGroup className="p-10">
                                <Label for="exampleEmail">Email</Label>
                                <Input type="email" name="enterprise_email" id="email"
                                    placeholder="Email" 
                                    value={userData.enterprise_email}
                                    onChange={(e) => changeHandler(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
                <CardFooter className="d-block text-right">
                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        // loading={tStore.ccLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                    Submit
                    </LaddaButton>
                </CardFooter>
            </Form>
        </Fragment>
        
    )
}

export default EditTeacher;
