import React, { useState, useEffect, useRef } from 'react'
import {
    Row, Col,
} from 'reactstrap';

import Loader from 'react-loader'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

import Modals from '../common/Modal'
import Table from '../common/DataTable';
import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
import moment from 'moment'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { getPaymentHistory } from '../../redux/Actions/auth'
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
// import "~react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from 'react-image-gallery';


const Index = () => {


    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const authData = useSelector(({ auth }) => auth)

    useEffect(() => {

        dispatch(getPaymentHistory(tokenData.token))


    }, []);

    const columns = [
        { title: 'Plan name ', field: 'plan_name', headerStyle: { fontWeight: '600' } },
        { title: 'Amount', field: 'amount', headerStyle: { fontWeight: '600' } },
        { title: 'Currency', field: 'currency', headerStyle: { fontWeight: '600' } },
        { title: 'Payment id', field: 'payment_id', headerStyle: { fontWeight: '600' } },
        { title: 'Date ', field: 'dated_at', headerStyle: { fontWeight: '600' } },
        { title: 'Payment Status ', field: 'payment_status', headerStyle: { fontWeight: '600' } },


    ]





    return (<>

        <Row>
            <Col sm={12} md={12}>

                <Table
                    title={"Payments"}
                    loader={authData.paymentHistoryLoader}
                    //actions={action}
                    data={authData.payment_history}


                    columns={columns}

                />
            </Col>
        </Row >
        <Modals
        // open={openStdModal}
        //handleClose={handleCloseStudentModal}
        //title={tokenData.enterpriseUser ? 'Assign Group' : 'Assign Students'}

        >
            {/* <AssignStudents
                classId={classId}
                loader={tStore.specificGroupLoader}
                data={tStore.specificStudent ? tStore.specificStudent : tStore.specificGroup}
                timing={timing}
                handleCloseStudentModal={handleCloseStudentModal2}
            /> */}
        </Modals>

    </>
    )
}

export default Index
