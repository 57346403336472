import Swal from 'sweetalert2'
// import { useHistory } from "react-router-dom";

const initialState = {
    grade: '',
    loader: '',
    ccLoader: '',
    subject: '',
    classes: [],
    enterpriseClasses: [],
    testClasses: [],
    specificClassTimeLoader: false,
    specificClassTime: '',
    specificStudentLoader: false,
    specificGroupLoader: false,

    specificStudent: '',
    specificGroup: '',
    myAccountDetails: '',
    nextClass: 'null',
    upcomingClassLoader: false,
    liveClassLoader: false,
    classesNowLoader: false,
    students: [],
    muteStatus: '',
    muteCheckedStatus: [],
    resentMail: '',
    resentMailLoader: '',
    editFormDetails: '',
    notifications: [],
    activities: [],
    timerLoader: false,
    studentSearch: [],
    myStudent: [],
    myStudentLoader: false,
    myEnterpeiseStudentLoader: false,
    myEnterpriseStudent: [],
    specificClassTimeSlot: [],
    editClassId: null,
    createClassHolidaysLoader: false,
    specialClassList: [],
    specialClassListLoader: false,
    startClasses: '',
    todayClass: [],
    liveClass: [],
    classesNow: [],
    counterDetails: '',
    subjectList: [],
    subjectListSchool: [],
    registeredTeachers: [],

    subjectListLifeLong: [],
    collegeSubLoader: false,
    createSubLoader: false,
    createSubLoaderLifeLong: false,
    startClass_id: null,
    startClassStatus: false,
    assignStudentLoader: false,
    attendanceLoader: false,
    attendanceInfo: [],
    studentAttandanceLoader: false,
    studentAttandance: {
        details: []
    },
    startedSession: '',
    leaveStatus: true,
    teacherClassesLoader: false,
    teacherClasses: [],
    studentClassLoader: false,
    studentClass: [],
    editClassDetails:[],
    whatsnew: null,
    teacherSubscriptions: [],
    nextSubClass:[],
    SpecificteacherSubscriptions:[],
    TeachersCourse:[],
    MyStudyMaterials : [],
    studyMaterialDetailsLoader : false,
    studyMaterialDetails : [],
    studyMaterialUploadLoader : false,
    uploadStudyMaterialProgress : '',
    updateStudyMaterialProgress : ''
}



const teacheHomeReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case 'SELECTION_IN_ASSIGN_EXAM' : {
            const index = state.specificStudent.findIndex(student => student.id !== payload) ;                                                                      
            const newArray = [...state.specificStudent]; //making a new array
            newArray[index].selected = true;//changing value in the new array
            return{
                ...state, //copying the orignal state
                specificStudent: newArray, //reassingning todos to new array 
            }
        }

        case 'WHATS_NEW_SUCCESS': {
            return {
                ...state, whatsnew: payload
            }
        }
        case 'POPULATE_ALL_STUDNET_CLASSES_REQUEST': {
            return {
                ...state, studentClassLoader: true
            }
        }
        case 'POPULATE_ALL_STUDNET_CLASSES_SUCCESS': {
            return {
                ...state, studentClassLoader: false, studentClass: payload
            }
        }
        case 'POPULATE_ALL_STUDNET_CLASSES_FAILED': {
            return {
                ...state, studentClassLoader: false
            }
        }
        case 'POPULATE_MY_COURSE_SUCCESS': {
            return {
                ...state, TeachersCourse: payload
            }
        }
        case 'POPULATE_TEACHER_CLASS_REQUEST': {
            return {
                ...state, teacherClassesLoader: true
            }
        }
        case 'POPULATE_TEACHER_CLASS_SUCCESS': {
            return {
                ...state, teacherClasses: payload, teacherClassesLoader: false
            }
        }

        case 'POPULATE_TEACHER_COURSE_REQUEST': {
            return {
                ...state,loadteacher:true
            }
        }
        case 'POPULATE_TEACHER_COURSE_SUCCESS': {
            return {
                ...state, teacherCourses: payload,loadteacher:false
            }
        }
        case 'POPULATE_TEACHER_COURSE_FAILED': {
            return {
                ...state,loadteacher:false
            }
        }

        case 'CLASS_LEAVE_REQUEST': {
            return {
                ...state, leaveStatus: false
            }
        }
        case 'ROLE_STUDENTS_ATTANDANCE_REQUEST': {
            return {
                ...state, studentAttandanceLoader: true
            }
        }
        case 'ROLE_STUDENTS_ATTANDANCE_SUCCESS': {
            return {
                ...state, studentAttandance: payload, studentAttandanceLoader: false
            }
        }
        case 'STUDENTS_ATTANDANCE_REQUEST': {
            return {
                ...state, studentAttandanceLoader: true
            }
        }
        case 'STUDENTS_ATTANDANCE_SUCCESS': {
            return {
                ...state, studentAttandance: payload, studentAttandanceLoader: false
            }
        }
        case 'ADD_STUDENT_TO_CLASS_REQUEST': {
            return {
                ...state, assignStudentLoader: true
            }
        }
        case 'ADD_STUDENT_TO_CLASS_SUCCESS': {
            return {
                ...state, assignStudentLoader: false
            }
        }
        case 'RESET_SUBJECT': {
            return {
                ...state, subject: ''
            }
        }
        case 'POPULATE_ALL_COLLEGE_SUBJECT_REQUEST': {
            return {
                ...state, collegeSubLoader: true
            }
        }
        case 'POPULATE_ALL_COLLEGE_SUBJECT_SUCCESS': {
            return {
                ...state, subjectList: payload, collegeSubLoader: false
            }
        }
        case 'POPULATE_ALL_SCHOOL_SUBJECT_REQUEST': {
            return {
                ...state, schoolSubLoader: true
            }
        }
        case 'POPULATE_ALL_SCHOOL_SUBJECT_SUCCESS': {
            return {
                ...state, subjectListSchool: payload, schoolSubLoader: false

            }
        }
        case 'POPULATE_ALL_REGISTERED_TEACHER_SUCCESS': {
            return {
                ...state, registeredTeachers: payload, teacherSubLoader: false

            }
        }
        case 'POPULATE_ALL_LIFE_LONG_SUBJECT_REQUEST': {
            return {
                ...state, lifeLongSubLoader: true

            }
        }
        case 'POPULATE_ALL_LIFE_LONG_SUBJECT_SUCCESS': {
            return {
                ...state, subjectListLifeLong: payload, lifeLongSubLoader: false

            }
        }
        case 'ADD_SUBJECT_REQUEST': {
            return {
                ...state, createSubLoader: true
            }
        }
        case 'ADD_SUBJECT_SUCCESS': {
            return {
                ...state, subjectList: [payload, ...state.subjectList], createSubLoader: false
            }
        }
        case 'ADD_SUBJECT_SCHOOL_REQUEST': {
            return {
                ...state, createSubLoaderSchool: true
            }
        }
        case 'ADD_SUBJECT_SCHOOL_SUCCESS': {
            return {
                ...state, createSubLoaderSchool: false, subjectListSchool: [payload, ...state.subjectListSchool]

            }
        }
        case 'ADD_SUBJECT_LIFE_LONG_REQUEST': {
            return {
                ...state, createSubLoaderLifeLong: true

            }
        }
        case 'ADD_SUBJECT_LIFE_LONG_SUCCESS': {
            return {
                ...state, createSubLoaderLifeLong: false, subjectListLifeLong: [payload, ...state.subjectListLifeLong]

            }
        }
        case 'UPDATE_SUBJECT_REQUEST': {
            return {
                ...state, collegeSubLoader: true
            }
        }
        case 'UPDATE_SUBJECT_SUCCESS': {
            let filtered = state.subjectList.filter(subject => subject.subject_id !== payload.subject_id)
            filtered = [payload, ...filtered]
            return {
                ...state, subjectList: filtered, collegeSubLoader: false
            }

        }
        case 'UPDATE_SUBJECT_SCHOOL_REQUEST': {
            return {
                ...state, schoolSubLoader: true

            }
        }
        case 'UPDATE_SUBJECT_SCHOOL_SUCCESS': {
            let filtered = state.subjectListSchool.filter(subject => subject.subject_id !== payload.subject_id)
            filtered = [payload, ...filtered]
            return {
                ...state, schoolSubLoader: false, subjectListSchool: filtered

            }
        }
        case 'UPDATE_SUBJECT_LIFE_LONG_REQUEST': {
            return {
                ...state, lifeLongSubLoader: true

            }
        }
        case 'UPDATE_SUBJECT_LIFE_LONG_SUCCESS': {
            let filtered = state.subjectListLifeLong.filter(subject => subject.subject_id !== payload.subject_id)
            filtered = [payload, ...filtered]
            return {
                ...state, lifeLongSubLoader: false, subjectListLifeLong: filtered

            }
        }
        case 'DELETE_SUBJECT_REQUEST': {
            return {
                ...state, collegeSubLoader: true

            }
        }
        case 'DELETE_SUBJECT_SUCCESS': {
            let filtered = state.subjectList.filter(subject => subject.subject_id !== payload)

            return {
                ...state, subjectList: filtered, collegeSubLoader: false


            }
        }
        case 'DELETE_SUBJECT_SCHOOL_REQUEST': {
            return {
                ...state, schoolSubLoader: true
            }
        }
        case 'DELETE_SUBJECT_SCHOOL_SUCCESS': {
            let filtered = state.subjectListSchool.filter(subject => subject.subject_id !== payload)

            return {
                ...state, subjectListSchool: filtered, schoolSubLoader: false

            }
        }
        case 'DELETE_SUBJECT_LIFE_LONG_REQUEST': {
            return {
                ...state, lifeLongSubLoader: true
            }
        }
        case 'DELETE_SUBJECT_LIFE_LONG_SUCCESS': {
            let filtered = state.subjectListLifeLong.filter(subject => subject.subject_id !== payload)

            return {
                ...state, subjectListLifeLong: filtered, lifeLongSubLoader: false

            }
        }
        case 'GET_NOTIFICATION_SUCCESS': {
            return {
                ...state, notifications: payload
            }
        }
        case 'GET_ACTIVITIES_REQUEST': {
            return {
                ...state, activityLoader: true
            }
        }
        case 'GET_ACTIVITIES_SUCCESS': {
            return {
                ...state, activities: payload, activityLoader: false
            }
        }
        case 'POPULATE_GRADE_SUCCESS': {
            return {
                ...state, grade: payload.grades
            }
        }
        case 'POPULATE_SUBJECT_REQUEST':{
            return {
                ...state,loadersub :true
            }
        }
        case 'POPULATE_SUBJECT_SUCCESS': {
            return {
                ...state, subject: payload.subjects,loadersub :false
            }
        }
        case 'POPULATE_SUBJECT_FAILED':{
            return {
                ...state,loadersub :false
            }
        }
        case 'POPULATE_ALL_USER_CLASSES_REQUEST': {

            return {
                ...state, allUserTableLoader: true
            }
        }
        case 'POPULATE_ALL_USER_CLASSES_SUCCESS': {

            return {
                ...state, classes: payload, allUserTableLoader: false
            }
        }
        case 'POPULATE_ALL_ENTERPRISE_USER_CLASSES_REQUEST': {

            return {
                ...state, allUserTableLoader: true
            }
        }
        case 'POPULATE_ALL_ENTERPRISE_USER_CLASSES_SUCCESS': {


            return {
                ...state, enterpriseClasses: payload.class_details, allUserTableLoader: false
            }
        }
        case 'CREATE_CLASS_REQUEST': {
            return {
                ...state, ccLoader: true
            }
        }
        case 'CREATE_ENTERPRISE_CLASS_SUCCESS': {
            return {
                ...state, enterpriseClasses: [payload, ...state.enterpriseClasses], successModal: true, ccLoader: false, nextClass: 'null',

            }
        }

        case 'CREATE_CLASS_SUCCESS': {

            return {
                ...state, classes: [payload, ...state.classes], successModal: true, ccLoader: false, nextClass: 'null',


            }
        }
        case 'GET_CLASS_TIMING_REQUEST': {
            return {
                ...state, specificClassTimeLoader: true
            }
        }
        case 'GET_CLASS_TIMING_SUCCESS': {

            return {

                ...state, specificClassTime: payload.classes, specificClassTimeLoader: false
            }
        }
        case 'POPULATE_STUDENT_REQUEST': {
            return {
                ...state, specificStudentLoader: true
            }
        }
        case 'POPULATE_STUDENT_SUCCESS': {
            return {
                ...state, specificStudent: payload.students, specificStudentLoader: false
            }
        }
        case 'POPULATE_STUDENT_FAILED': {
            return {
                ...state, specificStudentLoader: false
            }
        }
        case 'POPULATE_TEACHER_GROUP_REQUEST': {
            return {
                ...state, specificGroupLoader: true
            }
        }
        case 'POPULATE_TEACHER_GROUP_SUCCESS': {
            return {
                ...state, specificGroup: payload.details, specificGroupLoader: false
            }
        }
        case 'TODAY_CLASS_REQUEST': {
            return {
                ...state, upcomingClassLoader: true
            }
        }
        case 'TODAY_CLASS_SUCCESS': {
            return {
                ...state, todayClass: payload, upcomingClassLoader: false
            }
        }
        case 'TODAY_SUB_CLASS_REQUEST': {
            return {
                ...state, upcomingClassLoader: true
            }
        }
        case 'TODAY_SUB_    CLASS_SUCCESS': {
            return {
                ...state, todayClass: payload, upcomingClassLoader: false
            }
        }
        case 'PRINCIPAL_DASH_REQUEST': {
            return {
                ...state, liveClassLoader: true, classesNowLoader: true
            }
        }
        case 'PRINCIPAL_DASH_SUCCESS': {
            return {
                ...state, liveClass: payload.details, classesNow: payload.current_class, counterDetails: payload.counter, liveClassLoader: false, classesNowLoader: false
            }
        }



        case 'CHANGE_PASSWORD_SUCCESS': {
            return {

                ...state, message: payload.message, successModal: true
            }
        }

        case 'MY_ACCOUNT_SUCCESS': {
            return {
                ...state, myAccountInfo: payload.account_details
            }
        }
        case 'PAYMENT_SUCCESS': {

            return {
                ...state, myAccountInfo: { ...state.myAccountInfo, plan_name: payload }
            }
        }
        case 'POPULATE_ATTENDANCE_REQUEST': {
            return {
                ...state, attendanceLoader: true

            }
        }
        case 'RESET_ATTENDANCE': {
            return {
                ...state, attendanceInfo: []
            }
        }
        case 'POPULATE_ATTENDANCE_SUCCESS': {
            return {
                ...state, attendanceInfo: payload, attendanceLoader: false
            }
        }
        case 'NEXT_CLASS_SUCCESS': {
            return {
                ...state, nextClass: payload.class_details, startClassStatus: true
            }
        }
        case 'NEXT_SUB_CLASS_SUCCESS': {
            return {
                ...state, nextSubClass: payload.class_details, startClassStatus: true
            }
        }
        case 'RESET_ATTENDANCE': {
            return {
                ...state, attendanceInfo: []
            }
        
        }
        case 'ADD_CLASS_STUDENT_SUCCESS': {

            return {
                ...state, students: [...state.students, payload]
            }
        }

        case 'MUTE_STUDENT_SUCCESS': {

            return {
                ...state, muteStatus: payload
            }
        }
        case 'MUTE_SUCCESS': {

            const index = state.students.findIndex(x => x.id === payload);
            const copyof = state.students
            copyof[index].mute = !copyof[index].mute

            return {

                ...state, students: copyof


            }
        }

        case 'REMOVE_STUDENT_FROM_CLASS': {
            const updatedList = state.students.filter((item) => item.id !== payload)
            return {
                ...state, students: updatedList
            }
        }


        case 'EMAIL_SEND_REQUEST': {

            return {
                ...state, resentMailLoader: true
            }
        }

        case 'EMAIL_SEND_SUCCESS': {

            return {
                ...state, resentMailLoader: "sent"
            }
        }
        case 'EMAIL_SEND_FAILED': {

            return {
                ...state, resentMailLoader: "failed"
            }
        }

        case 'EDIT_CLASS_REQUEST': {

            return {
                ...state, ccLoader: true
            }
        }
        case 'EDIT_CLASS_SUCCESS': {

            return {
                ...state, ccLoader: false
            }
        }
        case 'EDIT_CLASS_FAILED': {

            return {
                ...state, ccLoader: false
            }
        }
        case 'EDIT_DETAILS_SUCCESS': {

            return {
                ...state, editFormDetails: payload, ccLoader: false
            }
        }
        case 'FETCH_CLASS_DETAILS_SUCCESS': {

            return {
                ...state, editClassDetails: payload
            }
        }


        case 'HANDLE_CHANGE_REQUEST': {
            const { name, value } = payload.target
            // console.log("handlechange paykoad", name)
            return {
                ...state, editFormDetails: { ...state.editFormDetails, [name]: value }
            }
        }
        case 'VALIDATE_START_CLASS_REQUEST': {
            return {
                ...state, timerLoader: true
            }
        }
        case 'VALIDATE_START_CLASS_SUCCESS': {
            return {
                ...state, timerLoader: false
            }
        }
        case 'GET_STUDENT_DETAILS_REQUEST': {
            return {
                ...state, studentSearchLoader: true
            }
        }
        case 'GET_STUDENT_DETAILS_SUCCESS': {
            if (state.studentSearch.length !== 0) {
                const found = state.studentSearch.some(x => x.student_id === payload.student_id);
                if (!found) {
                    return {
                        ...state, studentSearch: [...state.studentSearch, payload], studentSearchLoader: false
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: "Already Selected"

                    })
                }
            } else {
                return {
                    ...state, studentSearch: [...state.studentSearch, payload], studentSearchLoader: false
                }

            }

        }

        case 'GET_STUDENT_DETAILS_FAILED': {
            return {
                ...state, studentSearchLoader: false
            }
        }
        case 'REMOVE_STUDENT_DETAILS': {
            //alert("remove")
            const updatedList = state.studentSearch.filter(dt => dt.student_id !== payload)
            return {
                ...state, studentSearch: updatedList, studentSearchLoader: false
            }
        }
        case 'ADD_STUDENT_UNDER_TEACHER_REQUEST': {
            return {
                ...state, assignStudentUndetTeacherLoader: true
            }
        }
        case 'ADD_STUDENT_UNDER_TEACHER_SUCCESS': {
            // const concatinate = state.myStudent
            // console.log("concatinate befo", concatinate)
            // concatinate.concat(payload)
            // console.log("payload", payload)
            // console.log("concatinate", concatinate)

            return {
                ...state, studentSearch: [], assignStudentUndetTeacherLoader: false, myStudent: [...payload, ...state.myStudent]
            }
        }
        case 'ADD_STUDENT_UNDER_TEACHER_FAILED': {
            return {
                ...state, assignStudentUndetTeacherLoader: false

            }
        }
        case 'GET_MY_STUDENT_REQUEST': {
            return {
                ...state, myStudentLoader: true
            }
        }
        case 'GET_MY_STUDENT_SUCCESS': {
            return {
                ...state, myStudentLoader: false, myStudent: payload
            }
        }
        case 'GET_MY_STUDENT_FAILED': {
            return {
                ...state, myStudentLoader: false
            }
        }

        case 'GET_MY_ENTERPRISE_STUDENT_REQUEST': {
            return {
                ...state, myEnterpeiseStudentLoader: true
            }
        }
        case 'GET_MY_ENTERPRISE_STUDENT_SUCCESS': {
            return {
                ...state, myEnterpeiseStudentLoader: false, myEnterpriseStudent: payload
            }
        }
        case 'GET_MY_ENTERPRISE_STUDENT_FAILED': {
            return {
                ...state, myEnterpeiseStudentLoader: false
            }
        }
        case 'VALIDATE_SPECIAL_CLASS_TIME_REQUEST': {
            return {
                ...state, specificClassTimeSlotLoader: true
            }
        }
        case 'VALIDATE_SPECIAL_CLASS_TIME_SUCCESS': {
            return {
                ...state, specificClassTimeSlot: [...state.specificClassTimeSlot, payload], specificClassTimeSlotLoader: false
            }
        }
        case 'VALIDATE_SPECIAL_CLASS_TIME_FAILED': {
            return {
                ...state, specificClassTimeSlotLoader: false
            }
        }
        case 'CHANGE_HOLIDAY_STATUS': {
            const index = state.specificClassTime.findIndex(x => x.weekly_class_id === payload);
            const copyof = state.specificClassTime
            copyof[index].holiday_at = !copyof[index].holiday_at
            return {
                ...state, specificClassTime: copyof

            }
        }
        case 'CREATE_CLASS_HOLIDAYS_REQUEST': {
            return {
                ...state, createClassHolidaysLoader: true

            }
        }
        case 'CREATE_CLASS_HOLIDAYS_SUCCESS': {
            return {
                ...state, createClassHolidaysLoader: false

            }
        }
        case 'CREATE_CLASS_HOLIDAYS_FAILED': {
            return {
                ...state, createClassHolidaysLoader: false

            }
        }
        case 'POPULATE_ALL_USER_SPECIAL_CLASS_REQUEST': {
            return {
                ...state, specialClassListLoader: true
            }
        }
        case 'POPULATE_ALL_USER_SPECIAL_CLASS_SUCCESS': {
            return {
                ...state, specialClassList: payload, specialClassListLoader: false
            }
        }
        case 'POPULATE_ALL_USER_SPECIAL_CLASS_FAILED': {
            alert("Something went wrong")
            return {
                ...state, specialClassListLoader: false
            }
        }
        case 'POPULATE_STARTED_CLASS__SUCCESS': {
            return {
                ...state, startClasses: payload
            }
        }
        case 'RESET_GRADE': {
            return {
                ...state, grade: ''
            }
        }
        case 'TIMER_RESET': {
            return {
                ...state, nextClass: 'null'
            }
        }
        case 'RESET_TEACHER_STORE': {

            return initialState

        }
        case 'RESET_ASSIGN_STUDENT_CLASS': {
            return {
                ...state, specificStudent: ''
            }
        }
        case 'RESET_CLASS_TIME': {
            return {
                ...state, specificClassTime: ''
            }
        }
        case 'RESET_GROUP_LIST': {
            return {
                ...state, specificGroup: ''
            }
        }
        case 'LOG_OUT': {

            return initialState
        }
        case 'VIDEO_REQUEST_SUCCESS': {
            return {
                ...state, videoList: payload
            }
        }

        case 'RESET_STUDENT_ATTANDANCE': {
            return {
                ...state, studentAttandance: []
            }
        }
        case 'RESET_ATTADANCE': {
            return {
                ...state, attendanceInfo: []
            }
        }
        case 'CLASS_SESSION_STARTED': {
            return {
                ...state, startedSession: payload
            }
        }
        case 'SET_TEACHERSTORE_DATA':{
            return {
                ...state, ...payload
            }
        }
        case 'POPULATE_MY_SUBSCRIPTION_SUCCESS': {
            return {
                ...state, teacherSubscriptions: payload
            }
        }

        case 'POPULATE_SPECIFIC_TEACHER_SUBSCRIPTIONS_SUCCESS': {
            return {
                ...state, SpecificteacherSubscriptions: payload
            }
        }
        case 'POPULATE_STUDY_MATERIAL_NOTES_SUCCESS': {
            return {
                ...state, MyStudyMaterialsNotes : payload
            }
        }
        case 'UPLOAD_STUDY_MATERIAL_PROGRESS': {
            return {
                ...state, uploadStudyMaterialProgress : payload
            }
        }
        case 'POPULATE_STUDY_MATERIAL_SUCCESS': {
            return {
                ...state, MyStudyMaterials: payload
            }
        }
        case 'POPULATE_STUDY_MATERIAL_DATA_REQUEST': {
            return {
                ...state, studyMaterialDetailsLoader : true
            }
        }
       
        case 'POPULATE_STUDY_MATERIAL_DATA_SUCCESS': {
            return {
                ...state, studyMaterialDetails: payload.studyMaterialDetails, studyMaterialDetailsLoader : false
            }
        }
        case 'UPLOAD_STUDY_MATERIAL_REQUEST': {
            return {
                ...state, studyMaterialUploadLoader : true
            }
        }
        case 'UPLOAD_STUDY_MATERIAL_SUCCESS': {
            return {
                ...state, studyMaterialUploadLoader : false
            }
        }
        case 'UPLOAD_STUDY_MATERIAL_FAILED': {
            return {
                ...state, studyMaterialUploadLoader : false
            }
        }
        
        case 'UPDATE_STUDY_MATERIAL_PROGRESS': {
            return {
                ...state, updateStudyMaterialProgress : payload
            }
        }
        
        default:
            return state

    }
}
export default teacheHomeReducer