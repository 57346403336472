const initialState = {
    
};

const schoolReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    
    case "SCHOOL_DASHBOARD_SUCCESS": {
      return {
        ...state,
        resellerDashboard: payload.dashboard_data, //both video and banner image are included
      };
    }
  

  //   case 'REGISTER_SCHOOL_REQUEST': {

  //     return {
  //         ...state, loader: true


  //     }
  // }


  // case 'REGISTER_SCHOOL_SUCCESS': {

  //     return {
  //         ...state, emailValidation: { result: null, message: null }, loader: false,
  //         otp: null,
  //         validation: false,
  //         error: false,
  //         valid: null,
  //         emailValidation: { result: null, message: null },
  //         studentNumberAvail: null,
  //         teacherNumberAvail: null


  //     }
  // }
  // case 'REGISTER_SCHOOL_FAILED': {
  //     return {
  //         ...state, loader: false

  //     }
  // }

    default:
      return state;
  }
};

export default schoolReducer;
