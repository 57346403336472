import React, { useState, useEffect, Fragment } from 'react';
// import image from "../../LandingPageNew/images/login.jpg"


import "../../LandingPageNew/css/genex-custom.css"
import "../../LandingPageNew/css/custome.css"
import '../login.css'
import {  requiredValidator,emailValidator } from '../../../utilities/Validator'
import { authRequest, } from '../../../redux/Actions/auth'
import { setHomeData } from "../../../redux/Actions/genexhome"

import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from 'react-router-dom';
import LaddaButton, { L, ZOOM_IN } from 'react-ladda';
// import firebase from "../../push-notification"

export default function Admin() {

    const history = useHistory();
    const [ViewPassword, setViewPassword] = useState(false);
    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {

        if (tokenData) {
            history.push('/dashboard')
        }
        // if (firebase.messaging.isSupported()) {
        //     firebase.messaging().getToken().then((currentToken) => {
        //         if (currentToken) {
        //             dispatch(setHomeData('message_tok', currentToken))
        //         } else {
        //             console.log(
        //                 'No Instance ID token available. Request permission to generate one.')
        //         }
        //     }).catch(() => {
        //         console.log('error')
        //     })
        // }

       
        return()=>{
            dispatch({ type: 'RESET_LOGIN_STORE' })
        }
    }, []);

    const AuthData = useSelector(({ auth }) => auth)
    // const login_from_course = useSelector((state) => state.genexhome.login_from_course ? state.genexhome.login_from_course : false);
    const message_tok = useSelector((state) => state.genexhome.message_tok ? state.genexhome.message_tok : '');
    // const login_from_subscription = useSelector((state) => state.genexhome.login_from_subscription ? state.genexhome.login_from_subscription : false);

    const [authFields, setAuthFields] = useState({
        email: '',
        password: ''
    })
    const [errors, setErrors] = useState({})

    const handleChange = e => {
        const { name, value } = e.target;
        setAuthFields({ ...authFields, [name]: value })
    }
    const cb = () => {
        history.push('/dashboard')
    }

    const SubmitLogin = (e) => {
        e.preventDefault()

        const err = {
            email: emailValidator(authFields.email),
            password: requiredValidator(authFields.password)
        }

        if (!err.email && !err.password) {
            setErrors(err)
            let obj = {}
            obj.email=authFields.email
            obj.password=authFields.password
            obj.device_token=message_tok
            obj.role='admin'
            obj.login_type='email'
            dispatch(authRequest(obj, cb))

        } else {
            setErrors(err)
        }
    }
    const newstyle = { marginTop: '15px' }

    const toggleViewPassword = () => {
        setViewPassword(!ViewPassword);
    }


    return (
        <Fragment>
        <section class="padding-top-60px padding-bottom-60px" style={{ backgroundColor: '#ffffff' }}>
            <div class="container">

                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-6 col-12">
                        <div class="row">
                            {/* <div class="col-md-6 col-lg-6 col-12 order-lg-1 order-2">
                                <img src={image} alt="" class="img-fluid" />
                            </div> */}
                            <div class="col-12 order-lg-2 order-1" style={{padding:'30px 40px'}}>
                                <div class="col-md-12 col-lg-12 col-12 my-auto pl-0 pr-0">
                                    <form onSubmit={SubmitLogin} autocomplete="off">
                                        <h2 class="text-center text-title-medium mt-2" style={{ color: '#212529' }}>SignIn as Admin</h2>

                                        <div class="col-md-12 col-lg-12 col-12">
                                            <hr class="opacity-5" />
                                            <div class="input-group input-group-sm mb-1">
                                                <div class="input-group-prepend form-group-prepend">
                                                    <span class="input-group-text" id=""><i class="fa fa-envelope"></i></span>
                                                </div>
                                                <input
                                                    type="text"
                                                    class="form-control gn-form-control"
                                                    aria-label="Small"
                                                    placeholder="Email"
                                                    aria-describedby=""
                                                    name='email'
                                                    value={authFields.email}
                                                    onChange={handleChange}
                                                    autocomplete="off"
                                                />
                                            </div>
                                            <span style={{ color: 'red' }}>{errors.email}</span>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-12">
                                            <div class="input-group input-group-sm mb-1" style={newstyle}>
                                                <div class="input-group-prepend form-group-prepend">
                                                    <span class="input-group-text" id=""><i class="fa fa-lock"></i></span>
                                                </div>
                                                <input
                                                    type={ViewPassword ? "text" : "password"}
                                                    class="form-control gn-form-control"
                                                    aria-label="Small"
                                                    placeholder="Password"
                                                    aria-describedby=""
                                                    name='password'
                                                    onChange={handleChange}
                                                    value={authFields.password}
                                                    autocomplete="off"
                                                />
                                                <div class="input-group-append form-group-append">
                                                    <span style={{ cursor: 'pointer' }} onClick={toggleViewPassword} class="input-group-text" id=""><i class={ViewPassword ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>
                                                </div>

                                            </div>
                                            <span style={{ color: 'red' }}>{errors.password}</span>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-12">
                                            <p class="text-right mb-2"><Link to="/forgot" class="font-12">Forgot Password?</Link></p>
                                        </div>
                                        
                                        <div class="col-md-12 col-lg-12 col-12">
                                            {AuthData.currentUser.result === false ?
                                                <span style={{ color: 'red' }} >Invalid Credentials</span>
                                                : null}

                                        </div>
                                        <div class="col-md-12 col-lg-12 col-12">
                                          
                                            <LaddaButton className="form-input-btn btn-block"
                                                loading={AuthData.loader}
                                                type="submit"
                                                data-style={ZOOM_IN}
                                                data-size={L}
                                            >
                                                Login
                                            </LaddaButton>
                                        </div>
                                        {/* <div class="col-md-12 col-lg-12 col-12">
                                            <p class="text-center mb-0 mt-3 font-12">Don't have an account? <span class="font-12 text-link" style={{ position: 'relative', cursor: 'pointer' }} onClick={ShowSignUp}>SignUp</span></p>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </Fragment>
    )
}
