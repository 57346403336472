import React from 'react'
import DataTable from '../../common/DataTable'

const Table = (props) => {
    // console.log("table=======data", props.data)
    return (
        <>
            <DataTable
                EditGallery={(id) => props.EditGallery(id)}
                ViewImage={(id) => props.ViewImage(id)}
                Delete={(id) => props.Delete(id)}
                AddMedia={(id) => props.AddMedia(id)}
                actions={props.actions ? props.actions : null}
                role={props.role}
                columns={props.columns} 
                handleEdit
                Play={(id)=>{props.Play(id)}}
                data={props.data}
                title={props.title}
                loader={props.loader}
            />
        </>
    )
}

export default Table
