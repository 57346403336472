import React, { useState, useEffect } from "react";

// import ViewStudent from './viewStudent'
// import Swal from 'sweetalert2'
import {
  Row,
  Col,
  Badge,
  Card,
  CardBody,
  Button,
  CardHeader,
  Container,
  Input,
  FormFeedback
} from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
// import TimeShedule from './viewTime'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import AssignStudents from './addStudentToClass'
// import Modals from '../../common/Modal'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
// import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from "react-redux";
import { getAllReseller, deleteReseller, blockReseller, payoffCommision, correctCommision } from "../../../redux/Actions/Superadmin";
import { useHistory } from "react-router-dom";

import bg1 from "../../../assets/utils/images/dropdown-header/abstract1.jpg";
import bg2 from "../../../assets/utils/images/dropdown-header/abstract2.jpg";
import bg3 from "../../../assets/utils/images/dropdown-header/abstract3.jpg";
import bg4 from "../../../assets/utils/images/dropdown-header/abstract4.jpg";
import bg5 from "../../../assets/utils/images/dropdown-header/abstract5.jpg";
import bg6 from "../../../assets/utils/images/dropdown-header/abstract6.jpg";
import Slider from "react-slick";
import Model2 from "../../common/Modal/index"

import Swal from 'sweetalert2'
import SweetAlert from 'sweetalert-react';
import Label from "reactstrap/lib/Label";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { requiredValidator, } from '../../../utilities/Validator'

const Resellerlist = () => {
  var columns;
  const dispatch = useDispatch();
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const ResellerArray = useSelector((state) =>
    state.superadmin.ResellerArray ? state.superadmin.ResellerArray : []
  );
  const resellerloader = useSelector((state) =>
    state.superadmin.resellerloader ? state.superadmin.resellerloader : ""
  );
  const payoffloader = useSelector((state) =>
    state.superadmin.payoffloader ? state.superadmin.payoffloader : false
  );

  useEffect(() => {
    if (!localStorage.getItem("authDetails")) {
      history.push("/");
    } else {
      dispatch(getAllReseller(tokenData.token));
    }
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  columns = [
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "contact_number" },
    { title: "Area", field: "area" },
    { title: "PIN", field: "pin" },
    {
      title: "Balance", field: "balance", render: (rowData) => {
        return "Rs. " + rowData.balance
      }
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return rowData.status === 1 ? (
          <Badge color="danger" pill>
            {"Blocked"}
          </Badge>
        ) : (
          <Badge color="success" pill>
            {"Active"}
          </Badge>
        );
      },
    },
    // { title: "Total_Students", field: "total_students" },
  ];
  const data = ResellerArray
    ? ResellerArray.resellers
      ? ResellerArray.resellers
      : []
    : [];
  const recentdata = ResellerArray
    ? ResellerArray.newResellers
      ? ResellerArray.newResellers
      : []
    : [];

  const action = "rseller_table";

  const Studentview = (val) => {
    // console.log('AAAAASSSSSdddd',val)
    history.push(`/dashboard/resllerstudent/${val}`);
  };

  const Editreseller = (val) => {
    history.push(`/dashboard/editReseller/${val.reseller_id}`)
  }
  const ViewReseller = (val) => {
    history.push(`/dashboard/viewReseller/${val.reseller_id}`)
  }

  const DeleteReseller = (val) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(deleteReseller(tokenData.token, val.reseller_id, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Delete action cancelled',
          'error'
        )
      }
    })
  }
  const [successModal, setSuccessModal] = useState(false);
  const [Message, setMessage] = useState(null)

  const handleOpenSuccessModal = (message) => {
    dispatch(getAllReseller(tokenData.token));
    setMessage(message)
    setReseller({ id: '', open: false })
    setSuccessModal(true)
  }
  const handleCloseSuccesstModal = () => {
    setMessage(null)
    SetInput({ id: '', amount: '', remarks: '' })
    setSuccessModal(false)
  }

  const Updatestatus = (val) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure !',
      //text: 'All the classes under the subject will be deleted',

      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "CONFIRM",
      cancelButtonText: "CANCEL",

    }).then((result) => {
      if (result.value) {
        dispatch(blockReseller(tokenData.token, val.reseller_id, handleOpenSuccessModal))

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Cancelled',
          'error'
        )
      }
    })
  }

  const [resller_data, setReseller] = useState({ id: '', open: false })
  const [inputField, SetInput] = useState({ id: '', amount: '', remarks: '' })
  const [errors, setErrors] = useState({})

  const CreditDebit = (val) => {
    setErrors({})
    setReseller({ id: val.reseller_id, open: true })
  }
  const CloseModel = () => {
    setReseller({ id: '', open: false })
  }

  const Handlechange = (event) => {
    SetInput({ ...inputField, [event.target.name]: event.target.value })
  }

  const submitPayoff = () => {
    const err = {
      id: requiredValidator(inputField.id),
      amount: requiredValidator(inputField.amount),
      remarks: requiredValidator(inputField.remarks),

    };
    if (
      !err.id &&
      !err.amount &&
      !err.remarks
    ) {
      setErrors(err);
      if (inputField.id === 'debit') {
        dispatch(payoffCommision(tokenData.token, resller_data.id, inputField.amount, inputField.remarks, handleOpenSuccessModal))
      }
      else {
        dispatch(correctCommision(tokenData.token, resller_data.id, inputField.amount, inputField.remarks, handleOpenSuccessModal))
      }
    }
    else {
      setErrors(err)
    }
  }
  return (
    <>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container>
          {recentdata.length > 2 ? (
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardHeader>Recently Added Resellers</CardHeader>
                  <CardBody>
                    <Container>
                      <Row>
                        <Col sm={12}>
                          <Slider {...settings}>
                            {recentdata.map((val, ky) => {
                              return (
                                <div
                                  className="dropdown-menu-header"
                                  style={{ borderRadius: "5px" }}
                                >
                                  <div
                                    className="dropdown-menu-header-inner bg-primary"
                                    style={{
                                      borderRadius: "5px",
                                      width: "90%",
                                    }}
                                  >
                                    <div
                                      className="menu-header-image"
                                      style={{
                                        backgroundImage:
                                          ky === 0
                                            ? "url(" + bg1 + ")"
                                            : ky === 1
                                              ? "url(" + bg2 + ")"
                                              : ky === 2
                                                ? "url(" + bg3 + ")"
                                                : ky === 3
                                                  ? "url(" + bg4 + ")"
                                                  : ky === 4
                                                    ? "url(" + bg5 + ")"
                                                    : ky === 5
                                                      ? "url(" + bg6 + ")"
                                                      : "",
                                      }}
                                    />
                                    <div className="menu-header-content">
                                      <h5 className="menu-header-title">
                                        {val.name ? val.name.length > 21 ? val.name.slice(0,18)+'...':val.name:val.name}
                                      </h5>
                                      <h6 className="menu-header-subtitle">
                                        {val.email}
                                      </h6>
                                      <h6 className="menu-header-subtitle">
                                        {val.contact_number
                                          ? val.contact_number
                                          : ""}
                                      </h6>{" "}
                                      <br />
                                      <Button
                                        solid
                                        className="mb-2 mr-2 btn-hover-shine"
                                        color="light"
                                        onClick={() => { ViewReseller(val) }}
                                      >
                                        View Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col sm={12} md={12}>
              <Table
                loader={resellerloader}
                actions={action}
                data={data}
                title={"Reseller List"}
                columns={columns}
                DetailsofVendor={Studentview}
                EditReseller={Editreseller}
                DeleteReseller={DeleteReseller}
                Updatestatus={Updatestatus}
                ViewReseller={ViewReseller}
                CreditDebit={CreditDebit}
              />
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>


      <Model2
        open={resller_data.open ? resller_data.open : false}
        handleClose={CloseModel}
        title='New Payments'
      >
        <Row>
          <Col xs={12}>
            <Input
              type='select'
              style={{ width: "100%" }}
              onChange={Handlechange}
              name='id'
              value={inputField.id}
              invalid={errors.id}

            >
              <option value=''>Select Type</option>
              <option value='credit'>Credit</option>
              <option value='debit'>Debit</option>
            </Input>
            <FormFeedback>{errors.id}</FormFeedback>

          </Col>
          <Col xs={12} style={{ marginTop: '10px' }}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span>
              Amount
            </Label>
            <Input
              min={0}
              type="number"
              style={{ width: "100%" }}
              name="amount"
              value={inputField.amount}
              onChange={Handlechange}
              invalid={errors.amount}
            />
            <FormFeedback>{errors.amount}</FormFeedback>
          </Col>

          <Col xs={12} style={{ marginTop: '10px' }}>
            <Label for="exampleNameF">
              <span className="text-danger">*</span>
              Remarks
            </Label>
            <Input
              type="textarea"
              rows={3}
              style={{ width: "100%" }}
              name="remarks"
              value={inputField.remarks}
              onChange={Handlechange}
              invalid={errors.remarks}
            />
            <FormFeedback>{errors.remarks}</FormFeedback>

          </Col>

          <Col xs={12} style={{ marginTop: '5px' }}>
            <LaddaButton className=" btn btn-primary"
              loading={payoffloader}
              type="button"
              onClick={() => submitPayoff()}
              data-style={ZOOM_IN}
            >
              Submit
            </LaddaButton>
          </Col>
        </Row>
      </Model2>

      <SweetAlert
        title="Success"
        confirmButtonColor=""
        show={successModal}
        text={Message ? Message : "Successfully completed"}
        type="success"
        onConfirm={() => handleCloseSuccesstModal()} />
    </>
  );
};

export default Resellerlist;
