import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import OwlCarousel from "react-owl-carousel";
// import c1 from "../../images/banner.png"
// import c2 from "../../images/banner2.png"
// import c3 from "../../images/banner3.png"
import LoadingOverlay from 'react-loading-overlay';
import coursedummy from "../../images/coursedummy.png";
import Mots_cover from "../../../../assets/images/Mots_cover copy2.jpg"
import nodata from "../../images/nodata.png"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { setHomeData, CommonsearchCourse } from "../../../../redux/Actions/genexhome"
import WarningIcon from '@material-ui/icons/Warning';
import Swal from 'sweetalert2'
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
const reschedule = { backgroundColor: 'rgb(49 49 49 / 55%)', borderColor: 'white', position: 'absolute', top: '10px', left: '10px', color: 'white', padding: '0px 2px', borderRadius: '5px', boxSizing: 'border-box' }

const certification = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#202A60', padding: '3px 20px', background: 'white', width: '96px', boxSizing: 'border-box', display: 'inline-block' }
const timeleft = { border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }
const timelefthidden = { visibility: 'hidden', border: '1px solid #2E77BC', borderRadius: '5px', color: '#FD4720', padding: '3px 20px', background: 'white', width: 'auto', display: 'inline-block', marginLeft: '2px' }
class CourseList extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      filtered: this.props.course_array ? this.props.course_array[0] ? this.props.course_array[0].tab_content ? this.props.course_array[0].tab_content : [] : [] : [],
      val: this.props.course_array ? this.props.course_array[0] ? this.props.course_array[0].tab_id ? this.props.course_array[0].tab_id : [] : [] : [],
      desc: this.props.course_array ? this.props.course_array[0] ? this.props.course_array[0].tab_description ? this.props.course_array[0].tab_description : [] : [] : [],
      chk: false,
      loading: false,
      searchterm: '',
      search: false,
      homeImg:[{'image': Mots_cover}],
    };
  }




  componentDidUpdate() {
    if (this.state.chk) {
      this.setState({ chk: false });
      let arr = [...this.props.course_array];
      let new_arr = arr.filter((val) => val.tab_id === this.state.val);

      let content = new_arr[0]
        ? new_arr[0].tab_content
          ? new_arr[0].tab_content
          : []
        : [];
      this.setState({ filtered: content });
      setTimeout(() => {
        this.reRender();
      }, 500);
    }
  }
  ChnageArray = (val) => {
    this.setState({ loading: true })
    this.setState({ val: val.tab_id });
    this.setState({ desc: val.tab_description });
    this.setState({ chk: true });
  };
  ChnageArrayResponsive = (e) => {
    let a = e.target.value
    // console.log('aaaaaaaaa',a)
    this.setState({ loading: true })
    this.setState({ val: a });
    this.setState({ desc: '' });
    this.setState({ chk: true });
  };
  reRender = () => {
    // calling the forceUpdate() method
    this.setState({ loading: false })
    this.forceUpdate();
  };

  Showcourse = () => {
    this.props.history.push(`/categories/${this.state.val}`);
  };

  ShowSinglecourse = (val) => {
    if (val.edit_status === 1) {
      Swal.fire({
        icon: 'warning',
        title: 'Rescheduling!',
        text: 'Will be available soon',
      })
    } else {
      // this.props.history.push(`/categories/${this.state.val}/${val.course_id}`);
      this.props.history.push(`/view-course/${val.course_id}`);

    }
  }

  Handlechange = (e) => {
    this.setState({ searchterm: e.target.value })
  }
  SearchCourse = (e) => {
    e.preventDefault();
    this.setState({ search: true })
    this.props.dispatch(setHomeData('search_term', this.state.searchterm))
    this.props.dispatch(CommonsearchCourse(this.state.searchterm, 1, this.cb))
  }
  cb = () => {
    this.props.history.push(`/search-results`);
  }
  render() {
    const tabhead = this.props.tab_head;
    const filtered = this.state.filtered;

    const styleaddes = { color: '#20a8d8' }
    const vendorname_label = { fontFamily: 'revert', color: 'rgb(0 0 0)' }
    const vendorname = { fontFamily: 'revert', color: 'rgb(20 73 144)', fontWeight: '600' }
    
    let items = this.props.slide_section.map((val, ky) => {
      return (
        <div className="item curoselitem" data-value={ky + 1} >
          <img src={val.image}  className="banner-img" alt='' />
        </div>
      )
    })

    const options = {
      responsiveClass: true,
      nav: false,
      margin: 10,
      dots: true,
      autoplay: false,
      responsive: {
        0: {
          items: 1,
        },
        400: {
          items: 1,
        },
        600: {
          items: 2,
        },
        700: {
          items: 2,
        },
        1000: {
          items: 4,
        },
      },
    };

    return (

      <>


        <div class='curoselstyle'>
          <AliceCarousel
            autoPlay
            autoPlayStrategy="none"
            autoPlayInterval={1500}
            animationDuration={1500}
            animationType="fadeout"
            infinite
            touchTracking={false}
            disableDotsControls
            disableButtonsControls={true}
            items={items}
          />
        </div>


        <section class="padding-top-40px padding-bottom-60px">

          <LoadingOverlay
            active={this.state.loading}
            spinner
            text='Loading your content...'
          >
            <div class="container" style={{ position: 'relative' }}>


              <div class="main-search wow fadeInUp" data-wow-delay="0.2s" style={{ top: '-110px', width: '90%', marginLeft: '5%' }}>
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-12">
                    <form onSubmit={this.SearchCourse} class="search-form-main main-search-website">
                      <div class="row justify-content-center">
                        <div class="col-md-5 col-md-5 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                          <div class="form-group">
                            <input type="text" class="form-control" id="" value={this.state.searchterm ? this.state.searchterm : ''} onChange={this.Handlechange} placeholder="What are you looking for" />
                          </div>
                        </div>
                        <div class="col-md-2 col-md-2 col-12 pl-2 pr-2 pl-md-0 pr-md-0">
                          <div class="form-group">

                            <input
                              type="button"
                              disabled={!this.state.searchterm || this.state.search}
                              class="btn btn-block search-btn"
                              value="Search"
                              onClick={this.SearchCourse}
                            />
                          </div>
                        </div>
                      </div>
                    </form>


                    <div class="row">
                      <div class="col-md-12 col-lg-12 col-12 text-center">
                        <ul id="tabs-nav" className='tabnavlist'>
                          {
                            tabhead.map((val, ky) => {
                              return (
                                <li key={ky}
                                  onClick={() => { this.ChnageArray(val) }}>
                                  <a style={this.state.val === val.tab_id ? styleaddes : null}>
                                    {val.tab_name}
                                  </a>
                                </li>
                              )

                            })
                          }

                        </ul>
                        <div className='list-tab' style={{ width: '100%' }}>
                          <div class="row">
                            {
                              tabhead.map((val, ky) => {
                                return (
                                  <div style={{ width: '50%', textAlign: 'left' }} onClick={() => { this.ChnageArray(val) }}>
                                    <span className='spanres' style={this.state.val === val.tab_id ? styleaddes : null}> {val.tab_name}</span>
                                  </div>
                                )
                              })
                            }

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-12 col-12 pl-0 pr-0 pl-md-0 pr-md-0 search-col-title-col">
                  <h2 class="text-center search-col-title">Find your courses</h2>
                </div>

              </div>



              <div class="tabs">


                <div id="tabs-content">
                  <div id="tab1" class="tab-content ">

                    <OwlCarousel
                      id="feature-slide"
                      className="owl-carousel owl-theme newcurclass"
                      {...options}
                    >
                      {
                        filtered.map((val, ky) => {
                          let vendor = val.vendor_name ? val.vendor_name : ''
                          let v_name
                          if (vendor.length > 15) {
                            let apend = vendor.slice(0, 14)
                            v_name = apend + '...'
                          }
                          else {
                            v_name = vendor
                          }
                          return (
                            <div class="swiper-slide" style={{ paddingBottom: '5px', cursor: 'pointer' }} key={ky}  >
                              <div class="blog-item thum-hover featured-institute-main">
                                <div class="position-relative">
                                  <div onClick={() => { this.ShowSinglecourse(val) }} class="item-thumbnail  "><img src={val.banner_url ? val.banner_url : coursedummy} alt="" width='100%' height='200px'  /></div>
                                  <div class="cat-div">{val.course_category}</div>
                                  {
                                    val.edit_status === 1 ?
                                      <div style={reschedule}><WarningIcon style={{ color: '#d8d81c', fontSize: '15px' }} /> Rescheduling</div>
                                      : ''
                                  }
                                </div>
                                <div class="padding-20px bgclrbtm-of-course">
                                  <div onClick={() => { this.ShowSinglecourse(val) }} >
                                    <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                      <span class="f-i-title" style={{ color: '#000' }}>{val.course_name}</span>
                                    </div>
                                    <div style={{ height: '50px', overflow: 'hidden', wordWrap: 'break-word' }}>
                                      <p class="font-14 mb-1" style={{ color: '#000' }}>{val.course_subject_name}</p>
                                    </div>
                                    <p class="mb-0"><span class="font-16 font-weight-500 text-blue">{val.course_amount}</span></p>
                                  </div>
                                  <hr class="mt-1 mb-1" />
                                  <p class="mb-0">
                                    {
                                      val.certified === 1 ?
                                        <div style={certification}>Certified</div>
                                        : null
                                    }
                                    {
                                      val.days_left ?
                                        <div style={timeleft}>{val.days_left}</div>
                                        : <span style={timelefthidden} >{0}</span>
                                    }
                                  </p>
                                  <Tooltip arrow title={val.vendor_name}>
                                    <Link to={{ pathname: `/vendor/${val.vendor_id}/${val.skl_user_id}` }} >
                                      <span>
                                        <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                      </span>
                                    </Link>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </OwlCarousel>

                  </div>

                </div>

                {
                  filtered.length > 0 ?
                    ""
                    :
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <img src={nodata} width='500px' height='300px' loading='lazy' />
                    </div>
                }
              </div>
              {
                filtered.length > 0 ?

                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-12 text-center mt-0" >
                      <a onClick={this.Showcourse} style={{ cursor: 'pointer' }} class="btn btn-gn border-radius-30 wow fadeInUp" data-wow-delay="0.5s">View All</a>
                    </div>
                  </div>
                  : null
              }

            </div>
          </LoadingOverlay>
        </section>

      </>
    );
  }
}

function mapstore(store) {
  return {
    genexhome: store.genexhome,
  };
}
export default connect(mapstore)(withRouter(CourseList));
