import React,{useState,useEffect,Fragment} from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    Col,
    // Badge,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { requiredValidator } from "../../../utilities/Validator";
import { setReqData,addGalleryDetails,getGalleryDetails,deleteGalleryMedia } from "../../../redux/Actions/Superadmin"
import { useDispatch,useSelector } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { Progress } from "react-sweet-progress";
import { useParams } from 'react-router-dom'
import MediaCard from "./MediaCard"
import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Loader } from 'react-loaders';

function CreateGalleryPost() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const formData = new FormData();
    const params = useParams()
    const gallery = useSelector((state) =>state.superadmin.gallery ? state.superadmin.gallery : false);
    const percetage = useSelector((state) =>state.superadmin.percetage ? state.superadmin.percetage : '');

    
    const [InputField,setInputfield]=useState({
        title:'',
        discription:'',
    })
    const [err,setErr]=useState({})
    const [VideoFile, setVideoFile] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [opt, setOpt] = useState('image');

    const Gallery_Array = useSelector((state) =>state.superadmin.Gallery_Array_details ? state.superadmin.Gallery_Array_details : []);
	const prevpage = useSelector((state) =>state.superadmin.gallery_first_page_url ? state.superadmin.gallery_first_page_url :'');
	const nextpage = useSelector((state) =>state.superadmin.gallery_next_page_url ? state.superadmin.gallery_next_page_url : '');
	const page = useSelector((state) =>state.superadmin.gal_details_page ? state.superadmin.gal_details_page :1);
	const from = useSelector((state) =>state.superadmin.from_gal_details ? state.superadmin.from_gal_details : '');
	const to = useSelector((state) =>state.superadmin.to_gal_details ? state.superadmin.to_gal_details : '');

    const gallerydetailsloader = useSelector((state) =>state.superadmin.gallerydetailsloader ? state.superadmin.gallerydetailsloader :false);

    useEffect(()=>{
        dispatch(setReqData('percetage',''))
        dispatch(getGalleryDetails(tokenData.token,params.gallery_id,page))
    },[page])

    const handleChangeInput=(e)=>{
        setInputfield({...InputField,[e.target.name]:e.target.value})
    }

    const Submitform=(e)=>{
        e.preventDefault();
        let meadia
        if(opt==='image'){
            meadia=ImageFile===null ? "*Required":''
        }else{
            meadia=VideoFile===null ? "*Required":''
        }

        const error={
            title: requiredValidator(InputField.title),
            // discription: requiredValidator(InputField.discription),
            meadiaerr:meadia
        }
        if( !error.title && !error.meadiaerr){
            setErr(error)
            formData.append("title", InputField.title);
            formData.append("description", InputField.discription);
            formData.append("image_file", ImageFile);
            formData.append("video_file",VideoFile );
            formData.append("gallery_id",params.gallery_id);
            dispatch(addGalleryDetails(tokenData.token,formData,cb))
        }
        else{
            setErr(error)
        }
    }

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleVideoUpload = (e) => {
        setVideoFile(e.target.files[0]);
    };
      
    const cb=()=>{
        if(page===1){
            dispatch(getGalleryDetails(tokenData.token,params.gallery_id,1))
        }
        else{
            dispatch(setReqData('gal_details_page',1))
        }
        document.getElementById('form_element').reset()
        setInputfield({
            title:'',
            discription:'',
        })
        dispatch(setReqData('percetage',''))
        setVideoFile(null)
        setImageFile(null)
        setOpen(true)
    } 

    const ChangeOpt=(e)=>{
        setOpt(e.target.value)
    }

    const NextPage=()=>{
        dispatch(setReqData('gal_details_page',page+1))
    }
    const PreviousPage=()=>{
        dispatch(setReqData('gal_details_page',page-1))
    }

    const RemoveMedia=(id)=>{
        dispatch(deleteGalleryMedia(tokenData.token,id,cb2))
    }
    const cb2=()=>{
        dispatch(getGalleryDetails(tokenData.token,params.gallery_id,page))
    }
    return (
        <Fragment>

            <PageTitle
                heading="Add Media"
                subheading="Add Media To Your Gallery"
                icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
            />

                <Card className="main-card mb-3">
                    <Form onSubmit={Submitform} id='form_element'>
                    <CardHeader>
                        Add Media
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col className="pt-1  mt-2" xs={12}>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                        Title
                                    </Label>

                                    <Input
                                        type="text"
                                        name="title"
                                        value={InputField.title}
                                        invalid={err.title}
                                        onChange={handleChangeInput}
                                        autocomplete="off"
                                    />
                                    <FormFeedback>{err.title}</FormFeedback>

                            </Col>
                            
                            <Col className="pt-1 mt-2" xs={12}>
                                <Label for="exampleNameF">
                                    {/* <span className="text-danger">*</span> */}
                                        Description
                                    </Label>

                                    <Input
                                        type="textarea"
                                        rows={2}
                                        name="discription"
                                        value={InputField.discription}
                                        // invalid={err.discription}
                                        onChange={handleChangeInput}
                                    />
                                    {/* <FormFeedback>{err.discription}</FormFeedback> */}

                            </Col>
                            
                            <Col xs={12} sm={6} className="pt-1 mt-4">
                                <Label for="exampleNameF">
                                        Choose Image/Video
                                    </Label>
                                <Input 
                                    type='select'
                                    name='opt'
                                    onChange={ChangeOpt}
                                    value={opt}
                                    invalid={err.meadiaerr}
                                >
                                    <option value='image'>Image</option>
                                    <option value='video'>Video</option>
                                </Input>
                            </Col>
                            
                            <Col className="pt-1 mt-4" xs={12} sm={6}>
                                {
                                    opt==='image' ? 
                                    <>
                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                                Image
                                        </Label>
                                        <Input 
                                            type='file'
                                            name='image'
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                    </>
                                    :
                                    <>
                                        <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                                Video
                                            </Label>
                                        <Input 
                                            type='file'
                                            name='video'
                                            accept="video/mp4,video/x-m4v,video/*"
                                            onChange={handleVideoUpload}
                                        />
                                    </>
                                }
                                    <span style={{color:'red'}}>{err.meadiaerr}</span>
                            </Col>

           
                            
                            <Col sm={12} className="pt-1 mp-2">
                            {(percetage !== ""  && (ImageFile || VideoFile )) ? (
                                <>
                                    <strong style={{ color: "rgb(19, 140, 228)" }}>
                                    Uploading Files...
                                    </strong>
                                    <Progress percent={percetage} />
                                </>
                                ) : null}
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                    
                        <Col xs={12} sm={6}>
                            <LaddaButton
                                className="btn btn-primary"
                                loading={gallery}
                                type="submit"
                                data-style={ZOOM_IN}
                            >
                                Add
                            </LaddaButton>
                        </Col>
                    </CardFooter>
                            
                    </Form>
                </Card>
                {
                    Gallery_Array.length > 0 ? 
                
                <LoadingOverlay tag="div" active={gallerydetailsloader}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 0.5
                        })
                    }}
                    spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                <Card>
                    <CardHeader>
                        medias
                    </CardHeader>
                    <CardBody>
                        <Row>
                            {
                                Gallery_Array.map((val,ky)=>{
                                    return(
                                    <Col xs={12} sm={6} md={4} key={ky} className='mt-3'>
                                        <MediaCard value={val} Removemeadia={RemoveMedia} />
                                    </Col>
                                    )
                                })
                            }
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                            <div style={{width:'50%',display:'flex',height:'50px',justifyContent:'flex-end'}}>
                                <span style={{marginTop:'13px'}}>{from}-{to}</span>
                                <Button
                                    onClick={PreviousPage}
                                    disabled={prevpage ? false :true}
                                >
                                    <NavigateBeforeIcon />
                                </Button>
                                <Button
                                    onClick={NextPage}
                                    disabled={nextpage ? false :true}
                                >
                                    <NavigateNextIcon />
                                </Button>
                            </div>
                        </div>
                    </CardFooter>
                </Card>
                </LoadingOverlay>
                :''
                }
                

                <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={open}
                    text={"Media file added Sucessfully"}
                    type="success"
                    onConfirm={() => setOpen(false)} />

        </Fragment>
    )
}

export default CreateGalleryPost
