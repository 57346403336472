import React,{useEffect,useState} from 'react';
import { useDispatch,useSelector } from "react-redux";
import Table from "./table";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitle from "../../HeaderTitle/PageTitle";
import { useHistory,useLocation } from "react-router-dom";
import { Modal,ModalHeader,ModalBody } from 'reactstrap';

import ReactHtmlParser from 'react-html-parser';
import Swal from 'sweetalert2'
import spinner from '../../../assets/animated/Spinner.gif'
import { listAllGalleries,deleteGallery} from "../../../redux/Actions/Superadmin"
import SweetAlert from 'sweetalert-react';
import previewdummy from "../../LandingPageNew/images/previewdummy.png"

function GalleryList() {

    const dispatch = useDispatch();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const history = useHistory();
    const location = useLocation();


    const galleryloader = useSelector((state) =>state.superadmin.galleryloader ? state.superadmin.galleryloader : false);
    const Gallerydata = useSelector((state) =>state.superadmin.Gallerydata ? state.superadmin.Gallerydata : []);

    const [image,setImage]=useState({show:false,url:''})
    const [video,setVideo]=useState({show:false,url:''})

    useEffect(()=>{
        dispatch(listAllGalleries(tokenData.token))
    },[])

    const EditGallery=(rowdata)=>{
        history.push(`/dashboard/editGalleryPost/${rowdata.gallery_id}`)
    }
    const Playvideo=(rowdata)=>{
        if(rowdata.thumbnails){
            let video=rowdata ? rowdata.preview_video ? rowdata.preview_video:[]:[]
            let frstvideo=video ? video[0]:''
            let thumb=frstvideo ? frstvideo.thumbnails:[]
            let thumblink=thumb[1] ? thumb[1].link:''
            if (thumblink.includes('default')) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Processing',
                    // text: "Video on process"

                })
            } 
            else {
            setVideo({show:true,url:rowdata.embed})
            }
        }
        else{
            Swal.fire({
                icon: 'warning',
                title: 'No Video',
                text: "There is no video"

        })
        }

    }
    const CloseVideo=()=>{
        setVideo({show:false,url:''})
    }
    const ViewImage=(rowdata)=>{
        setImage({show:true,url:rowdata.image})
    }

    const CloseImage=()=>{
        setImage({show:false,url:''})
    }
    let col=[
        { title: 'Title', field: 'title' },
        // { title: 'Description', field: 'description' },
        { title: 'Created At', field: 'created_at' },
        { title: 'Updated At', field: 'updated_at' },

        // { title: 'Phone', field: 'phone' },
    ]

    const Delete=(rowdata)=>{
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',
    
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",
    
        }).then((result) => {
            if (result.value) {
                dispatch(deleteGallery(tokenData.token,rowdata.gallery_id,handleOpenSuccessModal))
    
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }

    const AddMedia=(val)=>{
        history.push('/dashboard/gallery/add-media/'+val.gallery_id)
    }
    const [successModal, setSuccessModal] = useState(false);
    const handleOpenSuccessModal = () => {
        dispatch(listAllGalleries(tokenData.token))
        setSuccessModal(true)
    }
    return (
        <div>
            <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
        >
            {
                location.pathname!=='/dashboard/createGallery' ?
                <PageTitle
                    heading="Galleries"
                    subheading="List of Your Galleries"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />
                :
                ''
            }
           

            <Table
                loader={galleryloader}
                actions={'gallery'}
                data={Gallerydata}
                title={'Gallery List'}
                columns={col}
                EditGallery={EditGallery}
                Play={Playvideo}
                ViewImage={ViewImage}
                Delete={Delete}
                AddMedia={AddMedia}
            />

        </CSSTransitionGroup>

        <Modal
          isOpen={image.show}
          toggle={CloseImage}
          size={'md'}
        >   
            <ModalHeader toggle={CloseImage}>
                Preview Image
            </ModalHeader>
            <ModalBody>
                {
                    image.url ?
                    <img src={image.url} alt='image' width='100%' height='350px' />
                    :
                    <div style={{width:'100%',height:'100%',justifyContent:'center',display:'flex',alignItems:'flex'}}>
                        <span>Please Upload An Image</span>
                    </div>
                }
            </ModalBody>
            
        </Modal>

        <Modal
            isOpen={video.show}
            toggle={CloseVideo}
            size={'md'}
        >
            <ModalHeader toggle={CloseVideo}>
                Preview Video
            </ModalHeader>
            <ModalBody>
            {
                video.url ? 
                <div style={{ background: `url(${spinner})`, minHeight: 200, backgroundSize: 75, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                  {ReactHtmlParser(video.url)}
                </div>
                :
                <div style={{width:'100%',height:'100%',justifyContent:'center',display:'flex',alignItems:'flex'}}>
                        <img  src={previewdummy} alt='' width='200px' height='200px'/>
                    </div>
              }
            </ModalBody>
        </Modal>


        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text= {"Deleted Successfully"}
            type="success"
            onConfirm={() => setSuccessModal(false)} />

        </div>
    )
}

export default GalleryList
