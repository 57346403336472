import React from 'react'
// import Loader from 'react-spinners/HashLoader'
//import Loader from 'react-spinners/ClipLoader'
//import Image from "../assets/utils/images/blocked.png"
//import Image from "../../assets/utils/images"
import Image from "../../assets/utils/images/blocked.png"
import {Card,Row,Col} from 'reactstrap'
function Index() {
    return (
        <div>
            <Card >
                <Row>

                <Col sm={2}></Col>
                <Col sm={8}>
                   {/* <img alt="blocked" src={Image} ></img> */}
                   <span style={{color:'red'}}>Please Contact Admin!</span>
                </Col>
             
                </Row>
                {/* <Row>
                    <Col sm={5} ></Col>
                    <Col className="pt-4" sm={5} >
                         <Loader color="blue"></Loader>
                    </Col>
                </Row> */}
            </Card>
        </div>
    )
}

export default Index
