import React, { useState } from 'react'
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import PageTitle from '../../../Components/common/HeaderTitle/PageTitle'
import { Card, Col, Row } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import moment from 'moment'
import { useDispatch, } from 'react-redux'
import { listAllSchoolTransactions,exportSchoolTransactions } from '../../../redux/Actions/reseller'
import Table from './table'

function Index() {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch()
    const [Transactions, setTransactions] = useState()
    const [Loader, setLoader] = useState(false)
    const [xLoader, setxLoader] = useState(false)
  
    const Download = () => {
        const body = {
            from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
            to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
            
        }
        setxLoader(true)
        dispatch(exportSchoolTransactions(tokenData.token,body,xcb))
        // console.clear()
        // console.log(body)
       
    }
    const xcb = (url) =>{
        setxLoader(false);
        var link = document.createElement("a");
        link.href = url;

        link.click();
        link.remove();

    }
     
    const cb = (data) => {
        setTransactions(data)
        setLoader(false)
    }
    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -32),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    let columns = [
        { title: '#', field: 'tableData.id' },
        { title: 'Name', field: 'full_name' },
        { title: 'Transaction_id', field: 'transaction_id',
        render: (rowData) => {
            return '#'+rowData.transaction_id
            },
        },
        { title: 'Order_Id', field: 'order_id',
        render: (rowData) => {
            if(rowData.order_id)
            return '#' + rowData.order_id
            else return " " 
          },
        },
        { title: 'Date', field: 'created_at' },
        { title: "Credit", field: "type",
        render: (rowData) => {
        return rowData.type === 'Credit' ? (
           'Rs. '+ rowData.amount
        ) : (
            ' '
        );
        }, 
    },
    { title: "Debit", field: "type",
    render: (rowData) => {
        return rowData.type === 'Debit' ? (
       'Rs. '+ rowData.amount
        ) : (
        ' '
        );
    }
}, { title: 'Balance', field: 'balance',
render: (rowData) => {
    return 'Rs .'+rowData.balance
    } },

    ]
    const filter = () => {
        const body = {
            from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
            to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
            user_id: 2197
        }
        setLoader(true)
        dispatch(listAllSchoolTransactions(tokenData.token, body, cb))
        // console.clear()
        // console.log(body)
    }
    return (
        <div>
            <PageTitle

                heading="Transaction Details"
                subheading="Details of all debits and credits"
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />
            <Card>
                <Col style={{ width: "100%" }} sm={12}>
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        maxDate={new Date()}
                        direction="horizontal"
                        color='#fed858'
                    />
                </Col>
                <Row>
                    <Col sm={8}>{" "}</Col>
                    <Col className="p-3" sm={2}>

                        <LaddaButton className=" mr-2 btn btn-primary"
                            style={{ width: '-webkit-fill-available' }}
                            loading={Loader}
                            type="button"
                            onClick={filter}
                            data-style={ZOOM_IN}  >
                            Filter
                    </LaddaButton>
                    </Col>
                    <Col className="p-3" sm={2}>

                        <LaddaButton className=" mr-2 btn btn-secondary"
                            style={{ width: '-webkit-fill-available' }}
                            loading={xLoader}
                            type="button"
                            onClick={Download}
                            data-style={ZOOM_IN}  >
                            Export
</LaddaButton>
                    </Col>
                </Row>
            </Card>
            <Row>
                <Col className="pt-4" sm={12} md={12}>
                    <Table
                        loader={Loader}
                        title={"Transactions"}
                        //loader={false}
                        data={Transactions}
                        //  data={dummyData}
                        role={tokenData.role}
                        columns={columns}
                        actions='reseller_transaction_list'

                    />
                </Col>
            </Row >
        </div>
    )
}

export default Index
