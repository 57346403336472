import { Route, Switch } from 'react-router-dom';
import React from 'react';

import Dashboards from '../../Components/Dashboard'

import verified from '../../Components/EmailVerified/VerifiedSuccess'
import verifiedAlr from '../../Components/EmailVerified/aleadyVerified'

import ForgotPassword from '../../Components/ForgotPassword/forgot';
import resetPassword from '../../Components/ResetPassword'

import ScrollToTop from "../ScrollToTop/ScrollToTop"
import LandingPageNew from "../../Components/LandingPageNew/index"
import CategoryIndex from "../../Components/LandingPageNew/Categories/index"
// import CourseIndex from "../../Components/LandingPageNew/Courses/index"
import VendorIndex from "../../Components/LandingPageNew/Vendor/index"
import LoginIndex from "../../Components/Logingenex/index"
import LoginIndexTeacher from "../../Components/Logingenex/LoginTeacher"
import Signupindex from "../../Components/Signupgenex/index"
// import SignupVendor from "../../Components/SignupgenexVendor/index"
// import SignupSchool from "../../Components/SignupgenexSchool/index"

import SingleVendorIndex from "../../Components/LandingPageNew/Vendor/singleVendor/index"
import SingleCourseIndex from "../../Components/LandingPageNew/Courses/SingleCourse/index"
// import WhyusIndex from "../../Components/LandingPageNew/Whyus/index"
// import WhoWeareIndex from "../../Components/LandingPageNew/WhoweAre"
import SearchCourseIndex from "../../Components/LandingPageNew/HomeSearch/Index"
// import FilterCollageGradeIndex from "../../Components/LandingPageNew/Collage/FilterCollageGrade/index"
// import CollageGradeList from "../../Components/LandingPageNew/Collage/CollageGradesList/index"
import ContactIndex from "../../Components/LandingPageNew/Contactus/Index"
import FullCourseIndex from "../../Components/LandingPageNew/Fullcourselist/Index"
// import TestimonialIndex from "../../Components/LandingPageNew/Testimonial/Index"
// import GalleryIndex from "../../Components/LandingPageNew/Gallery/Index"
// import NewsIndex from "../../Components/LandingPageNew/News/Index"
// import SingleNewsIndex from "../../Components/LandingPageNew/News/SingleNews/SingleNewsIndex"
import SubscriptionIndex from "../../Components/LandingPageNew/Subscriptions/Index"
import SingleSubscription from "../../Components/LandingPageNew/Subscriptions/SingleSubscription/index"
import NotFound from '../../Components/PageNotFound/index';

// import SingleGalleryIndex from "../../Components/LandingPageNew/Gallery/SingleGallery/Index"
import PaymentFromLink from "../../Components/LandingPageNew/PaymentByLink/Index"

// import TagIndex from "../../Components/LandingPageNew/Tags/index"
import TagDetail from "../../Components/LandingPageNew/Tags/SingleTagDetails/Index"
// import NewSIngleVendor from "../../Components/LandingPageNew/Vendor/NewSIngleVendor"
import CourseIndexThroughLink from "../../Components/LandingPageNew/CourseDetailsThrougLink/Index"
import DashboardCourseRedirect from "../../Components/LandingPageNew/CourseDetailsThrougLink/DashboardCoursePage/DashboardCourseRedirect"
import AdminLogin from "../../Components/Logingenex/LoginAdmin/Index"
import DashboardByLink from "../../Components/LandingPageNew/DashboardThroughLink/Index"
import FirstPaymentStatus from '../../Components/LandingPageNew/SkillablersPayment/FirstPaymentStatus';
import PaymentStatus from '../../Components/LandingPageNew/SkillablersPayment/PaymentStatus'

import PaymentStatusSubscription from '../../Components/LandingPageNew/SkillablersPayment/Subscription/PaymentStatus'
import FirstPaymentStatusSubscription from '../../Components/LandingPageNew/SkillablersPayment/Subscription/FirstPaymentStatus';
// import CameraTag from "./CameraTag"
const AppMain = () => {
    


    return (
        <>

            <ScrollToTop />
            <Switch>

                <Route exact path='/categories' component={CategoryIndex} ></Route>
                <Route exact path='/categories/:category_id' component={TagDetail} ></Route>
                <Route path='/vendors' component={VendorIndex} ></Route>
                <Route exact path='/vendor/:vendor_id/:skl_user_id' component={SingleVendorIndex} ></Route>
                <Route path='/student-register' component={Signupindex} ></Route>
                <Route path='/categories/:category_id/:course_id' component={SingleCourseIndex} ></Route>
                <Route exact path='/vendor/:vendor_id/:course_id' component={SingleCourseIndex} ></Route>
                <Route path='/search-results' component={SearchCourseIndex} ></Route>
                <Route path='/view-course/:course_id' component={SingleCourseIndex} ></Route>
                <Route path='/contact' component={ContactIndex}></Route>
                <Route path='/courseList' component={FullCourseIndex}></Route>
                <Route exact path='/subscription/:subscription_id' component={SingleSubscription} ></Route>
                <Route path='/subscriptions' component={SubscriptionIndex} ></Route>
                <Route path='/website/course-details/:course_id/:token' component={CourseIndexThroughLink} />
                <Route exact path='/dashboard/course-details/:course_id/:token' component={DashboardCourseRedirect} />
                <Route exact path="/" component={LandingPageNew} />
                <Route exact path="/login" component={LoginIndex} />
                <Route exact path="/login/teacher" component={LoginIndexTeacher} />
                <Route path='/admin' component={AdminLogin} />
                <Route path="/dashboard" component={Dashboards} />
                <Route path="/resetPassword/:token" component={resetPassword} />
                <Route path="/emailVerifiedSuccessfully" component={verified} />
                <Route path="/emailAlreadyVerified" component={verifiedAlr} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route exact path="/payment-link/:code" component={PaymentFromLink} />
                <Route exact path="/skillablers/dashboard/:token" component={DashboardByLink} />
                {/* <Route exact path="/test" component={Form} /> */}
                <Route path="/payment-status" component={FirstPaymentStatus} />
                <Route path="/payment-status-conformation" component={PaymentStatus} />
              
                <Route path="/payment-status-conformation-subscription" component={PaymentStatusSubscription} />
                <Route path="/payment-subscription-status" component={FirstPaymentStatusSubscription} />
                {/* <Route path="/camera" component={CameraTag} /> */}

                
                <Route  render={() => <div style={{width:'100%',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}><NotFound /></div>}/>
            </Switch>         

        </>
    )
};

export default AppMain;