import React,{useEffect} from 'react';
import Grid from '@material-ui/core/Grid';

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
// import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { getAllStudentCourse } from "../../../redux/Actions/Student"
import { useDispatch, useSelector } from "react-redux";
// import './course.css'
import { useHistory } from "react-router-dom";

import WarningIcon from '@material-ui/icons/Warning';
import PageTitle from "../../HeaderTitle/PageTitle";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import nodata from '../../LandingPageNew/images/nodata.png'
import coursedummy from "../../LandingPageNew/images/coursedummy.png"
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2'

const vendorname_label={fontFamily:'revert',color:'rgb(0 0 0)'}
const vendorname={fontFamily:'revert',color:'rgb(20 73 144)',fontWeight:'600'}

function StudentCourseList(){
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    const StudentCourseArray = useSelector((state) =>state.student.StudentCourseArray ? state.student.StudentCourseArray : '');

    useEffect(()=>{
        dispatch(getAllStudentCourse(tokenData.token))
    },[])

    const Viewcourse=(val)=>{
        if(val.payment_status==='Refund Completed'){
                Swal.fire({
                    icon: 'warning',
                    title: 'Refunded Course',
                    text: "No Access"
                })
              
        }
        else{
            history.push(`/dashboard/viewCourse/${val.course_id}/${val.order_id}`)
        }
    }
    const reschedule={backgroundColor:'rgb(49 49 49 / 55%)',borderColor:'white',position:'absolute',top:'10px',left:'10px',color:'white',padding:'0px 2px',borderRadius:'5px',boxSizing:'border-box'}

    return(
        
        <div>
            {
                StudentCourseArray ? 
                <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                    <PageTitle
                        // style={{ display: "none" }}
                        heading="Your Courses"
                        subheading="Course List"
                        icon="lnr-graduation-hat icon-gradient bg-happy-itmeo"
                    />
                <Grid container>
                {
                    StudentCourseArray.length > 0 ?
                    <>
                        {
                            StudentCourseArray.map((val,ky)=>{
                                let vendor=val.vendor_name ? val.vendor_name :''
                              let v_name
                              if(vendor.length >= 14){
                                let apend=vendor.slice(0,13)
                                v_name= apend +'...'
                              }
                              else{
                                v_name=vendor
                              }
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={ky} style={{marginTop:'10px',padding:'10px',boxSizing:'border-box'}}>
                                        
                                        <div class="blog-item thum-hover featured-institute-main" style={{cursor:'pointer'}} onClick={()=>{Viewcourse(val)}}>
                                                    <div class="position-relative">
                                                        <div class="item-thumbnail " 
                                                        style={{width:'100%', height:'200px'}}
                                                        ><img src={val.banner_url ? val.banner_url:coursedummy} alt="" width='100%' height='100%' /></div>
                                                        {/* <div class="cat-div">
                                                            {val.tags ? val.tags[0] ? val.tags[0].tag_name ? val.tags[0].tag_name:'':'':''}
                                                        </div> */}
                                                        {
                                                            val.edit_status===1 ?
                                                            <div style={reschedule}><WarningIcon style={{color:'#d8d81c',fontSize:'15px'}}/> Rescheduling</div>
                                                            :''
                                                        }
                                                    </div>
                                                    <div class="padding-20px bgclrbtm-of-course">
                                                        <div style={{height:'50px',overflow:'hidden',wordWrap:'break-word'}}>
                                                            <span class="f-i-title">{val.course_name}</span>
                                                        </div>
                                                        <div style={{height:'50px',overflow:'hidden',wordWrap:'break-word'}}>
                                                            <p class="font-14 mb-2">{val.course_subject_name}</p>
                                                        </div>
                                                        <p class="mb-0"><span style={{color:'#6a6f73'}}>Order Id </span><span class="font-16 font-weight-500 text-blue">#{val.order_id}</span>
                                                        </p>
                                                        <hr class="mt-1 mb-2" />
                                                        <p class="mb-0">
                                                            <span class="mr-1 course-badges badge badge-success">{val.purchase_date}</span>
                                                            <span class="course-badges badge badge-dark">{val.fee}</span>
                                                        </p>
                                                        <Tooltip arrow title={val.vendor_name}>
                                                            <span>
                                                                <span style={vendorname_label}>Provided by</span><span style={vendorname}> {v_name}</span>
                                                            </span>
                                                        </Tooltip> 
                                                    </div>
                                                </div>
                                    </Grid>
                                )
                            })
                        }
                    </>
                    :
                    <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <img src={nodata} width='600px' height='400px' alt='' />
                    </div>
                }

            </Grid>
            </CSSTransitionGroup>

            :
            <>
                <Card style={{ padding: '30px' }}>
                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={30} count={5} />
                </SkeletonTheme>
                </Card>
                <Card style={{ padding: '30px' }}>
                <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={30} count={5} />
                </SkeletonTheme>
                </Card>
            </>
            }
        </div>
    )
}

export default StudentCourseList