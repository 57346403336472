import React from 'react'
// import {  useSelector } from 'react-redux'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Row, Col, Card } from 'reactstrap'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import { updatePlaylist } from '../../../redux/Actions/playlist'
import EditIcon from '@material-ui/icons/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import './thumbStyle.scss'
const Index = (props) => {

    const { title, count, subject, grade, to, type,
        subject_id, DeletePlaylists, category_id,
        openEdit, index } = props
    return (
        <Card className="jms-thumb-card">

            <Row>
                <Col md={4} className="jms-thumb-icon">

                    <PlayCircleOutlineIcon style={{ fontSize: '56px' }} />
                    <p className="jms-video-count" >{count} VIDEOS </p>
                </Col>
                <Col md={8} className="jms-thumb-details">
                    <Link to={`/dashboard/playlist/${to}`}>
                        <div className="jms-thumb-link">
                            <p className="jms-thumb-title" id={`jms-tooltip-${index}`}>{title} </p>
   
                            {
                                type === "genaral" ?
                                    null :
                                    <>
                                        <p className="jms-thumb-sub-details"><FontAwesomeIcon color="#329481" icon={faBookOpen} /> &nbsp;&nbsp;{subject}</p>
                                        <p className="jms-thumb-sub-details"> <FontAwesomeIcon color="#329481" icon={faGraduationCap} /> &nbsp;&nbsp;{grade} </p>
                                    </>
                            }

                        </div>
                    </Link>

                    <div className="jms-thumb-action">
                        <div className="jms-thumb-action-edit" onClick={() => openEdit(title, to, category_id)}>  <EditIcon style={{ fontSize: '20px' }} />Edit</div>
                        <div className="jms-thumb-action-delete" onClick={() => DeletePlaylists(to, category_id)} ><DeleteOutlineIcon style={{ fontSize: '20px' }} />Delete</div>

                    </div>
                </Col>
            </Row>
        </Card>



    )
}

export default Index
